import { ColorRange } from 'types/ColorRange';

export const colorRangesForScaleLayerOfMapWidget: ColorRange[] = [
  {
    from: '#00ff00',
    to: '#ff0000',
  },
  {
    from: '#ff0000',
    to: '#00ff00',
  },
  {
    from: '#ff0000',
    to: '#0000ff',
  },
  {
    from: '#0000ff',
    to: '#ff0000',
  },
  {
    from: '#eeee00',
    to: '#00eeee',
  },
  {
    from: '#00eeee',
    to: '#eeee00',
  },
  {
    from: '#ff0000',
    to: '#333333',
  },
  {
    from: '#333333',
    to: '#ff0000',
  },
  {
    from: '#00ff00',
    to: '#333333',
  },
  {
    from: '#333333',
    to: '#00ff00',
  },
  {
    from: '#0000ff',
    to: '#333333',
  },
  {
    from: '#cc00cc',
    to: '#333333',
  },
  {
    from: '#00cccc',
    to: '#333333',
  },
  {
    from: '#cccc00',
    to: '#333333',
  },
];
