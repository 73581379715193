import { isRequired } from 'features/project/Constructor/i18nSharedReferences';
import * as M from 'types/serverModels';
import { FormElementState, makeFormElementState } from 'utils/FormState';
import { makePrimaryUnit } from 'utils/State';
import { nonEmptyArray, nonNull } from 'utils/validators';

import {
  QuestionConstructorWidget,
  SharedInstanceStateConstructor,
} from '../../types';
import * as FormExtension from './FormExtension';
import * as PreviewModeForm from './PreviewModeForm';
import { StateInstance } from './types';

export type { StateInstance };

export const constructorWidget: QuestionConstructorWidget<
  'file',
  StateInstance,
  typeof makeInstance
> = {
  key: 'file',
  icon: 'file',
  makeInstance,
  FormExtension: FormExtension.Component,
  PreviewModeForm: PreviewModeForm.Component,
};

export function makeServerQuestion(
  fileCodesState: FormElementState<string[] | null>,
): Partial<M.FileQuestion> {
  return {
    type: 'file',
    file_types: fileCodesState.getValue() || undefined,
  };
}

export function makeInstance(
  makeSharedInstanceState: SharedInstanceStateConstructor,
  fileCodes: string[] | null = null,
): StateInstance {
  const fileCodesState = makeFormElementState<string[] | null>(fileCodes, [
    nonNull(isRequired),
    nonEmptyArray(isRequired),
  ]);

  const formEntitiesUnit = makePrimaryUnit([fileCodesState]);

  return {
    kind: 'file',
    fileCodes: fileCodesState,
    makeServerQuestion: () => makeServerQuestion(fileCodesState),
    ...makeSharedInstanceState(formEntitiesUnit),
  };
}
