import React from 'react';

import { VennWidgetDataSettings } from 'features/project/Constructor/subfeatures';
import { widgetTypeToQuestionKindMapping } from 'shared/constants';
import { makeDerivedUnit, makePrimaryUnit } from 'utils/State';
import { VennChart } from 'utils/business';

import {
  enumeratedQuestionInstances,
  questionIDToQuestionKindUnit,
} from '../../../../InputDataForm/units/instances';
import { questionIDToIndexedQuestionTextUnit } from '../../../../InputDataForm/units/instances';
import { Question } from '../../../../shared';
import { DataSettingsProps } from '../../../types';
import { VennInstance } from '../types';

const instancesForVennChartWidget = makeDerivedUnit(
  enumeratedQuestionInstances,
  questionIDToQuestionKindUnit,
).getUnit((questions, questionIDToQuestionKind) =>
  questions.filter(x =>
    Object.values(widgetTypeToQuestionKindMapping.vennChart)
      .flat()
      .includes(questionIDToQuestionKind[x.instance.id]),
  ),
);

const questionsForVennUnit = makeDerivedUnit(
  instancesForVennChartWidget,
  questionIDToQuestionKindUnit,
  questionIDToIndexedQuestionTextUnit,
).getUnit((instances, questionIDToQuestion, questionIDToQuestionText) =>
  instances.map((x): VennChart.Question => {
    const id = x.instance.id;
    const name = makePrimaryUnit(questionIDToQuestionText[x.instance.id]);

    switch (questionIDToQuestion[x.instance.id]) {
      case 'number':
        return {
          kind: 'with-number',
          id,
          name,
        };

      case 'multiple-variants-selection':
        return {
          kind: 'with-multiple-variants',
          id,
          name,
          variants: (
            x.instance as any as Question.VariantSelection.StateInstance
          ).variants,
        };

      case 'single-variant-selection':
        return {
          kind: 'with-single-variant',
          id,
          name,
          variants: (
            x.instance as any as Question.VariantSelection.StateInstance
          ).variants,
        };
      default:
        throw Error('unexpected question instance');
    }
  }),
);

function DataSettings({ instance }: DataSettingsProps<VennInstance>) {
  return (
    <VennWidgetDataSettings.Component
      questionsUnit={questionsForVennUnit}
      setsUnit={instance.sets}
      setsFormSectionState={instance.setsFormSectionStateUnit}
      makeValidators={VennChart.makeConstructorValidators}
    />
  );
}

export const Component = React.memo(DataSettings);
