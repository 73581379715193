import i18nData from 'features/project/Constructor/i18n.json';
import { I18n } from 'services';
import * as TS from 'types';
import {
  AbstractStateUnit,
  makeDerivedUnit,
  makeMappingUnit,
} from 'utils/State';

const makeErrorReference = (
  key: keyof (typeof i18nData)[TS.Language]['steps']['shared']['materials']['errors'],
) => {
  return I18n.makeEntryReference(
    i18nData,
    data => data.steps.shared.materials.errors[key],
  );
};

const makeErrorUnit = (
  errorReference: I18n.EntryReference,
  invariantUnit: AbstractStateUnit<boolean>,
) =>
  makeDerivedUnit(invariantUnit).getUnit(invariant =>
    invariant ? null : errorReference,
  );

const makeNullableErrorsUnit = (
  allMaterialsAreConfirmedUnit: AbstractStateUnit<boolean>,
) =>
  makeMappingUnit([
    makeErrorUnit(
      makeErrorReference('notAllMaterialsConfirmed'),
      allMaterialsAreConfirmedUnit,
    ),
  ]);

export const makeErrorsUnit = (
  allMaterialsAreConfirmedUnit: AbstractStateUnit<boolean>,
) =>
  makeDerivedUnit(makeNullableErrorsUnit(allMaterialsAreConfirmedUnit)).getUnit(
    xs =>
      xs.filter<I18n.EntryReference>(
        (x): x is I18n.EntryReference => x !== null,
      ),
  );
