import {
  EnumeratedNodesDescription,
  SearchParamsDescription,
  HashParamDescription,
  InfiniteNodesDescription,
  SuperiorNodeDescription,
  LeafNodeDescription,
} from './types';

export function makeEnumeratedNodesDescription<
  S extends string,
  XS extends S[],
  N extends string,
  T extends Record<string, any>,
  SearchParams,
  HashParam extends Location['hash'],
>(
  values: XS,
  name: N,
  tree: T,
  searchParams?: SearchParamsDescription<SearchParams>,
  hashParam?: HashParamDescription<HashParam>,
): EnumeratedNodesDescription<XS[number], N, T, SearchParams, HashParam> {
  return {
    kind: 'enumerated',
    name,
    tree,
    values,
    searchParams,
    hashParam,
  };
}

export function makeInfiniteNodesDescription<
  T extends Record<string, any>,
  N extends string,
  SearchParams,
  HashParam extends Location['hash'],
  NameTreeSearchParams,
  NameTreeHashParam extends Location['hash'],
>(
  name: N,
  tree: T,
  searchParams?: SearchParamsDescription<SearchParams>,
  hashParam?: HashParamDescription<HashParam>,
  nameTreeSearchParams?: SearchParamsDescription<NameTreeSearchParams>,
  nameTreeHashParam?: HashParamDescription<NameTreeHashParam>,
): InfiniteNodesDescription<
  N,
  T,
  SearchParams,
  HashParam,
  NameTreeSearchParams,
  NameTreeHashParam
> {
  return {
    kind: 'string',
    name,
    tree,
    searchParams,
    hashParam,
    nameTreeSearchParams,
    nameTreeHashParam,
  };
}

export function makeSearchParamsDescription<
  Params,
>(): SearchParamsDescription<Params> {
  return {
    kind: 'search-params',
    params: null as any,
  };
}

export function makeHashParamDescription<
  Param extends Location['hash'],
>(): HashParamDescription<Param> {
  return {
    kind: 'hash-param',
    param: '' as Param,
  };
}

export function makeSuperiorNodeDescription<
  T extends Record<string, any>,
  SearchParams,
  HashParam extends Location['hash'],
>(
  tree: T,
  searchParams?: SearchParamsDescription<SearchParams>,
  hashParam?: HashParamDescription<HashParam>,
): SuperiorNodeDescription<T, SearchParams, HashParam> {
  return {
    kind: 'superior',
    tree,
    searchParams,
    hashParam,
  };
}

export function isSuperiorNodeDescription(
  x: any,
): x is SuperiorNodeDescription<Record<string, any>, any, any> {
  return (
    (x as SuperiorNodeDescription<Record<string, any>, any, any>)?.kind ===
    'superior'
  );
}

export function makeLeafNodeDescription<
  SearchParams,
  HashParam extends Location['hash'],
>(
  searchParams?: SearchParamsDescription<SearchParams>,
  hashParam?: HashParamDescription<HashParam>,
): LeafNodeDescription<SearchParams, HashParam> {
  return {
    kind: 'leaf',
    searchParams,
    hashParam,
  };
}

export function isLeafNodeDescription(
  x: any,
): x is LeafNodeDescription<any, any> {
  return (x as LeafNodeDescription<any, any>)?.kind === 'leaf';
}

export function isEnumeratedNodesDescription(
  x: any,
): x is EnumeratedNodesDescription<
  string,
  string,
  Record<string, any>,
  unknown,
  Location['hash']
> {
  return (
    (
      x as EnumeratedNodesDescription<
        string,
        string,
        Record<string, any>,
        unknown,
        Location['hash']
      >
    )?.kind === 'enumerated'
  );
}

export function isInfiniteNodesDescription(
  x: any,
): x is InfiniteNodesDescription<
  string,
  Record<string, any>,
  never,
  never,
  never,
  never
> {
  return (
    (
      x as InfiniteNodesDescription<
        string,
        Record<string, any>,
        never,
        never,
        never,
        never
      >
    )?.kind === 'string'
  );
}

export function isSearchParamsDescription(
  x: any,
): x is SearchParamsDescription<string[]> {
  return (x as SearchParamsDescription<string[]>)?.kind === 'search-params';
}

export function hasSearchParams(x: any): boolean {
  if (
    isInfiniteNodesDescription(x) ||
    isEnumeratedNodesDescription(x) ||
    isSuperiorNodeDescription(x) ||
    isLeafNodeDescription(x)
  ) {
    return x.searchParams !== undefined;
  }

  return false;
}
