import { USER_ROLE } from 'shared/constants';
import * as M from 'types/serverModels';

import {
  statusesOfDisplayableCoursesForStaffUser,
  statusesOfDisplayableCourses,
} from '../constants';

type Options = {
  currentUser: M.User | null;
};

export function getStatusesOfDisplayableCourses({ currentUser }: Options) {
  const withPrivateDataDisplay = currentUser?.role === USER_ROLE.moderator;

  return withPrivateDataDisplay
    ? statusesOfDisplayableCoursesForStaffUser
    : statusesOfDisplayableCourses;
}
