import { useEffect } from 'react';

import * as M from 'types/serverModels';
import { makeDerivedUnit, PrimaryStateUnit } from 'utils/State';

import * as services from '../services';
import { CallState } from '../types';

export const quantityRefsCallStateUnit =
  services.refLoad.makeCallStateUnit() as PrimaryStateUnit<
    CallState<M.QuantityReferenceBook[]>
  >;

const quantitiesCallStateUnit = makeDerivedUnit(
  quantityRefsCallStateUnit,
).getUnit<
  CallState<Record<M.QuantityReferenceBook['uuid'], M.QuantityReferenceBook>>
>(quantityRefsCallState => {
  if (quantityRefsCallState.kind === 'successful') {
    const data = quantityRefsCallState.data.reduce<
      Record<M.QuantityReferenceBook['uuid'], M.QuantityReferenceBook>
    >((acc, x) => {
      return {
        ...acc,
        [x.uuid]: x,
      };
    }, {});

    return {
      kind: 'successful',
      data,
    };
  }

  return quantityRefsCallState;
});

let wasCalled = false;

function useQuantityRefsCallState() {
  const quantityRefsCallState = quantityRefsCallStateUnit.useState();

  const call = services.refLoad.useCall(
    quantityRefsCallStateUnit as PrimaryStateUnit<CallState<M.ReferenceBook[]>>,
  );

  useEffect(() => {
    if (!wasCalled) {
      wasCalled = true;

      call({ ref: 'quantity' });
    }
  }, [quantityRefsCallState.kind, call]);
}

export function useCallState() {
  const callState = quantitiesCallStateUnit.useState();

  useQuantityRefsCallState();

  return callState;
}
