import * as R from 'ramda';

import { serverMaterialsUnit } from 'features/project/Constructor/units';
import { API } from 'services';
import * as M from 'types/serverModels';

import { CachedInstance } from './types';

type UnitsToBePreserved = {
  cachedInstance: CachedInstance;
  unit: M.Unit;
};

export function saveMaterials(
  cachedInstances: CachedInstance[],
): Promise<void[]> | null {
  const serverMaterials = serverMaterialsUnit.getState();

  const unitsToBePreserved = cachedInstances
    .map(cachedInstance => {
      const stateInstance = cachedInstance.getStateInstance();
      const mode = stateInstance.mode.getState();

      if (stateInstance.kind !== 'notSelected' && mode !== 'edit') {
        const unit = {
          ...stateInstance.makeSharedServerUnit(),
          ...stateInstance.makeServerUnit(),
        } as M.Unit;

        const serverMaterial = unit.uuid ? serverMaterials[unit.uuid] : null;

        const isUnitsEqual =
          !!serverMaterial &&
          Object.entries(unit).every(([key, x]) =>
            R.equals(serverMaterial[key as keyof M.Unit], x),
          );

        if (!isUnitsEqual) {
          return {
            cachedInstance,
            unit,
          };
        }
      }
      return null;
    })
    .filter((x): x is UnitsToBePreserved => x !== null);

  if (!unitsToBePreserved.length) {
    return null;
  }

  return Promise.all(
    unitsToBePreserved.map(async ({ cachedInstance, unit }) => {
      const uuid = await API.services.unitWrite.callPromised(unit);
      serverMaterialsUnit.setState(prevState => ({
        ...prevState,
        [uuid]: unit,
      }));
      cachedInstance.serverIDUnit.setState(uuid);
    }),
  );
}
