import * as R from 'ramda';
import React, { useCallback, useMemo } from 'react';

import { ConstructorConfigContext } from 'features/project/Constructor/config/configContext';
import { UsersSelectionModal } from 'features/project/Constructor/subfeatures';
import { API } from 'services';
import * as M from 'types/serverModels';
import { useRequiredContext } from 'utils/react/RequiredContext';

import { InvitedUserData } from '../../../../types';
import {
  authorsUnit,
  invitedAuthorsUnit,
  invitedSupervisorsUnit,
  supervisorsUnit,
} from '../../../../units';
import { ConfigContext } from './ConfigContext';
import * as List from './List';
import { Config } from './types';

export * from './types';

type Props = {
  config: Config;
  className?: string;
};

function Leadership({ config, className }: Props) {
  const { getProjectUUID } = useRequiredContext(ConstructorConfigContext);

  const UsersSelectionModalHeader = UsersSelectionModal.Header.useFromTitle({
    useTitle: useCallback(
      () => config.userSelectionModalTitle,
      [config.userSelectionModalTitle],
    ),
  });

  const handleUsersSelectionModalSubmit: UsersSelectionModal.Props['onSubmit'] =
    useCallback(
      users => {
        const projectUUID = getProjectUUID();

        if (projectUUID === null) {
          throw new Error('Unexpected nullary project UUID');
        }

        Promise.allSettled(
          users.map(x =>
            API.services.pendingActionCreate.callPromised({
              type: config.pendingActionType,
              data: {
                project: projectUUID,
                user: x.login,
              },
            }),
          ),
        ).then(xs => {
          const newInvitedUsers = R.zip(users, xs)
            .filter(([, promiseResult]) => promiseResult.status === 'fulfilled')
            .map(
              ([userData, promiseResult]): InvitedUserData => ({
                userData,
                actionUUID: (promiseResult as PromiseFulfilledResult<string>)
                  .value,
              }),
            );

          config.invitedUsersUnit.setState(prev => [
            ...prev,
            ...newInvitedUsers,
          ]);
        });
      },
      [config, getProjectUUID],
    );

  const invitedAuthors = invitedAuthorsUnit.useState();
  const invitedSupervisors = invitedSupervisorsUnit.useState();

  const authors = authorsUnit.useState();
  const supervisors = supervisorsUnit.useState();

  const usersFilterPredicate = useMemo(() => {
    const usersToHide = [
      ...invitedAuthors.map(x => x.userData),
      ...invitedSupervisors.map(x => x.userData),
      ...authors,
      ...supervisors,
    ];

    return (user: M.UserData) => !usersToHide.some(x => x.login === user.login);
  }, [authors, invitedAuthors, invitedSupervisors, supervisors]);

  return (
    <ConfigContext.Provider config={config}>
      <div className={className}>
        <List.Component usersUnit={config.usersUnit} />
        <UsersSelectionModal.Component
          Header={UsersSelectionModalHeader}
          isOpenUnit={config.usersSelectionModalIsOpenUnit}
          usersFilterPredicate={usersFilterPredicate}
          onSubmit={handleUsersSelectionModalSubmit}
        />
      </div>
    </ConfigContext.Provider>
  );
}

export const Component = React.memo(Leadership);

export { List };
