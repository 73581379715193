import {
  DisabledPresentationFileTypeIcon,
  EnabledPresentationFileTypeIcon,
  DisabledVideoFileTypeIcon,
  EnabledVideoFileTypeIcon,
  DisabledTestFileTypeIcon,
  EnabledTestFileTypeIcon,
  DisabledPrintFileTypeIcon,
  EnabledPrintFileTypeIcon,
  DisabledWebFileTypeIcon,
  EnabledWebFileTypeIcon,
} from 'shared/images/fileTypes';
import * as M from 'types/serverModels';

const icons = {
  presentation: {
    disabled: DisabledPresentationFileTypeIcon,
    enabled: EnabledPresentationFileTypeIcon,
  },
  video: {
    disabled: DisabledVideoFileTypeIcon,
    enabled: EnabledVideoFileTypeIcon,
  },
  test: {
    disabled: DisabledTestFileTypeIcon,
    enabled: EnabledTestFileTypeIcon,
  },
  print: {
    disabled: DisabledPrintFileTypeIcon,
    enabled: EnabledPrintFileTypeIcon,
  },
  web: { disabled: DisabledWebFileTypeIcon, enabled: EnabledWebFileTypeIcon },
  interactive: {
    disabled: DisabledWebFileTypeIcon,
    enabled: EnabledWebFileTypeIcon,
  },
  project: {
    disabled: DisabledPresentationFileTypeIcon,
    enabled: EnabledPresentationFileTypeIcon,
  },
  mesch_eom: {
    disabled: DisabledPrintFileTypeIcon,
    enabled: EnabledPrintFileTypeIcon,
  },
  noop() {
    return null;
  },
};

export function getIcon(
  data: Pick<M.Unit | M.ProjectBookUnit, 'type'> | undefined,
  variant: 'disabled' | 'enabled',
) {
  if (data?.type === undefined || icons[data.type] === undefined) {
    return icons.noop;
  }

  return icons[data.type][variant];
}
