import React, { useEffect, useMemo } from 'react';

import { Modal } from 'components';
import i18nData from 'features/project/Constructor/i18n.json';
import { serverProjectDataUnit } from 'features/project/Constructor/units';
import { API, I18n } from 'services';
import { userStateUnit } from 'shared/stateUnits';

import { authorsUnit, invitedAuthorsUnit } from '../../../units';
import { LeadershipList } from '../shared';

type Props = {};

function AuthorsList({}: Props) {
  const userState = userStateUnit.useState();
  const serverProject = serverProjectDataUnit.useState();

  const text =
    I18n.useText(i18nData).steps.access.sections.leadership.subsections.authors;

  const usersSelectionModalIsOpenUnit = Modal.useIsOpenUnit();
  const pendingActionListCallStateUnit =
    API.services.pendingActionList.useCallStateUnit();

  const config = useMemo((): LeadershipList.AuthorsConfig => {
    return {
      kind: 'authors',
      pendingActionType: 'project_author_invitation',
      listTitle: text.title,
      userSelectionModalTitle: text.inviteAuthorsModalTitle,
      inviteButtonTitle: text.inviteAuthorsButtonText,
      pendingUsersSublistTitle: text.pendingAuthorsSublistTitle,
      usersUnit: authorsUnit,
      invitedUsersUnit: invitedAuthorsUnit,
      usersSelectionModalIsOpenUnit,
      pendingActionListCallStateUnit,
    };
  }, [usersSelectionModalIsOpenUnit, pendingActionListCallStateUnit, text]);

  useEffect(() => {
    if (userState.kind === 'loaded' && serverProject === null) {
      const { user } = userState;

      authorsUnit.setState(prevState => {
        const isAuthorAlreadyExists = !!prevState.find(
          x => x.login === user.login,
        );

        if (isAuthorAlreadyExists) {
          return prevState;
        }

        return [
          {
            ...user,
            location: [user.location.longitude, user.location.latitude],
          },
          ...prevState,
        ];
      });
    }
  }, [serverProject, userState]);

  return <LeadershipList.Component config={config} />;
}

export const Component = React.memo(AuthorsList);
