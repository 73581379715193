/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';

import { API, Page } from 'services';
import { ArticleBatch } from 'services/API/services/articleList';
import { block } from 'utils/classname';

import { routeTree } from '../routeTree';
import * as features from './features';
import './style.scss';

const b = block('handbook-page');

const getBatchPromise = (code: string) =>
  API.services.articleList.callPromised({ code });

function HandbookMain() {
  const handbookBatchRouteParams =
    routeTree.LANG.handbook.CODE.batches.BATCH.useRouteParams();
  const topicBatchRouteParams =
    routeTree.LANG.handbook.CODE.topic.TOPIC.batches.BATCH.useRouteParams();

  const routeBatchCode = (() => {
    if (handbookBatchRouteParams) {
      return handbookBatchRouteParams.batch;
    }

    if (topicBatchRouteParams) {
      return topicBatchRouteParams.batch;
    }

    return null;
  })();

  const paramsTopic = routeTree.LANG.handbook.CODE.topic.TOPIC.useRouteParams();
  const topicCode = (() => {
    if (paramsTopic) {
      return paramsTopic.topic;
    }
    return null;
  })();

  if (process.env.BUILD_TARGET !== 'client') return null;

  const [batches, setBatches] = useState<ArticleBatch[] | null>(null);

  const params = routeTree.LANG.handbook.CODE.useRouteParams() as Record<
    'code' | 'lang',
    string
  > & {
    rest: string[];
  };

  useEffect(() => {
    if (!params) return;

    if (params.code.includes('-') || params.code.includes('_')) {
      const codes = params.code
        .replaceAll('-', ' ')
        .replaceAll('_', ' ')
        .split(' ');
      const batchPromises = codes.map(code => getBatchPromise(code));
      Promise.all(batchPromises).then(batches => {
        setBatches(batches);
      });
    } else {
      getBatchPromise(params.code).then(batch => {
        setBatches([batch]);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.code]);

  const match = useRouteMatch({
    path: routeTree.LANG.handbook.CODE.topic.TOPIC.getPath(),
  });
  const showTopicList = !match && batches;
  const showTopic = Boolean(match) && batches;

  return (
    <div className={b()}>
      <div className={b('side-menu')}>
        {batches && (
          <features.TabsMenu.Component
            batches={batches}
            currBatch={routeBatchCode}
            topicCode={topicCode}
          />
        )}
      </div>

      <div className={b('card')}>
        {showTopicList && routeBatchCode && (
          <features.HandbookMain.Component
            batches={batches}
            currBatch={routeBatchCode}
          />
        )}
        {showTopic && <features.Topic.Component code={topicCode} />}
      </div>
    </div>
  );
}

export const Component = Page.makePage({
  path: routeTree.LANG.handbook.CODE.getPath(),
  routeProps: { exact: false },
  scrollTo: 'top-on-mount',
  Component: React.memo(HandbookMain),
  features: Object.values(features).map(x => x.feature),
});
