import React from 'react';

import { Page } from 'services';
import { block } from 'utils/classname';

import { routeTree } from '../routeTree';
import * as features from './features';
import './style.scss';

const b = block('testimonials-page');

function Testimonials() {
  return (
    <div className={b()}>
      <features.TestimonialList.Component
        hashParamUnit={routeTree.LANG.testimonials.hashParamUnit}
      />
    </div>
  );
}

export const Component = Page.makePage({
  path: routeTree.LANG.testimonials.getPath(),
  routeProps: { exact: true },
  scrollTo: 'top-on-mount',
  Component: React.memo(Testimonials),
  features: Object.values(features).map(x => x.feature),
});
