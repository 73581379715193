import React from 'react';

import { routeTree } from 'pages/routeTree';
import { Page } from 'services';
import { block } from 'utils/classname';

import * as features from './features';
import './style.scss';

const b = block('questionnaire-view-page');

type Props = features.QuestionnaireView.Props;

function QuestionnaireView(props: Props) {
  return (
    <div className={b()}>
      <features.QuestionnaireView.Component {...props} />
    </div>
  );
}

export const path =
  routeTree.LANG.project.list.PROJECT.form.QUESTIONNAIRE_UUID.getPath();

export const Component = Page.makePage({
  path,
  routeProps: { exact: true },
  scrollTo: 'top-on-mount',
  Component: React.memo(QuestionnaireView) as typeof QuestionnaireView,
  features: Object.values(features).map(x => x.feature),
}) as typeof QuestionnaireView;
