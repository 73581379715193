import { makeSharedEntryReference } from 'services/I18n/makeEntryReference';

export const reportButtonLabelReference = makeSharedEntryReference(
  data => data.reportButtonLabel,
);

export const blockButtonLabelReference = makeSharedEntryReference(
  data => data.blockButtonLabel,
);

export const likeButtonLabelReference = makeSharedEntryReference(
  data => data.likeButtonLabel,
);
