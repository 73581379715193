import * as TS from 'types';
import * as M from 'types/serverModels';

export function makeServerQuestionType(
  questionKind: TS.QuestionKind,
): M.Question['type'] {
  switch (questionKind) {
    case 'date':
      return 'date';
    case 'figures':
      return 'draw';
    case 'image':
      return 'image';
    case 'map':
      return 'map';
    case 'multiline-string':
      return 'text';
    case 'multiple-variants-selection':
      return 'multi_choice';
    case 'number':
      return 'number';
    case 'single-line-string':
      return 'string';
    case 'single-variant-selection':
      return 'single_choice';
    case 'sound':
      return 'sound';
    case 'stream':
      return 'probe';
    case 'video':
      return 'video';
    case 'file':
      return 'file';
  }
}

export function makeServerQuizQuestionType(
  questionKind: TS.QuizQuestionKind,
): M.QuizQuestion['type'] {
  switch (questionKind) {
    case 'image':
      return 'image';
    case 'map':
      return 'map';
    case 'text-to-text-match':
      return 'match';
    case 'image-to-text-match':
      return 'match';
    case 'multiline-string':
      return 'text';
    case 'multiple-variants-selection':
      return 'multi_choice';
    case 'number':
      return 'number';
    case 'single-line-string':
      return 'string';
    case 'single-variant-selection':
      return 'single_choice';
  }
}

export function makeQuestionKind(
  questionType: M.Question['type'],
): TS.QuestionKind {
  switch (questionType) {
    case 'date':
      return 'date';
    case 'draw':
      return 'figures';
    case 'image':
      return 'image';
    case 'map':
      return 'map';
    case 'text':
      return 'multiline-string';
    case 'multi_choice':
      return 'multiple-variants-selection';
    case 'number':
      return 'number';
    case 'string':
      return 'single-line-string';
    case 'single_choice':
      return 'single-variant-selection';
    case 'sound':
      return 'sound';
    case 'probe':
      return 'stream';
    case 'video':
      return 'video';
    case 'file':
      return 'file';
  }
}

export function makeQuizQuestionKind(
  question: M.QuizQuestion,
): TS.QuizQuestionKind {
  switch (question.type) {
    case 'image':
      return 'image';
    case 'map':
      return 'map';
    case 'match':
      return question.columns.left.mode === 'image'
        ? 'image-to-text-match'
        : 'text-to-text-match';
    case 'text':
      return 'multiline-string';
    case 'multi_choice':
      return 'multiple-variants-selection';
    case 'number':
      return 'number';
    case 'string':
      return 'single-line-string';
    case 'single_choice':
      return 'single-variant-selection';
  }
}
