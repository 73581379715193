import {
  formatDuration,
  formatISODuration,
  intervalToDuration,
} from 'date-fns';
import { useCallback } from 'react';

import { activeLangStateUnit } from './activeLangStateUnit';
import { dateFormatLocales } from './constants';

type Arguments = {
  from: Date;
  to: Date;
  options?: {
    format?: (
      | 'years'
      | 'months'
      | 'weeks'
      | 'days'
      | 'hours'
      | 'minutes'
      | 'seconds'
    )[];
  };
};

export function useGetFormattedTextDuration() {
  const lang = activeLangStateUnit.useState();

  return useCallback(
    ({ from, to, options = {} }: Arguments) => {
      const fullOptions = {
        locale: dateFormatLocales[lang],
        format: ['years', 'months', 'weeks', 'days'],
        ...options,
      };

      const duration = intervalToDuration({ start: from, end: to });

      return {
        text: formatDuration(duration, fullOptions),
        datetime: formatISODuration(duration),
      };
    },
    [lang],
  );
}
