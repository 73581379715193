import { Validator } from 'types';
import {
  FormElementState,
  makeFormElementState,
  makeFormSectionState,
} from 'utils/FormState';
import { makeDerivedUnit, makeMappingUnitFromUnit } from 'utils/State';
import { VennChart } from 'utils/business';

import { VariantOfMultipleVariants } from '../types';

export function forMultipleVariants(
  question: VennChart.QuestionWithMultipleVariants,
  name: string,
  initiallyIncludedVariantsIDs: string[] = [],
  validators: Validator[] = [],
): VennChart.SetForMultipleVariantsQuestion {
  const checkboxStateCache: Record<
    string,
    FormElementState<boolean>
  > = initiallyIncludedVariantsIDs.reduce(
    (acc, x) => ({
      ...acc,
      [x]: makeFormElementState(true),
    }),
    {},
  );

  const variantsState = makeDerivedUnit(question.variants).getUnit(variants => {
    return variants.map((x): VariantOfMultipleVariants => {
      if (checkboxStateCache[x.id] === undefined) {
        checkboxStateCache[x.id] = makeFormElementState<boolean>(false);
      }
      return {
        id: x.id,
        name: x.text.formElementState.units.value,
        value: checkboxStateCache[x.id],
      };
    });
  });

  const valuesUnit = makeMappingUnitFromUnit(
    makeDerivedUnit(variantsState).getUnit(variants =>
      variants.map(x => x.value.units.value),
    ),
  );

  return {
    kind: 'for-multiple-variants',
    question,
    name,
    formSectionStateUnit: makeFormSectionState(valuesUnit, validators),
    variants: makeDerivedUnit(question.variants).getUnit(variants => {
      return variants.map((x): VariantOfMultipleVariants => {
        if (checkboxStateCache[x.id] === undefined) {
          checkboxStateCache[x.id] = makeFormElementState<boolean>(false);
        }
        return {
          id: x.id,
          name: x.text.formElementState.units.value,
          value: checkboxStateCache[x.id],
        };
      });
    }),
  };
}
