import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  data: M.Basket;
};

type Output = {
  basket: M.Basket;
  features: Record<M.Feature['code'], number>;
};

export const storeBasketSet = makeService<InputData, Output>(
  'store_basket_set',
  'post-json',
);
