import { useCallback, useMemo } from 'react';

import * as M from 'types/serverModels';

import { activeLangStateUnit } from './activeLangStateUnit';
import { datePlaceholders } from './constants';

type Options = {
  from: M.Datetime | Date | number | null;
  to: M.Datetime | Date | number | null;
};

export function useGetFormattedRangeDate(options?: Intl.DateTimeFormatOptions) {
  const lang = activeLangStateUnit.useState();
  const formatter = useMemo(
    () => new Intl.DateTimeFormat(lang, options),
    [lang, options],
  );

  return useCallback(
    ({ from, to }: Options) => {
      const dateFrom = from ? new Date(from) : null;
      const dateTo = to ? new Date(to) : null;

      if (dateFrom && dateTo) {
        return formatter.formatRange(dateFrom, dateTo);
      }

      if (!dateFrom && dateTo) {
        return formatter.format(dateTo);
      }

      if (dateFrom && !dateTo) {
        return formatter.format(dateFrom);
      }

      return `${datePlaceholders[lang]}\u00A0—\u00A0${datePlaceholders[lang]}`;
    },
    [formatter, lang],
  );
}
