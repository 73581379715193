import { makeSharedEntryReference } from 'services/I18n/makeEntryReference';

export const successTitleReference = makeSharedEntryReference(
  data => data.confirmEmailTitle.success,
);

export const errorTitleReference = makeSharedEntryReference(
  data => data.confirmEmailTitle.error,
);

export const pendingTitleReference = makeSharedEntryReference(
  data => data.confirmEmailTitle.pending,
);
