import { isRequired } from 'features/project/Constructor/i18nSharedReferences';
import { getProjectLanguage } from 'features/project/Constructor/utils';
import { I18n } from 'services';
import * as M from 'types/serverModels';
import { FormElementState, makeFormElementState } from 'utils/FormState';
import { FormEntityState } from 'utils/FormState/types';
import {
  AbstractStateUnit,
  makeDerivedUnit,
  makeMappingUnitFromUnit,
  makePrimaryUnit,
} from 'utils/State';
import { nonEmptyString } from 'utils/validators';

import {
  QuestionStepKey,
  SharedInstanceStateConstructor,
  SharedServerQuestion,
} from '../types';

export function makeSharedServerQuestion({
  questionTextState,
  questionExplanationState,
  isRequiredState,
  id,
  stepKey,
  image,
  answerVisibility,
  visibility,
}: {
  questionTextState: I18n.MultilangFormState;
  questionExplanationState: I18n.MultilangFormState;
  isRequiredState: FormElementState<boolean>;
  id: string;
  stepKey: QuestionStepKey;
  image?: M.Question['image'];
  visibility?: M.QuestionVisibility;
  answerVisibility?: M.QuestionAnswerVisibility;
}): SharedServerQuestion {
  const lang = getProjectLanguage();

  const shared = {
    uuid: id,
    title: questionTextState.getMergedMultilingString(lang),
    description: questionExplanationState.getMergedMultilingString(lang),
  };

  switch (stepKey) {
    case 'conclusions':
      return shared;

    case 'inputDataForm':
      return {
        ...shared,
        image,
        visibility,
        answer_visibility: answerVisibility,
        optional: !isRequiredState.units.value.getState(),
      };
  }
}

export function validateInstance(
  titleFormState: FormElementState<string>,
  formEntitiesUnit: AbstractStateUnit<FormEntityState[]>,
) {
  const formEntities = formEntitiesUnit.getState();
  return [titleFormState, ...formEntities]
    .map(x => x.formNode.validate())
    .every(x => x);
}

export function makeSharedInstanceStateConstructor({
  id,
  title = '',
  explanation = '',
  image,
  questionIsRequired = true,
  answerVisibility,
  visibility,
}: {
  id: string;
  questionIsRequired?: boolean;
  title?: string | (() => string) | I18n.MultilangFormState;
  explanation?: string | I18n.MultilangFormState;
  image?: M.Question['image'];
  visibility?: M.QuestionVisibility;
  answerVisibility?: M.QuestionAnswerVisibility;
}): SharedInstanceStateConstructor {
  const questionTextState = I18n.isMultilangFormState(title)
    ? title
    : I18n.makeMultilangFormState(
        makeFormElementState(title, [nonEmptyString(isRequired)]),
      );

  const questionExplanationState = I18n.isMultilangFormState(explanation)
    ? explanation
    : I18n.makeMultilangFormState(makeFormElementState(explanation));

  const isRequiredState = makeFormElementState<boolean>(questionIsRequired);

  return (
    formEntitiesUnit: AbstractStateUnit<FormEntityState[]> = makePrimaryUnit(
      [],
    ),
  ) => {
    const hasErrorUnit = makeDerivedUnit(
      makeMappingUnitFromUnit(
        makeDerivedUnit(formEntitiesUnit).getUnit(formEntities => {
          return [...formEntities, questionTextState.formElementState].map(
            x => x.units.error,
          );
        }),
      ),
    ).getUnit(errors => errors.some(x => x !== null));

    return {
      id,
      questionText: questionTextState,
      questionExplanation: questionExplanationState,
      isRequired: isRequiredState,
      hasErrorUnit,
      makeSharedServerQuestion: (stepKey: QuestionStepKey) => {
        return makeSharedServerQuestion({
          questionTextState,
          questionExplanationState,
          isRequiredState,
          id,
          stepKey,
          image,
          answerVisibility,
          visibility,
        });
      },
      validate: () => {
        return validateInstance(
          questionTextState.formElementState,
          formEntitiesUnit,
        );
      },
      isValid: () =>
        [
          questionTextState.formElementState,
          ...formEntitiesUnit.getState(),
        ].every(x => x.formNode.isValid()),
    };
  };
}
