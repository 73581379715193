import React, { useCallback, useLayoutEffect, useRef } from 'react';

import { makePrimaryUnit, PrimaryStateUnit } from 'utils/State';
import { block } from 'utils/classname';

import './style.scss';

const b = block('read-more-text');

type Props = React.PropsWithChildren<{
  minLines: number;
  maxLines: number;
  isExpandableUnit: PrimaryStateUnit<boolean>;
  isCollapsedUnit: PrimaryStateUnit<boolean>;
}>;

export function makeIsExpandableUnit(initialState: boolean = false) {
  return makePrimaryUnit(initialState);
}

export function makeIsCollapsedUnit(initialState: boolean = true) {
  return makePrimaryUnit(initialState);
}

function ReadMoreText({
  minLines,
  maxLines,
  children,
  isExpandableUnit,
  isCollapsedUnit,
}: Props) {
  const isCollapsed = isCollapsedUnit.useState();

  const ref = useRef<HTMLDivElement>(null);

  const handleResize = useCallback(
    (entries: ResizeObserverEntry[]) => {
      entries.forEach(entry => {
        if (entry.target.scrollHeight > entry.contentRect.height) {
          isExpandableUnit.setState(true);
        } else if (isCollapsedUnit.getState()) {
          isExpandableUnit.setState(false);
        }
      });
    },
    [isExpandableUnit, isCollapsedUnit],
  );

  useLayoutEffect(() => {
    const observer = new ResizeObserver(handleResize);

    if (ref.current !== null) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [handleResize]);

  return (
    <div
      className={b()}
      ref={ref}
      style={{
        ['--lines' as any]: isCollapsed ? minLines : maxLines,
      }}
    >
      {children}
    </div>
  );
}

export const Component = React.memo(ReadMoreText) as typeof ReadMoreText;
