import React, { useEffect, useMemo } from 'react';

import { ProjectPageRouteTree } from 'pages/routeTree';
import { I18n } from 'services';
import { useRequiredContext } from 'utils/react/RequiredContext';

import { ConstructorConfigContext } from '../../config/configContext';
import i18nData from '../../i18n.json';
import { ConstructorStepsContext } from '../../steps/stepsContext';
import { ConstructorHeader } from '../../subfeatures';
import { projectNameFormElementState } from '../../units';
import { ProjectWriteContext } from '../ProjectWriteContext';
import * as buttons from '../buttons';

type Props = {
  projectPageRouteTree?: ProjectPageRouteTree;
};

function usePlaceholder() {
  return I18n.useText(i18nData).introProjectName.inputPlaceholder;
}

function Header({ projectPageRouteTree }: Props) {
  const {
    mode,
    headerContentUnit,
    projectUUID,
    onProjectSave,
    onQuestionnaireDelete,
    getProjectUUID,
    useProjectUUID,
  } = useRequiredContext(ConstructorConfigContext);
  const {
    stepKeyToStep,
    steps,
    stepsSections,
    activeStepUnit,
    isLastStepUnit,
  } = useRequiredContext(ConstructorStepsContext);
  const { saveProject, saveProjectWithoutDebounce, status, callStateUnit } =
    useRequiredContext(ProjectWriteContext);

  const Providers: ConstructorHeader.Props['Providers'] = useMemo(() => {
    return React.memo(({ children }) => {
      return (
        <ConstructorConfigContext.Provider
          mode={mode}
          headerContentUnit={headerContentUnit}
          projectUUID={projectUUID}
          projectPageRouteTree={projectPageRouteTree}
          onProjectSave={onProjectSave}
          onQuestionnaireDelete={onQuestionnaireDelete}
          getProjectUUID={getProjectUUID}
          useProjectUUID={useProjectUUID}
        >
          <ConstructorStepsContext.Provider
            steps={steps}
            stepKeyToStep={stepKeyToStep}
            stepsSections={stepsSections}
            activeStepUnit={activeStepUnit}
            isLastStepUnit={isLastStepUnit}
          >
            <ProjectWriteContext.Provider
              status={status}
              saveProject={saveProject}
              saveProjectWithoutDebounce={saveProjectWithoutDebounce}
              callStateUnit={callStateUnit}
            >
              {children}
            </ProjectWriteContext.Provider>
          </ConstructorStepsContext.Provider>
        </ConstructorConfigContext.Provider>
      );
    });
  }, [
    activeStepUnit,
    callStateUnit,
    getProjectUUID,
    headerContentUnit,
    isLastStepUnit,
    mode,
    onProjectSave,
    onQuestionnaireDelete,
    projectPageRouteTree,
    projectUUID,
    saveProject,
    saveProjectWithoutDebounce,
    status,
    stepKeyToStep,
    steps,
    stepsSections,
    useProjectUUID,
  ]);

  const ButtonsFragment = useMemo(() => {
    return React.memo(() => (
      <buttons.Buttons.Component
        projectPageRouteTree={projectPageRouteTree}
        kind="inside-of-header"
      />
    ));
  }, [projectPageRouteTree]);

  useEffect(() => {
    return projectNameFormElementState.units.value.subscribe({
      name: 'project-name-changed',
      callback: (newState, preState) => {
        if (newState !== preState) {
          saveProject();
        }
      },
    });
  }, [saveProject]);

  useEffect(() => {
    return () => {
      projectNameFormElementState.formNode.reset();
    };
  }, []);

  return (
    <ConstructorHeader.Component
      Providers={Providers}
      ButtonsFragment={ButtonsFragment}
      formElementState={projectNameFormElementState}
      usePlaceholder={usePlaceholder}
    />
  );
}

export const Component = React.memo(Header);
