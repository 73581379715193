import {
  CallState,
  ErrorState,
  PendingState,
  SuccessfulState,
  CallStateData,
} from './types';

export function makeCallStateUnitsDeriver(success: 'every' | 'partial') {
  return <
    T extends CallState<unknown>[],
    R extends {
      [key in keyof T]: T[key] extends CallState<unknown>
        ? CallStateData<T[key]>
        : never;
    },
  >(
    ...states: T
  ): CallState<R> => {
    const stateWithPending = states.find(
      (state): state is PendingState => state.kind === 'pending',
    );

    if (stateWithPending !== undefined) {
      return { ...stateWithPending };
    }

    const stateWithError = states.find(
      (state): state is ErrorState => state.kind === 'error',
    );

    if (stateWithError !== undefined) {
      return { ...stateWithError };
    }

    if (
      (() => {
        switch (success) {
          case 'every': {
            return (
              states.length > 0 &&
              states.every(state => state.kind === 'successful')
            );
          }
          case 'partial': {
            return states.find(x => x.kind === 'successful') !== undefined;
          }
        }
      })()
    ) {
      return {
        kind: 'successful',
        data: (states as SuccessfulState<R[number]>[]).reduce(
          (acc, state) => [...acc, state.data] as R,
          [] as unknown as R,
        ),
      };
    }

    return { kind: 'initial' };
  };
}
