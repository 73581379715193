/* eslint-disable react-hooks/rules-of-hooks */
import React, { useContext } from 'react';
import { useLocation } from 'react-router';

import { Link } from 'components';
import { block, classnames } from 'utils/classname';

import { MobileLinkDependenciesContext } from '../../../../MobileLinkDependencies';
import * as Types from '../../../../types';
import { isLinkEntryActive } from '../../../../utils';
import './style.scss';

const b = block('m-main-menu-child-entry');

type Props<T = Types.ChildEntry> = {
  childEntry: T;
  className: string;
  bold: boolean;
};

const LinkEntry = React.memo(
  ({ childEntry, className, bold }: Props<Types.LinkEntry>) => {
    const { onLinkClick } = useContext(MobileLinkDependenciesContext);

    const location = useLocation();

    const isActive = isLinkEntryActive(childEntry, location);

    return (
      <Link.Component
        className={classnames(b({ active: isActive }), className)}
        href={childEntry.href}
        size="l"
        weight={bold ? 'bold' : 'normal'}
        onClick={onLinkClick}
      >
        {childEntry.label}
      </Link.Component>
    );
  },
);

function ChildEntry({ childEntry, className, bold }: Props) {
  switch (childEntry.kind) {
    case 'link':
      return (
        <LinkEntry childEntry={childEntry} className={className} bold={bold} />
      );
    case 'action':
      return (
        <div
          className={classnames(b({ bold }), className)}
          onClick={childEntry.onSelect}
        >
          {childEntry.label}
        </div>
      );
  }
}

export const Component = React.memo(ChildEntry);
