import React from 'react';

import { block } from 'utils/classname';

import './style.scss';

const b = block('label');

type Props = {
  useLabel(): string;
};

function LeftPart({ useLabel }: Props) {
  return <div className={b()}>{useLabel()}</div>;
}

const Component = React.memo(LeftPart) as typeof LeftPart;

export const makeComponent = (props: Props) => () => <Component {...props} />;
