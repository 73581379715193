import React, { useCallback } from 'react';

import { Button, Modal } from 'components';
import i18nData from 'features/project/Constructor/i18n.json';
import { Classifications } from 'features/project/Constructor/subfeatures';
import { I18n } from 'services';
import {
  makeDerivedUnit,
  makeMappingUnitFromUnit,
  PrimaryStateUnit,
} from 'utils/State';
import { block } from 'utils/classname';

import {
  classificationsStateUnit,
  classificationsUnit,
} from '../../../units/inputState';
import './style.scss';

const b = block('project-constructor-classifications-section-modal');

type Props = {};

export const isOpenUnit = Modal.makeIsOpenUnit();

const mappingUnit = makeMappingUnitFromUnit(
  makeDerivedUnit(classificationsStateUnit).getUnit(state =>
    Object.entries(state).map<[string, PrimaryStateUnit<boolean>]>(
      ([key, x]) => [key, x.value.units.value],
    ),
  ),
);

const Header = Modal.Header.makeFromTitle(() => {
  return I18n.useText(i18nData).steps.advancedSettings.section.classifications
    .modal.header;
});

function Subjects({}: Props) {
  const text =
    I18n.useText(i18nData).steps.advancedSettings.section.classifications.modal;
  const classifications = classificationsUnit.useState();

  const handleAcceptButtonClick = useCallback(() => {
    const selectedUUIDs = mappingUnit
      .getState()
      .filter(([, x]) => x)
      .map(([key]) => key);
    classificationsUnit.setState(selectedUUIDs);
    isOpenUnit.setState(false);
  }, []);

  const handleCloseButtonClick = useCallback(() => {
    isOpenUnit.setState(false);
  }, []);

  return (
    <Modal.Component
      isOpenUnit={isOpenUnit}
      size="m"
      className={b()}
      Header={Header}
    >
      <Modal.Layout.Default.Component as="div">
        <div className={b('content')}>
          <Classifications.Component
            classification={process.env.RAZZLE_SUBJECT_CLASSIFIER}
            stateUnit={classificationsStateUnit}
            initialValues={classifications}
          />
        </div>
        <Modal.Footer.Layout.Controls.Component gap="m" justifyContent="start">
          <Button.Component type="button" onClick={handleAcceptButtonClick}>
            {text.button.accept}
          </Button.Component>
          <Button.Component
            variant="outlined"
            color="secondary"
            type="button"
            onClick={handleCloseButtonClick}
          >
            {text.button.cancel}
          </Button.Component>
        </Modal.Footer.Layout.Controls.Component>
      </Modal.Layout.Default.Component>
    </Modal.Component>
  );
}

export const Component = React.memo(Subjects);
