import * as R from 'ramda';
import React from 'react';

import { ConstructorConfigContext } from 'features/project/Constructor/config/configContext';
import { ConstructorFormSection } from 'features/project/Constructor/subfeatures';
import { makePrimaryUnit } from 'utils/State';
import { block } from 'utils/classname';
import { useRequiredContext } from 'utils/react/RequiredContext';

import { Step } from '../../types';
import { makeStepErrorsUnit } from '../../utils';
import * as sections from './sections';
import './style.scss';
import {
  anonymousQuestionnairesSwitchState,
  authorsUnit,
  hasSomeInputUnit,
  initialPrivateAccessGroupsState,
  invitedAuthorsUnit,
  invitedSupervisorsUnit,
  privateAccessGroupsState,
  privateAccessSwitchState,
  supervisorsUnit,
  visitedUnit,
} from './units';

const b = block('access-section');

type Props = {};

function Access({}: Props) {
  const { mode } = useRequiredContext(ConstructorConfigContext);

  const privateAccessIsOn = privateAccessSwitchState.units.value.useState();

  return (
    <ConstructorFormSection.Component className={b()}>
      <sections.Leadership.Component />
      {mode === 'full' && (
        <>
          <sections.PrivateAccess.Component />
          {privateAccessIsOn && <sections.Groups.Component />}
        </>
      )}
      <sections.AnonymousQuestionnaires.Component />
    </ConstructorFormSection.Component>
  );
}

const Component = React.memo(Access) as typeof Access;

export const step: Step = {
  key: 'access',
  Form: Component,
  errorsUnit: makeStepErrorsUnit(),
  visitedUnit,
  progressInvariantUnitsUnit: makePrimaryUnit([]),
  hasSomeInputUnit,
  setInitialState: mode => {
    anonymousQuestionnairesSwitchState.units.value.setState(mode === 'compact');
  },
  getProjectData: ({ serverProject }) => {
    const privateAccess = privateAccessSwitchState.getValue();
    const privateAccessGroups = privateAccessGroupsState.getState();
    const allowAnonymousData = anonymousQuestionnairesSwitchState.getValue();

    const getGroupAccess = () => {
      if (!privateAccess) {
        return [];
      }
      if (
        privateAccessGroups === initialPrivateAccessGroupsState &&
        serverProject
      ) {
        return serverProject.group_access;
      }
      return R.uniq([
        ...privateAccessGroups
          .filter(x => x.checkboxState.units.value.getState())
          .map(x => x.group.uuid),
        ...(serverProject?.group_access?.filter(uuid => {
          const privateAccessGroup = privateAccessGroups.find(
            x => uuid === x.group.uuid,
          );
          if (privateAccessGroup) {
            return privateAccessGroup.checkboxState.getValue();
          }
          return true;
        }) || []),
      ]);
    };

    return {
      private: privateAccess,
      group_access: getGroupAccess(),
      allow_anonymous_data: allowAnonymousData,
    };
  },
  fillFromExistingProject: ({ project, mode }) => {
    authorsUnit.setState(Object.values(project.author));
    supervisorsUnit.setState(Object.values(project.supervisor || {}));

    privateAccessSwitchState.units.value.setState(!!project.private);
    if (
      privateAccessGroupsState.getState() === initialPrivateAccessGroupsState
    ) {
      const unsubscribe = privateAccessGroupsState.subscribe({
        name: 'checkbox-state-updater',
        callback: groupsState => {
          groupsState.forEach(groupState => {
            if (project.group_access?.includes(groupState.group.uuid)) {
              groupState.checkboxState.units.value.setState(true);
            }
          });
          unsubscribe();
        },
      });
    }
    anonymousQuestionnairesSwitchState.units.value.setState(
      project.allow_anonymous_data ?? mode === 'compact',
    );
  },
  resetState: () => {
    authorsUnit.resetState();
    supervisorsUnit.resetState();
    privateAccessGroupsState.resetState();
    privateAccessSwitchState.formNode.reset();
    invitedAuthorsUnit.resetState();
    invitedSupervisorsUnit.resetState();
    anonymousQuestionnairesSwitchState.formNode.reset();
    visitedUnit.resetState();
  },
};
