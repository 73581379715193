import React from 'react';

import { useRequiredContext } from 'utils/react/RequiredContext';

import { SavingIndicator } from '../../subfeatures';
import { ProjectWriteContext } from '../ProjectWriteContext';

type Props = {};

function WriteIndicator({}: Props) {
  const { callStateUnit } = useRequiredContext(ProjectWriteContext);

  return <SavingIndicator.Component callStateUnit={callStateUnit} />;
}

export const Component = React.memo(WriteIndicator);
