import * as M from 'types/serverModels';
import { MappedState } from 'utils/State';

import { makeServerSet } from './makeServerSet';
import { SetForRequest } from './types';

type DescriptorSets = Pick<
  M.VennChartWidgetDescriptor,
  'set1' | 'set2' | 'set3' | 'set4'
>;

export function makeServerDescriptorSets(
  sets: Array<MappedState<SetForRequest>>,
): DescriptorSets {
  return sets.reduce<DescriptorSets>(
    (acc, x, index) => ({
      ...acc,
      [`set${index + 1}`]: makeServerSet(x),
    }),
    {} as DescriptorSets,
  );
}
