import React, { useEffect } from 'react';

import { Switch } from 'components';
import { ConstructorStepSection } from 'features/project/Constructor/subfeatures';
import { I18n } from 'services';
import { useRequiredContext } from 'utils/react/RequiredContext';

import i18nData from '../../../../i18n.json';
import { ProjectWriteContext } from '../../../ProjectWriteContext';
import { EditError } from '../../../modals';
import { useIsEditAccess } from '../../shared';
import { isTableSwitchState } from '../units/inputState';

type Props = {};

function HeaderRightPart() {
  const { isEditAccess, errorCode } = useIsEditAccess();

  const onChangePredicate = () => {
    if (!isEditAccess) {
      EditError.errorCodeUnit.setState(errorCode);
      return;
    }
    isTableSwitchState.units.value.setState(prevState => !prevState);
  };

  return (
    <Switch.Component
      formElementState={isTableSwitchState}
      onChangePredicate={onChangePredicate}
    />
  );
}

function ModeSwitch({}: Props) {
  const text = I18n.useText(i18nData).steps.inputDataForm.mode;

  const { saveProject } = useRequiredContext(ProjectWriteContext);

  useEffect(() => {
    return isTableSwitchState.units.value.subscribe({
      name: 'mode-changed',
      callback: () => {
        saveProject();
      },
    });
  }, [saveProject]);

  return (
    <ConstructorStepSection.Component
      title={text.label}
      subtitle={text.explanation}
      HeaderRightPart={HeaderRightPart}
    />
  );
}

export const Component = React.memo(ModeSwitch);
