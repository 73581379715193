import { MathJax } from 'better-react-mathjax';

import { makeFormElementState } from 'utils/FormState';
import { MappedState } from 'utils/State';

import { StateInstance } from '../types';

type Options = {
  item: MappedState<StateInstance['rightColumn']['elementsUnit']>[number];
  initState(
    item: MappedState<StateInstance['rightColumn']['elementsUnit']>[number],
  ): boolean;
};

export function makeMatchingRightColumnElement({ item, initState }: Options) {
  return {
    value: makeFormElementState(initState(item)),
    item: item,
    useLabel: () => (
      <MathJax dynamic>
        {item.text.formElementState.units.value.useState()}
      </MathJax>
    ),
  };
}
