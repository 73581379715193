import { FindingsSettings } from '../../steps';

function getChartWidgetQuestionIDs(
  widget: FindingsSettings.chartWidgets.Instance,
): string[] {
  return [
    (() => {
      const xAxisData = widget.xAxisData.units.value.getState();
      return xAxisData?.kind === 'question' ? xAxisData.questionID : null;
    })(),
    (() => {
      const yAxisData = widget.yAxisData.units.value.getState();
      return yAxisData?.kind === 'question' ? yAxisData.questionID : null;
    })(),
    widget.cuttingQuestion.units.value.getState(),
  ].filter((x): x is string => x !== null);
}

export function widgetHasAssociatedQuestion(
  widget: FindingsSettings.FindingsWidgetInstance,
  questionID: string,
): boolean {
  switch (widget.kind) {
    case 'area':
    case 'pie':
    case 'column':
    case 'columnStack':
    case 'line':
    case 'scatter':
      return getChartWidgetQuestionIDs(widget).includes(questionID);
    case 'dataList':
      return false;
    case 'filesList':
    case 'textsCloud':
    case 'textsList':
    case 'gallery':
      return widget.questionID.units.value.getState() === questionID;
    case 'map':
      return widget.layers
        .getState()
        .some(
          x =>
            x.kind !== 'without-selected-question' &&
            x.question.id === questionID,
        );
    case 'venn':
      return widget.sets
        .getState()
        .some(
          x =>
            x.kind !== 'without-selected-question' &&
            x.question.id === questionID,
        );
    case 'timeline':
      return (
        widget.startDateQuestionID.units.value.getState() === questionID ||
        widget.endDateQuestionID.units.value.getState() === questionID ||
        widget.headlineQuestionID.units.value.getState() === questionID ||
        widget.textQuestionID.units.value.getState() === questionID ||
        widget.mediaQuestionID.units.value.getState() === questionID
      );
  }
}
