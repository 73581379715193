import React from 'react';

import { b } from '../b';

type Props = {};

function Header({ children }: React.PropsWithChildren<Props>) {
  return <div className={b('header')}>{children}</div>;
}

export const Component = React.memo(Header) as typeof Header;
