import { makeFeature } from 'services/Feature';

import i18nData from './i18n.json';
import * as subfeatures from './subfeatures';

export const feature = makeFeature({
  name: 'WidgetFilter',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "widget-filter" */
      './Component'
    ),
  i18nSharedReferences: null,
  i18nData,
  subfeatures: Object.values(subfeatures).map(x => x.feature),
});

export const Component = feature.Component;
export { makeState } from './makeState';
export { makeFilteringQuestionsUnit } from './makeFilteringQuestionsUnit';
export { makeFilterQuestionsMappingUnit } from './makeFilterQuestionsMappingUnit';
export { makeStateFromServerData } from './makeStateFromServerData';
export { makeFilterStateUnitForRequest } from './makeFilterStateUnitForRequest';
export { makeServerFilter } from './makeServerFilter';
export { makeServerFilterFromState } from './makeServerFilterFromState';
export { makeInitialStateFromServerFilter } from './makeInitialStateFromServerFilter';
export { FullscreenContext } from './FullscreenContext';
export type {
  State,
  FilteringQuestion,
  VariantSelectionAnswer,
  FilterStateForRequest,
  InitialState,
} from './types';
