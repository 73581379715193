import {
  createRequiredContext,
  useRequiredContext,
} from 'utils/react/RequiredContext';

import { QuestionsConfigContext } from '../../QuestionsConfigContext';
import { CachedQuestionInstance, QuestionConstructorWidget } from '../../types';

export type QuestionWidgetContextData = {
  cachedInstance: CachedQuestionInstance;
  constructorWidget: QuestionConstructorWidget<any, any, any>;
};

export const QuestionWidgetContext =
  createRequiredContext<QuestionWidgetContextData>();

type Props = {
  cachedInstance: CachedQuestionInstance;
};

export function QuestionWidgetContextProvider({
  cachedInstance,
  children,
}: React.PropsWithChildren<Props>) {
  const questionKey = cachedInstance.activeQuestionKeyUnit.useState();
  const { questionKeyToConstructorWidget } = useRequiredContext(
    QuestionsConfigContext,
  );

  const constructorWidget = questionKeyToConstructorWidget[questionKey];

  return (
    <QuestionWidgetContext.Provider
      cachedInstance={cachedInstance}
      constructorWidget={constructorWidget}
    >
      {children}
    </QuestionWidgetContext.Provider>
  );
}
