import React from 'react';

import { Page } from 'services';
import * as M from 'types/serverModels';
import { block } from 'utils/classname';

import { routeTree } from '../../routeTree';
import * as features from './features';

const b = block('user-profile-courses-page');

type Props = {
  user: M.User;
};

function Courses({ user }: Props) {
  return (
    <div className={b()}>
      <features.Courses.Component user={user} />
    </div>
  );
}

export const Component = Page.makePage({
  path: routeTree.LANG.users.USER.courses.getPath(),
  routeProps: { exact: true },
  scrollTo: 'none',
  Component: React.memo(Courses),
  features: Object.values(features).map(x => x.feature),
});
