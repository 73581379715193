import React from 'react';

import { ConstructorConfigContext } from 'features/project/Constructor/config/configContext';
import { serverProjectDataUnit } from 'features/project/Constructor/units';
import { ProjectPageRouteTree } from 'pages/routeTree';
import { PROJECT_STATUS, USER_ROLE } from 'shared/constants';
import { userRoleUnit } from 'shared/stateUnits';
import { useRequiredContext } from 'utils/react/RequiredContext';

import * as Next from '../Next';
import * as Preview from '../Preview';
import * as Publish from '../Publish';

type Props = {
  projectPageRouteTree?: ProjectPageRouteTree;
  kind: 'regular' | 'inside-of-header';
};

function Buttons({ projectPageRouteTree, kind }: Props) {
  const serverProject = serverProjectDataUnit.useState();
  const userRole = userRoleUnit.useState();
  const canBePublished =
    serverProject === null ||
    serverProject.status === PROJECT_STATUS.draft ||
    (userRole === USER_ROLE.moderator &&
      serverProject.status === PROJECT_STATUS.published);

  const { mode } = useRequiredContext(ConstructorConfigContext);

  const isRegular = kind === 'regular';

  return (
    <>
      {isRegular && <Next.Component />}
      {serverProject !== null && (
        <Preview.Component projectPageRouteTree={projectPageRouteTree} />
      )}
      {isRegular && canBePublished && mode === 'full' && <Publish.Component />}
    </>
  );
}

export const Component = React.memo(Buttons);
