import React from 'react';

import { Page } from 'services';

import { routeTree } from '../../../routeTree';
import * as features from './features';

type Props = features.History.Props;

function History(props: Props) {
  return <features.History.Component {...props} />;
}

export const path = [
  routeTree.LANG['project-book'].list.PROJECT_BOOK.history.getPath(),
  routeTree.LANG['project-book'].list.PROJECT_BOOK.users.USER.history.getPath(),
];

export const Component = Page.makePage({
  path,
  routeProps: { exact: true },
  scrollTo: 'none',
  Component: React.memo(History) as typeof History,
  features: Object.values(features).map(x => x.feature),
}) as typeof History;
