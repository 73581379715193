import { LocationSubstitute, PatternSubstitute } from './types';

const fromLocationSubstitute: LocationSubstitute = {
  kind: 'from-location',
};

const fromPatternSubstitute: PatternSubstitute = {
  kind: 'from-pattern',
};

export const substitutes = {
  fromLocation: fromLocationSubstitute,
  fromPattern: fromPatternSubstitute,
};

export { makeRouteTree } from './makeRouteTree';
export {
  makeEnumeratedNodesDescription,
  makeSearchParamsDescription,
  makeHashParamDescription,
  makeInfiniteNodesDescription,
} from './nodes';
export * from './types';
