import * as TS from 'types';
import * as M from 'types/serverModels';

import { makeService } from '../utils';

type InputData = M.EmulateParamsHolder & {
  question: string;
  limit: 100;
  filter: M.Filter | null;
};

type APIOutputData = {
  data: TS.WordCloudDataItem[];
};

export const wcloud = makeService<
  InputData,
  APIOutputData,
  TS.WordCloudDataItem[]
>(
  (data, convertToSearchParams) =>
    `data/wcloud/?${convertToSearchParams({
      ...data,
      filter: JSON.stringify(data.filter),
    })}`,
  'api-v2-get',
  false,
  ({ data }) => data,
);
