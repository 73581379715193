import React, { useMemo, useCallback, useEffect } from 'react';

type Props = {
  containerRef: React.RefObject<HTMLDivElement>;
  headerRef: React.RefObject<HTMLHeadingElement>;
};

function HeaderResizeObserver({ containerRef, headerRef }: Props) {
  const update = useCallback(() => {
    if (containerRef.current === null || headerRef.current === null) {
      return;
    }

    const { height } = headerRef.current.getBoundingClientRect();
    const heightStyle = `${height}px`;

    containerRef.current.style.setProperty('--header-height', heightStyle);
  }, [containerRef, headerRef]);

  const handleResize = useCallback(() => {
    update();
  }, [update]);

  const resizeObserver = useMemo(
    () => new ResizeObserver(handleResize),
    [handleResize],
  );

  useEffect(() => {
    update();
  }, [update]);

  useEffect(() => {
    if (headerRef.current === null) {
      return;
    }

    resizeObserver.observe(headerRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [headerRef, resizeObserver]);

  return null;
}

export const Component = React.memo(HeaderResizeObserver);
