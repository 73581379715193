import React from 'react';

import { Checkbox, InfoPopup } from 'components';
import { I18n } from 'services';
import { FormElementState, useFormElementState } from 'utils/FormState';

import i18nData from './i18n.json';

export const useFieldState = () => {
  return useFormElementState<boolean>(false);
};

type Props = {
  formElementState: FormElementState<boolean>;
};

const Label = Checkbox.makeLabel({
  useLabel: () => I18n.useText(i18nData).label,
  size: 'text-14',
});

const Body = () => <>{I18n.useText(i18nData).tooltip}</>;
const Extension = () => <InfoPopup.Component Body={Body} />;

function RoleCheckbox({ formElementState }: Props) {
  return (
    <Checkbox.Component
      formElementState={formElementState}
      Label={Label}
      Extension={Extension}
    />
  );
}

export const Component = React.memo(RoleCheckbox);
