import { API } from 'services';
import { UUID } from 'types/serverModels';
import { makePrimaryUnit } from 'utils/State';

import { StepKey } from './types';

export const openedStepPreviewUnit = makePrimaryUnit<StepKey | null>(null);

export const materialsInNonProjectLanguage = makePrimaryUnit<UUID[]>([]);
export const allMaterialsCallState =
  makePrimaryUnit<API.CallState<any>['kind']>('initial');
