import * as M from 'types/serverModels';

import { makeService } from '../utils';

type InputData = {
  uuid: M.UUID;
  status: M.ProjectStatus;
};

type APIOutputData = {
  project: M.Project;
};

export const status = makeService<InputData, APIOutputData, M.Project>(
  ({ uuid }) => `project/${uuid}/status`,
  'api-v2-put',
  false,
  ({ project }) => project,
  {
    contentType: 'application/x-www-form-urlencoded',
    extractBodyInput: ({ status }) => ({ status }),
  },
);
