import { createRequiredContext } from 'utils/react/RequiredContext';

import { ConstructorWidgetCachedInstance } from '../types';

export type WidgetContextData = {
  widgetInstanceViewKind: ConstructorWidgetCachedInstance['kind'];
  onDelete(): void;
  onMoveNext(): void;
  onMovePrev(): void;
};

export const WidgetContext = createRequiredContext<WidgetContextData>();
