import React from 'react';

import { RoundedListItemMark } from 'components';

type Props = {
  value: number;
};

function WidgetNumberMark({ value }: Props) {
  return (
    <RoundedListItemMark.Component variant="outlined">
      {value}
    </RoundedListItemMark.Component>
  );
}

const Component = React.memo(WidgetNumberMark);

export const makeComponent = (props: Props) => () => <Component {...props} />;
