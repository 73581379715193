import { makeFeature } from 'services/Feature';

import i18nData from './i18n.json';
import * as i18nSharedReferences from './i18nSharedReferences';
import * as subfeatures from './subfeatures';

export const feature = makeFeature({
  name: 'PasswordResetPageForm',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "password-reset-page-form" */
      './Component'
    ),
  i18nData,
  i18nSharedReferences: Object.values(i18nSharedReferences),
  subfeatures: Object.values(subfeatures).map(x => x.feature),
});

export const Component = feature.Component;
