import i18nData from 'features/project/Constructor/i18n.json';
import { Filter } from 'features/project/Constructor/subfeatures';
import { getProjectLanguage } from 'features/project/Constructor/utils';
import { I18n } from 'services';
import * as TS from 'types';
import * as M from 'types/serverModels';
import { makeFormSectionState } from 'utils/FormState';
import {
  makeDerivedUnit,
  makeMappingUnit,
  makeMappingUnitFromUnit,
  makePrimaryUnit,
  PrimaryStateUnit,
} from 'utils/State';
import { makeUUID } from 'utils/UUID';
import { Layer } from 'utils/business';
import { nonEmptyArray } from 'utils/validators';

import { flatQuestionInstancesUnit } from '../../../InputDataForm/units/instances';
import { Map } from '../../../shared/Question';
import { ConstructorWidgetConfigForFindingsWidget } from '../../types';
import { makeSharedInstancePart } from '../makeSharedInstancePart';
import { SharedConstructorArgs } from '../shared/SharedConstructorArgs';
import * as DataSettings from './DataSettings';
import * as Layers from './Layers';
import * as View from './View';
import { MapInstance } from './types';

type ServerWidgetConstructorArgs = {
  filter: Filter.State;
  layersUnit: PrimaryStateUnit<Layer.Layer[]>;
  selectedImageUnit: PrimaryStateUnit<string | null>;
};

export function makeServerWidget(
  args: ServerWidgetConstructorArgs,
): Partial<M.MapWidget> {
  const { filter, layersUnit, selectedImageUnit } = args;
  const selectedImage = selectedImageUnit.getState();
  return {
    type: 'map',
    descriptor: {
      filter: Filter.makeServerFilterFromState(filter),
      layers: layersUnit
        .getState()
        .filter(Layer.isWithSelectedQuestion)
        .map(x => Layer.makeServerLayer(x, getProjectLanguage())),
      background: selectedImage ? selectedImage : undefined,
    },
  };
}

const atLeastOneLayerRequiredReference = I18n.makeEntryReference(
  i18nData,
  data =>
    data.steps.findingsSettings.widgets.map.errors.atLeastOneLayerRequired,
);

function makeInstance({
  initialLayers = [{ kind: 'without-selected-question', id: makeUUID() }],
  initialImage = null,
  ...sharedArgs
}: {
  initialLayers?: Layer.Layer[];
  initialImage?: string | null;
} & SharedConstructorArgs): MapInstance {
  const layersUnit = makePrimaryUnit<Layer.Layer[]>(initialLayers);
  const layersFormSectionStateUnit = makeFormSectionState(layersUnit, [
    nonEmptyArray(atLeastOneLayerRequiredReference),
  ]);

  const activeLayerIndexUnit = makePrimaryUnit(0);

  const questionsSettingsUnit = makeDerivedUnit(
    makeMappingUnitFromUnit(
      makeDerivedUnit(flatQuestionInstancesUnit).getUnit<TS.QuestionSettings[]>(
        question =>
          question
            .filter((x): x is Map.StateInstance => x.kind === 'map')
            .map(x => ({
              questionText: x.questionText.formElementState.units.value,
              image: x.image.units.value,
            })) as any,
      ),
    ),
  ).getUnit(x => x.filter(x => x.image));

  const selectedImageUnit = makePrimaryUnit(initialImage);

  const selectedQuestionsUnit = makePrimaryUnit(
    Layer.makeSelectedQuestionsMapping(initialLayers),
  );
  const formEntitiesUnit = makeDerivedUnit(
    makeMappingUnit([selectedQuestionsUnit, layersUnit] as const),
  ).getUnit(([selectedQuestions, layers]) => [
    ...Object.values(selectedQuestions).map(x => x),
    ...layers.flatMap(layer => {
      switch (layer.kind) {
        case 'scale':
          return [layer.scaleRange.from, layer.scaleRange.to];
        default:
          return [];
      }
    }),
    layersFormSectionStateUnit,
  ]);

  return {
    kind: 'map',
    id: sharedArgs.id,
    layers: layersUnit,
    activeLayerIndex: activeLayerIndexUnit,
    selectedQuestions: selectedQuestionsUnit,
    selectedImage: selectedImageUnit,
    questionSettings: questionsSettingsUnit,
    makeServerWidget() {
      return makeServerWidget({
        filter: this.filter,
        layersUnit,
        selectedImageUnit,
      });
    },
    ...makeSharedInstancePart({
      ...sharedArgs,
      formEntitiesUnit,
    }),
  };
}

export const constructorWidgetConfig: ConstructorWidgetConfigForFindingsWidget<
  'map',
  MapInstance,
  typeof makeInstance
> = {
  key: 'map',
  icon: 'map',
  makeInstance,
  DataSettings: DataSettings.Component,
  Layers: Layers.Component,
  View: View.Component,
  viewHeight: 500,
};

export type { MapInstance };
