import { useMemo } from 'react';

import { I18n } from 'services';
import * as M from 'types/serverModels';

type References = Record<M.Unit['type'], I18n.EntryReference>;

export function useTypeText(references: References) {
  const presentationText = I18n.useReference(references.presentation);
  const webText = I18n.useReference(references.web);
  const testText = I18n.useReference(references.test);
  const videoText = I18n.useReference(references.video);
  const interactiveText = I18n.useReference(references.interactive);
  const printText = I18n.useReference(references.print);
  const projectText = I18n.useReference(references.project);
  const meschEOMText = I18n.useReference(references.mesch_eom);

  const text = useMemo(() => {
    return {
      presentation: presentationText,
      web: webText,
      test: testText,
      video: videoText,
      interactive: interactiveText,
      print: printText,
      project: projectText,
      mesch_eom: meschEOMText,
    };
  }, [
    presentationText,
    webText,
    testText,
    videoText,
    interactiveText,
    printText,
    projectText,
    meschEOMText,
  ]);

  return text;
}
