import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  project?: string;
};

export const userProjectList = makeService<InputData, M.UserData[]>(
  'user_project_list',
  'get',
);
