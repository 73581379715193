import { useCallback } from 'react';

import { USER_ROLE } from 'shared/constants';
import { userStateUnit } from 'shared/stateUnits';

import { QuestionConstructorWidget, QuestionKey } from './types';

export const useHasAccessToTheFile = () => {
  const userState = userStateUnit.useState();

  const hasAccessToTheFile = useCallback(
    (widget: QuestionConstructorWidget<QuestionKey, any, any>) => {
      if (widget.key !== 'file') {
        return true;
      }
      if (userState.kind !== 'loaded') {
        return false;
      }
      return userState.user.role === USER_ROLE.moderator;
    },
    [userState],
  );

  return hasAccessToTheFile;
};
