import { Text, Preloader } from 'components';
import { DataPrefetcher, notFetched } from 'services/DataPrefetch';

import { CallState, DataComponentConfigurator } from '../../types';

export function makeDataComponentConfigurator<T>(
  useState: () => CallState<T>,
): DataComponentConfigurator<T> {
  function pendingDefault() {
    return <Preloader.Component />;
  }

  function errorDefault(message: string) {
    return <Text.Component color="error">Error {message}</Text.Component>;
  }

  const prevDeps: Record<string, any[]> = {};
  const components: Record<string, React.FC> = {};

  function loop(description: any) {
    return {
      onSuccess: (getJSX: (data: T) => React.ReactNode) =>
        loop({ ...description, success: getJSX }),
      onPending: (getJSX: () => React.ReactNode) =>
        loop({ ...description, pending: getJSX }),
      onError: (getJSX: (message: string) => React.ReactNode) =>
        loop({ ...description, success: getJSX }),
      setDataPrefetcher: (prefetcher: DataPrefetcher<T>) =>
        loop({ ...description, prefetcher }),
      setID: (id: string) => loop({ ...description, id }),
      getComponent: (deps: any[] = []) => {
        // TODO unsure if we need new component on new deps, need to gather cases with non-empty deps

        const { id } = description;
        if (prevDeps[id]) {
          const depsAreEqual = prevDeps[id].reduce<boolean>(
            (acc, x, index) => acc && x === deps[index],
            true,
          );

          if (depsAreEqual) {
            return components[id];
          }
        }

        prevDeps[id] = deps;
        components[id] = () => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const state = useState();

          if (
            description.prefetcher &&
            (description.prefetcher as DataPrefetcher<T>).getData() !==
              notFetched
          ) {
            const data = (
              description.prefetcher as DataPrefetcher<T>
            ).getData();

            if (description.success) {
              return description.success(data);
            }

            console.error('unexpected description state', description);
            return () => null;
          }

          switch (state.kind) {
            case 'pending':
              return description['pending']();
            case 'successful':
              return description['success'](state.data);
            case 'error':
              return description['error'](state.message);
            case 'initial':
              return null;
            default:
              console.error('unexpected call state', state);
              return () => null;
          }
        };

        return components[id];
      },
    };
  }

  const initialData = {
    pending: pendingDefault,
    error: errorDefault,
    success: null,
    prefetcher: null,
    id: '',
  };

  return loop(initialData);
}
