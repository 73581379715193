import React, { useCallback } from 'react';

import { Button } from 'components';
import { StepContext } from 'features/project/Constructor/FormWithSteps/StepContext';
import i18nData from 'features/project/Constructor/i18n.json';
import { QuestionExplanation } from 'features/project/Constructor/subfeatures';
import { I18n } from 'services';
import { block } from 'utils/classname';
import { useRequiredContext } from 'utils/react/RequiredContext';

import {
  CachedQuestionInstance,
  QuestionConstructorWidget,
} from '../../../../types';
import './style.scss';

const b = block('constructor-widget-preview-content-for-question');

type Props = {
  cachedInstance: CachedQuestionInstance;
  constructorWidget: QuestionConstructorWidget<any, any, any>;
};

function PreviewContent({ cachedInstance, constructorWidget }: Props) {
  const stateInstance = cachedInstance.useStateInstance();

  const handleEditButtonClick = useCallback(() => {
    cachedInstance.mode.setState('edit');
  }, [cachedInstance.mode]);

  const text = I18n.useText(i18nData);

  const { step } = useRequiredContext(StepContext);

  return (
    <div className={b()}>
      <QuestionExplanation.Component
        className={b('question-explanation')}
        kind={step.key === 'conclusions' ? 'html' : 'text'}
        questionExplanation={stateInstance.questionExplanation.formElementState.units.value.useState()}
      />
      <constructorWidget.PreviewModeForm instance={stateInstance} />
      <Button.Component
        className={b('edit-button')}
        type="button"
        variant="outlined"
        color="secondary"
        onClick={handleEditButtonClick}
      >
        {text.editButtonLabel}
      </Button.Component>
    </div>
  );
}

export const Component = React.memo(PreviewContent) as typeof PreviewContent;
