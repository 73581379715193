import { units } from '../shared/Materials';

export const instancesUnit = units.makeInstancesUnit();
export const enumeratedInstancesUnit =
  units.makeEnumeratedInstancesUnit(instancesUnit);

export const allMaterialsAreConfirmedUnit =
  units.makeAllMaterialsAreConfirmedUnit(instancesUnit);
export const errorsUnit = units.makeErrorsUnit(allMaterialsAreConfirmedUnit);
export const hasSomeInputUnit = units.makeHasSomeInputUnit(instancesUnit);
export const progressInvariantUnitsUnit = units.makeProgressInvariantUnitsUnit(
  allMaterialsAreConfirmedUnit,
);
