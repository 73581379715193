import { AbstractStateUnit } from 'utils/State';

import { CallState } from './types';

export function makePromiseFromCallStateUnit<T>(
  callStateUnit: AbstractStateUnit<CallState<T>>,
): Promise<T> {
  return new Promise((resolve, reject) => {
    const unsubscribe = callStateUnit.subscribe({
      name: 'promise-mapper',
      callback: state => {
        if (state.kind === 'successful') {
          resolve(state.data);
          unsubscribe();
        } else if (state.kind === 'error') {
          reject(state.message);
          unsubscribe();
        }
      },
    });
  });
}
