import React from 'react';

import { ErrorMessage } from 'components';
import { FormSectionState } from 'utils/FormState';
import { AbstractStateUnit } from 'utils/State';

type Props = {
  sectionStateUnit: FormSectionState<AbstractStateUnit<unknown>>;
};

function SectionError({ sectionStateUnit }: Props) {
  const sectionError = sectionStateUnit.units.error.useState();

  return <ErrorMessage.Component rows={1} messageReference={sectionError} />;
}

export const Component = React.memo(SectionError);
