import { makeFeature } from 'services/Feature';

import i18nData from './i18n.json';
import * as i18nSharedReferences from './i18nSharedReferences';
import * as subfeatures from './subfeatures';

export { getMapData } from './getMapData';

export const feature = makeFeature({
  name: 'MapWidgetDatafulView',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "map-widget-dataful-view" */
      './Component'
    ),
  i18nData,
  i18nSharedReferences: Object.values(i18nSharedReferences),
  subfeatures: Object.values(subfeatures).map(x => x.feature),
});

export type { Props } from './Component';

export const Component = feature.Component;
