import * as M from 'types/serverModels';

import { makeService } from '../../utils';

type InputData = {
  project: M.UUID;
};

type OutputData = {
  data: M.QuestionnaireData;
};

export const get = makeService<InputData, OutputData[], M.QuestionnaireData[]>(
  ({ project }, convertToSearchParams) =>
    `data/draft/?${convertToSearchParams({ project })}`,
  'api-v2-get',
  false,
  output => Array.from(output).map(({ data }) => data),
);
