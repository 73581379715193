import React from 'react';

import * as M from 'types/serverModels';
import { FormElementState, makeFormElementState } from 'utils/FormState';
import { nonFalse } from 'utils/validators';

import { questionIsRequired } from '../i18nSharedReferences';

export type StateItem = Record<
  string,
  {
    valueState: FormElementState<boolean>;
    textState: FormElementState<string>;
  }
>;

export const makeState = (
  question: M.MultiChoiceQuizQuestion,
  initialValue: string[] = [],
) => {
  return makeFormElementState<StateItem>(
    question.variants.reduce((acc, x) => {
      const valueState = makeFormElementState(false, [
        nonFalse(questionIsRequired),
      ]);
      valueState.units.value.setState(initialValue.includes(x.uuid));

      return {
        ...acc,
        [x.uuid]: { valueState },
      };
    }, {}),
  );
};

export const useState = (
  question: M.MultiChoiceQuestion,
  initialValue?: string[],
) => {
  const [state] = React.useState(() => makeState(question, initialValue));
  return state;
};
