import * as R from 'ramda';
import React, { useCallback } from 'react';

import { VerticallyMovableElement, Button } from 'components';
import { useText } from 'services/I18n';
import { ReactComponent as PlusIcon } from 'shared/images/plus.svg';
import { AnswerVariant } from 'utils/business';
import { block } from 'utils/classname';
import { nonEmptyString } from 'utils/validators';

import i18nData from '../../../../../i18n.json';
import { isRequired } from '../../../../../i18nSharedReferences';
import { FormExtensionProps } from '../../../types';
import { StateInstance } from '../types';
import * as Variant from './Variant';
import * as VariantsError from './VariantsError';
import './style.scss';

const b = block('quiz-variant-selection-question-form-extension');

const useVerticallyMovableElements =
  VerticallyMovableElement.makeUseVerticallyMovableELements<AnswerVariant.AnswerVariant>(
    x => x.id,
  );

const useVariantSelectionText = () =>
  useText(i18nData).questions.list.variantSelection;

function FormExtension({ instance }: FormExtensionProps<StateInstance>) {
  const text = useVariantSelectionText();

  const handleAddVariantButtonClick = useCallback(() => {
    instance.variants.setState(prev => [
      ...prev,
      AnswerVariant.makeNewVariant([nonEmptyString(isRequired)]),
    ]);
  }, [instance.variants]);

  const handleVariantMoveDown = useCallback(
    (variant: AnswerVariant.AnswerVariant) => {
      instance.variants.setState(prev => {
        const index = prev.findIndex(x => x === variant);

        if (index === -1 || index === prev.length - 1) {
          return prev;
        }

        return R.move(index, index + 1, prev);
      });
    },
    [instance.variants],
  );

  const handleVariantMoveUp = useCallback(
    (variant: AnswerVariant.AnswerVariant) => {
      instance.variants.setState(prev => {
        const index = prev.findIndex(x => x === variant);

        if (index === -1 || index === 0) {
          return prev;
        }

        return R.move(index, index - 1, prev);
      });
    },
    [instance.variants],
  );

  const handleVariantRemove = useCallback(
    (variant: AnswerVariant.AnswerVariant) => {
      instance.variants.setState(prev => R.without([variant], prev));
    },
    [instance.variants],
  );

  const movableVariants = useVerticallyMovableElements(
    instance.variants.useState(),
  );

  return (
    <div className={b('element')}>
      {movableVariants.map(variant => {
        return (
          <VerticallyMovableElement.Component
            key={variant.value.id}
            element={variant}
          >
            <Variant.Component
              key={variant.value.id}
              variant={variant.value}
              onMoveDown={handleVariantMoveDown}
              onMoveUp={handleVariantMoveUp}
              onRemove={handleVariantRemove}
            />
          </VerticallyMovableElement.Component>
        );
      })}
      <VariantsError.Component
        variantsSectionStateUnit={instance.variantsSectionState}
      />
      <Button.Component
        type="button"
        size="s"
        color="secondary"
        variant="outlined"
        className={b('add-variant-link')}
        onClick={handleAddVariantButtonClick}
      >
        <PlusIcon className={b('plus-icon')} />
        {text.addVariantButtonLabel}
      </Button.Component>
    </div>
  );
}

export const Component = React.memo(FormExtension) as typeof FormExtension;
