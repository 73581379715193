import React from 'react';

import { block } from 'utils/classname';

import './style.scss';

const b = block('modal-info-text');

type Props = React.PropsWithChildren<{}>;

function InfoText({ children }: Props) {
  return <div className={b()}>{children}</div>;
}

export const Component = React.memo(InfoText);
