import {
  makeDerivedUnit,
  makeMappingUnitFromUnit,
  makePrimaryUnit,
} from 'utils/State';
import { makeUUID } from 'utils/UUID';

import { isQuestionInstance } from '../isQuestionInstance';
import { QuestionInstance } from '../questions';
import {
  ConstructorCachedInstance,
  QuestionSelectionInstance,
  StoreCachedInstances,
} from '../types';

export const makeSelectionInstance = (): QuestionSelectionInstance => ({
  kind: 'select-instance-kind',
  id: makeUUID(),
});

export function makeInstanceUnits(
  initialInstances: ConstructorCachedInstance[],
) {
  const cachedStoreInstancesUnit = makePrimaryUnit<StoreCachedInstances>({
    instances: initialInstances,
  });

  const flatCachedInstancesUnit = makeDerivedUnit(
    cachedStoreInstancesUnit,
  ).getUnit(({ instances }) => instances);

  const flatInstancesUnit = makeMappingUnitFromUnit(
    makeDerivedUnit(flatCachedInstancesUnit).getUnit(cachedInstances =>
      cachedInstances.map(cachedInstance =>
        cachedInstance.kind === 'select-instance-kind'
          ? makePrimaryUnit(cachedInstance)
          : makeDerivedUnit(cachedInstance.activeQuestionKeyUnit).getUnit(
              questionKey => cachedInstance.instancesCache[questionKey],
            ),
      ),
    ),
  );

  const flatQuestionInstancesUnit = makeDerivedUnit(flatInstancesUnit).getUnit(
    xs =>
      xs.filter(
        (x): x is QuestionInstance => x.kind !== 'select-instance-kind',
      ),
  );

  const questionInstancesUnit = makeDerivedUnit(
    flatCachedInstancesUnit,
  ).getUnit(instances => instances.filter(isQuestionInstance));

  return {
    cachedStoreInstancesUnit,
    questionInstancesUnit,
    flatCachedInstancesUnit,
    flatQuestionInstancesUnit,
  };
}
