import React from 'react';

import { block } from 'utils/classname';

import './style.scss';

const b = block('progress');

type Props = Pick<
  React.ProgressHTMLAttributes<HTMLProgressElement>,
  'max' | 'value'
> & {
  variant?: 'contained' | 'outlined';
  className?: string;
};

function Progress({ variant = 'contained', max, value, className }: Props) {
  return (
    <progress className={b({ variant }, [className])} max={max} value={value} />
  );
}

export const Component = React.memo(Progress) as typeof Progress;
