import React from 'react';

import { routeTree } from 'pages/routeTree';
import { Page } from 'services';

import * as features from './features';

function Quiz() {
  const params = routeTree.LANG.quiz.form.QUIZ_UUID.useRouteParams();

  if (!params) {
    return null;
  }

  return (
    <features.QuizForm.Component objectUUID={params.quiz_uuid} kind="quiz" />
  );
}

export const Component = Page.makePage({
  path: routeTree.LANG.quiz.form.QUIZ_UUID.getPath(),
  scrollTo: 'top-on-mount',
  Component: React.memo(Quiz),
  features: Object.values(features).map(x => x.feature),
});
