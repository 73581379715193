import {
  Point,
  PlotWordcloudRotationOptions,
  SeriesWordcloudOptions,
  SizeObject,
} from 'highcharts';

type Args = {
  point: Point;
  field: SizeObject;
  rotation?: PlotWordcloudRotationOptions;
};

function getRotation({
  point,
  field,
  rotation: { orientations = 2, from = 0, to = 90 } = {},
}: Args) {
  if (point.name.length > 12) {
    if (field.height > field.width) {
      return 90;
    }
    return 0;
  }

  const range = to - from;
  const intervals = range / (orientations - 1 || 1);
  const orientation = point.index % orientations;

  return from + orientation * intervals;
}

export function customPlacement(
  point: Point,
  { field, rotation }: SeriesWordcloudOptions & { field: SizeObject },
) {
  return {
    x: 0,
    y: 0,
    rotation: getRotation({ point, field, rotation }),
  };
}
