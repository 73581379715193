import * as TS from 'types';
import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  lang: TS.Language;
  statistic?: boolean;
  bbox?: string;
  resolution?: number;
  filter?: {
    substring?: string;
    role?: M.UserRole[];
    name?: string;
    subjects?: string;
    interests?: string;
    langs?: string;
    group?: M.UUID;
    project?: M.UUID;
    course?: M.UUID;
    segment?: M.UUID;
  };
};

export const userMapList = makeService<InputData, M.UserMapList>(
  'user_map_list',
  'get',
);
