import { format, formatDistance, isSameYear } from 'date-fns';
import { useCallback } from 'react';

import * as TS from 'types';

import { activeLangStateUnit } from './activeLangStateUnit';
import { dateFormatLocales } from './constants';

const formats: Record<TS.Language, string> = {
  en: 'MM/dd/yyyy',
  es: 'MM/dd/yyyy',
  ru: 'dd.MM.yyyy',
};

export function useGetFormattedDistanceDatetime() {
  const lang = activeLangStateUnit.useState();

  return useCallback(
    (datetime: string) => {
      if (datetime === '') {
        return '';
      }

      const currentTimestamp = Date.now();
      const timestamp = Date.parse(datetime);

      const isCurrentYear = isSameYear(timestamp, currentTimestamp);

      const options = {
        addSuffix: true,
        locale: dateFormatLocales[lang],
      };

      if (isCurrentYear) {
        return formatDistance(timestamp, currentTimestamp, options);
      }

      return format(timestamp, formats[lang]);
    },
    [lang],
  );
}
