import React, { useEffect } from 'react';

import i18nData from 'features/project/Constructor/i18n.json';
import { someError } from 'features/project/Constructor/i18nSharedReferences';
import { OkModal } from 'features/project/Constructor/subfeatures';
import { I18n } from 'services';
import { usePrimaryUnit } from 'utils/State';
import { useRequiredContext } from 'utils/react/RequiredContext';

import { ProjectWriteContext } from '../../ProjectWriteContext';

type Props = {};

function PublishErrorModal({}: Props) {
  const isOpenUnit = OkModal.useIsOpenUnit();

  const { status } = useRequiredContext(ProjectWriteContext);
  const textReferenceUnit = usePrimaryUnit<I18n.EntryReference | null>(null);
  const textReference = textReferenceUnit.useState();

  const text = I18n.useOptionalReference(textReference);

  useEffect(() => {
    return status.callStateUnit.subscribe({
      name: 'publish-error-modal-handler',
      callback: state => {
        if (state.kind === 'error') {
          isOpenUnit.setState(true);

          if (state.code === 1046 || state.code === 1047) {
            textReferenceUnit.setState(
              I18n.makeEntryReference(
                i18nData,
                data => data.modals.publishError.texts.notEnoughFeatures,
              ),
            );
          } else if (state.code === 1007) {
            textReferenceUnit.setState(
              I18n.makeEntryReference(
                i18nData,
                data => data.modals.publishError.texts.notEnoughPower,
              ),
            );
          } else if (state.code === 1043) {
            textReferenceUnit.setState(
              I18n.makeEntryReference(
                i18nData,
                data => data.modals.publishError.texts.alreadyPublished,
              ),
            );
          } else {
            textReferenceUnit.setState(someError);
          }
        }
      },
    });
  }, [isOpenUnit, status.callStateUnit, textReferenceUnit]);

  const Header = OkModal.Header.useFromTitle({
    useTitle: () => I18n.useText(i18nData).modals.publishError.title,
  });

  return (
    <OkModal.Component isOpenUnit={isOpenUnit} Header={Header}>
      {text}
    </OkModal.Component>
  );
}

export const Component = React.memo(PublishErrorModal);
