import { makeSharedEntryReference } from 'services/I18n/makeEntryReference';

export const showAllLinkReference = makeSharedEntryReference(
  data => data.showAllLink,
);

export const showMoreLinkReference = makeSharedEntryReference(
  data => data.showMoreLink,
);

export const hideLinkReference = makeSharedEntryReference(
  data => data.hideLink,
);

export const loadMoreButtonLabelReference = makeSharedEntryReference(
  data => data.loadMoreButtonLabel,
);
