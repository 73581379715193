import * as M from 'types/serverModels';

import { makeService } from '../../utils';

type InputData = {};

type OutputData = {
  experiments: M.ExperimentListItem[];
};

export const list = makeService<InputData, OutputData>(
  () => 'logger/experiment/',
  'api-v2-get',
);
