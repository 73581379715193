import { QuestionKind } from 'types';
import { createRequiredContext } from 'utils/react/RequiredContext';

import {
  EntityManipulationPermission,
  QuestionTypeChangePermission,
} from '../../types';

type PermissionContextData = {
  getQuestionDeletePermission(questionID: string): EntityManipulationPermission;
  getAnswerDeletePermission(
    questionID: string,
    answerID: string,
  ): EntityManipulationPermission;
  getQuestionTypeChangePermission(
    questionID: string,
    toSet: QuestionKind[],
  ): QuestionTypeChangePermission;
};

export const PermissionContext = createRequiredContext<PermissionContextData>();
