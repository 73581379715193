import React from 'react';

import { block } from 'utils/classname';
import { parseHTML } from 'utils/react';

import { Item } from '../../types';
import { getItemLabel } from '../../utils';
import { ProvidedProps } from '../types';
import './style.scss';

const b = block('autocomplete-default-sorted-item');

type Props<T extends Item> = ProvidedProps<T>;

function DefaultSortedItem<T extends Item>({ item, regExp }: Props<T>) {
  const label = getItemLabel(item);

  const labelWithMarkers = label.replaceAll(
    regExp,
    substring => substring && `<strong>${substring}</strong>`,
  );

  return <div className={b()}>{parseHTML(labelWithMarkers)}</div>;
}

export const Component = React.memo(
  DefaultSortedItem,
) as typeof DefaultSortedItem;
