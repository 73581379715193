import { PrimaryStateUnit } from 'utils/State';

import { makeCallStateUnit } from './makeCallState';
import { CallState } from './types';

export function makeCallStateUnitFromPromise<T>(
  promise: Promise<T>,
): PrimaryStateUnit<CallState<T>> {
  const callState = makeCallStateUnit<T>();
  callState.setState({ kind: 'pending' });

  promise
    .then(result => {
      callState.setState({ kind: 'successful', data: result });
    })
    .catch(error => {
      callState.setState({ kind: 'error', message: error });
    });

  return callState;
}
