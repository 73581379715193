import React, { useCallback, useEffect } from 'react';

import { getBreakpointDevice } from './getBreakpointDevice';
import { unit } from './unit';

type Props = {};

function Initializer({}: Props) {
  const handleWindowResize = useCallback(() => {
    const currentDevice = unit.getState();
    const breakpointDevice = getBreakpointDevice();

    if (breakpointDevice !== currentDevice) {
      unit.setState(breakpointDevice);
    }
  }, []);

  useEffect(() => {
    unit.setState(getBreakpointDevice());

    window.addEventListener('resize', handleWindowResize);
  }, [handleWindowResize]);

  return null;
}

export const Component = React.memo(Initializer) as typeof Initializer;
