import { makeMapping } from 'utils/collection';

import { makeSelectedQuestionState } from './makeSelectedQuestionState';
import { Layer } from './types';

export function makeSelectedQuestionsMapping(layers: Layer[]) {
  return makeMapping(
    layers,
    x => x.id,
    x =>
      makeSelectedQuestionState(
        x.kind === 'without-selected-question' ? null : x.question,
      ),
  );
}
