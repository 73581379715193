import * as R from 'ramda';

import * as TS from 'types';
import {
  AbstractStateUnit,
  makeDerivedUnit,
  makeMappingUnitFromUnit,
} from 'utils/State';
import { Layer, VennChart } from 'utils/business';

import { instancesUnit } from './instances';

const widgetIDToToQuestionIDsUnit = makeDerivedUnit(instancesUnit).getUnit(
  instances => {
    return instances.reduce<
      Record<string, string[] | AbstractStateUnit<string[]>>
    >((acc, x) => {
      return {
        ...acc,
        [x.id]: (() => {
          switch (x.kind) {
            case 'area':
            case 'column':
            case 'columnStack':
            case 'line':
            case 'pie':
            case 'scatter':
              return makeDerivedUnit(
                x.xAxisData.units.value,
                x.yAxisData.units.value,
                x.cuttingQuestion.units.value,
              ).getUnit((xAxisData, yAxisData, cuttingQuestion) => {
                const axisQuestionIDs = [xAxisData, yAxisData]
                  .filter<TS.QuestionAxisData>(
                    (x): x is TS.QuestionAxisData => x?.kind === 'question',
                  )
                  .map(x => x.questionID);

                if (cuttingQuestion) {
                  return [...axisQuestionIDs, cuttingQuestion];
                }
                return axisQuestionIDs;
              });
            case 'gallery':
            case 'textsCloud':
            case 'textsList':
            case 'filesList':
              return makeDerivedUnit(x.questionID.units.value).getUnit(x =>
                x === null ? [] : [x],
              );
            case 'timeline':
              return makeDerivedUnit(
                x.startDateQuestionID.units.value,
                x.endDateQuestionID.units.value,
                x.headlineQuestionID.units.value,
                x.textQuestionID.units.value,
                x.mediaQuestionID.units.value,
              ).getUnit((startDate, endDate, headline, text, media) =>
                [startDate, endDate, headline, text, media]
                  .filter((x): x is string => typeof x === 'string')
                  .map(x => x),
              );
            case 'dataList':
              return [];
            case 'venn':
              return makeDerivedUnit(x.sets).getUnit(sets => {
                return R.uniq(
                  sets
                    .filter<VennChart.SetWithQuestion>(
                      (x): x is VennChart.SetWithQuestion =>
                        x.kind !== 'without-selected-question',
                    )
                    .map(x => x.question.id),
                );
              });

            case 'map':
              return makeDerivedUnit(x.layers).getUnit(layers =>
                layers
                  .filter<Layer.LayerWithSelectedQuestion>(
                    (x): x is Layer.LayerWithSelectedQuestion =>
                      x.kind !== 'without-selected-question',
                  )
                  .map(x => x.question.id),
              );
            default:
              return [];
          }
        })(),
      };
    }, {});
  },
);

export const questionIDToWidgetsNumberUnit = makeDerivedUnit(
  makeMappingUnitFromUnit(widgetIDToToQuestionIDsUnit),
).getUnit(widgetIDToQuestionsID => {
  return Object.entries(widgetIDToQuestionsID).reduce<Record<string, number>>(
    (acc, [_, questionIDs]) => {
      return questionIDs.reduce((yAcc, questionID) => {
        return {
          ...yAcc,
          [questionID]:
            yAcc[questionID] === undefined ? 1 : yAcc[questionID] + 1,
        };
      }, acc);
    },
    {},
  );
});
