import * as M from 'types/serverModels';

import { makeService } from '../utils';

type InputData = {
  uuid: M.Quiz['uuid'];
  quiz: M.QuizWriteData;
};

type Output = {
  quiz: M.Quiz;
};

export const put = makeService<InputData, Output, M.Quiz>(
  ({ uuid }) => `quiz/${uuid}`,
  'api-v2-put',
  false,
  ({ quiz }) => quiz,
  { extractBodyInput: ({ quiz }) => ({ quiz }) },
);
