import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  classification: string;
  parent_term?: string;
  subterms?: string;
};

export const classificationTermLoad = makeService<
  InputData,
  M.ClassificationTerm[]
>('classification_term_load', 'get');
