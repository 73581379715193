import React from 'react';

import { block } from 'utils/classname';

import * as Container from './Container';
import './style.scss';

const b = block('sub-list');

export type Align = 'left' | 'right' | 'center';

export type CellProps<T> = {
  entry: T;
};

export type Column<T> = {
  cell: {
    Component: React.FC<CellProps<T>>;
    align: Align;
  };
};

type Props<T> = {
  entries: T[];
  columns: Array<Column<T>>;
  className?: string;
  title: string;
};

function SubList<T>({ columns, entries, className, title }: Props<T>) {
  return (
    <Container.Component
      className={b({}, [className])}
      isEmpty={entries.length === 0}
    >
      <div className={b('content')}>
        <h4 className={b('title')}>{title}</h4>
        <table className={b('table')}>
          <tbody>
            {entries.map((entry, index) => (
              <tr key={index}>
                {columns.map((column, index) => (
                  <td
                    key={index}
                    className={b('cell', { align: column.cell.align })}
                  >
                    <column.cell.Component key={index} entry={entry} />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Container.Component>
  );
}

export const Component = React.memo(SubList) as typeof SubList;

export { Container };
