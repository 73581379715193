import React from 'react';

import { block } from 'utils/classname';

import { HeaderProps } from '../types';
import './style.scss';

const b = block('expansion-panel-simple-labeled-header');

type OwnProps = {
  Label: React.FC;
  fullWidth?: boolean;
  className?: string;
};

type Props = OwnProps & HeaderProps;

function SimpleLabeledHeader({
  Label,
  ExpandTriggerArea,
  fullWidth,
  className,
}: Props) {
  return (
    <div className={b({ 'full-width': fullWidth }, [className])}>
      <ExpandTriggerArea fullWidth={fullWidth}>
        <div className={b('label')}>
          <Label />
        </div>
      </ExpandTriggerArea>
    </div>
  );
}

export const Component = React.memo(
  SimpleLabeledHeader,
) as typeof SimpleLabeledHeader;

export const makeComponent =
  (props: OwnProps) => (headerProps: HeaderProps) => (
    <Component {...props} {...headerProps} />
  );
