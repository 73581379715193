import * as M from 'types/serverModels';

import { makeService } from './utils';

type APIOutputData = {
  uuid: M.UUID;
  cdate: string;
  from: string;
  to: string;
  body: string;
  read: boolean;
  notified?: boolean;
};

type InputData = {
  correspondent: string;
};

function convertPrivateMessageList(data: APIOutputData[]): M.LoadedMessage[] {
  return data.map(message => ({
    ...message,
    cdate: new Date(message.cdate),
    kind: 'loaded',
  }));
}

export const privateMessageList = makeService<
  InputData,
  APIOutputData[],
  M.LoadedMessage[]
>('private_message_list', 'get', true, convertPrivateMessageList);
