import { Filter } from 'features/project/Constructor/subfeatures';
import { getProjectLanguage } from 'features/project/Constructor/utils';
import { I18n } from 'services';
import { chartPalettes } from 'shared/constants';
import * as TS from 'types';
import * as M from 'types/serverModels';
import { makePrimaryUnit } from 'utils/State';
import { AxisData, Layer, VennChart } from 'utils/business';
import { makeMapping } from 'utils/collection';

import {
  flatQuestionInstancesUnit,
  questionIDToQuestionInstanceUnit,
  questionIDToQuestionKindUnit,
  questionIDToQuestionTextUnitMappingUnit,
} from '../InputDataForm/units/instances';
import { Question } from '../shared';
import * as widgets from './widgets';
import { chartTypeToWidgetKey } from './widgets/chartWidgets/chartTypeToWidgetKey';
import { SharedConstructorArgs } from './widgets/shared/SharedConstructorArgs';

export function makeWidgetInstanceFromServerWidget(
  widget: M.Widget,
  project: M.Project,
  language: TS.Language,
): widgets.FindingsWidgetInstance {
  const variantSelectionQuestionIDToAnswerVariants = makeMapping(
    flatQuestionInstancesUnit
      .getState()
      .filter(
        (x): x is Question.VariantSelection.StateInstance =>
          x.kind === 'variantSelection',
      ),
    x => x.id,
    x => x.variants,
  );

  const questionIDToQuestion = makeMapping(
    project.questions,
    x => x.uuid,
    x => x,
  );

  const questionIDToQuestionInstance =
    questionIDToQuestionInstanceUnit.getState();

  const t = I18n.makeGetOptionalMultilingTranslation(language);

  const sharedData: SharedConstructorArgs = {
    id: widget.uuid,
    initialSortState: widget.descriptor.sort,
    initialFilterState: Filter.makeInitialStateFromServerFilter(
      widget.descriptor.filter ?? {},
    ),
    titleValue: t(widget.title),
    titleMultilingString: widget.title,
    answersFilter: widget.descriptor.filter?.answers,
  };

  switch (widget.type) {
    case 'chart': {
      const widgetKey = chartTypeToWidgetKey[widget.descriptor.chartType];

      return widgets.widgetKeyToFindingsConstructorWidget[
        widgetKey
      ].makeInstance({
        ...sharedData,
        initialCuttingQuestion: AxisData.makeCuttingQuestionID(
          widget.descriptor.seriesVar,
        ),
        initialPalette: chartPalettes[widget.descriptor.colorScheme],
        initialXAxisData:
          widget.descriptor.xVar &&
          AxisData.makeXAxisDataFromServerModel(widget.descriptor.xVar),
        initialYAxisData:
          widget.descriptor.yVar &&
          AxisData.makeYAxisDataFromServerModel(widget.descriptor.yVar),
      });
    }

    case 'map': {
      return widgets.widgetKeyToFindingsConstructorWidget.map.makeInstance({
        ...sharedData,
        initialLayers: widget.descriptor.layers
          // NOTE if question somehow was deleted but widget settings keep it
          .filter(x => questionIDToQuestionInstance[x.questionId] !== undefined)
          .map(x => {
            const questionInstance = questionIDToQuestionInstance[
              x.questionId
            ] as Question.VariantSelection.StateInstance;

            return Layer.makeLayerFromServerLayer({
              layer: x,
              questionIDToQuestionTextUnitMapping:
                questionIDToQuestionTextUnitMappingUnit.getState(),
              variants: {
                kind: 'constructor',
                value: questionInstance.variants,
              },
              questionIDToQuestionKind: questionIDToQuestionKindUnit.getState(),
            });
          }),
        initialImage: widget.descriptor.background,
      });
    }

    case 'venn':
      return widgets.widgetKeyToFindingsConstructorWidget.venn.makeInstance({
        ...sharedData,
        initialSets: VennChart.makeSetsFromServerData({
          widget,
          questionIDToQuestion,
          variantSelectionQuestionIDToAnswerVariants,
          langUnit: makePrimaryUnit(getProjectLanguage()),
        }),
      });

    case 'gallery':
      return widgets.widgetKeyToFindingsConstructorWidget.gallery.makeInstance({
        ...sharedData,
        initialQuestionID: widget.descriptor.questionId,
      });

    case 'textslist':
      return widgets.widgetKeyToFindingsConstructorWidget.textsList.makeInstance(
        {
          ...sharedData,
          initialQuestionID: widget.descriptor.questionId,
        },
      );

    case 'fileslist':
      return widgets.widgetKeyToFindingsConstructorWidget.filesList.makeInstance(
        {
          ...sharedData,
          initialQuestionID: widget.descriptor.questionId,
        },
      );

    case 'textscloud':
      return widgets.widgetKeyToFindingsConstructorWidget.textsCloud.makeInstance(
        {
          ...sharedData,
          initialQuestionID: widget.descriptor.questionId,
        },
      );

    case 'datalist':
      return widgets.widgetKeyToFindingsConstructorWidget.dataList.makeInstance(
        sharedData,
      );

    case 'timeline':
      return widgets.widgetKeyToFindingsConstructorWidget.timeline.makeInstance(
        {
          ...sharedData,
          initialStartDateQuestionID: widget.descriptor.startDateQuestionId,
          initialEndDateQuestionID: widget.descriptor.endDateQuestionId,
          initialHeadlineQuestionID: widget.descriptor.headlineQuestionId,
          initialTextQuestionID: widget.descriptor.textQuestionId,
          initialMediaQuestionID: widget.descriptor.mediaQuestionId,
        },
      );
  }
}
