import * as M from 'types/serverModels';
import { UUID } from 'types/serverModels';

import { makeService } from './utils';

export type InputData = {
  data: {
    product: UUID;
    details: M.Perk | { type: '' };
    redeem: boolean;
    currency: M.Currency;
  };
};

export type Output = {
  basket: M.Basket;
  features: M.Feature;
};

export const storeBasketAdd = makeService<InputData, Output>(
  'store_basket_add',
  'post-json',
);
