import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  license: M.UUID;
  users?: string[];
};

export type Output = M.License;

export const storeLicenseActivate = makeService<InputData, Output>(
  'store_license_activate',
  'post',
);
