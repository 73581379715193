import { isFormElementState, makeFormElementState } from 'utils/FormState';
import { AbstractStateUnit, makePrimaryUnit } from 'utils/State';

import { makeFilterQuestionsMappingUnit } from './makeFilterQuestionsMappingUnit';
import {
  FilteringQuestion,
  State,
  UserOption,
  GroupsAndUsersTab,
  InitialState,
} from './types';

export function makeState(
  filteringQuestionsUnit: AbstractStateUnit<FilteringQuestion[]>,
  initialState: InitialState = {
    submitDateFrom: null,
    submitDateTo: null,
    location: null,
    users: [],
  },
): State {
  const filteringQuestionsMappingUnit = makeFilterQuestionsMappingUnit(
    filteringQuestionsUnit,
  );

  return {
    locationState: makeFormElementState(initialState.location),
    filteringQuestionsUnit,
    filteringQuestionsMappingUnit,
    submitDate: {
      from: makeFormElementState(initialState.submitDateFrom),
      to: makeFormElementState(initialState.submitDateTo),
    },
    groupsAndUsers: {
      group: makeFormElementState(initialState.group || null),
      users: makeFormElementState<UserOption[]>(
        Array.from(initialState.users || []).map(x => ({ label: x, value: x })),
      ),
    },
    switchesState: {
      filtertingQuestions: makeFormElementState(
        Object.keys(filteringQuestionsMappingUnit.getState()).length > 0,
      ),
      location: makeFormElementState(initialState.location !== null),
      sumbitDate: makeFormElementState(
        initialState?.submitDateFrom !== null ||
          initialState?.submitDateTo !== null,
      ),
      groupsAndUsers: {
        switch: makeFormElementState(
          initialState?.group !== undefined ||
            (initialState?.users !== undefined &&
              initialState.users.length > 0),
        ),
        activeTab: makePrimaryUnit<GroupsAndUsersTab>(
          initialState?.users !== undefined && initialState.users.length > 0
            ? 'users'
            : 'group',
        ),
      },
    },
    validate: () =>
      filteringQuestionsUnit.getState().every(state =>
        Object.values(state).every(x => {
          if (isFormElementState(x)) {
            return x.formNode.validate();
          }
          return true;
        }),
      ),
  };
}
