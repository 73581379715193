import React from 'react';

import { DateQuestion } from 'features/project/Constructor/subfeatures';
import { useFormElementState } from 'utils/FormState';

import { PreviewModeFormProps } from '../../../types';
import { StateInstance } from '../types';

function PreviewModeForm({}: PreviewModeFormProps<StateInstance>) {
  const formElementState = useFormElementState<Date | null>(null);

  return <DateQuestion.Component formElementState={formElementState} />;
}

export const Component = React.memo(PreviewModeForm) as typeof PreviewModeForm;
