import { makeFeature } from 'services/Feature';

export const feature = makeFeature({
  name: 'LandingPageText1And2Elements',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "landing-page-text-1-and-2-elements" */
      './Component'
    ),
  i18nData: null,
  i18nSharedReferences: null,
  subfeatures: [],
});

export type { Props } from './Component';

export const Component = feature.Component;
