import { useState } from 'react';

import * as TS from 'types';
import { makeFormElementState } from 'utils/FormState';
import { PrimaryStateUnit } from 'utils/State';

export * as ButtonGroup from './ButtonGroup';
export * as Button from './Button';
export * from './types';

export function makeFieldState<T extends string>(
  initialState: T | null | PrimaryStateUnit<T | null> = null,
  validators?: TS.Validator[],
) {
  return makeFormElementState<T | null>(initialState, validators);
}

export function useFieldState<T extends string>(
  initialState: T | null | PrimaryStateUnit<T | null> = null,
) {
  const [state] = useState(() => makeFieldState<T>(initialState));
  return state;
}
