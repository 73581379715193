import * as M from 'types/serverModels';

import { makeService } from '../utils';

type InputData = {
  quiz: M.QuizWriteData;
};

type Output = {
  quiz: M.Quiz;
};

export const post = makeService<InputData, Output, M.Quiz>(
  () => 'quiz/',
  'api-v2-post',
  false,
  ({ quiz }) => quiz,
);
