import * as TS from 'types';
import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  lang?: TS.Language;
  limit?: number;
  shuffle?: boolean;
};

export const testimonialList = makeService<InputData, M.Testimonial[]>(
  'testimonial_list',
  'get',
);
