import { VennChart } from 'utils/business';

import * as setConstructors from './setConstructors';
import { Validators } from './types';

export function makeNewSet(
  question: VennChart.Question | null,
  name: string,
  validators?: Validators,
) {
  if (question === null) {
    return setConstructors.withoutQuestion(name);
  }

  switch (question.kind) {
    case 'with-multiple-variants':
      return setConstructors.forMultipleVariants(
        question,
        name,
        [],
        validators?.forMultipleVariants,
      );

    case 'with-single-variant':
      return setConstructors.forSingleVariant(
        question,
        name,
        null,
        validators?.forSingleVariant,
      );

    case 'with-number':
      return setConstructors.forNumberQuestion(question, name);

    default:
      console.error('bad question', question);
      throw Error('unexpected selected question');
  }
}
