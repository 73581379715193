import * as M from 'types/serverModels';
import { ProjectBook } from 'utils/models';

export function getDefaultStage(
  stages: M.ProjectBook['stages'],
  currentStageNumber: M.ProjectBook['current_stage_number'],
) {
  const currentStage = ProjectBook.getCurrentStage(stages, currentStageNumber);

  return currentStage || stages?.[0];
}
