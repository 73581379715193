import React from 'react';

import { Page } from 'services';

import { routeTree } from '../../../routeTree';
import * as features from './features';

type Props = features.Progress.Props;

function Progress(props: Props) {
  return <features.Progress.Component {...props} />;
}

export const path = routeTree.LANG.course.list.COURSE.progress.getPath();

export const Component = Page.makePage({
  path,
  routeProps: { exact: false },
  scrollTo: 'none',
  Component: React.memo(Progress) as typeof Progress,
  features: Object.values(features).map(x => x.feature),
}) as typeof Progress;
