import { MathJax } from 'better-react-mathjax';
import React, { useCallback } from 'react';

import { Button } from 'components';
import i18nData from 'features/project/Constructor/i18n.json';
import { QuizConstructorModal } from 'features/project/Constructor/subfeatures';
import { I18n } from 'services';
import { block } from 'utils/classname';
import { parseHTML } from 'utils/react';

import {
  AbstractMaterialStateInstance,
  MaterialConstructorWidget,
} from '../../../types';
import './style.scss';

const b = block('constructor-widget-preview-content-for-material');

type Props = {
  stateInstance: AbstractMaterialStateInstance<any, any>;
  constructorWidget: MaterialConstructorWidget<any, any, any>;
};

function PreviewContent({ stateInstance }: Props) {
  const handleEditButtonClick = useCallback(() => {
    stateInstance.mode.setState('edit');
    if (stateInstance.kind === 'test') {
      QuizConstructorModal.isOpenUnit.setState(true);
    }
  }, [stateInstance]);

  const text = I18n.useText(i18nData);

  const explanation = stateInstance.materialExplanation.units.value.useState();

  return (
    <div className={b()}>
      <MathJax className={b('explanation')} dynamic>
        {parseHTML(explanation)}
      </MathJax>
      <Button.Component
        className={b('edit-button')}
        type="button"
        variant="outlined"
        color="secondary"
        onClick={handleEditButtonClick}
      >
        {text.editButtonLabel}
      </Button.Component>
    </div>
  );
}

export const Component = React.memo(PreviewContent);
