import { API } from 'services';
import * as M from 'types/serverModels';
import { makePrimaryUnit } from 'utils/State';

export const callStateUnit = API.services.notificationList.makeCallStateUnit();

export const notificationsCallStateUnit =
  API.makeCallStateUnit<M.Notification[]>();
export const resolvedNotificationsCallStateUnit =
  API.makeCallStateUnit<
    (M.ProjectInvitationNotification | M.GroupNotification)[]
  >();
export const paStateUnit = makePrimaryUnit<
  M.PendingAction['state'] | M.PendingAction['state'][] | '*'
>(0);
