import { makeSharedEntryReference } from 'services/I18n/makeEntryReference';

export const isRequired = makeSharedEntryReference(
  data => data.validationErrors.requiredField,
);

export const questionIsRequired = makeSharedEntryReference(
  data => data.validationErrors.requiredQuestion,
);

export const autocompleteQuestionFormLabelReference = makeSharedEntryReference(
  data => data.autocompleteQuestionLabel.form,
);

export const autocompleteQuestionViewLabelReference = makeSharedEntryReference(
  data => data.autocompleteQuestionLabel.view,
);

export const optionalQuestionLabelReference = makeSharedEntryReference(
  data => data.optionalQuestionLabel,
);
