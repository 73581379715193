import React, { useLayoutEffect, useRef, useState } from 'react';

import * as M from 'types/serverModels';
import { getAvailableSpace } from 'utils/DOM';
import { block } from 'utils/classname';

import { Mode } from '../types';
import './style.scss';

const b = block('carousel-image-area');

type Props = {
  containerID: string;
  area: M.ImageArea;
  offset: number;
  mode: Mode;
  className?: string;
};

const minAvailableSpace = 50;

const getCaptionStyle = (containerID: string, element: HTMLElement | null) => {
  const container = document.getElementById(containerID);

  if (!container || !element) return;

  const availableSpace = getAvailableSpace(container, element);

  if (
    availableSpace.top < minAvailableSpace &&
    availableSpace.bottom < minAvailableSpace
  ) {
    return { bottom: 5, left: 5, right: 5 };
  }

  if (availableSpace.bottom < minAvailableSpace) {
    return {
      top: -30,
    };
  }
  return {
    bottom: -30,
  };
};

function Area({ containerID, area, offset, mode, className }: Props) {
  const ref = useRef<HTMLDivElement>(null);

  const [attachedStyle, setAttachedStyle] = useState<
    React.CSSProperties | undefined
  >();

  useLayoutEffect(() => {
    const handleResize = () => {
      setAttachedStyle(getCaptionStyle(containerID, ref.current));
    };

    const resizeObserver = new ResizeObserver(handleResize);

    if (ref.current !== null) {
      resizeObserver.observe(ref.current);
    }

    setTimeout(() => {
      setAttachedStyle(getCaptionStyle(containerID, ref.current));
    }, 50);

    return () => {
      resizeObserver.disconnect();
    };
  }, [containerID]);

  return (
    <div
      className={b({}, [className])}
      key={area.uuid}
      ref={ref}
      style={{
        top: `${area.top}%`,
        left: `${area.left}%`,
        height: `${area.height}%`,
        width: `${area.width}% `,
        transform: `translateX(${offset}px)`,
        transition: mode.kind === 'transition' ? 'transform 200ms' : undefined,
      }}
    >
      {area.text.length > 0 && (
        <div
          className={b('caption', {
            position:
              attachedStyle?.hasOwnProperty('top') ||
              attachedStyle?.hasOwnProperty('left')
                ? 'top'
                : 'bottom',
          })}
          title={area.text}
          style={attachedStyle}
        >
          <span className={b('caption-text')}>{area.text}</span>
        </div>
      )}
    </div>
  );
}

export const Component = React.memo(Area);
