import React from 'react';

import { block } from 'utils/classname';

import './style.scss';

const b = block('sublist-container');

type Props = {
  className?: string;
  isEmpty: boolean;
};

function Container({
  children,
  isEmpty,
  className,
}: React.PropsWithChildren<Props>) {
  return isEmpty ? null : <div className={b({}, [className])}>{children}</div>;
}

export const Component = React.memo(Container);
