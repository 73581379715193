import { I18n } from 'services';
import * as M from 'types/serverModels';
import { makeUUID } from 'utils/UUID';

export function makeWebUnit<
  T extends Omit<M.WebUnit, 'uuid' | 'lang' | 'type'>,
>(data: T) {
  return {
    uuid: makeUUID(),
    lang: I18n.activeLangStateUnit.getState(),
    type: 'web',
    ...data,
  } as const;
}
