import * as M from 'types/serverModels';

import { makeService } from '../utils';

type InputData = {
  uuid: M.UUID;
};

type APIOutputData = {
  project_assignment: M.ProjectAssignment;
  users: M.ProjectAssignmentUser[];
};

export const get = makeService<InputData, APIOutputData>(
  ({ uuid }) => `project_assignment/${uuid}`,
  'api-v2-get',
  false,
);
