import {
  invalidURLSyntax,
  isRequired,
} from 'features/project/Constructor/i18nSharedReferences';
import * as M from 'types/serverModels';
import { FormElementState, makeFormElementState } from 'utils/FormState';
import { makePrimaryUnit } from 'utils/State';
import { nonEmptyString, urlSyntax } from 'utils/validators';

import {
  MaterialConstructorWidget,
  SharedInstanceStateConstructor,
} from '../../types';
import * as FormExtension from './FormExtension';
import { StateInstance } from './types';

export type { StateInstance };

export function makeServerUnit(
  linkState: FormElementState<string>,
): Partial<M.WebUnit> {
  return {
    type: 'web',
    url: linkState.units.value.getState(),
  };
}

export const constructorWidget: MaterialConstructorWidget<
  'link',
  StateInstance,
  typeof makeInstance
> = {
  key: 'link',
  makeInstance,
  FormExtension: FormExtension.Component,
};

function makeInstance(
  makeSharedInstanceState: SharedInstanceStateConstructor,
  initialLink: string = '',
): StateInstance {
  const linkState = makeFormElementState(initialLink, [
    nonEmptyString(isRequired),
    urlSyntax(invalidURLSyntax),
  ]);

  return {
    kind: 'link',
    link: linkState,
    makeServerUnit: () => makeServerUnit(linkState),
    ...makeSharedInstanceState(makePrimaryUnit([linkState.formNode])),
  };
}
