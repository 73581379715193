import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  uuid: M.Certificate['uuid'];
};

type OutputData = unknown;

export const certificatePDF = makeService<InputData, OutputData>(
  'certificate_pdf',
  'get',
);
