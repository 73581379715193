import * as M from 'types/serverModels';

import { makeService } from './utils';

type GroupInvitationAction = {
  type: 'group_invitation';
  data: {
    user: string;
    group: string;
  };
};

type GroupKnockAction = {
  type: 'group_knock';
  data: {
    user: string;
    group: string;
  };
};

type ProjectAuthorInvitationAction = {
  type: 'project_author_invitation';
  data: {
    user: M.User['login'];
    project: M.Project['uuid'];
  };
};

type ProjectSupervisorInvitationAction = {
  type: 'project_supervisor_invitation';
  data: {
    user: M.User['login'];
    project: M.Project['uuid'];
  };
};

type InputData =
  | GroupInvitationAction
  | GroupKnockAction
  | ProjectAuthorInvitationAction
  | ProjectSupervisorInvitationAction;

export const pendingActionCreate = makeService<InputData, M.UUID>(
  'pending_action_create',
  'post-json',
);
