import React from 'react';

import { Page } from 'services';

import { routeTree } from '../../../routeTree';
import * as features from './features';

type Props = features.Results.Props;

function Results(props: Props) {
  return <features.Results.Component {...props} />;
}

export const path =
  routeTree.LANG[
    'project-assignment'
  ].list.PROJECT_ASSIGNMENT.findings.getPath();

export const Component = Page.makePage({
  path,
  scrollTo: 'none',
  Component: React.memo(Results),
  features: Object.values(features).map(x => x.feature),
});
