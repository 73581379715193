import i18nData from 'features/project/Constructor/i18n.json';
import { constructorModeUnit } from 'features/project/Constructor/units';
import { I18n } from 'services';
import * as TS from 'types';
import { FormEntityState } from 'utils/FormState/types';
import {
  makeDerivedUnit,
  makeMappingUnit,
  makeMappingUnitFromUnit,
} from 'utils/State';

import {
  ageRangesFormSectionState,
  annotationState,
  keywordsState,
  projectDescriptionState,
  projectImageState,
  subjectsFormSectionState,
} from './inputState';

const makeErrorUnit = (
  formElementState: FormEntityState,
  errorReferenceKey: keyof (typeof i18nData)[TS.Language]['steps']['projectAnnouncement']['errors'],
) =>
  makeDerivedUnit(formElementState.units.isValid).getUnit(isValid =>
    isValid
      ? null
      : I18n.makeEntryReference(
          i18nData,
          data => data.steps.projectAnnouncement.errors[errorReferenceKey],
        ),
  );

const annotationErrorUnit = makeErrorUnit(
  annotationState,
  'annotationFieldIsRequired',
);
const projectDescriptionErrorUnit = makeErrorUnit(
  projectDescriptionState,
  'projectDescriptionFieldIsRequired',
);
const projectImageErrorUnit = makeErrorUnit(
  projectImageState,
  'projectImageFieldIsRequired',
);
const keywordsErrorUnit = makeErrorUnit(
  keywordsState,
  'keywordsFieldIsRequired',
);
const ageRangesErrorUnit = makeErrorUnit(
  ageRangesFormSectionState,
  'ageRangesFieldIsRequired',
);
const subjectsErrorUnit = makeErrorUnit(
  subjectsFormSectionState,
  'subjectsFieldIsRequired',
);

export const errorsUnit = makeMappingUnitFromUnit(
  makeDerivedUnit(constructorModeUnit).getUnit(mode => {
    const errorsUnits = (() => {
      switch (mode) {
        case 'full':
          return [
            annotationErrorUnit,
            projectDescriptionErrorUnit,
            projectImageErrorUnit,
            keywordsErrorUnit,
            ageRangesErrorUnit,
            subjectsErrorUnit,
          ];
        case 'compact':
          return [
            annotationErrorUnit,
            projectDescriptionErrorUnit,
            projectImageErrorUnit,
            keywordsErrorUnit,
          ];
      }
    })();

    return makeDerivedUnit(makeMappingUnit(errorsUnits)).getUnit(errors =>
      errors.filter<I18n.EntryReference>(
        (x): x is I18n.EntryReference => x !== null,
      ),
    );
  }),
);
