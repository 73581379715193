import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  uuid: string | undefined;
  data: Partial<
    Pick<
      M.Group,
      'name' | 'description' | 'motto' | 'avatar' | 'avg_score' | 'school'
    > & { location: Pick<M.Group['location'], 'latitude' | 'longitude'> }
  >;
};

type Output = string;

export const groupWrite = makeService<InputData, Output>(
  'group_write',
  'post-json',
);
