import React from 'react';

import { useFormElementState } from 'utils/FormState';

import { TextQuestion } from '../../../../../subfeatures';
import { PreviewModeFormProps } from '../../../types';
import { StateInstance } from '../types';

function PreviewModeForm({ instance }: PreviewModeFormProps<StateInstance>) {
  const isMultiline = instance.isMultiline.units.value.useState();

  const formElementState = useFormElementState('');

  return (
    <TextQuestion.Component
      isMultiline={isMultiline}
      formElementState={formElementState}
    />
  );
}

export const Component = React.memo(PreviewModeForm);
