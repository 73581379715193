import React, { useEffect, useMemo } from 'react';

import { Select } from 'components';
import i18nData from 'features/project/Constructor/i18n.json';
import { useText } from 'services/I18n';
import * as questionIcons from 'shared/images/questionIcons';
import { useFormElementState } from 'utils/FormState';
import { block } from 'utils/classname';
import { useRequiredContext } from 'utils/react/RequiredContext';

import { QuestionsConfigContext } from '../../../../QuestionsConfigContext';
import { QuestionKey } from '../../../../types';
import { useHasAccessToTheFile } from '../../../../useHasAccessToTheFile';
import { QuestionWidgetContext } from '../../Context';
import { DependenciesContext } from '../Dependencies';
import './style.scss';

const b = block('header-of-constructor-widget-for-question-in-edit-mode');

type Props = {};

const ActiveOption = (props: Select.ActiveOption.Props<QuestionKey>) => {
  const text = useText(i18nData);

  const option = props.option as QuestionKey;

  const icon = questionIcons[option];

  return (
    <Select.ActiveOption.Container.DefaultComponent>
      <icon.InlineComponent
        className={b('active-option-question-icon', { kind: option })}
      />
      {text.steps.shared.questions.list[option].label}
    </Select.ActiveOption.Container.DefaultComponent>
  );
};

const Option = (props: Select.Option.Props<QuestionKey>) => {
  const text = useText(i18nData);

  const option = props.option as QuestionKey;

  const icon = questionIcons[option];

  return (
    <Select.Option.Container.DefaultComponent>
      <icon.InlineComponent
        className={b('option-question-icon', { kind: option })}
      />
      {text.steps.shared.questions.list[option].label}
    </Select.Option.Container.DefaultComponent>
  );
};

function Header({}: Props) {
  const { constructorWidget, cachedInstance } = useRequiredContext(
    QuestionWidgetContext,
  );
  const { onQuestionTypeChange } = useRequiredContext(DependenciesContext);

  const activeOptionState = useFormElementState(constructorWidget.key);

  const hasAccessToTheFile = useHasAccessToTheFile();

  useEffect(() => {
    return activeOptionState.units.value.subscribe({
      callback: state => {
        onQuestionTypeChange(state, (value: QuestionKey) => {
          setTimeout(() => {
            activeOptionState.units.value.setState(value, [
              'question-type-state-forwarder',
            ]);
          }, 0);
        });
      },
      name: 'question-type-state-forwarder',
    });
  }, [activeOptionState, onQuestionTypeChange]);

  const stateInstance = cachedInstance.useStateInstance();

  const { widgets } = useRequiredContext(QuestionsConfigContext);

  const questionKeys = useMemo(
    () => widgets.filter(hasAccessToTheFile).map(x => x.key),
    [hasAccessToTheFile, widgets],
  );

  return (
    <div className={b()}>
      <Select.Component
        ActiveOption={ActiveOption}
        Option={Option}
        activeOptionState={activeOptionState}
        options={questionKeys}
      />
      {constructorWidget.HeaderExtension && (
        <div className={b('extension')}>
          <constructorWidget.HeaderExtension instance={stateInstance} />
        </div>
      )}
    </div>
  );
}

export const Component = React.memo(Header) as typeof Header;
