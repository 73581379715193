import * as TS from 'types';

import { default as largerSrc } from './larger.svg';
import {
  default as smallerSrc,
  ReactComponent as InlineComponent,
} from './smaller.svg';

export const larger = largerSrc;

export const smaller: TS.Icon = {
  src: smallerSrc,
  InlineComponent,
};
