import React from 'react';

import { block } from 'utils/classname';

import './style.scss';

const b = block('modal-default-layout');

export type Props<T extends keyof HTMLElementTagNameMap> =
  React.PropsWithChildren<{
    as: T;
  }> &
    React.HTMLAttributes<HTMLElementTagNameMap[T]>;

function Default<T extends keyof HTMLElementTagNameMap>({
  as,
  children,
  className,
  ...attributes
}: Props<T>) {
  return React.createElement(
    as,
    { className: b({}, [className]), ...attributes },
    children,
  );
}

export const Component = Default;
