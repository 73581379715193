import React from 'react';

import { InfoPopup } from 'components';
import { block } from 'utils/classname';

import { Mode, ProvidedContentProps } from '../types';
import './style.scss';

const b = block('sortable-list-chip-item-content');

type Props = {
  mode: Mode;
  DescriptionBody?: React.FC;
  useLabel(): string;
};

function Content({ mode, DescriptionBody, useLabel }: Props) {
  const label = useLabel();

  return (
    <div className={b({ mode })}>
      <span className={b('label')} title={label}>
        {label}
      </span>
      {DescriptionBody && (
        <InfoPopup.Component
          className={b('description')}
          Body={DescriptionBody}
        />
      )}
    </div>
  );
}

const Component = React.memo(Content) as typeof Content;

export function makeContent(props: Omit<Props, keyof ProvidedContentProps>) {
  return (providedProps: ProvidedContentProps) => (
    <Component {...providedProps} {...props} />
  );
}
