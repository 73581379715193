import React from 'react';

import { block } from 'utils/classname';

import './style.scss';

const b = block('fullscreen-widget-container-primary-title');

type Props = {
  useText(): React.ReactNode;
};

function PrimaryTitle({ useText }: Props) {
  return <h3 className={b()}>{useText()}</h3>;
}

export const Component = React.memo(PrimaryTitle) as typeof PrimaryTitle;
