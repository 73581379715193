import React from 'react';

import { block } from 'utils/classname';
import { useRequiredContext } from 'utils/react/RequiredContext';

import { QuestionText } from '../../../../../../subfeatures';
import { QuestionContext } from '../../Context';

const b = block('header-of-quiz-constructor-for-question-in-preview-mode');

type Props = {};

function PreviewMode({}: Props) {
  const { cachedInstance } = useRequiredContext(QuestionContext);

  const stateInstance = cachedInstance.useStateInstance();

  const questionText =
    stateInstance.questionText.formElementState.units.value.useState();

  return (
    <div className={b()}>
      <QuestionText.Component questionText={questionText} />
    </div>
  );
}

export const Component = React.memo(PreviewMode);
