import { makeService } from './utils';

type InputData = {
  group: string;
  user: string;
};

type Output = {};

export const groupExpelMember = makeService<InputData, Output>(
  'group_expel_member',
  'post',
);
