import i18nData from 'features/project/Constructor/i18n.json';
import { isRequired } from 'features/project/Constructor/i18nSharedReferences';
import { I18n } from 'services';
import { makeFormElementState } from 'utils/FormState';
import { makeDerivedUnit, makeMappingUnit, makePrimaryUnit } from 'utils/State';
import { nonEmptyString } from 'utils/validators';

import { makeStepVisitedUnit } from '../../utils';

export const workProcessUnit = makeFormElementState(
  process.env.RAZZLE_MOCK_CONSTRUCTOR_DATA === 'yes' ? 'work process' : '',
  [nonEmptyString(isRequired)],
);
export const equipmentUnit = makeFormElementState('');
export const safetyProcedureUnit = makeFormElementState('');

export const hasSomeInputUnit = makeDerivedUnit(
  makeMappingUnit([
    workProcessUnit.units.value,
    equipmentUnit.units.value,
    safetyProcedureUnit.units.value,
  ]),
).getUnit(inputs => inputs.some(x => x !== ''));

export const progressInvariantUnitsUnit = makePrimaryUnit([
  makeDerivedUnit(workProcessUnit.units.value).getUnit(x => x !== ''),
]);

const workProcessErrorReference = I18n.makeEntryReference(
  i18nData,
  data => data.steps.instruction.errors.workProcessFieldIsRequired,
);

export const errorsUnit = makeDerivedUnit(
  workProcessUnit.units.isValid,
).getUnit<I18n.EntryReference[]>(isValid =>
  isValid ? [] : [workProcessErrorReference],
);

export const visitedUnit = makeStepVisitedUnit();

const errorUnitsNotifierName = 'error-units-notifier';

visitedUnit.subscribe({
  name: errorUnitsNotifierName,
  callback: state => {
    if (state) {
      workProcessUnit.units.visited.setState(true);
    }
  },
});
