import React, { useCallback } from 'react';

import { ItemSelection } from 'components';
import i18nData from 'features/project/Constructor/i18n.json';
import { materialUnitTypeReference } from 'features/project/Constructor/i18nSharedReferences';
import { QuizConstructorModal } from 'features/project/Constructor/subfeatures';
import { I18n } from 'services';
import * as materialIcons from 'shared/images/materialIcons';
import { block } from 'utils/classname';
import { Unit } from 'utils/models';

import { constructorWidgets } from '../../materials';
import { CachedInstance, MaterialKey } from '../../types';
import * as ControlsBar from '../ControlsBar';
import './style.scss';

const b = block('constructor-widget-for-material-selection');

type Props = {
  Title?: React.FC;
  cachedInstance: CachedInstance;
};

const Header = ItemSelection.Header.makeComponent({
  useTitle: () => I18n.useText(i18nData).steps.shared.materials.header.title,
  useSubtitle: () =>
    I18n.useText(i18nData).steps.shared.materials.header.subtitle,
});

function ConstructorWidgetForWidgetSelection({ Title, cachedInstance }: Props) {
  const text = I18n.useText(i18nData).steps.shared.materials;

  const materialUnitTypeText = Unit.useMaterialUnitTypeText(
    materialUnitTypeReference,
  );

  const handleMaterialSelect = useCallback(
    (materialKey: MaterialKey) => {
      if (materialKey === 'test') {
        QuizConstructorModal.isOpenUnit.setState(true);
      }
      cachedInstance.activeMaterialKeyUnit.setState(materialKey);
    },
    [cachedInstance.activeMaterialKeyUnit],
  );

  const Content = ItemSelection.Content.makeComponent({
    data: constructorWidgets
      .filter(x => x.key !== 'notSelected')
      .map(x => {
        const materialKey = x.key as Exclude<MaterialKey, 'notSelected'>;

        const title = (() => {
          switch (materialKey) {
            case 'test': {
              return text.kinds[materialKey].title;
            }
            case 'link':
            case 'text':
            case 'file':
            case 'video': {
              return materialUnitTypeText[materialKey];
            }
          }
        })();

        return {
          title,
          itemKey: materialKey,
          tooltip: text.kinds[materialKey].tooltip,
          iconSrc: materialIcons[materialKey].src,
        };
      }),
    onSelect: handleMaterialSelect,
  });

  return (
    <div className={b()}>
      <ItemSelection.Component
        Header={Header}
        Content={Content}
        Title={Title}
        ControlsBar={ControlsBar.Component}
      />
    </div>
  );
}

export const Component = React.memo(ConstructorWidgetForWidgetSelection);
