import * as M from 'types/serverModels';
import { makeFormElementState } from 'utils/FormState';
import { makeDerivedUnit, makePrimaryUnit } from 'utils/State';

import { makeStepVisitedUnit } from '../../utils';
import { GroupState, InvitedUserData } from './types';

export const visitedUnit = makeStepVisitedUnit();

export const authorsUnit = makePrimaryUnit<M.UserXShort[]>([]);
export const invitedAuthorsUnit = makePrimaryUnit<InvitedUserData[]>([]);

export const supervisorsUnit = makePrimaryUnit<M.UserXShort[]>([]);
export const invitedSupervisorsUnit = makePrimaryUnit<InvitedUserData[]>([]);

export const privateAccessSwitchState = makeFormElementState(false);

export const anonymousQuestionnairesSwitchState = makeFormElementState(false);

export const initialPrivateAccessGroupsState: GroupState[] = [];
export const privateAccessGroupsState = makePrimaryUnit<GroupState[]>(
  initialPrivateAccessGroupsState,
);

export const hasSomeInputUnit = makeDerivedUnit(
  authorsUnit,
  supervisorsUnit,
  invitedAuthorsUnit,
  invitedSupervisorsUnit,
  privateAccessSwitchState.units.value,
).getUnit(
  (
    authors,
    supervisors,
    invitedAuthors,
    invitedSupervisors,
    privateAccessSwitchValue,
  ) =>
    authors.length > 1 ||
    supervisors.length > 0 ||
    invitedAuthors.length > 0 ||
    invitedSupervisors.length > 0 ||
    privateAccessSwitchValue,
);
