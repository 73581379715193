import React from 'react';

import { block } from 'utils/classname';

import './style.scss';

const b = block('list-controls-right-column');

type Props = React.PropsWithChildren<{
  className?: string;
}>;

function ControlsRightColumn({ children, className }: Props) {
  return <div className={b({}, [className])}>{children}</div>;
}

export * as Button from './Button';

export const Component = React.memo(ControlsRightColumn);
