import React, { Suspense } from 'react';

import { Preloader } from 'components';
import * as M from 'types/serverModels';
import { FormElementState, makeFormElementState } from 'utils/FormState';

import {
  QuestionConstructor,
  SharedInstanceStateConstructor,
} from '../../types';
import * as PreviewModeForm from './PreviewModeForm';
import { ServerQuestion, StateInstance } from './types';

const FormExtension = React.lazy(() => import('./FormExtension'));

export type { StateInstance };

export const constructor: QuestionConstructor<
  'map',
  StateInstance,
  typeof makeInstance
> = {
  key: 'map',
  icon: 'map',
  makeInstance,
  PreviewModeForm: PreviewModeForm.Component,
  FormExtension: ({ instance }) => (
    <Suspense fallback={<Preloader.Component size="s" />}>
      <FormExtension instance={instance} />
    </Suspense>
  ),
};

export function makeServerQuestion(
  shouldShowImageOnMapState: FormElementState<boolean>,
  backgroundState: FormElementState<string | null>,
  matchingState: FormElementState<M.FilterByLocation | null>,
  scoreState: FormElementState<number | null>,
): Partial<ServerQuestion> {
  const shouldShowImageOnMap = shouldShowImageOnMapState.getValue();
  const background = backgroundState.getValue();

  return {
    type: 'map',
    settings:
      shouldShowImageOnMap && background ? { background } : undefined,
    matching: matchingState.getValue() || [0, 0, 0, 0],
    score: Number(scoreState.getValue()),
  };
}

type InitialState = {
  matching: M.FilterByLocation | null;
  score: number;
  shouldShowImageOnMap: boolean;
  background?: string;
};

export function makeInstance(
  makeSharedInstanceState: SharedInstanceStateConstructor,
  initialState?: InitialState,
): StateInstance {
  const shouldShowImageOnMapState = makeFormElementState(
    !!initialState?.shouldShowImageOnMap,
  );
  const backgroundState = makeFormElementState<string | null>(
    initialState?.background || null,
  );
  const matchingState = makeFormElementState<M.FilterByLocation | null>(
    initialState?.matching || null,
  );
  const scoreState = makeFormElementState(initialState?.score ?? null);

  return {
    kind: 'map',
    shouldShowImageOnMap: shouldShowImageOnMapState,
    background: backgroundState,
    matching: matchingState,
    score: scoreState,
    makeServerQuestion: () =>
      makeServerQuestion(
        shouldShowImageOnMapState,
        backgroundState,
        matchingState,
        scoreState,
      ),
    ...makeSharedInstanceState(),
  };
}
