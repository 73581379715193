import type React from 'react';

function getVerticalOptionsStyle(
  anchorRect: DOMRect,
  margin: number,
  defaultMaxHeight?: number,
): React.CSSProperties {
  const anchorCenter = anchorRect.top + anchorRect.height / 2;

  const isAboveClientCenter =
    document.documentElement.clientHeight / 2 - anchorCenter >= 0;

  const bottomAvailableHeight =
    document.documentElement.clientHeight - anchorRect.bottom - margin;
  const preferBottomPlacement =
    defaultMaxHeight !== undefined
      ? bottomAvailableHeight >= defaultMaxHeight
      : false;

  if (isAboveClientCenter || preferBottomPlacement) {
    return {
      top: anchorRect.bottom + margin,
      bottom: 'auto',
      maxHeight:
        defaultMaxHeight !== undefined
          ? Math.min(defaultMaxHeight, bottomAvailableHeight)
          : bottomAvailableHeight,
    };
  }

  const topAvailableHeight = anchorRect.top - margin;

  return {
    bottom: window.innerHeight - anchorRect.top + margin,
    top: 'auto',
    maxHeight:
      defaultMaxHeight !== undefined
        ? Math.min(defaultMaxHeight, topAvailableHeight)
        : topAvailableHeight,
  };
}

function getHorizontalOptionsStyle(
  anchorRect: DOMRect,
  elementRect?: DOMRect,
  centered?: boolean,
): React.CSSProperties {
  if (centered && elementRect) {
    return {
      left: anchorRect.left - (elementRect.width - anchorRect.width) / 2,
      right: 'auto',
    };
  }

  const rightAvailableWidth =
    document.documentElement.clientWidth - anchorRect.left;
  const leftAvailableWidth = anchorRect.right;

  if (rightAvailableWidth >= leftAvailableWidth) {
    return {
      left: anchorRect.left,
      right: 'auto',
    };
  }

  return {
    right: document.documentElement.clientWidth - anchorRect.right,
    left: 'auto',
  };
}

export function getFixedPositionStyle({
  anchorRect,
  elementRect,
  margin,
  defaultMaxHeight,
  centered,
}: {
  anchorRect: DOMRect;
  elementRect?: DOMRect;
  margin: number;
  defaultMaxHeight?: number;
  centered?: boolean;
}): React.CSSProperties {
  return {
    ...getHorizontalOptionsStyle(anchorRect, elementRect, centered),
    ...getVerticalOptionsStyle(anchorRect, margin, defaultMaxHeight),
  };
}
