import { createRequiredContext } from 'utils/react/RequiredContext';

import { QuestionKey } from '../../../types';

export type Dependencies = {
  onQuestionTypeChange(
    questionKey: QuestionKey,
    setQuestionType: (questionKey: QuestionKey) => void,
  ): void;
};

export const DependenciesContext = createRequiredContext<Dependencies>();
