import * as TS from 'types';

import { makeService } from './utils';

type InputData = {
  lang: TS.Language;
  ref: 'subject' | 'language';
  // Tip: term length should be > 0
  term: string;
};

type OutputData =
  | {
      label: string;
      value: TS.Language;
    }[]
  | undefined;

export const refSuggest = makeService<InputData, OutputData>(
  'ref_suggest',
  'post',
);
