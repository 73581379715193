import { isScrollable } from './isScrollable';

type ScrollableAncestors = HTMLElement | Window;

export function getScrollableAncestors(
  element: HTMLElement,
): ScrollableAncestors[] {
  function loop(node: HTMLElement, acc: HTMLElement[]): ScrollableAncestors[] {
    if (node.parentElement === null) {
      return [...acc, window];
    }

    if (node.parentElement) {
      const newAcc = isScrollable(node.parentElement)
        ? [...acc, node.parentElement]
        : [...acc];

      return loop(node.parentElement, newAcc);
    }

    console.error('unexpectedly reached end');
    return acc;
  }

  return loop(element, []);
}
