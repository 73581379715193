import * as M from 'types/serverModels';

export function convertToStreamTrackObject(
  data: M.CourseStreamServiceTrackObject,
): M.StreamTrackObject {
  switch (data.type) {
    case 'section':
    case 'subsection': {
      return {
        uuid: data.uuid,
        type: data.type,
        title: data.title,
        abstract: data.abstract,
        children: data.children?.map(x => convertToStreamTrackObject(x)),
      };
    }
    case 'project':
    case 'unit':
    case 'quiz': {
      return {
        uuid: data.uuid,
        type: data.type,
        title: data.title,
        abstract: data.abstract,
      };
    }
  }
}
