import { routeTree } from 'pages/routeTree';

import { projectBookUserUnit } from '../../../units';

export function get():
  | (typeof routeTree.LANG)['project-book']['list']['PROJECT_BOOK']
  | (typeof routeTree.LANG)['project-book']['list']['PROJECT_BOOK']['users']['USER'] {
  const projectBookUser = projectBookUserUnit.getState();

  if (projectBookUser === null) {
    return routeTree.LANG['project-book'].list.PROJECT_BOOK;
  }

  return routeTree.LANG['project-book'].list.PROJECT_BOOK.users.USER;
}
