import React from 'react';

import { classnames } from 'utils/classname';

import { b } from '../b';

type Props = {
  className?: string;
};

function Header({ children, className }: React.PropsWithChildren<Props>) {
  return <div className={classnames(b('header'), className)}>{children}</div>;
}

export const Component = React.memo(Header) as typeof Header;
