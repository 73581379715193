import React from 'react';

import { block } from 'utils/classname';

import './style.scss';

const b = block('list-title-left-column');

export type Color = 'black';

type Props = {
  useTitle(): string;
  className?: string;
};

function TitleLeftColumn({ useTitle, className }: Props) {
  return <h3 className={b({}, [className])}>{useTitle()}</h3>;
}

export const Component = React.memo(TitleLeftColumn);

export const makeComponent = (props: Props) => () => <Component {...props} />;
