import React, { useEffect, useMemo } from 'react';

import { Select } from 'components';
import i18nData from 'features/project/Constructor/i18n.json';
import { materialUnitTypeReference } from 'features/project/Constructor/i18nSharedReferences';
import { useText } from 'services/I18n';
import { makeFormElementState } from 'utils/FormState';
import { Unit } from 'utils/models';
import { useRequiredContext } from 'utils/react/RequiredContext';

import { materialKeys } from '../../../materials';
import { MaterialKey } from '../../../types';
import { WidgetContext } from '../../Context';
import { DependenciesContext } from '../Dependencies';

type Props = {};

const ActiveOption = (props: Select.ActiveOption.Props<MaterialKey>) => {
  const materialUnitTypeText = Unit.useMaterialUnitTypeText(
    materialUnitTypeReference,
  );
  const text = useText(i18nData);

  const option = props.option!;

  return (
    <Select.ActiveOption.Container.DefaultComponent>
      {(() => {
        switch (option) {
          case 'notSelected':
          case 'test': {
            return text.steps.shared.materials.kinds[option].title;
          }
          case 'link':
          case 'text':
          case 'file':
          case 'video': {
            return materialUnitTypeText[option];
          }
        }
      })()}
    </Select.ActiveOption.Container.DefaultComponent>
  );
};

const Option = (props: Select.Option.Props<MaterialKey>) => {
  const materialUnitTypeText = Unit.useMaterialUnitTypeText(
    materialUnitTypeReference,
  );
  const text = useText(i18nData);

  const option = props.option!;

  return (
    <Select.Option.Container.DefaultComponent>
      {(() => {
        switch (option) {
          case 'notSelected':
          case 'test': {
            return text.steps.shared.materials.kinds[option].title;
          }
          case 'link':
          case 'text':
          case 'file':
          case 'video': {
            return materialUnitTypeText[option];
          }
        }
      })()}
    </Select.Option.Container.DefaultComponent>
  );
};

const options = materialKeys.filter(x => x !== 'notSelected');

function Header({}: Props) {
  const { constructorWidget } = useRequiredContext(WidgetContext);
  const { onMaterialKeyChange } = useRequiredContext(DependenciesContext);

  const activeOptionState = useMemo(
    () => makeFormElementState<MaterialKey>(constructorWidget.key),
    [constructorWidget.key],
  );

  useEffect(() => {
    return activeOptionState.units.value.subscribe({
      callback: state => {
        onMaterialKeyChange(state);
      },
      name: 'question-type-state-forwarder',
    });
  }, [activeOptionState, onMaterialKeyChange]);

  return (
    <Select.Component
      ActiveOption={ActiveOption}
      Option={Option}
      activeOptionState={activeOptionState}
      options={options}
    />
  );
}

export const Component = React.memo(Header) as typeof Header;
