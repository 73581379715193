import * as R from 'ramda';

import { serverProjectDataUnit } from 'features/project/Constructor/units';
import * as M from 'types/serverModels';

import { Question } from '../shared';

export function makeServerQuestion(
  cachedQuestionInstance: Question.CachedQuestionInstance,
): M.Question | null {
  const mode = cachedQuestionInstance.mode.getState();
  const instance =
    cachedQuestionInstance.instancesCache[
      cachedQuestionInstance.activeQuestionKeyUnit.getState()
    ];

  if (mode === 'edit') {
    const serverProject = serverProjectDataUnit.getState();
    const serverQuestion = serverProject?.questions.find(
      x => x.uuid === instance.id,
    );

    return serverQuestion || null;
  }

  return R.filter(x => typeof x !== 'undefined', {
    ...instance.makeSharedServerQuestion('inputDataForm'),
    ...instance.makeServerQuestion('inputDataForm'),
  }) as M.Question;
}
