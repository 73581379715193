import React from 'react';

import { ErrorDetails } from 'features/global';
import i18nData from 'features/project/Constructor/i18n.json';
import { OkModal } from 'features/project/Constructor/subfeatures';
import { I18n } from 'services';
import { makePrimaryUnit } from 'utils/State';

type Props = {};

const isOpenUnit = OkModal.makeIsOpenUnit();
const messageUnit = makePrimaryUnit('');

export const open = (message: string = '') => {
  messageUnit.setState(message);
  isOpenUnit.setState(true);
};

const useModalText = () =>
  I18n.useText(i18nData).steps.findingsSettings
    .questionsNotSavedOrOtherErrorModal;

function QuestionsNotSavedOrOtherError({}: Props) {
  const Header = OkModal.Header.useFromTitle({
    useTitle: () => useModalText().title,
  });

  const message = messageUnit.useState();

  return (
    <OkModal.Component Header={Header} isOpenUnit={isOpenUnit}>
      {useModalText().text}
      {message && <ErrorDetails.Component message={message} alignCenter />}
    </OkModal.Component>
  );
}

export const Component = React.memo(QuestionsNotSavedOrOtherError);
