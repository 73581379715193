export function getVHStyleVariable() {
  return 'var(--vh, 1vh)';
}

export function getVHSize() {
  return window.document.documentElement.clientHeight * 0.01;
}

export function setVHVariable() {
  const vh = getVHSize();

  document.documentElement.style.setProperty('--vh', `${vh}px`);
}
