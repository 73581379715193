import React, { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import * as PageFeaturesRegistry from 'services/PageFeaturesRegistry';
import * as Routing from 'services/Routing';
import { PrimaryStateUnit } from 'utils/State';
import { useRequiredContext } from 'utils/react/RequiredContext';

import { FallbackContext } from './Context';
import { Mode } from './types';

export function makeComponent<T extends Record<string, any>>(
  loadModule: () => Promise<void>,
  modeUnit: PrimaryStateUnit<Mode<T>>,
  Preloader: React.FC,
  prefetched: boolean,
  name: string,
): React.FC<T> {
  if (process.env.BUILD_TARGET === 'server' && prefetched) {
    loadModule();
  }

  return (props: T) => {
    const mode = modeUnit.useState();

    useEffect(() => {
      if (modeUnit.getState().kind === 'initial') {
        loadModule();

        const location = Routing.getCurrentLocation();

        if (location === null) {
          throw Error(`location is null`);
        }

        const features = PageFeaturesRegistry.getFeaturesForPath(
          location.pathname,
        );

        if (
          process.env.NODE_ENV === 'development' &&
          !features.some(x => x.name === name)
        ) {
          throw Error(`rendered unregistered feature ${name}`);
        }
      }
    }, []);

    const { FallbackComponent } = useRequiredContext(FallbackContext);

    switch (mode.kind) {
      case 'loaded':
        return (
          <ErrorBoundary FallbackComponent={FallbackComponent}>
            <mode.Component {...props} />
          </ErrorBoundary>
        );

      case 'initial':
      case 'pending':
        return <Preloader data-feature={name} />;
    }
  };
}
