import * as R from 'ramda';

import * as M from 'types/serverModels';

export function equals(
  serverQuiz: M.Quiz | null,
  quizWriteData: M.QuizWriteData,
) {
  if (!serverQuiz) {
    return false;
  }

  return Object.entries(quizWriteData).every(([key, x]) =>
    R.equals(x, serverQuiz[key as keyof M.Quiz]),
  );
}
