import { I18n } from 'services';
import { makeFormElementState } from 'utils/FormState';
import { VennChart } from 'utils/business';
import { makeRangeValidators, nonNull } from 'utils/validators';

export function forNumberQuestion(
  question: VennChart.QuestionWithNumber,
  name: string,
  value?: {
    from?: number | null;
    to?: number | null;
  },
): VennChart.SetForNumberQuestion {
  const fromState = makeFormElementState<number | null>(value?.from ?? null);
  const toState = makeFormElementState<number | null>(value?.to ?? null);

  const { fromRangeValidator, toRangeValidator } = makeRangeValidators(
    {
      valueUnit: fromState.units.value,
      messageReference: I18n.constants.emptyReference,
    },
    {
      valueUnit: toState.units.value,
      messageReference: I18n.constants.emptyReference,
    },
  );

  fromState.setValidators(
    nonNull(I18n.constants.emptyReference),
    fromRangeValidator,
  );
  toState.setValidators(
    nonNull(I18n.constants.emptyReference),
    toRangeValidator,
  );

  return {
    kind: 'for-number-question',
    question,
    name,
    range: {
      from: fromState,
      to: toState,
    },
  };
}
