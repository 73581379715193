import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  course: M.Course['uuid'];
  tracks: M.CourseTrackObject['uuid'][];
  name?: M.MultilingString;
  active?: 0 | 1;
  open?: 0 | 1;
};

type OutputData = M.CourseStreamServiceStreamOutput;

export const courseCreateStream = makeService<InputData, OutputData>(
  'course_create_stream',
  'post',
);
