import React from 'react';

import { VariantSelectionQuestion } from 'features/project/Constructor/subfeatures';

import { PreviewModeFormProps } from '../../../types';
import { StateInstance } from '../types';
import { useModel } from './useModel';

function PreviewModeForm({ instance }: PreviewModeFormProps<StateInstance>) {
  const model = useModel(instance);

  return <VariantSelectionQuestion.Component model={model} />;
}

export const Component = React.memo(PreviewModeForm) as typeof PreviewModeForm;
