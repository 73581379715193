import React, { useMemo } from 'react';

import { block } from 'utils/classname';

import './style.scss';

const b = block('modal-header');

export const makeFromTitle = (useTitle: () => React.ReactNode) => () => (
  <h4 className={b('title')}>{useTitle()}</h4>
);

export const useFromTitle = ({
  useTitle,
}: {
  useTitle: () => React.ReactNode;
}) => useMemo(() => makeFromTitle(useTitle), [useTitle]);
