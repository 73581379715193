import { makeFeature } from 'services/Feature';

import { ComponentType } from './Component';
import i18nData from './i18n.json';

export const feature = makeFeature({
  name: 'List',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "list" */
      './Component'
    ),
  i18nData,
  i18nSharedReferences: null,
  subfeatures: [],
});

export * from './types';

// TODO extend feature service for lazy loading of multiple components
export * as StringTitle from './StringTitle';
export * as TwoColumnHeader from './TwoColumnHeader';
export * as SubList from './SubList';

export const Component = feature.Component as ComponentType;
