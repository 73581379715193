import * as M from 'types/serverModels';

type TrackObject =
  | (Pick<
      | M.CourseStreamServiceSectionTrackObjectOutput
      | M.CourseStreamServiceSubsectionTrackObjectOutput,
      'type'
    > & { children?: TrackObject[] })
  | Pick<M.CourseStreamServiceProjectTrackObjectOutput, 'type'>
  | Pick<M.CourseStreamServiceUnitTrackObjectOutput, 'type' | 'object'>
  | Pick<M.CourseStreamServiceQuizTrackObjectOutput, 'type'>;

type Options<T extends TrackObject> = {
  nameConverter?(trackObject: T & { type: 'unit' }): string;
};

export function getUnits<T extends TrackObject, U extends M.Unit>(
  trackObjects: T[],
  options: Options<T> = {},
): U[] {
  return trackObjects.reduce<U[]>((acc, x) => {
    switch (x.type) {
      case 'section':
      case 'subsection': {
        if (x.children === undefined || x.children.length < 1) {
          return [...acc];
        }

        return [...acc, ...getUnits<T, U>(x.children as T[], options)];
      }

      case 'unit': {
        const unit = {
          ...x.object,
          name:
            options.nameConverter !== undefined
              ? options.nameConverter(x as T & { type: 'unit' })
              : x.object.name,
        } as U;

        return [...acc, unit];
      }
      default: {
        return [...acc];
      }
    }
  }, []);
}
