import React from 'react';

import { Page } from 'services';
import { block } from 'utils/classname';

import { routeTree } from '../routeTree';
import * as features from './features';
import './style.scss';

const b = block('sign-up-page');

function SignUp() {
  return (
    <div className={b()}>
      <features.SignUpPageFormFeature.Component />
    </div>
  );
}

export const Component = Page.makePage({
  path: routeTree.LANG['sign-up'].getPath(),
  routeProps: { exact: true },
  scrollTo: 'top-on-mount',
  Component: React.memo(SignUp),
  features: Object.values(features).map(x => x.feature),
});
