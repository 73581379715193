import * as M from 'types/serverModels';
import { makeFormElementState } from 'utils/FormState';

import { State } from './types';

export function makeState(
  initialState: M.WidgetDescriptorSort = {
    field: 'Date',
    order: 'Desc',
  },
): State {
  return {
    fieldState: makeFormElementState(initialState.field),
    orderState: makeFormElementState(initialState.order),
  };
}
