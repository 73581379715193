import { SuccessfulState } from 'services/API/types';
import { PENDING_ACTION_STATE } from 'shared/constants';
import * as M from 'types/serverModels';

export function getNotOutdatedSocketNotifications(
  buff: M.SocketNotification[],
  callState: SuccessfulState<M.Notification[]>,
): M.SocketNotification[] {
  const withoutPendingDuplicateNotifications = buff.filter(x => {
    switch (x.ntype) {
      case 'project_author_invitation':
      case 'project_supervisor_invitation':
      case 'group_invitation':
      case 'group_knock': {
        if (x.state !== PENDING_ACTION_STATE.pending) {
          return true;
        }

        break;
      }
      case 'private_message': {
        if (x.read) {
          return true;
        }

        break;
      }
      case 'private_message_read': {
        return true;
      }
    }

    return callState.data.find(y => y.uuid === x.uuid) === undefined;
  });
  const pendingOnlyNotifications = [
    ...callState.data,
    ...withoutPendingDuplicateNotifications.filter(x => {
      switch (x.ntype) {
        case 'project_author_invitation':
        case 'project_supervisor_invitation':
        case 'group_invitation':
        case 'group_knock': {
          return x.state === PENDING_ACTION_STATE.pending;
        }
        case 'private_message': {
          return !x.read;
        }
        case 'private_message_read': {
          return false;
        }
      }

      return false;
    }),
  ];
  const withoutExtraResolvedNotifications =
    withoutPendingDuplicateNotifications.reduce<M.SocketNotification[]>(
      (acc, x) => {
        if (x.ntype === 'private_message_read') {
          return [
            ...acc,
            {
              ...x,
              uuid: x.uuid.filter(
                y =>
                  pendingOnlyNotifications.find(z => z.uuid === y) !==
                  undefined,
              ),
            },
          ];
        }

        if (
          pendingOnlyNotifications.find(y => y.uuid === x.uuid) !== undefined
        ) {
          return [...acc, x];
        }

        return [...acc];
      },
      [],
    );

  return withoutExtraResolvedNotifications;
}
