import React from 'react';

import { block, classnames } from 'utils/classname';

import './style.scss';
import { Element } from './types';

export { makeUseVerticallyMovableELements } from './useVerticallyMovableElements';

const b = block('vertically-movable-element');

type Props<T> = {
  element: Element<T>;
  className?: string;
};

function VerticallyMovableElement<T>({
  element,
  className,
  children,
}: React.PropsWithChildren<Props<T>>) {
  return (
    <div
      className={classnames(b(), className)}
      ref={element.ref}
      style={element.style}
      onTransitionEnd={element.handleTransitionEnd}
    >
      {children}
    </div>
  );
}

export const Component = React.memo(
  VerticallyMovableElement,
) as typeof VerticallyMovableElement;
export type { Element };
