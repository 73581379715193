import { Logger } from 'utils/Logger';

import { StateSubscriber } from './types';

export function notifySubscribers<T>(
  subscribersToNotify: Array<StateSubscriber<T>>,
  newState: T,
  prevState: T,
  logger: Logger,
) {
  while (subscribersToNotify.length > 0) {
    const subscriber = subscribersToNotify.pop()!;
    logger.log(`(${subscriber.name}) about to notify with value`, newState);
    subscriber.callback(newState, prevState);
  }
}
