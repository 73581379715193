import { useMemo } from 'react';

import { FormElementState, FormElementStateUnits, FormNode } from './types';

export function makeBridgeUnitConstructor<T>(
  formNode: FormNode & { reset(): void },
  units: FormElementStateUnits<T>,
) {
  return function getBridgeUnit<Y>(
    forwardStateConverter: (state: T) => Y,
    backwardStateConverter: (state: Y) => T,
    backwardFilterPredicate?: (state: Y) => boolean,
  ): FormElementState<Y> {
    const valueBridgeUnit = units.value.getBridgeUnit(
      forwardStateConverter,
      backwardStateConverter,
      backwardFilterPredicate,
    );

    const bridgeUnits: FormElementStateUnits<Y> = {
      ...units,
      value: valueBridgeUnit,
    };

    const getBridgeBridgeUnit = makeBridgeUnitConstructor(
      formNode,
      bridgeUnits,
    );

    function useBridgeUnitMemo<U>(
      forwardStateConverter: (state: Y) => U,
      backwardStateConverter: (state: U) => Y,
      backwardFilterPredicate?: (state: U) => boolean,
    ): FormElementState<U> {
      return useMemo(
        () =>
          getBridgeBridgeUnit<U>(
            forwardStateConverter,
            backwardStateConverter,
            backwardFilterPredicate,
          ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [getBridgeBridgeUnit],
      );
    }

    return {
      kind: 'form-element-state',
      getValue: () => bridgeUnits.value.getState(),
      formNode: {
        ...formNode,
        getValue: valueBridgeUnit.getState,
      },
      units: bridgeUnits,
      getBridgeUnit: getBridgeBridgeUnit,
      useBridgeUnitMemo,
      setValidators: () => {
        // TODO implement
        throw Error('not implemented');
      },
    };
  };
}
