import * as M from 'types/serverModels';
import { FormElementState } from 'utils/FormState';
import { makePrimaryUnit } from 'utils/State';
import { makeQuestionIDToQuestionTextUnit } from 'utils/models/Project';

import { Props } from './types';

export const makePropsUnits = (
  selectedQuestionIDState: FormElementState<string | null>,
  questions: M.Question[],
  types: M.Question['type'][],
  optional: boolean = false,
): Props => {
  return {
    selectedQuestionIDState,
    questionIDToQuestionTextUnit: makeQuestionIDToQuestionTextUnit(questions),
    questionIDsUnit: makePrimaryUnit(
      optional
        ? [
            null,
            ...questions.filter(x => types.includes(x.type)).map(x => x.uuid),
          ]
        : questions.filter(x => types.includes(x.type)).map(x => x.uuid),
    ),
  };
};
