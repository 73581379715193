import * as M from 'types/serverModels';

import { Subscriber } from './types';

export const subscribers: Record<M.Notification['ntype'], Subscriber[]> = {
  group_invitation: [],
  group_knock: [],
  private_message: [],
  project_author_invitation: [],
  project_supervisor_invitation: [],
};
