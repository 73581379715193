import React, { useEffect } from 'react';

import { ErrorModal } from 'features/project/Constructor/subfeatures';
import { makePrimaryUnit } from 'utils/State';

import { StepErrorsDescription } from '../../types';
import * as StepErrors from './StepErrors';

type Props = {};

export const errorsDescriptionsUnit = makePrimaryUnit<StepErrorsDescription[]>(
  [],
);

export const isOpenUnit = ErrorModal.makeIsOpenUnit();

function ErrorsModal({}: Props) {
  const errors = errorsDescriptionsUnit.useState();

  useEffect(() => {
    return () => {
      errorsDescriptionsUnit.resetState();
      isOpenUnit.resetState();
    };
  }, []);

  return (
    <ErrorModal.Component isOpenUnit={isOpenUnit} alignCenter={false} size="m">
      {errors.map((x, index) => (
        <StepErrors.Component key={index} description={x} />
      ))}
    </ErrorModal.Component>
  );
}

export const Component = React.memo(ErrorsModal) as typeof ErrorsModal;
