import { QuizQuestionKind } from 'types';

import { QuestionKey } from './types';

export function getQuestionKindsSet(
  questionKey: QuestionKey,
): QuizQuestionKind[] {
  switch (questionKey) {
    case 'image':
      return ['image'];
    case 'map':
      return ['map'];
    case 'match':
      return ['text-to-text-match', 'image-to-text-match'];
    case 'number':
      return ['number'];
    case 'text':
      return ['single-line-string', 'multiline-string'];
    case 'variantSelection':
      return ['single-variant-selection', 'multiple-variants-selection'];
  }
}
