import { Location } from 'history';

import type { ChildEntrySection } from '../../types';
import { isLinkEntryActive } from './isLinkEntryActive';

export function isParentMenuEntryActive(
  childrenSections: ChildEntrySection[],
  location: Location,
) {
  return childrenSections.some(x =>
    x.some(y => {
      switch (y.kind) {
        case 'link': {
          return isLinkEntryActive(y, location);
        }
        default: {
          return false;
        }
      }
    }),
  );
}
