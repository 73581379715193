import { I18n } from 'services';
import * as TS from 'types';
import * as M from 'types/serverModels';

export function getName(
  stream: Pick<M.CourseStreamServiceStreamOutput, 'name' | 'cdate'>,
  lang: TS.Language,
) {
  return (
    I18n.getOptionalMultilingTranslation(lang, stream.name) ||
    I18n.getFormattedDate(lang, stream.cdate)
  );
}

export function useName(
  stream: Pick<M.CourseStreamServiceStreamOutput, 'cdate'> & {
    name: I18n.MultilangFormState;
  },
  lang: TS.Language,
) {
  const name = stream.name.formElementState.units.value.useState();

  return name || I18n.getFormattedDate(lang, stream.cdate);
}
