import * as M from 'types/serverModels';

import { makeService } from '../utils';

type InputData = {
  uuid: M.Quiz['uuid'];
};

type Output = {
  quiz: M.Quiz;
};

export const get = makeService<InputData, Output, M.Quiz>(
  ({ uuid }) => `quiz/${uuid}`,
  'api-v2-get',
  false,
  ({ quiz }) => quiz,
);
