import * as M from 'types/serverModels';

import { makeService } from '../../../../utils';

type InputData = {
  schoolID: M.UUID;
  keyword: string;
};

type APIOutputData = {
  student: M.MeschStudent[];
};

export const get = makeService<InputData, APIOutputData>(
  ({ schoolID, keyword }, convertToSearchParams) =>
    `mesch/school/${schoolID}/student?${convertToSearchParams({ keyword })}`,
  'api-v2-get',
);
