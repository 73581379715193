import React from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import { block, classnames } from 'utils/classname';

import type * as Types from '../../types';
import { isLinkEntryActive } from '../../utils';
import './style.scss';

const b = block('main-menu-child-entry');

type Props<T = Types.ChildEntry> = {
  childEntry: T;
  className: string;
  onSelect(): void;
};

const LinkEntry = React.memo(
  ({ childEntry, className, onSelect }: Props<Types.LinkEntry>) => {
    const location = useLocation();

    const isActive = isLinkEntryActive(childEntry, location);

    return (
      <Link
        className={classnames(b({ active: isActive }), className)}
        to={childEntry.href}
        onClick={onSelect}
      >
        {childEntry.label}
      </Link>
    );
  },
);

function ChildEntry({ childEntry, className, onSelect }: Props) {
  switch (childEntry.kind) {
    case 'link':
      return (
        <LinkEntry
          childEntry={childEntry}
          className={className}
          onSelect={onSelect}
        />
      );
    case 'action':
      const handleClick = () => {
        onSelect();
        childEntry.onSelect();
      };

      return (
        <div className={classnames(b(), className)} onClick={handleClick}>
          {childEntry.label}
        </div>
      );
  }
}

export const Component = React.memo(ChildEntry);
