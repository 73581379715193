import {
  makeDerivedUnit,
  makePrimaryUnit,
  PrimaryStateUnit,
} from 'utils/State';

import { CachedInstance, EnumeratedInstance } from '../types';

export const makeInstancesUnit = () => makePrimaryUnit<CachedInstance[]>([]);

export const makeEnumeratedInstancesUnit = (
  instancesUnit: PrimaryStateUnit<CachedInstance[]>,
) =>
  makeDerivedUnit(instancesUnit).getUnit(instances =>
    instances.map<EnumeratedInstance>((x, index) => ({
      instance: x,
      number: index + 1,
    })),
  );
