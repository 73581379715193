import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  conclusions: M.UUID;
};

export const conclusionsList = makeService<InputData, M.Conclusion>(
  'conclusions_list',
  'get',
);
