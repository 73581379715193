import * as M from 'types/serverModels';

import { makeService } from '../../utils';

type PathInputData = {
  uuid: Exclude<M.ProjectBook['uuid'], undefined>;
};

type BodyInputData = {
  set: {
    uuid: Exclude<M.ProjectBookChange['uuid'], undefined>;
    set: M.ProjectBookChangeNotificationStatus;
  }[];
};

type InputData = PathInputData & BodyInputData;

type OutputData = void;

function extractResponse(data: OutputData): OutputData {
  return data;
}

export const set = makeService<InputData, OutputData, OutputData>(
  ({ uuid, ticket }, convertToSearchParams) => {
    return `project_book/${uuid}/notification/set/?${convertToSearchParams({
      ticket,
    })}`;
  },
  'api-v2-put',
  false,
  extractResponse,
  {
    extractBodyInput: ({ uuid, ticket, ...bodyInput }) => {
      return bodyInput.set;
    },
  },
);
