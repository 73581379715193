import { makeFeature } from 'services/Feature';

import i18nData from './i18n.json';
import * as subfeatures from './subfeatures';

export const feature = makeFeature({
  name: 'GalleryWidgetDatafulView',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "gallery-widget-dataful-view" */
      './ImagesGrid'
    ),
  i18nSharedReferences: null,
  i18nData,
  subfeatures: Object.values(subfeatures).map(x => x.feature),
});

export type { Props } from './ImagesGrid';

export const Component = feature.Component;

export type { QuestionType } from './types';
