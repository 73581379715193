import React from 'react';

import { TextInput, IconContainer } from 'components';
import { I18n } from 'services';
import roundedArrowDownIconSrc from 'shared/images/roundedArrowDown.svg';
import trashIconSrc from 'shared/images/trash.svg';
import { FormElementState } from 'utils/FormState';
import { block } from 'utils/classname';
import {
  createRequiredContext,
  useRequiredContext,
} from 'utils/react/RequiredContext';

import i18nData from '../../../../../../../i18n.json';
import { ScoreInput } from '../../../../shared';
import './style.scss';

const b = block('quiz-variant-expansion-panel-header');

type HeaderDependencies = {
  textFormElementState: FormElementState<string>;
  scoreFormElementState: FormElementState<number | null>;
  onMoveUp(): void;
  onMoveDown(): void;
  onRemove(): void;
};

export const DependenciesContext = createRequiredContext<HeaderDependencies>();

function Header() {
  const {
    textFormElementState,
    scoreFormElementState,
    onMoveUp,
    onMoveDown,
    onRemove,
  } = useRequiredContext(DependenciesContext);
  const text = I18n.useText(i18nData);

  return (
    <div className={b()}>
      <div className={b('inputs')}>
        <TextInput.Component
          className={b('text-input')}
          formElementState={textFormElementState}
          placeholder={
            text.questions.list.variantSelection.variantTextInputPlaceholder
          }
          errorRows={1}
        />
        <ScoreInput.Component
          className={b('text-input')}
          formElementState={scoreFormElementState}
        />
      </div>
      <div className={b('controls')}>
        <div className={b('move-controls')}>
          <IconContainer.Component
            backgroundDarknessLevel={2}
            size="s-half"
            onClick={onMoveUp}
          >
            <img
              className={b('arrow-up-icon')}
              src={roundedArrowDownIconSrc}
              alt="arrow-up"
            />
          </IconContainer.Component>
          <IconContainer.Component
            backgroundDarknessLevel={2}
            size="s-half"
            onClick={onMoveDown}
            className={b('arrow-down-icon-container')}
          >
            <img
              src={roundedArrowDownIconSrc}
              alt="arrow-down"
              className={b('arrow-down-icon')}
            />
          </IconContainer.Component>
        </div>
        <IconContainer.Component
          size="s"
          backgroundDarknessLevel={2}
          onClick={onRemove}
          className={b('delete-icon-container')}
        >
          <img src={trashIconSrc} className={b('delete-icon')} alt="remove" />
        </IconContainer.Component>
      </div>
    </div>
  );
}

export const Component = React.memo(Header);
