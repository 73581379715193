import * as R from 'ramda';

import { getProjectLanguage } from 'features/project/Constructor/utils';
import { I18n } from 'services';
import * as M from 'types/serverModels';
import { QuestionKind } from 'utils/business';

import { Question } from '../shared';
import { config } from './config';
import { descriptionState } from './units/description';
import { cachedStoreInstancesUnit } from './units/instances';
import { quizUnit } from './units/quizUnit';

export function fillConclusions(quiz: M.Quiz) {
  const language = getProjectLanguage();
  const t = I18n.makeGetMultilingTranslation(language);

  const [expectedQuestions, unexpectedQuestions] = R.partition(x => {
    if (x.type === 'match') {
      throw Error(
        `question ${x.uuid} has "match" type. Question with this type is not supported`,
      );
    }

    const questionKind = QuestionKind.makeQuestionKind(x.type);

    const questionKey = Question.questionKindToQuestionKeyMapping[questionKind];

    return (
      questionKey !== null &&
      config.questionKeyToConstructorWidget[questionKey] !== undefined
    );
  }, quiz.questions);

  if (unexpectedQuestions.length > 0) {
    console.error(
      'received conclusions with unexpected question types',
      unexpectedQuestions,
    );
  }

  cachedStoreInstancesUnit.setState({
    kind: 'ungrouped',
    instances: expectedQuestions.map(x => {
      const instance = Question.makeQuestionInstanceFromServerQuestion(
        x,
        language,
      );

      return Question.makeCachedInstanceFromQuestionInstance(
        instance,
        instance.isValid() ? 'preview' : 'edit',
        config.questionKeyToConstructorWidget,
      );
    }),
  });

  if (quiz.description) {
    descriptionState.units.value.setState(t(quiz.description));
  }

  quizUnit.setState(quiz);
}
