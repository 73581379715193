export const getAvailableSpace = (
  container: HTMLElement,
  element: HTMLElement,
) => {
  const containerRect = container.getBoundingClientRect();
  const elementRect = element.getBoundingClientRect();

  return {
    top: elementRect.top - containerRect.top,
    left: elementRect.left - containerRect.left,
    right: containerRect.right - elementRect.right,
    bottom: containerRect.bottom - elementRect.bottom,
  };
};
