import React from 'react';

import * as LinkComponent from 'components/Link';
import { block } from 'utils/classname';

import { RightPartPlugin } from '../../RightPartPlugin';
import './style.scss';

const b = block('text-input-right-part-link');

type Props = {
  Icon?: React.FC<{ className?: string }>;
  useText(): string;
  onClick(): void;
};

function Link({ Icon, useText, onClick }: Props) {
  return (
    <LinkComponent.Component
      className={b()}
      size="m"
      color="accent-2"
      weight="normal"
      nowrap="white-space"
      onClick={onClick}
    >
      {Icon && <Icon className={b('icon')} />}
      {useText()}
    </LinkComponent.Component>
  );
}

const Component = React.memo(Link) as typeof Link;
export const makePlugin = (ownProps: Props): RightPartPlugin => ({
  shouldBeSlidOnMobile: true,
  Component: () => <Component {...ownProps} />,
});
