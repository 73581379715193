import React from 'react';

import { block } from 'utils/classname';

import './style.scss';

const b = block('pending-sub-list-entry-controls-container');

type Props = React.PropsWithChildren<{}>;

function Container({ children }: Props) {
  return <div className={b()}>{children}</div>;
}

export const Component = React.memo(Container) as typeof Container;
