import * as R from 'ramda';
import { useEffect, useState } from 'react';

import { makeLogger } from 'utils/Logger';
import { useIsMountedRef } from 'utils/react';

import { AbstractStateUnit, StateSubscriber, UnitDebugData } from '../types';
import { DerivedStateUnit, DerivedUnitGetter, UnitsState } from './types';

export function makeDerivedUnit<T extends Array<AbstractStateUnit<unknown>>>(
  ...stateUnits: T
): DerivedUnitGetter<T> {
  return {
    getUnit<R>(
      deriver: (...unitsState: UnitsState<T>) => R,
      debugData?: UnitDebugData,
    ): DerivedStateUnit<R> {
      const { name, debugMode = true } = debugData || {
        name: 'not-specified',
        debugMode: false,
      };

      const { log } = makeLogger(name, debugMode);

      const initialDerivableStates: unknown[] = stateUnits.map(
        x => x.initialState,
      );

      let lastKnownDerivableStates = initialDerivableStates.slice();

      const initialState = deriver(...(initialDerivableStates as any));

      let derivedState: R = initialState;
      let prevDerivedState: R = initialState;

      log('initial derivable states', initialDerivableStates);
      log('initial state', initialState);

      let subscribers: Array<StateSubscriber<R>> = [];
      let unsubscribers: Array<() => void> = [];

      const getCurrentDerivedState = () => {
        const currentDerivableStates = stateUnits.map(x => x.getState());

        return deriver(...(currentDerivableStates as any));
      };

      const updateDerivedStateIfRequired = () => {
        const currentDerivableStates = stateUnits.map(x => x.getState());

        if (
          !currentDerivableStates.every(
            (x, index) => x === lastKnownDerivableStates[index],
          )
        ) {
          prevDerivedState = derivedState;
          derivedState = deriver(...(currentDerivableStates as any));
          log(
            'updating derivedState with',
            derivedState,
            currentDerivableStates,
            lastKnownDerivableStates,
          );
          lastKnownDerivableStates = currentDerivableStates;
        }
      };

      const onUnitSubscribe = () => {
        if (subscribers.length === 0) {
          log('subscrbing to dependency units');
          updateDerivedStateIfRequired();

          unsubscribers = stateUnits.map((unit, index) => {
            return unit.subscribe({
              name,
              callback: state => {
                log('new dependency state', index, state);
                lastKnownDerivableStates[index] = state;
                prevDerivedState = derivedState;
                log('-> prev derived state', prevDerivedState);
                derivedState = deriver(...(lastKnownDerivableStates as any));
                log('-> new derived state', derivedState);
                if (!R.equals(prevDerivedState, derivedState)) {
                  log('notify subscribers', subscribers.length);
                  subscribers.forEach(subscriber =>
                    subscriber.callback(derivedState, prevDerivedState),
                  );
                }
              },
            });
          });
        }
      };

      const onUnitUnsubscribe = () => {
        if (subscribers.length === 0) {
          log('unsubscribing from dependency units');
          unsubscribers.forEach(f => f());
          unsubscribers = [];
        }
      };

      const subscribe = (subscriber: StateSubscriber<R>) => {
        log('subscribe', subscriber);
        onUnitSubscribe();
        subscribers.push(subscriber);

        return () => {
          subscribers = subscribers.filter(x => x !== subscriber);
          onUnitUnsubscribe();
        };
      };

      const subscribeInUseState = (
        subscriber: StateSubscriber<R>,
        initializedState: R,
      ) => {
        log('subscribe', subscriber);

        onUnitSubscribe();

        if (initializedState !== derivedState) {
          log('forcing state update to', derivedState);
          subscriber.callback(derivedState, prevDerivedState);
        }

        subscribers.push(subscriber);

        return () => {
          subscribers = subscribers.filter(x => x !== subscriber);
          onUnitUnsubscribe();
        };
      };

      return {
        kind: 'derived',
        subscribe,
        getState: () => {
          return getCurrentDerivedState();
        },
        initialState,
        isStateUnit: true,
        useState: (debugName: string = 'not-specified') => {
          const [state, setState] = useState<R>(() => {
            return derivedState;
          });
          const isMountedRef = useIsMountedRef();

          useEffect(() => {
            return subscribeInUseState(
              {
                callback: newState => {
                  if (isMountedRef.current) {
                    setState(newState);
                  }
                },
                name: debugName,
              },
              state,
            );
            // eslint-disable-next-line react-hooks/exhaustive-deps
          }, [debugName, subscribeInUseState]);

          // NOTE we return derivedState instead of state to prevent
          // cached state returning on unit switching for the same place
          // of the hook call
          return derivedState;
        },
      };
    },
  };
}
