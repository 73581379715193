import { useCallback } from 'react';

import { routeTree } from 'pages/routeTree';
import { I18n } from 'services';
import * as M from 'types/serverModels';

export function useGetReason(text: {
  courseEntityLabel: string;
  projectEntityLabel: string;
  webinarEntityLabel: string;
}) {
  const t = I18n.useGetMultilingTranslation();

  const lang = I18n.activeLangStateUnit.useState();

  return useCallback(
    (
      perk: Pick<M.Perk, 'reason'>,
      courseIdx: M.User['courseIdx'],
      projectIdx: M.User['projectIdx'],
      webinarIdx: M.User['webinarIdx'],
    ) => {
      if (perk.reason === undefined) {
        return null;
      }

      if ('course' in perk.reason) {
        const courseIdxItem = courseIdx[perk.reason.course];

        if (courseIdxItem === undefined) {
          return null;
        }

        const courseID = courseIdxItem['code'] || courseIdxItem['uuid'];
        const href =
          courseID &&
          routeTree.LANG.course.list.COURSE.getPath({
            routeParams: {
              LANG: lang,
              COURSE: courseID,
            },
          });

        return {
          text: text.courseEntityLabel,
          link: {
            href,
            text: courseIdxItem.name ? t(courseIdxItem.name) : '',
          },
        };
      }

      if ('project' in perk.reason) {
        const projectIdxItem = projectIdx[perk.reason.project];

        if (projectIdxItem === undefined) {
          return null;
        }

        const projectID = projectIdxItem['code'] || projectIdxItem['uuid'];
        const href =
          projectID &&
          routeTree.LANG.project.list.PROJECT.general.getPath({
            routeParams: {
              LANG: lang,
              PROJECT: projectID,
            },
          });

        return {
          text: text.projectEntityLabel,
          link: {
            href,
            text: t(projectIdxItem.title),
          },
        };
      }

      if ('webinar' in perk.reason && perk.reason.webinar) {
        const webinarIdxItem = webinarIdx[perk.reason.webinar];

        if (webinarIdxItem === undefined) {
          return null;
        }

        const href =
          webinarIdxItem.uuid &&
          routeTree.LANG.webinar.page.WEBINAR_UUID.getPath({
            routeParams: {
              LANG: lang,
              WEBINAR_UUID: webinarIdxItem.uuid,
            },
          });

        return {
          text: text.webinarEntityLabel,
          link: { href, text: webinarIdxItem.title },
          date: webinarIdxItem.datetime,
        };
      }

      return null;
    },
    [
      lang,
      text.courseEntityLabel,
      text.projectEntityLabel,
      text.webinarEntityLabel,
      t,
    ],
  );
}
