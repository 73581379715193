import { useEffect } from 'react';

import * as M from 'types/serverModels';
import { makeDerivedUnit, PrimaryStateUnit } from 'utils/State';

import * as services from '../services';
import { CallState } from '../types';

const fileTypesRefsCallStateUnit =
  services.refLoad.makeCallStateUnit() as PrimaryStateUnit<
    CallState<M.FileTypesReferenceBook[]>
  >;

export const fileTypesCallStateUnit = makeDerivedUnit(
  fileTypesRefsCallStateUnit,
).getUnit<
  CallState<Record<M.FileTypesReferenceBook['code'], M.FileTypesReferenceBook>>
>(callState => {
  if (callState.kind === 'successful') {
    const data = callState.data.reduce<
      Record<M.FileTypesReferenceBook['code'], M.FileTypesReferenceBook>
    >((acc, x) => {
      return {
        ...acc,
        [x.code]: x,
      };
    }, {});

    return {
      kind: 'successful',
      data,
    };
  }

  return callState;
});

const call = services.refLoad.makeCall(
  fileTypesRefsCallStateUnit as PrimaryStateUnit<CallState<M.ReferenceBook[]>>,
);

let wasCalled = false;

export const callRefService = () => {
  if (!wasCalled) {
    wasCalled = true;

    call({ ref: 'file_type' });
  }
};

export function useFileTypesRefsCallEffect() {
  const callState = fileTypesRefsCallStateUnit.useState();

  useEffect(() => {
    callRefService();
  }, [callState.kind]);
}

export function useCallState() {
  const callState = fileTypesCallStateUnit.useState();

  useFileTypesRefsCallEffect();

  return callState;
}
