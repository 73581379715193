import React, { useRef, useEffect, useCallback } from 'react';

import { Page } from 'services';
import { ReactComponent as CrossIcon } from 'shared/images/cross.svg';
import { PrimaryStateUnit } from 'utils/State';
import { block } from 'utils/classname';

import { Size } from '../types';
import './style.scss';

const b = block('m-modal');

export type Props = {
  Header?: React.FC;
  isOpenUnit: PrimaryStateUnit<boolean>;
  onClose?(): void;
  size: Size;
  className?: string;
};

function MModal({
  isOpenUnit,
  children,
  onClose,
  Header,
  className,
  size,
}: React.PropsWithChildren<Props>) {
  const isOpen = isOpenUnit.useState();

  const modalRef = useRef<HTMLDivElement | null>(null);

  Page.useSetScroll(isOpen);

  useEffect(() => {
    if (isOpen) {
      modalRef.current?.focus();
    }
  }, [isOpen]);

  const handleClick: React.MouseEventHandler = useCallback(event => {
    event.stopPropagation();
  }, []);

  const handleCloseIconClick = useCallback(() => {
    isOpenUnit.setState(false);
    onClose?.();
  }, [isOpenUnit, onClose]);

  const handleCrossIconKeyDown: React.KeyboardEventHandler = useCallback(
    event => {
      const shouldClick = !event.repeat && event.code === 'Enter';

      if (shouldClick) {
        event.preventDefault();

        handleCloseIconClick();
      }
    },
    [handleCloseIconClick],
  );

  return (
    <div
      className={b({ size }, [className])}
      ref={modalRef}
      tabIndex={-1}
      onClick={handleClick}
    >
      <div className={b('header')}>
        <div className={b('left')}>{Header && <Header />}</div>
        <div className={b('right')}>
          <CrossIcon
            className={b('close-icon')}
            tabIndex={0}
            onClick={handleCloseIconClick}
            onKeyDown={handleCrossIconKeyDown}
          />
        </div>
      </div>
      <div className={b('content')}>{children}</div>
    </div>
  );
}

export const Component = React.memo(MModal) as typeof MModal;
