import { I18n } from 'services';
import * as M from 'types/serverModels';
import { FormElementState, makeFormElementState } from 'utils/FormState';
import { FormEntityState } from 'utils/FormState/types';
import {
  AbstractStateUnit,
  makeDerivedUnit,
  makeMappingUnitFromUnit,
  makePrimaryUnit,
} from 'utils/State';
import { nonEmptyString } from 'utils/validators';

import { isRequired } from '../../../i18nSharedReferences';
import { quizLanguageUnit } from '../../../units';
import { SharedInstanceStateConstructor, SharedServerQuestion } from '../types';

export function makeSharedServerQuestion({
  questionTextState,
  questionExplanationState,
  answerExplanationState,
  id,
}: {
  questionTextState: I18n.MultilangFormState;
  questionExplanationState: I18n.MultilangFormState;
  answerExplanationState: I18n.MultilangFormState;
  id: string;
}): SharedServerQuestion {
  const lang = quizLanguageUnit.getState();

  const shared = {
    uuid: id,
    title: questionTextState.getMergedMultilingString(lang),
    description: questionExplanationState.getMergedMultilingString(lang),
    answer: answerExplanationState.getMergedMultilingString(lang),
  };

  return shared;
}

export function validateInstance(
  titleFormState: FormElementState<string>,
  formEntitiesUnit: AbstractStateUnit<FormEntityState[]>,
) {
  const formEntities = formEntitiesUnit.getState();

  return [titleFormState, ...formEntities]
    .map(x => x.formNode.validate())
    .every(x => x);
}

export function makeSharedInstanceStateConstructor({
  id,
  title = '',
  explanation = '',
  answer = '',
}: {
  id: string;
  questionIsRequired?: boolean;
  title?: string | (() => string) | I18n.MultilangFormState;
  explanation?: string | I18n.MultilangFormState;
  image?: M.QuizQuestion['image'];
  visibility?: M.QuestionVisibility;
  answerVisibility?: M.QuestionAnswerVisibility;
  answer?: string | I18n.MultilangFormState;
}): SharedInstanceStateConstructor {
  const questionTextState = I18n.isMultilangFormState(title)
    ? title
    : I18n.makeMultilangFormState(
        makeFormElementState(title, [nonEmptyString(isRequired)]),
      );

  const questionExplanationState = I18n.isMultilangFormState(explanation)
    ? explanation
    : I18n.makeMultilangFormState(makeFormElementState(explanation));

  const answerExplanationState = I18n.isMultilangFormState(answer)
    ? answer
    : I18n.makeMultilangFormState(makeFormElementState(answer));

  return (
    formEntitiesUnit: AbstractStateUnit<FormEntityState[]> = makePrimaryUnit(
      [],
    ),
  ) => {
    const hasErrorUnit = makeDerivedUnit(
      makeMappingUnitFromUnit(
        makeDerivedUnit(formEntitiesUnit).getUnit(formEntities => {
          return [...formEntities, questionTextState.formElementState].map(
            x => x.units.error,
          );
        }),
      ),
    ).getUnit(errors => errors.some(x => x !== null));

    return {
      id,
      questionText: questionTextState,
      questionExplanation: questionExplanationState,
      answerExplanation: answerExplanationState,
      hasErrorUnit,
      makeSharedServerQuestion: () => {
        return makeSharedServerQuestion({
          questionTextState,
          questionExplanationState,
          answerExplanationState,
          id,
        });
      },
      validate: () => {
        return validateInstance(
          questionTextState.formElementState,
          formEntitiesUnit,
        );
      },
      isValid: () =>
        [
          questionTextState.formElementState,
          ...formEntitiesUnit.getState(),
        ].every(x => x.formNode.isValid()),
    };
  };
}
