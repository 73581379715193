import React from 'react';

import { Page } from 'services';

import { routeTree } from '../../../routeTree';
import * as features from './features';

type Props = features.RecycleBin.Props;

function RecycleBin(props: Props) {
  return <features.RecycleBin.Component {...props} />;
}

export const path = [
  routeTree.LANG['project-book'].list.PROJECT_BOOK['recycle-bin'].getPath(),
  routeTree.LANG['project-book'].list.PROJECT_BOOK.users.USER[
    'recycle-bin'
  ].getPath(),
];

export const Component = Page.makePage({
  path,
  routeProps: { exact: false },
  scrollTo: 'none',
  Component: React.memo(RecycleBin) as typeof RecycleBin,
  features: Object.values(features).map(x => x.feature),
}) as typeof RecycleBin;
