import React from 'react';

import { I18n } from 'services';
import { DisabledTestFileTypeIcon } from 'shared/images/fileTypes';
import { block } from 'utils/classname';

import i18nData from '../../../i18n.json';
import { ProvidedProps } from '../types';

const b = block('quiz-action-disabled-file-type-icon-content');

type Props = ProvidedProps;

function DisabledFileTypeIcon({}: Props) {
  const text = I18n.useText(i18nData).content.disabledFileTypeIcon;

  return <DisabledTestFileTypeIcon className={b()} title={text.title} />;
}

export const Component = React.memo(
  DisabledFileTypeIcon,
) as typeof DisabledFileTypeIcon;
