import React, { useMemo } from 'react';

import { I18n, Page } from 'services';
import { PrimaryStateUnit } from 'utils/State';

import { routeTree } from '../routeTree';
import * as features from './features';

function Home() {
  const lang = I18n.activeLangStateUnit.useState();

  const code = useMemo(() => {
    const codes = JSON.parse(process.env.RAZZLE_HOME_PAGE_CODES);

    const currentLangCode = codes[lang];

    if (currentLangCode) {
      return currentLangCode;
    }

    const defaultLang = I18n.constants.languages[0];

    if (defaultLang && codes[defaultLang]) {
      return codes[defaultLang];
    }

    const code = Object.values(codes)[0];

    if (code) {
      return code;
    }

    return null;
  }, [lang]);

  return (
    <features.Page.Component
      code={code}
      hashParamUnit={
        routeTree.LANG.hashParamUnit as PrimaryStateUnit<'' | `#${string}`>
      }
      withDefaultTopElements
      withDefaultFooterElements
    />
  );
}

export const Component = Page.makePage({
  path: routeTree.LANG.getPath(),
  routeProps: { exact: true },
  scrollTo: 'top-on-mount',
  Component: React.memo(Home),
  features: Object.values(features).map(x => x.feature),
});
