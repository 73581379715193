import debounce from 'debounce';
import * as R from 'ramda';
import React, { useEffect, useMemo } from 'react';

import { ConstructorConfigContext } from 'features/project/Constructor/config/configContext';
import {
  Filter,
  MapWidgetDatafulView,
} from 'features/project/Constructor/subfeatures';
import { serverProjectDataUnit } from 'features/project/Constructor/units';
import { MapDatafulView } from 'features/widgets/map/Operational/subfeatures';
import { API, ThemeProvider } from 'services';
import * as M from 'types/serverModels';
import { makeMappingUnit, makeDerivedUnit } from 'utils/State';
import { Layer } from 'utils/business';
import { useRequiredContext } from 'utils/react/RequiredContext';

import * as modals from '../../../modals';
import { ViewProps } from '../../../types';
import * as DatalessWidgetLayout from '../../shared/DatalessWidgetLayout';
import { makeEmulateParams } from '../../shared/makeEmulateParams';
import { MapInstance } from '../types';

const dataIsEmpty = (item: { data: M.ServerMapData[] }[]) =>
  item.length === 0 || item.every(x => x.data.length === 0);

function View({
  instance,
  shouldEmulateDataUnit,
  useEmulationSeed,
}: ViewProps<MapInstance>) {
  const theme = ThemeProvider.useTheme();

  const callStateUnit = API.services.data.map.useCallStateUnit();
  const callState = callStateUnit.useState();
  const call = API.services.data.map.useCall(callStateUnit);

  const emulationSeed = useEmulationSeed();

  const filterStateForRequestUnit = useMemo(
    () => Filter.makeFilterStateUnitForRequest(instance.filter),
    [instance.filter],
  );

  const settingsUnit = useMemo(() => {
    return makeMappingUnit(
      {
        layers: makeDerivedUnit(instance.layers).getUnit(layers =>
          layers
            .filter(Layer.isWithSelectedQuestion)
            .map(Layer.makeLayerForRequest),
        ),
        filter: filterStateForRequestUnit,
        shouldEmulate: shouldEmulateDataUnit,
      },
      { deep: true },
    );
  }, [filterStateForRequestUnit, instance.layers, shouldEmulateDataUnit]);

  const DatalessView = DatalessWidgetLayout.useViewWithLayout(
    'map',
    shouldEmulateDataUnit,
  );

  const { onQuestionnaireDelete, getProjectUUID } = useRequiredContext(
    ConstructorConfigContext,
  );

  const project = serverProjectDataUnit.useState();

  useEffect(() => {
    const requestData = async (
      settings: ReturnType<(typeof settingsUnit)['getState']>,
      prevSettings?: ReturnType<(typeof settingsUnit)['getState']>,
    ) => {
      const projectUUID = getProjectUUID();

      if (
        projectUUID &&
        settings.layers.length > 0 &&
        !R.equals(settings, prevSettings) &&
        instance.filter.validate()
      ) {
        call({
          question: settings.layers.map(x => x.question.id),
          filter: Filter.makeServerFilter(settings.filter),
          emulate: makeEmulateParams(settings.shouldEmulate, emulationSeed),
        });
      }
    };
    requestData(settingsUnit.getState());

    return settingsUnit.subscribe({
      name: 'data-requester',
      callback: debounce(requestData, 500),
    });
  }, [call, emulationSeed, getProjectUUID, instance.filter, settingsUnit]);

  const activeLayerUnit = useMemo(
    () =>
      makeDerivedUnit(instance.layers, instance.activeLayerIndex).getUnit(
        (layers, index) => layers[index],
      ),
    [instance.activeLayerIndex, instance.layers],
  );

  useEffect(() => {
    return callStateUnit.subscribe({
      name: 'error-modal-opener',
      callback: state => {
        if (shouldEmulateDataUnit.getState()) {
          if (state.kind === 'error') {
            modals.QuestionsNotSavedOrOtherError.open(state.message);
          } else if (
            state.kind === 'successful' &&
            dataIsEmpty(state.data) &&
            Object.values(settingsUnit.getState().filter).every(x => x === null)
          ) {
            modals.QuestionsNotSavedOrOtherError.open('Received data is empty');
          }
        }
      },
    });
  }, [callStateUnit, settingsUnit, shouldEmulateDataUnit]);

  useEffect(() => {
    let prevQuestionIDs: string[] = [];

    return instance.layers.subscribe({
      name: 'emulation-mode-canceller',
      callback: state => {
        if (shouldEmulateDataUnit.getState()) {
          const layersQuestions = state
            .filter(Layer.isWithSelectedQuestion)
            .map(x => x.question.id);

          if (
            !layersQuestions.every((x, index) => x === prevQuestionIDs[index])
          ) {
            prevQuestionIDs = layersQuestions;
            shouldEmulateDataUnit.setState(false);
          }
        }
      },
    });
  }, [instance.layers, shouldEmulateDataUnit]);

  return API.renderCallState(callState, {
    successful: ({ data }) => {
      const mapData = MapDatafulView.getMapData({
        mapData: data,
        layers: settingsUnit.getState().layers,
        theme,
      });

      if (
        mapData.length === 0 ||
        mapData.every(x => x.data.length === 0) ||
        !project
      ) {
        return <DatalessView />;
      }

      return (
        <MapWidgetDatafulView.Component
          data={MapDatafulView.getMapData({
            mapData: data,
            layers: settingsUnit.getState().layers,
            theme,
          })}
          layersUnit={instance.layers}
          activeLayerUnit={activeLayerUnit}
          selectedImageUnit={instance.selectedImage}
          project={project}
          onQuestionnaireDelete={onQuestionnaireDelete}
        />
      );
    },
    error: () => <DatalessView />,
    initial: () => <DatalessView />,
  });
}

export const Component = React.memo(View);
