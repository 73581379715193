import type * as ProjectViewModal from 'features/project/view/Modal';

import { projectXShortsUnit } from '../units';

export const handleQuestionnaireDelete: ProjectViewModal.Props['onQuestionnaireDelete'] =
  ({ questionnaire }) => {
    const projectXShorts = projectXShortsUnit.getState();
    const projectUnit = projectXShorts?.[questionnaire.project];

    if (projectUnit === undefined) {
      console.error('undefined project');

      return;
    }

    projectUnit.setState(prev => ({
      ...prev,
      results_counter: (prev.results_counter || 1) - 1,
    }));
  };
