import { CachedInstance } from './types';

export const getInstanceID = (x: CachedInstance): string => {
  return (
    x.localID ||
    x.serverIDUnit.getState() ||
    (() => {
      throw new Error('no local nor server id');
    })()
  );
};
