import { routeTree } from 'pages/routeTree';
import * as M from 'types/serverModels';

export function getURL(data: M.Unit | M.ProjectBookUnit) {
  switch (data.type) {
    case 'video': {
      return data.video?.mp4 ? data.video?.mp4 : null;
    }
    case 'web':
    case 'test':
    case 'interactive': {
      return data.url ? data.url : null;
    }
    case 'print':
    case 'presentation': {
      if (data.file && data.file.url) {
        return data.file.url;
      }

      return data.url || null;
    }
    case 'project': {
      return routeTree.LANG.project.list.PROJECT.general.getPath({
        routeParams: { LANG: data.lang, PROJECT: data.project_uuid },
      });
    }
    case 'mesch_eom': {
      return data.launch_url;
    }
  }

  return null;
}
