import { makeDerivedUnit, makePrimaryUnit } from 'utils/State';

import { descriptionState } from './description';
import { flatCachedInstancesUnit } from './instances';
import { modesUnit } from './modes';

export const allQuestionAreSavedUnit = makeDerivedUnit(modesUnit).getUnit(
  modes => modes.every(x => x === 'preview'),
);

export const oneQuestionInvariantUnit = makeDerivedUnit(
  flatCachedInstancesUnit,
  descriptionState.units.value,
).getUnit(
  (instances, description) =>
    (description.length === 0 && instances.length === 0) ||
    instances.length > 0,
);

export const progressInvariantUnitsUnit = makePrimaryUnit([
  allQuestionAreSavedUnit,
]);
