import { Merge, SetRequired } from 'type-fest';

import * as M from 'types/serverModels';

import * as track from './track';

export function convertToStream(
  data: Merge<
    M.CourseStreamServiceStream,
    {
      tracks: SetRequired<M.CourseStreamServiceTrack, 'lang'>[];
    }
  >,
): M.Stream {
  return {
    active: data.active,
    blog: data.blog,
    cdate: data.cdate,
    course: data.course,
    name: data.name,
    open: data.open,
    tracks: data.tracks.map(x => track.convertToStreamTrack(x)),
    uuid: data.uuid,
  };
}
