import { I18n } from 'services';
import * as TS from 'types';
import * as M from 'types/serverModels';
import { makeFormElementState } from 'utils/FormState';
import { makeUUID } from 'utils/UUID';

import { AnswerVariant } from './types';

export function makeNewVariant(textValidators?: TS.Validator[]): AnswerVariant {
  return {
    id: makeUUID(),
    text: I18n.makeMultilangFormState(
      makeFormElementState<string>('', textValidators),
    ),
    explanation: I18n.makeMultilangFormState(makeFormElementState<string>('')),
    isOwn: makeFormElementState<boolean>(false),
    image: makeFormElementState<M.ImageInfo | null>(null),
    score: makeFormElementState<number | null>(null),
  };
}
