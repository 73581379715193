import React from 'react';

import { Page } from 'services';

import { routeTree } from '../../../routeTree';
import * as features from './features';

type Props = features.Passport.Props;

function Passport(props: Props) {
  return <features.Passport.Component {...props} />;
}

export const path = [
  routeTree.LANG['project-book'].list.PROJECT_BOOK.passport.getPath(),
  routeTree.LANG[
    'project-book'
  ].list.PROJECT_BOOK.users.USER.passport.getPath(),
];

export const Component = Page.makePage({
  path,
  routeProps: { exact: true },
  scrollTo: 'none',
  Component: React.memo(Passport) as typeof Passport,
  features: Object.values(features).map(x => x.feature),
}) as typeof Passport;
