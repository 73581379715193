import React from 'react';

import { QuestionText } from 'features/project/Constructor/subfeatures';
import { block } from 'utils/classname';
import { useRequiredContext } from 'utils/react/RequiredContext';

import { QuestionWidgetContext } from '../../Context';
import './style.scss';

const b = block('header-of-constructor-widget-for-question-in-preview-mode');

type Props = {};

function PreviewMode({}: Props) {
  const { cachedInstance } = useRequiredContext(QuestionWidgetContext);

  const stateInstance = cachedInstance.useStateInstance();

  return (
    <div className={b()}>
      <QuestionText.Component
        questionText={stateInstance.questionText.formElementState.units.value.useState()}
      />
    </div>
  );
}

export const Component = React.memo(PreviewMode) as typeof PreviewMode;
