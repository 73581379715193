import * as TS from 'types';
import * as M from 'types/serverModels';

import { makeService } from '../../../utils';

type PathInputData = {
  uuid: Exclude<M.ProjectBook['uuid'], undefined>;
};

type QueryInputData = { use_cache?: boolean; lang: TS.Language };

type InputData = PathInputData & QueryInputData;

type APIOutputData = M.ProjectBookPersonalEvaluationAnalysis;

type OutputData = {
  users: Record<
    M.ProjectBookPersonalEvaluationAnalysis['user'],
    {
      projectBook: {
        [key in M.ProjectBookPersonalEvaluationAnalysis['category']]?: M.ProjectBookPersonalEvaluationAnalysis;
      };
    }
  >;
};

function extractResponse(
  prev: OutputData | null,
  data: APIOutputData[],
): OutputData {
  return (
    data.reduce<OutputData | null>((acc, x) => {
      return {
        users: {
          ...(acc?.users || {}),
          [x.user]: {
            projectBook: {
              ...(acc?.users?.[x.user]?.projectBook || {}),
              [x.category]: x,
            },
          },
        },
      };
    }, prev) || { users: {} }
  );
}

export const get = makeService<InputData, APIOutputData, OutputData>(
  ({ uuid, ...params }, convertToSearchParams) => {
    const { ...rest } = params;

    return `project_book/${uuid}/analysis/personal_evaluation/?${convertToSearchParams(
      {
        ...rest,
      },
    )}`;
  },
  'api-v2-get',
  false,
  extractResponse,
  { contentType: 'application/ndjson' },
);
