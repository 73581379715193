import * as M from 'types/serverModels';

import { makeService } from '../utils';

type InputData = {
  project_assignment: Pick<M.ProjectAssignment, 'project' | 'assignees'>;
};

type APIOutputData = {
  project_assignment: M.ProjectAssignment;
  users: M.ProjectAssignmentUser[];
};

export const post = makeService<InputData, APIOutputData>(
  () => 'project_assignment',
  'api-v2-post',
  false,
);
