import React from 'react';

import { MapWidgetDataSettings } from 'features/project/Constructor/subfeatures';

import { DataSettingsProps } from '../../../types';
import { questionsForLayersUnit } from '../questionsForLayersUnit';
import { MapInstance } from '../types';

function MapDataSettings({ instance }: DataSettingsProps<MapInstance>) {
  return (
    <MapWidgetDataSettings.Component
      layersUnit={instance.layers}
      questionsUnit={questionsForLayersUnit}
      selectedQuestionsUnit={instance.selectedQuestions}
      questionSettingsUnit={instance.questionSettings}
      selectedImageUnit={instance.selectedImage}
    />
  );
}

export const Component = React.memo(MapDataSettings);
