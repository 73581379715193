import md5 from 'md5';
import { useMemo } from 'react';

import { makeServerQuestion } from './makeServerQuestion';
import { questionInstancesUnit } from './units/instances';

export function useStateHash() {
  const questionInstances = questionInstancesUnit.useState();

  return useMemo(
    () => md5(JSON.stringify(questionInstances.map(makeServerQuestion))),
    [questionInstances],
  );
}
