import * as M from 'types/serverModels';

import { makeService } from '../utils';

type PathInputData = {
  uuid: Exclude<M.APIToken['uuid'], 'undefined'>;
};

type QueryInputData = {};

type InputData = PathInputData & QueryInputData;

type APIOutputData = {
  api_token?: Omit<M.APIToken, 'token' | 'valid'> & { valid?: false };
};

type OutputData = {
  apiToken: Omit<M.APIToken, 'token' | 'valid'> & { valid?: false };
};

function extractResponse(data: APIOutputData): OutputData {
  return {
    apiToken: data.api_token || {},
  };
}

export const del = makeService<InputData, APIOutputData, OutputData>(
  ({ ticket, uuid }, convertToSearchParams) =>
    `api_token/${uuid}/?${convertToSearchParams({ ticket })}`,
  'api-v2-delete',
  false,
  extractResponse,
);
