import { makeFeature } from 'services/Feature';

import * as i18nSharedReferences from '../../i18nSharedReferences';
import i18nData from './i18n.json';

export { useFieldState } from './Component';

export const feature = makeFeature({
  name: 'SignUpLoginInput',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "sign-up-login-input" */
      './Component'
    ),
  i18nSharedReferences: Object.values(i18nSharedReferences),
  i18nData,
  subfeatures: [],
});

export const Component = feature.Component;
