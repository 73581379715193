import React from 'react';

import { I18n } from 'services';
import { block } from 'utils/classname';

import { StepErrorsDescription } from '../../../types';
import './style.scss';

const b = block('step-errors');

type Props = {
  description: StepErrorsDescription;
};

function StepErrors({ description }: Props) {
  const lang = I18n.activeLangStateUnit.useState();

  const t = (reference: I18n.EntryReference) =>
    I18n.getReferenceText(lang, reference);

  return (
    <div className={b()}>
      <h2 className={b('step-name')}>{t(description.stepName)}</h2>
      <ul>
        {description.errors.map((x, index) => (
          <li key={index}>{t(x)}</li>
        ))}
      </ul>
    </div>
  );
}

export const Component = React.memo(StepErrors) as typeof StepErrors;
