import * as TS from 'types';

export const chartTypeToYAxisConstOptions: Record<
  TS.ChartType,
  TS.YAxisData[]
> = {
  line: [{ kind: 'latitude' }, { kind: 'longtitude' }, { kind: 'quantity' }],
  area: [{ kind: 'latitude' }, { kind: 'longtitude' }, { kind: 'quantity' }],
  scatter: [
    { kind: 'latitude' },
    { kind: 'longtitude' },
    { kind: 'quantity' },
    { kind: 'questionnaire-submit-date' },
  ],
  column: [{ kind: 'latitude' }, { kind: 'longtitude' }, { kind: 'quantity' }],
  'column-stack': [{ kind: 'quantity' }],
  pie: [{ kind: 'quantity' }],
};
