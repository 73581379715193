import React from 'react';

import { ReactComponent as CheckIcon } from 'shared/images/check.svg';
import { block } from 'utils/classname';

import './style.scss';

const b = block('circle-counter');

type Props = {
  tag: 'button' | 'div';
  forwardedRef?: React.Ref<HTMLElement>;
  counter?: number;
  active?: boolean;
  className?: string;
  onClick?(): void;
};

function CircleCounter({
  tag,
  forwardedRef,
  counter,
  active,
  className,
  onClick,
}: Props) {
  return React.createElement(
    tag,
    {
      className: b({ active, interactive: onClick !== undefined }, [className]),
      ref: forwardedRef,
      onClick,
    },
    counter !== undefined ? counter : <CheckIcon className={b('icon')} />,
  );
}

export const Component = React.memo(CircleCounter);
