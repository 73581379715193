import * as File from './File';
import * as Link from './Link';
import * as NotSelected from './NotSelected';
import * as Test from './Test';
import * as Text from './Text';
import * as Video from './Video';

export const constructorWidgets = [
  NotSelected.constructorWidget,
  Link.constructorWidget,
  Text.constructorWidget,
  File.constructorWidget,
  Video.constructorWidget,
  Test.constructorWidget,
] as const;
