import * as M from 'types/serverModels';

export function getCertificate<
  T extends { details: Pick<M.Perk, 'type' | 'title' | 'reason'> },
>(certificateList: T[], perk: M.Perk) {
  return (
    certificateList.find(certificate => {
      if (certificate.details.type !== perk.type) {
        return false;
      }

      if (
        certificate.details.reason === undefined &&
        perk.reason === undefined
      ) {
        return true;
      }

      if (
        certificate.details.reason === undefined ||
        perk.reason === undefined
      ) {
        return false;
      }

      if ('project' in certificate.details.reason && 'project' in perk.reason) {
        return certificate.details.reason.project === perk.reason.project;
      }

      if ('course' in certificate.details.reason && 'course' in perk.reason) {
        return certificate.details.reason.course === perk.reason.course;
      }

      if (
        'issue' in certificate.details.reason &&
        'note' in certificate.details.reason &&
        'issue' in perk.reason &&
        'note' in perk.reason
      ) {
        return certificate.details.reason.issue === perk.reason.issue;
      }

      return false;
    }) || null
  );
}
