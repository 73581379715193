import { makeSharedEntryReference } from 'services/I18n/makeEntryReference';

export const projectBookLabelReference = makeSharedEntryReference(
  data => data.entity.projectBook.label,
);

export const projectBookStatusReferences = {
  assigned: makeSharedEntryReference(data => data.projectBook.status.assigned),
  onprogress: makeSharedEntryReference(
    data => data.projectBook.status.onprogress,
  ),
  finished: makeSharedEntryReference(data => data.projectBook.status.finished),
  postponed: makeSharedEntryReference(
    data => data.projectBook.status.postponed,
  ),
  deleted: makeSharedEntryReference(data => data.projectBook.status.deleted),
  null: makeSharedEntryReference(data => data.projectBook.status.null),
};
