import { I18n } from 'services';
import { colorRangesForScaleLayerOfMapWidget } from 'shared/constants';
import * as TS from 'types';
import * as M from 'types/serverModels';
import { makeFormElementState } from 'utils/FormState';
import { makeRangeValidators, nonNull } from 'utils/validators';

import { QuestionForLayerWithScale, LayerWithScale } from '../types';

export function withScale(
  question: QuestionForLayerWithScale,
  id: string,
): LayerWithScale {
  const fromState = makeFormElementState<number | null>(0);
  const toState = makeFormElementState<number | null>(100);

  const { fromRangeValidator, toRangeValidator } = makeRangeValidators(
    {
      valueUnit: fromState.units.value,
      messageReference: I18n.constants.emptyReference,
    },
    {
      valueUnit: toState.units.value,
      messageReference: I18n.constants.emptyReference,
    },
  );

  fromState.setValidators(
    nonNull(I18n.constants.emptyReference),
    fromRangeValidator,
  );

  toState.setValidators(
    nonNull(I18n.constants.emptyReference),
    toRangeValidator,
  );

  toState.units.visited.subscribe({
    name: 'sync-visited-from',
    callback: visited => {
      if (visited && !fromState.units.visited.getState()) {
        fromState.units.visited.setState(true);
      }
    },
  });

  fromState.units.visited.subscribe({
    name: 'sync-visited-to',
    callback: visited => {
      if (visited && !toState.units.visited.getState()) {
        toState.units.visited.setState(true);
      }
    },
  });

  return {
    kind: 'scale',
    id,
    question,
    pointsNumber: makeFormElementState(10),
    scaleRange: {
      from: fromState,
      to: toState,
    },
    markerShape: makeFormElementState<M.MapGraphicName>('circle'),
    colorRange: makeFormElementState<TS.ColorRange>(
      colorRangesForScaleLayerOfMapWidget[0],
    ),
  };
}
