import React, { useMemo } from 'react';

import { TimelineWidgetDataSettings } from 'features/project/Constructor/subfeatures';
import { widgetTypeToQuestionKindMapping } from 'shared/constants';
import { QuestionKind } from 'types';
import { FormElementState } from 'utils/FormState';
import { makeDerivedUnit } from 'utils/State';

import {
  questionIDToQuestionTextUnit,
  questionsUnit,
} from '../../../../InputDataForm/units/instances';
import { DataSettingsProps } from '../../../types';
import { TimeLineInstance } from '../types';

const questionKinds = widgetTypeToQuestionKindMapping.timeline;

type Args = {
  questionKinds: QuestionKind[];
  formElementState: FormElementState<string | null>;
  optional?: boolean;
  predicate?: (
    question: ReturnType<typeof questionsUnit.getState>[0],
  ) => boolean;
};

const makeQuestionSelectPropsUnits = ({
  questionKinds,
  formElementState,
  optional = false,
  predicate,
}: Args) => {
  return {
    questionIDsUnit: makeDerivedUnit(questionsUnit).getUnit(questions => {
      const questionIDs = questions
        .filter(x =>
          typeof predicate === 'function'
            ? predicate?.(x) && questionKinds.includes(x.questionKind)
            : questionKinds.includes(x.questionKind),
        )
        .map(x => x.id);
      return optional ? [null, ...questionIDs] : questionIDs;
    }),
    questionIDToQuestionTextUnit,
    selectedQuestionIDState: formElementState,
  };
};

function DataSettings({ instance }: DataSettingsProps<TimeLineInstance>) {
  const startDateQuestionID =
    instance.startDateQuestionID.units.value.useState();
  const endDateQuestionID = instance.endDateQuestionID.units.value.useState();
  const headlineQuestionID = instance.headlineQuestionID.units.value.useState();
  const textQuestionID = instance.textQuestionID.units.value.useState();

  const startDateQuestionSelectPropsUnits = useMemo(
    () =>
      makeQuestionSelectPropsUnits({
        questionKinds: questionKinds.startDate,
        formElementState: instance.startDateQuestionID,
        predicate: x => x.id !== endDateQuestionID,
      }),
    [endDateQuestionID, instance.startDateQuestionID],
  );
  const endDateQuestionSelectPropsUnits = useMemo(
    () =>
      makeQuestionSelectPropsUnits({
        questionKinds: questionKinds.endDate,
        formElementState: instance.endDateQuestionID,
        optional: true,
        predicate: x => x.id !== startDateQuestionID,
      }),
    [instance.endDateQuestionID, startDateQuestionID],
  );
  const headlineQuestionSelectPropsUnits = useMemo(
    () =>
      makeQuestionSelectPropsUnits({
        questionKinds: questionKinds.headline,
        formElementState: instance.headlineQuestionID,
        predicate: x => x.id !== textQuestionID,
      }),
    [instance.headlineQuestionID, textQuestionID],
  );
  const textQuestionSelectPropsUnits = useMemo(
    () =>
      makeQuestionSelectPropsUnits({
        questionKinds: questionKinds.text,
        formElementState: instance.textQuestionID,
        optional: true,
        predicate: x => x.id !== headlineQuestionID,
      }),
    [headlineQuestionID, instance.textQuestionID],
  );
  const mediaQuestionSelectPropsUnits = useMemo(
    () =>
      makeQuestionSelectPropsUnits({
        questionKinds: questionKinds.media,
        formElementState: instance.mediaQuestionID,
        optional: true,
      }),
    [instance.mediaQuestionID],
  );

  return (
    <TimelineWidgetDataSettings.Component
      startDateQuestionSelectPropsUnits={startDateQuestionSelectPropsUnits}
      endDateQuestionSelectPropsUnits={endDateQuestionSelectPropsUnits}
      headlineQuestionSelectPropsUnits={headlineQuestionSelectPropsUnits}
      textQuestionSelectPropsUnits={textQuestionSelectPropsUnits}
      mediaQuestionSelectPropsUnits={mediaQuestionSelectPropsUnits}
    />
  );
}

export const Component = React.memo(DataSettings);
