import { I18n } from 'services';
import { useRequiredContext } from 'utils/react/RequiredContext';

import i18nData from '../../i18n.json';
import { ConstructorFormContainer } from '../../subfeatures';
import { StepContext } from '../StepContext';

export const useStepText: ConstructorFormContainer.Props['useText'] = () => {
  const { step } = useRequiredContext(StepContext);

  const text = I18n.useText(i18nData);
  const stepText = text.steps[step.key];

  return stepText;
};
