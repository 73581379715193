import { I18n } from 'services';
import * as TS from 'types';
import { UnitDebugData } from 'utils/State';

import { makeSingleUnitValidator } from './makeValidator';

type Options = {
  regex: RegExp;
  messageReference: I18n.EntryReference;
  debounceInterval?: number;
  debugData?: UnitDebugData;
};

export const regExp = ({
  regex,
  messageReference,
  debounceInterval,
  debugData,
}: Options) =>
  makeSingleUnitValidator(
    (value: unknown): TS.ValidationResult => {
      const stringValue =
        typeof value === 'string' ? value : JSON.stringify(value);

      if (regex.test(stringValue)) {
        return { kind: 'valid' };
      }

      return { kind: 'invalid', messageReference };
    },
    debounceInterval,
    debugData,
  );
