import { paStateUnit } from './units';

export function callHistory() {
  const paState = paStateUnit.getState();

  if (paState === '*') {
    return;
  }

  paStateUnit.setState('*');
}
