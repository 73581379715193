import { makeService } from './utils';

type InputData = {
  key: string;
  password: string;
};

export const userPasswordActivate = makeService<InputData, unknown>(
  'user_password_activate',
  'post',
);
