import * as TS from 'types';

import { ChartWidgetKey } from '../../types';

export const widgetKeyToChartType: Record<ChartWidgetKey, TS.ChartType> = {
  area: 'area',
  column: 'column',
  columnStack: 'column-stack',
  line: 'line',
  pie: 'pie',
  scatter: 'scatter',
};
