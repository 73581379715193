import * as R from 'ramda';

import { widgetTypeToQuestionKindMapping } from 'shared/constants';
import { QuestionKind } from 'types';
import { Layer, VennChart } from 'utils/business';

import { FindingsSettings } from '../../steps';
import { widgetKeyToChartType } from '../../steps/FindingsSettings/widgets/chartWidgets/widgetKeyToChartType';
import {
  AssociatedWidgetErrorInfo,
  QuestionTypeChangePermission,
} from '../../types';
import { widgetHasAssociatedQuestion } from './widgetHasAssociatedQuestion';

export function getPossibleQuestionKinds(
  widget: FindingsSettings.FindingsWidgetInstance,
  questionID: string,
): QuestionKind[] {
  switch (widget.kind) {
    case 'gallery':
      return widgetTypeToQuestionKindMapping.gallery;
    case 'map':
      return widget.layers
        .getState()
        .filter(Layer.isWithSelectedQuestion)
        .filter(x => x.question.id === questionID)
        .flatMap(x => widgetTypeToQuestionKindMapping.map[x.kind]);

    case 'area':
    case 'column':
    case 'columnStack':
    case 'line':
    case 'scatter':
    case 'pie': {
      const axisDataToQuestionKindMapping =
        widgetTypeToQuestionKindMapping.chart[
          widgetKeyToChartType[widget.kind]
        ];

      const xAxisData = widget.xAxisData.units.value.getState();
      if (
        xAxisData?.kind === 'question' &&
        xAxisData.questionID === questionID
      ) {
        return axisDataToQuestionKindMapping['x-axis'];
      }

      const yAxisData = widget.yAxisData.units.value.getState();
      if (
        yAxisData?.kind === 'question' &&
        yAxisData.questionID === questionID
      ) {
        return axisDataToQuestionKindMapping['y-axis'];
      }

      if (widget.cuttingQuestion.units.value.getState() === questionID) {
        return axisDataToQuestionKindMapping['cutting-question'];
      }

      return [];
    }

    case 'dataList':
      return [];

    case 'textsCloud':
      return widgetTypeToQuestionKindMapping.textsCloud;

    case 'textsList':
      return widgetTypeToQuestionKindMapping.textsList;

    case 'filesList':
      return widgetTypeToQuestionKindMapping.filesList;

    case 'venn':
      return widget.sets
        .getState()
        .filter(VennChart.isSetWithQuestion)
        .filter(x => x.question.id === questionID)
        .flatMap(x => widgetTypeToQuestionKindMapping.vennChart[x.kind]);

    case 'timeline': {
      if (widget.startDateQuestionID.getValue() === questionID) {
        return widgetTypeToQuestionKindMapping.timeline.startDate;
      }
      if (widget.endDateQuestionID.getValue() === questionID) {
        return widgetTypeToQuestionKindMapping.timeline.endDate;
      }
      if (widget.headlineQuestionID.getValue() === questionID) {
        return widgetTypeToQuestionKindMapping.timeline.headline;
      }
      if (widget.textQuestionID.getValue() === questionID) {
        return widgetTypeToQuestionKindMapping.timeline.text;
      }
      if (widget.mediaQuestionID.getValue() === questionID) {
        return widgetTypeToQuestionKindMapping.timeline.media;
      }
      return [];
    }
  }
}

export function getQuestionTypeChangePermission(
  questionID: string,
  toSet: QuestionKind[],
): QuestionTypeChangePermission {
  const associatedWidgets = FindingsSettings.selectedWidgetInstances
    .getState()
    .filter(widget => widgetHasAssociatedQuestion(widget, questionID));

  const possibleQuestionKinds = associatedWidgets
    .map(x => getPossibleQuestionKinds(x, questionID))
    .reduce<QuestionKind[]>(R.intersection, toSet);

  if (possibleQuestionKinds.length > 0) {
    return { kind: 'granted', questionKind: possibleQuestionKinds[0] };
  }

  const incompatibleWidgets = associatedWidgets.filter(
    x =>
      R.intersection(getPossibleQuestionKinds(x, questionID), toSet).length ===
      0,
  );

  return {
    kind: 'denied',
    associatedWidgets: incompatibleWidgets.map(
      (x): AssociatedWidgetErrorInfo => ({
        name: x.title.formElementState.units.value.getState(),
        widgetKey: x.kind,
      }),
    ),
  };
}
