import { useMemo } from 'react';

import { makeFeature } from 'services/Feature';
import * as TS from 'types';
import { makeFormElementState } from 'utils/FormState';
import { makePrimaryUnit } from 'utils/State';

import i18nData from './i18n.json';
import { Kind, Mode, RenderedInput } from './types';

export const feature = makeFeature({
  name: 'EditableTextView',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "editable-text-view" */
      './Component'
    ),
  i18nData,
  i18nSharedReferences: null,
  subfeatures: [],
});

export type { Mode, Kind, RenderedInput };

export type { Props } from './Component';

export function makeFieldState(
  initialState: string = '',
  validators?: TS.Validator[],
) {
  return makeFormElementState<string>(initialState, validators);
}

export function useFieldState(initialState: string = '') {
  return useMemo(() => makeFieldState(initialState), [initialState]);
}

export function makeSharedStateUnits(inputValidators?: TS.Validator[]) {
  const modeUnit = makePrimaryUnit<Mode>('view');
  const textInputState = makeFormElementState('', inputValidators);
  const renderedInputs = makePrimaryUnit<RenderedInput[]>([]);

  return { modeUnit, textInputState, renderedInputs };
}

export function useSharedStateUnits(inputValidators?: TS.Validator[]) {
  return useMemo(
    () => makeSharedStateUnits(inputValidators),
    [inputValidators],
  );
}

export const Component = feature.Component;
