import { makeFeature } from 'services/Feature';

export const feature = makeFeature({
  name: 'PresentationTags',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "presentation-tags" */
      './Component'
    ),
  i18nData: null,
  i18nSharedReferences: null,
  subfeatures: [],
  prefetched: true,
});

export type { Props } from './Component';

export const Component = feature.Component;
