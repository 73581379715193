import * as R from 'ramda';
import React, { useCallback, useEffect } from 'react';

import { Button } from 'components';
import { ProjectWriteContext } from 'features/project/Constructor/FormWithSteps/ProjectWriteContext';
import i18nData from 'features/project/Constructor/i18n.json';
import { ClassificationsFetcher } from 'features/project/Constructor/subfeatures';
import { API, I18n } from 'services';
import * as M from 'types/serverModels';
import { block } from 'utils/classname';
import { useRequiredContext } from 'utils/react/RequiredContext';

import {
  classificationsStateUnit,
  classificationsUnit,
} from '../../units/inputState';
import * as Item from './Item';
import * as ClassificationsModal from './Modal';
import './style.scss';

const b = block('project-constructor-classifications-section');

type Props = {};

function Rubrics({}: Props) {
  const text =
    I18n.useText(i18nData).steps.advancedSettings.section.classifications;

  const callStateUnit = API.services.classificationPaths.useCallStateUnit();
  const call = API.services.classificationPaths.useCall(callStateUnit);
  const callState = callStateUnit.useState();

  const classifications = classificationsUnit.useState();

  const isDisplayedClassification = (data: M.ClassificationPaths) => {
    const classification = data.path[data.path.length - 1]?.uuid || null;

    return (
      data.uuid === process.env.RAZZLE_SUBJECT_CLASSIFIER &&
      classification !== null &&
      classifications.includes(classification)
    );
  };

  const handleButtonClick = useCallback(() => {
    ClassificationsModal.isOpenUnit.setState(true);
  }, []);

  const { saveProject } = useRequiredContext(ProjectWriteContext);

  useEffect(() => {
    return classificationsUnit.subscribe({
      name: 'classifications-changed',
      callback: saveProject,
    });
  }, [saveProject]);

  useEffect(() => {
    const subscribeToUpdate = (
      predicate: (data: string[]) => boolean = () => true,
    ) => {
      return classificationsUnit.subscribe({
        name: 'request-missing-data',
        callback: (next, prev) => {
          if (R.equals(next, prev)) return;

          if (predicate(next)) {
            call({ term: next });
          }
        },
      });
    };
    switch (callState.kind) {
      case 'initial': {
        const classifications = classificationsUnit.getState();

        if (classifications.length > 0) {
          call({ term: classifications });
        }
        return subscribeToUpdate();
      }
      case 'successful': {
        const predicate = (data: string[]) => {
          return !data.every(uuid =>
            callState.data.some(x => x.path[x.path.length - 1]?.uuid === uuid),
          );
        };

        return subscribeToUpdate(predicate);
      }
      default:
        return subscribeToUpdate();
    }
  }, [call, callState]);

  useEffect(() => {
    const classifications = classificationsUnit.getState();

    const syncState = (classifications: string[]) => {
      Object.entries(classificationsStateUnit.getState()).forEach(([key, x]) =>
        x.value.units.value.setState(classifications.includes(key)),
      );
    };

    syncState(classifications);

    return classificationsUnit.subscribe({
      name: 'sync-state',
      callback: syncState,
    });
  }, []);

  return (
    <div className={b()}>
      {API.renderCallState(callState, {
        successful: ({ data }) => {
          return (
            <ul className={b('list')}>
              {data.filter(isDisplayedClassification).map((x, index) => (
                <Item.Component
                  key={x.path[x.path.length - 1]?.uuid ?? index}
                  data={x}
                />
              ))}
            </ul>
          );
        },
      })}
      <ClassificationsModal.Component />
      <ClassificationsFetcher.Component
        classification={process.env.RAZZLE_SUBJECT_CLASSIFIER}
        initialValues={classifications}
        stateUnit={classificationsStateUnit}
      />
      <Button.Component
        callStateUnit={ClassificationsFetcher.callStateUnit}
        type="button"
        onClick={handleButtonClick}
      >
        {text.button}
      </Button.Component>
    </div>
  );
}

export const Component = React.memo(Rubrics);
