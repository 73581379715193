import React, { useCallback } from 'react';

import eyeClosedIconSrc from 'shared/images/eyeClosed.svg';
import eyeOpenIconSrc from 'shared/images/eyeOpen.svg';
import { PrimaryStateUnit } from 'utils/State';
import { block } from 'utils/classname';

import './style.scss';

const b = block('password-visibility-toggle');

type Props = {
  passwordVisibilityUnit: PrimaryStateUnit<boolean>;
  isToggleDisabledUnit?: PrimaryStateUnit<boolean>;
};

function PasswordVisibilityToggle({
  passwordVisibilityUnit,
  isToggleDisabledUnit,
}: Props) {
  const passwordVisible = passwordVisibilityUnit.useState();
  const isToggleDisabled = isToggleDisabledUnit?.useState();

  const handleToggleClick = useCallback(() => {
    if (!isToggleDisabled) {
      passwordVisibilityUnit.setState(prev => !prev);
    }
  }, [isToggleDisabled]);

  const handleKeyDown: React.KeyboardEventHandler = useCallback(event => {
    const element = event.target;

    const shouldClick = !event.repeat && event.code === 'Enter';

    if (element instanceof HTMLElement && shouldClick) {
      event.preventDefault();
      element.click();
    }
  }, []);

  return (
    <div className={b()}>
      {passwordVisible ? (
        <img
          className={b('toggle', { disabled: isToggleDisabled })}
          src={eyeOpenIconSrc}
          alt="eye-open"
          tabIndex={0}
          onClick={handleToggleClick}
          onKeyDown={handleKeyDown}
        />
      ) : (
        <img
          className={b('toggle', { disabled: isToggleDisabled })}
          src={eyeClosedIconSrc}
          alt="eye-closed"
          tabIndex={0}
          onClick={handleToggleClick}
          onKeyDown={handleKeyDown}
        />
      )}
    </div>
  );
}

export const Component = React.memo(
  PasswordVisibilityToggle,
) as typeof PasswordVisibilityToggle;

export const makeComponent = (props: Props) => () => <Component {...props} />;
