import * as M from 'types/serverModels';

import { makeService } from '../utils';

type InputData = {
  object: string;
  collection: M.Collection;
  parent?: string;
  tree?: boolean;
};

type APICommentData = Omit<M.CommentData, 'author' | 'children'> & {
  author: string;
  children?: APICommentData[];
};

type APIOutputData = {
  comments: APICommentData[];
  author_profiles: M.CommentData['author'][] | null;
};

const convertChildren = (
  comment: APICommentData,
  authorProfiles: Record<string, M.CommentData['author']>,
): M.CommentData => {
  return {
    ...comment,
    author: authorProfiles[comment.author],
    children: comment.children?.map(x => convertChildren(x, authorProfiles)),
  };
};

export const list = makeService<InputData, APIOutputData, M.CommentData[]>(
  (data, convertToSearchParams) => `comment/?${convertToSearchParams(data)}`,
  'api-v2-get',
  false,
  ({ comments, author_profiles }) => {
    const authorProfiles =
      author_profiles?.reduce<Record<string, M.CommentData['author']>>(
        (acc, x) => (x.login ? { ...acc, [x.login]: x } : acc),
        {},
      ) || {};

    return comments.map(comment => ({
      ...comment,
      author: authorProfiles[comment.author],
      children: comment.children?.map(x => convertChildren(x, authorProfiles)),
    }));
  },
);
