import { makeEntryReference } from 'services/I18n';

import i18nData from './i18n.json';

export const i18nStageSectionTitleSharedReferences = {
  units: makeEntryReference(
    i18nData,
    data => data.stageInfo.unitsSection.title,
  ),
  materials: makeEntryReference(i18nData, data => data.materialsSection.title),
};
