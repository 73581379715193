import * as M from 'types/serverModels';

type Options<T> = {
  user: M.User;
  convertItem?(project: M.ProjectIdxItem): T;
};

export function getDraftProjects<T = M.ProjectIdxItem>({
  user,
  convertItem = project => project as unknown as T,
}: Options<T>) {
  return (
    user.draft_project_stack?.reduce<T[]>((acc, uuid) => {
      const project = user.projectIdx[uuid];

      if (project === undefined) {
        return acc;
      }

      return [...acc, convertItem(project)];
    }, []) || []
  );
}
