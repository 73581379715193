import * as M from 'types/serverModels';

import { makeService } from '../../utils';

type InputData = {
  uuid: M.UUID;
  batch?: M.UUID;
  status: Exclude<M.ProjectAssignmentResultStatus, 'waiting' | 'deleted'>;
};

type OutputData = {
  project_assignment_results: M.ProjectAssignmentResult[];
};

export const batchStatus = makeService<InputData, OutputData, OutputData>(
  ({ uuid }) => `project_assignment_result/${uuid}/batch_status`,
  'api-v2-put',
  false,
  output => output,
  { contentType: 'application/x-www-form-urlencoded' },
);
