import React from 'react';

import { block } from 'utils/classname';

import './style.scss';

const b = block('radio-button');

type Props = {
  useLabel(): string;
};

function TextLabel({ useLabel }: Props) {
  return <div className={b('text-label')}>{useLabel()}</div>;
}

export const Component = React.memo(TextLabel) as typeof TextLabel;

export const makeComponent = (props: Props) => () => <Component {...props} />;
