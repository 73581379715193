import * as M from 'types/serverModels';

import { makeService } from '../../utils';

type InputData = {};

type APIOutputData = {
  project_assignment_results: M.ProjectAssignmentResult[];
  data: M.ProjectAssignmentResultData[];
  conclusions: M.ProjectAssignmentResultConclusions[];
};

type OutputData = M.ProjectAssignmentResultList;

const convertOutputData = (output: APIOutputData): OutputData => {
  return {
    projectAssignmentResults: output.project_assignment_results,
    data: output.data.reduce((acc, x) => ({ ...acc, [x.uuid]: x }), {}),
    conclusions: output.conclusions.reduce(
      (acc, x) => ({ ...acc, [x.uuid]: x }),
      {},
    ),
  };
};

export const list = makeService<InputData, APIOutputData, OutputData>(
  () => 'project_assignment_result/',
  'api-v2-get',
  false,
  convertOutputData,
);
