import React from 'react';

import { userStateUnit } from 'shared/stateUnits';
import * as M from 'types/serverModels';
import { makeFormElementState } from 'utils/FormState';
import { nonNull } from 'utils/validators';

import { questionIsRequired } from '../i18nSharedReferences';

export const makeState = (initialValue: M.Location | null = null) => {
  const userState = userStateUnit.getState();

  const userLocation: M.Location =
    userState.kind === 'loaded'
      ? [userState.user.location.longitude, userState.user.location.latitude]
      : [0, 0];

  const presetLocation = userLocation.every(x => x === 0) ? null : userLocation;

  const value = initialValue || presetLocation;

  const formElementState = makeFormElementState<M.Location | null>(null, [
    nonNull(questionIsRequired),
  ]);

  formElementState.units.value.setState(value);

  return formElementState;
};

export const useState = (initialValue?: M.Location | null) => {
  const [state] = React.useState(() => makeState(initialValue));
  return state;
};
