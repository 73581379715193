import {
  QuestionConstructorWidget,
  SharedInstanceStateConstructor,
} from '../../types';
import * as PreviewModeForm from './PreviewModeForm';
import { ServerQuestion, StateInstance } from './types';

export type { StateInstance };

export function makeServerQuestion(): Partial<ServerQuestion> {
  return {
    type: 'date',
  };
}

export const constructorWidget: QuestionConstructorWidget<
  'date',
  StateInstance,
  typeof makeInstance
> = {
  key: 'date',
  icon: 'date',
  makeInstance,
  PreviewModeForm: PreviewModeForm.Component,
};

export function makeInstance(
  makeSharedInstanceState: SharedInstanceStateConstructor,
): StateInstance {
  return {
    kind: 'date',
    makeServerQuestion: () => ({
      type: 'date',
    }),
    ...makeSharedInstanceState(),
  };
}
