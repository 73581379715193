import { match } from 'path-to-regexp';
import { useLocation } from 'react-router';

import { getCurrentLocation } from 'services/Routing';

import { makeGetPath } from './makeGetPath';
import { makeHashParamUnit } from './makeHashParamUnit';
import { makeNullSearchParamsUnit } from './makeNullSearchParamsUnit';
import { makeSearchParamsUnit } from './makeSearchParamsUnit';
import {
  hasSearchParams,
  isEnumeratedNodesDescription,
  isInfiniteNodesDescription,
  isSuperiorNodeDescription,
  isLeafNodeDescription,
  makeSuperiorNodeDescription,
} from './nodes';
import {
  RawRouteTree,
  EnumeratedNodesDescription,
  RouteTree,
  Node,
  RouteInterface,
} from './types';

export function makeRouteTree<
  T extends
    | RawRouteTree
    | EnumeratedNodesDescription<
        string,
        string,
        Record<string, any>,
        unknown,
        Location['hash']
      >,
  SharedParams,
  SharedHash extends Location['hash'],
>(rawTree: T): RouteTree<T, [], SharedParams, SharedHash> {
  return {
    ...((function loop(
      node: Node,
      pathPatternElements: string[] = [],
    ): Record<string, any> {
      const pathPattern = `/${pathPatternElements.join('/')}`;

      const routeData: RouteInterface<[], any> = {
        getPath: makeGetPath(pathPattern),
        searchParamsUnit: hasSearchParams(node)
          ? makeSearchParamsUnit(pathPattern)
          : makeNullSearchParamsUnit(),
        hashParamUnit: makeHashParamUnit(pathPattern),
        getRouteParams: (path?: string) => {
          const route = path ?? getCurrentLocation()?.pathname;

          if (route === undefined) {
            return false;
          }

          const matcher = match<any>(`${pathPattern}/:rest*`);
          const matchResult = matcher(route);

          return matchResult && matchResult.params;
        },
        useRouteParams: (path?: string) => {
          useLocation();

          return routeData.getRouteParams(path);
        },
      };

      if (isSuperiorNodeDescription(node)) {
        return {
          ...routeData,
          ...Object.entries(node.tree).reduce((acc: any, [key, value]) => {
            const xPath = [...pathPatternElements, key];
            return {
              ...acc,
              [key]: loop(value, xPath),
            };
          }, {}),
        };
      }

      if (node === null || isLeafNodeDescription(node)) {
        return routeData;
      }

      if (isEnumeratedNodesDescription(node)) {
        return {
          ...node.values.reduce((acc: any, nodeKey: string) => {
            const xPath = [...pathPatternElements, nodeKey];

            return {
              ...acc,
              [nodeKey]: loop(node.tree, xPath),
            };
          }, {}),
          [node.name]: (() => {
            const key = node.name.toLowerCase();
            const values = node.values.join('|');
            const nodeElement = `:${key}(${values})`;
            const xPath = [...pathPatternElements, nodeElement];

            return loop(node.tree, xPath);
          })(),
        };
      }

      if (isInfiniteNodesDescription(node)) {
        return new Proxy(
          {
            ...routeData,
            [node.name]: (() => {
              const xPath = [
                ...pathPatternElements,
                `:${node.name.toLowerCase()}`,
              ];
              return loop(
                makeSuperiorNodeDescription(
                  node.tree,
                  node.nameTreeSearchParams,
                  node.nameTreeHashParam,
                ),
                xPath,
              );
            })(),
          },
          {
            get: (target, prop: string) => {
              if (prop in target) {
                return target[prop as keyof typeof target];
              }

              const xPath = [...pathPatternElements, prop];
              return loop(
                makeSuperiorNodeDescription(
                  node.tree,
                  node.nameTreeSearchParams,
                  node.nameTreeHashParam,
                ),
                xPath,
              );
            },
          },
        );
      }

      return Object.entries(node).reduce((acc: any, [key, value]) => {
        const xPath = [...pathPatternElements, key];
        return {
          ...acc,
          [key]: loop(value as any, xPath),
        };
      }, routeData);
    })(rawTree) as any as RouteTree<T, [], SharedParams, SharedHash>),
  };
}
