import React, { useCallback } from 'react';

import { block } from 'utils/classname';
import { useRequiredContext } from 'utils/react/RequiredContext';

import { DependenciesContext } from './dependenciesContext';

export type { Dependencies } from './dependenciesContext';
export { DependenciesContext };

const b = block('select');

export type Props = {
  className?: string;
};

function Container({ children, className }: React.PropsWithChildren<Props>) {
  const { option, setActiveOption, setSelectIsExpanded } =
    useRequiredContext(DependenciesContext);

  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();

      setActiveOption(option);
      setSelectIsExpanded(false);
    },
    [option, setActiveOption, setSelectIsExpanded],
  );

  return (
    <div className={b('option', [className])} onClick={handleClick}>
      <div className={b('option-background')} />
      {children}
    </div>
  );
}

export const DefaultComponent = React.memo(Container) as typeof Container;
