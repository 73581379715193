import { useMemo } from 'react';

import { Preloader } from 'components';
import { makeFeature } from 'services/Feature';
import { makePrimaryUnit } from 'utils/State';

import * as subfeatures from './subfeatures';

export { useIsOpenUnit } from 'components/Modal';

export const feature = makeFeature({
  name: 'UserActionErrorModal',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "user-action-error-modal" */
      './Component'
    ),
  Preloader: Preloader.variants.Empty.Component,
  i18nSharedReferences: null,
  i18nData: null,
  subfeatures: Object.values(subfeatures).map(x => x.feature),
});

export function useCaptionUnit(initialState: string | null = null) {
  return useMemo(() => makePrimaryUnit(initialState), [initialState]);
}

export const Component = feature.Component;
