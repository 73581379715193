import * as M from 'types/serverModels';

import { findStage } from './findStage';
import { matchBoard } from './matchBoard';

export function findBoard(
  data: M.ProjectBookChange['change'],
  projectBook: M.ProjectBook,
) {
  const boardUUID = matchBoard(data);

  const stage = findStage(data, projectBook);

  return boardUUID ? stage?.boards?.find(x => x.uuid === boardUUID) : undefined;
}
