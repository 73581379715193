import { useMemo } from 'react';

export function useMemoizedDataConstructor() {
  return useMemo(() => {
    const dataStore: Record<string, any> = {};
    const prevDeps: Record<string, any[]> = {};

    return function <T>(key: string, data: T, deps: any[]): T {
      if (dataStore[key]) {
        const depsAreEqual = prevDeps[key].reduce<boolean>(
          (acc, x, index) => acc && x === deps[index],
          true,
        );

        if (depsAreEqual) {
          return dataStore[key];
        }
      }

      prevDeps[key] = deps;
      dataStore[key] = data;
      return data;
    };
  }, []);
}
