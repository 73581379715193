import { makeFeature } from 'services/Feature';

import i18nData from './i18n.json';
import * as i18nSharedReferences from './i18nSharedReferences';

export const feature = makeFeature({
  name: 'ShopChooseTariff',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "shop-choose-tariff" */
      './Component'
    ),
  i18nSharedReferences: Object.values(
    (() => {
      const { currencyReference } = i18nSharedReferences;

      return [...Object.values(currencyReference)];
    })(),
  ),
  i18nData,
  subfeatures: [],
});

export const Component = feature.Component;
