import { ExperimentData } from 'types/serverModels';

import { makeService } from '../../../utils';

type InputData = {
  experimentID: string;
  data: ExperimentData;
};

export const put = makeService<InputData, ExperimentData, ExperimentData>(
  ({ experimentID }) => `logger/experiment/${experimentID}/data`,
  'api-v2-put',
  false,
  data => data,
  { extractBodyInput: ({ data }) => ({ data }) },
);
