import { Merge } from 'type-fest';

import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  data: Partial<
    Merge<
      M.UserProfile,
      {
        subjects: (string | { lang: string; value: string })[];
        location: Pick<M.UserProfile['location'], 'latitude' | 'longitude'>;
      }
    >
  >;
};

export const userUpdate = makeService<InputData, unknown>(
  'user_update',
  'post-json',
);
