import { Group, ServerGroup } from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  uuid: string;
  licenses?: 1 | 0;
  rand: number;
};

export const groupRead = makeService<InputData, Group>('group_read', 'get');
export const serverGroupRead = makeService<InputData, ServerGroup>(
  'group_read',
  'get',
);
