import React from 'react';

import { block } from 'utils/classname';

import './style.scss';

const b = block('radio-button-label');

type Props = {
  useLabel(): React.ReactNode;
  color?: 'inherit' | 'fg-3';
  weight?: 'normal' | 'bold';
};

function Label({ useLabel, color = 'inherit', weight = 'normal' }: Props) {
  const label = useLabel();

  return <div className={b({ color, weight })}>{label}</div>;
}

export const Component = React.memo(Label) as typeof Label;

export const makeComponent = (props: Props) => () => <Component {...props} />;
