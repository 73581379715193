import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  track: M.CourseTrack;
} & ({ stream: M.Stream['uuid'] } | { course: M.Course['uuid'] });

type OutputData = void;

export const courseWriteTrack = makeService<InputData, OutputData, OutputData>(
  'course_write_track',
  'post-json',
  true,
  x => x,
  {
    extractBodyInput: ({
      ticket,
      stream,
      course,
      track,
    }: {
      ticket?: M.UUID;
      track: M.CourseTrack;
      stream?: M.Stream['uuid'];
      course?: M.Course['uuid'];
    }) => ({
      ticket,
      stream,
      course,
      track: JSON.stringify(track),
    }),
  },
);
