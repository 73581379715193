import React, { useCallback } from 'react';

import i18nData from 'features/project/Constructor/i18n.json';
import { PendingActionSubList } from 'features/project/Constructor/subfeatures';
import { I18n } from 'services';
import { useRequiredContext } from 'utils/react/RequiredContext';

import { ConfigContext } from '../../../../../../ConfigContext';

export type Props = Pick<
  PendingActionSubList.Entry.Control.Cancel.Props,
  'pendingAction' | 'pendingActionsUnit'
>;

const useLabel = () =>
  I18n.useText(i18nData).steps.access.sections.leadership.buttons
    .cancelInvitationLabel;

const useConfirmActionModalCaption = () =>
  I18n.useText(i18nData).steps.access.sections.leadership.modals
    .confirmCancelInvitation.text;

const useErrorModalCaption = () =>
  I18n.useText(i18nData).steps.access.sections.leadership.modals.actionError
    .text;

function CancelAction({ pendingAction, pendingActionsUnit }: Props) {
  const { config } = useRequiredContext(ConfigContext);

  const handleError: PendingActionSubList.Entry.Control.Cancel.Props['onError'] =
    useCallback(
      ({ kind, action }) => {
        switch (kind) {
          case 'already-accepted': {
            config.usersUnit.setState(prev => [...prev, action.userData]);
          }
        }
      },
      [config.usersUnit],
    );

  return (
    <PendingActionSubList.Entry.Control.Cancel.Component
      pendingAction={pendingAction}
      pendingActionsUnit={pendingActionsUnit}
      action="cancel"
      useLabel={useLabel}
      useConfirmActionModalCaption={useConfirmActionModalCaption}
      useErrorModalCaption={useErrorModalCaption}
      onError={handleError}
    />
  );
}

export const Component = React.memo(CancelAction);
