import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  uuid?: M.Course['uuid'];
};

type OutputData = M.UserData[];

export const userCourseList = makeService<InputData, OutputData>(
  'user_course_list',
  'get',
);
