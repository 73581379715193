import React from 'react';

import i18nData from 'features/project/Constructor/i18n.json';
import { OkModal } from 'features/project/Constructor/subfeatures';
import { I18n } from 'services';

type Props = {};

const isOpenUnit = OkModal.makeIsOpenUnit();

const useModalText = () =>
  I18n.useText(i18nData).steps.access.modals.cantInvite;

export const open = () => isOpenUnit.setState(true);

function CantInvite({}: Props) {
  const Header = OkModal.Header.useFromTitle({
    useTitle: () => useModalText().title,
  });

  return (
    <OkModal.Component isOpenUnit={isOpenUnit} size="s" Header={Header}>
      {useModalText().text}
    </OkModal.Component>
  );
}

export const Component = React.memo(CantInvite);
