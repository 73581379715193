import React, { useEffect } from 'react';
import { useLocation } from 'react-router';

import { getCurrentLocation, setCurrentLocation } from './currentLocation';
import { subscribers } from './locationSubscription';
import { setPreviousLocation } from './previousLocation';

type Props = {};

export function LocationChangeListener({}: Props) {
  const location = useLocation();

  const previousLocation = getCurrentLocation();

  if (previousLocation !== null) {
    setPreviousLocation(previousLocation);
  }

  setCurrentLocation(location);

  useEffect(() => {
    subscribers.forEach(f => f(location));
  }, [location]);
  return null;
}

export const Component = React.memo(
  LocationChangeListener,
) as typeof LocationChangeListener;
