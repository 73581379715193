import React, { useCallback } from 'react';

import { Button, VerticallyMovableElement } from 'components';
import i18nData from 'features/project/Constructor/i18n.json';
import { ConstructorFormSection } from 'features/project/Constructor/subfeatures';
import { I18n } from 'services';
import { makeDerivedUnit, makeMappingUnitFromUnit } from 'utils/State';
import { block } from 'utils/classname';

import { Step } from '../../types';
import { makeStepVisitedUnit } from '../../utils';
import {
  CachedInstance,
  makeNewMaterialInstance,
  ConstructorWidget,
  getInstanceID,
} from '../shared/Materials';
import * as units from './units';

export { instancesUnit } from './units';

const b = block('teacher-materials');

type Props = {};

const useVerticallyMovableELements =
  VerticallyMovableElement.makeUseVerticallyMovableELements<CachedInstance>(
    getInstanceID,
  );

const isAddMaterialButtonDisabledUnit = makeDerivedUnit(
  makeMappingUnitFromUnit(
    makeDerivedUnit(units.instancesUnit).getUnit(questionInstances =>
      questionInstances.map(instance =>
        makeDerivedUnit(instance.mode, instance.activeMaterialKeyUnit).getUnit(
          (mode, activeMaterialKey) =>
            activeMaterialKey !== 'notSelected' && mode === 'edit',
        ),
      ),
    ),
  ),
).getUnit(x => x.some(x => x));

function TeacherMaterials({}: Props) {
  const instances = units.instancesUnit.useState();

  const movableInstances = useVerticallyMovableELements(instances);

  const isAddMaterialButtonDisabled =
    isAddMaterialButtonDisabledUnit.useState();

  const handleAddMaterialButtonClick = useCallback(() => {
    units.instancesUnit.setState(prev => [
      ...prev,
      makeNewMaterialInstance('notSelected', true),
    ]);
  }, []);

  const text = I18n.useText(i18nData);

  return (
    <div className={b()}>
      {movableInstances.map((movableInstance, index) => {
        return (
          <VerticallyMovableElement.Component
            element={movableInstance}
            key={getInstanceID(movableInstance.value)}
          >
            <ConstructorWidget.Component
              cachedInstance={movableInstance.value}
              index={index}
              instancesUnit={units.instancesUnit}
            />
          </VerticallyMovableElement.Component>
        );
      })}
      <ConstructorFormSection.Component>
        <Button.Component
          type="button"
          disabled={isAddMaterialButtonDisabled}
          className={b('add-material-button')}
          onClick={handleAddMaterialButtonClick}
        >
          {text.steps.shared.materials.addMaterialButtonText}
        </Button.Component>
      </ConstructorFormSection.Component>
    </div>
  );
}

const Component = React.memo(TeacherMaterials);

const visitedUnit = makeStepVisitedUnit();

export const step: Step = {
  key: 'teacherMaterials',
  Form: Component,
  errorsUnit: units.errorsUnit,
  visitedUnit,
  progressInvariantUnitsUnit: units.progressInvariantUnitsUnit,
  hasSomeInputUnit: units.hasSomeInputUnit,
  resetState: () => {
    units.instancesUnit.resetState();
    visitedUnit.resetState();
  },
};
