import * as M from 'types/serverModels';

import { matchStage } from './matchStage';

export function findStage(
  data: M.ProjectBookChange['change'] | M.ProjectBookRecycleBinObjectItem,
  projectBook: M.ProjectBook,
) {
  const stageUUID = matchStage(data);

  return stageUUID
    ? projectBook.stages?.find(x => x.uuid === stageUUID)
    : undefined;
}
