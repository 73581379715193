import { makeDerivedUnit, makeMappingUnit } from 'utils/State';

import {
  annotationState,
  projectDescriptionState,
  projectImageState,
  keywordsState,
  ageRangesChecksUnit,
  subjectsChecksUnit,
} from './inputState';

export const hasSomeInputUnit = makeDerivedUnit(
  makeMappingUnit([
    annotationState.units.value,
    projectDescriptionState.units.value,
    projectImageState.units.value,
    keywordsState.units.value,
    ageRangesChecksUnit,
    subjectsChecksUnit,
  ] as const),
).getUnit(
  ([
    annotation,
    projectDescription,
    projectImage,
    keywords,
    ageRangesChecks,
    subjectsChecks,
  ]) =>
    annotation !== '' ||
    projectDescription !== '' ||
    projectImage !== null ||
    keywords !== '' ||
    ageRangesChecks.some(x => x === true) ||
    subjectsChecks.some(x => x === true),
);
