import { Question } from '../shared';

const widgets = [
  Question.Number.constructorWidget,
  Question.Text.constructorWidget,
  Question.Map.constructorWidget,
  Question.Date.constructorWidget,
  Question.Video.constructorWidget,
  Question.Sound.constructorWidget,
  Question.Image.constructorWidget,
  Question.SensorData.constructorWidget,
  Question.VariantSelection.constructorWidget,
  Question.File.constructorWidget,
];

export const config = Question.makeConfig(widgets);
