import { format, isSameYear } from 'date-fns';
import { useCallback } from 'react';

import { activeLangStateUnit } from './activeLangStateUnit';
import { dateFormatLocales } from './constants';

export function useGetFormattedTextDatetime() {
  const lang = activeLangStateUnit.useState();

  return useCallback(
    (datetime: string) => {
      if (datetime === '') {
        return '';
      }

      const date = new Date(datetime);

      const isCurrentYear = isSameYear(date, Date.now());

      if (isCurrentYear) {
        return format(date, 'd MMMM, HH:mm', {
          locale: dateFormatLocales[lang],
        });
      }

      return format(date, 'd MMMM yyyy, HH:mm', {
        locale: dateFormatLocales[lang],
      });
    },
    [lang],
  );
}
