import { MathJax } from 'better-react-mathjax';
import React from 'react';

import { RoundedListItemMark } from 'components';
import { Blocks } from 'features/project/Constructor/subfeatures';
import { block } from 'utils/classname';

import { EnumeratedQuestionInstance } from '../../InputDataForm/types';
import { enumeratedQuestionInstances } from '../../InputDataForm/units/instances';
import * as WidgetSelect from './WidgetSelect';
import './style.scss';

const b = block('questions-blocks');

type Props = {};

const columns: Blocks.Column<EnumeratedQuestionInstance>[] = [
  {
    cell: {
      align: 'left',
      Component: ({ entry }) => (
        <div className={b('question-cell-content')}>
          <RoundedListItemMark.Component
            variant="contained"
            className={b('rounded-mark')}
          >
            {entry.number}
          </RoundedListItemMark.Component>
          <div className={b('question-text')}>
            <MathJax dynamic>
              {entry.instance.questionText.formElementState.units.value.useState()}
            </MathJax>
          </div>
        </div>
      ),
    },
  },

  {
    cell: {
      align: 'right',
      Component: ({ entry }) => {
        return (
          <div className={b('add-widget-cell-content')}>
            <WidgetSelect.Component questionInstance={entry} />
          </div>
        );
      },
    },
  },
];

function MobileRow({
  entry,
}: Blocks.MobileRowProps<EnumeratedQuestionInstance>) {
  return (
    <div className={b('row')}>
      <div className={b('numbered-question')}>
        <RoundedListItemMark.Component
          variant="contained"
          className={b('rounded-mark')}
        >
          {entry.number}
        </RoundedListItemMark.Component>
        <div className={b('question-text')}>
          <MathJax dynamic>
            {entry.instance.questionText.formElementState.units.value.useState()}
          </MathJax>
        </div>
      </div>
      <div className={b('add-widget-control')}>
        <WidgetSelect.Component questionInstance={entry} />
      </div>
    </div>
  );
}

function QuestionsBlocks({}: Props) {
  const questions = enumeratedQuestionInstances.useState();

  return (
    <Blocks.Component
      variant="contained"
      columns={columns}
      entries={questions}
      MobileRow={MobileRow}
    />
  );
}

export const Component = React.memo(QuestionsBlocks) as typeof QuestionsBlocks;
