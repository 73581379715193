import { AbstractStateUnit, makePrimaryUnit } from 'utils/State';

import { isClientScriptLoadedUnit } from './units';

if (process.env.BUILD_TARGET === 'client' && (module as any).hot) {
  (module as any).hot.decline();
}

type Options = {
  module: NodeModule;
};

export function makeSocketUnit({ module }: Options): {
  socketUnit: AbstractStateUnit<SocketIOClient.Socket | null>;
  isSocketConnectedUnit: AbstractStateUnit<boolean>;
} {
  if (process.env.BUILD_TARGET === 'client' && (module as any).hot) {
    (module as any).hot.decline();
  }

  const socketUnit = makePrimaryUnit<SocketIOClient.Socket | null>(null);
  const isSocketConnectedUnit = makePrimaryUnit<boolean>(false);

  const handleConnect = () => {
    isSocketConnectedUnit.setState(true);
  };

  const handleDisconnect = () => {
    isSocketConnectedUnit.setState(false);
  };

  const handleClientScriptLoad = () => {
    if (typeof window.io !== 'function') {
      return;
    }

    const socket = window.io(process.env.RAZZLE_SOCKET_BACKEND_URL, {
      autoConnect: false,
    });

    socket.on('connect', handleConnect);
    socket.on('disconnect', handleDisconnect);

    socketUnit.setState(socket);
  };

  const isClientScriptLoaded = isClientScriptLoadedUnit.getState();

  if (isClientScriptLoaded) {
    handleClientScriptLoad();
  } else {
    const unsubscribe = isClientScriptLoadedUnit.subscribe({
      name: 'socket-initializer',
      callback: isLoaded => {
        if (!isLoaded) {
          return;
        }

        unsubscribe();

        handleClientScriptLoad();
      },
    });
  }

  return { socketUnit, isSocketConnectedUnit };
}
