import React, { useCallback } from 'react';

import { ReactComponent as CrossIcon } from 'shared/images/cross.svg';
import { emulateClick } from 'utils/DOM';
import { block } from 'utils/classname';

import { UserProfile } from '../../subfeatures';
import { Item } from '../../types';
import { ProvidedProps } from '../types';
import './style.scss';

const b = block('autocomplete-user-profile-value-item');

type Props<T extends Item> = ProvidedProps<T> & UserProfile.Props;

function UserProfileValueItem<T extends Item>({
  item,
  formElementState,
  onDelete,
  ...userProfileProps
}: Props<T>) {
  const disabled = formElementState.units.disabled.useState();

  const handleDeleteButtonKeyDown: React.KeyboardEventHandler = useCallback(
    event => {
      emulateClick(event);
    },
    [],
  );

  return (
    <div className={b()}>
      <UserProfile.Component {...userProfileProps} />
      <button
        className={b('button', { disabled })}
        type="button"
        onKeyDown={handleDeleteButtonKeyDown}
        onClick={onDelete}
      >
        <CrossIcon className={b('icon')} />
      </button>
    </div>
  );
}

export const Component = React.memo(
  UserProfileValueItem,
) as typeof UserProfileValueItem;

export const makeComponent =
  (userProfileProps: UserProfile.Props) =>
  <T extends Item>(props: ProvidedProps<T>) => (
    <Component {...props} {...userProfileProps} />
  );

export const useUserNameFormatter = UserProfile.useUserNameFormatter;
export const makeUserNameFormatter = UserProfile.makeUserNameFormatter;
