import { Language } from 'types';
import * as M from 'types/serverModels';
import { Course } from 'utils/models';

import { makeService } from './utils';

type InputData = {
  stream: M.Stream['uuid'];
  lang: Language;
  nest_results?: boolean;
  users?: M.User['login'][];
};

type APIOutputData = M.CourseStreamStat | M.CourseStreamStatWithNestedResults;

type OutputData =
  | M.CourseStreamStatOutput
  | M.CourseStreamStatWithNestedResultsOutput;

function extractResponse(data: APIOutputData): OutputData {
  return {
    ...data,
    track: {
      ...data.track,
      objects: Course.addKeyToTrackObjects(data.track.objects),
    },
  };
}

export const courseStreamStat = makeService<
  InputData,
  APIOutputData,
  OutputData
>('course_stream_stat', 'get', true, extractResponse);
