import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  currency: M.Currency;
};

type Output = {
  basket: M.Basket;
  features: Record<M.Feature['code'], number>;
};

export const storeBasketRead = makeService<InputData, Output>(
  'store_basket_read',
  'post',
);
