import * as M from 'types/serverModels';

export const unitTypes: M.Unit['type'][] = [
  'presentation',
  'web',
  'test',
  'video',
  'interactive',
  'print',
  'project',
  process.env.RAZZLE_PROFILE === 'MESCH' && 'mesch_eom',
].filter((x): x is M.Unit['type'] => !!x);

export const materialUnitTypes: M.MaterialUnitType[] = [
  'link',
  'text',
  'file',
  'video',
];
