import { PartialDeep } from 'type-fest';

import { Filter, Sort } from 'features/project/Constructor/subfeatures';
import * as M from 'types/serverModels';

import { questionIDToQuestionInstanceUnit } from '../../../InputDataForm/units/instances';
import { ServerWidgetConstructorArgs } from './index';

export function makeServerWidget(
  args: ServerWidgetConstructorArgs,
): PartialDeep<M.GalleryWidget> {
  const { filter, sort, selectedQuestionIDState } = args;
  const selectedQuestionID = selectedQuestionIDState.units.value.getState();

  if (selectedQuestionID) {
    const questionIDToQuestionInstance =
      questionIDToQuestionInstanceUnit.getState();

    const questionInstance = questionIDToQuestionInstance[selectedQuestionID];

    if (questionInstance) {
      return {
        type: 'gallery',
        descriptor: {
          filter: Filter.makeServerFilterFromState(filter),
          sort: sort && Sort.makeServerFilterFromState(sort),
          questionId: selectedQuestionID,
          questionType: questionInstance.kind === 'image' ? 'image' : 'video',
        },
      };
    }
  }

  return {
    type: 'gallery',
    descriptor: {
      filter: Filter.makeServerFilterFromState(filter),
      sort: sort && Sort.makeServerFilterFromState(sort),
    },
  };
}
