import React, { useCallback } from 'react';

import { WidgetDeleteControl } from 'features/project/Constructor/subfeatures';
import { I18n } from 'services';
import * as M from 'types/serverModels';
import { block } from 'utils/classname';

import i18nData from '../../../../../../i18n.json';
import { classificationsUnit } from '../../../units/inputState';
import './style.scss';

const b = block('project-constructor-classifications-section-item');

type Props = {
  data: M.ClassificationPaths;
};

function Item({ data }: Props) {
  const t = I18n.useGetMultilingProjectTranslation();

  const text =
    I18n.useText(i18nData).steps.advancedSettings.section.classifications;

  const handleDelete = useCallback(() => {
    const { uuid } = data.path[data.path.length - 1];

    classificationsUnit.setState(prevState =>
      prevState.filter(x => x !== uuid),
    );
  }, [data.path]);

  return (
    <li className={b()}>
      {data.path.map(path => t(path.caption)).join(' / ')}
      <WidgetDeleteControl.Component
        confirmMessage={text.confirmDeleteMessage}
        onDelete={handleDelete}
      />
    </li>
  );
}

export const Component = React.memo(Item);
