import * as M from 'types/serverModels';
import { PrimaryStateUnit } from 'utils/State';
import { createRequiredContext } from 'utils/react/RequiredContext';

type Dependencies = {
  data: M.QuestionnaireData[];
  hasUserFeatureUnit: PrimaryStateUnit<boolean | null>;
};

export const DependenciesContext = createRequiredContext<Dependencies>();
