import React from 'react';

import { Page } from 'services';

import { routeTree } from '../../routeTree';
import * as features from './features';

type Props = {};

function CourseConstructor({}: Props) {
  return <features.CourseConstructor.Component />;
}

export const Component = Page.makePage({
  path: routeTree.LANG.course.constructor.getPath(),
  routeProps: { exact: false },
  scrollTo: 'top-on-mount',
  Component: React.memo(CourseConstructor) as typeof CourseConstructor,
  features: Object.values(features).map(x => x.feature),
}) as typeof CourseConstructor;
