import { makeFeature } from 'services/Feature';

import i18nData from './i18n.json';

export const feature = makeFeature({
  name: 'LandingPageHeader1And2Elements',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "landing-page-header-1-and-2-elements" */
      './Component'
    ),
  i18nData,
  i18nSharedReferences: null,
  subfeatures: [],
});

export const Component = feature.Component;
