import React, { useMemo } from 'react';

import { List } from 'features/project/Constructor/subfeatures';
import { block } from 'utils/classname';
import { useRequiredContext } from 'utils/react/RequiredContext';

import { ConfigContext } from '../../ConfigContext';
import * as RightPart from './RightPart';

const b = block('authors-list-header');

type Props = {};

function Header({}: Props) {
  const { config } = useRequiredContext(ConfigContext);

  const LeftPart = useMemo(
    () =>
      List.TwoColumnHeader.TitleLeftColumn.makeComponent({
        useTitle: () => config.listTitle,
      }),
    [config.listTitle],
  );

  return (
    <List.TwoColumnHeader.Component
      className={b()}
      LeftPart={LeftPart}
      RightPart={RightPart.Component}
      backgroundColor="accent-0"
    />
  );
}

export const Component = React.memo(Header);
