import { emptyReference } from 'services/I18n/constants';
import { makeSingleUnitValidator } from 'utils/validators';

import { Validators } from './types';

export function makeConstructorValidators(): Validators {
  return {
    forSingleVariant: [
      makeSingleUnitValidator((value: string | null) => {
        return value === null
          ? { kind: 'invalid', messageReference: emptyReference }
          : { kind: 'valid' };
      }),
    ],
    forMultipleVariants: [
      makeSingleUnitValidator((values: boolean[]) => {
        return values.every(x => x === false)
          ? { kind: 'invalid', messageReference: emptyReference }
          : { kind: 'valid' };
      }),
    ],
  };
}
