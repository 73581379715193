import { useMemo } from 'react';

import { I18n } from 'services';

type References = Record<
  keyof I18n.SharedI18nData['course']['tabs'],
  I18n.EntryReference
>;

export function useTabText(references: References) {
  const coverText = I18n.useReference(references.cover);
  const trackText = I18n.useReference(references.track);
  const progressText = I18n.useReference(references.progress);
  const projectsText = I18n.useReference(references.projects);
  const resultsText = I18n.useReference(references.results);
  const conclusionsText = I18n.useReference(references.conclusions);
  const blogText = I18n.useReference(references.blog);
  const membersText = I18n.useReference(references.members);

  const text = useMemo(() => {
    return {
      cover: coverText,
      track: trackText,
      progress: progressText,
      projects: projectsText,
      results: resultsText,
      conclusions: conclusionsText,
      blog: blogText,
      members: membersText,
    };
  }, [
    coverText,
    trackText,
    progressText,
    projectsText,
    resultsText,
    conclusionsText,
    blogText,
    membersText,
  ]);

  return text;
}
