import * as M from 'types/serverModels';
import { makePrimaryUnit } from 'utils/State';
import { makeUUID } from 'utils/UUID';

import { BasketState } from '../../types';

export function convertToState(data: M.Basket): BasketState {
  return {
    ...data,
    contents:
      data.contents?.map(x => ({
        ...x,
        key: makeUUID(),
        qty: makePrimaryUnit(x.qty),
      })) || [],
  };
}
