import * as TS from 'types';

const regularXAxisOptions: TS.XAxisData[] = [
  { kind: 'latitude' },
  { kind: 'longtitude' },
  { kind: 'questionnaire-submit-date' },
];

export const chartTypeToXAxisConstOptions: Record<
  TS.ChartType,
  TS.XAxisData[]
> = {
  line: regularXAxisOptions,
  area: regularXAxisOptions,
  scatter: regularXAxisOptions,
  column: regularXAxisOptions,
  'column-stack': regularXAxisOptions,
  pie: regularXAxisOptions,
};
