import React, { useMemo, useCallback, useEffect } from 'react';

type Props = {
  rootRef: React.RefObject<HTMLElement>;
  placeholderRef: React.RefObject<HTMLElement>;
};

function ResizeObserver({ rootRef, placeholderRef }: Props) {
  const update = useCallback(() => {
    if (rootRef.current === null) {
      return;
    }

    const rootHeight = rootRef.current.getBoundingClientRect().height;
    const rootPaddingBottom = parseFloat(
      getComputedStyle(rootRef.current).paddingBottom,
    );

    if (placeholderRef.current !== null) {
      const placeholderHeight = rootHeight - rootPaddingBottom;
      const placeholderHeightStyle = `${placeholderHeight}px`;

      placeholderRef.current.style.setProperty(
        '--placeholder-height',
        placeholderHeightStyle,
      );
    }
  }, [rootRef, placeholderRef]);

  const handleResize = useCallback(() => {
    update();
  }, [update]);

  const resizeObserver = useMemo(
    () => new globalThis.ResizeObserver(handleResize),
    [handleResize],
  );

  useEffect(() => {
    update();
  }, [update]);

  useEffect(() => {
    if (rootRef.current !== null) {
      resizeObserver.observe(rootRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [rootRef, resizeObserver]);

  return null;
}

export const Component = React.memo(ResizeObserver);
