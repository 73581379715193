import React, { useMemo, useCallback } from 'react';

import { quizLanguageUnit } from 'features/quiz/Constructor/units';
import { I18n } from 'services';
import { makeFormElementState } from 'utils/FormState';
import { MappedState } from 'utils/State';

import { MatchQuestion } from '../../../../../subfeatures';
import { PreviewModeFormProps } from '../../../types';
import { makeServerQuestion } from '../makeServerQuestion';
import { StateInstance } from '../types';

function PreviewModeForm({ instance }: PreviewModeFormProps<StateInstance>) {
  const model = useMemo(() => makeServerQuestion(instance), [instance]);
  const formElementState = useMemo(
    () =>
      makeFormElementState(
        Object.entries(model['matchings']).reduce<
          MappedState<MatchQuestion.Props['formElementState']['units']['value']>
        >((acc, [key, x]) => ({ ...acc, [key]: makeFormElementState(x) }), {}),
      ),
    [model],
  );

  const useTranslation: MatchQuestion.Props['useTranslation'] = useCallback(
    function useTranslation() {
      const lang = quizLanguageUnit.useState();

      return useCallback(
        text => I18n.getMultilingTranslation(lang, text),
        [lang],
      );
    },
    [],
  );

  return (
    <MatchQuestion.Component
      formElementState={formElementState}
      model={model}
      kind="form"
      useTranslation={useTranslation}
    />
  );
}

export const Component = React.memo(PreviewModeForm);
