import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  qqfile: Blob;
};

type OutputData = (Pick<M.User, 'name' | 'login' | 'email' | 'role'> & {
  uuid: M.UUID;
  password: string;
} & (
    | { status: 1 }
    | {
        status: 0;
        error: Partial<
          Record<
            'login',
            ('incorrectLogin' | 'occupiedLogin' | 'notUniqueLogin')[]
          > &
            Record<
              'email',
              ('occupiedEmail' | 'notUniqueEmail' | 'badEmailFormat')[]
            > &
            Record<'password', 'badPassword'[]>
        >;
      }
  ))[];

export const userImportFile = makeService<InputData, OutputData>(
  'user_import_file',
  'post-file',
);
