import { widgetTypeToQuestionKindMapping } from 'shared/constants';
import * as TS from 'types';

import { WidgetKey } from '../../types';

type Inclusion = {
  includes: boolean;
  key: WidgetKey;
};

export function getWidgetKeys(questionKind: TS.QuestionKind): WidgetKey[] {
  const chartTypeToWidgetKey: Record<TS.ChartType, WidgetKey> = {
    area: 'area',
    'column-stack': 'columnStack',
    column: 'column',
    line: 'line',
    pie: 'pie',
    scatter: 'scatter',
  };

  const chartInclusions = Object.entries(
    widgetTypeToQuestionKindMapping.chart,
  ).map(
    ([chartType, mapping]): Inclusion => ({
      includes: mapping['x-axis'].includes(questionKind),
      key: chartTypeToWidgetKey[chartType as TS.ChartType],
    }),
  );

  const widgetKeysInclusions: Inclusion[] = [
    {
      includes: Object.values(widgetTypeToQuestionKindMapping.map)
        .flat()
        .includes(questionKind),
      key: 'map',
    },

    {
      includes: Object.values(widgetTypeToQuestionKindMapping.gallery)
        .flat()
        .includes(questionKind),
      key: 'gallery',
    },

    {
      includes:
        widgetTypeToQuestionKindMapping.textsCloud.includes(questionKind),
      key: 'textsCloud',
    },

    {
      includes:
        widgetTypeToQuestionKindMapping.textsList.includes(questionKind),
      key: 'textsList',
    },

    {
      includes: Object.values(widgetTypeToQuestionKindMapping.vennChart)
        .flat()
        .includes(questionKind),
      key: 'venn',
    },

    {
      includes: Object.values(widgetTypeToQuestionKindMapping.filesList)
        .flat()
        .includes(questionKind),
      key: 'filesList',
    },

    {
      includes: Object.values(widgetTypeToQuestionKindMapping.timeline)
        .flat()
        .includes(questionKind),
      key: 'timeline',
    },

    ...chartInclusions,
  ];

  return widgetKeysInclusions.filter(x => x.includes).map(x => x.key);
}
