import { FormElementState, makeFormElementState } from 'utils/FormState';

import {
  QuestionConstructorWidget,
  SharedInstanceStateConstructor,
} from '../../types';
import * as FormExtension from './FormExtension';
import * as HeaderExtension from './HeaderExtension';
import * as PreviewModeForm from './PreviewModeForm';
import { ServerQuestion, StateInstance } from './types';

export type { StateInstance };

export const constructorWidget: QuestionConstructorWidget<
  'map',
  StateInstance,
  typeof makeInstance
> = {
  key: 'map',
  icon: 'map',
  makeInstance,
  HeaderExtension: HeaderExtension.Component,
  FormExtension: FormExtension.Component,
  PreviewModeForm: PreviewModeForm.Component,
};

export function makeServerQuestion(
  shouldShowFiguresOnMapState: FormElementState<boolean>,
  shouldShowImageOnMapState: FormElementState<boolean>,
  imageState: FormElementState<string | null>,
): Partial<ServerQuestion> {
  const shouldShowFiguresOnMap = shouldShowFiguresOnMapState.getValue();
  const shouldShowImageOnMap = shouldShowImageOnMapState.getValue();
  const image = imageState.getValue();
  return {
    type: shouldShowFiguresOnMap ? 'draw' : 'map',
    settings: shouldShowImageOnMap && image ? { background: image } : undefined,
  };
}

export function makeInstance(
  makeSharedInstanceState: SharedInstanceStateConstructor,
  shouldShowFiguresOnMap = false,
  shouldShowImageOnMap = false,
  image: string | null = null,
): StateInstance {
  const shouldShowFiguresOnMapState = makeFormElementState(
    shouldShowFiguresOnMap,
  );
  const shouldShowImageOnMapState = makeFormElementState(shouldShowImageOnMap);
  const imageState = makeFormElementState<string | null>(image);

  return {
    kind: 'map',
    shouldShowFiguresOnMap: shouldShowFiguresOnMapState,
    shouldShowImageOnMap: shouldShowImageOnMapState,
    image: imageState,
    makeServerQuestion: () =>
      makeServerQuestion(
        shouldShowFiguresOnMapState,
        shouldShowImageOnMapState,
        imageState,
      ),
    ...makeSharedInstanceState(),
  };
}
