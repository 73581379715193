import React, { useEffect } from 'react';

import { ErrorMessage } from 'components';
import { ProjectWriteContext } from 'features/project/Constructor/FormWithSteps/ProjectWriteContext';
import { block } from 'utils/classname';
import { useRequiredContext } from 'utils/react/RequiredContext';

import {
  featuresChecksUnit,
  featuresFormSectionState,
  featuresUnit,
} from '../../units/inputState';
import * as FeatureElement from './FeatureElement';
import './style.scss';

const b = block('project-constructor-features-section');

type Props = {};

function Features({}: Props) {
  const features = featuresUnit.useState();
  const error = featuresFormSectionState.units.error.useState();

  const { saveProject } = useRequiredContext(ProjectWriteContext);

  useEffect(() => {
    return featuresChecksUnit.subscribe({
      name: 'features-changed',
      callback: saveProject,
    });
  }, [saveProject]);

  return (
    <div className={b()}>
      <div className={b('checkboxes', { invalid: error !== null })}>
        {features.map(x => (
          <FeatureElement.Component key={x.id} data={x} />
        ))}
      </div>
      <ErrorMessage.Component messageReference={error} rows={1} />
    </div>
  );
}

export const Component = React.memo(Features);
