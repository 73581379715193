import * as TS from 'types';
import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  project: M.UUID;
  lang: TS.Language;
};

export const projectGeo = makeService<InputData, M.ProjectGeo>(
  'project_geo',
  'get',
);
