import { Language } from 'types';
import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  lang?: Language;
  hidden?: boolean;
};

export const courseGroupList = makeService<InputData, M.CourseGroup[]>(
  'course_group_list',
  'get',
);
