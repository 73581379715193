import { I18n } from 'services';

import { CallInputData } from '../types';
import type { makeFieldsetState } from './makeFieldsetState';

type Options = {
  fieldsetState: ReturnType<typeof makeFieldsetState>;
};

export function getCallInputData({ fieldsetState }: Options): CallInputData {
  const [longitude, latitude] = fieldsetState.location.units.value.getState();

  return {
    avatar: fieldsetState.avatar.units.value.getState()?.thumb[0].url,
    name: fieldsetState.name.units.value.getState(),
    badge: fieldsetState.badge.units.value.getState(),
    native_langs: fieldsetState.nativeLangs.units.value
      .getState()
      .map(nativeLang => nativeLang.value),
    langs: fieldsetState.langs.units.value.getState().map(lang => lang.value),
    location: { longitude, latitude },
    subjects: fieldsetState.subjects.units.value
      .getState()
      .map(subject =>
        typeof subject === 'string'
          ? { lang: I18n.activeLangStateUnit.getState(), value: subject }
          : subject.value,
      ),
    interests: fieldsetState.interests.units.value
      .getState()
      .map(interest =>
        typeof interest === 'string' ? interest : interest.label,
      ),
    education: fieldsetState.education?.units.value.getState(),
    about: fieldsetState.about.units.value.getState(),
  };
}
