import React, { useCallback } from 'react';

import { TextInput } from 'components';
import { ProjectWriteContext } from 'features/project/Constructor/FormWithSteps/ProjectWriteContext';
import i18nData from 'features/project/Constructor/i18n.json';
import { I18n } from 'services';
import { useRequiredContext } from 'utils/react/RequiredContext';

import { weightState } from '../../units/inputState';

type Props = {};

const forwardStateConverter = (state: number | null) => {
  return state === null ? '' : String(state);
};

const backwardStateConverter = (state: string) => {
  return state === '' ? null : Number(state);
};

function Weight({}: Props) {
  const text = I18n.useText(i18nData);

  const { saveProject } = useRequiredContext(ProjectWriteContext);

  const handleBlur = useCallback(() => {
    saveProject();
  }, [saveProject]);

  return (
    <TextInput.Component
      formElementState={weightState.useBridgeUnitMemo(
        forwardStateConverter,
        backwardStateConverter,
      )}
      placeholder={text.steps.advancedSettings.section.weight.placeholder}
      errorRows={1}
      isOnlyWholeNumbers
      type="number"
      onBlur={handleBlur}
    />
  );
}

export const Component = React.memo(Weight);
