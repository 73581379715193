import React, { useEffect } from 'react';

import { Preloader } from 'components';
import * as ChooseTariff from 'features/shop/ChooseTariff';
import { Page, API } from 'services';
import { userStateUnit } from 'shared/stateUnits';
import { block } from 'utils/classname';

import { routeTree } from '../routeTree';
import * as Catalog from './Catalog';
import * as Tariff from './Tariff';
import * as features from './features';
import './style.scss';

const b = block('shop');

const callStateUnit = API.services.storePackageList.makeCallStateUnit();
const featureCallStateUnit = API.services.storeFeatureRef.makeCallStateUnit();

function Shop() {
  const callState = callStateUnit.useState();
  const featureCallState = featureCallStateUnit.useState();
  const userState = userStateUnit.useState();

  const call = API.services.storePackageList.useCall(callStateUnit);
  const callStoreFeatureRef =
    API.services.storeFeatureRef.useCall(featureCallStateUnit);

  useEffect(() => {
    let callOptions = {};
    if (userState.kind === 'loaded') {
      callOptions = {
        login: userState.user.login,
      };
    }

    call(callOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState.kind]);

  useEffect(() => {
    callStoreFeatureRef({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const callStateDeriver = API.makeCallStateUnitsDeriver('every');

  return (
    <div className={b()}>
      <ChooseTariff.Component />
      <div className={b('side-menu')}>
        {API.renderCallState(callState, {
          successful: ({ data: packages }) => (
            <features.ShopMenu.Component packages={packages} />
          ),
        })}
      </div>

      <div className={b('card')}>
        {API.renderCallState(callStateDeriver(callState, featureCallState), {
          successful: ({ data }) => {
            const [packages, featuresRef] = data;
            return (
              <>
                <Catalog.Component packages={packages} />
                {
                  <Tariff.Component
                    packages={packages}
                    featuresRef={featuresRef}
                  />
                }
              </>
            );
          },
          pending: () => <Preloader.Component size="m" />,
        })}
      </div>
    </div>
  );
}

export const Component = Page.makePage({
  path: routeTree.LANG.shop.getPath(),
  routeProps: { exact: false },
  scrollTo: 'top-on-mount',
  Component: React.memo(Shop),
  features: Object.values(features).map(x => x.feature),
});
