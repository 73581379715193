import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  file: File;
  data: M.ImageData;
};

export const imageSave = makeService<InputData, M.ImageInfo>(
  'image_save',
  'post-file',
);
