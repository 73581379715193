import { makeDerivedUnit, makeMappingUnit } from 'utils/State';

import { State } from './types';

const mapping = {
  Date: 'cdate',
  Latitude: 'lat',
  Longitude: 'lan',
  Author: 'user',
  Likes: 'likes',
};

export function makeFilterStateUnitForRequest({
  fieldState,
  orderState,
}: State) {
  return makeMappingUnit(
    makeDerivedUnit(fieldState.units.value, orderState.units.value).getUnit(
      (field, order) => ({
        [mapping[field]]: order === 'Asc' ? 1 : -1,
      }),
    ),
  );
}

export type FilterForRequest = ReturnType<typeof makeFilterStateUnitForRequest>;
