import React, { useCallback, useEffect } from 'react';

import { Button } from 'components';
import { ProjectWriteContext } from 'features/project/Constructor/FormWithSteps/ProjectWriteContext';
import { I18n } from 'services';
import { makeFormElementState } from 'utils/FormState';
import { makeUUID } from 'utils/UUID';
import { block } from 'utils/classname';
import { useRequiredContext } from 'utils/react/RequiredContext';

import i18nData from '../../../../../i18n.json';
import { productsUnit } from '../../units/inputState';
import * as Product from './Product';
import './style.scss';

const b = block('project-constructor-products');

type Props = {};

function Products({}: Props) {
  const text = I18n.useText(i18nData).steps.advancedSettings.section.products;

  const products = productsUnit.useState();

  const { saveProject } = useRequiredContext(ProjectWriteContext);

  const handleAddProduct = useCallback(() => {
    productsUnit.setState(prev => [
      ...prev,
      { id: makeUUID(), formElementState: makeFormElementState('') },
    ]);
  }, []);

  const handleDeleteProduct = useCallback((id: string) => {
    productsUnit.setState(prev => prev.filter(x => x.id !== id));
  }, []);

  useEffect(() => {
    return productsUnit.subscribe({
      name: 'products-changed',
      callback: () => {
        saveProject();
      },
    });
  }, [saveProject]);

  return (
    <div className={b()}>
      {products.length > 0 && (
        <div className={b('list')}>
          {products.map(({ id, formElementState }) => (
            <Product.Component
              key={id}
              id={id}
              formElementState={formElementState}
              onDelete={handleDeleteProduct}
            />
          ))}
        </div>
      )}
      <Button.Component
        Icon={Button.PlusIcon.Component}
        type="button"
        color="secondary"
        onClick={handleAddProduct}
      >
        {text.buttonLabel}
      </Button.Component>
    </div>
  );
}

export const Component = React.memo(Products);
