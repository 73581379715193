import React from 'react';

import { block } from 'utils/classname';

import { FormExtensionProps } from '../../../types';
import { ScoreInput } from '../../shared';
import { StateInstance } from '../types';
import * as LeftColumnList from './LeftColumnList';
import * as Matchings from './Matchings';
import * as RightColumnList from './RightColumnList';
import './style.scss';

const b = block('quiz-match-question-form-extension');

function FormExtension({ instance }: FormExtensionProps<StateInstance>) {
  return (
    <div className={b()}>
      <LeftColumnList.Component instance={instance} />
      <RightColumnList.Component instance={instance} />
      <Matchings.Component instance={instance} />
      <ScoreInput.Component
        formElementState={instance.score}
        isOnlyPositiveNumbers
      />
    </div>
  );
}

export const Component = React.memo(FormExtension);
