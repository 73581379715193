import { isRequired } from 'features/project/Constructor/i18nSharedReferences';
import * as M from 'types/serverModels';
import { FormElementState, makeFormElementState } from 'utils/FormState';
import { makePrimaryUnit } from 'utils/State';
import { nonNull } from 'utils/validators';

import {
  QuestionConstructorWidget,
  SharedInstanceStateConstructor,
} from '../../types';
import * as FormExtension from './FormExtension';
import * as PreviewModeForm from './PreviewModeForm';
import { StateInstance } from './types';

export type { StateInstance };

export const constructorWidget: QuestionConstructorWidget<
  'sensorData',
  StateInstance,
  typeof makeInstance
> = {
  key: 'sensorData',
  icon: 'sensorData',
  makeInstance,
  FormExtension: FormExtension.Component,
  PreviewModeForm: PreviewModeForm.Component,
};

export function makeServerQuestion(
  probetypeState: FormElementState<M.UUID | null>,
): Partial<M.ProbeQuestion> {
  return {
    type: 'probe',
    probe: probetypeState.units.value.getState() || '',
  };
}

export function makeInstance(
  makeSharedInstanceState: SharedInstanceStateConstructor,
  probetype: M.UUID | null = null,
): StateInstance {
  const probetypeState = makeFormElementState<M.UUID | null>(probetype, [
    nonNull(isRequired),
  ]);

  return {
    kind: 'sensorData',
    probetypeID: probetypeState,
    makeServerQuestion: () => makeServerQuestion(probetypeState),
    ...makeSharedInstanceState(makePrimaryUnit([probetypeState])),
  };
}
