import type * as ProjectViewModal from 'features/project/view/Modal';

import { projectXShortsUnit } from '../units';

export const handleQuestionnaireComplete: ProjectViewModal.Props['onQuestionnaireComplete'] =
  ({ questionnaire }) => {
    const { project, answersCounter } = Array.isArray(questionnaire)
      ? {
          project: questionnaire[0]?.project,
          answersCounter: questionnaire.length,
        }
      : { project: questionnaire.project, answersCounter: 1 };

    if (!project) {
      console.error('undefined questionnaire project');

      return;
    }

    const projectXShorts = projectXShortsUnit.getState();
    const projectUnit = projectXShorts?.[project];

    if (projectUnit === undefined) {
      console.error('undefined project');

      return;
    }

    projectUnit.setState(prev => ({
      ...prev,
      results_counter: (prev.results_counter || 0) + answersCounter,
    }));
  };
