import React from 'react';

import { TextInput } from 'components';
import { I18n } from 'services';
import { FormElementState, useFormElementState } from 'utils/FormState';
import { makePrimaryUnit } from 'utils/State';
import { nonEmptyString } from 'utils/validators';

import i18nData from './i18n.json';
import { requiredFieldReference } from './i18nSharedReferences';
import './style.scss';

type Props = {
  formElementState: FormElementState<string>;
};

const passwordVisibilityUnit = makePrimaryUnit<boolean>(false);

export const useFieldState = () => {
  return useFormElementState<string>('', [
    nonEmptyString(requiredFieldReference),
  ]);
};

const Header = TextInput.makeHeaderWithLabel({
  useLabel: () => I18n.useText(i18nData).label,
  useTooltip: () => I18n.useText(i18nData).tooltip,
  color: 'black',
});

const PasswordVisibilityToggle =
  TextInput.rightPartsWithinCountour.PasswordVisibilityToggle.makeComponent({
    passwordVisibilityUnit,
  });

function PasswordInput({ formElementState }: Props) {
  const text = I18n.useText(i18nData);
  const passwordVisible = passwordVisibilityUnit.useState();

  return (
    <TextInput.Component
      formElementState={formElementState}
      Header={Header}
      RightPartWithinContour={PasswordVisibilityToggle}
      placeholder={text.placeholder}
      type={passwordVisible ? 'text' : 'password'}
    />
  );
}

export const Component = React.memo(PasswordInput);
