import { makeMapping } from 'utils/collection';
import { createRequiredContext } from 'utils/react/RequiredContext';

import { QuestionConstructor, QuestionKey } from './types';

type Config = {
  questions: QuestionConstructor<any, any, any>[];
  questionKeyToConstructor: Record<
    QuestionKey,
    QuestionConstructor<QuestionKey, any, any>
  >;
};

export const QuestionsConfigContext = createRequiredContext<Config>();

export function makeConfig(
  questions: QuestionConstructor<any, any, any>[],
): Config {
  return {
    questions,
    questionKeyToConstructor: makeMapping(
      questions,
      x => x.key,
      x => x,
    ),
  };
}
