import React from 'react';

import { block } from 'utils/classname';

import './style.scss';

const b = block('table-placeholder');

type Props = { useText(): string };

export function makePlaceholder({ useText }: Props) {
  return React.memo(() => {
    const text = useText();

    return <p className={b()}>{text}</p>;
  });
}
