import { Merge } from 'type-fest';

import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  login?: M.User['login'];
};

export type Package = Merge<
  M.Package,
  {
    products: M.Product[];
    hidden?: boolean;
    _isFree: boolean;
    _myPackage: boolean;
    _currentPackage: boolean;
    _expiresIn: number;
    _expiresOn: M.Datetime;
  }
>;

type OutputData = Package[] | undefined;

export const storePackageList = makeService<InputData, OutputData>(
  'store_package_list',
  'get',
);
