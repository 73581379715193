import { MathJax } from 'better-react-mathjax';
import React from 'react';

import { block } from 'utils/classname';
import { useRequiredContext } from 'utils/react/RequiredContext';

import { WidgetContext } from '../../Context';
import './style.scss';

const b = block('header-of-constructor-widget-for-material-in-preview-mode');

type Props = {};

function PreviewMode({}: Props) {
  const { stateInstance } = useRequiredContext(WidgetContext);
  const title = stateInstance.materialTitle.units.value.useState();

  return (
    <div className={b()}>
      <h3 className={b('title')}>
        <MathJax dynamic inline>
          {title}
        </MathJax>
      </h3>
    </div>
  );
}

export const Component = React.memo(PreviewMode);
