import React, { useCallback } from 'react';

import { ProjectWriteContext } from 'features/project/Constructor/FormWithSteps/ProjectWriteContext';
import { I18n } from 'services';
import { useRequiredContext } from 'utils/react/RequiredContext';

import i18nData from '../../../../../i18n.json';
import { TextEditor } from '../../../../../subfeatures';
import { paywallWarningState } from '../../units/inputState';

type Props = {};

function PaywallWarning({}: Props) {
  const text = I18n.useText(i18nData).steps.advancedSettings.section;

  const { saveProject } = useRequiredContext(ProjectWriteContext);

  const handleBlur = useCallback(() => {
    saveProject();
  }, [saveProject]);

  return (
    <TextEditor.Component
      formElementState={paywallWarningState}
      placeholder={text.paywallWarning.placeholder}
      statusbar={false}
      resize={false}
      onBlur={handleBlur}
      rounded
    />
  );
}

export const Component = React.memo(PaywallWarning);
