import { isRequired } from 'features/project/Constructor/i18nSharedReferences';
import { getProjectLanguage } from 'features/project/Constructor/utils';
import { Filter } from 'features/widgets/shared';
import { I18n } from 'services';
import { MultilangFormState } from 'services/I18n';
import * as M from 'types/serverModels';
import {
  makeFormElementState,
  FormElementState,
  isFormElementState,
} from 'utils/FormState';
import { FormEntityState } from 'utils/FormState/types';
import {
  AbstractStateUnit,
  makeDerivedUnit,
  makeMappingUnitFromUnit,
  makePrimaryUnit,
} from 'utils/State';
import { makeMapping } from 'utils/collection';
import { makeRangeValidators, nonEmptyString } from 'utils/validators';

import { flatQuestionInstancesUnit } from '../../InputDataForm/units/instances';
import {
  AbstractConstructorWidgetStateInstance,
  SharedServerWidget,
  WidgetMode,
} from '../types';
import { SharedConstructorArgs } from './shared/SharedConstructorArgs';

type SharedInstancePart = Pick<
  AbstractConstructorWidgetStateInstance<any, any>,
  | 'title'
  | 'filter'
  | 'mode'
  | 'validate'
  | 'validateForEmulation'
  | 'makeSharedServerWidget'
  | 'hasErrorUnit'
>;

type GetCachedState<T> = (id: string, initialValue: T) => FormElementState<T>;

function makeGetCachedState<T>(
  initialCache: Record<string, FormElementState<T>> = {},
): GetCachedState<T> {
  const cache: Record<string, FormElementState<T>> = initialCache;

  return (id, initialValue) => {
    if (cache[id] === undefined) {
      cache[id] = makeFormElementState(initialValue);
    }

    return cache[id];
  };
}

type NumericCachedState<T> = {
  fromState: FormElementState<T>;
  toState: FormElementState<T>;
};

type GetNumericCachedState<T> = (
  id: string,
  initialFromValue: T,
  initialToValue: T,
) => NumericCachedState<T>;

function makeGetNumericCachedState<T extends number | null>(
  initialCache: Record<string, NumericCachedState<T>> = {},
): GetNumericCachedState<T> {
  const cache: Record<string, NumericCachedState<T>> = initialCache;

  return (id, initialFrom, initialTo) => {
    if (cache[id] === undefined) {
      const fromState = makeFormElementState(initialFrom);
      const toState = makeFormElementState(initialTo);

      const { fromRangeValidator, toRangeValidator } = makeRangeValidators(
        {
          valueUnit: fromState.units.value as any,
          messageReference: I18n.constants.emptyReference,
        },
        {
          valueUnit: toState.units.value as any,
          messageReference: I18n.constants.emptyReference,
        },
        true,
      );

      fromState.setValidators(fromRangeValidator);
      toState.setValidators(toRangeValidator);

      cache[id] = { fromState, toState };
    }

    return cache[id];
  };
}

const makeFilteringQuestionsUnit = (answersFilter: M.Filter['answers']) => {
  const checkedAnswerIDs = answersFilter
    ? Object.values(answersFilter)
        .filter((x): x is string[] => Array.isArray(x))
        .flat()
    : [];

  const getVariantsCheckboxState = makeGetCachedState(
    makeMapping(
      checkedAnswerIDs,
      x => x,
      () => makeFormElementState<boolean>(true),
    ),
  );

  const getNumericCheckboxState = makeGetNumericCachedState<number | null>();

  const getDateFromCheckboxState = makeGetCachedState<Date | null>();
  const getDateToCheckboxState = makeGetCachedState<Date | null>();

  return makeDerivedUnit(flatQuestionInstancesUnit).getUnit<
    Filter.FilteringQuestion[]
  >(instances => {
    return instances.flatMap<Filter.FilteringQuestion>(instance => {
      switch (instance.kind) {
        case 'variantSelection':
          return {
            kind: 'variant-selection',
            id: instance.id,
            titleUnit: instance.questionText.formElementState.units.value,
            answers: makeDerivedUnit(instance.variants).getUnit(variants => {
              return variants.map((x): Filter.VariantSelectionAnswer => {
                return {
                  checkboxState: getVariantsCheckboxState(x.id, false),
                  id: x.id,
                  text: x.text.formElementState.units.value,
                };
              });
            }),
          };
        case 'number':
        case 'sensorData': {
          const answers = answersFilter?.[instance.id] as
            | M.FilterNumericAnswers
            | undefined;

          const { fromState, toState } = getNumericCheckboxState(
            instance.id,
            answers?.from ?? null,
            answers?.to ?? null,
          );

          return {
            kind: 'numeric',
            id: instance.id,
            titleUnit: instance.questionText.formElementState.units.value,
            fromState,
            toState,
          };
        }
        case 'date': {
          const answers = answersFilter?.[instance.id] as
            | M.FilterDateAnswers
            | undefined;
          return {
            kind: 'date',
            id: instance.id,
            titleUnit: instance.questionText.formElementState.units.value,
            fromState: getDateFromCheckboxState(
              instance.id,
              answers?.from === undefined ? null : new Date(answers.from),
            ),
            toState: getDateToCheckboxState(
              instance.id,
              answers?.to === undefined ? null : new Date(answers.to),
            ),
          };
        }
        default:
          return [];
      }
    });
  });
};

function makeSharedServerWidget(
  id: string,
  titleState: MultilangFormState,
  sort?: any,
): SharedServerWidget {
  return {
    uuid: id,
    title: titleState.getMergedMultilingString(getProjectLanguage()),
    ...(sort ? { descriptor: { sort } } : {}),
  };
}

function validateInstance(
  titleState: FormElementState<string>,
  formNodesUnit: AbstractStateUnit<FormEntityState[]>,
) {
  const formNodes = formNodesUnit.getState();
  return [titleState, ...formNodes]
    .map(x => x.formNode.validate())
    .every(x => x);
}

export function makeSharedInstancePart({
  id,
  formEntitiesUnit = makePrimaryUnit([]),
  initialFilterState,
  titleMultilingString,
  titleValue = '',
  answersFilter,
}: {
  formEntitiesUnit?: AbstractStateUnit<FormEntityState[]>;
} & SharedConstructorArgs): SharedInstancePart {
  const titleState = I18n.makeMultilangFormState(
    makeFormElementState(titleValue, [nonEmptyString(isRequired)]),
    titleMultilingString,
  );

  const filterState = Filter.makeState(
    makeFilteringQuestionsUnit(answersFilter),
    initialFilterState,
  );

  const hasErrorUnit = makeDerivedUnit(
    makeMappingUnitFromUnit(
      makeDerivedUnit(
        formEntitiesUnit,
        filterState.filteringQuestionsUnit,
      ).getUnit((formEntities, filterState) => {
        return [
          ...filterState.flatMap(state =>
            Object.values(state).filter(x => isFormElementState(x)),
          ),
          ...formEntities,
          titleState.formElementState,
        ].map(x => x.units.error);
      }),
    ),
  ).getUnit(errors => errors.some(x => x !== null));

  const isValid =
    [...formEntitiesUnit.getState(), titleState.formElementState].every(x =>
      x.formNode.isValid(),
    ) && filterState.validate();

  return {
    title: titleState,
    filter: filterState,
    mode: makePrimaryUnit<WidgetMode>(isValid ? 'preview' : 'edit'),
    makeSharedServerWidget: () => makeSharedServerWidget(id, titleState),
    validate: () => {
      return (
        validateInstance(titleState.formElementState, formEntitiesUnit) &&
        filterState.validate()
      );
    },
    validateForEmulation: () =>
      formEntitiesUnit
        .getState()
        .map(x => x.formNode.validate())
        .every(x => x) && filterState.validate(),
    hasErrorUnit,
  };
}
