import React from 'react';

import { block } from 'utils/classname';
import { parseHTML } from 'utils/react';

import { workProcessUnit } from '../units';

const b = block('instruction-preview');

type Props = {};

function Preview({}: Props) {
  const workProcess = workProcessUnit.units.value.useState();

  return <div className={b()}>{parseHTML(workProcess)}</div>;
}

export const Component = React.memo(Preview) as typeof Preview;
