import * as M from 'types/serverModels';

import { subscribers } from './subscribers';
import { Subscriber } from './types';

export function subscribe(
  type: M.MessageNotification['ntype'],
  subscriber: Subscriber<M.MessageNotification[]>,
): () => void;
export function subscribe(
  type: M.ProjectInvitationNotification['ntype'],
  subscriber: Subscriber<M.ProjectInvitationNotification[]>,
): () => void;
export function subscribe(
  type: M.GroupNotification['ntype'],
  subscriber: Subscriber<M.GroupNotification[]>,
): () => void;
export function subscribe(
  type: M.Notification['ntype'],
  subscriber: Subscriber,
): () => void;
export function subscribe(
  type: M.Notification['ntype'],
  changeSubscriber: Subscriber<any>,
): () => void {
  subscribers[type].push(changeSubscriber);

  return () => {
    subscribers[type] = subscribers[type].filter(x => x !== changeSubscriber);
  };
}
