import { InstanceView, StoreCachedInstances } from './types';

export function getInstanceViews(
  instances: StoreCachedInstances,
): InstanceView[] {
  return instances.instances.map(
    (x, index): InstanceView => ({
      instance: x,
      path: { index },
    }),
  );
}
