import { useMemo } from 'react';

import { makeFeature } from 'services/Feature';
import * as TS from 'types';
import { makeFormElementState } from 'utils/FormState';

import i18nData from './i18n.json';
import * as i18nSharedReferences from './i18nSharedReferences';
import * as subfeatures from './subfeatures';

export const feature = makeFeature({
  name: 'FileUpload',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "file-upload" */
      './Component'
    ),
  i18nSharedReferences: Object.values(i18nSharedReferences),
  i18nData,
  subfeatures: Object.values(subfeatures).map(x => x.feature),
});

export type { Props } from './Component';

export { getFileName } from './defaults';

export type { InputHandle } from './types';

export function makeFieldState<T extends TS.URLString | TS.URLString[]>(
  initialState: T | null = null,
  validators?: TS.Validator[],
) {
  return makeFormElementState<T | null>(initialState, validators);
}

export function useFieldState<T extends TS.URLString | TS.URLString[]>(
  initialState: T | null = null,
) {
  return useMemo(() => makeFieldState(initialState), [initialState]);
}

export const Component = feature.Component;
