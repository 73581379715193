import React from 'react';

import { WidgetContainer } from 'components';
import { block } from 'utils/classname';

import './style.scss';

export * as Header from './Header';
export * as Content from './Content';

const b = block('item-selection');

type Props = {
  Header?: React.FC;
  Title?: React.FC;
  Content: React.FC;
  ControlsBar: React.FC;
};

function ItemSelection(props: Props) {
  return (
    <div className={b()}>
      <WidgetContainer.Component {...props} />
    </div>
  );
}

export const Component = React.memo(ItemSelection);
