import { I18n } from 'services';

import {
  ProjectTrackObject,
  formatProjectTrackObjectTitle,
} from './formatProjectTrackObjectTitle';
import {
  QuizTrackObject,
  formatQuizTrackObjectTitle,
} from './formatQuizTrackObjectTitle';
import {
  SectionTrackObject,
  formatSectionTrackObjectTitle,
} from './formatSectionTrackObjectTitle';
import {
  UnitTrackObject,
  formatUnitTrackObjectTitle,
} from './formatUnitTrackObjectTitle';

export function formatTrackObjectTitle(
  trackObject:
    | SectionTrackObject
    | ProjectTrackObject
    | UnitTrackObject
    | QuizTrackObject,
  t: ReturnType<typeof I18n.useGetMultilingTranslation>,
) {
  switch (trackObject.type) {
    case 'section':
    case 'subsection': {
      return formatSectionTrackObjectTitle(trackObject, t);
    }
    case 'project': {
      return formatProjectTrackObjectTitle(trackObject, t);
    }
    case 'unit': {
      return formatUnitTrackObjectTitle(trackObject, t);
    }
    case 'quiz': {
      return formatQuizTrackObjectTitle(trackObject, t);
    }
  }
}
