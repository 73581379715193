import { ProjectPageRouteTree } from 'pages/routeTree';

import { Kind } from './types';

export function useKind(
  projectPageRouteTree: ProjectPageRouteTree,
): Kind | null {
  const routeParams = projectPageRouteTree.tree.PROJECT.useRouteParams();

  if (routeParams === false) {
    return null;
  }

  const [leaf, param] = routeParams.rest;

  if (leaf === undefined) {
    return null;
  }

  if (leaf === 'form' && param !== undefined) {
    return 'form-view';
  }

  if (leaf === 'conclusions' && param === 'view') {
    return 'conclusion-view';
  }

  if (leaf === 'conclusions' && param === 'form') {
    return 'conclusion-form';
  }

  if (leaf === 'quiz' && param === 'form') {
    return 'quiz-form';
  }

  if (leaf === 'table' && param !== undefined) {
    return 'table-view';
  }

  return leaf as Kind;
}
