import { emptyReference } from 'services/I18n/constants';
import { makeFormElementState } from 'utils/FormState';
import { nonNull } from 'utils/validators';

import { QuestionForLayer } from './types';

export function makeSelectedQuestionState(
  initialValue: QuestionForLayer | null,
) {
  return makeFormElementState(initialValue, [nonNull(emptyReference)]);
}
