import React from 'react';

import { InvitedUserData } from 'features/project/Constructor/FormWithSteps/steps/Access/types';
import { PendingActionSubList } from 'features/project/Constructor/subfeatures';
import { PrimaryStateUnit } from 'utils/State';

import * as actions from './actions';

type Props = {
  pendingAction: InvitedUserData;
  pendingActionsUnit: PrimaryStateUnit<InvitedUserData[]>;
};

function Controls({ pendingAction, pendingActionsUnit }: Props) {
  return (
    <PendingActionSubList.Entry.Control.Container.Component>
      <actions.Cancel.Component
        pendingAction={pendingAction}
        pendingActionsUnit={pendingActionsUnit}
      />
    </PendingActionSubList.Entry.Control.Container.Component>
  );
}

export const Component = React.memo(Controls) as typeof Controls;
