import React from 'react';

import { block } from 'utils/classname';

import './style.scss';

const b = block('modal-footer-default-layout');

type Props = React.PropsWithChildren<{}>;

function Default({ children }: Props) {
  return <div className={b()}>{children}</div>;
}

export const Component = React.memo(Default);
