import { useCallback } from 'react';

import * as API from 'services/API';
import * as M from 'types/serverModels';

import { userProfileCallStateUnit } from './units';

export const userProfileRefetchCallStateUnit = API.makeCallStateUnit<M.User>();

userProfileRefetchCallStateUnit.subscribe({
  name: 'user-profile-call-state-unit-updater',
  callback: state => {
    if (state.kind === 'successful' || state.kind === 'error') {
      userProfileCallStateUnit.setState({ ...state });
    }
  },
});

type Options = {};

export function useRefetchUser({}: Options) {
  const call = API.services.userProfile.useCall(
    userProfileRefetchCallStateUnit,
  );

  return useCallback(async () => {
    return call({});
  }, [call]);
}
