import { default as dateSrc, ReactComponent as DateInline } from './date.svg';
import { default as fileSrc, ReactComponent as FileInline } from './file.svg';
import {
  default as imageSrc,
  ReactComponent as ImageInline,
} from './image.svg';
import { default as mapSrc, ReactComponent as MapInline } from './map.svg';
import {
  default as matchSrc,
  ReactComponent as MatchInline,
} from './match.svg';
import {
  default as numberSrc,
  ReactComponent as NumberInline,
} from './number.svg';
import {
  default as sensorDataSrc,
  ReactComponent as SensorDataInline,
} from './sensorData.svg';
import {
  default as soundSrc,
  ReactComponent as SoundInline,
} from './sound.svg';
import { default as textSrc, ReactComponent as TextInline } from './text.svg';
import {
  default as variantSelectionSrc,
  ReactComponent as VariantSelectionInline,
} from './variantSelection.svg';
import {
  default as videoSrc,
  ReactComponent as VideoInline,
} from './video.svg';

type QuestionIcon = {
  src: string;
  InlineComponent: typeof DateInline;
};

export const date: QuestionIcon = {
  src: dateSrc,
  InlineComponent: DateInline,
};

export const image: QuestionIcon = {
  src: imageSrc,
  InlineComponent: ImageInline,
};

export const map: QuestionIcon = {
  src: mapSrc,
  InlineComponent: MapInline,
};

export const match: QuestionIcon = {
  src: matchSrc,
  InlineComponent: MatchInline,
};

export const number: QuestionIcon = {
  src: numberSrc,
  InlineComponent: NumberInline,
};

export const sensorData: QuestionIcon = {
  src: sensorDataSrc,
  InlineComponent: SensorDataInline,
};

export const sound: QuestionIcon = {
  src: soundSrc,
  InlineComponent: SoundInline,
};

export const text: QuestionIcon = {
  src: textSrc,
  InlineComponent: TextInline,
};
export const variantSelection: QuestionIcon = {
  src: variantSelectionSrc,
  InlineComponent: VariantSelectionInline,
};

export const video: QuestionIcon = {
  src: videoSrc,
  InlineComponent: VideoInline,
};

export const file: QuestionIcon = {
  src: fileSrc,
  InlineComponent: FileInline,
};
