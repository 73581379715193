import { quizLanguageUnit } from 'features/quiz/Constructor/units';
import { I18n } from 'services';
import * as M from 'types/serverModels';
import {
  FormEntityState,
  makeFormElementState,
  makeFormSectionState,
} from 'utils/FormState';
import {
  MappedState,
  makePrimaryUnit,
  makeDerivedUnit,
  makeMappingUnitFromUnit,
} from 'utils/State';
import { makeSingleUnitValidator, nonEmptyString } from 'utils/validators';

import i18nData from '../../../../i18n.json';
import { isRequired } from '../../../../i18nSharedReferences';
import {
  QuestionConstructor,
  SharedInstanceStateConstructor,
} from '../../types';
import * as FormExtension from './FormExtension';
import * as PreviewModeForm from './PreviewModeForm';
import { makeServerQuestion } from './makeServerQuestion';
import { ServerQuestion, StateInstance, MatchingsState } from './types';
import { makeMatchingLeftColumnElement } from './utils';

export type { StateInstance };

export const constructor: QuestionConstructor<
  'match',
  StateInstance,
  typeof makeInstance
> = {
  key: 'match',
  icon: 'match',
  makeInstance,
  FormExtension: FormExtension.Component,
  PreviewModeForm: PreviewModeForm.Component,
};

const atLeastTwoElementsRequiredLeftColumnMessage = I18n.makeEntryReference(
  i18nData,
  data =>
    data.questions.list.match.leftColumn.errors.atLeastTwoElementsRequired,
);
const atLeastTwoElementsRequiredRightColumnMessage = I18n.makeEntryReference(
  i18nData,
  data =>
    data.questions.list.match.rightColumn.errors.atLeastTwoElementsRequired,
);
const atLeastOneMatchingRequiredMatchingsMessage = I18n.makeEntryReference(
  i18nData,
  data => data.questions.list.match.matchings.errors.atLeastOneMatchingRequired,
);

type InitialState = Pick<ServerQuestion, 'columns' | 'matchings' | 'score'>;

export function makeInstance(
  makeSharedInstanceState: SharedInstanceStateConstructor,
  initialState: InitialState = {
    columns: {
      left: { mode: 'text', elements: [] },
      right: {
        elements: [],
      },
    },
    matchings: {},
  },
): StateInstance {
  const leftColumn: StateInstance['leftColumn'] = {
    isImage: makeFormElementState(initialState.columns.left.mode === 'image'),
    textElementsUnit: makePrimaryUnit(
      initialState.columns.left.mode !== 'image'
        ? initialState.columns.left.elements.map(x => ({
            ...x,
            text: I18n.makeMultilangFormState(
              makeFormElementState(
                I18n.getMultilingTranslation(
                  quizLanguageUnit.getState(),
                  x.text,
                ),
                [nonEmptyString(isRequired)],
              ),
              x.text,
            ),
          }))
        : [],
    ),
    imageElementsUnit: makePrimaryUnit(
      initialState.columns.left.mode === 'image'
        ? initialState.columns.left.elements.map(x => ({
            ...x,
            image: makeFormElementState<M.ImageInfo | null>({
              uuid: '',
              original: x.image,
              thumb: [{ code: 'icon', url: x.image }],
            }),
          }))
        : [],
    ),
  };

  const rightColumn: StateInstance['rightColumn'] = {
    elementsUnit: makePrimaryUnit(
      initialState.columns.right.elements.map(x => ({
        ...x,
        text: I18n.makeMultilangFormState(
          makeFormElementState(
            I18n.getMultilingTranslation(quizLanguageUnit.getState(), x.text),
            [nonEmptyString(isRequired)],
          ),
          x.text,
        ),
      })),
    ),
  };

  const matchingsUnit: StateInstance['matchingsUnit'] = makePrimaryUnit(
    (leftColumn.isImage.units.value.getState()
      ? leftColumn.imageElementsUnit.getState()
      : leftColumn.textElementsUnit.getState()
    ).reduce<MatchingsState>((accX, x) => {
      return {
        ...accX,
        [x.uuid]: makeMatchingLeftColumnElement({
          rightColumn,
          initState: rightElement =>
            initialState.matchings[x.uuid]?.includes(rightElement.uuid) ??
            false,
        }),
      };
    }, {}),
  );

  const atLeastTwoLeftColumnElementsValidator = makeSingleUnitValidator<
    MappedState<
      | StateInstance['leftColumn']['imageElementsUnit']
      | StateInstance['leftColumn']['textElementsUnit']
    >
  >(x =>
    x.length < 2
      ? {
          kind: 'invalid',
          messageReference: atLeastTwoElementsRequiredLeftColumnMessage,
        }
      : { kind: 'valid' },
  );
  const atLeastTwoRightColumnElementsValidator = makeSingleUnitValidator<
    MappedState<StateInstance['rightColumn']['elementsUnit']>
  >(x =>
    x.length < 2
      ? {
          kind: 'invalid',
          messageReference: atLeastTwoElementsRequiredRightColumnMessage,
        }
      : { kind: 'valid' },
  );
  const atLeastOneMatchingRequiredMatchingsValidator = makeSingleUnitValidator<
    MappedState<StateInstance['matchingsSectionState']['units']['value']>
  >(x =>
    Object.values(x).length > 0 &&
    Object.values(x).some(y => !Object.values(y).some(z => !!z))
      ? {
          kind: 'invalid',
          messageReference: atLeastOneMatchingRequiredMatchingsMessage,
        }
      : { kind: 'valid' },
  );

  const leftColumnSectionState = makeFormSectionState(
    makeDerivedUnit(
      leftColumn.isImage.units.value,
      leftColumn.textElementsUnit,
      leftColumn.imageElementsUnit,
    ).getUnit((isImage, textElements, imageElements) =>
      isImage ? imageElements : textElements,
    ),
    [atLeastTwoLeftColumnElementsValidator],
  );
  const rightColumnSectionState = makeFormSectionState(
    rightColumn.elementsUnit,
    [atLeastTwoRightColumnElementsValidator],
  );
  const matchingsSectionState = makeFormSectionState(
    makeMappingUnitFromUnit(
      makeDerivedUnit(leftColumn.isImage.units.value, matchingsUnit).getUnit(
        (isImageLeftColumn, matchings) => {
          const leftColumnTextElements = leftColumn.textElementsUnit.getState();
          const leftColumnImageElements =
            leftColumn.imageElementsUnit.getState();

          return (
            isImageLeftColumn ? leftColumnImageElements : leftColumnTextElements
          ).reduce<
            Record<
              keyof MatchingsState,
              MatchingsState[string]['formSectionState']['units']['value']
            >
          >(
            (acc, x) => ({
              ...acc,
              [x.uuid]: matchings[x.uuid].formSectionState.units.value,
            }),
            {},
          );
        },
      ),
    ),
    [atLeastOneMatchingRequiredMatchingsValidator],
  );

  const validatablesUnit = makeDerivedUnit(
    leftColumn.isImage.units.value,
    leftColumn.textElementsUnit,
    leftColumn.imageElementsUnit,
    rightColumn.elementsUnit,
    matchingsUnit,
  ).getUnit(
    (
      isImageLeftColumn,
      leftColumnTextElements,
      leftColumnImageElements,
      rightColumnElements,
      matchings,
    ): FormEntityState[] => {
      return [
        ...(isImageLeftColumn
          ? leftColumnImageElements.map(x => x.image)
          : leftColumnTextElements.map(x => x.text.formElementState)),
        ...rightColumnElements.map(x => x.text.formElementState),
        ...(isImageLeftColumn
          ? leftColumnImageElements.reduce<FormEntityState[]>(
              (acc, x) =>
                matchings[x.uuid]
                  ? [...acc, matchings[x.uuid].formSectionState]
                  : acc,
              [],
            )
          : leftColumnTextElements.reduce<FormEntityState[]>(
              (acc, x) =>
                matchings[x.uuid]
                  ? [...acc, matchings[x.uuid].formSectionState]
                  : acc,
              [],
            )),
        leftColumnSectionState,
        rightColumnSectionState,
        matchingsSectionState,
      ];
    },
  );

  const score = makeFormElementState(
    initialState?.score !== undefined ? Number(initialState.score) : null,
  );

  return {
    kind: 'match',
    leftColumn,
    rightColumn,
    matchingsUnit,
    score,
    leftColumnSectionState,
    rightColumnSectionState,
    matchingsSectionState,
    makeServerQuestion: () =>
      makeServerQuestion({
        leftColumn,
        rightColumn,
        matchingsUnit,
        score,
      }),
    ...makeSharedInstanceState(validatablesUnit),
  };
}
