import { SetRequired } from 'type-fest';

import * as M from 'types/serverModels';

import { makeService } from '../utils';

type PathInputData = { uuid: Exclude<M.ProjectBook['uuid'], undefined> };

type QueryInputData = {};

type InputData = PathInputData & QueryInputData;

type APIOutputData = {
  project_book: SetRequired<
    M.ProjectBook,
    'uuid' | 'lang' | 'cdate' | 'supervisors' | 'status'
  >[];
};

type OutputData = {
  projectBook: SetRequired<
    M.ProjectBook,
    'uuid' | 'lang' | 'cdate' | 'supervisors' | 'status'
  >[];
};

function extractResponse(data: APIOutputData): OutputData {
  return {
    projectBook: { ...data.project_book },
  };
}

export const del = makeService<InputData, APIOutputData, OutputData>(
  (inputData, convertToSearchParams) => {
    const { uuid, ...rest } = inputData;

    return `project_book/${uuid}/?${convertToSearchParams({
      ...rest,
    })}`;
  },
  'api-v2-delete',
  false,
  extractResponse,
);
