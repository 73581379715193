import { QuizQuestionKind } from 'types';

import { CachedQuestionInstance, QuestionKey } from './types';

export function setInstanceQuestionKind(
  cachedInstance: CachedQuestionInstance,
  questionKind: QuizQuestionKind,
) {
  const { doEffect, questionKey } = ((): {
    questionKey?: QuestionKey;
    doEffect?: () => void;
  } => {
    switch (questionKind) {
      case 'image':
        return { questionKey: 'image' };
      case 'map':
        return { questionKey: 'map' };
      case 'text-to-text-match':
        return { questionKey: 'match' };
      case 'image-to-text-match':
        return {
          questionKey: 'match',
          doEffect: () => {
            cachedInstance.instancesCache.match.leftColumn.isImage.units.value.setState(
              true,
            );
          },
        };
      case 'multiline-string':
        return {
          questionKey: 'text',
          doEffect: () => {
            cachedInstance.instancesCache.text.isMultiline.units.value.setState(
              true,
            );
          },
        };
      case 'multiple-variants-selection':
        return {
          questionKey: 'variantSelection',
          doEffect: () => {
            cachedInstance.instancesCache.variantSelection.hasMultipleChoice.units.value.setState(
              true,
            );
          },
        };
      case 'number':
        return { questionKey: 'number' };
      case 'single-line-string':
        return { questionKey: 'text' };
      case 'single-variant-selection':
        return { questionKey: 'variantSelection' };
    }
  })();

  if (questionKey) cachedInstance.activeQuestionKeyUnit.setState(questionKey);

  if (doEffect) doEffect();
}
