import * as M from 'types/serverModels';

import { makeService } from '../utils';

type InputData = {
  uuid: M.Quiz['uuid'];
};

type Output = {
  answer: M.QuizAnswer;
  quiz: M.Quiz;
  user: Pick<M.User, 'login' | 'name' | 'avatar' | 'role'>;
};

export const get = makeService<InputData, Output>(
  ({ uuid }) => `answer/${uuid}`,
  'api-v2-get',
  false,
);
