import { Preloader } from 'components';
import { makeFeature } from 'services/Feature';

import i18nData from './i18n.json';
import * as subfeatures from './subfeatures';

export const feature = makeFeature({
  name: 'AuthorizedUserOnlyModal',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "authorized-user-only-modal" */
      './Component'
    ),
  Preloader: Preloader.variants.Empty.Component,
  i18nData,
  i18nSharedReferences: null,
  subfeatures: Object.values(subfeatures).map(x => x.feature),
});

export type { Props } from './Component';

export { makeIsOpenUnit, useIsOpenUnit } from 'components/Modal';

export const Component = feature.Component;
