import { I18n } from 'services';
import * as M from 'types/serverModels';

export type ProjectTrackObject = Pick<
  M.CourseStreamServiceProjectTrackObjectOutput,
  'type' | 'object' | 'title'
>;

export function getProjectTrackObjectTitleMultilingStrings(
  trackObject: ProjectTrackObject,
  t: ReturnType<typeof I18n.useGetMultilingTranslation>,
) {
  return [trackObject.title, trackObject.object.title].filter(
    (x): x is M.MultilingString => !!(x && t(x)),
  );
}

export function formatProjectTrackObjectTitle(
  trackObject: ProjectTrackObject,
  t: ReturnType<typeof I18n.useGetMultilingTranslation>,
) {
  return getProjectTrackObjectTitleMultilingStrings(trackObject, t).reduce(
    (acc, x) => (acc ? acc : t(x)),
    '',
  );
}
