import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  user: string;
  sensors: string;
};

export const loggerGetStreams = makeService<InputData, M.Experiment[]>(
  'logger_get_streams',
  'get',
);
