import React, { useEffect } from 'react';

import { Switch } from 'components';
import { PayWall } from 'features/global';
import { ProjectWriteContext } from 'features/project/Constructor/FormWithSteps/ProjectWriteContext';
import { EditError } from 'features/project/Constructor/FormWithSteps/modals';
import i18nData from 'features/project/Constructor/i18n.json';
import { ConstructorStepSection } from 'features/project/Constructor/subfeatures';
import { I18n } from 'services';
import { block } from 'utils/classname';
import { useRequiredContext } from 'utils/react/RequiredContext';

import { useIsEditAccess } from '../../../shared';
import {
  anonymousQuestionnairesSwitchState,
  privateAccessSwitchState,
} from '../../units';
import { payWallPrivateProjectTextReference } from './../../../../../i18nSharedReferences';

type Props = {};

function HeaderRightPart() {
  const { isEditAccess, errorCode } = useIsEditAccess();

  const defaultPrivateProjectPayWallText = I18n.useReference(
    payWallPrivateProjectTextReference,
  );

  const onChangePredicate = () => {
    if (!isEditAccess) {
      EditError.errorCodeUnit.setState(errorCode);
      return;
    }
    if (anonymousQuestionnairesSwitchState.getValue()) {
      EditError.errorCodeUnit.setState('privateAccessCannotBeEnabled');
      return;
    }

    PayWall.addCheck(
      PayWall.makeCheck({
        featureCode: 'private_project',
        blockType: 'action',
        blockedText: defaultPrivateProjectPayWallText,
        onHaveFeature: () => {
          privateAccessSwitchState.units.value.setState(prev => !prev);
        },
      }),
    );
  };

  return (
    <Switch.Component
      formElementState={privateAccessSwitchState}
      onChangePredicate={onChangePredicate}
    />
  );
}

const b = block('private-access-section');

function PrivateAccess({}: Props) {
  const text = I18n.useText(i18nData).steps.access.sections.privateAccess;

  const { saveProject } = useRequiredContext(ProjectWriteContext);

  useEffect(() => {
    return privateAccessSwitchState.units.value.subscribe({
      name: 'saving-project',
      callback: (newState, prevState) => {
        if (newState !== prevState) {
          saveProject();
        }
      },
    });
  }, [saveProject]);

  return (
    <ConstructorStepSection.Component
      className={b()}
      title={text.title}
      subtitle={text.tooltip}
      HeaderRightPart={HeaderRightPart}
    />
  );
}

export const Component = React.memo(PrivateAccess);
