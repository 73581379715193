import { Modal } from 'components';
import { API } from 'services';
import * as M from 'types/serverModels';
import { makeFormElementState } from 'utils/FormState';
import { makePrimaryUnit } from 'utils/State';
import { nonEmptyString, nonNull } from 'utils/validators';

import { requiredFieldReference } from './i18nSharedReferences';
import { BasketState } from './types';

export const featuresCallStateUnit = makePrimaryUnit<
  API.CallState<Record<M.StoreUserPurchaseListFeature['code'], number>>
>({ kind: 'initial' });

export const storeBasketReadCallStateUnit =
  API.services.storeBasketRead.makeCallStateUnit();
export const storeBasketAddCallStateUnit =
  API.services.storeBasketAdd.makeCallStateUnit();
export const storeBasketSetCallStateUnit =
  API.services.storeBasketSet.makeCallStateUnit();

export const isOpenUnit = Modal.makeIsOpenUnit();

export const basketUnit = makePrimaryUnit<BasketState | null>(null);

export const form = {
  name: makeFormElementState('', [nonEmptyString(requiredFieldReference)]),

  delivery: {
    recipient: makeFormElementState('', [
      nonEmptyString(requiredFieldReference),
    ]),
    address: makeFormElementState('', [nonEmptyString(requiredFieldReference)]),
    zipCode: makeFormElementState('', [nonEmptyString(requiredFieldReference)]),
    country: makeFormElementState<M.CountryReferenceBook | null>(null, [
      nonNull(requiredFieldReference),
    ]),
    phone: makeFormElementState('', [nonEmptyString(requiredFieldReference)]),
  },
};
