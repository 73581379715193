import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {};

export type FeatureRef = {
  [key: string]: M.Feature;
};

export const storeFeatureRef = makeService<InputData, FeatureRef>(
  'store_feature_ref',
  'post',
);
