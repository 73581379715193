import { camelCase } from 'camel-case';

import { pageRouteTree } from '../../../utils';
import { Tab } from '../types';

export function useActiveTab(): Tab | null {
  const routeParams = pageRouteTree.get().useRouteParams();

  if (!routeParams) {
    return null;
  }

  const [leaf] = routeParams.rest;

  if (typeof leaf !== 'string') {
    return null;
  }

  return camelCase(leaf) as Tab;
}

export function getActiveTab(): Tab | null {
  const routeParams = pageRouteTree.get().getRouteParams();

  if (!routeParams) {
    return null;
  }

  const [leaf] = routeParams.rest;

  if (typeof leaf !== 'string') {
    return null;
  }

  return camelCase(leaf) as Tab;
}
