import React from 'react';

import { block } from 'utils/classname';

import { LabelProps } from '../types';
import './style.scss';

const b = block('major-tab-text-label');

type Props = {
  useLabel(): string;
  renderCounter?(): string;
  useTopRightMarker?(): React.ReactNode;
  useIsActive(): boolean;
};

function TabTextLabel({
  useLabel,
  renderCounter,
  useTopRightMarker,
  useIsActive,
}: Props) {
  const isActive = useIsActive();

  const topRightIcon = useTopRightMarker?.();

  return (
    <div className={b({ active: isActive })}>
      {useLabel()}
      {renderCounter && (
        <span className={b('counter')}>
          {'\u00A0'}
          {!isActive && renderCounter()}
        </span>
      )}
      {topRightIcon && (
        <div className={b('top-right-marker')}>{topRightIcon}</div>
      )}
    </div>
  );
}

export const Component = React.memo(TabTextLabel) as typeof TabTextLabel;

export const makeComponent =
  (
    useLabel: () => string,
    renderCounter?: () => string,
    useTopRightMarker?: Props['useTopRightMarker'],
  ): React.FC<LabelProps> =>
  props => (
    <Component
      useIsActive={props.useIsActive}
      useLabel={useLabel}
      renderCounter={renderCounter}
      useTopRightMarker={useTopRightMarker}
    />
  );
