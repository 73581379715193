import React from 'react';

import { block } from 'utils/classname';

import './style.scss';

const b = block('quiz-match-question-form-extension-section');

type Props = {
  Content: React.VFC;
  HeaderExtension?: React.VFC;
  useTitle(): string;
};

function Section({ Content, HeaderExtension, useTitle }: Props) {
  const title = useTitle();

  return (
    <div className={b()}>
      <div className={b('header')}>
        <h6 className={b('header-title')}>{title}</h6>
        {HeaderExtension && <HeaderExtension />}
      </div>
      <Content />
    </div>
  );
}

export const Component = React.memo(Section);
