import React from 'react';

import { block } from 'utils/classname';

import './style.scss';

const b = block('item-selection-header');

type Props = {
  useTitle(): string;
  useSubtitle(): string;
};

function Header({ useTitle, useSubtitle }: Props) {
  const title = useTitle();
  const subtitle = useSubtitle();

  return (
    <div className={b()}>
      <h4 className={b('title')}>{title}</h4>
      <p className={b('subtitle')}>{subtitle}</p>
    </div>
  );
}

const Component = React.memo(Header);

export const makeComponent = (props: Props) => () => <Component {...props} />;
