import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  term: string[];
};

export const classificationPaths = makeService<
  InputData,
  M.ClassificationPaths[]
>('classification_paths', 'get');
