import * as TS from 'types';
import * as M from 'types/serverModels';

export function getAxisDataText(
  axisData: TS.AxisData,
  text: {
    latitude: string;
    longtitude: string;
    quantity: string;
    questionnaireSubmitDate: string;
    questionDictionary: Record<string, string>;
  },
) {
  switch (axisData.kind) {
    case 'latitude':
      return text.latitude;
    case 'longtitude':
      return text.longtitude;
    case 'quantity':
      return text.quantity;
    case 'questionnaire-submit-date':
      return text.questionnaireSubmitDate;
    case 'question':
      return text.questionDictionary[axisData.questionID];
  }
}

export function equal(x: TS.AxisData, y: TS.AxisData) {
  switch (x.kind) {
    case 'question':
      return x.kind === y.kind && x.questionID === y.questionID;
    default:
      return x.kind === y.kind;
  }
}

export function makeXAxisDataFromServerModel(
  axisData: M.XAxisData,
): TS.XAxisData {
  switch (axisData) {
    case 'Meta.cdate':
      return { kind: 'questionnaire-submit-date' };
    case 'Meta.location.0':
      return { kind: 'longtitude' };
    case 'Meta.location.1':
      return { kind: 'latitude' };
    default:
      const [, questionID] = axisData.split('.');
      return { kind: 'question', questionID };
  }
}

export function makeYAxisDataFromServerModel(
  axisData: M.YAxisData,
): TS.YAxisData {
  switch (axisData) {
    case 'Agg.Count':
      return { kind: 'quantity' };
    default:
      return makeXAxisDataFromServerModel(axisData);
  }
}

export function makeServerXAxisDataFromModel(
  axisData: TS.XAxisData,
): M.XAxisData {
  switch (axisData.kind) {
    case 'latitude':
      return 'Meta.location.1';
    case 'longtitude':
      return 'Meta.location.0';
    case 'questionnaire-submit-date':
      return 'Meta.cdate';
    case 'question':
      return `Fact.${axisData.questionID}`;
  }
}

export function makeServerYAxisDataFromModel(
  axisData: TS.YAxisData,
): M.YAxisData {
  switch (axisData.kind) {
    case 'quantity':
      return 'Agg.Count';
    default:
      return makeServerXAxisDataFromModel(axisData);
  }
}

export function makeServerCuttingQuestionAxisDataFromModel(
  questionID: string | null,
): M.CuttingQuestionAxisData {
  return questionID === null ? 'nosel' : `Fact.${questionID}`;
}

export function makeCuttingQuestionID(
  serverModel: M.CuttingQuestionAxisData,
): string | null {
  return serverModel === 'nosel' ? null : serverModel.split('.')[1];
}
