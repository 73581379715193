import * as M from 'types/serverModels';

import { makeService } from '../utils';

type InputData = {
  uuid: M.UUID;
  body: string;
};

type APIOutputData = {
  comment: Omit<M.CommentData, 'author'> & { author: string };
  author_profile: Pick<M.User, 'login' | 'name' | 'avatar' | 'role'>;
};

export const put = makeService<InputData, APIOutputData, M.CommentData>(
  ({ uuid }) => `comment/${uuid}/body`,
  'api-v2-put',
  false,
  ({ comment, author_profile }) => ({ ...comment, author: author_profile }),
  {
    contentType: 'application/x-www-form-urlencoded',
  },
);
