import * as R from 'ramda';

import { isFormElementState } from 'utils/FormState';
import { MapFormElementStateToValueUnit } from 'utils/types';

export function mapFormElementStateToValueUnit<
  Y,
  S = MapFormElementStateToValueUnit<Y>,
>(value: Y, path: Array<number | string> = []): S {
  if (isFormElementState(value)) {
    return value.units.value as S;
  }

  if (typeof value === 'object') {
    return R.mapObjIndexed(
      (x, key) => mapFormElementStateToValueUnit(x, [...path, key]),
      value as object,
    ) as S;
  }

  return value as unknown as S;
}
