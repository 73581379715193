import React from 'react';

import { block, classnames } from 'utils/classname';

import './style.scss';

const b = block('main-menu');

type Props = {
  RootEntry: React.FC;
  className?: string;
};

function Menu({ RootEntry, className }: Props) {
  return (
    <div className={classnames(b(), className)}>
      <RootEntry />
    </div>
  );
}

export const Component = React.memo(Menu) as typeof Menu;
