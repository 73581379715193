import { makeChartWidget } from './makeChartWidget';

export { makeServerWidget } from './makeChartWidget';

export const Line = makeChartWidget('line', 'line');
export const Column = makeChartWidget('column', 'column');
export const ColumnStack = makeChartWidget('columnStack', 'columnStack');
export const Area = makeChartWidget('area', 'area');
export const Pie = makeChartWidget('pie', 'pie');
export const Scatter = makeChartWidget('scatter', 'scatter');

export type { Instance } from './makeChartWidget/types';
