import { useEffect } from 'react';
import React from 'react';

import { makeFormElementState } from 'utils/FormState';
import { makePrimaryUnit, PrimaryStateUnit } from 'utils/State';

import { ReferenceBookCallState, ReferenceBookState } from '../types';

type Props = {
  kind: 'age' | 'theme';
  initialValues?: string[];
  stateUnit: PrimaryStateUnit<ReferenceBookState>;
  useCallState(): ReferenceBookCallState;
};

function Fetcher({ initialValues, stateUnit, useCallState }: Props) {
  const callState = useCallState();

  useEffect(() => {
    if (callState.kind === 'successful') {
      const state = stateUnit.getState();

      const isAlreadyExist = Object.values(callState.data).every(
        x => state[x.uuid],
      );
      if (isAlreadyExist) {
        Object.entries(state).forEach(([key, x]) =>
          x.value.units.value.setState(!!initialValues?.includes(key)),
        );
        return;
      }
      stateUnit.setState(prevState => ({
        ...prevState,
        ...Object.values(callState.data).reduce((acc, x) => {
          if (prevState[x.uuid]) {
            prevState[x.uuid].value.units.value.setState(
              !!initialValues?.includes(x.uuid),
            );
            return acc;
          }

          return {
            ...acc,
            [x.uuid]: {
              label: makePrimaryUnit(x.val),
              value: makeFormElementState(!!initialValues?.includes(x.uuid)),
            },
          };
        }, {}),
      }));
    }
  }, [callState, initialValues, stateUnit]);

  return null;
}

export const Component = React.memo(Fetcher);
