import { makeFeature } from 'services/Feature';

import i18nData from './i18n.json';
import * as i18nSharedReferences from './i18nSharedReferences';
import * as subfeatures from './subfeatures';

export const feature = makeFeature({
  name: 'CourseCard',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "course-card" */
      './Component'
    ),
  i18nSharedReferences: Object.values(
    (() => {
      const { courseStatusReference } = i18nSharedReferences;

      return [...Object.values(courseStatusReference)];
    })(),
  ),
  i18nData,
  subfeatures: Object.values(subfeatures).map(x => x.feature),
});

export type { Props } from './Component';

export const Component = feature.Component;
