import * as M from 'types/serverModels';

export function getCurrentStage(
  stages: M.ProjectBook['stages'],
  currentStageNumber: M.ProjectBook['current_stage_number'],
) {
  if (typeof currentStageNumber !== 'number') {
    return null;
  }

  if (!Array.isArray(stages)) {
    return null;
  }

  return stages[currentStageNumber - 1] || null;
}
