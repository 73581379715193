import { useState } from 'react';

export function useForceUpdate() {
  const [, setValue] = useState(0);
  return () =>
    setValue(value => {
      if (value === Infinity) {
        return 0;
      }

      return value + 1;
    });
}
