import i18nData from 'features/project/Constructor/i18n.json';
import { Filter } from 'features/project/Constructor/subfeatures';
import { I18n } from 'services';
import { vennChartSetNames } from 'shared/constants';
import * as M from 'types/serverModels';
import { makeFormSectionState, FormEntityState } from 'utils/FormState';
import {
  makeDerivedUnit,
  makeMappingUnitFromUnit,
  makePrimaryUnit,
  PrimaryStateUnit,
} from 'utils/State';
import { VennChart } from 'utils/business';
import { makeSingleUnitValidator } from 'utils/validators';

import { ConstructorWidgetConfigForFindingsWidget } from '../../types';
import { makeSharedInstancePart } from '../makeSharedInstancePart';
import { SharedConstructorArgs } from '../shared/SharedConstructorArgs';
import * as DataSettings from './DataSettings';
import * as View from './View';
import { VennInstance } from './types';

const getFormEntites = (set: VennChart.Set): FormEntityState[] => {
  switch (set.kind) {
    case 'for-number-question':
      return [set.range.from, set.range.to];
    case 'for-single-variant':
      return [set.selectedVariantID];
    case 'for-multiple-variants':
      return [set.formSectionStateUnit];
    case 'without-selected-question':
      return [];
  }
};

type ServerFilterConstructorArgs = {
  filter: Filter.State;
  setsUnit: PrimaryStateUnit<VennChart.Set[]>;
};

export function makeServerWidget(
  args: ServerFilterConstructorArgs,
): Partial<M.VennChartWidget> {
  const { filter, setsUnit } = args;

  const mappedSets = makeMappingUnitFromUnit(
    makeDerivedUnit(setsUnit).getUnit(sets =>
      sets.map(VennChart.makeSetForRequest),
    ),
  );

  return {
    type: 'venn',
    descriptor: {
      filter: Filter.makeServerFilterFromState(filter),
      ...VennChart.makeServerDescriptorSets(mappedSets.getState()),
    },
  };
}

const atLeastOneSetRequiredReference = I18n.makeEntryReference(
  i18nData,
  data => data.steps.findingsSettings.widgets.venn.errors.atLeastOneSetRequired,
);

const atLeastOneSetRequiredValidator = makeSingleUnitValidator<VennChart.Set[]>(
  sets => {
    return sets.filter(VennChart.isSetWithQuestion).length === 0
      ? { kind: 'invalid', messageReference: atLeastOneSetRequiredReference }
      : { kind: 'valid' };
  },
);

function makeInstance({
  initialSets = vennChartSetNames.map(
    VennChart.setConstructors.withoutQuestion,
  ),
  ...sharedArgs
}: {
  initialSets?: VennChart.Set[];
} & SharedConstructorArgs): VennInstance {
  const setsUnit = makePrimaryUnit(initialSets);
  const setsFormSectionStateUnit = makeFormSectionState(setsUnit, [
    atLeastOneSetRequiredValidator,
  ]);

  const formEntitiesUnit = makeDerivedUnit(setsUnit).getUnit<FormEntityState[]>(
    sets => [...sets.flatMap(getFormEntites), setsFormSectionStateUnit],
  );

  return {
    ...makeSharedInstancePart({
      ...sharedArgs,
      formEntitiesUnit,
    }),
    kind: 'venn',
    id: sharedArgs.id,
    sets: setsUnit,
    setsFormSectionStateUnit,
    makeServerWidget() {
      return makeServerWidget({
        filter: this.filter,
        setsUnit,
      });
    },
  };
}

export const constructorWidgetConfig: ConstructorWidgetConfigForFindingsWidget<
  'venn',
  VennInstance,
  typeof makeInstance
> = {
  key: 'venn',
  icon: 'vennChart',
  makeInstance,
  View: View.Component,
  DataSettings: DataSettings.Component,
  viewHeight: 500,
};

export type { VennInstance };
