import React from 'react';

import { routeTree } from 'pages/routeTree';
import { Page } from 'services';

import * as features from './features';

type Props = features.Results.Props;

function Results(props: Props) {
  return <features.Results.Component {...props} />;
}

export const path = routeTree.LANG.project.list.PROJECT.findings.getPath();

export const Component = Page.makePage({
  path,
  routeProps: { exact: false },
  scrollTo: 'none',
  Component: React.memo(Results) as typeof Results,
  features: Object.values(features).map(x => x.feature),
}) as typeof Results;
