import React from 'react';

import { block } from 'utils/classname';

import './style.scss';

const b = block('attention-message');

type Props = React.PropsWithChildren<{
  className?: string;
}>;

function AttentionMessage({ children, className }: Props) {
  return <p className={b({}, [className])}>{children}</p>;
}

export const Component = React.memo(
  AttentionMessage,
) as typeof AttentionMessage;
