import * as R from 'ramda';
import React, { useCallback } from 'react';

import { VerticallyMovableElement, Link } from 'components';
import { I18n } from 'services';
import { FormSectionState, makeFormElementState } from 'utils/FormState';
import { PrimaryStateUnit } from 'utils/State';
import { makeUUID } from 'utils/UUID';
import { block } from 'utils/classname';
import { nonEmptyString } from 'utils/validators';

import i18nData from '../../../../../../../i18n.json';
import { isRequired } from '../../../../../../../i18nSharedReferences';
import { TextElementState } from '../../../types';
import { SectionError } from '../../components';
import * as Element from './Element';
import './style.scss';

const b = block('quiz-match-question-form-extension-text-elements');

type Props = {
  elementsUnit: PrimaryStateUnit<TextElementState[]>;
  sectionStateUnit: FormSectionState<any>;
  onAdd(element: TextElementState): void;
  onRemove(element: TextElementState): void;
};

const useVerticallyMovableElements =
  VerticallyMovableElement.makeUseVerticallyMovableELements<TextElementState>(
    x => x.uuid,
  );

const ElementsList = React.memo(
  ({ elementsUnit, onRemove }: Pick<Props, 'elementsUnit' | 'onRemove'>) => {
    const movableElements = useVerticallyMovableElements(
      elementsUnit.useState(),
    );

    const handleElementMoveDown = useCallback(
      (element: TextElementState) => {
        elementsUnit.setState(prev => {
          const index = prev.findIndex(x => x === element);

          if (index === -1 || index === prev.length - 1) {
            return prev;
          }

          return R.move(index, index + 1, prev);
        });
      },
      [elementsUnit],
    );

    const handleElementMoveUp = useCallback(
      (element: TextElementState) => {
        elementsUnit.setState(prev => {
          const index = prev.findIndex(x => x === element);

          if (index === -1 || index === 0) {
            return prev;
          }

          return R.move(index, index - 1, prev);
        });
      },
      [elementsUnit],
    );

    const handleElementRemove = useCallback(
      (element: TextElementState) => {
        elementsUnit.setState(prev => R.without([element], prev));

        onRemove(element);
      },
      [elementsUnit, onRemove],
    );

    return (
      <ul className={b('list')}>
        {movableElements.map(x => {
          return (
            <li className={b('list-item')} key={x.value.uuid}>
              <VerticallyMovableElement.Component element={x}>
                <Element.Component
                  element={x.value}
                  onMoveDown={handleElementMoveDown}
                  onMoveUp={handleElementMoveUp}
                  onRemove={handleElementRemove}
                />
              </VerticallyMovableElement.Component>
            </li>
          );
        })}
      </ul>
    );
  },
);

function TextElements({
  elementsUnit,
  sectionStateUnit,
  onAdd,
  onRemove,
}: Props) {
  const text = I18n.useText(i18nData).questions.list.match.elements.text;

  const handleAddElementButtonClick = useCallback(() => {
    const newElement = {
      uuid: makeUUID(),
      text: I18n.makeMultilangFormState(
        makeFormElementState('', [nonEmptyString(isRequired)]),
      ),
    };

    elementsUnit.setState(prev => [...prev, newElement]);

    onAdd(newElement);
  }, [elementsUnit, onAdd]);

  return (
    <div className={b()}>
      <ElementsList elementsUnit={elementsUnit} onRemove={onRemove} />
      <SectionError.Component sectionStateUnit={sectionStateUnit} />
      <div className={b('add-element-link')}>
        <Link.Component
          size="l"
          color="primary"
          onClick={handleAddElementButtonClick}
        >
          {`+ ${text.addElementButtonLabel}`}
        </Link.Component>
      </div>
    </div>
  );
}

export const Component = React.memo(TextElements);
