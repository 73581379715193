import React from 'react';

import { Checkbox } from 'components';
import { StepContext } from 'features/project/Constructor/FormWithSteps/StepContext';
import i18nData from 'features/project/Constructor/i18n.json';
import { I18n } from 'services';
import { block } from 'utils/classname';
import { useRequiredContext } from 'utils/react/RequiredContext';

import { HeaderExtensionProps } from '../../../types';
import { StateInstance } from '../types';
import './style.scss';

const b = block('image-question-header-extension');

const Label = Checkbox.makeLabel({
  size: 'text-14',
  useLabel: () =>
    I18n.useText(i18nData).steps.shared.questions.list.image
      .hasMultipleImagesCheckboxLabel,
});

function HeaderExtension({ instance }: HeaderExtensionProps<StateInstance>) {
  const { step } = useRequiredContext(StepContext);
  switch (step.key) {
    case 'conclusions':
      return null;
    default:
      return (
        <div className={b()}>
          <Checkbox.Component
            Label={Label}
            formElementState={instance.hasMultipleImages}
          />
        </div>
      );
  }
}

export const Component = React.memo(HeaderExtension) as typeof HeaderExtension;
