import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

import { initHistory } from './history';

type Props = {};

function HistoryInitializer({}: Props) {
  const history = useHistory();

  useEffect(() => {
    initHistory(history);
  }, [history]);

  return null;
}

export const Component = React.memo(HistoryInitializer);
