import { useLocation } from 'react-router';

import { SharedHash } from 'pages/routeTree';
import { Routing } from 'services';

let subjectURI: string | null = null;

export function setSubjectURI(value: string | null) {
  subjectURI = value;
}

export function resetSubjectURI() {
  subjectURI = null;
}

export function getSubjectURI() {
  return subjectURI;
}

export function useCurrentLocationOpenHref(hash: SharedHash) {
  const location = useLocation();

  return `${location.pathname}${location.search}${hash}`;
}

export function getCurrentLocationOpenHref(hash: SharedHash) {
  const location = Routing.getCurrentLocation();

  return `${location?.pathname}${location?.search}${hash}`;
}
