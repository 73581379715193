import React from 'react';

import { block } from 'utils/classname';
import { useRequiredContext } from 'utils/react/RequiredContext';

import * as Icon from '../Icon';
import { DependenciesContext } from './dependenciesContext';

export { DependenciesContext } from './dependenciesContext';

const b = block('select');

export type Props = React.PropsWithChildren<{
  backgroundColor?: 'transparent' | 'bg-0';
  color?: 'fg-1' | 'fg-3';
  className?: string;
}>;

function Container({
  children,
  backgroundColor = 'transparent',
  color = 'fg-1',
  className,
}: Props) {
  const { onClick } = useRequiredContext(DependenciesContext);

  return (
    <div
      className={b(
        'active-option-container',
        { empty: !children, 'background-color': backgroundColor, color },
        [className],
      )}
      onClick={onClick}
    >
      {children}
      <Icon.DefaultComponent />
    </div>
  );
}

export const DefaultComponent = React.memo(Container) as typeof Container;
