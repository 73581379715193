import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  license: M.UUID;
};

export type Output = M.License;

export const storeLicenseAppropriate = makeService<InputData, Output>(
  'store_license_appropriate',
  'post',
);
