import React, { useCallback } from 'react';

import { AnswerVariant } from 'utils/business';
import { block } from 'utils/classname';

import * as Header from './Header';
import './style.scss';

const b = block('quiz-variant');

type Props = {
  variant: AnswerVariant.AnswerVariant;
  onMoveUp(variant: AnswerVariant.AnswerVariant): void;
  onMoveDown(variant: AnswerVariant.AnswerVariant): void;
  onRemove(variant: AnswerVariant.AnswerVariant): void;
};

function Variant({ variant, onMoveDown, onMoveUp, onRemove }: Props) {
  const handleMoveUp = useCallback(() => {
    onMoveUp(variant);
  }, [onMoveUp, variant]);

  const handleMoveDown = useCallback(() => {
    onMoveDown(variant);
  }, [onMoveDown, variant]);

  const handleRemove = useCallback(() => {
    onRemove(variant);
  }, [onRemove, variant]);

  return (
    <div className={b()}>
      <Header.DependenciesContext.Provider
        scoreFormElementState={variant.score}
        textFormElementState={variant.text.formElementState}
        onMoveDown={handleMoveDown}
        onMoveUp={handleMoveUp}
        onRemove={handleRemove}
      >
        <Header.Component />
      </Header.DependenciesContext.Provider>
    </div>
  );
}

export const Component = React.memo(Variant) as typeof Variant;
