import { CallInputData } from '../types';
import type { makeFieldsetState } from './makeFieldsetState';

type Options = {
  fieldsetState: ReturnType<typeof makeFieldsetState>;
};

export function getCallInputData({ fieldsetState }: Options): CallInputData {
  return {
    notification: fieldsetState.notification.units.value.getState(),
  };
}
