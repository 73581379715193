export const isValidUrl = (value: unknown): value is string => {
  try {
    if (typeof value === 'string') {
      const url = new URL(value);
      return url.protocol === 'http:' || url.protocol === 'https:';
    }
    return false;
  } catch {
    return false;
  }
};
