import React from 'react';

import { block } from 'utils/classname';

import './style.scss';

const b = block('divider');

type Props = {
  className?: string;
};

function Divider({ className }: Props) {
  return <hr className={b({}, [className])} />;
}

export const Component = React.memo(Divider) as typeof Divider;
