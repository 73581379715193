import * as M from 'types/serverModels';

import { makeService } from '../../utils';

type PathInputData = {
  uuid: Exclude<M.ProjectBook['uuid'], undefined>;
};

type BodyInputData = {
  notification_config: M.ProjectBookNotificationConfig;
};

type InputData = PathInputData & BodyInputData;

type OutputData = void;

function extractResponse(data: OutputData): OutputData {
  return data;
}

export const put = makeService<InputData, OutputData, OutputData>(
  ({ ticket, uuid }, convertToSearchParams) =>
    `project_book/${uuid}/notification_config/?${convertToSearchParams({
      ticket,
    })}`,
  'api-v2-put',
  false,
  extractResponse,
  {
    extractBodyInput: input => {
      const { ticket, uuid, ...bodyInput } = input;

      return bodyInput;
    },
  },
);
