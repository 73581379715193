import * as TS from 'types';
import * as M from 'types/serverModels';
import { makePrimaryUnit } from 'utils/State';

import { State } from '../types';
import * as Image from './Image';
import * as Map from './Map';
import * as Match from './Match';
import * as MultiChoice from './MultiChoice';
import * as Number from './Number';
import * as SingleChoice from './SingleChoice';
import * as String from './String';
import * as Text from './Text';

export { Image, Map, MultiChoice, Number, SingleChoice, String, Text };

const makeQuestionState = (question: M.QuizQuestion, quiz: M.Quiz) => {
  const initialValue = quiz?.answers?.[question.uuid] as any;

  switch (question.type) {
    case 'text':
      return Text.makeState(initialValue);
    case 'string':
      return String.makeState(initialValue);
    case 'map':
      return Map.makeState(initialValue);
    case 'number':
      return Number.makeState(initialValue);
    case 'image':
      return Image.makeState(initialValue);
    case 'multi_choice':
      return MultiChoice.makeState(question, initialValue);
    case 'single_choice':
      return SingleChoice.makeState(question, initialValue);
    case 'match':
      return Match.makeState(question, initialValue);
  }
};

export const makeQuestionsState = (quiz: M.Quiz): TS.QuizQuestionsState => {
  return quiz.questions.reduce<TS.QuizQuestionsState>((acc, question) => {
    if (acc[question.type]) {
      return {
        ...acc,
        [question.type]: {
          ...acc[question.type],
          [question.uuid]: makeQuestionState(question, quiz),
        },
      };
    }
    return {
      ...acc,
      [question.type]: {
        [question.uuid]: makeQuestionState(question, quiz),
      },
    };
  }, {} as TS.QuizQuestionsState);
};

export const makeState = (quiz: M.Quiz): State => {
  return {
    quiz: makePrimaryUnit(quiz),
    questions: makeQuestionsState(quiz),
  };
};
