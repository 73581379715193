import { makeService } from './utils';

type InputData = {
  qqfile: Blob;
  question: string;
};

export const soundUpload = makeService<InputData, string>(
  'sound_upload',
  'post-file',
);
