import { Modal } from 'components';
import { makePrimaryUnit } from 'utils/State';

import { State } from './types';

export const isOpenUnit = Modal.makeIsOpenUnit();

export const statesUnit = makePrimaryUnit<State[]>([]);

statesUnit.subscribe({
  name: 'resetter',
  callback: states => {
    if (states.length > 0) {
      return;
    }

    isOpenUnit.resetState();
  },
});
