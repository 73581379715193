import React from 'react';

import { PrimaryStateUnit } from 'utils/State';
import { block } from 'utils/classname';

import { UserTableCellView } from '../subfeatures';
import { PendingAction } from '../types';
import './style.scss';

const b = block('pending-action-sub-list-entry');

export type Props = {
  Controls: React.VFC<Pick<Props, 'pendingAction' | 'pendingActionsUnit'>>;
  pendingAction: PendingAction;
  pendingActionsUnit: PrimaryStateUnit<PendingAction[]>;
};

function Entry({ Controls, pendingAction, pendingActionsUnit }: Props) {
  return (
    <div className={b()}>
      <div className={b('user-table-cell-view')}>
        <UserTableCellView.Component userData={pendingAction.userData} />
      </div>
      <Controls
        pendingAction={pendingAction}
        pendingActionsUnit={pendingActionsUnit}
      />
    </div>
  );
}

export * as Control from './Control';

export const Component = React.memo(Entry);
