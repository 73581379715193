import { I18n } from 'services';
import * as TS from 'types';
import { PrimaryStateUnit } from 'utils/State';

import { makeMultipleUnitsValidator } from './makeValidator';

type RangeElementConfig = {
  valueUnit: PrimaryStateUnit<number | null>;
  messageReference: I18n.EntryReference;
};

export function makeRangeValidators(
  fromConfig: RangeElementConfig,
  toConfig: RangeElementConfig,
  allowEqual?: boolean,
) {
  const lessThan = allowEqual
    ? (from: number, to: number) => from <= to
    : (from: number, to: number) => from < to;

  return {
    fromRangeValidator: makeMultipleUnitsValidator(
      (from, to): TS.ValidationResult => {
        if (from === null || to === null || lessThan(from, to)) {
          return { kind: 'valid' };
        }

        return {
          kind: 'invalid',
          messageReference: I18n.constants.emptyReference,
        };
      },
      fromConfig.valueUnit,
      toConfig.valueUnit,
    ),
    toRangeValidator: makeMultipleUnitsValidator(
      (from, to): TS.ValidationResult => {
        if (from === null || to === null || lessThan(from, to)) {
          return { kind: 'valid' };
        }

        return {
          kind: 'invalid',
          messageReference: I18n.constants.emptyReference,
        };
      },
      fromConfig.valueUnit,
      toConfig.valueUnit,
    ),
  };
}
