import { makeFeature } from 'services/Feature';

import i18nData from './i18n.json';

export const feature = makeFeature({
  name: 'WidgetSort',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "widget-sort" */
      './Component'
    ),
  i18nSharedReferences: null,
  i18nData,
  subfeatures: [],
});

export const Component = feature.Component;
export { makeState } from './makeState';
export { makeFilterStateUnitForRequest } from './makeFilterStateUnitForRequest';
export { makeServerFilterFromState } from './makeServerFilterFromState';
export type { State } from './types';
