import { makePrimaryUnit } from 'utils/State';
import { makeUUID } from 'utils/UUID';

import { materialKeyToMaterialConstructorWidget } from './materials';
import { makeSharedInstanceStateConstructor } from './materials/makeSharedInstanceState';
import {
  CachedInstance,
  InstancesCache,
  MaterialKey,
  MaterialLanguage,
  MaterialMode,
} from './types';

export function makeNewMaterialInstance(
  materialKey: MaterialKey,
  isForTeacher: boolean,
): CachedInstance {
  const id = makeUUID();

  const language: MaterialLanguage = 'project-language';

  const materialModeUnit = makePrimaryUnit<MaterialMode>('edit');

  const serverIDUnit = makePrimaryUnit<string | null>(null);
  const makeSharedInstanceState = makeSharedInstanceStateConstructor({
    serverIDUnit,
    modeUnit: materialModeUnit,
    isForTeacher,
    language,
  });

  const activeMaterialKeyUnit = makePrimaryUnit(materialKey);
  const instancesCache = Object.entries(
    materialKeyToMaterialConstructorWidget,
  ).reduce<InstancesCache>(
    (acc, [key, widget]) => ({
      ...acc,
      [key]: widget.makeInstance(makeSharedInstanceState),
    }),
    {} as InstancesCache,
  );
  return {
    activeMaterialKeyUnit,
    localID: id,
    serverIDUnit,
    mode: materialModeUnit,
    instancesCache,
    language,
    getStateInstance: () => instancesCache[activeMaterialKeyUnit.getState()],
  };
}
