import { SetRequired } from 'type-fest';

import * as M from 'types/serverModels';

import { makeService } from '../utils';

type PathInputData = {
  task_uuid: Exclude<M.ProjectBookTask['uuid'], undefined>;
};

type BodyInputData = {
  project_book_task: Omit<
    M.ProjectBookTask,
    | 'uuid'
    | 'number'
    | 'author'
    | 'cdate'
    | 'project_book'
    | 'project_book_shadow'
    | 'stage'
  >;
};

type InputData = PathInputData & BodyInputData;

type APIOutputData = {
  project_book_task: SetRequired<
    M.ProjectBookTask,
    'uuid' | 'number' | 'project_book' | 'stage' | 'cdate' | 'author' | 'status'
  >;
  linked_tasks?: M.ProjectBookTask[];
  referring_tasks?: M.ProjectBookTask[];
};

type OutputData = {
  projectBookTask: SetRequired<
    M.ProjectBookTask,
    'uuid' | 'number' | 'project_book' | 'stage' | 'cdate' | 'author' | 'status'
  >;
  linkedTasks: M.ProjectBookTask[];
  referringTasks: M.ProjectBookTask[];
};

function extractResponse(data: APIOutputData): OutputData {
  return {
    projectBookTask: data.project_book_task,
    linkedTasks: data.linked_tasks || [],
    referringTasks: data.referring_tasks || [],
  };
}

export const put = makeService<InputData, APIOutputData, OutputData>(
  ({ ticket, task_uuid }, convertToSearchParams) =>
    `project_book_task/${task_uuid}/?${convertToSearchParams({ ticket })}`,
  'api-v2-put',
  false,
  extractResponse,
  {
    extractBodyInput: input => {
      const { ticket, task_uuid, ...bodyInput } = input;

      return bodyInput;
    },
  },
);
