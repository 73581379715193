import React, { useMemo } from 'react';

import { I18n } from 'services';
import { block } from 'utils/classname';
import { Unit } from 'utils/models';

import i18nData from '../../i18n.json';
import { ProvidedProps } from '../types';

const b = block('unit-action-disabled-file-type-icon-content');

type Props = ProvidedProps;

function DisabledFileTypeIcon({ unit }: Props) {
  const text = I18n.useText(i18nData).content.disabledFileTypeIcon;

  const UnitIcon = useMemo(() => Unit.getIcon(unit, 'disabled'), [unit]);

  return <UnitIcon className={b()} title={text.title} />;
}

export const Component = React.memo(
  DisabledFileTypeIcon,
) as typeof DisabledFileTypeIcon;
