import * as M from 'types/serverModels';

export function makeEmulateParams(
  shouldEmulate: boolean,
  seed: string,
): string | undefined {
  const params: M.EmulateParams = {
    number: 30,
    seed,
  };

  return shouldEmulate ? JSON.stringify(params) : undefined;
}
