import React from 'react';

import { routeTree } from 'pages/routeTree';
import { Page } from 'services';

import * as features from './features';

type Props = features.Conclusion.Props;

function Conclusion(props: Props) {
  return <features.Conclusion.Component {...props} />;
}

export const path =
  routeTree.LANG.course.list.COURSE.conclusions.form.getPath();

export const Component = Page.makePage({
  path,
  scrollTo: 'top-on-mount',
  Component: React.memo(Conclusion) as typeof Conclusion,
  features: Object.values(features).map(x => x.feature),
}) as typeof Conclusion;
