import React, { useMemo } from 'react';

import i18nData from 'features/project/Constructor/i18n.json';
import {
  WidgetDeleteControl,
  WidgetNavigationControls,
} from 'features/project/Constructor/subfeatures';
import { I18n } from 'services';
import { makePrimaryUnit } from 'utils/State';
import { block } from 'utils/classname';
import { useRequiredContext } from 'utils/react/RequiredContext';

import { ConstructorWidgetContext } from '../Context';
import './style.scss';

const b = block('collapsed-constructor-widget');

type Props = {};

function Collapsed({}: Props) {
  const { onDelete, onMoveNext, onMovePrev, stateInstance } =
    useRequiredContext(ConstructorWidgetContext);

  const text = I18n.useText(i18nData);
  const widgetType =
    stateInstance.kind === 'select-instance-kind'
      ? text.typelessWidgetType
      : text.steps.findingsSettings.widgets[stateInstance.kind].name;

  const titleUnit = useMemo(() => {
    return stateInstance.kind === 'select-instance-kind'
      ? makePrimaryUnit(text.namelessWidgetTitle)
      : stateInstance.title.formElementState.units.value;
  }, [stateInstance, text.namelessWidgetTitle]);

  const title = titleUnit.useState();

  return (
    <div className={b()}>
      <div className={b('type-and-title')}>
        <div className={b('widget-type')}>{widgetType}</div>
        <div className={b('widget-title')}>
          {title === '' ? text.namelessWidgetTitle : title}
        </div>
      </div>
      <div className={b('control-panel')}>
        <WidgetNavigationControls.Component
          onMoveNext={onMoveNext}
          onMovePrev={onMovePrev}
        />
        <WidgetDeleteControl.Component
          confirmMessage={text.steps.findingsSettings.confirmDeleteMessage}
          onDelete={onDelete}
        />
      </div>
    </div>
  );
}

export const Component = React.memo(Collapsed);
