import * as R from 'ramda';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as ReactDOM from 'react-dom';

import { VerticallyMovableElement, IconContainer, TextInput } from 'components';
import { ConfirmActionModal } from 'features/project/Constructor/subfeatures';
import { I18n, IDProvider } from 'services';
import crossIconSrc from 'shared/images/cross.svg';
import pencilIconSrc from 'shared/images/pencil.svg';
import { useFormElementState } from 'utils/FormState';
import { block } from 'utils/classname';
import { useRequiredContext } from 'utils/react/RequiredContext';

import i18nData from '../../../../i18n.json';
import { ProjectWriteContext } from '../../../ProjectWriteContext';
import { Question } from '../../shared';
import { cachedStoreInstancesUnit } from '../units/instances';
import './style.scss';

const b = block('widget-group-header');

type Props = {
  groupHeaderView: VerticallyMovableElement.Element<Question.GroupHeaderView>;
  groupCounter: number;
  onDelete(id: string): void;
};

type Mode = 'view' | 'edit';

function WidgetGroupHeader({ groupHeaderView, groupCounter, onDelete }: Props) {
  const [mode, setMode] = useState<Mode>('view');
  const formID = IDProvider.useID('form-for-widget-group-input');

  const text = I18n.useText(i18nData);

  const formElementState = useFormElementState(groupHeaderView.value.name);

  const { saveProject } = useRequiredContext(ProjectWriteContext);

  const isOpenUnit = ConfirmActionModal.useIsOpenUnit();

  const handleYesClick = useCallback(() => {
    onDelete(groupHeaderView.value.id);
  }, [groupHeaderView.value.id, onDelete]);

  const handleNoClick = useCallback(() => {
    isOpenUnit.setState(false);
  }, [isOpenUnit]);

  const handleDeleteIconClick = useCallback(() => {
    isOpenUnit.setState(true);
  }, [isOpenUnit]);

  const handleEditIconClick = useCallback(() => setMode('edit'), []);

  const setGroupName = useCallback(() => {
    cachedStoreInstancesUnit.setState(prev => {
      if (prev.kind === 'grouped') {
        const groupIndex = prev.groups.findIndex(
          x => x.id === groupHeaderView.value.id,
        );
        if (groupIndex !== -1) {
          const groupNameLens = R.lensPath<
            Question.GroupedStoreCachedInstances,
            'groups',
            number,
            'name'
          >(['groups', groupIndex, 'name']);

          return R.set(
            groupNameLens,
            formElementState.units.value.getState(),
            prev,
          );
        }
      }

      console.error('received incosistent state');
      return prev;
    });
    setMode('view');
    saveProject();
  }, [formElementState.units.value, groupHeaderView.value.id, saveProject]);

  const handleTextInputBlur = useCallback(setGroupName, [setGroupName]);
  const handleFormSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      setGroupName();
    },
    [setGroupName],
  );

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (mode === 'edit' && inputRef.current) {
      inputRef.current.focus();
    }
  }, [mode]);

  return (
    <VerticallyMovableElement.Component
      element={groupHeaderView}
      className="widget-group-header-container"
    >
      {ReactDOM.createPortal(
        <form onSubmit={handleFormSubmit} id={formID} />,
        document.body,
      )}
      <div className={b()}>
        <div className={b('content')}>
          {(() => {
            switch (mode) {
              case 'view':
                return (
                  <>
                    <div className={b('left-part')}>
                      <div className={b('name')}>
                        {`${text.steps.inputDataForm.defaultStageLabel} ${groupCounter}.`}{' '}
                        {groupHeaderView.value.name}
                      </div>
                      <IconContainer.Component
                        className={b('edit-icon-container')}
                        size="s"
                        onClick={handleEditIconClick}
                      >
                        <img
                          className={b('edit-icon')}
                          src={pencilIconSrc}
                          alt="edit"
                        />
                      </IconContainer.Component>
                    </div>
                    <img
                      className={b('delete-icon')}
                      src={crossIconSrc}
                      alt="delete"
                      onClick={handleDeleteIconClick}
                    />
                  </>
                );
              case 'edit':
                return (
                  <TextInput.Component
                    form={formID}
                    formElementState={formElementState}
                    onBlur={handleTextInputBlur}
                    errorRows={0}
                    inputRef={inputRef}
                  />
                );
            }
          })()}
        </div>
        <ConfirmActionModal.Component
          onYesClick={handleYesClick}
          onNoClick={handleNoClick}
          isOpenUnit={isOpenUnit}
        >
          {text.steps.inputDataForm.confirmDeleteMessage}
        </ConfirmActionModal.Component>
      </div>
    </VerticallyMovableElement.Component>
  );
}

export const Component = React.memo(WidgetGroupHeader);
