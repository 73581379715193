import React from 'react';

import { MapWidgetLayers } from 'features/project/Constructor/subfeatures';

import { LayersProps } from '../../../types';
import { MapInstance } from '../types';

function Layers({ instance }: LayersProps<MapInstance>) {
  return (
    <MapWidgetLayers.Component
      layersUnit={instance.layers}
      activeLayerIndexUnit={instance.activeLayerIndex}
    />
  );
}

export const Component = React.memo(Layers);
