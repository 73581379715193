import { useCallback } from 'react';

import { makePhrase } from 'utils/lang';

import { activeLangStateUnit } from './activeLangStateUnit';

export function useGetTemplate() {
  const lang = activeLangStateUnit.useState();

  return useCallback(
    (template: string, substitutes: Record<string, string | number>) => {
      return makePhrase(template, lang, substitutes);
    },
    [lang],
  );
}
