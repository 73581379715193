import { PrimaryStateUnit } from 'utils/State';

export function makeNullSearchParamsUnit<T>(): PrimaryStateUnit<T | null> {
  return {
    getState: () => null,
    initialState: null,
    isStateUnit: true,
    kind: 'primary',
    setState: () => void 0,
    useState: () => null,
    getBridgeUnit: () => {
      throw Error('not-implemented');
    },
    useBridgeUnitMemo: () => {
      throw Error('not-implemented');
    },
    resetState: () => {
      throw Error('not-implemented');
    },
    subscribe: () => {
      throw Error('not-implemented');
    },
    subscribeStart: () => {
      throw Error('not-implemented');
    },
  };
}
