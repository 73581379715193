import React, { useCallback, useState } from 'react';

import { TextInput } from 'components';
import { I18n } from 'services';
import { FormElementState, useFormElementState } from 'utils/FormState';
import { block } from 'utils/classname';

import i18nData from '../../i18n.json';
import './style.scss';

const b = block('sign-up-promo-code-field');

type Props = {
  formElementState: FormElementState<string>;
};

export const useFieldState = () => {
  return useFormElementState<string>('');
};

function PromoCodeInput({ formElementState }: Props) {
  const [isVisibleField, setIsVisibleField] = useState(false);

  const text = I18n.useText(i18nData).promoCodeField;

  const handleButtonClick = useCallback(() => {
    setIsVisibleField(true);
  }, []);

  return (
    <div className={b()}>
      {isVisibleField ? (
        <TextInput.Component
          formElementState={formElementState}
          placeholder={text.placeholder}
          errorRows={0}
        />
      ) : (
        <button
          className={b('button')}
          type="button"
          onClick={handleButtonClick}
        >
          {text.label}
        </button>
      )}
    </div>
  );
}

export const Component = React.memo(PromoCodeInput) as typeof PromoCodeInput;
