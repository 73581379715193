import React, { useCallback } from 'react';

import { ProjectWriteContext } from 'features/project/Constructor/FormWithSteps/ProjectWriteContext';
import { projectLanguageUnit } from 'features/project/Constructor/units';
import { I18n } from 'services';
import * as M from 'types/serverModels';
import { useRequiredContext } from 'utils/react/RequiredContext';

import { QuizConstructorModal } from '../../../../../../../subfeatures';
import { FormExtensionProps } from '../../../types';
import { StateInstance } from '../types';

function FormExtension({ instance }: FormExtensionProps<StateInstance>) {
  const quizUUID = instance.quiz.units.value.useState();
  const projectLang = projectLanguageUnit.useState();

  const { saveProject } = useRequiredContext(ProjectWriteContext);

  const t = I18n.useGetMultilingProjectTranslation();

  const handleSave = useCallback(
    (data: M.Quiz) => {
      instance.materialTitle.units.value.setState(t(data.title));
      instance.materialExplanation.units.value.setState(t(data.description));
      instance.quiz.units.value.setState(data.uuid);
      saveProject();
    },
    [instance, saveProject, t],
  );

  const handleClose = useCallback(() => {
    instance.mode.setState('preview');
  }, [instance]);

  return (
    <QuizConstructorModal.Component
      quizUUID={quizUUID || undefined}
      language={projectLang}
      onSave={handleSave}
      onClose={handleClose}
    />
  );
}

export const Component = React.memo(FormExtension);
