import debounce from 'debounce';
import { useLayoutEffect } from 'react';

export function useScrollToElementOnMount(
  getElement: () => HTMLElement | null,
) {
  useLayoutEffect(() => {
    const element = getElement();

    if (!element) return;

    const scrollToElement = debounce(() => {
      element.scrollIntoView({ behavior: 'smooth' });
    }, 100);

    const observer = new ResizeObserver(() => {
      scrollToElement();
    });

    observer.observe(document.documentElement);

    const disconnectObserver = () => {
      observer.disconnect();
    };

    window.addEventListener('pointerdown', disconnectObserver);
    window.addEventListener('wheel', disconnectObserver);
    window.addEventListener('keydown', disconnectObserver);

    return () => {
      disconnectObserver();

      window.removeEventListener('pointerdown', disconnectObserver);
      window.removeEventListener('wheel', disconnectObserver);
      window.removeEventListener('keydown', disconnectObserver);
    };
  }, [getElement]);
}
