import React from 'react';

import { Checkbox } from 'components';
import { I18n } from 'services';
import { FormElementState, useFormElementState } from 'utils/FormState';

import i18nData from '../../i18n.json';

export const useFieldState = () => {
  return useFormElementState<boolean>(false);
};

type Props = {
  formElementState: FormElementState<boolean>;
};

const Label = Checkbox.makeLabel({
  useLabel: () => I18n.useText(i18nData).subscribeField.label,
  size: 'text-14',
});

function SubscribeCheckbox({ formElementState }: Props) {
  return (
    <Checkbox.Component formElementState={formElementState} Label={Label} />
  );
}

export const Component = React.memo(SubscribeCheckbox);
