import { useEffect } from 'react';

import * as M from 'types/serverModels';
import { makeDerivedUnit, PrimaryStateUnit } from 'utils/State';

import * as services from '../services';
import { CallState } from '../types';

const ageRefsCallStateUnit =
  services.refLoad.makeCallStateUnit() as PrimaryStateUnit<
    CallState<M.AgeReferenceBook[]>
  >;

export const agesCallStateUnit = makeDerivedUnit(ageRefsCallStateUnit).getUnit<
  CallState<Record<M.AgeReferenceBook['uuid'], M.AgeReferenceBook>>
>(ageRefsCallState => {
  if (ageRefsCallState.kind === 'successful') {
    const data = ageRefsCallState.data.reduce<
      Record<M.AgeReferenceBook['uuid'], M.AgeReferenceBook>
    >((acc, x) => {
      return {
        ...acc,
        [x.uuid]: x,
      };
    }, {});

    return {
      kind: 'successful',
      data,
    };
  }

  return ageRefsCallState;
});

const call = services.refLoad.makeCall(
  ageRefsCallStateUnit as PrimaryStateUnit<CallState<M.ReferenceBook[]>>,
);

let wasCalled = false;

export const callRefService = () => {
  if (!wasCalled) {
    wasCalled = true;

    call({ ref: 'age' });
  }
};

export function useAgeRefsCallEffect() {
  const callState = ageRefsCallStateUnit.useState();

  useEffect(() => {
    callRefService();
  }, [callState.kind]);
}

export function useCallState() {
  const callState = agesCallStateUnit.useState();

  useAgeRefsCallEffect();

  return callState;
}
