import React from 'react';

import { block } from 'utils/classname';

import { CommonProps } from '../types';
import './style.scss';

const b = block('sortable-list-default-container');

type Props = CommonProps & {
  header?: string;
  listDirection?: 'column' | 'row';
  Footer?: React.FC;
  headless?: boolean;
};

function DefaultContainer({
  header,
  listDirection = 'row',
  Footer,
  headless,
  ...commonProps
}: Props) {
  const { children, provided } = commonProps as CommonProps & {
    [key: string]: undefined;
  };

  return (
    <section
      className={b({ 'list-direction': listDirection, headless })}
      ref={provided?.innerRef}
      {...provided?.droppableProps}
    >
      {header && <h6 className={b('header')}>{header}</h6>}
      <div className={b('list')}>{children}</div>
      {Footer && <Footer />}
    </section>
  );
}

export const Component = React.memo(
  DefaultContainer,
) as typeof DefaultContainer;
