import { makeFeature } from 'services/Feature';

import * as i18nSharedReferences from './i18nSharedReferences';
import * as subfeatures from './subfeatures';

export const feature = makeFeature({
  name: 'CourseViewNavigation',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "course-view-navigation" */
      './Component'
    ),
  i18nData: null,
  i18nSharedReferences: Object.values(
    (() => {
      const { courseTabReferences } = i18nSharedReferences;

      return { ...courseTabReferences };
    })(),
  ),
  subfeatures: Object.values(subfeatures).map(x => x.feature),
});

export const Component = feature.Component;
