import React from 'react';

import { I18n } from 'services';
import { block } from 'utils/classname';

import * as ProgressBar from '../../../ProgressBar';
import i18nData from '../../../i18n.json';
import './style.scss';

const b = block('form-container');

type Props = {};

function FormContainer({}: Props) {
  const text = I18n.useText(i18nData);

  return (
    <div className={b()}>
      <h4 className={b('title')}>{text.modals.constructor.title}</h4>
      <div className={b('progress-bar')}>
        <ProgressBar.Component />
      </div>
    </div>
  );
}

export const Component = React.memo(FormContainer);
