import { makeSharedEntryReference } from 'services/I18n/makeEntryReference';

export const isRequired = makeSharedEntryReference(
  data => data.validationErrors.requiredField,
);

export const nullOptionReference = makeSharedEntryReference(
  data => data.select.nullOption,
);

export const someError = makeSharedEntryReference(data => data.someError);
