import { quizLanguageUnit } from 'features/quiz/Constructor/units';
import { I18n } from 'services';
import {
  FormElementState,
  makeFormElementState,
  makeFormSectionState,
  FormEntityState,
} from 'utils/FormState';
import {
  makeDerivedUnit,
  makePrimaryUnit,
  PrimaryStateUnit,
} from 'utils/State';
import { AnswerVariant } from 'utils/business';
import { makeSingleUnitValidator } from 'utils/validators';

import i18nData from '../../../../i18n.json';
import {
  QuestionConstructor,
  SharedInstanceStateConstructor,
} from '../../types';
import * as FormExtension from './FormExtension';
import * as HeaderExtension from './HeaderExtension';
import * as PreviewModeForm from './PreviewModeForm';
import { DisplayKind, ServerQuestion, StateInstance } from './types';

export { DependenciesContext } from './Dependencies';
export type { StateInstance, DisplayKind };

export const constructor: QuestionConstructor<
  'variantSelection',
  StateInstance,
  typeof makeInstance
> = {
  key: 'variantSelection',
  icon: 'variantSelection',
  makeInstance,
  HeaderExtension: HeaderExtension.Component,
  FormExtension: FormExtension.Component,
  PreviewModeForm: PreviewModeForm.Component,
};

type ServerQuestionConstructorArgs = {
  variantsUnit: PrimaryStateUnit<AnswerVariant.AnswerVariant[]>;
  hasMultipleChoiceState: FormElementState<boolean>;
  displayKind: FormElementState<DisplayKind>;
};

export function makeServerQuestion({
  hasMultipleChoiceState,
  variantsUnit,
  displayKind,
}: ServerQuestionConstructorArgs): Partial<ServerQuestion> {
  const variants = variantsUnit.getState();

  const lang = quizLanguageUnit.getState();

  return hasMultipleChoiceState.units.value.getState()
    ? ({
        type: 'multi_choice',
        variants: variants.map(x =>
          AnswerVariant.makeServerVariant(x, lang, 'conclusions'),
        ),
      } as Partial<ServerQuestion>)
    : ({
        type: 'single_choice',
        variants: variants.map(x =>
          AnswerVariant.makeServerVariant(x, lang, 'conclusions'),
        ),
        control: {
          dropdown: displayKind.units.value.getState() === 'select',
        },
      } as Partial<ServerQuestion>);
}

const atLeastTwoVariantsRequiredMessage = I18n.makeEntryReference(
  i18nData,
  data =>
    data.questions.list.variantSelection.errors.atLeastTwoVariantsRequired,
);

type InitialState = {
  hasMultipleChoice: boolean;
  variants: AnswerVariant.AnswerVariant[];
  displayKind: DisplayKind;
};

export function makeInstance(
  makeSharedInstanceState: SharedInstanceStateConstructor,
  initialState: InitialState = {
    hasMultipleChoice: false,
    variants: [],
    displayKind: 'radio',
  },
): StateInstance {
  const variants = makePrimaryUnit<AnswerVariant.AnswerVariant[]>(
    initialState.variants,
  );

  const atLeastTwoVariantsValidator = makeSingleUnitValidator<
    AnswerVariant.AnswerVariant[]
  >(x =>
    x.length < 2
      ? { kind: 'invalid', messageReference: atLeastTwoVariantsRequiredMessage }
      : { kind: 'valid' },
  );

  const variantsSectionState = makeFormSectionState(variants, [
    atLeastTwoVariantsValidator,
  ]);

  const validatablesUnit = makeDerivedUnit(variants).getUnit(
    (variants): FormEntityState[] => [
      ...variants.map(x => x.text.formElementState),
      variantsSectionState,
    ],
  );

  const hasMultipleChoiceState = makeFormElementState<boolean>(
    initialState.hasMultipleChoice,
  );

  const displayKind = makeFormElementState<DisplayKind>(
    initialState.displayKind,
  );

  return {
    kind: 'variantSelection',
    hasMultipleChoice: hasMultipleChoiceState,
    variants,
    displayKind,
    variantsSectionState,
    makeServerQuestion() {
      return makeServerQuestion({
        hasMultipleChoiceState,
        variantsUnit: this.variants,
        displayKind,
      });
    },
    ...makeSharedInstanceState(validatablesUnit),
  };
}
