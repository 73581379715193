import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  login: string;
  conclusions: M.UUID;
};

export const conclusionsCheckUser = makeService<InputData, boolean>(
  'conclusions_check_user',
  'get',
);
