import React, { useMemo } from 'react';

import { ReactComponent as SearchIcon } from 'shared/images/search.svg';
import { makeDerivedUnit } from 'utils/State';
import { block } from 'utils/classname';

import { RightPartPlugin, RightPartProps } from '../../RightPartPlugin';
import { b as textInputBlock } from '../../b';
import './style.scss';

const b = block('text-input-right-part-search-button');

type SearchButtonOwnProps = {
  onClick(): void;
};

type Props = RightPartProps & SearchButtonOwnProps;

function SearchButton({ formElementState, onClick }: Props) {
  const hasValueUnit = useMemo(
    () =>
      makeDerivedUnit(formElementState.units.value).getUnit(
        value => value !== '',
      ),
    [formElementState.units.value],
  );

  const hasValue = hasValueUnit.useState();

  return (
    <div className={b({ 'has-value': hasValue })} onClick={onClick}>
      <SearchIcon className={b('search-icon')} />
    </div>
  );
}

export const Component = React.memo(SearchButton) as typeof SearchButton;
export const makePlugin = (
  ownProps: SearchButtonOwnProps,
): RightPartPlugin => ({
  useInputContourClassName: formElementState => {
    const value = formElementState.units.value.useState();

    return textInputBlock('input-contour', {
      'with-value': value !== '',
      'with-search-button': true,
    });
  },
  Component: (rightPartProps: RightPartProps) => (
    <Component {...ownProps} {...rightPartProps} />
  ),
});
