import * as M from 'types/serverModels';
import { Course } from 'utils/models';

import { makeService } from './utils';

type InputData = {
  stream: M.Stream['uuid'];
} & Pick<Partial<M.Stream>, 'name' | 'active' | 'open'>;

type APIOutputData = M.CourseStreamServiceStream;

type OutputData = M.CourseStreamServiceStreamOutput;

function extractResponse(data: APIOutputData): OutputData {
  return {
    ...data,
    tracks: data.tracks.map(x => ({
      ...x,
      objects: Course.addKeyToTrackObjects(x.objects),
    })),
  };
}

export const courseCloneStream = makeService<
  InputData,
  APIOutputData,
  OutputData
>('course_clone_stream', 'post', true, extractResponse);
