import { Location } from 'history';
import { compile, parse, Token } from 'path-to-regexp';

import { getCurrentLocation } from 'services/Routing';
import { searchParamsProperty } from 'services/Routing/constants';

import { Substitute } from './types';

export type GetPathArguments<
  RouteParams extends string[],
  SearchParams extends string[],
> = {
  routeParams?: Record<RouteParams[number], string | Substitute>;
  searchParams?: Record<SearchParams[number], string>;
  hashParam?: Location['hash'];
};

export function findParamIndex(pathPattern: string, param: string) {
  function loop(tokens: Token[], index: number): number {
    if (tokens.length === 0) {
      return -1;
    }

    const [x, ...xs] = tokens;

    if (typeof x === 'string') {
      return loop(xs, index + x.split('/').length - 1);
    }

    if (x.name === param) {
      return index;
    }

    return loop(xs, index + 1);
  }

  return loop(parse(pathPattern), 0);
}

export function makeGetPath(pathPattern: string) {
  return (args?: GetPathArguments<string[], string[]>): string => {
    const currentLocation = getCurrentLocation() as Location<unknown>;

    if (!args) {
      return pathPattern;
    }

    const jsonSearchParams = JSON.stringify(args.searchParams);
    const searchParams =
      args.searchParams && jsonSearchParams !== '{}'
        ? `?${searchParamsProperty}=${jsonSearchParams}`
        : '';

    const hashParam = args.hashParam || '';

    const pathname = args.routeParams
      ? (() => {
          const params = Object.entries(args.routeParams).reduce(
            (acc, [key, value]) => {
              return {
                ...acc,
                [key.toLowerCase()]:
                  typeof value === 'string'
                    ? value
                    : (() => {
                        switch (value.kind) {
                          case 'from-location': {
                            const paramIndex = findParamIndex(
                              pathPattern,
                              key.toLowerCase(),
                            );

                            if (paramIndex === -1) {
                              console.error(
                                'param',
                                key,
                                'was not found in pattern',
                                pathPattern,
                              );
                              return undefined;
                            }

                            const parsedLocation = currentLocation.pathname
                              .split('/')
                              .slice(1);
                            return parsedLocation[paramIndex];
                          }
                          case 'from-pattern': {
                            return `:${key.toLowerCase()}`;
                          }
                        }
                      })(),
              };
            },
            {},
          );

          const makePath = compile(pathPattern);

          return makePath(params);
        })()
      : pathPattern;

    const path = `${pathname}${searchParams}${hashParam}`;

    return path;
  };
}
