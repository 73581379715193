import { isRequired } from 'features/project/Constructor/i18nSharedReferences';
import { URLString } from 'types';
import * as M from 'types/serverModels';
import { FormElementState, makeFormElementState } from 'utils/FormState';
import { makePrimaryUnit } from 'utils/State';
import { nonNull } from 'utils/validators';

import {
  MaterialConstructorWidget,
  SharedInstanceStateConstructor,
} from '../../types';
import * as FormExtension from './FormExtension';
import { StateInstance } from './types';

export type { StateInstance };

export function makeServerUnit(
  fileState: FormElementState<URLString | null>,
): Partial<M.PrintUnit> {
  const url = fileState.units.value.getState();

  return {
    type: 'print',
    file: url === null ? undefined : { url },
  };
}

export const constructorWidget: MaterialConstructorWidget<
  'file',
  StateInstance,
  typeof makeInstance
> = {
  key: 'file',
  makeInstance,
  FormExtension: FormExtension.Component,
};

function makeInstance(
  makeSharedInstanceState: SharedInstanceStateConstructor,
  initialFile: URLString | null = null,
): StateInstance {
  const fileState = makeFormElementState<URLString | null>(initialFile, [
    nonNull(isRequired),
  ]);

  return {
    kind: 'file',
    file: fileState,
    makeServerUnit: () => makeServerUnit(fileState),
    ...makeSharedInstanceState(makePrimaryUnit([fileState.formNode])),
  };
}
