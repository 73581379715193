import { ExperimentData } from 'types/serverModels';

import { makeService } from '../../../utils';

type InputData = {
  experimentID: string;
};

type OutputData = {
  data: ExperimentData;
};

export const get = makeService<InputData, OutputData>(
  ({ experimentID }) => `logger/experiment/${experimentID}/data`,
  'api-v2-get',
);
