import { pathToRegexp, TokensToRegexpOptions } from 'path-to-regexp';
import * as R from 'ramda';

import { Feature } from 'services/Feature';

export type PageFeatures = {
  features: Array<Feature<any>>;
  tokenToRegexpOptions?: TokensToRegexpOptions;
};

const pageFeaturesRegistry: { [pagePathPattern: string]: PageFeatures } = {};

export function setPageFeatures(
  pagePathPattern: string,
  features: Array<Feature<any>>,
  tokenToRegexpOptions?: TokensToRegexpOptions,
) {
  pageFeaturesRegistry[pagePathPattern] = { features, tokenToRegexpOptions };
}

export function getFeaturesForPath(path: string): Array<Feature<any>> {
  return R.uniqBy(
    x => x.name,
    Object.entries(pageFeaturesRegistry).flatMap(
      ([pathPattern, pageFeatures]) => {
        const regex = pathToRegexp(
          pathPattern,
          undefined,
          pageFeatures.tokenToRegexpOptions,
        );

        return regex.test(path) ? pageFeatures.features : [];
      },
    ),
  );
}
