import * as M from 'types/serverModels';
import { makeFormElementState } from 'utils/FormState';

import { Fieldset } from '../types';

type Options = {
  user: M.User;
};

export function makeFieldsetState({ user }: Options): Fieldset {
  const { notification } = user;

  return { notification: makeFormElementState(notification) };
}
