import { MathJax } from 'better-react-mathjax';
import React, { useMemo, useCallback } from 'react';

import { Dropdown } from 'components';
import { nullOptionReference } from 'features/quiz/Constructor/i18nSharedReferences';
import { I18n } from 'services';
import * as M from 'types/serverModels';
import { FormElementState } from 'utils/FormState';
import { makeDerivedUnit, makeMappingUnitFromUnit } from 'utils/State';
import { block } from 'utils/classname';

import i18nData from '../../../../../../i18n.json';
import {
  TextElementState,
  ImageElementState,
  MatchingsState,
  StateInstance,
} from '../../types';
import { Section, SectionError } from '../components';
import './style.scss';

const b = block('quiz-match-question-form-extension-matchings');

type Props = { instance: StateInstance };

const useTitle = () =>
  I18n.useText(i18nData).questions.list.match.matchings.title;

const LeftColumnTextElement = React.memo(
  ({ formElementState }: { formElementState: FormElementState<string> }) => {
    const text = formElementState.units.value.useState();

    return (
      <p className={b('left-column-text-element')}>
        <MathJax dynamic>{text}</MathJax>
      </p>
    );
  },
);

const LeftColumnImageElement = React.memo(
  ({
    formElementState,
  }: {
    formElementState: FormElementState<M.ImageInfo | null>;
  }) => {
    const image = formElementState.units.value.useState();

    return image ? (
      <img
        className={b('left-column-image-element')}
        src={image.original}
        width="160px"
        height="160px"
        alt=""
      />
    ) : null;
  },
);

const LeftColumnElement = React.memo(
  ({
    instance,
    item,
  }: Pick<Props, 'instance'> & { item: keyof MatchingsState }) => {
    const leftColumn = {
      isImage: instance.leftColumn.isImage.units.value.useState(),
      textElements: (function useElements() {
        const elements = instance.leftColumn.textElementsUnit.useState();

        return useMemo(
          () =>
            elements.reduce<Record<TextElementState['uuid'], TextElementState>>(
              (acc, x) => ({ ...acc, [x.uuid]: x }),
              {},
            ),
          [elements],
        );
      })(),
      imageElements: (function useElements() {
        const elements = instance.leftColumn.imageElementsUnit.useState();

        return useMemo(
          () =>
            elements.reduce<
              Record<ImageElementState['uuid'], ImageElementState>
            >((acc, x) => ({ ...acc, [x.uuid]: x }), {}),
          [elements],
        );
      })(),
    };

    return (
      <div className={b('left-column-element')}>
        {leftColumn.isImage ? (
          <LeftColumnImageElement
            formElementState={leftColumn.imageElements[item].image}
          />
        ) : (
          <LeftColumnTextElement
            formElementState={
              leftColumn.textElements[item].text.formElementState
            }
          />
        )}
      </div>
    );
  },
);

const RightColumnElement = React.memo(
  ({
    instance,
    item,
  }: Pick<Props, 'instance'> & { item: MatchingsState[string] }) => {
    const orderUnit = useMemo(
      () =>
        makeDerivedUnit(instance.rightColumn.elementsUnit).getUnit(elements =>
          elements.map(x => x.uuid),
        ),
      [instance.rightColumn.elementsUnit],
    );

    const state = item.stateUnit.useState();
    const order = orderUnit.useState();

    const useLabel = useMemo(() => {
      return function useLabel() {
        const nullOptionText = I18n.useReference(nullOptionReference);

        const mappedState = useMemo(
          () => makeMappingUnitFromUnit(item.stateUnit),
          [],
        ).useState();

        const order = orderUnit.useState();

        const labels = order.reduce<string[]>(
          (acc, x) =>
            mappedState[x] && mappedState[x].value.units.value
              ? [...acc, mappedState[x].item.text.formElementState.units.value]
              : acc,
          [],
        );

        return (
          <MathJax dynamic>
            {labels.length > 0 ? labels.join('; ') : nullOptionText}
          </MathJax>
        );
      };
    }, [item, orderUnit]);

    const useError = useMemo(() => {
      return function useError() {
        return item.formSectionState.units.error.useState();
      };
    }, [item.formSectionState]);

    const handleClose = useCallback(() => {
      item.formSectionState.formNode.validate();
    }, [item.formSectionState]);

    return (
      <div className={b('right-column-element')}>
        <Dropdown.Component
          errorRows={1}
          height="auto"
          useLabel={useLabel}
          useIsSelected={Dropdown.Children.CheckBoxList.useIsSelectedCallBack(
            state,
          )}
          useError={useError}
          onClose={handleClose}
        >
          <Dropdown.Children.CheckBoxList.Component
            stateUnit={item.stateUnit}
            order={order}
          />
        </Dropdown.Component>
      </div>
    );
  },
);

const List = React.memo(({ instance }: Pick<Props, 'instance'>) => {
  const leftColumn = {
    isImage: instance.leftColumn.isImage.units.value.useState(),
    textElements: instance.leftColumn.textElementsUnit.useState(),
    imageElements: instance.leftColumn.imageElementsUnit.useState(),
  };
  const matchings = instance.matchingsUnit.useState();

  return (
    <ul className={b('list')}>
      {(leftColumn.isImage
        ? leftColumn.imageElements
        : leftColumn.textElements
      ).map(x => (
        <li className={b('item')} key={x.uuid}>
          <LeftColumnElement instance={instance} item={x.uuid} />
          {matchings[x.uuid] && (
            <RightColumnElement instance={instance} item={matchings[x.uuid]} />
          )}
        </li>
      ))}
    </ul>
  );
});

function Matchings({ instance }: Props) {
  const Content = useMemo(
    () =>
      React.memo(() => {
        return (
          <div className={b('content')}>
            <List instance={instance} />
            <SectionError.Component
              sectionStateUnit={instance.matchingsSectionState}
            />
          </div>
        );
      }),
    [instance],
  );

  return <Section.Component Content={Content} useTitle={useTitle} />;
}

export const Component = React.memo(Matchings);
