import { Language } from 'types';
import * as M from 'types/serverModels';
import { FormElementState } from 'utils/FormState';

import { makeMultilingString } from './makeMultilingString';
import { mergeMultilingStrings } from './mergeMultilingStrings';
import { MultilangFormState } from './types';

export function makeMultilangFormState(
  formElementState: FormElementState<string>,
  multilingString: M.MultilingString = [],
): MultilangFormState {
  return {
    kind: 'multilang-form-state',
    formElementState,
    multilingString,
    getMergedMultilingString: (
      formElementStateLang: Language,
    ): M.MultilingString => {
      return mergeMultilingStrings(
        makeMultilingString([
          {
            lang: formElementStateLang,
            value: formElementState.units.value.getState(),
          },
        ]),
        multilingString,
      );
    },
  };
}
