import React, { useRef, useCallback, useLayoutEffect, useEffect } from 'react';
import { createPortal } from 'react-dom';

import { ReactComponent as ExtraIcon } from 'shared/images/extra.svg';
import {
  getScrollableAncestors,
  getFixedPositionStyle,
  stickFixedPositionElement,
} from 'utils/DOM';
import { PrimaryStateUnit } from 'utils/State';
import { block } from 'utils/classname';

import type * as Button from '../Button';
import './style.scss';

type Variant = 'contained' | 'outlined';
type Size = 's' | 'm' | 'l';

const b = block('extra-menu');

export type Props = {
  isOpenUnit: PrimaryStateUnit<boolean>;
  Icon?: React.VFC<{ className?: string }>;
  Button?: React.VFC<Button.ProvidedProps>;
  className?: string;
  variant?: Variant;
  size?: Size;
  children: React.ReactNode;
};

function ExtraMenu({
  isOpenUnit,
  Icon = ExtraIcon,
  Button,
  className,
  variant = 'outlined',
  size = 'm',
  children,
}: Props) {
  const isOpen = isOpenUnit.useState();

  const ref = useRef<HTMLButtonElement>(null);
  const optionsRef = useRef<HTMLUListElement>(null);

  const updateOptionsStyle = useCallback(() => {
    if (!ref.current || !optionsRef.current) return null;

    const fixedPositionStyle = getFixedPositionStyle({
      anchorRect: ref.current.getBoundingClientRect(),
      margin: 5,
      defaultMaxHeight: 300,
    });

    Object.entries(fixedPositionStyle).forEach(([key, x]) => {
      optionsRef.current?.style.setProperty(
        key,
        typeof x === 'number' ? `${x}px` : x,
      );
    });
  }, []);

  const handleClick = useCallback(() => {
    isOpenUnit.setState(prev => !prev);
  }, [isOpenUnit]);

  const handleAncestorScroll = useCallback(() => {
    isOpenUnit.setState(false);
  }, [isOpenUnit]);

  const handleListClick: React.MouseEventHandler = useCallback(event => {
    event.stopPropagation();
  }, []);

  const handleListKeyDown: React.KeyboardEventHandler = useCallback(
    event => {
      switch (event.key) {
        case 'Escape': {
          ref.current?.focus();

          isOpenUnit.setState(false);

          break;
        }
      }
    },
    [isOpenUnit],
  );

  const handleDocumentBodyClick = useCallback(
    (e: MouseEvent) => {
      if (!(e.target instanceof Node)) return;

      if (
        ref.current?.contains(e.target) ||
        optionsRef.current?.contains(e.target)
      ) {
        return;
      }

      isOpenUnit.setState(false);
    },
    [isOpenUnit],
  );

  useLayoutEffect(() => {
    if (!isOpen || !ref.current) return;

    updateOptionsStyle();

    const scrollableAncestors = getScrollableAncestors(ref.current);

    scrollableAncestors.forEach(x => {
      x.addEventListener('scroll', handleAncestorScroll);
    });

    document.body.addEventListener('click', handleDocumentBodyClick);

    const unsubscribe = stickFixedPositionElement({
      updatePosition: updateOptionsStyle,
    });

    return () => {
      scrollableAncestors.forEach(x => {
        x.removeEventListener('scroll', handleAncestorScroll);
      });

      document.body.removeEventListener('click', handleDocumentBodyClick);

      unsubscribe();
    };
  }, [
    handleAncestorScroll,
    handleDocumentBodyClick,
    isOpen,
    updateOptionsStyle,
  ]);

  useEffect(() => {
    if (isOpen) {
      optionsRef.current?.focus();
    }
  }, [isOpen]);

  return (
    <>
      {Button ? (
        <Button
          forwardedRef={ref}
          counter={React.Children.toArray(children).length}
          expanded={isOpen}
          onClick={handleClick}
        />
      ) : (
        <button
          className={b({ variant, size, expanded: isOpen }, [className])}
          ref={ref}
          onClick={handleClick}
        >
          <Icon className={b('icon')} />
        </button>
      )}
      {isOpen &&
        createPortal(
          <ul
            className={b('list')}
            ref={optionsRef}
            tabIndex={-1}
            onKeyDown={handleListKeyDown}
            onClick={handleListClick}
          >
            {children}
          </ul>,
          document.body,
        )}
    </>
  );
}

export const Component = ExtraMenu;
