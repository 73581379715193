import React from 'react';

import { block } from 'utils/classname';

import './style.scss';

export * as TitleLeftColumn from './TitleLeftColumn';
export * as ControlsRightColumn from './ControlsRightColumn';

const b = block('list-two-column-header');

type Props = {
  LeftPart: React.FC;
  backgroundColor: 'accent-0';
  RightPart?: React.FC;
  className?: string;
};

function TwoColumnHeader({
  LeftPart,
  backgroundColor,
  RightPart,
  className,
}: Props) {
  return (
    <div className={b({ 'background-color': backgroundColor }, [className])}>
      <LeftPart />
      {RightPart && <RightPart />}
    </div>
  );
}

export const Component = React.memo(TwoColumnHeader);

export const makeComponent = (props: Props) => () => <Component {...props} />;
