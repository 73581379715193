import React from 'react';

import { block } from 'utils/classname';

const b = block('enabled-project-file-type-icon');

export type Props = {
  marks?: 1 | 2 | 3;
  title?: string;
  className?: string;
};

function EnabledProjectFileTypeIcon({ marks = 2, title, className }: Props) {
  return (
    <svg
      className={b({}, [className])}
      width="36"
      height="48"
      viewBox="0 0 36 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {title && <title>{title}</title>}
      <g opacity="0.5">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.81861 0.973159C8.81861 0.435698 9.25431 0 9.79177 0H10.4138H23.9002H24.5222C25.0597 0 25.4953 0.435698 25.4953 0.973159V1.63711C25.4953 2.17457 25.0597 2.61027 24.5222 2.61027H23.9002V12.3907H23.9154L35.3947 38.3556C37.4018 42.8956 34.0776 48 29.1136 48H6.8738C2.06878 48 -1.25076 43.1922 0.451936 38.6989L10.4138 12.4108V2.61027H9.79177C9.2543 2.61027 8.81861 2.17457 8.81861 1.63711V0.973159Z"
          fill="#9DE2E6"
        />
      </g>
      {Array.from({ length: marks }, (x, i) => (
        <path
          key={i}
          className={b('mark')}
          d={`M24.1798 ${5.26904 + 3.92432 * i}H18.2934`}
          stroke="#676677"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      ))}
      <path
        opacity="0.2"
        d="M7.13453 44.439C4.79186 44.439 3.17033 42.0992 3.9929 39.9057L7.63428 30.1953H28.1096L32.285 39.7389C33.2548 41.9557 31.6307 44.439 29.211 44.439H7.13453Z"
        fill="#676677"
      />
      <ellipse
        cx="21.1062"
        cy="37.705"
        rx="2.15226"
        ry="2.15226"
        fill="white"
      />
      <circle cx="15.6225" cy="30.1957" r="3.56093" fill="white" />
    </svg>
  );
}

export const Component = React.memo(EnabledProjectFileTypeIcon);
