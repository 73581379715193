import React from 'react';

import { ExpansionPanel, TextInput, IconContainer } from 'components';
import { StepContext } from 'features/project/Constructor/FormWithSteps/StepContext';
import i18nData from 'features/project/Constructor/i18n.json';
import { ImageInput } from 'features/project/Constructor/subfeatures';
import { I18n } from 'services';
import roundedArrowDownIconSrc from 'shared/images/roundedArrowDown.svg';
import settingsIconSrc from 'shared/images/settings.svg';
import trashIconSrc from 'shared/images/trash.svg';
import * as M from 'types/serverModels';
import { FormElementState } from 'utils/FormState';
import { block } from 'utils/classname';
import {
  createRequiredContext,
  useRequiredContext,
} from 'utils/react/RequiredContext';

import './style.scss';

const b = block('variant-expansion-panel-header');

type HeaderDependencies = {
  imageFormElementState: FormElementState<M.ImageInfo | null>;
  textFormElementState: FormElementState<string>;
  onMoveUp(): void;
  onMoveDown(): void;
  onRemove(): void;
};

const imageData: M.ImageData = {
  container: 'data',
  thumb: [{ code: 'icon', mode: 3, size: 32 }],
};

export const DependenciesContext = createRequiredContext<HeaderDependencies>();

function Header({
  ExpandTriggerArea,
  panelExpandedUnit,
}: ExpansionPanel.HeaderProps) {
  const {
    textFormElementState,
    imageFormElementState,
    onMoveUp,
    onMoveDown,
    onRemove,
  } = useRequiredContext(DependenciesContext);
  const text =
    I18n.useText(i18nData).steps.shared.questions.list.variantSelection;

  const panelExpanded = panelExpandedUnit.useState();

  const { step } = useRequiredContext(StepContext);

  return (
    <div className={b()}>
      <ImageInput.Component
        formElementState={imageFormElementState}
        imageData={imageData}
        className={b('image-input')}
        variant="icon"
      />
      <TextInput.Component
        className={b('text-input')}
        formElementState={textFormElementState}
        placeholder={text.variantTextInputPlaceholder}
        errorRows={1}
      />
      <div className={b('controls')}>
        {step.key !== 'conclusions' && (
          <ExpandTriggerArea>
            <IconContainer.Component
              size="s"
              backgroundDarknessLevel={panelExpanded ? 3 : 2}
            >
              <img
                src={settingsIconSrc}
                alt="expand"
                className={b('expand-icon')}
              />
            </IconContainer.Component>
          </ExpandTriggerArea>
        )}
        <div className={b('move-controls')}>
          <IconContainer.Component
            backgroundDarknessLevel={2}
            size="s-half"
            onClick={onMoveUp}
          >
            <img
              className={b('arrow-up-icon')}
              src={roundedArrowDownIconSrc}
              alt="arrow-up"
            />
          </IconContainer.Component>
          <IconContainer.Component
            backgroundDarknessLevel={2}
            size="s-half"
            onClick={onMoveDown}
            className={b('arrow-down-icon-container')}
          >
            <img
              src={roundedArrowDownIconSrc}
              alt="arrow-down"
              className={b('arrow-down-icon')}
            />
          </IconContainer.Component>
        </div>
        <IconContainer.Component
          size="s"
          backgroundDarknessLevel={2}
          onClick={onRemove}
          className={b('delete-icon-container')}
        >
          <img src={trashIconSrc} className={b('delete-icon')} alt="remove" />
        </IconContainer.Component>
      </div>
    </div>
  );
}

export const Component = React.memo(Header) as typeof Header;
