import { makeFeature } from 'services/Feature';

import i18nData from './i18n.json';

export const feature = makeFeature({
  name: 'SignUpComplete',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "sign-up-complete" */
      './Component'
    ),
  i18nData,
  i18nSharedReferences: null,
  subfeatures: [],
});

export const Component = feature.Component;
