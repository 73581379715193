import React from 'react';

import { Page } from 'services';
import * as M from 'types/serverModels';

import { routeTree } from '../../routeTree';
import * as features from './features';

type Props = {
  user: M.User;
};

function PersonalAccount({ user }: Props) {
  return <features.PersonalAccount.Component user={user} />;
}

export const Component = Page.makePage({
  path: routeTree.LANG['personal-account']['personal-account'].getPath(),
  routeProps: { exact: true },
  scrollTo: 'top-on-mount',
  Component: React.memo(PersonalAccount),
  features: Object.values(features).map(x => x.feature),
});
