import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  experiment: M.UUID;
  position: number;
};

export const getExperiment = makeService<InputData, M.RawExperiment>(
  'get_experiment',
  'get',
);
