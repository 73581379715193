import { I18n } from 'services';
import * as M from 'types/serverModels';

export type SectionTrackObject = Pick<
  | M.CourseStreamServiceSectionTrackObjectOutput
  | M.CourseStreamServiceSubsectionTrackObjectOutput,
  'type' | 'abstract'
>;

export function getSectionTrackObjectAbstractMultilingStrings(
  trackObject: SectionTrackObject,
  t: ReturnType<typeof I18n.useGetMultilingTranslation>,
) {
  return [trackObject.abstract].filter(
    (x): x is M.MultilingString => !!(x && t(x)),
  );
}

export function formatSectionTrackObjectAbstract(
  trackObject: SectionTrackObject,
  t: ReturnType<typeof I18n.useGetMultilingTranslation>,
) {
  return getSectionTrackObjectAbstractMultilingStrings(trackObject, t).reduce(
    (acc, x) => (acc ? acc : t(x)),
    '',
  );
}
