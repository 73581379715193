import React, { useMemo } from 'react';

import { QuestionSelect } from 'features/project/Constructor/subfeatures';
import { widgetTypeToQuestionKindMapping } from 'shared/constants';
import { makeDerivedUnit } from 'utils/State';
import { block } from 'utils/classname';

import {
  questionIDToQuestionTextUnit,
  questionsUnit,
} from '../../../../InputDataForm/units/instances';
import { DataSettingsProps } from '../../../types';
import { FilesListInstance } from '../types';
import './style.scss';

const b = block('texts-list-data-settings');

const questionKinds = widgetTypeToQuestionKindMapping.filesList;

function DataSettings({ instance }: DataSettingsProps<FilesListInstance>) {
  const questionIDsUnit = useMemo(
    () =>
      makeDerivedUnit(questionsUnit).getUnit(questions =>
        questions
          .filter(x => questionKinds.includes(x.questionKind))
          .map(x => x.id),
      ),
    [],
  );

  return (
    <div className={b()}>
      <QuestionSelect.Component
        questionIDsUnit={questionIDsUnit}
        questionIDToQuestionTextUnit={questionIDToQuestionTextUnit}
        selectedQuestionIDState={instance.questionID}
      />
    </div>
  );
}

export const Component = React.memo(DataSettings);
