import { useMemo } from 'react';

import { I18n } from 'services';
import { PROJECT_BOOK_TASK_PRIORITY } from 'shared/constants';
import * as M from 'types/serverModels';

type References = Record<M.ProjectBookTaskPriority, I18n.EntryReference>;

export function usePriorityText(references: References) {
  const highText = I18n.useReference(
    references[PROJECT_BOOK_TASK_PRIORITY.high],
  );
  const mediumText = I18n.useReference(
    references[PROJECT_BOOK_TASK_PRIORITY.medium],
  );
  const lowText = I18n.useReference(references[PROJECT_BOOK_TASK_PRIORITY.low]);

  const text = useMemo(() => {
    return {
      [PROJECT_BOOK_TASK_PRIORITY.high]: highText,
      [PROJECT_BOOK_TASK_PRIORITY.medium]: mediumText,
      [PROJECT_BOOK_TASK_PRIORITY.low]: lowText,
    };
  }, [highText, mediumText, lowText]);

  return text;
}
