import * as M from 'types/serverModels';

import { makeService } from '../../utils';

type InputData = {
  uuid: M.UUID;
};

type APIOutputData = {
  project_assignment_results: M.ProjectAssignmentResult[];
  data: M.ProjectAssignmentResultData[];
  conclusions: M.ProjectAssignmentResultConclusions[];
};

type OutputData = {
  projectAssignmentResults: M.ProjectAssignmentResult[];
  data: Record<M.UUID, M.ProjectAssignmentResultData>;
  conclusions: Record<M.UUID, M.ProjectAssignmentResultConclusions>;
};

const convertOutputData = (output: APIOutputData): OutputData => {
  return {
    projectAssignmentResults: output.project_assignment_results,
    data: output.data.reduce((acc, x) => ({ ...acc, [x.uuid]: x }), {}),
    conclusions: output.conclusions.reduce(
      (acc, x) => ({ ...acc, [x.uuid]: x }),
      {},
    ),
  };
};

export const get = makeService<InputData, APIOutputData, OutputData>(
  ({ uuid }) => `project_assignment/${uuid}/results`,
  'api-v2-get',
  false,
  convertOutputData,
);
