import { Preloader } from 'components';
import { makeFeature } from 'services/Feature';

import i18nData from './i18n.json';

export { makeIsOpenUnit, useIsOpenUnit } from 'components/Modal';

export const feature = makeFeature({
  name: 'ConfirmActionModal',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "confirm-action-modal" */
      './Component'
    ),
  Preloader: Preloader.variants.Empty.Component,
  i18nData,
  i18nSharedReferences: null,
  subfeatures: [],
});

export const Component = feature.Component;

export type { Props } from './types';
