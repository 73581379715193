import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {};

type APIOutputData = Omit<M.PrivateMessagePartner, 'unread'>;

function convertPrivateMessagePartners(
  data: APIOutputData[],
): M.PrivateMessagePartner[] {
  return data.map(x => ({ ...x, unread: null }));
}

export const privateMessagePartners = makeService<
  InputData,
  APIOutputData[],
  M.PrivateMessagePartner[]
>('private_message_partners', 'get', true, convertPrivateMessagePartners);
