import { makeSharedEntryReference } from 'services/I18n/makeEntryReference';

export const userTabReferences = {
  myProfile: makeSharedEntryReference(data => data.user.tabs.myProfile),
  personalAccount: makeSharedEntryReference(
    data => data.user.tabs.personalAccount,
  ),
  perks: makeSharedEntryReference(data => data.user.tabs.perks),
  myAssignments: makeSharedEntryReference(data => data.user.tabs.myAssignments),
  myProjectBooks: makeSharedEntryReference(
    data => data.user.tabs.myProjectBooks,
  ),
  myProjects: makeSharedEntryReference(data => data.user.tabs.myProjects),
  myTests: makeSharedEntryReference(data => data.user.tabs.myTests),
  myCourses: makeSharedEntryReference(data => data.user.tabs.myCourses),
  myGroups: makeSharedEntryReference(data => data.user.tabs.myGroups),
  mySensors: makeSharedEntryReference(data => data.user.tabs.mySensors),
  package: makeSharedEntryReference(data => data.user.tabs.package),
  settings: makeSharedEntryReference(data => data.user.tabs.settings),
  signOut: makeSharedEntryReference(data => data.user.tabs.signOut),
};
