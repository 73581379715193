import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {};

export const storeLicenseList = makeService<InputData, M.License[]>(
  'store_license_list',
  'get',
);
