import randomColor from 'randomcolor';

import * as M from 'types/serverModels';
import { FormElementState, makeFormElementState } from 'utils/FormState';
import { makeDerivedUnit } from 'utils/State';

import {
  QuestionForLayerWithVariants,
  LayerWithColoredMarkersOrIcons,
  MarkerMode,
  VariantColor,
  VariantIcon,
} from '../types';

const colorStateCache: Record<string, FormElementState<string>> = {};
const iconStateCache: Record<string, FormElementState<M.ImageInfo | null>> = {};

export function withColoredMarkersOrIcons(
  question: QuestionForLayerWithVariants,
  id: string,
): LayerWithColoredMarkersOrIcons {
  return {
    kind: 'colored-markers/icons',
    id,
    question,
    settings: {
      mode: makeFormElementState<MarkerMode>('colored-markers'),
      color: {
        markerShape: makeFormElementState<M.MapGraphicName>('circle'),
        variantsColors: makeDerivedUnit(question.variants).getUnit(variants => {
          return variants.map((x): VariantColor => {
            if (colorStateCache[x.id] === undefined) {
              colorStateCache[x.id] = makeFormElementState(randomColor());
            }

            return {
              color: colorStateCache[x.id],
              variantID: x.id,
            };
          });
        }),
      },
      icons: {
        variantsIcons: makeDerivedUnit(question.variants).getUnit(variants => {
          return variants.map((x): VariantIcon => {
            if (iconStateCache[x.id] === undefined) {
              iconStateCache[x.id] = makeFormElementState(
                x.image.units.value.getState(),
              );
            }
            return {
              icon: iconStateCache[x.id],
              variantID: x.id,
            };
          });
        }),
      },
    },
  };
}
