import * as M from 'types/serverModels';

import { makeService } from '../utils';

type PathInputData = {
  uuid: M.UUID;
  sort?: {
    property: keyof M.ProjectAssignmentChange;
    direction: 'ASC' | 'DESC';
  };
};

type InputData = PathInputData;

type APIOutputData = {
  project_assignment_changes: M.ProjectAssignmentChange[];
  count: number;
};

export const changes = makeService<InputData, APIOutputData>(
  ({ uuid, sort }, convertToSearchParams) =>
    `project_assignment/${uuid}/changes/?${convertToSearchParams({ sort })}`,
  'api-v2-get',
  false,
);
