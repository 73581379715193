import { makePrimaryUnit } from 'utils/State';

import { makeSocketUnit } from './makeSocketUnit';

export const isClientScriptLoadedUnit = makePrimaryUnit<boolean>(false);

export const {
  socketUnit: userSocketUnit,
  isSocketConnectedUnit: isUserSocketConnectedUnit,
} = makeSocketUnit({
  module,
});
