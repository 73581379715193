import { makeDerivedUnit, makePrimaryUnit } from 'utils/State';

import { instancesUnit } from './instances';
import { modesUnit } from './modes';

export const allWidgetsAreSavedUnit = makeDerivedUnit(modesUnit).getUnit(
  modes => modes.every(x => x === 'preview'),
);

export const oneWidgetInvariantUnit = makeDerivedUnit(instancesUnit).getUnit(
  instances =>
    instances.filter(x => x.kind !== 'select-instance-kind').length >= 1,
);

export const progressInvariantUnitsUnit = makePrimaryUnit([
  allWidgetsAreSavedUnit,
  oneWidgetInvariantUnit,
]);
