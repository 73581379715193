import * as API from 'services/API';
import * as M from 'types/serverModels';

import { Subscriber } from './types';

const queue: M.PendingMessage[] = [];
let delivered: M.LoadedMessage[] = [];
let current: M.PendingMessage | null = null;
let subscribers: Subscriber[] = [];

let isPending = false;

export function subscribe(
  subscriber: Subscriber,
  initialHandler: (data: M.ChatMessage[]) => void,
) {
  subscribers.push(subscriber);

  initialHandler(
    current ? [...delivered, current, ...queue] : [...delivered, ...queue],
  );

  return () => {
    subscribers = subscribers.filter(x => x !== subscriber);
  };
}

export async function sendMessage(message: M.PendingMessage) {
  if (isPending) {
    queue.push(message);

    return;
  }

  isPending = true;

  current = message;

  const data = await API.services.privateMessageWrite.callPromised(
    { data: { to: message.to, body: message.body } },
    { retry: true },
  );

  delivered.push(data);

  if (data) {
    subscribers.forEach(subscriber => {
      subscriber(message.id, data);
    });
  }

  isPending = false;

  if (queue.length > 0) {
    const nextMessage = queue.shift();

    if (nextMessage) {
      current = nextMessage;

      sendMessage(nextMessage);
    }
  } else {
    current = null;
    delivered = [];
  }
}
