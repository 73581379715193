import { API } from 'services';
import * as M from 'types/serverModels';
import { makePrimaryUnit } from 'utils/State';

export const displayedProjectUnit =
  makePrimaryUnit<M.Project['uuid']>('');

export const statisticCallStateUnit =
  API.services.projectStat.makeCallStateUnit();

export const memberCountUnit = makePrimaryUnit(0);

export const resultCountUnit = makePrimaryUnit(0);

export const commentCountUnit = makePrimaryUnit(0);

export const likesCountUnit = makePrimaryUnit(0);
