import { makeDerivedUnit, makeMappingUnitFromUnit } from 'utils/State';

import { Set, SetForRequest } from './types';

export function makeSetForRequest(set: Set): SetForRequest {
  switch (set.kind) {
    case 'for-number-question':
      return {
        kind: 'for-number-question',
        questionID: set.question.id,
        range: {
          from: set.range.from.units.value,
          to: set.range.to.units.value,
        },
      };
    case 'for-single-variant':
      return {
        kind: 'for-single-variant',
        questionID: set.question.id,
        selectedVariantID: set.selectedVariantID.units.value,
      };

    case 'for-multiple-variants':
      return {
        kind: 'for-multiple-variants',
        questionID: set.question.id,
        variantIDs: makeDerivedUnit(
          makeMappingUnitFromUnit(set.variants),
        ).getUnit(variants =>
          variants.filter(x => x.value.units.value).map(x => x.id),
        ),
      };
    case 'without-selected-question':
      return { kind: 'without-selected-question' };
  }
}
