import React, { useCallback, useEffect } from 'react';

import { Switch } from 'components';
import { ProjectWriteContext } from 'features/project/Constructor/FormWithSteps/ProjectWriteContext';
import { EditError } from 'features/project/Constructor/FormWithSteps/modals';
import i18nData from 'features/project/Constructor/i18n.json';
import { ConstructorStepSection } from 'features/project/Constructor/subfeatures';
import { serverProjectDataUnit } from 'features/project/Constructor/units';
import { I18n } from 'services';
import { block } from 'utils/classname';
import { useRequiredContext } from 'utils/react/RequiredContext';

import { cachedStoreInstancesUnit } from '../../../InputDataForm/units/instances';
import { useIsEditAccess } from '../../../shared';
import {
  anonymousQuestionnairesSwitchState,
  privateAccessSwitchState,
} from '../../units';

type Props = {};

function HeaderRightPart() {
  const { isEditAccess, errorCode } = useIsEditAccess();

  const onChangePredicate = useCallback(() => {
    if (!isEditAccess) {
      EditError.errorCodeUnit.setState(errorCode);
      return;
    }
    const cachedStoreInstances = cachedStoreInstancesUnit.getState();
    const serverProject = serverProjectDataUnit.getState();
    const hasAccess =
      cachedStoreInstances.kind !== 'grouped' &&
      !privateAccessSwitchState.getValue() &&
      !serverProject?.charge;

    if (!hasAccess) {
      EditError.errorCodeUnit.setState(
        'anonymousQuestionnairesCannotBeEnabled',
      );
      return;
    }
    anonymousQuestionnairesSwitchState.units.value.setState(
      prevState => !prevState,
    );
  }, [errorCode, isEditAccess]);

  return (
    <Switch.Component
      formElementState={anonymousQuestionnairesSwitchState}
      onChangePredicate={onChangePredicate}
    />
  );
}

const b = block('anonymous-questionnaires-section');

function AnonymousQuestionnaires({}: Props) {
  const text =
    I18n.useText(i18nData).steps.access.sections.anonymousQuestionnaires;

  const { saveProject } = useRequiredContext(ProjectWriteContext);

  useEffect(() => {
    return anonymousQuestionnairesSwitchState.units.value.subscribe({
      name: 'saving-project',
      callback: (newState, prevState) => {
        if (newState !== prevState) {
          saveProject();
        }
      },
    });
  }, [saveProject]);

  return (
    <ConstructorStepSection.Component
      className={b()}
      title={text.title}
      subtitle={text.tooltip}
      HeaderRightPart={HeaderRightPart}
    />
  );
}

export const Component = React.memo(AnonymousQuestionnaires);
