import React from 'react';

import { block } from 'utils/classname';

import './style.scss';

const b = block('icon-avatar');

type Props = {
  Icon: React.VFC;
  color?: 'error' | 'accent-1' | 'accent-4';
  backgroundColor?: 'bg-0';
  className?: string;
};

function IconAvatar({ Icon, color, backgroundColor, className }: Props) {
  return (
    <div
      className={b({ color, 'background-color': backgroundColor }, [className])}
    >
      <Icon />
    </div>
  );
}

export const Component = React.memo(IconAvatar);
