import { useMemo } from 'react';

import { I18n } from 'services';

type References = {
  0: I18n.EntryReference;
  1: I18n.EntryReference;
  2: I18n.EntryReference;
  4: I18n.EntryReference;
  8: I18n.EntryReference;
  24: I18n.EntryReference;
  32: I18n.EntryReference;
};

export function useStatusText(references: References) {
  const text0 = I18n.useReference(references[0]);
  const text1 = I18n.useReference(references[1]);
  const text2 = I18n.useReference(references[2]);
  const text4 = I18n.useReference(references[4]);
  const text8 = I18n.useReference(references[8]);
  const text24 = I18n.useReference(references[24]);
  const text32 = I18n.useReference(references[32]);

  const text = useMemo(() => {
    return {
      0: text0,
      1: text1,
      2: text2,
      4: text4,
      8: text8,
      24: text24,
      32: text32,
    };
  }, [text0, text1, text2, text4, text8, text24, text32]);

  return text;
}
