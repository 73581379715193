import { getProjectLanguage } from 'features/project/Constructor/utils';
import { isRequired } from 'features/project/Constructor/i18nSharedReferences';
import { makeFormElementState } from 'utils/FormState';
import { FormElementState, FormNode } from 'utils/FormState/types';
import {
  AbstractStateUnit,
  makePrimaryUnit,
  PrimaryStateUnit,
} from 'utils/State';
import { nonEmptyString } from 'utils/validators';

import {
  MaterialLanguage,
  MaterialMode,
  SharedInstanceStateConstructor,
  SharedServerUnit,
} from '../types';

export function validateInstance(
  titleStateFormNode: FormNode,
  formNodesUnit: AbstractStateUnit<FormNode[]>,
) {
  const formNodes = formNodesUnit.getState();
  return [titleStateFormNode, ...formNodes]
    .map(x => x.validate())
    .every(x => x);
}

type SharedServerUnitConstructorArgs = {
  titleState: FormElementState<string>;
  explanationState: FormElementState<string>;
  serverIDUnit: PrimaryStateUnit<string | null>;
  isForTeacher: boolean;
  language: MaterialLanguage;
};

export function makeSharedServerUnit(
  args: SharedServerUnitConstructorArgs,
): SharedServerUnit {
  const { explanationState, titleState, serverIDUnit, isForTeacher, language } =
    args;

  return {
    uuid: serverIDUnit.getState() || undefined,
    name: titleState.units.value.getState(),
    description: explanationState.units.value.getState(),
    lang: language === 'project-language' ? getProjectLanguage() : language,
    tutor: isForTeacher,
  };
}

export function makeSharedInstanceStateConstructor({
  serverIDUnit,
  modeUnit,
  title = '',
  explanation = '',
  isForTeacher,
  language,
}: {
  serverIDUnit: PrimaryStateUnit<string | null>;
  modeUnit: PrimaryStateUnit<MaterialMode>;
  title?: string;
  explanation?: string;
  isForTeacher: boolean;
  language: MaterialLanguage;
}): SharedInstanceStateConstructor {
  const titleState = makeFormElementState(title, [nonEmptyString(isRequired)]);

  const explanationState = makeFormElementState(explanation);

  return (
    formNodesUnit: AbstractStateUnit<FormNode[]> = makePrimaryUnit([]),
  ) => {
    return {
      serverIDUnit,
      materialTitle: titleState,
      materialExplanation: explanationState,
      mode: modeUnit,
      validate: () => {
        return validateInstance(titleState.formNode, formNodesUnit);
      },
      isValid: () => {
        return [titleState.formNode, ...formNodesUnit.getState()].every(x =>
          x.isValid(),
        );
      },
      makeSharedServerUnit: () =>
        makeSharedServerUnit({
          explanationState,
          serverIDUnit,
          titleState,
          isForTeacher,
          language,
        }),
    };
  };
}
