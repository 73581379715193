import React, { useRef, useEffect } from 'react';

function ScrollBehaviorInitializer() {
  const savedScrollRestorationRef = useRef<ScrollRestoration>();

  useEffect(() => {
    savedScrollRestorationRef.current = window.history.scrollRestoration;

    window.history.scrollRestoration = 'manual';

    return () => {
      if (savedScrollRestorationRef.current !== undefined) {
        window.history.scrollRestoration = savedScrollRestorationRef.current;
      }
    };
  }, []);

  return null;
}

export const Component = React.memo(ScrollBehaviorInitializer);
