import * as M from 'types/serverModels';

import { makeService } from '../../utils';

type PathInputData = { uuid: Exclude<M.ProjectBook['uuid'], undefined> };

type QueryInputData = {
  sort?: { property: keyof M.ProjectBookShadow; direction: 'ASC' | 'DESC' };
};

type InputData = PathInputData & QueryInputData;

type APIOutputData = {
  project_book_shadows?: Pick<
    M.ProjectBookShadow,
    'assignee' | 'current_stage_number' | 'date_closest' | 'needs_assessment'
  >[];
  users?: M.UserXShort[];
};

type OutputData = {
  projectBookShadows: Pick<
    M.ProjectBookShadow,
    'assignee' | 'current_stage_number' | 'date_closest' | 'needs_assessment'
  >[];
  users: M.UserXShortRecord;
};

function extractResponse(data: APIOutputData): OutputData {
  return {
    projectBookShadows: data.project_book_shadows || [],
    users:
      data?.users?.reduce<M.UserXShortRecord>(
        (acc, x) =>
          x.login !== undefined ? { ...acc, [x.login]: x } : { ...acc },
        {},
      ) || {},
  };
}

export const list = makeService<InputData, APIOutputData, OutputData>(
  (inputData, convertToSearchParams) => {
    const { uuid, sort, ...rest } = inputData;

    return `project_book/${uuid}/shadows/?${convertToSearchParams({
      ...rest,
      sort: JSON.stringify(sort),
    })}`;
  },
  'api-v2-get',
  false,
  extractResponse,
);
