import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  // Tip: any of that should be provided
  uuid?: NonNullable<M.PendingAction['uuid']>;
  group?: M.UUID | M.UUID[];
  project?: M.UUID | M.UUID[];
  state?: '*' | M.PendingAction['state'];
  user?: M.User['login'] | M.User['login'][];
  type?:
    | NonNullable<M.PendingAction['type']>
    | NonNullable<M.PendingAction['type']>[];

  resolve_user?: boolean;
  resolve_group?: boolean;
  resolve_project?: boolean;
};

export const pendingActionList = makeService<InputData, M.PendingAction[]>(
  'pending_action_list',
  'post',
);
