import { makeService } from './utils';

type InputData = {
  uuid: string;
  leader: string;
};

type Output = {};

export const groupRevokeLeader = makeService<InputData, Output>(
  'group_revoke_leader',
  'post',
);
