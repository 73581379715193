import React from 'react';

import { Checkbox, TextArea } from 'components';
import i18nData from 'features/project/Constructor/i18n.json';
import { I18n } from 'services';
import { FormElementState } from 'utils/FormState';
import { block } from 'utils/classname';
import {
  createRequiredContext,
  useRequiredContext,
} from 'utils/react/RequiredContext';

import './style.scss';

const b = block('variant-extension');

type Props = {};

type Dependencies = {
  explanationFormElementState: FormElementState<string>;
  isOwnFormElementState: FormElementState<boolean>;
};

export const DependenciesContext = createRequiredContext<Dependencies>();

const useText = () =>
  I18n.useText(i18nData).steps.shared.questions.list.variantSelection;

const OwnAnswerCheckboxLabel = Checkbox.makeLabel({
  size: 'text-16',
  useLabel: () => useText().ownAnswerCheckboxLabel,
});

function Extension({}: Props) {
  const deps = useRequiredContext(DependenciesContext);
  const text = useText();

  return (
    <div className={b()}>
      <TextArea.Component
        formElementState={deps.explanationFormElementState}
        placeholder={text.variantExplanationInputPlaceholder}
        errorRows={0}
      />
      <Checkbox.Component
        className={b('own-answer-checkbox')}
        Label={OwnAnswerCheckboxLabel}
        formElementState={deps.isOwnFormElementState}
      />
    </div>
  );
}

export const Component = React.memo(Extension) as typeof Extension;
