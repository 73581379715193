import React, { useCallback } from 'react';

import { FileUpload } from 'features/project/Constructor/subfeatures';
import { API } from 'services';

import { FormExtensionProps } from '../../../types';
import { StateInstance } from '../types';

const extensions = ['m4v', 'mp4', 'm4a'];

function FormExtension({ instance }: FormExtensionProps<StateInstance>) {
  const callStateUnit = API.services.fileUpload.useCallStateUnit();
  const call = API.services.fileUpload.useCall(callStateUnit);

  const handleFileUpload = useCallback(
    (file: File) => {
      call({ file, container: 'projects' });
    },
    [call],
  );

  return (
    <FileUpload.Component
      extensions={extensions}
      callStateUnit={callStateUnit}
      urlsState={instance.video}
      withDeleteControl
      onUpload={handleFileUpload}
    />
  );
}

export const Component = React.memo(FormExtension);
