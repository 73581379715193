import React from 'react';

import { Page } from 'services';

import { routeTree } from '../../../routeTree';
import * as features from './features';

type Props = features.Track.Props;

function Track(props: Props) {
  return <features.Track.Component {...props} />;
}

export const path = routeTree.LANG.course.list.COURSE.track.getPath();

export const Component = Page.makePage({
  path,
  routeProps: { exact: false },
  scrollTo: 'none',
  Component: React.memo(Track) as typeof Track,
  features: Object.values(features).map(x => x.feature),
}) as typeof Track;
