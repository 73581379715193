import { I18n } from 'services';
import * as M from 'types/serverModels';

export type UnitTrackObject = Pick<
  M.CourseStreamServiceUnitTrackObjectOutput,
  'type' | 'object' | 'title'
>;

export function getUnitTrackObjectTitleMultilingStrings(
  trackObject: UnitTrackObject,
  t: ReturnType<typeof I18n.useGetMultilingTranslation>,
) {
  return [trackObject.title].filter(
    (x): x is M.MultilingString => !!(x && t(x)),
  );
}

export function formatUnitTrackObjectTitle(
  trackObject: UnitTrackObject,
  t: ReturnType<typeof I18n.useGetMultilingTranslation>,
) {
  return (
    getUnitTrackObjectTitleMultilingStrings(trackObject, t).reduce(
      (acc, x) => (acc ? acc : t(x)),
      '',
    ) ||
    trackObject.object.name ||
    ''
  );
}
