import * as M from 'types/serverModels';

export function convertToMaterialUnitType(
  unit: M.Unit | M.CourseUnit,
): M.MaterialUnitType {
  switch (unit.type) {
    case 'web': {
      if (unit.url) {
        return 'link';
      }

      return 'text';
    }
    case 'interactive':
    case 'presentation': {
      return 'link';
    }
    case 'print': {
      return 'file';
    }
    case 'video': {
      return 'video';
    }
    case 'test':
    case 'project':
    case 'mesch_eom': {
      throw Error('invalid type');
    }
  }
}
