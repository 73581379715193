export function makeDataProxy(obj: any, ancestors: string[] = []): any {
  return new Proxy(obj, {
    get: (target, propKey) => {
      const prop = target[propKey];

      if (prop === null) {
        const seq = [...ancestors, propKey];
        return `No entry for: ${seq.join('.')}`;
      }

      switch (typeof prop) {
        case 'object':
          return makeDataProxy(prop, [...ancestors, propKey as string]);

        case 'string':
          return prop;
      }
    },
  });
}
