import { makeFeature } from 'services/Feature';

export const feature = makeFeature({
  name: 'PresentationCardStatistics',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "presentation-card-statistics" */
      './Component'
    ),
  i18nData: null,
  i18nSharedReferences: null,
  subfeatures: [],
});

export type { Props } from './Component';

export const Component = feature.Component;
