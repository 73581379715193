import { color } from 'd3-color';
import { interpolateRgb, interpolateNumber } from 'd3-interpolate';
import * as R from 'ramda';

import { I18n } from 'services';
import * as TS from 'types';
import * as M from 'types/serverModels';
import { FormElementState } from 'utils/FormState';

import { questionKindToServerLayerWithoutSettingsType } from './questionKindToLayerWithoutSettingsType';
import { LayerWithSelectedQuestion } from './types';

type ScaleRange = {
  from: FormElementState<number | null>;
  to: FormElementState<number | null>;
};

const makeScaleElements = (
  { from, to }: TS.ColorRange,
  scaleRange: ScaleRange,
  pointsNumber: number,
): M.MapQuantitativeLayerScale[] => {
  const iColor = interpolateRgb(from, to);

  const fromNumber = scaleRange.from.units.value.getState()!;
  const toNumber = scaleRange.to.units.value.getState()!;
  const iNumber = interpolateNumber(fromNumber, toNumber);

  return R.range(0, pointsNumber).map((x): M.MapQuantitativeLayerScale => {
    return {
      color: color(iColor(x / (pointsNumber - 1)))!.formatHex(),
      start: Math.round(iNumber(x / pointsNumber) * 100) / 100,
      end: Math.round(iNumber((x + 1) / pointsNumber) * 100) / 100,
    };
  });
};

export function makeServerLayer(
  layer: LayerWithSelectedQuestion,
  language: TS.Language,
): M.MapLayer {
  const makeMultilingString = I18n.makeMultilingStringConstructor(language);

  const layerName = makeMultilingString(layer.question.name.getState());

  switch (layer.kind) {
    case 'colored-markers/icons':
      return layer.settings.mode.units.value.getState() === 'colored-markers'
        ? {
            mode: 'color',
            uuid: layer.id,
            layerName,
            graphicName:
              layer.settings.color.markerShape.units.value.getState(),
            questionId: layer.question.id,
            type: 'qualitative',
            scale: layer.settings.color.variantsColors.getState().map(
              (x): M.MapQualitativeLayerScale => ({
                value: x.variantID,
                display: x.color.units.value.getState(),
              }),
            ),
          }
        : {
            uuid: layer.id,
            layerName,
            questionId: layer.question.id,
            mode: 'icons',
            type: 'qualitative',
            scale: layer.settings.icons.variantsIcons.getState().map(
              (x): M.MapQualitativeLayerScale => ({
                value: x.variantID,
                display: x.icon.units.value.getState()?.thumb[0].url || '',
              }),
            ),
          };
    case 'scale':
      return {
        type: 'quantitative',
        uuid: layer.id,
        graphicName: layer.markerShape.units.value.getState(),
        layerName,
        questionId: layer.question.id,
        scale: makeScaleElements(
          layer.colorRange.units.value.getState(),
          layer.scaleRange,
          layer.pointsNumber.units.value.getState(),
        ),
      };
    case 'without-settings':
      return {
        type: questionKindToServerLayerWithoutSettingsType[layer.question.kind],
        uuid: layer.id,
        questionId: layer.question.id,
        layerName,
      };
  }
}
