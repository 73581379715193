import React from 'react';

import { useRequiredContext } from 'utils/react/RequiredContext';

import { QuestionContext } from '../Context';
import * as EditContent from './EditContent';
import * as PreviewContent from './PreviewContent';

type Props = {};

function Content({}: Props) {
  const { constructorQuestion, cachedInstance } =
    useRequiredContext(QuestionContext);

  const mode = cachedInstance.mode.useState();

  switch (mode) {
    case 'edit':
      return (
        <EditContent.Component
          constructorQuestion={constructorQuestion}
          cachedInstance={cachedInstance}
        />
      );
    case 'preview':
      return (
        <PreviewContent.Component
          constructorQuestion={constructorQuestion}
          cachedInstance={cachedInstance}
        />
      );
  }
}

export const Component = React.memo(Content);
