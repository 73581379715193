import { makeSharedEntryReference } from 'services/I18n/makeEntryReference';

export const downloadLinkReference = makeSharedEntryReference(
  data => data.downloadLink,
);

export const getTheCertificateLinkReference = makeSharedEntryReference(
  data => data.getTheCertificateLink,
);

export const currencyReference = {
  EUR: makeSharedEntryReference(data => data.units.currency.EUR),
  GBP: makeSharedEntryReference(data => data.units.currency.GBP),
  RUR: makeSharedEntryReference(data => data.units.currency.RUR),
  USD: makeSharedEntryReference(data => data.units.currency.USD),
};
