import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  data: {
    user: M.User['login'];
    features: M.Feature['code'][];
  };
};

type Features = M.StoreFeatureListFeatures;

export const storeFeatureList = makeService<InputData, Features>(
  'store_feature_list',
  'post-json',
);
