import { useEffect, useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import { validate } from 'uuid';

import { API, I18n } from 'services';
import { Page } from 'services';

import { ProjectAssignmentRouteTree, routeTree } from '../../routeTree';
import * as ProjectAssignment from './ProjectAssignment';
import * as features from './features';

type Props = {};

const path =
  routeTree.LANG['project-assignment'].list.PROJECT_ASSIGNMENT.getPath();

function Content({}: Props) {
  const params =
    routeTree.LANG[
      'project-assignment'
    ].list.PROJECT_ASSIGNMENT.useRouteParams();
  const projectAssignmentUUID = params ? params.project_assignment : null;

  const callStateUnit = API.services.projectAssignment.get.useCallStateUnit();
  const callState = callStateUnit.useState();
  const call = API.services.projectAssignment.get.useCall(callStateUnit);

  const lang = I18n.activeLangStateUnit.useState();

  const projectAssignmentRouteTree: ProjectAssignmentRouteTree = useMemo(() => {
    return {
      kind: 'project-assignment',
      tree: routeTree.LANG['project-assignment'].list.PROJECT_ASSIGNMENT,
    };
  }, []);

  useEffect(() => {
    if (projectAssignmentUUID && validate(projectAssignmentUUID)) {
      call({ uuid: projectAssignmentUUID });
    }
  }, [call, projectAssignmentUUID]);

  return API.renderCallState(callState, {
    successful: ({ data }) => (
      <ProjectAssignment.Component
        projectAssignmentRouteTree={projectAssignmentRouteTree}
        projectAssignment={data.project_assignment}
        users={data.users}
      />
    ),
    error: () => (
      <Redirect to={routeTree.LANG.getPath({ routeParams: { LANG: lang } })} />
    ),
  });
}

export const Component = Page.makePage({
  path,
  scrollTo: 'top-on-mount',
  Component: Content,
  features: Object.values(features).map(x => x.feature),
});
