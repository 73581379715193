import * as M from 'types/serverModels';

import { BasketState } from '../../types';

export function convertToData(state: BasketState): M.Basket {
  return {
    ...state,
    contents: state.contents.map(x => {
      const { key, ...model } = x;

      return {
        ...model,
        qty: model.qty.getState(),
      };
    }),
  };
}
