import { PartialDeep } from 'type-fest';

import { Filter, Sort } from 'features/project/Constructor/subfeatures';
import { I18n } from 'services';
import * as M from 'types/serverModels';
import { FormElementState, makeFormElementState } from 'utils/FormState';
import { makePrimaryUnit } from 'utils/State';
import { nonNull } from 'utils/validators';

import {
  ConstructorWidgetConfigForFindingsWidget,
  QuestionID,
  WidgetMode,
} from '../../types';
import { makeSharedInstancePart } from '../makeSharedInstancePart';
import { SharedConstructorArgs } from '../shared/SharedConstructorArgs';
import * as DataSettings from './DataSettings';
import * as View from './View';
import { TextsListInstance } from './types';

type ServerWidgetConstructorArgs = {
  selectedQuestionIDState: FormElementState<QuestionID | null>;
  filter: Filter.State;
  sort?: Sort.State;
};

export function makeServerWidget(
  args: ServerWidgetConstructorArgs,
): PartialDeep<M.TextsListWidget> {
  const { filter, sort, selectedQuestionIDState } = args;
  const selectedQuestionID = selectedQuestionIDState.units.value.getState();

  return {
    type: 'textslist',
    descriptor: {
      filter: Filter.makeServerFilterFromState(filter),
      sort: sort && Sort.makeServerFilterFromState(sort),
      questionId: selectedQuestionID || undefined,
    },
  };
}

function makeInstance({
  initialQuestionID = null,
  initialSortState,
  ...sharedArgs
}: {
  id: string;
  initialQuestionID?: QuestionID | null;
  initialFilterState?: Filter.InitialState;
  initialMode?: WidgetMode;
} & SharedConstructorArgs): TextsListInstance {
  const questionIDState = makeFormElementState<null | QuestionID>(
    initialQuestionID,
    [nonNull(I18n.constants.emptyReference)],
  );

  return {
    ...makeSharedInstancePart({
      ...sharedArgs,
      formEntitiesUnit: makePrimaryUnit([questionIDState]),
    }),
    kind: 'textsList',
    id: sharedArgs.id,
    questionID: questionIDState,
    sort: Sort.makeState(initialSortState),
    makeServerWidget() {
      return makeServerWidget({
        filter: this.filter,
        sort: this.sort,
        selectedQuestionIDState: questionIDState,
      });
    },
  };
}

export const constructorWidgetConfig: ConstructorWidgetConfigForFindingsWidget<
  'textsList',
  TextsListInstance,
  typeof makeInstance
> = {
  key: 'textsList',
  icon: 'textsList',
  makeInstance,
  DataSettings: DataSettings.Component,
  View: View.Component,
  viewHeight: 500,
};

export type { TextsListInstance };
