import React from 'react';

import { FormElementState } from 'utils/FormState';
import { block } from 'utils/classname';

import * as ErrorMessage from '../../ErrorMessage';
import * as RadioButton from '../Button';
import { ControlConfig } from '../types';
import './style.scss';

export * as Label from './Label';

const b = block('radio-button-group');

export type Layout = 'vertical' | 'horizontal' | 'two-columns';

type Props<Key extends string, State extends Key | null> = {
  Label?: React.FC;
  formElementState: FormElementState<State>;
  keyToControlConfig: Record<Key, ControlConfig>;
  className?: string;
  orientation?: Layout;
  errorRows?: 0 | 1;
  onChangePredicate?: (key: Key) => void;
};

function RadioButtonGroup<Key extends string, State extends Key | null>({
  formElementState,
  keyToControlConfig,
  Label,
  className,
  orientation = 'horizontal',
  errorRows = 1,
  onChangePredicate,
}: Props<Key, State>) {
  const error = formElementState.units.error.useState();

  const renderButtons = () => {
    return Object.entries<ControlConfig>(keyToControlConfig).map(
      ([key, controlConfig]) => {
        return (
          <RadioButton.Component
            key={key}
            className={b('radio')}
            formElementState={formElementState}
            buttonKey={key as Key}
            controlConfig={controlConfig}
            onChangePredicate={onChangePredicate}
          />
        );
      },
    );
  };

  return (
    <div className={b({ orientation }, [className])}>
      <div className={b('labeled-field')}>
        {Label && (
          <div className={b('label')}>
            <Label />
          </div>
        )}
        {orientation === 'two-columns' ? (
          <div
            className={b('two-columns-grid')}
            style={{
              gridTemplateRows: `repeat(${Math.ceil(
                Object.keys(keyToControlConfig).length / 2,
              )}, auto)`,
            }}
          >
            {renderButtons()}
          </div>
        ) : (
          renderButtons()
        )}
      </div>
      {errorRows !== 0 && (
        <ErrorMessage.Component messageReference={error} rows={errorRows} />
      )}
    </div>
  );
}

export const Component = React.memo(
  RadioButtonGroup,
) as typeof RadioButtonGroup;
