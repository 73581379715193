import { widgetTypeToQuestionKindMapping } from 'shared/constants';
import { makeDerivedUnit, makePrimaryUnit } from 'utils/State';
import { VennChart } from 'utils/business';

import {
  enumeratedQuestionInstances,
  questionIDToIndexedQuestionTextUnit,
  questionIDToQuestionKindUnit,
} from '../../../InputDataForm/units/instances';
import { Question } from '../../../shared';

const instancesForVennChartWidget = makeDerivedUnit(
  enumeratedQuestionInstances,
  questionIDToQuestionKindUnit,
).getUnit((questions, questionIDToQuestionKind) =>
  questions.filter(x =>
    Object.values(widgetTypeToQuestionKindMapping.vennChart)
      .flat()
      .includes(questionIDToQuestionKind[x.instance.id]),
  ),
);

export const questionsForVennUnit = makeDerivedUnit(
  instancesForVennChartWidget,
  questionIDToQuestionKindUnit,
  questionIDToIndexedQuestionTextUnit,
).getUnit((instances, questionIDToQuestion, questionIDToQuestionText) =>
  instances.map((x): VennChart.Question => {
    const id = x.instance.id;

    const name = makePrimaryUnit(questionIDToQuestionText[x.instance.id]);

    switch (questionIDToQuestion[x.instance.id]) {
      case 'number':
        return {
          kind: 'with-number',
          id,
          name,
        };

      case 'multiple-variants-selection':
        return {
          kind: 'with-multiple-variants',
          id,
          name,
          variants: (
            x.instance as any as Question.VariantSelection.StateInstance
          ).variants,
        };

      case 'single-variant-selection':
        return {
          kind: 'with-single-variant',
          id,
          name,
          variants: (
            x.instance as any as Question.VariantSelection.StateInstance
          ).variants,
        };
      default:
        throw Error('unexptected question instance');
    }
  }),
);

export const questionIDToQuestionForVennUnit = makeDerivedUnit(
  questionsForVennUnit,
).getUnit<Record<string, VennChart.Question>>(questions =>
  questions.reduce(
    (acc, x) => ({
      ...acc,
      [x.id]: x,
    }),
    {},
  ),
);
