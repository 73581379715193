import debounce from 'debounce';
import * as R from 'ramda';
import React, { useEffect, useMemo } from 'react';

import { ConstructorConfigContext } from 'features/project/Constructor/config/configContext';
import {
  Filter,
  TextsCloudWidgetDatafulView,
} from 'features/project/Constructor/subfeatures';
import { API } from 'services';
import { makeMappingUnit } from 'utils/State';
import { useRequiredContext } from 'utils/react/RequiredContext';

import * as modals from '../../../modals';
import { ViewProps } from '../../../types';
import * as DatalessWidgetLayout from '../../shared/DatalessWidgetLayout';
import { makeEmulateParams } from '../../shared/makeEmulateParams';
import { TextsCloudInstance } from '../types';

function View({
  instance,
  shouldEmulateDataUnit,
  useEmulationSeed,
}: ViewProps<TextsCloudInstance>) {
  const callStateUnit = API.services.data.wcloud.useCallStateUnit();
  const callState = callStateUnit.useState();
  const call = API.services.data.wcloud.useCall(callStateUnit);

  const emulationSeed = useEmulationSeed();

  const filterStateForRequestUnit = useMemo(
    () => Filter.makeFilterStateUnitForRequest(instance.filter),
    [instance.filter],
  );

  const settingsUnit = useMemo(() => {
    return makeMappingUnit({
      questionID: instance.questionID.units.value,
      filter: filterStateForRequestUnit,
      shouldEmulate: shouldEmulateDataUnit,
    });
  }, [
    filterStateForRequestUnit,
    instance.questionID.units.value,
    shouldEmulateDataUnit,
  ]);

  const { getProjectUUID } = useRequiredContext(ConstructorConfigContext);

  useEffect(() => {
    const requestData = (
      settings: ReturnType<(typeof settingsUnit)['getState']>,
      prevSettings?: ReturnType<(typeof settingsUnit)['getState']>,
    ) => {
      const projectUUID = getProjectUUID();

      if (
        projectUUID &&
        settings.questionID &&
        !R.equals(settings, prevSettings) &&
        instance.filter.validate()
      ) {
        call({
          limit: 100,
          question: settings.questionID,
          filter: Filter.makeServerFilter(settings.filter),
          emulate: makeEmulateParams(settings.shouldEmulate, emulationSeed),
        });
      }
    };

    requestData(settingsUnit.getState());

    return settingsUnit.subscribe({
      name: 'data-requester',
      callback: debounce(requestData, 500),
    });
  }, [call, emulationSeed, getProjectUUID, instance.filter, settingsUnit]);

  useEffect(() => {
    return callStateUnit.subscribe({
      name: 'error-modal-opener',
      callback: state => {
        const isError =
          shouldEmulateDataUnit.getState() &&
          state.kind === 'successful' &&
          state.data.length === 0 &&
          Object.values(filterStateForRequestUnit.getState()).every(
            x => x === null,
          );
        if (isError) {
          modals.QuestionsNotSavedOrOtherError.open();
        }
      },
    });
  }, [callStateUnit, filterStateForRequestUnit, shouldEmulateDataUnit]);

  useEffect(() => {
    return instance.questionID.units.value.subscribe({
      name: 'emulation-mode-canceller',
      callback: () => {
        if (shouldEmulateDataUnit.getState()) {
          shouldEmulateDataUnit.setState(false);
        }
      },
    });
  }, [instance.questionID.units.value, shouldEmulateDataUnit]);

  const DatalessView = DatalessWidgetLayout.useViewWithLayout(
    'word-cloud',
    shouldEmulateDataUnit,
  );

  return API.renderCallState(callState, {
    successful: ({ data }) => {
      if (data.length > 0)
        return <TextsCloudWidgetDatafulView.Component data={data} />;

      return <DatalessView />;
    },
    error: () => <DatalessView />,
    initial: () => <DatalessView />,
  });
}

export const Component = React.memo(View);
