import React from 'react';

import { ErrorMessage } from 'components';
import { FormSectionState } from 'utils/FormState';
import { PrimaryStateUnit } from 'utils/State';
import { AnswerVariant } from 'utils/business';

type Props = {
  variantsSectionStateUnit: FormSectionState<
    PrimaryStateUnit<AnswerVariant.AnswerVariant[]>
  >;
};

function VariantsError({ variantsSectionStateUnit }: Props) {
  const variantsSectionError = variantsSectionStateUnit.units.error.useState();
  return (
    <ErrorMessage.Component rows={1} messageReference={variantsSectionError} />
  );
}

export const Component = React.memo(VariantsError);
