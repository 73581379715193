import React, { useMemo } from 'react';

import { TextInput } from 'components';
import { I18n } from 'services';
import { FormElementState, useFormElementState } from 'utils/FormState';
import { parseHTML } from 'utils/react';
import { loginSyntax, nonEmptyString } from 'utils/validators';

import i18nData from './i18n.json';
import { requiredFieldReference } from './i18nSharedReferences';
import { loginInexistenceValidator } from './loginInexistenceValidator';

type Props = {
  formElementState: FormElementState<string>;
};

const Header = TextInput.makeHeaderWithLabel({
  useLabel: () => I18n.useText(i18nData).label,
  useTooltip: () => parseHTML(I18n.useText(i18nData).tooltip),
  color: 'black',
});

export const useFieldState = () => {
  return useFormElementState<string>('', [
    nonEmptyString(requiredFieldReference),
    loginSyntax(I18n.makeEntryReference(i18nData, data => data.error.invalid)),
    loginInexistenceValidator(),
  ]);
};

function LoginInput({ formElementState }: Props) {
  const text = I18n.useText(i18nData);

  const asyncValidationData: TextInput.AsyncValidationData = useMemo(() => {
    return {
      pendingMessage: I18n.makeEntryReference(
        i18nData,
        data => data.pendingMessage,
      ),
      successMessage: I18n.makeEntryReference(
        i18nData,
        data => data.successMessage,
      ),
    };
  }, []);

  return (
    <TextInput.Component
      formElementState={formElementState}
      Header={Header}
      placeholder={text.placeholder}
      asyncValidationData={asyncValidationData}
    />
  );
}

export const Component = React.memo(LoginInput);
