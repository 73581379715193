import { makeFeature } from 'services/Feature';

export const feature = makeFeature({
  name: 'SideTabPanel',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "side-tab-panel" */
      './Component'
    ),
  i18nData: null,
  i18nSharedReferences: null,
  subfeatures: [],
});

export * as TabTextLabel from './TabTextLabel';
export type { LabelProps, Tab } from './types';

export const Component = feature.Component;
