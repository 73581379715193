import { makePrimaryUnit } from 'utils/State';

export const headerUnit = makePrimaryUnit<React.VFC | 'default'>('default');

export const headerContentUnit = makePrimaryUnit<React.VFC | 'default'>(
  'default',
);

export const mobileHeaderContentUnit = makePrimaryUnit<React.VFC | 'default'>(
  'default',
);
