import { I18n } from 'services';
import { useRequiredContext } from 'utils/react/RequiredContext';

import i18nData from '../../../../i18n.json';
import {
  WidgetNavigationControls,
  WidgetDeleteControl,
} from '../../../../subfeatures';
import { QuestionContext } from '../Context';

export const Component = () => {
  const text = I18n.useText(i18nData);

  const { instanceKind, onDelete, onMoveNext, onMovePrev } =
    useRequiredContext(QuestionContext);

  return (
    <>
      <WidgetNavigationControls.Component
        onMoveNext={onMoveNext}
        onMovePrev={onMovePrev}
      />
      <WidgetDeleteControl.Component
        confirmMessage={
          instanceKind === 'question-instance'
            ? text.questions.control.delete.confirmMessage
            : undefined
        }
        onDelete={onDelete}
      />
    </>
  );
};
