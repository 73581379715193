import { API } from 'services';
import { Project } from 'types/serverModels';

import { fillMaterialsSteps } from '../FormWithSteps';
import {
  allMaterialsCallState,
  materialsInNonProjectLanguage,
} from '../FormWithSteps/units';
import { serverMaterialsUnit } from '../units';
import { getProjectLanguage } from './getProjectLanguage';

export function loadAndFillMaterials(project: Project) {
  allMaterialsCallState.setState('pending');
  Promise.all(
    project.units?.map(x => API.services.unitRead.callPromised({ uuid: x })) ??
      [],
  )
    .then(materials => {
      serverMaterialsUnit.setState(
        materials.reduce((acc, x) => ({ ...acc, [x.uuid]: x }), {}),
      );
      fillMaterialsSteps(materials);
      const projectLang = getProjectLanguage();

      materialsInNonProjectLanguage.setState(
        materials.filter(x => x.lang !== projectLang).map(x => x.uuid),
      );
      allMaterialsCallState.setState('successful');
    })
    .catch(() => {
      allMaterialsCallState.setState('error');
    });
}
