import { SharedHash } from 'pages/routeTree';

export const hashes = {
  supportHashTag: `#support-modal`,
  complaintHashTag: `#complaint-modal`,
  signInHashTag: `#sign-in-modal`,
  signUpHashTag: `#sign-up-modal`,
} as const;

export const openingTags: Set<SharedHash> = new Set([
  hashes.supportHashTag,
  hashes.complaintHashTag,
  hashes.signInHashTag,
  hashes.signUpHashTag,
] as const);
