import React, { useCallback, useEffect } from 'react';

import { setVHVariable } from './vh';

type Props = {};

function ViewportInfoInitializer({}: Props) {
  const handleWindowResize = useCallback(() => {
    setVHVariable();
  }, []);

  useEffect(() => {
    setVHVariable();

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [handleWindowResize]);

  return null;
}

export const Component = React.memo(
  ViewportInfoInitializer,
) as typeof ViewportInfoInitializer;
