import { useEffect } from 'react';

import * as M from 'types/serverModels';
import { makeDerivedUnit, PrimaryStateUnit } from 'utils/State';

import * as services from '../services';
import { CallState } from '../types';

const probetypeRefsCallStateUnit =
  services.refLoad.makeCallStateUnit() as PrimaryStateUnit<
    CallState<M.ProbetypeReferenceBook[]>
  >;

const probetypesCallStateUnit = makeDerivedUnit(
  probetypeRefsCallStateUnit,
).getUnit<
  CallState<Record<M.ProbetypeReferenceBook['uuid'], M.ProbetypeReferenceBook>>
>(probetypeRefsCallState => {
  if (probetypeRefsCallState.kind === 'successful') {
    const data = probetypeRefsCallState.data.reduce<
      Record<M.ProbetypeReferenceBook['uuid'], M.ProbetypeReferenceBook>
    >((acc, x) => {
      return {
        ...acc,
        [x.uuid]: x,
      };
    }, {});

    return {
      kind: 'successful',
      data,
    };
  }

  return probetypeRefsCallState;
});

let wasCalled = false;

function useProbetypeRefsCallState() {
  const callState = probetypeRefsCallStateUnit.useState();

  const call = services.refLoad.useCall(
    probetypeRefsCallStateUnit as PrimaryStateUnit<
      CallState<M.ReferenceBook[]>
    >,
  );

  useEffect(() => {
    if (!wasCalled) {
      wasCalled = true;

      call({ ref: 'probetype' });
    }
  }, [callState.kind, call]);
}

export function useCallState() {
  const callState = probetypesCallStateUnit.useState();

  useProbetypeRefsCallState();

  return callState;
}
