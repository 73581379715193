import * as M from 'types/serverModels';

import { makeService } from './utils';

type CommonInputData = {
  type: M.FeedbackType | M.ComplaintType;
  message: string;
  subject_uri?: M.URL;
};

type AuthorizedUserInputData = CommonInputData;
type PublicInputData = CommonInputData & { email: string };

type InputData = AuthorizedUserInputData | PublicInputData;

type OutputData = void;

export const sendFeedback = makeService<InputData, OutputData>(
  'send_feedback',
  'post',
);
