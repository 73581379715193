import * as M from 'types/serverModels';

import { defaults } from './constants';

type Options = {
  user: M.User;
  statusesToDisplay?: Set<M.Course['status']>;
};

export function getParticipantCourses({
  user,
  statusesToDisplay = defaults.statusesToDisplay,
}: Options) {
  return (
    user.courses?.reduce<M.Course[]>((acc, uuid) => {
      const course = user.courseIdx[uuid];

      const isCourseToDisplay =
        course !== undefined && statusesToDisplay.has(course.status);

      if (!isCourseToDisplay) {
        return acc;
      }

      return [...acc, course];
    }, []) || []
  );
}
