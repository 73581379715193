import * as TS from 'types';
import * as M from 'types/serverModels';

export function makeMultilingString(
  stringData: M.MultilingString,
): M.MultilingString {
  return stringData;
}

export function makeMultilingStringConstructor(language: TS.Language) {
  return (value: string) => makeMultilingString([{ lang: language, value }]);
}
