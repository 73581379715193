import React, { useCallback } from 'react';

import { TextInput, IconContainer } from 'components';
import { I18n } from 'services';
import roundedArrowDownIconSrc from 'shared/images/roundedArrowDown.svg';
import trashIconSrc from 'shared/images/trash.svg';
import { block } from 'utils/classname';

import i18nData from '../../../../../../../../i18n.json';
import { TextElementState } from '../../../../types';
import './style.scss';

const b = block('quiz-match-question-form-extension-text-element');

type Props = {
  element: TextElementState;
  onMoveUp(element: TextElementState): void;
  onMoveDown(element: TextElementState): void;
  onRemove(element: TextElementState): void;
};

function Element({ element, onMoveDown, onMoveUp, onRemove }: Props) {
  const text =
    I18n.useText(i18nData).questions.list.match.elements.text.element;

  const handleMoveUp = useCallback(() => {
    onMoveUp(element);
  }, [element, onMoveUp]);

  const handleMoveDown = useCallback(() => {
    onMoveDown(element);
  }, [element, onMoveDown]);

  const handleRemove = useCallback(() => {
    onRemove(element);
  }, [element, onRemove]);

  return (
    <div className={b()}>
      <TextInput.Component
        formElementState={element.text.formElementState}
        placeholder={text.inputPlaceholder}
        errorRows={1}
      />
      <div className={b('controls')}>
        <div className={b('move-controls')}>
          <IconContainer.Component
            tabIndex={0}
            size="s-half"
            backgroundDarknessLevel={2}
            onClick={handleMoveUp}
          >
            <img
              className={b('arrow-up-icon')}
              src={roundedArrowDownIconSrc}
              alt="arrow-up"
            />
          </IconContainer.Component>
          <IconContainer.Component
            tabIndex={0}
            size="s-half"
            backgroundDarknessLevel={2}
            onClick={handleMoveDown}
          >
            <img
              className={b('arrow-down-icon')}
              src={roundedArrowDownIconSrc}
              alt="arrow-down"
            />
          </IconContainer.Component>
        </div>
        <IconContainer.Component
          tabIndex={0}
          size="s"
          backgroundDarknessLevel={2}
          onClick={handleRemove}
        >
          <img src={trashIconSrc} className={b('delete-icon')} alt="remove" />
        </IconContainer.Component>
      </div>
    </div>
  );
}

export const Component = React.memo(Element);
