import React from 'react';

import * as features from './features';

type Props = features.View.Props;

function Webinar(props: Props) {
  return <features.View.Component {...props} />;
}

export const Component = React.memo(Webinar) as typeof Webinar;
