import React from 'react';

import { CircleCounter } from 'components';

import { ProvidedProps } from '../types';

type Props = ProvidedProps & {};

function Circle({ forwardedRef, counter, onClick }: Props) {
  return (
    <CircleCounter.Component
      tag="button"
      forwardedRef={forwardedRef}
      counter={counter}
      active
      onClick={onClick}
    />
  );
}

export const Component = React.memo(Circle);
