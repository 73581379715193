import React, { useCallback } from 'react';

import { Button } from 'components';
import { PayWall } from 'features/global';
import { ConfirmActionModal } from 'features/modals';
import { ConstructorConfigContext } from 'features/project/Constructor/config/configContext';
import { ConstructorStepsContext } from 'features/project/Constructor/steps/stepsContext';
import {
  isPrivateProjectUnit,
  projectReadCallStateUnit,
  projectStatusUnit,
  serverProjectDataUnit,
} from 'features/project/Constructor/units';
import { I18n } from 'services';
import { PROJECT_STATUS, USER_ROLE } from 'shared/constants';
import { userRoleUnit } from 'shared/stateUnits';
import { block } from 'utils/classname';
import { useRequiredContext } from 'utils/react/RequiredContext';

import i18nData from '../../../i18n.json';
import { ProjectWriteContext } from '../../ProjectWriteContext';
import * as modals from '../../modals';
import { StepErrorsDescription } from '../../types';
import { payWallPublicProjectTextReference } from './../../../i18nSharedReferences';
import './style.scss';

const b = block('publish-button');

type Props = {};

const isOpenUnit = ConfirmActionModal.makeIsOpenUnit();

function Publish({}: Props) {
  const text = I18n.useText(i18nData);
  const defaultPublicProjectPayWallText = I18n.useReference(
    payWallPublicProjectTextReference,
  );

  const { callStateUnit, status } = useRequiredContext(ProjectWriteContext);

  const { getProjectUUID } = useRequiredContext(ConstructorConfigContext);
  const { steps } = useRequiredContext(ConstructorStepsContext);

  const projectStatus = projectStatusUnit.useState();
  const isPrivate = isPrivateProjectUnit.useState();

  const userRole = userRoleUnit.useState();

  const publishProject = useCallback(() => {
    const projectUUID = getProjectUUID();
    const userRole = userRoleUnit.getState();
    const serverProjectData = serverProjectDataUnit.getState();

    if (!projectUUID || userRole === null) return;

    if (userRole === USER_ROLE.moderator) {
      if (serverProjectData?.status === PROJECT_STATUS.published) {
        status.call({ uuid: projectUUID, status: PROJECT_STATUS.draft });
        return;
      }
      status.call({ uuid: projectUUID, status: PROJECT_STATUS.published });
      return;
    }

    const isPrivate = !!serverProjectDataUnit.getState()?.private;
    if (isPrivate) {
      status.call({ uuid: projectUUID, status: PROJECT_STATUS.published });
      return;
    }
    status.call({ uuid: projectUUID, status: PROJECT_STATUS.reviewing });
  }, [getProjectUUID, status]);

  const validate = useCallback(() => {
    steps.forEach(step => {
      step.visitedUnit.setState(true);
    });
    const errorsDescriptions = steps
      .map(
        (x): StepErrorsDescription => ({
          stepName: I18n.makeEntryReference(
            i18nData,
            data => data.steps[x.key].title,
          ),
          errors: x.errorsUnit.getState(),
        }),
      )
      .filter(x => x.errors.length > 0);

    const isValid = errorsDescriptions.length === 0;
    if (isValid) {
      isOpenUnit.setState(true);
    } else {
      modals.InvalidForm.errorsDescriptionsUnit.setState(errorsDescriptions);
      modals.InvalidForm.isOpenUnit.setState(true);
    }
  }, [steps]);

  const handleButtonClick = useCallback(() => {
    PayWall.addCheck(
      PayWall.makeCheck({
        featureCode: 'submit_project',
        blockType: 'action',
        blockedText: defaultPublicProjectPayWallText,
        onHaveFeature: () => {
          validate();
        },
      }),
    );
  }, [defaultPublicProjectPayWallText, validate]);

  const callState = callStateUnit.useState();
  const projectReadCallState = projectReadCallStateUnit.useState();

  return (
    <>
      <Button.Component
        className={b()}
        type="button"
        callStateUnit={status.callStateUnit}
        disabled={
          projectReadCallState.kind === 'pending' ||
          callState.kind === 'pending'
        }
        onClick={handleButtonClick}
      >
        {projectStatus === PROJECT_STATUS.published &&
        userRole === USER_ROLE.moderator
          ? text.formContainer.unpublishButtonLabel
          : text.formContainer.publishButtonLabel}
      </Button.Component>
      <ConfirmActionModal.Component
        isOpenUnit={isOpenUnit}
        onYesClick={publishProject}
      >
        {projectStatus === PROJECT_STATUS.published
          ? text.modals.confirmPublish.message.unpublish
          : isPrivate
            ? text.modals.confirmPublish.message.private
            : text.modals.confirmPublish.message.public}
      </ConfirmActionModal.Component>
    </>
  );
}

export const Component = React.memo(Publish);
