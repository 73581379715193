import React, { useEffect } from 'react';

import { Switch } from 'components';
import { ProjectWriteContext } from 'features/project/Constructor/FormWithSteps/ProjectWriteContext';
import { useRequiredContext } from 'utils/react/RequiredContext';

import { isPaywallState } from '../../units/inputState';

type Props = {};

function PaywallSwitch({}: Props) {
  const { saveProject } = useRequiredContext(ProjectWriteContext);

  useEffect(() => {
    return isPaywallState.units.value.subscribe({
      name: 'paywall-switching',
      callback: saveProject,
    });
  }, [saveProject]);

  return <Switch.Component formElementState={isPaywallState} />;
}

export const Component = React.memo(PaywallSwitch);
