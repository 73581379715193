import { I18n } from 'services';
import * as TS from 'types';
import { FormElementState, makeFormElementState } from 'utils/FormState';
import { UnitDebugData, makeDerivedUnit } from 'utils/State';
import {
  makeRangeValidators,
  makeSingleUnitValidator,
  nonNull,
} from 'utils/validators';

import i18nData from '../../../../../../i18n.json';
import {
  QuestionConstructorWidget,
  QuestionStepKey,
  SharedInstanceStateConstructor,
} from '../../types';
import * as FormExtension from './FormExtension';
import * as HeaderExtension from './HeaderExtension';
import * as PreviewModeForm from './PreviewModeForm';
import { ServerQuestion, StateInstance } from './types';

export type { StateInstance };

export const constructorWidget: QuestionConstructorWidget<
  'image',
  StateInstance,
  typeof makeInstance
> = {
  key: 'image',
  icon: 'image',
  makeInstance,
  HeaderExtension: HeaderExtension.Component,
  FormExtension: FormExtension.Component,
  PreviewModeForm: PreviewModeForm.Component,
};

type ServerQuestionConstructorArgs = {
  minImagesState: FormElementState<number | null>;
  maxImagesState: FormElementState<number | null>;
  hasMultipleImagesState: FormElementState<boolean>;
  questionStepKey: QuestionStepKey;
};

export function makeServerQuestion(
  args: ServerQuestionConstructorArgs,
): Partial<ServerQuestion> {
  const {
    maxImagesState,
    minImagesState,
    hasMultipleImagesState,
    questionStepKey,
  } = args;

  switch (questionStepKey) {
    case 'conclusions':
      return {
        type: 'image',
      };
    case 'inputDataForm':
      return {
        type: 'image',
        min: minImagesState.getValue() ?? undefined,
        max: hasMultipleImagesState.getValue()
          ? maxImagesState.getValue() ?? undefined
          : 1,
        control: {
          multiple: hasMultipleImagesState.units.value.getState(),
        },
      };
  }
}

type InitialState = {
  hasMultipleImages: boolean;
  minImages: number | null;
  maxImages: number | null;
};

const rangeErrorReference = I18n.makeEntryReference(
  i18nData,
  data => data.steps.shared.questions.list.image.errors.range,
);

export const rangeValidator = (debugData?: UnitDebugData) =>
  makeSingleUnitValidator(
    (value: unknown): TS.ValidationResult =>
      typeof value === 'number' && value > 0 && value <= 20
        ? { kind: 'valid' }
        : { kind: 'invalid', messageReference: rangeErrorReference },
    undefined,
    debugData,
  );

export function makeInstance(
  makeSharedInstanceState: SharedInstanceStateConstructor,
  initialState: InitialState = {
    hasMultipleImages: false,
    maxImages: 1,
    minImages: 1,
  },
): StateInstance {
  const maxImagesState = makeFormElementState<number | null>(
    initialState.maxImages,
  );
  const minImagesState = makeFormElementState<number | null>(
    initialState.minImages,
  );

  const { fromRangeValidator, toRangeValidator } = makeRangeValidators(
    {
      valueUnit: minImagesState.units.value,
      messageReference: I18n.constants.emptyReference,
    },

    {
      valueUnit: maxImagesState.units.value,
      messageReference: I18n.constants.emptyReference,
    },
    true,
  );

  minImagesState.setValidators(
    rangeValidator(),
    nonNull(I18n.constants.emptyReference),
    fromRangeValidator,
  );

  maxImagesState.setValidators(
    rangeValidator(),
    nonNull(I18n.constants.emptyReference),
    toRangeValidator,
  );

  maxImagesState.units.visited.subscribe({
    name: 'sync-visited-from',
    callback: visited => {
      if (visited && !minImagesState.units.visited.getState()) {
        minImagesState.units.visited.setState(true);
      }
    },
  });

  minImagesState.units.visited.subscribe({
    name: 'sync-visited-to',
    callback: visited => {
      if (visited && !maxImagesState.units.visited.getState()) {
        maxImagesState.units.visited.setState(true);
      }
    },
  });

  const hasMultipleImagesState = makeFormElementState<boolean>(
    initialState.hasMultipleImages,
  );

  const formEntitiesUnit = makeDerivedUnit(
    hasMultipleImagesState.units.value,
  ).getUnit(hasMultipleImages => {
    return hasMultipleImages ? [maxImagesState, minImagesState] : [];
  });

  return {
    kind: 'image',
    hasMultipleImages: hasMultipleImagesState,
    maxImages: maxImagesState,
    minImages: minImagesState,
    makeServerQuestion: (questionStepKey: QuestionStepKey) =>
      makeServerQuestion({
        hasMultipleImagesState,
        maxImagesState,
        minImagesState,
        questionStepKey,
      }),
    ...makeSharedInstanceState(formEntitiesUnit),
  };
}
