import { makeFeature } from 'services/Feature';

export const feature = makeFeature({
  name: 'UserNotificationSettings',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "user-notification-settings" */
      './Component'
    ),
  i18nSharedReferences: null,
  i18nData: null,
  subfeatures: [],
});

export {
  makeFieldsetState,
  setIsFieldsetDisabled,
  getCallInputData,
} from './utils';
export const Component = feature.Component;
