import { pathToRegexp } from 'path-to-regexp';

export function routeMatches(route: string, pathPatternOrURL: string) {
  // NOTE path-to-regexp parses ":" in json search params as parameters, which leads to runtime error
  // basically we don't need to match search params
  const pathPattern = pathPatternOrURL.split('?')[0];

  if (!pathPattern.startsWith('/') && !pathPattern.startsWith('#')) {
    try {
      const { pathname, hostname } = new URL(pathPattern);
      if (hostname === window.location.hostname) {
        return pathToRegexp(pathname, [], { end: false }).exec(route) !== null;
      }
    } catch {
      return false;
    }
    return false;
  }
  return pathToRegexp(pathPattern, [], { end: false }).exec(route) !== null;
}
