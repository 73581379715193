import React from 'react';

import { block } from 'utils/classname';

import * as Card from './Card';
import './style.scss';

const b = block('item-selection-content');

type Data<T extends string> = {
  title: string;
  tooltip?: string;
  iconSrc: string;
  itemKey: T;
};

type Props<T extends string> = {
  data: Data<T>[];
  onSelect(itemKey: T): void;
};

function Content<T extends string>({ data, onSelect }: Props<T>) {
  return (
    <div className={b()}>
      <div className={b('cards')}>
        {data.map(x => {
          return <Card.Component key={x.itemKey} {...x} onSelect={onSelect} />;
        })}
      </div>
    </div>
  );
}

const Component = React.memo(Content) as typeof Content;

export const makeComponent =
  <T extends string>(props: Props<T>) =>
  () => <Component {...props} />;
