import React, { useCallback } from 'react';

import { Button } from 'components';
import { I18n } from 'services';
import { block } from 'utils/classname';

import i18nData from '../../../../../../i18n.json';
import { QuestionExplanation } from '../../../../../../subfeatures';
import { CachedQuestionInstance, QuestionConstructor } from '../../../../types';
import './style.scss';

const b = block('quiz-constructor-preview-content-for-question');

type Props = {
  cachedInstance: CachedQuestionInstance;
  constructorQuestion: QuestionConstructor<any, any, any>;
};

function PreviewContent({ cachedInstance, constructorQuestion }: Props) {
  const stateInstance = cachedInstance.useStateInstance();

  const handleEditButtonClick = useCallback(() => {
    cachedInstance.mode.setState('edit');
  }, [cachedInstance.mode]);

  const text = I18n.useText(i18nData);

  return (
    <div className={b()}>
      <QuestionExplanation.Component
        className={b('question-explanation')}
        kind="html"
        questionExplanation={stateInstance.questionExplanation.formElementState.units.value.useState()}
      />
      <constructorQuestion.PreviewModeForm instance={stateInstance} />
      <Button.Component
        className={b('edit-button')}
        type="button"
        variant="outlined"
        color="secondary"
        onClick={handleEditButtonClick}
      >
        {text.buttons.edit}
      </Button.Component>
    </div>
  );
}

export const Component = React.memo(PreviewContent);
