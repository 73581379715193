import * as TS from 'types';
import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData =
  | {
      course: string;
      lang?: TS.Language;
    }
  | {
      project: string;
      lang?: TS.Language;
    };

export const unitList = makeService<InputData, M.Unit[]>('unit_list', 'get');
