import React, { useEffect, useState } from 'react';

import { Page } from 'services';

import { routeTree } from '../routeTree';
import * as features from './features';

function ArticlePage() {
  const [articleCode, setArticleCode] = useState<string | null>(null);

  useEffect(() => {
    const params = routeTree.LANG.article.CODE.getRouteParams();
    if (params) setArticleCode(params.code);
  }, []);

  return <features.Topic.Component code={articleCode} />;
}

export const Component = Page.makePage({
  path: routeTree.LANG.article.CODE.getPath(),
  routeProps: { exact: true },
  scrollTo: 'top-on-mount',
  Component: React.memo(ArticlePage),
  features: Object.values(features).map(x => x.feature),
});
