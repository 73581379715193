import { I18n } from 'services';
import * as M from 'types/serverModels';

export type QuizTrackObject = Pick<
  M.CourseStreamServiceQuizTrackObjectOutput,
  'type' | 'object' | 'title'
>;

export function getQuizTrackObjectTitleMultilingStrings(
  trackObject: QuizTrackObject,
  t: ReturnType<typeof I18n.useGetMultilingTranslation>,
) {
  return [trackObject.title, trackObject.object.title].filter(
    (x): x is M.MultilingString => !!(x && t(x)),
  );
}

export function formatQuizTrackObjectTitle(
  trackObject: QuizTrackObject,
  t: ReturnType<typeof I18n.useGetMultilingTranslation>,
) {
  return getQuizTrackObjectTitleMultilingStrings(trackObject, t).reduce(
    (acc, x) => (acc ? acc : t(x)),
    '',
  );
}
