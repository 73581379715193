import { FormElementState, makeFormElementState } from 'utils/FormState';

import {
  QuestionConstructorWidget,
  SharedInstanceStateConstructor,
} from '../../types';
import * as HeaderExtension from './HeaderExtension';
import * as PreviewModeForm from './PreviewModeForm';
import { ServerQuestion, StateInstance } from './types';

export type { StateInstance };

export function makeServerQuestion(
  isMultilineState: FormElementState<boolean>,
): Partial<ServerQuestion> {
  return {
    type: isMultilineState.units.value.getState() ? 'text' : 'string',
  };
}

export const constructorWidget: QuestionConstructorWidget<
  'text',
  StateInstance,
  typeof makeInstance
> = {
  key: 'text',
  icon: 'text',
  makeInstance,
  HeaderExtension: HeaderExtension.Component,
  PreviewModeForm: PreviewModeForm.Component,
};

export function makeInstance(
  makeSharedInstanceState: SharedInstanceStateConstructor,
  isMultiline: boolean = false,
): StateInstance {
  const isMultilineState = makeFormElementState<boolean>(isMultiline);

  return {
    kind: 'text',
    isMultiline: isMultilineState,
    makeServerQuestion: () => makeServerQuestion(isMultilineState),
    ...makeSharedInstanceState(),
  };
}
