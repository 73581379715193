import * as M from 'types/serverModels';

import { makeService } from '../utils';

type InputData = {
  uuid: M.UUID;
};

type OutputData = {
  data: M.QuestionnaireData;
};

export const del = makeService<InputData, OutputData, M.QuestionnaireData>(
  ({ uuid }) => `data/${uuid}/`,
  'api-v2-delete',
  false,
  ({ data }) => data,
);
