import { isRequired } from 'features/project/Constructor/i18nSharedReferences';
import * as M from 'types/serverModels';
import { FormElementState, makeFormElementState } from 'utils/FormState';
import { makePrimaryUnit } from 'utils/State';
import { nonEmptyString } from 'utils/validators';

import {
  MaterialConstructorWidget,
  SharedInstanceStateConstructor,
} from '../../types';
import * as FormExtension from './FormExtension';
import { StateInstance } from './types';

export type { StateInstance };

export function makeServerUnit(
  textState: FormElementState<string>,
): Partial<M.WebUnit> {
  return {
    type: 'web',
    text: textState.units.value.getState(),
  };
}

export const constructorWidget: MaterialConstructorWidget<
  'text',
  StateInstance,
  typeof makeInstance
> = {
  key: 'text',
  makeInstance,
  FormExtension: FormExtension.Component,
};

function makeInstance(
  makeSharedInstanceState: SharedInstanceStateConstructor,
  initialText: string = '',
): StateInstance {
  const textState = makeFormElementState(initialText, [
    nonEmptyString(isRequired),
  ]);

  return {
    kind: 'text',
    text: textState,
    makeServerUnit: () => makeServerUnit(textState),
    ...makeSharedInstanceState(makePrimaryUnit([textState.formNode])),
  };
}
