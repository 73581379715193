import { I18n } from 'services';
import * as M from 'types/serverModels';

export type ProjectTrackObject = Pick<
  M.CourseStreamServiceProjectTrackObjectOutput,
  'type' | 'object' | 'abstract'
>;

export function getProjectTrackObjectAbstractMultilingStrings(
  trackObject: ProjectTrackObject,
  t: ReturnType<typeof I18n.useGetMultilingTranslation>,
) {
  return [trackObject.abstract, trackObject.object.about].filter(
    (x): x is M.MultilingString => !!(x && t(x)),
  );
}

export function formatProjectTrackObjectAbstract(
  trackObject: ProjectTrackObject,
  t: ReturnType<typeof I18n.useGetMultilingTranslation>,
) {
  return getProjectTrackObjectAbstractMultilingStrings(trackObject, t).reduce(
    (acc, x) => (acc ? acc : t(x)),
    '',
  );
}
