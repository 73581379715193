import { media } from './constants';
import { Device } from './types';

export function getBreakpointDevice(): Device {
  const match = Object.entries(media).find(([_, query]) => {
    return window.matchMedia(query).matches;
  }) as [device: Device, query: string] | undefined;

  // TIP: it's undefined on border of queries cause of fractional values. You can reproduce it by zoom-in/zoom-out to change your devicePixelRatio and see what happens for desktop-m -> desktop-l.
  return match?.[0] || 'desktop-l';
}
