import { makeService } from './utils';

type InputData = {
  domain: 'user-interest';
  // Tip: term length should be > 0
  term: string;
};

type OutputData = {
  label: string;
  value: string;
}[];

export const tagSuggest = makeService<InputData, OutputData>(
  'tag_suggest',
  'post',
);
