import * as M from 'types/serverModels';

import { LayerWithoutSettings } from './types';

export const questionKindToServerLayerWithoutSettingsType: Record<
  LayerWithoutSettings['question']['kind'],
  M.MapLayerWithoutSettings['type']
> = {
  'multiline-string': 'text',
  'single-line-string': 'text',
  date: 'date',
  figures: 'draw',
  image: 'photo',
  sound: 'sound',
  map: 'map',
  video: 'video',
};
