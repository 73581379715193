import { createRequiredContext } from 'utils/react/RequiredContext';

import { ConstructorCachedInstance } from '../types';

export type QuestionContextData = {
  instanceKind: ConstructorCachedInstance['kind'];
  onDelete(): void;
  onMoveNext(): void;
  onMovePrev(): void;
};

export const QuestionContext = createRequiredContext<QuestionContextData>();
