import { useMemo } from 'react';

import { I18n } from 'services';

type References = {
  0: I18n.EntryReference;
  1: I18n.EntryReference;
  2: I18n.EntryReference;
  4: I18n.EntryReference;
  8: I18n.EntryReference;
};

export function useRoleText(references: References) {
  const text0 = I18n.useReference(references[0]);
  const text1 = I18n.useReference(references[1]);
  const text2 = I18n.useReference(references[2]);
  const text4 = I18n.useReference(references[4]);
  const text8 = I18n.useReference(references[8]);

  const userRoleText = useMemo(() => {
    return {
      0: text0,
      1: text1,
      2: text2,
      4: text4,
      8: text8,
    };
  }, [text0, text1, text2, text4, text8]);

  return userRoleText;
}
