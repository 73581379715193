import * as TS from 'types';

import { activeLangStateUnit } from './activeLangStateUnit';
import { makeDataProxy } from './makeDataProxy';
import { TextOf } from './types';

export function getText<T extends Record<TS.Language, any>>(
  i18nData: T,
): TextOf<T[TS.Language]> {
  return makeDataProxy(i18nData[activeLangStateUnit.getState()]);
}
