import { hydrate, render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { API, PageFeaturesRegistry } from 'services';
import { getCookie } from 'utils/Cookie';

import App from './App';

API.setTicket(getCookie('ticket'));

const prefetchedFeatures = PageFeaturesRegistry.getFeaturesForPath(
  window.location.pathname.replace(
    new RegExp(`^${process.env.RAZZLE_PATH_BASENAME}`),
    '',
  ),
).filter(x => x.prefetched);

Promise.all(prefetchedFeatures.map(x => x.loadComponentModule())).then(() => {
  const renderer = (() => {
    switch (process.env.RAZZLE_BUILD_TYPE) {
      case 'iso': {
        return hydrate;
      }
      case 'spa': {
        return render;
      }
    }
  })();

  renderer?.(
    <BrowserRouter basename={process.env.RAZZLE_PATH_BASENAME}>
      <App />
    </BrowserRouter>,
    document.getElementById('root'),
  );
});

if ((module as any).hot) {
  (module as any).hot.accept();
}
