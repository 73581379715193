import React from 'react';

import { FileQuestion } from 'features/project/Constructor/subfeatures';
import { URLString } from 'types';
import { useFormElementState } from 'utils/FormState';
import { makeUUID } from 'utils/UUID';

import { PreviewModeFormProps } from '../../../types';
import { StateInstance } from '../types';

function PreviewModeForm({ instance }: PreviewModeFormProps<StateInstance>) {
  const fileTypes = instance.fileCodes.units.value.useState();

  const formElementState = useFormElementState<URLString | null>(null);

  return (
    <FileQuestion.Component
      formElementState={formElementState}
      questionUUID={makeUUID()}
      fileTypes={fileTypes || undefined}
    />
  );
}

export const Component = React.memo(PreviewModeForm);
