import React from 'react';

import { makeFormElementState } from 'utils/FormState';
import { nonEmptyString } from 'utils/validators';

import { questionIsRequired } from '../i18nSharedReferences';

export const makeState = (initialValue: string = '') => {
  const formElementState = makeFormElementState('', [
    nonEmptyString(questionIsRequired),
  ]);
  formElementState.units.value.setState(String(initialValue));

  return formElementState;
};

export const useState = (initialValue?: string) => {
  const [state] = React.useState(() => makeState(initialValue));
  return state;
};
