import React from 'react';

import { b } from '../b';

export type Props = {
  useLabel(): {
    text: string;
    htmlFor: React.LabelHTMLAttributes<HTMLLabelElement>['htmlFor'];
  };
};

export function Label({ useLabel }: Props) {
  const { text, htmlFor } = useLabel();

  return (
    <label className={b('label')} htmlFor={htmlFor}>
      {text}
    </label>
  );
}

export const Component = React.memo(Label) as typeof Label;
