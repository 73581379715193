import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  uuid: M.UUID;
};

export const projectLike = makeService<InputData, number>(
  'project_like',
  'post',
);
