import { I18n } from 'services';
import * as M from 'types/serverModels';

import { currencyReference } from '../i18nSharedReferences';

type Options = {
  currency: M.Currency;
};

export function useCurrencyText({ currency }: Options) {
  return I18n.useGetCurrencyText(currencyReference)({ currency });
}
