import { FindingsSettings } from '../../steps';
import {
  AssociatedWidgetErrorInfo,
  EntityManipulationPermission,
} from '../../types';

function hasAssociatedQuestion(
  widget: FindingsSettings.FindingsWidgetInstance,
  questionID: string,
  answerID: string,
): boolean {
  switch (widget.kind) {
    case 'venn':
      return widget.sets.getState().some(x => {
        if (
          x.kind === 'for-multiple-variants' &&
          x.question.id === questionID
        ) {
          return x.variants
            .getState()
            .some(y => y.value.units.value.getState() && y.id === answerID);
        }

        if (x.kind === 'for-single-variant' && x.question.id === questionID) {
          return x.selectedVariantID.units.value.getState() === answerID;
        }

        return false;
      });

    default:
      return false;
  }
}

export function getAnswerDeletePermission(
  questionID: string,
  answerID: string,
): EntityManipulationPermission {
  const widgetsAssociatedWithQuestion = FindingsSettings.selectedWidgetInstances
    .getState()
    .filter(widget => hasAssociatedQuestion(widget, questionID, answerID));

  if (widgetsAssociatedWithQuestion.length === 0) {
    return { kind: 'granted' };
  }

  return {
    kind: 'denied',
    associatedWidgets: widgetsAssociatedWithQuestion.map(
      (x): AssociatedWidgetErrorInfo => ({
        name: x.title.formElementState.units.value.getState(),
        widgetKey: x.kind,
      }),
    ),
  };
}
