import { MeschStudent, UUID } from 'types/serverModels';

import { makeService } from '../../../../utils';

type InputData = {
  schoolID: UUID;
  classID: UUID;
};

type APIOutputData = {
  student: MeschStudent[];
};

export const get = makeService<InputData, APIOutputData>(
  ({ ticket, schoolID, classID }, convertToSearchParams) =>
    `mesch/school/${schoolID}/class/${classID}/student/?${convertToSearchParams(
      { ticket },
    )}`,
  'api-v2-get',
);
