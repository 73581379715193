import * as M from 'types/serverModels';

export function convertToCourseUnitType(
  type: M.MaterialUnitType,
): M.CourseUnit['type'] {
  switch (type) {
    case 'link':
    case 'text': {
      return 'web';
    }
    case 'file': {
      return 'print';
    }
    case 'video': {
      return 'video';
    }
  }
}
