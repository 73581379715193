import { QuestionKind } from 'types';

import { QuestionKey } from './types';

export const questionKindToQuestionKeyMapping: Record<
  QuestionKind,
  QuestionKey | null
> = {
  'multiline-string': 'text',
  'single-line-string': 'text',
  'multiple-variants-selection': 'variantSelection',
  'single-variant-selection': 'variantSelection',
  date: 'date',
  figures: 'map',
  image: 'image',
  file: null,
  map: 'map',
  number: 'number',
  stream: 'sensorData',
  sound: 'sound',
  video: 'video',
};
