import React from 'react';

import { TextInput } from 'components';
import i18nData from 'features/project/Constructor/i18n.json';
import { I18n } from 'services';

import { FormExtensionProps } from '../../../types';
import { StateInstance } from '../types';

function FormExtension({ instance }: FormExtensionProps<StateInstance>) {
  const text = I18n.useText(i18nData);

  return (
    <TextInput.Component
      formElementState={instance.link}
      placeholder={text.steps.shared.materials.kinds.link.placeholder}
    />
  );
}

export const Component = React.memo(FormExtension);
