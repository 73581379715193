import { QuestionKind } from 'types';

import { CachedQuestionInstance, QuestionKey } from './types';

export function setInstanceQuestionKind(
  cachedInstance: CachedQuestionInstance,
  questionKind: QuestionKind,
) {
  const { doEffect, questionKey } = ((): {
    questionKey?: QuestionKey;
    doEffect?: () => void;
  } => {
    switch (questionKind) {
      case 'date':
        return { questionKey: 'date' };
      case 'figures':
        return {
          questionKey: 'map',
          doEffect: () => {
            cachedInstance.instancesCache.map.shouldShowFiguresOnMap.units.value.setState(
              true,
            );
          },
        };
      case 'file':
        return { questionKey: 'file' };
      case 'image':
        return { questionKey: 'image' };
      case 'map':
        return { questionKey: 'map' };
      case 'multiline-string':
        return {
          questionKey: 'text',
          doEffect: () => {
            cachedInstance.instancesCache.text.isMultiline.units.value.setState(
              true,
            );
          },
        };
      case 'multiple-variants-selection':
        return {
          questionKey: 'variantSelection',
          doEffect: () => {
            cachedInstance.instancesCache.variantSelection.hasMultipleChoice.units.value.setState(
              true,
            );
          },
        };
      case 'number':
        return { questionKey: 'number' };
      case 'single-line-string':
        return { questionKey: 'text' };
      case 'single-variant-selection':
        return { questionKey: 'variantSelection' };
      case 'sound':
        return { questionKey: 'sound' };
      case 'stream':
        return { questionKey: 'sensorData' };
      case 'video':
        return { questionKey: 'video' };
    }
  })();

  if (questionKey) cachedInstance.activeQuestionKeyUnit.setState(questionKey);

  if (doEffect) doEffect();
}
