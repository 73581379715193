import React from 'react';

import i18nData from 'features/project/Constructor/i18n.json';
import { OkModal } from 'features/project/Constructor/subfeatures';
import { I18n } from 'services';
import { block } from 'utils/classname';

const b = block('empty-groups-error-modal');

type Props = {};

export const isOpenUnit = OkModal.makeIsOpenUnit(false);

function EmptyGroups({}: Props) {
  const text = I18n.useText(i18nData).steps.inputDataForm.errors;

  return (
    <OkModal.Component className={b()} isOpenUnit={isOpenUnit}>
      {text.cantDeleteFirstStageBeforeFollowing}
    </OkModal.Component>
  );
}

export const Component = React.memo(EmptyGroups) as typeof EmptyGroups;
