import { makeFeature } from 'services/Feature';

import * as subfeatures from './subfeatures';

export const feature = makeFeature({
  name: 'MainLayout',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "main-layout" */
      './Component'
    ),
  i18nData: null,
  i18nSharedReferences: null,
  subfeatures: Object.values(subfeatures).map(x => x.feature),
  prefetched: true,
});

export {
  getHeaderHeightStyleVariable,
  getStickyPartHeightStyleVariable,
} from './styleVariables';
export {
  stickyPartHeightUnit,
  headerHeightUnit,
  progressBarUnit,
} from './units';
export const Component = feature.Component;
