import React from 'react';

import { routeTree } from 'pages/routeTree';
import { Page } from 'services';

import * as features from './features';

type Props = features.Discussion.Props;

function Discussion(props: Props) {
  return <features.Discussion.Component {...props} />;
}

export const path = routeTree.LANG.project.list.PROJECT.discussion.getPath();

export const Component = Page.makePage({
  path,
  routeProps: { exact: true },
  scrollTo: 'none',
  Component: React.memo(Discussion) as typeof Discussion,
  features: Object.values(features).map(x => x.feature),
}) as typeof Discussion;
