import * as M from 'types/serverModels';

import {
  QuestionConstructorWidget,
  SharedInstanceStateConstructor,
} from '../../types';
import * as PreviewModeForm from './PreviewModeForm';
import { StateInstance } from './types';

export type { StateInstance };

export function makeServerQuestion(): Partial<M.SoundQuestion> {
  return {
    type: 'sound',
  };
}

export const constructorWidget: QuestionConstructorWidget<
  'sound',
  StateInstance,
  typeof makeInstance
> = {
  key: 'sound',
  icon: 'sound',
  makeInstance,
  PreviewModeForm: PreviewModeForm.Component,
};

export function makeInstance(
  makeSharedInstanceState: SharedInstanceStateConstructor,
): StateInstance {
  return {
    kind: 'sound',
    makeServerQuestion,
    ...makeSharedInstanceState(),
  };
}
