import { useMemo } from 'react';

import * as M from 'types/serverModels';
import { msInAHour, msInAMinute, getMSLocaleOffset } from 'utils/date';

export function useIsOutdated(
  webinar: Pick<M.Webinar, 'datetime' | 'tz' | 'duration'>,
) {
  return useMemo(
    () =>
      Date.parse(webinar.datetime) -
        (getMSLocaleOffset() - webinar.tz * msInAHour) +
        webinar.duration * msInAMinute <
      Date.now(),
    [webinar.datetime, webinar.duration, webinar.tz],
  );
}
