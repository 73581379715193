import React from 'react';

import { routeTree } from 'pages/routeTree';
import { Page } from 'services';

import * as features from './features';

type Props = features.Task.Props;

function Task(props: Props) {
  return <features.Task.Component {...props} />;
}

export const path =
  routeTree.LANG['project-assignment'].list.PROJECT_ASSIGNMENT.task.getPath();

export const Component = Page.makePage({
  path,
  scrollTo: 'none',
  Component: React.memo(Task),
  features: Object.values(features).map(x => x.feature),
});
