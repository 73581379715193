import { useEffect } from 'react';

import * as M from 'types/serverModels';
import { makeDerivedUnit, PrimaryStateUnit } from 'utils/State';

import * as services from '../services';
import { CallState } from '../types';

const perkRefsCallStateUnit =
  services.refLoad.makeCallStateUnit() as PrimaryStateUnit<
    CallState<M.PerkReferenceBook[]>
  >;

const perksCallStateUnit = makeDerivedUnit(perkRefsCallStateUnit).getUnit<
  CallState<Record<M.PerkReferenceBook['code'], M.PerkReferenceBook>>
>(perkRefsCallState => {
  if (perkRefsCallState.kind === 'successful') {
    const data = perkRefsCallState.data.reduce<
      Record<M.PerkReferenceBook['code'], M.PerkReferenceBook>
    >((acc, x) => {
      return {
        ...acc,
        [x.code]: x,
      };
    }, {});

    return {
      kind: 'successful',
      data,
    };
  }

  return perkRefsCallState;
});

let wasCalled = false;

function usePerkRefsCallState() {
  const callState = perkRefsCallStateUnit.useState();

  const call = services.refLoad.useCall(
    perkRefsCallStateUnit as PrimaryStateUnit<CallState<M.ReferenceBook[]>>,
  );

  useEffect(() => {
    if (!wasCalled) {
      wasCalled = true;

      call({ ref: 'perk' });
    }
  }, [callState.kind, call]);
}

export function useCallState() {
  const callState = perksCallStateUnit.useState();

  usePerkRefsCallState();

  return callState;
}
