import React, { useMemo } from 'react';
import { Redirect } from 'react-router';

import { API, I18n } from 'services';
import { Page } from 'services';
import { userStateUnit } from 'shared/stateUnits';
import * as TS from 'types';

import { routeTree } from './routeTree';

type Props = {};

function Root({}: Props) {
  const ticket = API.useTicket();
  const userState = userStateUnit.useState();

  const langToRedirect: TS.Language = useMemo(() => {
    if (userState.kind === 'loaded') {
      const userLang = I18n.constants.languages.find(
        l => l === userState.user.language,
      );

      return userLang !== undefined ? userLang : I18n.constants.languages[0];
    } else {
      if (typeof window !== 'undefined') {
        const browserLang = window.navigator.languages.find(
          (l): l is TS.Language =>
            (I18n.constants.languages as string[]).includes(l),
        );

        return browserLang !== undefined
          ? browserLang
          : I18n.constants.languages[0];
      }
    }

    return I18n.constants.languages[0];
  }, [userState]);

  const to = routeTree.LANG.getPath({
    routeParams: {
      LANG: langToRedirect,
    },
  });

  if (process.env.BUILD_TARGET === 'server') {
    return null;
  }

  if (ticket !== null && userState.kind !== 'loaded') {
    return null;
  }

  return <Redirect to={to} />;
}

export const Component = Page.makePage({
  path: '/',
  routeProps: { exact: true },
  scrollTo: 'none',
  Component: React.memo(Root),
  features: [],
});
