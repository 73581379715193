import type { makeFieldsetState } from './makeFieldsetState';

export function setIsFieldsetDisabled(
  fieldsetState: ReturnType<typeof makeFieldsetState>,
  newDisableState: boolean,
) {
  Object.entries(fieldsetState).forEach(([key, fieldsetElementState]) => {
    fieldsetElementState.units.disabled.setState(newDisableState);
  });
}
