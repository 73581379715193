import React from 'react';

import { block } from 'utils/classname';

import './style.scss';

const b = block('constructor-form-container-buttons');

type Props = React.PropsWithChildren<{
  className?: string;
}>;

function Buttons({ children, className }: Props) {
  return <div className={b({}, [className])}>{children}</div>;
}

export const Component = React.memo(Buttons) as typeof Buttons;
