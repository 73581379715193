import React from 'react';

import {
  questionIDToQuestionTextUnit,
  questionsUnit,
} from 'features/project/Constructor/FormWithSteps/steps/InputDataForm/units/instances';
import { ChartWidgetDataSettings } from 'features/project/Constructor/subfeatures';

import { DataSettingsProps } from '../../../../types';
import { Instance } from '../types';

function DataSettings({ instance }: DataSettingsProps<Instance>) {
  return (
    <ChartWidgetDataSettings.Component
      questionsUnit={questionsUnit}
      selectedChartTypeUnit={instance.chartType}
      selectedXAxisUnit={instance.xAxisData}
      selectedYAxisUnit={instance.yAxisData}
      selectedCuttingQuestionUnit={instance.cuttingQuestion}
      selectedPaletteUnit={instance.palette}
      questionIDToQuestionTextUnit={questionIDToQuestionTextUnit}
    />
  );
}

export const Component = React.memo(DataSettings) as typeof DataSettings;
