import * as M from 'types/serverModels';

import { makeService } from '../utils';

type InputData = {
  uuid: M.UUID;
};

export const monitoring = makeService<InputData, M.Monitoring>(
  ({ uuid }) => `group/${uuid}/monitoring`,
  'api-v2-get',
  false,
);
