import * as M from 'types/serverModels';

import { makeService } from '../utils';

type InputData = {
  sort?: {
    property: keyof M.ProjectAssignment;
    direction: 'ASC' | 'DESC';
  };
};

type APIOutputData = {
  project_assignments: M.ProjectAssignment[];
  projects: M.ProjectAssignmentProject[];
  users: M.ProjectAssignmentUser[];
  count: number;
};

type OutputData = M.ProjectAssignmentListItem[];

const convertResponse = (data: APIOutputData): OutputData => {
  const users = data.users.reduce<Record<string, M.ProjectAssignmentUser>>(
    (acc, x) => ({ ...acc, [x.login]: x }),
    {},
  );
  const projects = data.projects.reduce<
    Record<string, M.ProjectAssignmentProject>
  >((acc, x) => ({ ...acc, [x.uuid]: x }), {});

  return data.project_assignments.map(projectAssignment => ({
    ...projectAssignment,
    author: users[projectAssignment.author],
    assignees: projectAssignment.assignees.map(x => users[x]),
    project: projects[projectAssignment.project],
  }));
};

export const list = makeService<InputData, APIOutputData, OutputData>(
  ({ sort }, convertToSearchParams) =>
    `project_assignment/?${convertToSearchParams({ sort })}`,
  'api-v2-get',
  false,
  convertResponse,
);
