import React from 'react';

import { routeTree } from 'pages/routeTree';
import { Page } from 'services';

import * as features from './features';

type Props = features.Quiz.Props;

function Quiz(props: Props) {
  return <features.Quiz.Component {...props} />;
}

export const path =
  routeTree.LANG.course.list.COURSE.quiz.form.QUIZ_UUID.getPath();

export const Component = Page.makePage({
  path,
  scrollTo: 'top-on-mount',
  Component: React.memo(Quiz),
  features: Object.values(features).map(x => x.feature),
});
