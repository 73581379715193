import * as M from 'types/serverModels';

import { makeService } from '../utils';

type PathInputData = {};

type QueryInputData = {};

type InputData = PathInputData & QueryInputData;

type APIOutputData = {
  api_tokens?: (Omit<M.APIToken, 'token' | 'valid'> & { valid?: true })[];
};

type OutputData = {
  apiTokens: (Omit<M.APIToken, 'token' | 'valid'> & { valid?: true })[];
};

function extractResponse(data: APIOutputData): OutputData {
  return {
    apiTokens: data.api_tokens || [],
  };
}

export const get = makeService<InputData, APIOutputData, OutputData>(
  ({ ticket }, convertToSearchParams) =>
    `api_token/?${convertToSearchParams({ ticket })}`,
  'api-v2-get',
  false,
  extractResponse,
);
