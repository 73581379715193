import { makeService } from './utils';

type InputData = {
  uuid: string;
  leader: string;
};

type Output = {};

export const groupGrantLeader = makeService<InputData, Output>(
  'group_grant_leader',
  'post',
);
