import * as TS from 'types';
import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  group: M.Group['uuid'];
  lang: TS.Language;
  role?: M.User['role'];
};

type UsersRating = M.UserFromRating[];

export const groupPerkRating = makeService<InputData, UsersRating>(
  'group_perk_rating',
  'get',
);
