import React from 'react';

import { Page } from 'services';
import { Package } from 'services/API/services/storePackageList';

import { routeTree } from '../../routeTree';
import * as features from './features';

type Props = {
  packages: Package[];
};

function CatalogPage({ packages }: Props) {
  return <features.Catalog.Component packages={packages} />;
}

export const Component = Page.makePage({
  path: routeTree.LANG.shop.catalog.getPath(),
  routeProps: { exact: true },
  scrollTo: 'top-on-mount',
  Component: React.memo(CatalogPage) as typeof CatalogPage,
  features: Object.values(features).map(x => x.feature),
});
