import { makeDerivedUnit, makePrimaryUnit } from 'utils/State';
import { makeUUID } from 'utils/UUID';

import {
  ConstructorWidgetInstance,
  WidgetKey,
  WidgetSelectionInstance,
} from '../types';
import {
  FindingsWidgetInstance,
  widgetKeyToFindingsConstructorWidget,
} from '../widgets';

export const makeSelectionInstance = (): WidgetSelectionInstance => ({
  kind: 'select-instance-kind',
  id: makeUUID(),
});

export const makeInitialInstances = () => [makeSelectionInstance()];

export const instancesUnit = makePrimaryUnit<ConstructorWidgetInstance[]>(
  makeInitialInstances(),
);

export const instancesNumberUnit = makeDerivedUnit(instancesUnit).getUnit(
  instances => instances.length,
);

export const selectedWidgetInstances = makeDerivedUnit(instancesUnit).getUnit(
  instances =>
    instances.filter<FindingsWidgetInstance>(
      (x): x is FindingsWidgetInstance => x.kind !== 'select-instance-kind',
    ),
);

export function makeNewWidgetInstance(
  widgetKey: WidgetKey,
  instanceID: string,
): FindingsWidgetInstance {
  return widgetKeyToFindingsConstructorWidget[
    widgetKey as keyof typeof widgetKeyToFindingsConstructorWidget
  ].makeInstance({
    id: instanceID,
    initialYAxisData:
      widgetKey === 'pie' || widgetKey === 'columnStack'
        ? { kind: 'quantity' }
        : undefined,
  });
}
