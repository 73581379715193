import React from 'react';

import { TextInput } from 'components';
import { I18n } from 'services';
import { block } from 'utils/classname';

import i18nData from '../../../../../i18n.json';
import { FormExtensionProps } from '../../../types';
import { ScoreInput } from '../../shared';
import { StateInstance } from '../types';
import './style.scss';

const b = block('quiz-number-question-form-extension');

function FormExtension({ instance }: FormExtensionProps<StateInstance>) {
  const text = I18n.useText(i18nData);

  return (
    <div className={b()}>
      <TextInput.Component
        formElementState={instance.matching}
        placeholder={text.answerLabel}
        errorRows={0}
      />
      <ScoreInput.Component
        formElementState={instance.score}
        isOnlyPositiveNumbers
      />
    </div>
  );
}

export const Component = React.memo(FormExtension);
