import * as TS from 'types';
import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  lang: TS.Language;
  limit?: number;
  skip?: number;
  sort?: {};
  compact?: boolean;
  filter?: {
    substring?: string;
    role?: M.UserRole[];
    name?: string;
    subjects?: string;
    interests?: string;
    langs?: string;
  };
};

type APIOutputData = M.UserData[];

export const userList = makeService<InputData, APIOutputData>(
  'user_list',
  'get',
);

type AutocompleteOutputData = (M.UserData & {
  label: string;
  value: M.UserData['login'];
})[];

function convertToAutocompleteUserData(
  data: APIOutputData,
): AutocompleteOutputData {
  return (
    data.map(x => ({
      ...x,
      label: x.name ? `${x.name}, ${x.login}` : x.login,
      value: x.login,
    })) || []
  );
}

export const autocompleteUserList = makeService<
  InputData,
  APIOutputData,
  AutocompleteOutputData
>('user_list', 'get', true, convertToAutocompleteUserData);
