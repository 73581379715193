export function getHeaderHeightStyleVariable() {
  return 'var(--header-height)';
}
export function getHeaderHeight({
  innerElement,
}: {
  innerElement: HTMLElement;
}) {
  const styles = getComputedStyle(innerElement);

  const height = styles.getPropertyValue('--header-height');

  return parseFloat(height.trim());
}
