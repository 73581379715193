import React, { CSSProperties } from 'react';

import { ReactComponent as PlusSVGIcon } from 'shared/images/plus.svg';
import { block } from 'utils/classname';

import './style.scss';

const b = block('button-plus-icon');

type Props = { color?: CSSProperties['color'] };

function PlusIcon({ color = 'inherit' }: Props) {
  return <PlusSVGIcon className={b()} style={{ color }} />;
}

export const Component = React.memo(PlusIcon) as typeof PlusIcon;

export const makeComponent = (props: Props) => () => <Component {...props} />;
