import { History } from 'history';

let history: History | null = null;

export function initHistory(value: History | null) {
  history = value;
}

export function getHistory() {
  return history;
}
