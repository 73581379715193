import * as R from 'ramda';

import { Feature } from '../Feature';

export function flattenFeatures(
  features: Array<Feature<any>>,
): Array<Feature<any>> {
  return R.uniqBy(
    x => x.name,
    features.flatMap(
      (x): Array<Feature<any>> => [x, ...flattenFeatures(x.subfeatures)],
    ),
  );
}
