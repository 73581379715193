import { useEffect } from 'react';

import * as M from 'types/serverModels';
import { makeDerivedUnit, PrimaryStateUnit } from 'utils/State';

import * as services from '../services';
import { CallState } from '../types';

type ProjectBookKindReferenceBookCode = M.ProjectBookKindReferenceBook['code'];

const projectBookKindRefsCallStateUnit =
  services.refLoad.makeCallStateUnit() as PrimaryStateUnit<
    CallState<M.ProjectBookKindReferenceBook[]>
  >;

const projectBookKindCallStateUnit = makeDerivedUnit(
  projectBookKindRefsCallStateUnit,
).getUnit<
  CallState<{
    [key in ProjectBookKindReferenceBookCode]: M.ProjectBookKindReferenceBook;
  }>
>(projectBookKindRefsCallState => {
  if (projectBookKindRefsCallState.kind === 'successful') {
    const data = projectBookKindRefsCallState.data.reduce<{
      [key in ProjectBookKindReferenceBookCode]: M.ProjectBookKindReferenceBook;
    }>((acc, x) => {
      return {
        ...acc,
        [x.code]: x,
      };
    }, {});

    return {
      kind: 'successful',
      data,
    };
  }

  return projectBookKindRefsCallState;
});

let wasCalled = false;

function useProjectBookKindRefsCallState() {
  const callState = projectBookKindRefsCallStateUnit.useState();

  const call = services.refLoad.useCall(
    projectBookKindRefsCallStateUnit as PrimaryStateUnit<
      CallState<M.ReferenceBook[]>
    >,
  );

  useEffect(() => {
    if (!wasCalled) {
      wasCalled = true;

      call({ ref: 'pb_kind' });
    }
  }, [callState.kind, call]);
}

export function useCallState() {
  const callState = projectBookKindCallStateUnit.useState();

  useProjectBookKindRefsCallState();

  return callState;
}
