import React from 'react';

import { ReactComponent as AngleArrowDownIcon } from 'shared/images/angleArrowDown.svg';
import { block } from 'utils/classname';

const b = block('select');

type Props = {};

function Icon({}: Props) {
  return (
    <div className={b('active-option-icon')}>
      <AngleArrowDownIcon />
    </div>
  );
}

export const DefaultComponent = React.memo(Icon) as typeof Icon;
