import { makeMultilangFormState } from 'services/I18n';
import * as TS from 'types';
import * as M from 'types/serverModels';
import { makeFormElementState } from 'utils/FormState';
import { ImageInfoUtility } from 'utils/business';

import { AnswerVariant } from './types';

type Args = {
  variant: M.Variant;
  validators?: TS.Validator[];
  getMultilingTranslation: (str: M.MultilingString) => string;
};

export function makeVariantFromServerVariant(args: Args): AnswerVariant {
  const { variant, validators = [], getMultilingTranslation: t } = args;

  const description = (variant as M.Variant)?.description;

  return {
    id: variant.uuid,
    text: makeMultilangFormState(
      makeFormElementState(variant.title ? t(variant.title) : '', validators),
      variant.title,
    ),
    explanation: makeMultilangFormState(
      makeFormElementState(description ? t(description) : ''),
      description,
    ),
    image: makeFormElementState<M.ImageInfo | null>(
      (() => {
        const image = variant.image;
        if (image) {
          return ImageInfoUtility.makeIcon(image.icon, image.uuid);
        }

        return null;
      })(),
    ),
    isOwn: makeFormElementState((variant as M.Variant)?.custom ?? false),
    score: makeFormElementState(variant.score ?? null),
  };
}
