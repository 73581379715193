import { makeDerivedUnit, makeMappingUnitFromUnit } from 'utils/State';

import { Question } from '../../shared';
import { flatCachedInstancesUnit } from './instances';

export const modeUnitsUnit = makeDerivedUnit(flatCachedInstancesUnit).getUnit(
  questions => questions.filter(Question.isQuestionInstance).map(x => x.mode),
);

export const modesUnit = makeMappingUnitFromUnit(modeUnitsUnit);
