import { Get, Merge, SetOptional } from 'type-fest';

import * as TS from 'types';
import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  uuid: M.Course['uuid'];
  lang: TS.Language;
  track_uuid?: Get<M.Course, 'tracks.0.uuid'>;
};

type ResolvedProject = SetOptional<
  Pick<
    M.Project,
    | 'about'
    | 'blog_messages_counter'
    | 'code'
    | 'comments_counter'
    | 'features'
    | 'group_access'
    | 'likes'
    | 'private'
    | 'results_counter'
    | 'status'
    | 'thumb'
    | 'title'
    | 'uuid'
    | 'questions'
    | 'cdate'
  >,
  'questions'
> & {
  author: Record<M.UserData['login'], M.UserXShort>;
  subjects: Record<
    M.ThemeReferenceBook['uuid'],
    { color: string; val: string }
  >;
};

type ResolvedQuestion = M.Question;

type ResolvedUnit = M.Unit;

type ResolvedWidget = M.Widget & {
  projectId: M.Project['uuid'];
  questions: ResolvedQuestion[];
};

type APIOutputData = {
  projects: ResolvedProject[];
  questions: ResolvedQuestion[];
  units: ResolvedUnit[];
  widgets: ResolvedWidget[];
};

type OutputData = Merge<
  APIOutputData,
  {
    projects: Record<M.Project['uuid'], M.Project>;
  }
>;

function extractResponse(data: APIOutputData): OutputData {
  return {
    ...data,
    projects: data.projects.reduce<OutputData['projects']>(
      (acc, x) => ({
        ...acc,
        [x.uuid]: {
          description: [],
          resultWidgets: [],
          keywords: [],
          splash: { large: '' },
          research_aim: [],
          crowdsourcing_justification: [],
          perks: [],
          protocol: [],
          ...x,
          author: Object.entries(x.author).reduce<M.Project['author']>(
            (acc, [key, y]) => ({
              ...acc,
              [key]: {
                ...y,
                avatar: y.avatar || '',
                name: y.name || '',
              },
            }),
            {},
          ),
          supervisor: {},
          subjects: Object.keys(x.subjects),
          questions:
            x.questions?.map(y => ({ ...y, _sensors: undefined })) || [],
        },
      }),
      {},
    ),
  };
}

export const courseProjectUnitList = makeService<
  InputData,
  APIOutputData,
  OutputData
>('course_project_unit_list', 'get', true, extractResponse);
