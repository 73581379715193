import i18nData from 'features/project/Constructor/i18n.json';
import { isRequired } from 'features/project/Constructor/i18nSharedReferences';
import { I18n } from 'services';
import { makeFormElementState } from 'utils/FormState';
import { makeDerivedUnit, makeMappingUnit, makePrimaryUnit } from 'utils/State';
import { nonEmptyString } from 'utils/validators';

import { makeStepVisitedUnit } from '../../utils';

export const problemState = makeFormElementState(
  process.env.RAZZLE_MOCK_CONSTRUCTOR_DATA === 'yes' ? 'problem' : '',
);

export const purposeState = makeFormElementState(
  process.env.RAZZLE_MOCK_CONSTRUCTOR_DATA === 'yes' ? 'purpose' : '',
  [nonEmptyString(isRequired)],
);

export const hypothesisState = makeFormElementState('');
export const rationaleState = makeFormElementState('');

export const hasSomeInputUnit = makeDerivedUnit(
  makeMappingUnit([
    problemState.units.value,
    purposeState.units.value,
    hypothesisState.units.value,
    rationaleState.units.value,
  ]),
).getUnit(inputs => inputs.some(x => x !== ''));

export const progressInvariantUnitsUnit = makePrimaryUnit([
  makeDerivedUnit(purposeState.units.value).getUnit(x => x !== ''),
]);

const purposeErrorReference = I18n.makeEntryReference(
  i18nData,
  data => data.steps.concept.errors.purposeFieldIsRequired,
);

export const errorsUnit = makeDerivedUnit(
  makeMappingUnit([
    makeDerivedUnit(purposeState.units.isValid).getUnit(isValid =>
      isValid ? null : purposeErrorReference,
    ),
  ]),
).getUnit(errors =>
  errors.filter<I18n.EntryReference>(
    (x): x is I18n.EntryReference => x !== null,
  ),
);

export const someFieldIsVisitedUnit = makeDerivedUnit(
  makeMappingUnit([problemState.units.visited, purposeState.units.visited]),
).getUnit(statuses => statuses.some(x => x));

export const visitedUnit = makeStepVisitedUnit();

const errorUnitsNotifierName = 'error-units-notifier';

visitedUnit.subscribe({
  name: errorUnitsNotifierName,
  callback: state => {
    if (state) {
      purposeState.units.visited.setState(true);
      problemState.units.visited.setState(true);
    }
  },
});
