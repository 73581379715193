import * as TS from 'types';
import * as M from 'types/serverModels';

import { makeService } from './utils';

type Sort =
  | { weight: -1 }
  | { publish_date: -1 | 1 }
  | { likes: -1 | 1 }
  | { results_counter: -1 | 1 }
  | Record<string, never>;

type InputData = {
  limit?: number;
  offset?: number;
  lang?: TS.Language;
  substring?: string;
  langs?: string[];
  subjects?: string[];
  ages?: string[];
  features?: string[];
  classification?: string[];
  sort?: Sort;
};

export const projectSearch = makeService<InputData, M.ProjectListProject[]>(
  'project_search',
  'get',
  false,
);
