import { makeDerivedUnit } from 'utils/State';

import { instancesUnit } from './instances';

export const hasSomeInputUnit = makeDerivedUnit(instancesUnit).getUnit(
  instances => {
    return (
      instances.length !== 1 || instances[0].kind !== 'select-instance-kind'
    );
  },
);
