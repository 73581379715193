import React from 'react';

import { Page } from 'services';

import { routeTree } from '../../../routeTree';
import * as features from './features';

type Props = features.Results.Props;

function Results(props: Props) {
  return <features.Results.Component {...props} />;
}

export const path = [
  routeTree.LANG['project-book'].list.PROJECT_BOOK.results.getPath(),
  routeTree.LANG['project-book'].list.PROJECT_BOOK.users.USER.results.getPath(),
];

export const Component = Page.makePage({
  path,
  routeProps: { exact: false },
  scrollTo: 'none',
  Component: React.memo(Results) as typeof Results,
  features: Object.values(features).map(x => x.feature),
}) as typeof Results;
