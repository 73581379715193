import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  qqfile: Blob;
  question: M.UUID;
};

export const dataFileSave = makeService<InputData, M.URL>(
  'data_file_save',
  'post-file',
);
