import * as Header from './Header';
import * as Label from './Label';

export function makeHeaderWithLabel(props: Label.Props) {
  return () => (
    <Header.Component>
      <Label.Component {...props} />
    </Header.Component>
  );
}
