import React from 'react';

import { Page } from 'services';
import { FeatureRef } from 'services/API/services/storeFeatureRef';
import { Package } from 'services/API/services/storePackageList';

import { routeTree } from '../../routeTree';
import * as features from './features';

type Props = {
  packages: Package[];
  featuresRef: FeatureRef;
};

function TariffPage({ packages, featuresRef }: Props) {
  return (
    <features.Tariff.Component packages={packages} featuresRef={featuresRef} />
  );
}

export const Component = Page.makePage({
  path: routeTree.LANG.shop.tariff.UUID.getPath(),
  routeProps: { exact: true },
  scrollTo: 'top-on-mount',
  Component: React.memo(TariffPage) as typeof TariffPage,
  features: Object.values(features).map(x => x.feature),
});
