import { Modal, Preloader } from 'components';
import { makeFeature } from 'services/Feature';

import i18nData from './i18n.json';
import * as i18nSharedReferences from './i18nSharedReferences';
import * as subfeatures from './subfeatures';

export const useIsOpenUnit = Modal.useIsOpenUnit;

export const feature = makeFeature({
  name: 'UsersSelectionModal',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "users-selection-modal" */
      './Component'
    ),
  Preloader: Preloader.variants.Empty.Component,
  i18nSharedReferences: Object.values(
    (() => {
      const { userTabReferences, ...rest } = i18nSharedReferences;

      return [...Object.values(rest), ...Object.values(userTabReferences)];
    })(),
  ),
  i18nData,
  subfeatures: Object.values(subfeatures).map(x => x.feature),
});

export type { Props } from './Component';

export { Header } from 'components/Modal';

export const Component = feature.Component;
