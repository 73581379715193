import { Question } from '../shared';

export const widgets = [
  Question.Text.constructorWidget,
  Question.VariantSelection.constructorWidget,
  Question.Image.constructorWidget,
  Question.Map.constructorWidget,
  Question.Number.constructorWidget,
];

export const config = Question.makeConfig(widgets);
