import * as TS from 'types';
import { AbstractStateUnit, makeDerivedUnit } from 'utils/State';

import { QuestionInstance } from './questions';

export const getQuestionKindFromQuestionInstance = (
  instance: QuestionInstance,
): TS.QuestionKind | AbstractStateUnit<TS.QuestionKind> => {
  switch (instance.kind) {
    case 'date':
      return 'date';
    case 'image':
      return 'image';
    case 'map':
      return makeDerivedUnit(
        instance.shouldShowFiguresOnMap.units.value,
      ).getUnit<TS.QuestionKind>(shouldShowFigures =>
        shouldShowFigures ? 'figures' : 'map',
      );
    case 'number':
      return 'number';
    case 'sensorData':
      return 'stream';
    case 'sound':
      return 'sound';
    case 'text':
      return makeDerivedUnit(
        instance.isMultiline.units.value,
      ).getUnit<TS.QuestionKind>(value =>
        value ? 'multiline-string' : 'single-line-string',
      );
    case 'variantSelection':
      return makeDerivedUnit(
        instance.hasMultipleChoice.units.value,
      ).getUnit<TS.QuestionKind>(value =>
        value ? 'multiple-variants-selection' : 'single-variant-selection',
      );
    case 'video':
      return 'video';
    case 'file':
      return 'file';
  }
};
