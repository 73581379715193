import {
  createRequiredContext,
  useRequiredContext,
} from 'utils/react/RequiredContext';

import { QuestionsConfigContext } from '../../QuestionsConfigContext';
import { CachedQuestionInstance, QuestionConstructor } from '../../types';

export type QuestionContextData = {
  cachedInstance: CachedQuestionInstance;
  constructorQuestion: QuestionConstructor<any, any, any>;
};

export const QuestionContext = createRequiredContext<QuestionContextData>();

type Props = {
  cachedInstance: CachedQuestionInstance;
};

export function QuestionContextProvider({
  cachedInstance,
  children,
}: React.PropsWithChildren<Props>) {
  const questionKey = cachedInstance.activeQuestionKeyUnit.useState();
  const { questionKeyToConstructor } = useRequiredContext(
    QuestionsConfigContext,
  );

  const constructorQuestion = questionKeyToConstructor[questionKey];

  return (
    <QuestionContext.Provider
      cachedInstance={cachedInstance}
      constructorQuestion={constructorQuestion}
    >
      {children}
    </QuestionContext.Provider>
  );
}
