import { useMemo } from 'react';

import * as M from 'types/serverModels';

export const makeUserFromServerUser = (
  user: (Partial<M.User> & { login: string }) | string,
): Partial<M.User> & { login: string } => {
  if (typeof user === 'string') {
    return { login: user };
  }
  return user;
};

export const useUserFromServerUser = (
  user: (Partial<M.User> & { login: string }) | string,
) => {
  return useMemo(() => makeUserFromServerUser(user), [user]);
};
