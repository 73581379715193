import React, { useCallback } from 'react';

import { Switch } from 'components';
import i18nData from 'features/project/Constructor/i18n.json';
import { FileUpload } from 'features/project/Constructor/subfeatures';
import { API, I18n } from 'services';
import { block } from 'utils/classname';

import { FormExtensionProps } from '../../../types';
import { StateInstance } from '../types';
import './style.scss';

const b = block('map-question-form-extension');

const Label = () => {
  const text = I18n.useText(i18nData).steps.shared.questions.list.map;

  return <>{text.backgroundCheckboxLabel}</>;
};

const extensions = ['jpg', 'jpeg', 'svg', 'png'];

function FormExtension({
  instance,
  className,
}: FormExtensionProps<StateInstance>) {
  const callStateUnit = API.services.fileUpload.useCallStateUnit();
  const call = API.services.fileUpload.useCall(callStateUnit);

  const shouldShowImageOnMap =
    instance.shouldShowImageOnMap.units.value.useState();

  const handleUpload = useCallback(
    (file: File) => {
      call({ container: 'projects', file });
    },
    [call],
  );

  return (
    <div className={b({}, [className])}>
      <Switch.Component
        Label={Label}
        formElementState={instance.shouldShowImageOnMap}
      />
      {shouldShowImageOnMap && (
        <FileUpload.Component
          callStateUnit={callStateUnit}
          variant="image"
          extensions={extensions}
          urlsState={instance.image}
          onUpload={handleUpload}
          withDeleteControl
        />
      )}
    </div>
  );
}

export const Component = React.memo(FormExtension);
