import React from 'react';

import { TextSize } from 'types/UIKit';
import { block } from 'utils/classname';

import { LabelProvidedProps } from '../types';

type Props = LabelProvidedProps & {
  size: TextSize;
  color?: 'fg-1' | 'fg-3';
  useLabel(): React.ReactNode;
};

const b = block('checkbox');

function Label({ size, color = 'fg-1', useLabel, onClick }: Props) {
  return (
    <div className={b('label', { size, color })} onClick={onClick}>
      {useLabel()}
    </div>
  );
}

const Component = React.memo(Label);

export function makeLabel(props: Omit<Props, keyof LabelProvidedProps>) {
  return (providedProps: Pick<Props, keyof LabelProvidedProps>) => (
    <Component {...providedProps} {...props} />
  );
}
