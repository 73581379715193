import { makeDerivedUnit } from 'utils/State';

import { Question } from '../../shared';
import { descriptionState } from './description';
import { cachedStoreInstancesUnit } from './instances';

export const hasSomeInputUnit = makeDerivedUnit(
  cachedStoreInstancesUnit,
  descriptionState.units.value,
).getUnit(
  (instances, description) =>
    (instances as Question.UngroupedStoreCachedInstances).instances.length >
      0 || description !== '',
);
