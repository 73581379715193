import React from 'react';

import { block, classnames } from 'utils/classname';

import './style.scss';
import { Tag, Typography, Color, Weight, Align } from './types';

export * from './types';

const b = block('text');

export type Props<T extends Tag> = {
  typography?: Typography;
  color?: Color;
  as?: T;
  className?: string;
  weight?: Weight;
  align?: Align;
  tagProps?: JSX.IntrinsicElements[T];
  inline?: boolean;
};

function Text<T extends Tag>(props: React.PropsWithChildren<Props<T>>) {
  const {
    className,
    as = 'div',
    typography = 'text-m',
    color = 'fg-1',
    weight = 'normal',
    align = 'left',
    inline = false,
    children,
    tagProps,
  } = props;

  return React.createElement(
    as,
    {
      className: classnames(
        className,
        b({ typography, color, weight, align, inline }),
      ),
      ...tagProps,
    },
    children,
  );
}

export const Component = React.memo(Text);
