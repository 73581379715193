import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  login?: string;
  email?: string;
};

export const checkCredentials = makeService<InputData, M.CredentialsCheck>(
  'check_credentials',
  'get',
);
