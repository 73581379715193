import { Filter } from 'features/project/Constructor/subfeatures';
import { Sort } from 'features/widgets/shared';
import { I18n } from 'services';
import { chartPalettes } from 'shared/constants';
import * as TS from 'types';
import * as M from 'types/serverModels';
import { FormElementState, makeFormElementState } from 'utils/FormState';
import { makePrimaryUnit } from 'utils/State';
import { AxisData, QuestionKind } from 'utils/business';
import { nonNull } from 'utils/validators';

import { questionIDToQuestionKindUnit } from '../../../../InputDataForm/units/instances';
import {
  ChartWidgetKey,
  ConstructorWidgetConfigForFindingsWidget,
  QuestionID,
  WidgetIcon,
} from '../../../types';
import { makeSharedInstancePart } from '../../makeSharedInstancePart';
import { SharedConstructorArgs } from '../../shared/SharedConstructorArgs';
import * as DataSettings from './DataSettings';
import * as View from './View';
import { Instance } from './types';

const widgetKeyToChartType: Record<ChartWidgetKey, TS.ChartType> = {
  area: 'area',
  column: 'column',
  columnStack: 'column-stack',
  line: 'line',
  pie: 'pie',
  scatter: 'scatter',
};

type ServerWidgetConstructorArgs = {
  chartTypeState: FormElementState<TS.ChartType>;
  paletteState: FormElementState<string[]>;
  cuttingQuestionState: FormElementState<QuestionID | null>;
  xAxisDataState: FormElementState<TS.XAxisData | null>;
  yAxisDataState: FormElementState<TS.YAxisData | null>;
  filter: Filter.State;
  sort?: Sort.State;
};

export function makeServerWidget(
  args: ServerWidgetConstructorArgs,
): Partial<M.ChartWidget> {
  const {
    chartTypeState,
    cuttingQuestionState,
    paletteState,
    xAxisDataState,
    yAxisDataState,
    filter,
    sort,
  } = args;

  const chartType = chartTypeState.units.value.getState();
  const palette = paletteState.units.value.getState();
  const xAxisData = xAxisDataState.units.value.getState();
  const yAxisData = yAxisDataState.units.value.getState();
  const cuttingQuestion = cuttingQuestionState.units.value.getState();

  const colorScheme = chartPalettes.indexOf(palette);

  const xVar = xAxisData
    ? AxisData.makeServerXAxisDataFromModel(xAxisData)
    : undefined;
  const yVar = yAxisData
    ? AxisData.makeServerYAxisDataFromModel(yAxisData)
    : undefined;

  const questionIDToQuestionKind = questionIDToQuestionKindUnit.getState();

  return {
    type: 'chart',
    descriptor: {
      chartType,
      colorScheme,
      xVar,
      xVarType:
        xAxisData === null
          ? undefined
          : xAxisData.kind === 'question'
            ? QuestionKind.makeServerQuestionType(
                questionIDToQuestionKind[xAxisData.questionID],
              )
            : xVar,
      yVar,
      yVarType:
        yAxisData === null
          ? undefined
          : yAxisData.kind === 'question'
            ? QuestionKind.makeServerQuestionType(
                questionIDToQuestionKind[yAxisData.questionID],
              )
            : yVar,
      seriesVar:
        AxisData.makeServerCuttingQuestionAxisDataFromModel(cuttingQuestion),
      filter: Filter.makeServerFilterFromState(filter),
      sort: sort && Sort.makeServerFilterFromState(sort),
    },
  };
}

export function makeChartWidget<Key extends ChartWidgetKey>(
  key: Key,
  icon: WidgetIcon,
): ConstructorWidgetConfigForFindingsWidget<
  Key,
  Instance,
  typeof makeInstance
> {
  function makeInstance({
    initialPalette = chartPalettes[0],
    initialCuttingQuestion = null,
    initialXAxisData,
    initialYAxisData,
    ...sharedArgs
  }: {
    initialPalette?: string[];
    initialCuttingQuestion?: QuestionID | null;
    initialXAxisData?: TS.XAxisData;
    initialYAxisData?: TS.YAxisData;
  } & SharedConstructorArgs): Instance {
    const chartTypeState = makeFormElementState<TS.ChartType>(
      widgetKeyToChartType[key],
    );
    const paletteState = makeFormElementState(initialPalette);
    const cuttingQuestionState = makeFormElementState<QuestionID | null>(
      initialCuttingQuestion,
    );

    const xAxisDataState = makeFormElementState(initialXAxisData ?? null, [
      nonNull(I18n.constants.emptyReference),
    ]);
    const yAxisDataState = makeFormElementState(initialYAxisData ?? null, [
      nonNull(I18n.constants.emptyReference),
    ]);

    const formEntitiesUnit = makePrimaryUnit([xAxisDataState, yAxisDataState]);

    return {
      ...makeSharedInstancePart({ ...sharedArgs, formEntitiesUnit }),
      kind: key,
      id: sharedArgs.id,
      chartType: chartTypeState,
      xAxisData: xAxisDataState,
      yAxisData: yAxisDataState,
      cuttingQuestion: cuttingQuestionState,
      palette: paletteState,
      makeServerWidget() {
        return makeServerWidget({
          chartTypeState,
          cuttingQuestionState,
          filter: this.filter,
          paletteState,
          xAxisDataState,
          yAxisDataState,
        });
      },
    };
  }

  return {
    key,
    icon,
    makeInstance,
    DataSettings: DataSettings.Component,
    View: View.Component,
    viewHeight: 500,
  };
}
