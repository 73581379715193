import React from 'react';

import { ReactComponent as ChattingSVGIcon } from 'shared/images/chatting.svg';

type Props = {};

function ChattingIcon({}: Props) {
  return <ChattingSVGIcon />;
}

const Component = React.memo(ChattingIcon) as typeof ChattingIcon;

export const makeComponent = (props: Props) => () => <Component {...props} />;
