import * as M from 'types/serverModels';

import { makeService } from '../utils';

type InputData = {
  data: {
    uuid?: M.UUID;
    group?: string;
    project: M.UUID;
    answers?: M.QuestionnaireData['answers'];
    custom?: Record<string, Record<M.UUID, string>>;
  };
};

type Output = {
  data: M.QuestionnaireData | M.QuestionnaireData[];
  notice?: string;
};

export const post = makeService<InputData, Output>(
  () => 'data/',
  'api-v2-post',
  false,
);
