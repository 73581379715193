import React from 'react';

import { Button } from 'components';

export type Props = Omit<Button.Props, 'type'>;

function DefaultControl(props: Props) {
  return (
    <Button.Component
      type="button"
      size="xs"
      variant="outlined"
      color="secondary"
      {...props}
    />
  );
}

export const Component = React.memo(DefaultControl) as typeof DefaultControl;
