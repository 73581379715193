import { Location } from 'history';

let currentLocation: Location | null = null;

export function getCurrentLocation(): Location | null {
  return currentLocation;
}

export function setCurrentLocation(value: Location) {
  currentLocation = value;
}
