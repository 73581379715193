import { useCallback } from 'react';

import { I18n } from 'services';

type References = {
  EUR: I18n.EntryReference;
  GBP: I18n.EntryReference;
  RUR: I18n.EntryReference;
  USD: I18n.EntryReference;
};

export function useGetCurrencyText(references: References) {
  const EURText = I18n.useReference(references.EUR);
  const GBPText = I18n.useReference(references.GBP);
  const RURText = I18n.useReference(references.RUR);
  const USDText = I18n.useReference(references.USD);

  return useCallback(
    ({ currency }: { currency: keyof References }) => {
      const dictionary = {
        EUR: EURText,
        GBP: GBPText,
        RUR: RURText,
        USD: USDText,
      };

      return dictionary[currency];
    },
    [EURText, GBPText, RURText, USDText],
  );
}
