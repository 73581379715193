import * as R from 'ramda';

import * as M from 'types/serverModels';

import { quizUnit } from '../units';
import * as Question from './Question';

export function makeServerQuestion(
  cachedQuestionInstance: Question.CachedQuestionInstance,
): M.QuizQuestion | null {
  const mode = cachedQuestionInstance.mode.getState();
  const instance =
    cachedQuestionInstance.instancesCache[
      cachedQuestionInstance.activeQuestionKeyUnit.getState()
    ];

  if (mode === 'edit') {
    const quiz = quizUnit.getState();
    const serverQuestion = quiz?.questions.find(x => x.uuid === instance.id);

    return serverQuestion || null;
  }
  return R.filter(x => typeof x !== 'undefined', {
    ...instance.makeSharedServerQuestion(),
    ...instance.makeServerQuestion(),
  } as M.QuizQuestion);
}
