import React, {
  useCallback,
  useLayoutEffect,
  useEffect,
  useRef,
  useState,
} from 'react';

import { block, classnames } from 'utils/classname';

import './style.scss';

const b = block('preloader');

export type Size = 'xs' | 's' | 'm';

export type CompletionMarker = 'success' | 'fail';
export type Color = 'accent-1' | 'contrast-fg';

type Props = {
  className?: string;
  completionMarker?: CompletionMarker;
  size?: Size;
  color?: Color;
  embed?: boolean;
  onTransitionEnd?(): void;
};

type Mode =
  | LoadingMode
  | InnerCircleRotationMode
  | CheckAnimationMode
  | FailAnimationMode;

type LoadingMode = { kind: 'loading' };
type InnerCircleRotationMode = { kind: 'inner-circle-rotation' };
type CheckAnimationMode = { kind: 'check-animation' };
type FailAnimationMode = { kind: 'fail-animation' };

// TODO most of code is unused, it was implemented for preloader with multiple states
// and animated transition between those states. We should fix and use this (it was buggy in some cases)
// or replace with ordinary svg or something else

// NOTE rest included for debugging purpose: e.g. for data-feature prop passing
function Preloader({
  className,
  completionMarker,
  size = 'm',
  color = 'accent-1',
  embed,
  onTransitionEnd,
  ...rest
}: Props) {
  const [mode, setMode] = useState<Mode>({ kind: 'loading' });
  const circlerRef = useRef<HTMLDivElement>(null);
  const innerCircleRef = useRef<HTMLDivElement>(null);
  const rotateAnimationRef = useRef<Animation | null>(null);

  const useEff =
    process.env.BUILD_TARGET === 'server' ? useLayoutEffect : useEffect;

  useEff(() => {
    if (circlerRef.current) {
      rotateAnimationRef.current = circlerRef.current.animate(
        [{ transform: 'rotate(360deg)' }],
        {
          duration: 1000,
          iterations: Infinity,
        },
      );
    }
  }, []);

  useEff(() => {
    if (completionMarker) {
      const rotateAnimation = rotateAnimationRef.current;
      if (rotateAnimation) {
        rotateAnimation.pause();
        setMode({ kind: 'inner-circle-rotation' });
      }
    }
  }, [completionMarker]);

  const handleInnerCircleTransitionEnd = useCallback(
    (e: React.TransitionEvent<HTMLDivElement>) => {
      if (e.propertyName === 'transform') {
        switch (completionMarker) {
          case 'success':
            setMode({ kind: 'check-animation' });
            break;
          case 'fail':
            setMode({ kind: 'fail-animation' });
        }
      }
    },
    [completionMarker],
  );

  const handleCheckIconTransitionEnd = useCallback(
    (e: React.TransitionEvent<HTMLDivElement>) => {
      if (e.propertyName === 'height') {
        onTransitionEnd?.();
      }
    },
    [onTransitionEnd],
  );

  return (
    <div
      className={classnames(
        b({ complete: completionMarker, size, mode: mode.kind, color, embed }),
        className,
      )}
      {...rest}
    >
      <div
        className={b('circle', {
          rotated:
            mode.kind === 'inner-circle-rotation' ||
            mode.kind === 'check-animation' ||
            mode.kind === 'fail-animation',
        })}
        ref={circlerRef}
      >
        <div
          className={b('inner-circle', {
            rotated:
              mode.kind === 'inner-circle-rotation' ||
              mode.kind === 'check-animation' ||
              mode.kind === 'fail-animation',
          })}
          ref={innerCircleRef}
          onTransitionEnd={handleInnerCircleTransitionEnd}
        ></div>
      </div>
      {(() => {
        switch (completionMarker) {
          case 'success':
            return (
              <div className={b('check-icon-container')}>
                <div
                  className={b('check-icon', {
                    visible: mode.kind === 'check-animation',
                  })}
                  onTransitionEnd={handleCheckIconTransitionEnd}
                />
              </div>
            );
          case 'fail':
            return (
              <div
                className={b('fail-icon', {
                  visible: mode.kind === 'fail-animation',
                })}
                onTransitionEnd={onTransitionEnd}
              />
            );
        }
      })()}
    </div>
  );
}

export * as variants from './variants';

export const Component = React.memo(Preloader) as React.FC<Props>;
