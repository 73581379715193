export type APIError = {
  kind: 'api-error';
  message: string;
  code?: number;
};
export function makeAPIError(message: string, code?: number): APIError {
  return {
    kind: 'api-error',
    message,
    code,
  };
}

export function isAPIError(x: unknown): x is APIError {
  return (x as APIError)?.kind === 'api-error';
}
