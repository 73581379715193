import { CallInputData } from '../types';
import type { makeFieldsetState } from './makeFieldsetState';
import { passwordMask } from './makeFieldsetState';

type Options = {
  fieldsetState: ReturnType<typeof makeFieldsetState>;
};

export function getCallInputData({ fieldsetState }: Options): CallInputData {
  const newPassword = fieldsetState.password.units.value.getState();

  const isPasswordChanged = newPassword !== passwordMask;
  if (!isPasswordChanged) {
    return { password: null };
  }

  return {
    password: newPassword,
  };
}
