import { PartialDeep } from 'type-fest';

import { Filter } from 'features/project/Constructor/subfeatures';
import { I18n } from 'services';
import * as M from 'types/serverModels';
import { FormElementState, makeFormElementState } from 'utils/FormState';
import { makePrimaryUnit } from 'utils/State';
import { nonNull } from 'utils/validators';

import {
  ConstructorWidgetConfigForFindingsWidget,
  QuestionID,
  WidgetMode,
} from '../../types';
import { makeSharedInstancePart } from '../makeSharedInstancePart';
import { SharedConstructorArgs } from '../shared/SharedConstructorArgs';
import * as DataSettings from './DataSettings';
import * as View from './View';
import { TimeLineInstance } from './types';

type ServerWidgetConstructorArgs = {
  startDateSelectedQuestionIDState: FormElementState<QuestionID | null>;
  endDateSelectedQuestionIDState: FormElementState<QuestionID | null>;
  headlineSelectedQuestionIDState: FormElementState<QuestionID | null>;
  textSelectedQuestionIDState: FormElementState<QuestionID | null>;
  mediaSelectedQuestionIDState: FormElementState<QuestionID | null>;
  filter: Filter.State;
};

export function makeServerWidget(
  args: ServerWidgetConstructorArgs,
): PartialDeep<M.TimelineWidget> {
  const {
    filter,
    startDateSelectedQuestionIDState,
    endDateSelectedQuestionIDState,
    headlineSelectedQuestionIDState,
    textSelectedQuestionIDState,
    mediaSelectedQuestionIDState,
  } = args;

  const startDateSelectedQuestionID =
    startDateSelectedQuestionIDState.units.value.getState();
  const endDateSelectedQuestionID =
    endDateSelectedQuestionIDState.units.value.getState();
  const headlineSelectedQuestionID =
    headlineSelectedQuestionIDState.units.value.getState();
  const textSelectedQuestionID =
    textSelectedQuestionIDState.units.value.getState();
  const mediaSelectedQuestionID =
    mediaSelectedQuestionIDState.units.value.getState();

  return {
    type: 'timeline',
    descriptor: {
      filter: Filter.makeServerFilterFromState(filter),
      startDateQuestionId: startDateSelectedQuestionID || undefined,
      endDateQuestionId: endDateSelectedQuestionID || undefined,
      headlineQuestionId: headlineSelectedQuestionID || undefined,
      textQuestionId: textSelectedQuestionID || undefined,
      mediaQuestionId: mediaSelectedQuestionID || undefined,
    },
  };
}

function makeInstance({
  initialStartDateQuestionID = null,
  initialEndDateQuestionID = null,
  initialHeadlineQuestionID = null,
  initialTextQuestionID = null,
  initialMediaQuestionID = null,
  ...sharedArgs
}: {
  id: string;
  initialStartDateQuestionID?: QuestionID | null;
  initialEndDateQuestionID?: QuestionID | null;
  initialHeadlineQuestionID?: QuestionID | null;
  initialTextQuestionID?: QuestionID | null;
  initialMediaQuestionID?: QuestionID | null;
  initialFilterState?: Filter.InitialState;
  initialMode?: WidgetMode;
} & SharedConstructorArgs): TimeLineInstance {
  const startDateQuestionIDState = makeFormElementState<QuestionID | null>(
    initialStartDateQuestionID,
    [nonNull(I18n.constants.emptyReference)],
  );
  const endDateQuestionIDState = makeFormElementState<QuestionID | null>(
    initialEndDateQuestionID,
  );
  const headlineQuestionIDState = makeFormElementState<QuestionID | null>(
    initialHeadlineQuestionID,
    [nonNull(I18n.constants.emptyReference)],
  );
  const textQuestionIDState = makeFormElementState<QuestionID | null>(
    initialTextQuestionID,
  );
  const mediaQuestionIDState = makeFormElementState<QuestionID | null>(
    initialMediaQuestionID,
  );

  return {
    ...makeSharedInstancePart({
      ...sharedArgs,
      formEntitiesUnit: makePrimaryUnit([
        startDateQuestionIDState,
        headlineQuestionIDState,
      ]),
    }),
    kind: 'timeline',
    id: sharedArgs.id,
    startDateQuestionID: startDateQuestionIDState,
    endDateQuestionID: endDateQuestionIDState,
    headlineQuestionID: headlineQuestionIDState,
    textQuestionID: textQuestionIDState,
    mediaQuestionID: mediaQuestionIDState,
    makeServerWidget() {
      return makeServerWidget({
        filter: this.filter,
        startDateSelectedQuestionIDState: startDateQuestionIDState,
        endDateSelectedQuestionIDState: endDateQuestionIDState,
        headlineSelectedQuestionIDState: headlineQuestionIDState,
        textSelectedQuestionIDState: textQuestionIDState,
        mediaSelectedQuestionIDState: mediaQuestionIDState,
      });
    },
  };
}

export const constructorWidgetConfig: ConstructorWidgetConfigForFindingsWidget<
  'timeline',
  TimeLineInstance,
  typeof makeInstance
> = {
  key: 'timeline',
  icon: 'timeline',
  makeInstance,
  DataSettings: DataSettings.Component,
  View: View.Component,
  viewHeight: 500,
};

export type { TimeLineInstance };
