import * as M from 'types/serverModels';

import { makeService } from '../utils';

type InputData = {
  answer: {
    quiz: M.UUID;
    answers: Record<M.UUID, number | string[] | M.Image | M.Location>;
  };
};

type Output = {
  answer: M.QuizAnswer;
};

export const post = makeService<InputData, Output, M.QuizAnswer>(
  () => 'answer/',
  'api-v2-post',
  false,
  ({ answer }) => answer,
);
