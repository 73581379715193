import React, { useCallback } from 'react';

import { Button } from 'components';
import i18nData from 'features/project/Constructor/i18n.json';
import { I18n } from 'services';
import { block } from 'utils/classname';

import { instancesNumberUnit } from '../units';
import { orderingModeUnit } from '../units/orderingMode';
import './style.scss';

const b = block('ordering-mode-button');

type Props = {};

function OrderingModeButton({}: Props) {
  const text = I18n.useText(i18nData);
  const mode = orderingModeUnit.useState();

  const handleClick = useCallback(() => {
    orderingModeUnit.setState(prev => (prev === 'on' ? 'off' : 'on'));
  }, []);

  const instancesNumber = instancesNumberUnit.useState();

  return (
    <Button.Component
      className={b()}
      type="button"
      onClick={handleClick}
      color="secondary"
      variant="outlined"
      disabled={mode !== 'on' && instancesNumber < 2}
    >
      {mode === 'off'
        ? text.orderingModeButton.offModeText
        : text.orderingModeButton.onModeText}
    </Button.Component>
  );
}

export const Component = React.memo(OrderingModeButton);
