import { MESCH_USER_ROLE, USER_ROLE } from 'shared/constants';
import * as M from 'types/serverModels';

export function getMESCHRole(role: M.UserRole): M.MESCHUserRole {
  switch (role) {
    case USER_ROLE.teacher: {
      return MESCH_USER_ROLE.teacher;
    }
    default: {
      return MESCH_USER_ROLE.student;
    }
  }
}
