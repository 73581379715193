import { makeFeature } from 'services/Feature';

import i18nData from './i18n.json';
import * as i18nSharedReferences from './i18nSharedReferences';
import * as subfeatures from './subfeatures';

export const feature = makeFeature({
  name: 'ProjectBookPageStages',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "project-book-page-stages" */
      './Component'
    ),
  i18nSharedReferences: Object.values(
    (() => {
      const {
        projectBookTaskPriorityReferences,
        projectBookTaskPriorityTooltipReferences,
        ...rest
      } = i18nSharedReferences;

      return [
        ...Object.values(rest),
        ...Object.values(projectBookTaskPriorityReferences),
        ...Object.values(projectBookTaskPriorityTooltipReferences),
      ];
    })(),
  ),
  i18nData,
  subfeatures: Object.values(subfeatures).map(x => x.feature),
});

export type { Props } from './Component';

export { i18nStageSectionTitleSharedReferences as i18nStagesStageSectionTitleSharedReferences } from './i18nStageSectionTitleSharedReferences';

export const Component = feature.Component;
