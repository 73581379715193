import * as M from 'types/serverModels';

import { getProjectLanguage } from '../utils';
import { TeacherMaterials, ParticipantMaterials } from './steps';
import {
  CachedInstance,
  makeInstanceFromServerUnit,
} from './steps/shared/Materials';

export function fillMaterialsSteps(units: M.Unit[]) {
  const projectLang = getProjectLanguage();

  TeacherMaterials.instancesUnit.setState(
    units
      .filter(x => x.tutor && x.lang === projectLang)
      .map(makeInstanceFromServerUnit)
      .filter((x): x is CachedInstance => x !== null),
  );

  ParticipantMaterials.instancesUnit.setState(
    units
      .filter(x => !x.tutor && x.lang === projectLang)
      .map(makeInstanceFromServerUnit)
      .filter((x): x is CachedInstance => x !== null),
  );
}
