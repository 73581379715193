import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  login: M.User['login'];
};

type Course = Pick<
  M.Course,
  | 'uuid'
  | 'code'
  | 'status'
  | 'name'
  | 'subtitle'
  | 'abstract'
  | 'subjects'
  | 'targetAge'
  | 'langs'
  | 'author'
  | 'users_counter'
  | 'options'
  | 'weight'
> & {
  _joined?: boolean;
  _expiresOn?: M.Datetime;
};

type OutputData = Course[] | undefined;

export const storeUserCourses = makeService<InputData, OutputData>(
  'store_user_courses',
  'get',
);
