import { makeSharedEntryReference } from 'services/I18n/makeEntryReference';

export const projectStatusReference = {
  0: makeSharedEntryReference(data => data.project.statusText[0]),
  1: makeSharedEntryReference(data => data.project.statusText[1]),
  2: makeSharedEntryReference(data => data.project.statusText[2]),
  4: makeSharedEntryReference(data => data.project.statusText[4]),
  8: makeSharedEntryReference(data => data.project.statusText[8]),
  24: makeSharedEntryReference(data => data.project.statusText[24]),
  32: makeSharedEntryReference(data => data.project.statusText[32]),
};

export const editButtonLabelReference = makeSharedEntryReference(
  data => data.editButtonLabel,
);
