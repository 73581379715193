import { GeoJsonObject } from 'geojson';
import React from 'react';

import {
  MapQuestion,
  DrawQuestion,
} from 'features/project/Constructor/subfeatures';
import * as M from 'types/serverModels';
import { useFormElementState } from 'utils/FormState';

import { PreviewModeFormProps } from '../../../types';
import { StateInstance } from '../types';

function PreviewModeForm({ instance }: PreviewModeFormProps<StateInstance>) {
  const mapFormElementState = useFormElementState<M.Location | null>(null);
  const figuresFormElementState = useFormElementState<GeoJsonObject | null>(
    null,
  );

  const shouldShowFiguresOnMap =
    instance.shouldShowFiguresOnMap.units.value.useState();
  const shouldShowImageOnMap =
    instance.shouldShowImageOnMap.units.value.useState();

  const image = instance.image.units.value.useState();
  const imageForMap = (shouldShowImageOnMap && image) || undefined;

  if (shouldShowFiguresOnMap) {
    return (
      <DrawQuestion.Component
        formElementState={figuresFormElementState}
        image={imageForMap}
      />
    );
  }
  return (
    <MapQuestion.Component
      formElementState={mapFormElementState}
      image={imageForMap}
    />
  );
}

export const Component = React.memo(PreviewModeForm);
