import React from 'react';

import { Button } from 'components';

type Props = Omit<Button.Props, 'type'>;

function HeaderButton(props: Props) {
  return (
    <Button.Component
      type="button"
      variant="contained"
      color="white"
      size="s"
      {...props}
    />
  );
}

export const Component = React.memo(HeaderButton);
