import * as R from 'ramda';

import {
  GroupedStoreCachedInstances,
  GroupedWidgetPath,
  ConstructorWidgetCachedInstance,
} from '../../types';

export function moveGroupedPrev(
  widgetInstance: ConstructorWidgetCachedInstance,
  path: GroupedWidgetPath,
  storeInstances: GroupedStoreCachedInstances,
): GroupedStoreCachedInstances {
  if (path.groupIndex === 0 && path.widgetIndex === 0) {
    return storeInstances;
  }

  const makeWidgetInstancesLens = (groupIndex: number) =>
    R.lensPath<GroupedStoreCachedInstances, 'groups', number, 'instances'>([
      'groups',
      groupIndex,
      'instances',
    ]);

  if (path.widgetIndex === 0) {
    const removeFromOldGroup = R.over(
      makeWidgetInstancesLens(path.groupIndex),
      instances => R.without([widgetInstance], instances),
    );

    const addToPrevGroup = R.over(
      makeWidgetInstancesLens(path.groupIndex - 1),
      instances => [...instances, widgetInstance],
    );

    return [removeFromOldGroup, addToPrevGroup].reduce(
      (acc, f) => f(acc),
      storeInstances,
    );
  }

  return R.over(
    makeWidgetInstancesLens(path.groupIndex),
    instances => R.move(path.widgetIndex, path.widgetIndex - 1, instances),
    storeInstances,
  );
}
