import { makeFeature } from 'services/Feature';

import { TableType } from './Component';
import i18nData from './i18n.json';
import * as i18nSharedReferences from './i18nSharedReferences';

export * from './types';

export const feature = makeFeature({
  name: 'Table',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "table" */
      './Component'
    ),
  i18nSharedReferences: Object.values(i18nSharedReferences),
  i18nData,
  subfeatures: [],
});

export type { Props } from './Component';

export * as HeaderSortToggle from './HeaderSortToggle';
export * as Placeholder from './Placeholder';

export const Component = feature.Component as TableType;
