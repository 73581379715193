import { makeSharedEntryReference } from 'services/I18n';

export const someErrorReference = makeSharedEntryReference(
  data => data.someError,
);

export const requiredFieldReference = makeSharedEntryReference(
  data => data.validationErrors.requiredField,
);

export const invalidEmailValidationErrorReference = makeSharedEntryReference(
  data => data.validationErrors.invalidEmail,
);
