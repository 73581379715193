import React, { useCallback, useMemo } from 'react';

import { ExpansionPanel } from 'components';
import { AnswerVariant } from 'utils/business';
import { block } from 'utils/classname';

import * as Extension from './Extension';
import * as Header from './Header';
import './style.scss';

const b = block('variant');

type Props = {
  variant: AnswerVariant.AnswerVariant;
  onMoveUp(variant: AnswerVariant.AnswerVariant): void;
  onMoveDown(variant: AnswerVariant.AnswerVariant): void;
  onRemove(variant: AnswerVariant.AnswerVariant): void;
};

function Variant({ variant, onMoveDown, onMoveUp, onRemove }: Props) {
  const handleMoveUp = useCallback(() => {
    onMoveUp(variant);
  }, [onMoveUp, variant]);

  const handleMoveDown = useCallback(() => {
    onMoveDown(variant);
  }, [onMoveDown, variant]);

  const handleRemove = useCallback(() => {
    onRemove(variant);
  }, [onRemove, variant]);

  const extensionVisibleUnit = useMemo(() => {
    return ExpansionPanel.makeVisibleUnit();
  }, []);

  return (
    <div className={b()}>
      <Header.DependenciesContext.Provider
        imageFormElementState={variant.image}
        textFormElementState={variant.text.formElementState}
        onMoveDown={handleMoveDown}
        onMoveUp={handleMoveUp}
        onRemove={handleRemove}
      >
        <Extension.DependenciesContext.Provider
          explanationFormElementState={variant.explanation.formElementState}
          isOwnFormElementState={variant.isOwn}
        >
          <ExpansionPanel.Component
            className={b('expansion-panel')}
            Header={Header.Component}
            Content={Extension.Component}
            visibleUnit={extensionVisibleUnit}
          />
        </Extension.DependenciesContext.Provider>
      </Header.DependenciesContext.Provider>
    </div>
  );
}

export const Component = React.memo(Variant) as typeof Variant;
