import { makeFeature } from 'services/Feature';

import * as subfeatures from './subfeatures';

export const feature = makeFeature({
  name: 'LandingPageProducts1Elements',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "landing-page-products-1-elements" */
      './Component'
    ),
  i18nData: null,
  i18nSharedReferences: null,
  subfeatures: Object.values(subfeatures).map(x => x.feature),
});

export const Component = feature.Component;
