import {
  makeDerivedUnit,
  makeMappingUnitFromUnit,
  makePrimaryUnit,
} from 'utils/State';

import {
  cachedStoreInstancesUnit,
  flatQuestionInstancesUnit,
} from './instances';
import { modesUnit } from './modes';

const areRequredUnitsUnit = makeDerivedUnit(flatQuestionInstancesUnit).getUnit(
  questions => questions.map(x => x.isRequired.units.value),
);

const areRequiredUnit = makeMappingUnitFromUnit(areRequredUnitsUnit);

export const twoQuestionsInvariantUnit = makeDerivedUnit(
  flatQuestionInstancesUnit,
).getUnit(instances => instances.length >= 2);

export const oneMapQuestionInvariantUnit = makeDerivedUnit(
  flatQuestionInstancesUnit,
).getUnit(instances => instances.some(x => x.kind === 'map'));

export const oneRequiredInvariantUnit = makeDerivedUnit(
  areRequiredUnit,
).getUnit(areRequred => areRequred.some(x => x));

export const noEmptyGroupsUnit = makeDerivedUnit(
  cachedStoreInstancesUnit,
).getUnit(
  x => x.kind === 'ungrouped' || x.groups.every(y => y.instances.length > 0),
);

export const allQuestionAreSavedUnit = makeDerivedUnit(modesUnit).getUnit(
  modes => modes.every(x => x === 'preview'),
);

export const progressInvariantUnitsUnit = makePrimaryUnit([
  twoQuestionsInvariantUnit,
  oneMapQuestionInvariantUnit,
  oneRequiredInvariantUnit,
  noEmptyGroupsUnit,
  allQuestionAreSavedUnit,
]);
