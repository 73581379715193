import { I18n } from 'services';
import * as TS from 'types';
import {
  AbstractStateUnit,
  makeDerivedUnit,
  makeMappingUnit,
} from 'utils/State';

import i18nData from '../i18n.json';
import {
  allQuestionAreSavedUnit,
  oneQuestionInvariantUnit,
} from './stepInvariants';

const makeErrorReference = (
  key: keyof (typeof i18nData)[TS.Language]['errors'],
) => {
  return I18n.makeEntryReference(i18nData, data => data.errors[key]);
};

const makeErrorUnit = (
  errorReference: I18n.EntryReference,
  invariantUnit: AbstractStateUnit<boolean>,
) =>
  makeDerivedUnit(invariantUnit).getUnit(invariant =>
    invariant ? null : errorReference,
  );

const nullableErrorsUnit = makeMappingUnit([
  makeErrorUnit(
    makeErrorReference('notAllQuestionsSaved'),
    allQuestionAreSavedUnit,
  ),
  makeErrorUnit(
    makeErrorReference('noOneQuestionAtLeast'),
    oneQuestionInvariantUnit,
  ),
]);

export const errorsUnit = makeDerivedUnit(nullableErrorsUnit).getUnit(xs =>
  xs.filter<I18n.EntryReference>((x): x is I18n.EntryReference => x !== null),
);
