import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  file: File;
  container: 'projects';
};

export const fileUpload = makeService<InputData, M.URL>(
  'file_upload',
  'post-file',
);
