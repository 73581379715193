import React, { useCallback } from 'react';
import { Link as RouterLink, LinkProps } from 'react-router-dom';

import * as M from 'types/serverModels';
import { emulateClick } from 'utils/DOM';
import { block } from 'utils/classname';

import './style.scss';

const b = block('link');

export type Color = 'primary' | 'secondary' | 'accent-2' | 'inherit';
export type Weight = 'normal' | 'bold';
export type Size = 'xxxl' | 'xl' | 'l' | 'm' | 's' | 'inherit';

export type Props = Pick<
  LinkProps,
  'target' | 'rel' | 'title' | 'tabIndex' | 'download'
> & {
  href?: M.URL;
  onClick?: React.MouseEventHandler<HTMLAnchorElement | HTMLDivElement>;
  className?: string;
  color?: Color;
  weight?: Weight;
  size: Size;
  externalHrefDefaultTarget?:
    | React.AnchorHTMLAttributes<HTMLAnchorElement>['target']
    | null;
  nowrap?: 'white-space' | 'ellipsis';
};

const Link = React.forwardRef<HTMLElement, React.PropsWithChildren<Props>>(
  (
    {
      href,
      target,
      rel,
      title,
      onClick,
      children,
      className,
      weight = 'bold',
      color = 'primary',
      size = 'm',
      externalHrefDefaultTarget = '_blank',
      tabIndex = undefined,
      nowrap = undefined,
      download = false,
    },
    ref,
  ) => {
    const handleKeyDown: React.KeyboardEventHandler = useCallback(event => {
      emulateClick(event);
    }, []);

    return href ? (
      href.startsWith('/') || href.startsWith('#') ? (
        <RouterLink
          className={b({ kind: 'link', weight, color, size, nowrap }, [
            className,
          ])}
          to={href}
          target={target}
          rel={rel}
          title={title}
          onClick={onClick}
          ref={ref as React.RefObject<HTMLAnchorElement>}
          tabIndex={tabIndex}
          download={download}
        >
          {children}
        </RouterLink>
      ) : (
        <a
          className={b({ kind: 'link', weight, color, size, nowrap }, [
            className,
          ])}
          href={href}
          target={target || externalHrefDefaultTarget || undefined}
          rel={rel || 'noopener noreferrer'}
          title={title}
          onClick={onClick}
          ref={ref as React.RefObject<HTMLAnchorElement>}
          tabIndex={tabIndex}
          download={download}
        >
          {children}
        </a>
      )
    ) : (
      <div
        className={b(
          {
            kind: 'button',
            weight,
            color,
            size,
            nowrap,
            idle: onClick === undefined,
          },
          [className],
        )}
        title={title}
        ref={ref as React.RefObject<HTMLDivElement>}
        tabIndex={tabIndex ?? (onClick && 0)}
        onKeyDown={handleKeyDown}
        onClick={onClick}
      >
        {children}
      </div>
    );
  },
);

export const Component = React.memo(Link) as typeof Link;
