import React from 'react';

import { b } from '../b';

type Props = {
  className?: string;
};

function Header({ children, className }: React.PropsWithChildren<Props>) {
  return <div className={b('header', [className])}>{children}</div>;
}

export const Component = React.memo(Header) as typeof Header;
