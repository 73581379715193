import React from 'react';

import { Page } from 'services';

import { routeTree } from '../../routeTree';
import * as features from './features';

type Props = {};

function Intro({}: Props) {
  return <features.View.Component Content={features.Intro.Component} />;
}

const path = routeTree.LANG['project-book'].intro.getPath();

export const Component = Page.makePage({
  path,
  scrollTo: 'top-on-mount',
  Component: React.memo(Intro) as typeof Intro,
  features: Object.values(features).map(x => x.feature),
});
