import React from 'react';

import { ReactComponent as CheckIcon } from 'shared/images/check.svg';
import { block } from 'utils/classname';

import { RightPartPlugin, RightPartProps } from '../../RightPartPlugin';
import { b as textInputBlock } from '../../b';
import './style.scss';

const b = block('text-input-right-part-apply-button');

type ApplyButtonOwnProps = {
  useTitle(): string;
  onClick(): void;
};

type Props = RightPartProps & ApplyButtonOwnProps;

function ApplyButton({ useTitle, onClick }: Props) {
  return (
    <button className={b({})} title={useTitle()} onClick={onClick}>
      <CheckIcon className={b('apply-icon')} />
    </button>
  );
}

export const Component = React.memo(ApplyButton) as typeof ApplyButton;
export const makePlugin = (ownProps: ApplyButtonOwnProps): RightPartPlugin => ({
  useInputContourClassName: () => {
    // const value = formElementState.units.value.useState();

    return textInputBlock('input-contour', { 'with-apply-button': true });
  },
  Component: (rightPartProps: RightPartProps) => (
    <Component {...ownProps} {...rightPartProps} />
  ),
});
