import { QuestionKind } from 'types';

import { QuestionKey } from './types';

export function getQuestionKindsSet(questionKey: QuestionKey): QuestionKind[] {
  switch (questionKey) {
    case 'date':
      return ['date'];
    case 'image':
      return ['image'];
    case 'map':
      return ['map', 'figures'];
    case 'number':
      return ['number'];
    case 'sensorData':
      return ['stream'];
    case 'sound':
      return ['sound'];
    case 'text':
      return ['single-line-string', 'multiline-string'];
    case 'variantSelection':
      return ['single-variant-selection', 'multiple-variants-selection'];
    case 'video':
      return ['video'];
    case 'file':
      return ['file'];
  }
}
