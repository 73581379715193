import React from 'react';

import { block } from 'utils/classname';

import './style.scss';

const b = block('dots-preloader');

export type Size = 'xs' | 's' | 'm';

type Props = {
  size?: Size;
  className?: string;
};

// NOTE: rest included for debugging purpose: e.g. for data-feature prop passing
function DotsPreloader({ className, size = 'm', ...rest }: Props) {
  return (
    <div className={b({ size }, [className])} {...rest}>
      <span className={b('dot')} />
      <span className={b('dot')} />
      <span className={b('dot')} />
    </div>
  );
}

export const Component = React.memo(DotsPreloader) as typeof DotsPreloader;
