import React, { useCallback } from 'react';

import { TextInput } from 'components';
import { ProjectWriteContext } from 'features/project/Constructor/FormWithSteps/ProjectWriteContext';
import i18nData from 'features/project/Constructor/i18n.json';
import { I18n } from 'services';
import * as M from 'types/serverModels';
import { useRequiredContext } from 'utils/react/RequiredContext';

import { perkState } from '../../units/inputState';

type Props = {};

const forwardStateConverter = (state: M.ProjectPerk | null) => {
  return state === null ? '' : String(state.type);
};

const backwardStateConverter = (state: string) => {
  return state === '' ? null : { type: Number(state) };
};

function Perk({}: Props) {
  const text = I18n.useText(i18nData).steps.advancedSettings.section.perk;

  const { saveProject } = useRequiredContext(ProjectWriteContext);

  const handleBlur = useCallback(() => {
    saveProject();
  }, [saveProject]);

  return (
    <TextInput.Component
      formElementState={perkState.useBridgeUnitMemo(
        forwardStateConverter,
        backwardStateConverter,
      )}
      placeholder={text.placeholder}
      errorRows={1}
      isOnlyWholeNumbers
      type="number"
      onBlur={handleBlur}
    />
  );
}

export const Component = React.memo(Perk);
