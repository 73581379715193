import {
  QuestionConstructor,
  SharedInstanceStateConstructor,
} from '../../types';
import * as PreviewModeForm from './PreviewModeForm';
import { ServerQuestion, StateInstance } from './types';

export type { StateInstance };

export const constructor: QuestionConstructor<
  'image',
  StateInstance,
  typeof makeInstance
> = {
  key: 'image',
  icon: 'image',
  makeInstance,
  PreviewModeForm: PreviewModeForm.Component,
};

export function makeServerQuestion(): Partial<ServerQuestion> {
  return {
    type: 'image',
    score: 0,
  };
}

export function makeInstance(
  makeSharedInstanceState: SharedInstanceStateConstructor,
): StateInstance {
  return {
    kind: 'image',
    makeServerQuestion: () => makeServerQuestion(),
    ...makeSharedInstanceState(),
  };
}
