import React, { useEffect, useMemo } from 'react';

import { Select } from 'components';
import { useText } from 'services/I18n';
import * as questionIcons from 'shared/images/questionIcons';
import { useFormElementState } from 'utils/FormState';
import { block } from 'utils/classname';
import { useRequiredContext } from 'utils/react/RequiredContext';

import i18nData from '../../../../../../i18n.json';
import { QuestionsConfigContext } from '../../../../QuestionsConfigContext';
import { QuestionKey } from '../../../../types';
import { QuestionContext } from '../../Context';
import { DependenciesContext } from '../Dependencies';
import './style.scss';

const b = block('header-of-quiz-constructor-for-question-in-edit-mode');

type Props = {};

const ActiveOption = (props: Select.ActiveOption.Props<QuestionKey>) => {
  const text = useText(i18nData);

  const option = props.option as QuestionKey;

  const icon = questionIcons[option];

  return (
    <Select.ActiveOption.Container.DefaultComponent>
      <icon.InlineComponent
        className={b('active-option-question-icon', { kind: option })}
      />
      {text.questions.list[option].label}
    </Select.ActiveOption.Container.DefaultComponent>
  );
};

const Option = (props: Select.Option.Props<QuestionKey>) => {
  const text = useText(i18nData);

  const option = props.option as QuestionKey;

  const icon = questionIcons[option];

  return (
    <Select.Option.Container.DefaultComponent>
      <icon.InlineComponent
        className={b('option-question-icon', { kind: option })}
      />
      {text.questions.list[option].label}
    </Select.Option.Container.DefaultComponent>
  );
};

function Header({}: Props) {
  const { constructorQuestion, cachedInstance } =
    useRequiredContext(QuestionContext);
  const { onQuestionTypeChange } = useRequiredContext(DependenciesContext);

  const activeOptionState = useFormElementState(constructorQuestion.key);

  useEffect(() => {
    return activeOptionState.units.value.subscribe({
      callback: state => {
        onQuestionTypeChange(state, (value: QuestionKey) => {
          setTimeout(() => {
            activeOptionState.units.value.setState(value, [
              'question-type-state-forwarder',
            ]);
          }, 0);
        });
      },
      name: 'question-type-state-forwarder',
    });
  }, [activeOptionState, onQuestionTypeChange]);

  const stateInstance = cachedInstance.useStateInstance();

  const { questions } = useRequiredContext(QuestionsConfigContext);

  const questionKeys = useMemo(() => questions.map(x => x.key), [questions]);

  return (
    <div className={b()}>
      <Select.Component
        ActiveOption={ActiveOption}
        Option={Option}
        activeOptionState={activeOptionState}
        options={questionKeys}
      />
      {constructorQuestion.HeaderExtension && (
        <div className={b('extension')}>
          <constructorQuestion.HeaderExtension instance={stateInstance} />
        </div>
      )}
    </div>
  );
}

export const Component = React.memo(Header);
