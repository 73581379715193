import { makeService } from './utils';

type LoginOrEmail = { login: string } | { email: string };

type InputData = LoginOrEmail & {
  password: string;
};

export const login = makeService<InputData, string, string>(
  'login',
  'post',
  true,
  output => output,
  { extractBodyInput: ({ ticket, ...input }) => ({ ...input }) },
);
