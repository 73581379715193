import { I18n } from 'services';
import * as M from 'types/serverModels';

export type UnitTrackObject = Pick<
  M.CourseStreamServiceUnitTrackObjectOutput,
  'type' | 'object' | 'abstract'
>;

export function getUnitTrackObjectAbstractMultilingStrings(
  trackObject: UnitTrackObject,
  t: ReturnType<typeof I18n.useGetMultilingTranslation>,
) {
  return [trackObject.abstract].filter(
    (x): x is M.MultilingString => !!(x && t(x)),
  );
}

export function formatUnitTrackObjectAbstract(
  trackObject: UnitTrackObject,
  t: ReturnType<typeof I18n.useGetMultilingTranslation>,
) {
  return (
    getUnitTrackObjectAbstractMultilingStrings(trackObject, t).reduce(
      (acc, x) => (acc ? acc : t(x)),
      '',
    ) ||
    trackObject.object.description ||
    ''
  );
}
