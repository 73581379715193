import React from 'react';

import { Switch } from 'components';
import { I18n } from 'services';
import { block } from 'utils/classname';

import i18nData from '../../../../../i18n.json';
import { HeaderExtensionProps } from '../../../types';
import { StateInstance } from '../types';
import './style.scss';

const b = block('quiz-variant-selection-question-header-extension');

const Label = Switch.makeLabel({
  useLabel: () =>
    I18n.useText(i18nData).questions.list.variantSelection
      .multipleChoiceSwitchLabel,
});

function HeaderExtension({ instance }: HeaderExtensionProps<StateInstance>) {
  return (
    <div className={b()}>
      <Switch.Component
        Label={Label}
        formElementState={instance.hasMultipleChoice}
      />
    </div>
  );
}

export const Component = React.memo(HeaderExtension) as typeof HeaderExtension;
