import React, { useMemo, useEffect } from 'react';

import recommendationsHeaderIconSrc from 'shared/images/recommendations.svg';
import headerIconSrc from 'shared/images/rulerAndText.svg';
import { useRequiredContext } from 'utils/react/RequiredContext';

import { ConstructorConfigContext } from '../../config/configContext';
import { ConstructorStepsContext } from '../../steps/stepsContext';
import { ConstructorFormContainer } from '../../subfeatures';
import { StepContext } from '../StepContext';
import { Buttons } from '../buttons';
import * as modals from '../modals';
import * as Header from './Header';
import './style.scss';
import { useStepText } from './useStepText';

type Props = {};

function FormContainer({}: Props) {
  const {
    mode,
    headerContentUnit,
    projectUUID,
    projectPageRouteTree,
    onProjectSave,
    onQuestionnaireDelete,
    getProjectUUID,
    useProjectUUID,
  } = useRequiredContext(ConstructorConfigContext);
  const {
    stepKeyToStep,
    steps,
    stepsSections,
    activeStepUnit,
    isLastStepUnit,
  } = useRequiredContext(ConstructorStepsContext);

  const { step } = useRequiredContext(StepContext);

  const HeaderIcon: ConstructorFormContainer.Props['HeaderIcon'] =
    useMemo(() => {
      return React.memo(({ className }) => {
        const { step } = useRequiredContext(StepContext);

        return (
          <img
            className={className}
            src={
              step.key === 'recommendations'
                ? recommendationsHeaderIconSrc
                : headerIconSrc
            }
            alt="ruler"
          />
        );
      });
    }, []);

  const ButtonsFragment = useMemo(() => {
    return React.memo(() => (
      <Buttons.Component
        projectPageRouteTree={projectPageRouteTree}
        kind="regular"
      />
    ));
  }, [projectPageRouteTree]);

  useEffect(() => {
    if (headerContentUnit) {
      const HeaderContent = React.memo(() => {
        return (
          <ConstructorConfigContext.Provider
            mode={mode}
            headerContentUnit={headerContentUnit}
            projectUUID={projectUUID}
            projectPageRouteTree={projectPageRouteTree}
            onProjectSave={onProjectSave}
            onQuestionnaireDelete={onQuestionnaireDelete}
            getProjectUUID={getProjectUUID}
            useProjectUUID={useProjectUUID}
          >
            <ConstructorStepsContext.Provider
              steps={steps}
              stepKeyToStep={stepKeyToStep}
              stepsSections={stepsSections}
              activeStepUnit={activeStepUnit}
              isLastStepUnit={isLastStepUnit}
            >
              <Header.Component />
            </ConstructorStepsContext.Provider>
          </ConstructorConfigContext.Provider>
        );
      });
      headerContentUnit.setState(HeaderContent);
    }
  }, [
    activeStepUnit,
    getProjectUUID,
    headerContentUnit,
    isLastStepUnit,
    mode,
    onProjectSave,
    onQuestionnaireDelete,
    projectPageRouteTree,
    projectUUID,
    stepKeyToStep,
    steps,
    stepsSections,
    useProjectUUID,
  ]);

  return (
    <ConstructorFormContainer.Component
      HeaderIcon={HeaderIcon}
      ButtonsFragment={ButtonsFragment}
      step={step}
      useText={useStepText}
    >
      <modals.InvalidForm.Component />
    </ConstructorFormContainer.Component>
  );
}

export const Component = React.memo(FormContainer);
