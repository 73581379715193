import { useEffect, useState } from 'react';

import { getCurrentLocation } from './currentLocation';
import { subscribeLocationChange } from './locationSubscription';
import { routeMatches } from './routeMatches';

export function useCurrentLocationIsMatchingTo(pathPatternOrURL: string) {
  const [isMatching, setIsMatching] = useState(() => {
    const location = getCurrentLocation();

    return location ? routeMatches(location.pathname, pathPatternOrURL) : false;
  });

  useEffect(() => {
    const location = getCurrentLocation();

    if (location && routeMatches(location.pathname, pathPatternOrURL)) {
      setIsMatching(true);
    }

    return subscribeLocationChange(location => {
      setIsMatching(routeMatches(location.pathname, pathPatternOrURL));
    });
  }, [pathPatternOrURL]);

  return isMatching;
}
