import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = { stream: M.Stream['uuid'] } & Pick<
  Partial<M.Stream>,
  'name' | 'active' | 'open'
>;

type OutputData = InputData;

export const courseEditStream = makeService<InputData, OutputData>(
  'course_edit_stream',
  'post',
  true,
);
