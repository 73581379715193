import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  lang: string;
  q: string;
};

export const getGeoSuggest = makeService<InputData, M.GeoSuggest[]>(
  'geo_suggest',
  'get',
);
