import { VennChart } from 'utils/business';

export function withoutQuestion(
  name: string,
): VennChart.SetWithoutSelectedQuestion {
  return {
    kind: 'without-selected-question',
    name,
  };
}
