import React, { useEffect } from 'react';

import { TextInput } from 'components';
import { StepContext } from 'features/project/Constructor/FormWithSteps/StepContext';
import i18nData from 'features/project/Constructor/i18n.json';
import { useText } from 'services/I18n';
import { block } from 'utils/classname';
import { naturalNumberPredicate } from 'utils/predicates';
import { useRequiredContext } from 'utils/react/RequiredContext';

import { FormExtensionProps } from '../../../types';
import { StateInstance } from '../types';
import './style.scss';

const b = block('image-question-form-extension');

const forwardConverter = (state: number | null): string =>
  state === null ? '' : state.toString();
const backwardConverter = (state: string): number | null =>
  state === '' ? null : Number(state);

const maxImagesHeaderLabel = TextInput.leftParts.Label.makeComponent({
  useLabel: () =>
    useText(i18nData).steps.shared.questions.list.image.maxImagesLabel,
});

function FormExtension({
  instance,
  className,
}: FormExtensionProps<StateInstance>) {
  const hasMultipleImages = instance.hasMultipleImages.units.value.useState();

  const maxImages = instance.maxImages.useBridgeUnitMemo<string>(
    forwardConverter,
    backwardConverter,
    naturalNumberPredicate,
  );

  const { step } = useRequiredContext(StepContext);

  useEffect(() => {
    if (!hasMultipleImages) {
      instance.maxImages.units.value.setState(null);
    }
  }, [hasMultipleImages, instance.maxImages.units.value]);

  if (step.key === 'conclusions' || !hasMultipleImages) {
    return null;
  }

  return (
    <div className={b({}, [className])}>
      <TextInput.Component
        formElementState={maxImages}
        LeftPart={maxImagesHeaderLabel}
      />
    </div>
  );
}

export const Component = React.memo(FormExtension);
