import { useMemo } from 'react';

import { I18n } from 'services';
import * as M from 'types/serverModels';

type References = Record<M.ProjectBookStatus | 'null', I18n.EntryReference>;

export function useStatusText(references: References) {
  const assignedText = I18n.useReference(references.assigned);
  const onprogressText = I18n.useReference(references.onprogress);
  const finishedText = I18n.useReference(references.finished);
  const postponedText = I18n.useReference(references.postponed);
  const deletedText = I18n.useReference(references.deleted);
  const nullText = I18n.useReference(references.null);

  const text = useMemo(() => {
    return {
      assigned: assignedText,
      onprogress: onprogressText,
      finished: finishedText,
      postponed: postponedText,
      deleted: deletedText,
      null: nullText,
    };
  }, [
    assignedText,
    onprogressText,
    finishedText,
    postponedText,
    deletedText,
    nullText,
  ]);

  return text;
}
