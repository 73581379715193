import { makePrimaryUnit } from 'utils/State';

const ticketUnit = makePrimaryUnit<string | null>(null);

const broadcastChannel =
  process.env.BUILD_TARGET === 'client' ? new BroadcastChannel('ticket') : null;

broadcastChannel?.addEventListener('message', event => {
  ticketUnit.setState(event.data);
});

export function setTicket(ticket: string | null) {
  ticketUnit.setState(ticket);

  broadcastChannel?.postMessage(ticket);
}

export function getTicket() {
  return ticketUnit.getState();
}

export function useTicket() {
  const ticket = ticketUnit.useState();

  return ticket;
}
