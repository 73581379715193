import { makeDerivedUnit } from 'utils/State';

import { ForRequest, LayerWithSelectedQuestion } from './types';

export function makeLayerForRequest(
  layer: LayerWithSelectedQuestion,
): ForRequest.Layer {
  switch (layer.kind) {
    case 'colored-markers/icons':
      return {
        kind: 'colored-markers/icons',
        question: {
          id: layer.question.id,
          kind: layer.question.kind,
          variants: layer.question.variants,
        },
        settings: {
          mode: layer.settings.mode.units.value,
          color: {
            markerShape: layer.settings.color.markerShape.units.value,
            variantsColors: makeDerivedUnit(
              layer.settings.color.variantsColors,
            ).getUnit(variants =>
              variants.map(
                (x): ForRequest.VariantColor => ({
                  color: x.color.units.value,
                  variantID: x.variantID,
                }),
              ),
            ),
          },
          icons: {
            variantsIcons: makeDerivedUnit(
              layer.settings.icons.variantsIcons,
            ).getUnit(variants =>
              variants.map(
                (x): ForRequest.VariantIcon => ({
                  icon: x.icon.units.value,
                  variantID: x.variantID,
                }),
              ),
            ),
          },
        },
      };

    case 'scale':
      return {
        kind: 'scale',
        colorRange: layer.colorRange.units.value,
        markerShape: layer.markerShape.units.value,
        pointsNumber: layer.pointsNumber.units.value,
        question: {
          id: layer.question.id,
          kind: layer.question.kind,
        },
        scaleRange: {
          from: layer.scaleRange.from.units.value,
          to: layer.scaleRange.to.units.value,
        },
      };

    case 'without-settings':
      return {
        kind: 'without-settings',
        question: {
          id: layer.question.id,
          kind: layer.question.kind,
        },
      };
  }
}
