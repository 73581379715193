import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  uuid: M.UUID;
};

export const projectStat = makeService<InputData, M.ProjectStat>(
  'project_stat',
  'get',
);
