import * as M from 'types/serverModels';

export const statusesOfDisplayableCourses: Set<M.Course['status']> = new Set<
  M.Course['status']
>([4]);

export const statusesOfDisplayableCoursesForStaffUser: Set<M.Course['status']> =
  new Set<M.Course['status']>([0, 1, 4]);

export const statusesOfDisplayableProjects: Set<M.Project['status']> = new Set<
  M.Project['status']
>([4]);

export const statusesOfDisplayableProjectsForStaffUser: Set<
  M.Project['status']
> = new Set<M.Project['status']>([0, 1, 2, 4, 8, 24, 32]);
