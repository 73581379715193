import React from 'react';

import { block } from 'utils/classname';

import './style.scss';

const b = block('has-updates-circle');

type Props = { className?: string };

function HasUpdatesCircle({ className }: Props) {
  return <div className={b({}, [className])} />;
}

export const Component = React.memo(HasUpdatesCircle);
