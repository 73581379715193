import React from 'react';

import { routeTree } from 'pages/routeTree';
import { Page } from 'services';

import * as features from './features';

type Props = features.QuestionnaireTableForm.Props;

function QuestionnaireTableForm(props: Props) {
  return <features.QuestionnaireTableForm.Component {...props} />;
}

export const path = routeTree.LANG.project.list.PROJECT.table.getPath();

export const Component = Page.makePage({
  path,
  routeProps: { exact: true },
  scrollTo: 'top-on-mount',
  Component: React.memo(QuestionnaireTableForm),
  features: Object.values(features).map(x => x.feature),
});
