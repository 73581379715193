import { FilterQuery } from 'mongodb';

import * as M from 'types/serverModels';

import { makeService } from '../utils';

type PathInputData = {};

type QueryInputData = {
  project_book_uuid: Exclude<M.ProjectBook['uuid'], undefined>;
  stage_uuid: Exclude<M.ProjectBookStage['uuid'], 'undefined'>;
  user?: M.UserXShort['login'];
  filter?: FilterQuery<M.ProjectBookTask>;
  sort?: { property: keyof M.ProjectBookTask; direction: 'ASC' | 'DESC' };
  start?: number;
  limit?: number;
};

type InputData = PathInputData & QueryInputData;

type APIOutputData = {
  project_book_task: M.ProjectBookStageTasksGetProjectBookTask[];
  count: number;
};

type OutputData = {
  projectBookTasks: M.ProjectBookStageTasksGetProjectBookTask[];
  count: number;
};

function extractResponse(data: APIOutputData): OutputData {
  return {
    projectBookTasks: data.project_book_task,
    count: data.count,
  };
}

export const list = makeService<InputData, APIOutputData, OutputData>(
  (inputData, convertToSearchParams) => {
    const { filter, ...rest } = inputData;

    return `project_book_task/?${convertToSearchParams({
      ...rest,
      filter: JSON.stringify(filter),
    })}`;
  },
  'api-v2-get',
  false,
  extractResponse,
);
