import * as R from 'ramda';
import React, { useCallback } from 'react';

import { ItemSelection } from 'components';
import { I18n } from 'services';
import * as questionIcons from 'shared/images/questionIcons';
import { PrimaryStateUnit } from 'utils/State';
import { block } from 'utils/classname';
import { useRequiredContext } from 'utils/react/RequiredContext';

import i18nData from '../../../../i18n.json';
import { QuestionsConfigContext } from '../../QuestionsConfigContext';
import { makeNewCachedInstance } from '../../cachedInstanceConstructors';
import { QuestionKey, QuestionPath, StoreCachedInstances } from '../../types';
import * as ControlsBar from '../ControlsBar';
import './style.scss';

const b = block('quiz-constructor-widget-for-question-selection');

type Props = {
  path: QuestionPath;
  cachedStoreInstancesUnit: PrimaryStateUnit<StoreCachedInstances>;
  Title?: React.FC;
};

const Header = ItemSelection.Header.makeComponent({
  useTitle: () => I18n.useText(i18nData).questions.header.title,
  useSubtitle: () => I18n.useText(i18nData).questions.header.subtitle,
});

function ConstructorForQuestionSelection({
  path,
  cachedStoreInstancesUnit,
  Title,
}: Props) {
  const { questions, questionKeyToConstructor } = useRequiredContext(
    QuestionsConfigContext,
  );

  const text = I18n.useText(i18nData).questions;

  const handleQuestionSelect = useCallback(
    (questionKey: QuestionKey) => {
      cachedStoreInstancesUnit.setState(prev => {
        const lens = R.lensPath<StoreCachedInstances, 'instances', number>([
          'instances',
          path.index,
        ]);

        return R.over(
          lens,
          instance => {
            return makeNewCachedInstance(
              questionKey,
              instance.id,
              questionKeyToConstructor,
            );
          },
          prev,
        );
      });
    },
    [cachedStoreInstancesUnit, path.index, questionKeyToConstructor],
  );

  const Content = ItemSelection.Content.makeComponent({
    data: questions.map(x => {
      const questionKey = x.key as QuestionKey;

      return {
        title: text.list[questionKey].label,
        itemKey: questionKey,
        tooltip: text.list[questionKey].tooltip,
        iconSrc: questionIcons[x.icon].src,
      };
    }),
    onSelect: handleQuestionSelect,
  });

  return (
    <div className={b()}>
      <ItemSelection.Component
        Header={Header}
        Content={Content}
        Title={Title}
        ControlsBar={ControlsBar.Component}
      />
    </div>
  );
}

export const Component = React.memo(ConstructorForQuestionSelection);
