import { constructorWidgets } from './constructorWidgets';

export type { MaterialStateInstance } from './MaterialStateInstance';

export { materialKeyToMaterialConstructorWidget } from './materialKeyToMaterialConstructorWidget';
export { makeInstanceFromServerUnit } from './makeInstanceFromServerUnit';

export { constructorWidgets };

export const materialKeys = constructorWidgets.map(x => x.key);
