import { useCallback, useMemo } from 'react';

import * as TS from 'types';

import { activeLangStateUnit } from './activeLangStateUnit';
import { datePlaceholders } from './constants';

export function getFormattedDate(
  lang: TS.Language,
  date?: string | number | Date | null,
  options?: Intl.DateTimeFormatOptions,
) {
  if (!date) {
    return datePlaceholders[lang];
  }
  return new Date(date).toLocaleDateString(lang, options);
}

export function useGetFormattedDate(options?: Intl.DateTimeFormatOptions) {
  const lang = activeLangStateUnit.useState();
  const formatter = useMemo(
    () => new Intl.DateTimeFormat(lang, options),
    [lang, options],
  );

  return useCallback(
    (date: string | number | Date | null | undefined) => {
      if (!date) {
        return datePlaceholders[lang];
      }
      if (typeof date === 'string') {
        return formatter.format(new Date(date));
      }
      return formatter.format(date);
    },
    [formatter, lang],
  );
}
