import React from 'react';

import { Page } from 'services';

import { routeTree } from '../../../routeTree';
import * as features from './features';

type Props = features.Assignees.Props;

function Assignees(props: Props) {
  return <features.Assignees.Component {...props} />;
}

export const path =
  routeTree.LANG[
    'project-assignment'
  ].list.PROJECT_ASSIGNMENT.assignees.getPath();

export const Component = Page.makePage({
  path,
  scrollTo: 'none',
  Component: React.memo(Assignees),
  features: Object.values(features).map(x => x.feature),
});
