import { useCallback } from 'react';

type Options = {};

const useCustomKeyboardScrollHandler = <
  T extends Element = Element,
>({}: Options = {}) => {
  const handleKeyDown: React.KeyboardEventHandler<T> = useCallback(event => {
    if (
      [
        'ArrowUp',
        'ArrowDown',
        'ArrowLeft',
        'ArrowRight',
        'Space',
        'End',
        'Home',
        'PageDown',
        'PageUp',
      ].indexOf(event.code) > -1
    ) {
      event.preventDefault();

      const behavior = event.repeat ? 'auto' : 'smooth';

      switch (event.code) {
        case 'ArrowUp': {
          event.currentTarget.scrollBy({ top: -40, behavior });

          break;
        }
        case 'ArrowDown': {
          event.currentTarget.scrollBy({ top: 40, behavior });

          break;
        }
        case 'ArrowLeft': {
          event.currentTarget.scrollBy({ left: -40, behavior });

          break;
        }
        case 'ArrowRight': {
          event.currentTarget.scrollBy({ left: 40, behavior });

          break;
        }
        case 'Space': {
          event.currentTarget.scrollBy({
            top: event.currentTarget.scrollHeight / 3,
            behavior,
          });

          break;
        }
        case 'End': {
          event.currentTarget.scrollTo({
            top: event.currentTarget.scrollHeight,
            behavior,
          });

          break;
        }
        case 'Home': {
          event.currentTarget.scrollTo({ top: 0, behavior });

          break;
        }
        case 'PageDown': {
          event.currentTarget.scrollBy({
            top: event.currentTarget.scrollHeight / 3,
            behavior,
          });

          break;
        }
        case 'PageUp': {
          event.currentTarget.scrollBy({
            top: -event.currentTarget.scrollHeight / 3,
            behavior,
          });

          break;
        }
      }
    }
  }, []);

  return handleKeyDown;
};

export { useCustomKeyboardScrollHandler };
