import { serverProjectDataUnit } from 'features/project/Constructor/units';
import { getProjectLanguage } from 'features/project/Constructor/utils';
import { API, I18n } from 'services';
import * as M from 'types/serverModels';
import { QuizWrite } from 'utils/business';

import { makeServerQuestion } from './makeServerQuestion';
import { descriptionState } from './units/description';
import { questionInstancesUnit } from './units/instances';
import { quizUnit } from './units/quizUnit';

const saveQuiz = async (quiz: M.QuizWriteData, uuid?: M.UUID) => {
  if (uuid) {
    return API.services.quiz.put.callPromised({ uuid, quiz });
  } else {
    return API.services.quiz.post.callPromised({ quiz });
  }
};

export function saveConclusions() {
  const instances = questionInstancesUnit.getState();
  const lang = getProjectLanguage();
  const quiz = quizUnit.getState();
  const project = serverProjectDataUnit.getState();

  const questions = instances
    .map(makeServerQuestion)
    .filter((x): x is M.QuizQuestion => x !== null);

  const isNotEmpty =
    questions.length > 0 ||
    descriptionState.units.value.getState() !== '' ||
    project?.conclusions;

  if (isNotEmpty) {
    const quizWriteData = {
      questions,
      description: I18n.makeMultilangFormState(
        descriptionState,
        quiz?.description,
      ).getMergedMultilingString(lang),
      conclusions: true,
    };

    if (!QuizWrite.equals(quiz, quizWriteData)) {
      return saveQuiz(quizWriteData, project?.conclusions || quiz?.uuid).then(
        quiz => quizUnit.setState(quiz),
      );
    }
  }
  return null;
}
