import { API, I18n } from 'services';
import { CREDENTIALS_CHECK_RESULT } from 'shared/constants';
import * as TS from 'types';
import { makeSingleUnitValidator } from 'utils/validators';

import i18nData from '../../i18n.json';
import { someErrorReference } from '../../i18nSharedReferences';

export const loginInexistenceValidator = () =>
  makeSingleUnitValidator((value: string) => {
    return API.services.checkCredentials
      .callPromised({ login: value })
      .then((result): TS.ValidResult | TS.InvalidResult => {
        if (result.login === CREDENTIALS_CHECK_RESULT.success) {
          return { kind: 'valid' };
        }

        return {
          kind: 'invalid',
          messageReference: I18n.makeEntryReference(
            i18nData,
            data => data.loginField.error.alreadyExists,
          ),
        };
      })
      .catch(
        (): TS.InvalidResult => ({
          kind: 'invalid',
          messageReference: someErrorReference,
        }),
      );
  }, 500);
