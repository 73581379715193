import { format, isSameYear } from 'date-fns';
import { useCallback } from 'react';

import { activeLangStateUnit } from './activeLangStateUnit';
import { dateFormatLocales } from './constants';

type Options = {
  isYearHiddenForCurrentYear: boolean;
};

export function useGetFormattedTextDate() {
  const lang = activeLangStateUnit.useState();

  return useCallback(
    (
      date: string,
      { isYearHiddenForCurrentYear }: Options = {
        isYearHiddenForCurrentYear: false,
      },
    ) => {
      if (date === '') {
        return '';
      }

      const timestamp = Date.parse(date);

      const isCurrentYear = isSameYear(timestamp, Date.now());

      const options = { locale: dateFormatLocales[lang] };

      if (isYearHiddenForCurrentYear && isCurrentYear) {
        return format(timestamp, 'd MMMM', options);
      }

      return format(timestamp, 'PPP', options);
    },
    [lang],
  );
}
