import React from 'react';

import { Device } from 'services';
import { block } from 'utils/classname';

type Props = {
  className?: string;
};

function Divider({ className }: Props) {
  const device = Device.unit.useState();

  const b = block(device === 'mobile' ? 'm-extra-menu' : 'extra-menu');

  return <li className={b('divider', {}, [className])} />;
}

export const Component = React.memo(Divider) as typeof Divider;
