import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  login: M.User['login'];
};

type OutputData = M.StoreUserPurchaseListPurchase[] | undefined;

export const storeUserPurchaseList = makeService<InputData, OutputData>(
  'store_user_purchase_list',
  'get',
);
