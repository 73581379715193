import {
  WidgetNavigationControls,
  WidgetDeleteControl,
} from 'features/project/Constructor/subfeatures';
import { I18n } from 'services';
import { useRequiredContext } from 'utils/react/RequiredContext';

import i18nData from '../../../../../../i18n.json';
import { WidgetContext } from '../Context';

export const Component = () => {
  const text = I18n.useText(i18nData).steps.shared.questions;

  const { widgetInstanceViewKind, onDelete, onMoveNext, onMovePrev } =
    useRequiredContext(WidgetContext);

  return (
    <>
      <WidgetNavigationControls.Component
        onMoveNext={onMoveNext}
        onMovePrev={onMovePrev}
      />
      <WidgetDeleteControl.Component
        confirmMessage={
          widgetInstanceViewKind === 'question-instance'
            ? text.confirmDeleteMessage
            : undefined
        }
        onDelete={onDelete}
      />
    </>
  );
};
