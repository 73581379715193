export function setItem(key: string, value: string) {
  try {
    localStorage.setItem(key, value);
  } catch (error) {
    console.warn('got error on local storage item set', error);
  }
}

export function getItem(key: string) {
  if (process.env.BUILD_TARGET === 'client') {
    return localStorage.getItem(key);
  }

  return null;
}

export function removeItem(key: string) {
  return localStorage.removeItem(key);
}
