import { useMemo } from 'react';

import { I18n } from 'services';
import { USER_STATUS } from 'shared/constants';
import * as M from 'types/serverModels';

type References = Record<M.UserStatus, I18n.EntryReference>;

export function useStatusText(references: References) {
  const pendingText = I18n.useReference(references[USER_STATUS.pending]);
  const unknownText = I18n.useReference(references[USER_STATUS.unknown]);
  const activeText = I18n.useReference(references[USER_STATUS.active]);
  const bannedText = I18n.useReference(references[USER_STATUS.banned]);

  const userStatusText = useMemo(() => {
    return {
      [USER_STATUS.pending]: pendingText,
      [USER_STATUS.unknown]: unknownText,
      [USER_STATUS.active]: activeText,
      [USER_STATUS.banned]: bannedText,
    };
  }, [pendingText, unknownText, activeText, bannedText]);

  return userStatusText;
}
