import { isRequired } from 'features/project/Constructor/i18nSharedReferences';
import * as M from 'types/serverModels';
import { FormElementState, makeFormElementState } from 'utils/FormState';
import { makePrimaryUnit } from 'utils/State';
import { nonNull } from 'utils/validators';

import {
  MaterialConstructorWidget,
  SharedInstanceStateConstructor,
} from '../../types';
import * as FormExtension from './FormExtension';
import { StateInstance } from './types';

export type { StateInstance };

export function makeServerUnit(
  quizState: FormElementState<M.Quiz['uuid'] | null>,
): Partial<M.TestUnit> {
  if (!quizState.getValue()) {
    return {};
  }
  return {
    type: 'test',
    quiz_uuid: quizState.getValue() || undefined,
  };
}

export const constructorWidget: MaterialConstructorWidget<
  'test',
  StateInstance,
  typeof makeInstance
> = {
  key: 'test',
  makeInstance,
  FormExtension: FormExtension.Component,
};

function makeInstance(
  makeSharedInstanceState: SharedInstanceStateConstructor,
  initialQuiz: M.Quiz['uuid'] | null = null,
): StateInstance {
  const quizState = makeFormElementState<M.UUID | null>(initialQuiz, [
    nonNull(isRequired),
  ]);

  return {
    kind: 'test',
    quiz: quizState,
    makeServerUnit: () => makeServerUnit(quizState),
    ...makeSharedInstanceState(makePrimaryUnit([quizState.formNode])),
  };
}
