import { Language } from 'types';
import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  lang: Language;
};

export const courseList = makeService<InputData, M.Course[]>(
  'course_list',
  'get',
);
