import {
  makeDerivedUnit,
  makeMappingUnit,
  makeMappingUnitFromUnit,
  makePrimaryUnit,
  PrimaryStateUnit,
} from 'utils/State';

import { StoreCachedInstances } from '../types';

export const makeHasSomeInputUnit = (
  cachedStoreInstancesUnit: PrimaryStateUnit<StoreCachedInstances>,
) =>
  makeMappingUnitFromUnit(
    makeDerivedUnit(cachedStoreInstancesUnit).getUnit(instances => {
      if (instances.kind !== 'ungrouped' || instances.instances.length !== 2) {
        return makePrimaryUnit(true);
      }

      const [firstInstance, secondInstance] = instances.instances;

      if (
        firstInstance.kind !== 'question-instance' ||
        secondInstance.kind !== 'select-instance-kind'
      ) {
        return makePrimaryUnit(true);
      }

      return makeMappingUnitFromUnit(
        makeDerivedUnit(firstInstance.activeQuestionKeyUnit).getUnit(
          questionKey => {
            if (questionKey !== 'map') {
              return makePrimaryUnit(true);
            }

            const stateInstance = firstInstance.instancesCache[questionKey];

            return makeDerivedUnit(
              makeMappingUnit([
                stateInstance.questionText.formElementState.units.value,
                stateInstance.questionExplanation.formElementState.units.value,
                stateInstance.shouldShowFiguresOnMap.units.value,
                stateInstance.isRequired.units.value,
              ]),
            ).getUnit(
              ([
                questionText,
                questionExplanation,
                shouldShowFigures,
                isRequired,
              ]) => {
                const questionTextInitialState =
                  stateInstance.questionText.formElementState.units.value
                    .initialState;
                return (
                  questionText !==
                    (typeof questionTextInitialState === 'function'
                      ? questionTextInitialState()
                      : questionTextInitialState) ||
                  questionExplanation !==
                    stateInstance.questionExplanation.formElementState.units
                      .value.initialState ||
                  shouldShowFigures !==
                    stateInstance.shouldShowFiguresOnMap.units.value
                      .initialState ||
                  isRequired !==
                    stateInstance.isRequired.units.value.initialState
                );
              },
            );
          },
        ),
      );
    }),
  );
