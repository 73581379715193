type ScrollableProperties = {
  [key: string]: boolean;
};

const scrollableProperties: ScrollableProperties = {
  auto: true,
  scroll: true,
};

export function isScrollable(element: HTMLElement) {
  const { overflowY, overflowX } = window.getComputedStyle(element);

  const canScrollY = scrollableProperties[overflowY];
  const canScrollX = scrollableProperties[overflowX];

  return canScrollY || canScrollX;
}
