import React from 'react';

import { useRequiredContext } from 'utils/react/RequiredContext';

import { QuestionWidgetContext } from '../Context';
import { DependenciesContext } from './Dependencies';
import * as EditMode from './EditMode';
import * as PreviewMode from './PreviewMode';

type Props = {};

function Header({}: Props) {
  const { cachedInstance } = useRequiredContext(QuestionWidgetContext);

  const mode = cachedInstance.mode.useState();

  switch (mode) {
    case 'edit':
      return <EditMode.Component />;
    case 'preview':
      return <PreviewMode.Component />;
  }
}

export const Component = React.memo(Header) as typeof Header;
export { DependenciesContext };
