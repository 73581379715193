import { Merge } from 'type-fest';

import { PROJECT_BOOK_TASK_PRIORITY } from 'shared/constants';
import * as M from 'types/serverModels';

export function makeTask<T extends Partial<M.ProjectBookStagePostTask>>(
  data: T,
): Merge<M.ProjectBookTaskTemplate, T> {
  return {
    name: '',
    description: '',
    status: 'active',
    estimation: 0,
    priority: PROJECT_BOOK_TASK_PRIORITY.medium,
    assignees: [],
    materials: [],
    linked_tasks: [],
    ...data,
  } as Merge<M.ProjectBookTaskTemplate, T>;
}
