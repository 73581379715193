import React from 'react';

import { TextInput } from 'components';
import { I18n } from 'services';
import { FormElementState } from 'utils/FormState';

import i18nData from '../../../../../i18n.json';

type Props = {
  formElementState: FormElementState<number | null>;
  isOnlyPositiveNumbers?: boolean;
  className?: string;
};

const forwardStateConverter = (state: number | null) => {
  return state === null ? '' : String(state);
};

const backwardStatePredicate = (state: string) => {
  return state === '' ? null : Number(state);
};

function ScoreInput({
  formElementState,
  isOnlyPositiveNumbers,
  className,
}: Props) {
  const text = I18n.useText(i18nData);

  return (
    <TextInput.Component
      className={className}
      formElementState={formElementState.useBridgeUnitMemo(
        forwardStateConverter,
        backwardStatePredicate,
      )}
      placeholder={text.scoreLabel}
      errorRows={0}
      decimalPlace={2}
      isOnlyPositiveNumbers={isOnlyPositiveNumbers}
      type="number"
    />
  );
}

export const Component = React.memo(ScoreInput);
