import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  user: M.User['login'];
  object_type: 'project' | 'unit' | 'quiz';
  object: M.CourseTrackObject['uuid'];
};

type OutputData = M.UserStream;

export const courseResultWrite = makeService<InputData, OutputData>(
  'course_result_write',
  'post',
);
