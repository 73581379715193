import { constructorWidgets } from './constructorWidgets';

export type ConstructorWidgets = {
  [Widget in (typeof constructorWidgets)[number] as Widget['key']]: Widget;
};

export const materialKeyToMaterialConstructorWidget =
  constructorWidgets.reduce<ConstructorWidgets>(
    (acc, x) => ({ ...acc, [x.key]: x }),
    {} as ConstructorWidgets,
  );
