import React from 'react';

import { useRequiredContext } from 'utils/react/RequiredContext';

import { WidgetContext } from '../Context';
import { DependenciesContext } from './Dependencies';
import * as EditMode from './EditMode';
import * as PreviewMode from './PreviewMode';

type Props = {};

function Header({}: Props) {
  const { stateInstance } = useRequiredContext(WidgetContext);

  const mode = stateInstance.mode.useState();

  if (stateInstance.kind === 'notSelected') {
    return null;
  }

  switch (mode) {
    case 'edit':
      return <EditMode.Component />;
    case 'preview':
      return <PreviewMode.Component />;
  }
}

export const Component = React.memo(Header) as typeof Header;
export { DependenciesContext };
