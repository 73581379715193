import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  code: string;
};

type Article = {
  uuid: string;
  code: string;
  title: M.MultilingString;
  abstract: M.MultilingString;
};

export type ArticleBatch = {
  title: M.MultilingString;
  code: string;
  uuid: string;
  articles: Article[];
};

export const articleList = makeService<InputData, ArticleBatch>(
  'article_list',
  'get',
);
