import * as M from 'types/serverModels';

import { Question } from './types';

export function makeQuestionKind(
  questionType: M.Question['type'],
): Question['kind'] {
  switch (questionType) {
    case 'multi_choice':
      return 'with-multiple-variants';
    case 'single_choice':
      return 'with-single-variant';
    case 'number':
      return 'with-number';
    default:
      throw Error(`bad question type, ${questionType}`);
  }
}
