import React, { useMemo, useCallback } from 'react';

import { I18n } from 'services';

import i18nData from '../../../../../../i18n.json';
import { TextElementState, StateInstance } from '../../types';
import { makeMatchingRightColumnElement } from '../../utils';
import { Section } from '../components';
import * as elements from '../elements';

type Props = { instance: StateInstance };

const useTitle = () =>
  I18n.useText(i18nData).questions.list.match.rightColumn.title;

function RightColumnList({ instance }: Props) {
  const Content = useMemo(
    () =>
      React.memo(() => {
        const handleElementAdd = useCallback((element: TextElementState) => {
          Object.values(instance.matchingsUnit.getState()).forEach(right => {
            right.stateUnit.setState(prev => {
              return {
                ...prev,
                [element.uuid]: makeMatchingRightColumnElement({
                  item: element,
                  initState: () => false,
                }),
              };
            });
          });
        }, []);

        const handleElementRemove = useCallback((element: TextElementState) => {
          Object.values(instance.matchingsUnit.getState()).forEach(right => {
            right.stateUnit.setState(prev => {
              const { [element.uuid]: deleted, ...rest } = prev;

              return rest;
            });
          });
        }, []);

        return (
          <elements.Text.Component
            elementsUnit={instance.rightColumn.elementsUnit}
            sectionStateUnit={instance.rightColumnSectionState}
            onAdd={handleElementAdd}
            onRemove={handleElementRemove}
          />
        );
      }),
    [instance],
  );

  return <Section.Component Content={Content} useTitle={useTitle} />;
}

export const Component = React.memo(RightColumnList);
