import React from 'react';

import { TextSize } from 'types';
import { block } from 'utils/classname';

export type Props = {
  size?: TextSize;
  color?: 'fg-1' | 'fg-3';
  useLabel(): string;
};

const b = block('switch');

function Label({ useLabel, size, color = 'fg-1' }: Props) {
  return <div className={b('label', { size, color })}>{useLabel()}</div>;
}

const Component = React.memo(Label);

export function makeLabel(props: Props) {
  return () => <Component {...props} />;
}
