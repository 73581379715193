import { useEffect } from 'react';

import * as M from 'types/serverModels';
import { makeDerivedUnit, PrimaryStateUnit } from 'utils/State';

import * as services from '../services';
import { CallState } from '../types';

const countryRefsCallStateUnit =
  services.refLoad.makeCallStateUnit() as PrimaryStateUnit<
    CallState<M.CountryReferenceBook[]>
  >;

export const countriesCallStateUnit = makeDerivedUnit(
  countryRefsCallStateUnit,
).getUnit<
  CallState<Record<M.CountryReferenceBook['code'], M.CountryReferenceBook>>
>(countryRefsCallState => {
  if (countryRefsCallState.kind === 'successful') {
    const data = countryRefsCallState.data.reduce<
      Record<M.CountryReferenceBook['code'], M.CountryReferenceBook>
    >((acc, x) => {
      return {
        ...acc,
        [x.code]: x,
      };
    }, {});

    return {
      kind: 'successful',
      data,
    };
  }

  return countryRefsCallState;
});

const call = services.refLoad.makeCall(
  countryRefsCallStateUnit as PrimaryStateUnit<CallState<M.ReferenceBook[]>>,
);

let wasCalled = false;

export const callRefService = () => {
  if (!wasCalled) {
    wasCalled = true;

    call({ ref: 'country' });
  }
};

export function useCountryRefsCallEffect() {
  const callState = countryRefsCallStateUnit.useState();

  useEffect(() => {
    callRefService();
  }, [callState.kind]);
}

export function useCallState() {
  const callState = countriesCallStateUnit.useState();

  useCountryRefsCallEffect();

  return callState;
}
