import {
  AbstractStateUnit,
  makeDerivedUnit,
  makeMappingUnitFromUnit,
  makePrimaryUnit,
  PrimaryStateUnit,
} from 'utils/State';

import { CachedInstance } from '../types';

export const makeAllMaterialsAreConfirmedUnit = (
  instancesUnit: PrimaryStateUnit<CachedInstance[]>,
) =>
  makeDerivedUnit(makeMappingUnitFromUnit(instancesUnit)).getUnit(xs => {
    return xs
      .filter(x => x.activeMaterialKeyUnit !== 'notSelected')
      .every(x => x.mode === 'preview');
  });

export const makeProgressInvariantUnitsUnit = (
  allMaterialsAreConfirmedUnit: AbstractStateUnit<boolean>,
) => makePrimaryUnit([allMaterialsAreConfirmedUnit]);
