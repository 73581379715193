import { Get } from 'type-fest';

import { I18n } from 'services';
import * as M from 'types/serverModels';

type References = Record<
  Exclude<Get<M.ProjectBookChange, 'change.event'>, undefined>,
  I18n.EntryReference
>;

export function useEventLabelText(references: References) {
  const text = Object.entries(references).reduce(
    (acc, [key, x]) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      return { ...acc, [key]: I18n.useReference(x) };
    },
    {} as Record<
      Exclude<Get<M.ProjectBookChange, 'change.event'>, undefined>,
      string
    >,
  );

  return text;
}
