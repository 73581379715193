import React from 'react';

import { AssociatedWidgetErrorInfo } from 'features/project/Constructor/FormWithSteps/types';
import i18nData from 'features/project/Constructor/i18n.json';
import { ErrorModal } from 'features/project/Constructor/subfeatures';
import { I18n } from 'services';
import { makePrimaryUnit } from 'utils/State';

type Props = {};

export type ManipulationKind =
  | 'question-delete'
  | 'answer-delete'
  | 'question-type-change';

export type Data = {
  associatedWidgets: AssociatedWidgetErrorInfo[];
  manipulationKind: ManipulationKind;
};

const isOpenUnit = ErrorModal.makeIsOpenUnit(false);
const dataUnit = makePrimaryUnit<Data | null>(null);

export function openModal(data: Data) {
  isOpenUnit.setState(true);
  dataUnit.setState(data);
}

function UnpermittedManipulationWithWidgetData({}: Props) {
  const text = I18n.useText(i18nData);
  const errorsText = text.steps.inputDataForm.errors;

  const data = dataUnit.useState();

  if (data === null) {
    return null;
  }

  const manipulationKindToText: Record<ManipulationKind, string> = {
    'answer-delete': errorsText.cantDeleteAnswerWithAssociatedWidgets,
    'question-delete': errorsText.cantDeleteQuestionWithAssociatedWidgets,
    'question-type-change': errorsText.cantChangeQuestionType,
  };

  return (
    <ErrorModal.Component isOpenUnit={isOpenUnit}>
      {manipulationKindToText[data.manipulationKind]}
      <ol>
        {data.associatedWidgets.map((x, index) => {
          const widgetType =
            text.steps.findingsSettings.widgets[x.widgetKey].name;

          const name = x.name === '' ? text.namelessWidgetTitle : `"${x.name}"`;

          return <li key={index}>{`${name} (${widgetType})`}</li>;
        })}
      </ol>
    </ErrorModal.Component>
  );
}

export const Component = React.memo(UnpermittedManipulationWithWidgetData);
