import { useState } from 'react';

import * as TS from 'types';
import { makeFormElementState } from 'utils/FormState';
import { PrimaryStateUnit } from 'utils/State';

export * from './Component';
export { makeHeaderWithLabel } from './makeHeaderWithLabel';
export * as leftParts from './leftParts';
export * as rightParts from './rightParts';
export * as rightPartsWithinCountour from './rightPartsWithinCountour';
export * as Header from './Header';
export * as Label from './Label';
export type { RightPartPlugin } from './RightPartPlugin';

export function makeFieldState(
  initialState: string | PrimaryStateUnit<string> = '',
  validators?: TS.Validator[],
) {
  return makeFormElementState<string>(initialState, validators);
}

export function useFieldState(
  initialState: string | PrimaryStateUnit<string> = '',
) {
  const [state] = useState(() => makeFieldState(initialState));
  return state;
}
