import React from 'react';
import { Route } from 'react-router';

import * as Evaluation from './Evaluation';
import * as History from './History';
import * as Members from './Members';
import * as Notifications from './Notifications';
import * as Passport from './Passport';
import * as RecycleBin from './RecycleBin';
import * as Reflexion from './Reflexion';
import * as Results from './Results';
import * as Stages from './Stages';
import * as features from './features';

type Props = Omit<
  features.Page.Props,
  'Navigation' | 'Content' | 'useTabsState'
>;

const Content: features.Page.Props['Content'] = React.memo(({ userRole }) => {
  return (
    <>
      <Route path={Passport.path} exact>
        <Passport.Component />
      </Route>
      <Route path={Members.path} exact>
        <Members.Component />
      </Route>
      <Route path={Stages.path}>
        <Stages.Component />
      </Route>
      <Route path={Results.path}>
        <Results.Component />
      </Route>
      <Route path={RecycleBin.path}>
        <RecycleBin.Component />
      </Route>
      {process.env.RAZZLE_PROFILE !== 'MESCH' && (
        <Route path={Evaluation.path} exact>
          <Evaluation.Component />
        </Route>
      )}
      <Route path={Reflexion.path} exact>
        <Reflexion.Component />
      </Route>
      {process.env.RAZZLE_PROFILE !== 'MESCH' &&
        userRole !== 'watcher-moderator' && (
          <Route path={Notifications.path} exact>
            <Notifications.Component />
          </Route>
        )}
      <Route path={History.path} exact>
        <History.Component />
      </Route>
    </>
  );
});

function Page(props: Props) {
  return (
    <features.Page.Component
      {...props}
      Navigation={features.Navigation.Component}
      Content={Content}
      useTabsState={features.Navigation.useTabsState}
    />
  );
}

export const Component = React.memo(Page) as typeof Page;
