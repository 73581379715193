import React, { useRef, useEffect } from 'react';

import { makeLogger } from 'utils/Logger';
import { useForceUpdate } from 'utils/react';

import { Element } from './types';

function getShifts<T>(
  newState: T[],
  prevState: T[],
  getID: (model: T) => string,
): Record<string, number> {
  return newState.reduce((acc, x, index) => {
    if (getID(prevState[index]) === getID(x)) return acc;

    if (!prevState.some(y => getID(y) === getID(x))) return acc;

    return {
      ...acc,
      [getID(x)]: index,
    };
  }, {});
}

export function makeUseVerticallyMovableELements<T>(
  getID: (model: T) => string,
  loggerName?: string,
) {
  const logger = makeLogger(loggerName ?? '', loggerName !== undefined);

  return (newState: T[]): Array<Element<T>> => {
    const stage = useRef<'animation' | 'render-new-state'>('animation');
    const forceUpdate = useForceUpdate();

    const prevStateRef = useRef<T[] | null>(null);
    const modelIDToRefRef = useRef<
      Record<string, React.RefObject<HTMLDivElement>>
    >({});

    const makeNotMovedElement = (x: T): Element<T> => ({
      value: x,
      style: {},
      ref: modelIDToRefRef.current[getID(x)],
    });

    logger.log(
      'state on execution',
      stage,
      newState,
      prevStateRef.current,
      modelIDToRefRef.current,
    );

    useEffect(() => {
      return () => {
        logger.log('deinit', modelIDToRefRef.current);
        prevStateRef.current = null;
        modelIDToRefRef.current = {};
      };
    }, []);

    if (stage.current === 'render-new-state') {
      logger.log('cond 1');
      stage.current = 'animation';
      prevStateRef.current = newState;
      return newState.map(makeNotMovedElement);
    }

    if (stage.current === 'animation' && newState !== prevStateRef.current) {
      logger.log('cond 2');
      newState.forEach(x => {
        const id = getID(x);
        if (modelIDToRefRef.current[id] === undefined) {
          modelIDToRefRef.current[id] = React.createRef<HTMLDivElement>();
        }
      });

      if (prevStateRef.current === null) {
        logger.log('cond 2 1');
        prevStateRef.current = newState;
        return newState.map(makeNotMovedElement);
      } else if (newState.length === prevStateRef.current.length) {
        const shifts = getShifts(newState, prevStateRef.current, getID);
        logger.log('cond 2 2', shifts);

        if (Object.keys(shifts).length === 0) {
          logger.log('cond 2 2 1', shifts);
          prevStateRef.current = newState;
          return newState.map(makeNotMovedElement);
        }

        let transitionHandlerIsIncluded: boolean = false;

        const res = prevStateRef.current.map((x, index, array): Element<T> => {
          const id = getID(x);
          const shift = shifts[id];

          if (shift !== undefined) {
            const shiftedTo = array[shift];
            const shiftedToID = getID(shiftedTo);

            const sourceRect =
              modelIDToRefRef.current[id].current!.getBoundingClientRect();
            const destinationRect =
              modelIDToRefRef.current[
                shiftedToID
              ].current!.getBoundingClientRect();

            const diff =
              shift > index
                ? destinationRect.bottom - sourceRect.bottom
                : destinationRect.top - sourceRect.top;

            return {
              value: x,
              style: {
                transform: `translateY(${diff}px)`,
                transition: 'transform 300ms',
              },
              ref: modelIDToRefRef.current[getID(x)],
              handleTransitionEnd: (() => {
                if (transitionHandlerIsIncluded) {
                  return;
                }

                transitionHandlerIsIncluded = true;

                return (event: React.TransitionEvent<HTMLElement>) => {
                  if (
                    event.target instanceof HTMLElement &&
                    event.target.classList.contains(
                      'vertically-movable-element',
                    )
                  ) {
                    logger.log('executing transition end handler');
                    stage.current = 'render-new-state';
                    forceUpdate();
                  }
                };
              })(),
            };
          }

          return makeNotMovedElement(x);
        });

        prevStateRef.current = newState;

        logger.log('result', res);

        return res;
      }
    }

    prevStateRef.current = newState;

    return newState.map(makeNotMovedElement);
  };
}
