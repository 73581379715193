import { useMemo } from 'react';

import { makeFeature } from 'services/Feature';
import * as TS from 'types';
import * as M from 'types/serverModels';
import { makeFormElementState } from 'utils/FormState';

import i18nData from './i18n.json';
import * as subfeatures from './subfeatures';

export type { InputHandle } from './types';
export type { Props } from './Component';

export const feature = makeFeature({
  name: 'ImageInput',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "image-input" */
      './Component'
    ),
  i18nData,
  i18nSharedReferences: null,
  subfeatures: Object.values(subfeatures).map(x => x.feature),
});

export function makeFieldState<T extends M.ImageInfo>(
  initialState: T | null = null,
  validators?: TS.Validator[],
) {
  return makeFormElementState<T | null>(initialState, validators);
}

export function useFieldState<T extends M.ImageInfo>(
  initialState: T | null = null,
) {
  return useMemo(() => makeFieldState(initialState), [initialState]);
}

export const Component = feature.Component;
