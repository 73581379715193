import { Modal } from 'components';
import { Notification } from 'services';
import * as M from 'types/serverModels';
import { makePrimaryUnit, makeDerivedUnit } from 'utils/State';

export const isChatOpenUnit = Modal.makeIsOpenUnit();
export const isPreviewModalOpenedUnit = Modal.makeIsOpenUnit();
export const unreadMessagesCallStateUnit = makeDerivedUnit(
  Notification.Poller.notificationsCallStateUnit,
).getUnit(state => {
  if (state.kind !== 'successful') {
    return state;
  }

  return {
    kind: 'successful',
    data: state.data.filter(
      (notification): notification is M.MessageNotification =>
        notification.ntype === 'private_message',
    ),
  };
});
export const selectedUserUnit =
  makePrimaryUnit<M.PrivateMessagePartnerUser | null>(null);
export const partnersUnit = makePrimaryUnit<M.PrivateMessagePartner[]>([]);
export const messagesUnit = makePrimaryUnit<M.ChatMessage[]>([]);
export const isTopItemListVisible = makePrimaryUnit(false);
