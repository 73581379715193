import * as TS from 'types';
import { AbstractStateUnit, makeDerivedUnit } from 'utils/State';

import { QuestionInstance } from './questions';

export const getQuestionKindFromQuestionInstance = (
  instance: QuestionInstance,
): TS.QuizQuestionKind | AbstractStateUnit<TS.QuizQuestionKind> => {
  switch (instance.kind) {
    case 'image':
      return 'image';
    case 'map':
      return 'map';
    case 'match':
      return makeDerivedUnit(
        instance.leftColumn.isImage.units.value,
      ).getUnit<TS.QuizQuestionKind>(value =>
        value ? 'image-to-text-match' : 'text-to-text-match',
      );
    case 'number':
      return 'number';
    case 'text':
      return makeDerivedUnit(
        instance.isMultiline.units.value,
      ).getUnit<TS.QuizQuestionKind>(value =>
        value ? 'multiline-string' : 'single-line-string',
      );
    case 'variantSelection':
      return makeDerivedUnit(
        instance.hasMultipleChoice.units.value,
      ).getUnit<TS.QuizQuestionKind>(value =>
        value ? 'multiple-variants-selection' : 'single-variant-selection',
      );
  }
};
