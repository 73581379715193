import { format } from 'date-fns';
import { useCallback } from 'react';

import * as M from 'types/serverModels';

import { activeLangStateUnit } from './activeLangStateUnit';
import { datetimeFormats, datetimePlaceholders } from './constants';

export function useGetFormattedDatetime() {
  const activeLang = activeLangStateUnit.useState();

  return useCallback(
    (date: M.Datetime | number | Date | null) => {
      if (date === null) {
        return datetimePlaceholders[activeLang];
      }

      return format(new Date(date), datetimeFormats[activeLang]);
    },
    [activeLang],
  );
}
