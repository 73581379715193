import { API } from 'services';
import { InputData as InputDataBasketAdd } from 'services/API/services/storeBasketAdd';
import * as M from 'types/serverModels';

import { isOpenUnit, storeBasketAddCallStateUnit } from '../units';

type Props = {
  product: M.Product;
  redeem?: boolean;
  perk?: M.Perk;
};

export function addToBasket({ product, redeem = false, perk }: Props) {
  const currency = process.env.RAZZLE_CURRENCY;

  const inputData: InputDataBasketAdd = {
    data: {
      product: product.uuid,
      details:
        product.type === 'certificate' && perk !== undefined
          ? perk
          : { type: '' },
      redeem,
      currency,
    },
  };

  const call = API.services.storeBasketAdd.makeCall(
    storeBasketAddCallStateUnit,
  );

  const unsubscribe = storeBasketAddCallStateUnit.subscribe({
    name: 'is-open-unit-updater',
    callback: callState => {
      switch (callState.kind) {
        case 'successful': {
          unsubscribe();

          isOpenUnit.setState(true);

          break;
        }
        case 'error': {
          unsubscribe();

          break;
        }
      }
    },
  });

  call(inputData);
}
