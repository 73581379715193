import { makeFormElementState } from 'utils/FormState';

import { Fieldset } from '../types';

export const passwordMask = '\u2022'.repeat(10);

export function makeFieldsetState(): Fieldset {
  const fieldsetState = { password: makeFormElementState(passwordMask) };

  fieldsetState.password.units.disabled.setState(true);

  return fieldsetState;
}
