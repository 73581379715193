import { SetState } from 'utils/State';
import { createRequiredContext } from 'utils/react/RequiredContext';

export type Dependencies<T> = {
  setActiveOption: SetState<T>;
  setSelectIsExpanded: SetState<boolean>;
  option: T;
};

export const DependenciesContext = createRequiredContext<Dependencies<any>>();
