import * as M from 'types/serverModels';

export function getFile(data: M.Unit | M.ProjectBookUnit) {
  switch (data.type) {
    case 'print':
    case 'presentation': {
      return data.file || null;
    }
    default: {
      return null;
    }
  }
}
