import React from 'react';

import { block } from 'utils/classname';

import { LabelProps } from '../types';
import './style.scss';

const b = block('side-tab-text-label');

type Props = {
  useLabel(): string;
  renderCounter?(): string;
  useIsActive(): boolean;
};

function TabTextLabel({ useLabel, renderCounter, useIsActive }: Props) {
  const isActive = useIsActive();

  return (
    <div className={b({ active: isActive })}>
      {useLabel()}
      {renderCounter && (
        <span className={b('counter')}>
          {'\u00A0'}
          {!isActive && renderCounter()}
        </span>
      )}
    </div>
  );
}

export const Component = React.memo(TabTextLabel);

export const makeComponent =
  (
    useLabel: () => string,
    renderCounter?: () => string,
  ): React.FC<LabelProps> =>
  props => (
    <Component
      useIsActive={props.useIsActive}
      useLabel={useLabel}
      renderCounter={renderCounter}
    />
  );
