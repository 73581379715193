import React, { useEffect } from 'react';

import { Preloader } from 'components';
import { ProjectWriteContext } from 'features/project/Constructor/FormWithSteps/ProjectWriteContext';
import i18nData from 'features/project/Constructor/i18n.json';
import { ConstructorStepSection } from 'features/project/Constructor/subfeatures';
import { I18n } from 'services';
import { userStateUnit } from 'shared/stateUnits';
import { makeFormElementState } from 'utils/FormState';
import { makeDerivedUnit, makeMappingUnitFromUnit } from 'utils/State';
import { block } from 'utils/classname';
import { useRequiredContext } from 'utils/react/RequiredContext';

import { privateAccessGroupsState } from '../../units';
import * as Group from './Group';
import './style.scss';

type Props = {};

const b = block('groups-section');

const selectedGroupsUnit = makeDerivedUnit(
  makeMappingUnitFromUnit(privateAccessGroupsState),
).getUnit(x => x.flatMap(x => x.checkboxState.getValue()));

function Groups({}: Props) {
  const text = I18n.useText(i18nData).steps.access.sections.yourGroups;
  const userState = userStateUnit.useState();

  const privateAccessGroups = privateAccessGroupsState.useState();

  const { saveProject } = useRequiredContext(ProjectWriteContext);

  useEffect(() => {
    if (userState.kind === 'loaded') {
      const user = userState.user;

      const leadedGroupsIDs = user.groups_leader_stack?.map(x => x.uuid) ?? [];
      const groups = [
        ...(user.groups_leader_stack ?? []),
        ...(user.groups_member_stack?.filter(
          x => !leadedGroupsIDs.some(y => y === x.uuid),
        ) ?? []),
      ];

      privateAccessGroupsState.setState(
        groups.map(x => ({
          group: x,
          checkboxState: makeFormElementState(false),
        })),
      );
    }
  }, [userState]);

  useEffect(() => {
    return selectedGroupsUnit.subscribe({
      name: 'saving-project',
      callback: saveProject,
    });
  }, [saveProject]);

  return (
    <ConstructorStepSection.Component
      className={b()}
      title={text.title}
      subtitle={text.tooltip}
    >
      {userState.kind === 'pending' && <Preloader.Component size="s" />}
      <div className={b('groups')}>
        {privateAccessGroups.map(x => (
          <Group.Component key={x.group.uuid} groupState={x} />
        ))}
      </div>
    </ConstructorStepSection.Component>
  );
}

export const Component = React.memo(Groups);
