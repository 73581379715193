import React from 'react';

import { Page } from 'services';
import * as M from 'types/serverModels';

import { routeTree } from '../../routeTree';
import * as features from './features';

type Props = {
  user: M.User;
};

function MyGroups({ user }: Props) {
  return (
    <>
      <features.MyGroups.Component user={user} />
    </>
  );
}

export const Component = Page.makePage({
  path: routeTree.LANG['personal-account']['my-groups'].getPath(),
  routeProps: { exact: true },
  scrollTo: 'top-on-mount',
  Component: React.memo(MyGroups),
  features: Object.values(features).map(x => x.feature),
});
