import { InstanceView, StoreCachedInstances } from './types';

export function getInstanceViews(
  instances: StoreCachedInstances,
): InstanceView[] {
  switch (instances.kind) {
    case 'ungrouped':
      return instances.instances.map(
        (x, index): InstanceView => ({
          kind: 'widget',
          instance: x,
          path: { kind: 'ungrouped', index },
        }),
      );
    case 'grouped':
      return instances.groups.flatMap((x, groupIndex): InstanceView[] => [
        { kind: 'group-header', id: x.id, name: x.name },
        ...x.instances.map(
          (x, widgetIndex): InstanceView => ({
            kind: 'widget',
            instance: x,
            path: { kind: 'grouped', groupIndex, widgetIndex },
          }),
        ),
      ]);
  }
}
