import { getProjectLanguage } from 'features/project/Constructor/utils';
import { I18n } from 'services';
import * as M from 'types/serverModels';

import { Question } from '../shared';

export function makeServerStages(
  instances: Question.StoreCachedInstances,
): M.ProjectStage[] {
  return instances.kind === 'ungrouped'
    ? []
    : (function loop(
        groups: Question.InstanceGroup[],
        acc: M.ProjectStage[],
        instancesCount: number,
      ): M.ProjectStage[] {
        if (groups.length === 0) {
          return acc;
        }

        const [x, ...xs] = groups;

        return loop(
          xs,
          [
            ...acc,
            {
              title: I18n.makeMultilingString([
                { lang: getProjectLanguage(), value: x.name },
              ]),
              start: x.instances.length === 0 ? -1 : instancesCount,
              stop:
                x.instances.length === 0
                  ? -1
                  : instancesCount + x.instances.length - 1,
            },
          ],
          instancesCount + x.instances.length,
        );
      })(
        instances.groups.map(x => ({
          ...x,
          instances: x.instances.filter(
            instance => instance.kind !== 'select-instance-kind',
          ),
        })),
        [],
        0,
      );
}
