import { makeFeature } from 'services/Feature';

export { pageModeUnit } from './pageModeUnit';

export const feature = makeFeature({
  name: 'SwitchPageMode',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "switch-page-mode" */
      './Component'
    ),
  i18nSharedReferences: null,
  i18nData: null,
  subfeatures: [],
});
export const Component = feature.Component;
