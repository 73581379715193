import { createRequiredContext } from 'utils/react/RequiredContext';

import { ConstructorWidgetConfigForFindingsWidget, WidgetKey } from '../types';
import { FindingsWidgetInstance } from '../widgets';

export type FindingsConstructorWidgetContextData = {
  constructorWidget: ConstructorWidgetConfigForFindingsWidget<
    WidgetKey,
    any,
    any
  >;
  stateInstance: FindingsWidgetInstance;
};

export const FindingsConstructorWidgetContext =
  createRequiredContext<FindingsConstructorWidgetContextData>();
