import * as R from 'ramda';

export function renameKeys<T>(
  obj: Record<string, T>,
  config: Record<string, string>,
): Record<string, T> {
  return Object.keys(obj).reduce(
    (acc, key) => R.assoc(config[key] || key, obj[key], acc),
    {},
  );
}
