import React from 'react';

import { NumberQuestion } from 'features/project/Constructor/subfeatures';
import { useFormElementState } from 'utils/FormState';

import { PreviewModeFormProps } from '../../../types';
import { StateInstance } from '../types';

function PreviewModeForm({}: PreviewModeFormProps<StateInstance>) {
  const formElementState = useFormElementState('');

  return <NumberQuestion.Component formElementState={formElementState} />;
}

export const Component = React.memo(PreviewModeForm) as typeof PreviewModeForm;
