import React, { useCallback, useMemo } from 'react';

import { IconContainer, InfoPopup } from 'components';
import { block } from 'utils/classname';

import './style.scss';

const b = block('item-selection-card');

type Props<T> = {
  title: string;
  tooltip?: string;
  iconSrc: string;
  itemKey: T;
  className?: string;
  onSelect(itemKey: T): void;
};

function Card<T>({
  title,
  tooltip,
  iconSrc,
  itemKey,
  className,
  onSelect,
}: Props<T>) {
  const Body = useCallback(
    () => <div className={b('tooltip')}>{tooltip}</div>,
    [tooltip],
  );

  const Icon = useMemo(() => {
    return React.memo(() => {
      const handleCardClick = useCallback(() => {
        onSelect(itemKey);
      }, []);

      return (
        <IconContainer.Component
          size="xl"
          className={b('container')}
          onClick={handleCardClick}
        >
          <img className={b('icon')} src={iconSrc} alt="question-icon" />
          {title}
        </IconContainer.Component>
      );
    });
  }, [iconSrc, itemKey, onSelect, title]);

  if (!tooltip) {
    return (
      <div className={b({}, [className])}>
        <Icon />
      </div>
    );
  }

  return (
    <InfoPopup.Component
      className={b({}, [className])}
      Body={Body}
      Icon={Icon}
      centered
    />
  );
}

export const Component = React.memo(Card);
