import React from 'react';

import { routeTree } from 'pages/routeTree';
import { Page } from 'services';

import * as features from './features';

type Props = features.General.Props;

function General(props: Props) {
  return <features.General.Component {...props} />;
}

export const path = routeTree.LANG.project.list.PROJECT.general.getPath();

export const Component = Page.makePage({
  path,
  routeProps: { exact: false },
  scrollTo: 'none',
  Component: React.memo(General) as typeof General,
  features: Object.values(features).map(x => x.feature),
}) as typeof General;
