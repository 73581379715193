import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  user: M.User['login'];
};

export const certificateList = makeService<InputData, M.Certificate[]>(
  'certificate_list',
  'get',
);
