import * as R from 'ramda';
import React from 'react';

import { block } from 'utils/classname';

import { ChildEntrySection } from '../../../types';
import * as ChildEntry from './ChildEntry';
import './style.scss';

const b = block('m-main-menu-content');

type Props = {
  childrenSections: ChildEntrySection[];
  bold: boolean;
};

function Content({ childrenSections, bold }: Props) {
  const makeSectionRenderer =
    (section: ChildEntrySection) => (key: React.Key) => (
      <div key={key} className={b('section')}>
        {section.map((entry, index) => (
          <ChildEntry.Component
            bold={bold}
            key={index}
            childEntry={entry}
            className={b('child-entry')}
          />
        ))}
      </div>
    );

  const sectionRenderers = childrenSections.map(makeSectionRenderer);

  const renderSeparator = (key: React.Key) => (
    <div key={key} className={b('separator')} />
  );

  const contentRenderers = R.intersperse(renderSeparator, sectionRenderers);

  return (
    <div className={b()}>
      {contentRenderers.map((render, index) => render(index))}
    </div>
  );
}

export const Component = React.memo(Content) as typeof Content;
export const makeComponent = (props: Props) => () => <Component {...props} />;
