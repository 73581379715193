import { useEffect } from 'react';

import { userStateUnit } from 'shared/stateUnits';

import { useTicket } from '../dependencies';
import { userSocketUnit, isUserSocketConnectedUnit } from './units';

type Props = {};

function UserSocketInitializer({}: Props) {
  const ticket = useTicket();
  const userState = userStateUnit.useState();

  const socket = userSocketUnit.useState();

  const userLogin = userState.kind === 'loaded' ? userState.user.login : null;

  useEffect(() => {
    if (socket === null || userLogin === null || ticket === null) {
      return;
    }

    const unsubscribeJoin = isUserSocketConnectedUnit.subscribe({
      name: 'join-emitter',
      callback: isConnected => {
        if (!isConnected) {
          return;
        }

        socket.emit('join', `user-${userLogin}`, ticket);
      },
    });

    socket.connect();

    return () => {
      unsubscribeJoin();

      socket.disconnect();
    };
  }, [socket, userLogin, ticket]);

  return null;
}

export const Component = UserSocketInitializer;
