import React from 'react';

import { block } from 'utils/classname';

import './style.scss';

const b = block('static-tooltip-testimonial-default-slide');

type Props = React.PropsWithChildren<{}>;

function DefaultSlide({ children }: Props) {
  return <div className={b()}>{children}</div>;
}

export const Component = React.memo(DefaultSlide) as typeof DefaultSlide;
