import {
  AbstractStateUnit,
  makeDerivedUnit,
  makeMappingUnitFromUnit,
  makePrimaryUnit,
} from 'utils/State';
import { Layer } from 'utils/business';
import {
  QuestionForLayer,
  QuestionForLayerWithoutSettings,
  QuestionForLayerWithScale,
} from 'utils/business/Layer';

import { enumeratedQuestionInstances } from '../../../InputDataForm/units/instances';

export const questionsForLayersUnit = makeMappingUnitFromUnit(
  makeDerivedUnit(enumeratedQuestionInstances).getUnit(instances =>
    instances.flatMap(({ instance }): AbstractStateUnit<QuestionForLayer>[] => {
      switch (instance.kind) {
        case 'variantSelection':
          return [
            makeDerivedUnit(instance.hasMultipleChoice.units.value).getUnit(
              (hasMultipleChoice): QuestionForLayer => {
                return {
                  kind: hasMultipleChoice
                    ? 'multiple-variants-selection'
                    : 'single-variant-selection',
                  id: instance.id,
                  name: instance.questionText.formElementState.units.value,
                  variants: instance.variants,
                };
              },
            ),
          ];

        case 'number':
        case 'sensorData': {
          const question: QuestionForLayerWithScale = {
            kind: instance.kind === 'number' ? 'number' : 'stream',
            id: instance.id,
            name: instance.questionText.formElementState.units.value,
          };

          return [makePrimaryUnit(question)];
        }

        case 'text':
          return [
            makeDerivedUnit(instance.isMultiline.units.value).getUnit(
              (isMultiline): QuestionForLayerWithoutSettings => {
                return {
                  kind: isMultiline ? 'multiline-string' : 'single-line-string',
                  id: instance.id,
                  name: instance.questionText.formElementState.units.value,
                };
              },
            ),
          ];
        case 'image':
        case 'sound':
        case 'video':
        case 'date': {
          const question: QuestionForLayerWithoutSettings = {
            kind: instance.kind,
            id: instance.id,
            name: instance.questionText.formElementState.units.value,
          };

          return [makePrimaryUnit(question)];
        }
        case 'map':
          return [
            makeDerivedUnit(
              instance.shouldShowFiguresOnMap.units.value,
            ).getUnit(
              (shouldShowFiguresOnMap): QuestionForLayerWithoutSettings => {
                return {
                  id: instance.id,
                  kind: shouldShowFiguresOnMap ? 'figures' : 'map',
                  name: instance.questionText.formElementState.units.value,
                };
              },
            ),
          ];
        default:
          return [];
      }
    }),
  ),
);

export const questionIDToQuestionForLayerUnit = makeDerivedUnit(
  questionsForLayersUnit,
).getUnit(questions => {
  return questions.reduce<Record<string, Layer.QuestionForLayer>>((acc, x) => {
    return {
      ...acc,
      [x.id]: x,
    };
  }, {});
});
