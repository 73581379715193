import { quizLanguageUnit } from 'features/quiz/Constructor/units';
import { I18n } from 'services';
import { MultilingString } from 'types/serverModels';
import { FormElementState, makeFormElementState } from 'utils/FormState';

import {
  QuestionConstructor,
  SharedInstanceStateConstructor,
} from '../../types';
import * as FormExtension from './FormExtension';
import * as HeaderExtension from './HeaderExtension';
import * as PreviewModeForm from './PreviewModeForm';
import { ServerQuestion, StateInstance } from './types';

export type { StateInstance };

export function makeServerQuestion(
  matchingState: I18n.MultilangFormState,
  scoreState: FormElementState<number | null>,
  isMultilineState: FormElementState<boolean>,
): Partial<ServerQuestion> {
  const isMultiline = isMultilineState.units.value.getState();
  const matching = matchingState.getMergedMultilingString(
    quizLanguageUnit.getState(),
  );

  return {
    type: isMultiline ? 'text' : 'string',
    matching: isMultiline ? undefined : matching,
    matchings: isMultiline
      ? undefined
      : matching.map(x => x.value || '').filter(Boolean),
    score: isMultiline ? 0 : Number(scoreState.getValue()),
  };
}

export const constructor: QuestionConstructor<
  'text',
  StateInstance,
  typeof makeInstance
> = {
  key: 'text',
  icon: 'text',
  makeInstance,
  HeaderExtension: HeaderExtension.Component,
  FormExtension: FormExtension.Component,
  PreviewModeForm: PreviewModeForm.Component,
};

type InitialState = {
  matching: MultilingString;
  score: number;
  isMultiline: boolean;
};

export function makeInstance(
  makeSharedInstanceState: SharedInstanceStateConstructor,
  initialState?: InitialState,
): StateInstance {
  const matchingState = I18n.makeMultilangFormState(
    makeFormElementState(
      I18n.getOptionalMultilingTranslation(
        quizLanguageUnit.getState(),
        initialState?.matching,
      ),
    ),
    initialState?.matching,
  );
  const scoreState = makeFormElementState(initialState?.score ?? null);
  const isMultilineState = makeFormElementState<boolean>(
    !!initialState?.isMultiline,
  );

  return {
    kind: 'text',
    matching: matchingState,
    score: scoreState,
    isMultiline: isMultilineState,
    makeServerQuestion: () =>
      makeServerQuestion(matchingState, scoreState, isMultilineState),
    ...makeSharedInstanceState(),
  };
}
