import { makeDerivedUnit } from 'utils/State';

import * as Question from '../Questions/Question';
import { descriptionState } from './description';
import { cachedStoreInstancesUnit } from './instances';

export const hasSomeInputUnit = makeDerivedUnit(
  cachedStoreInstancesUnit,
  descriptionState.units.value,
).getUnit(
  (instances, description) =>
    (instances as Question.StoreCachedInstances).instances.length > 0 ||
    description !== '',
);
