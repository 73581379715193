import { widgetTypeToQuestionKindMapping } from 'shared/constants';
import * as TS from 'types';

import { LayerVariant } from './types';

export function makeVariantFromQuestionKind(
  questionKind: TS.QuestionKind,
): LayerVariant {
  const entry = Object.entries(widgetTypeToQuestionKindMapping.map).find(
    ([_, questionKinds]) => {
      return questionKinds.includes(questionKind);
    },
  );

  if (entry === undefined) {
    throw new Error(
      `can not find layer variant for question kind "${questionKind}"`,
    );
  }

  return entry[0] as LayerVariant;
}
