import { useEffect } from 'react';

import { userStateUnit } from 'shared/stateUnits';
import * as TS from 'types';
import * as M from 'types/serverModels';
import { makeDerivedUnit, PrimaryStateUnit } from 'utils/State';

import { makeCallStateUnitsDeriver } from '../makeCallStateUnitsDeriver';
import * as services from '../services';
import { CallState } from '../types';
import { quantityRefsCallStateUnit } from './quantities';

const unitRefsCallStateUnit =
  services.refLoad.makeCallStateUnit() as PrimaryStateUnit<
    CallState<M.UnitReferenceBook[]>
  >;

const unitsCallStateUnit = makeDerivedUnit(
  unitRefsCallStateUnit,
  quantityRefsCallStateUnit,
  userStateUnit,
).getUnit<CallState<Record<M.QuantityReferenceBook['uuid'], TS.UnitReference>>>(
  (unitRefsCallState, quantityRefsCallState, userState) => {
    const callState = makeCallStateUnitsDeriver('every')(
      unitRefsCallState,
      quantityRefsCallState,
    );

    if (callState.kind === 'initial' || userState.kind === 'null') {
      return { kind: 'initial' };
    }

    if (callState.kind === 'pending' || userState.kind === 'pending') {
      return { kind: 'pending' };
    }

    if (callState.kind === 'error') {
      return {
        ...callState,
      };
    }

    const [units, quantities] = callState.data;
    const { user } = userState;

    const unitIDToUnitReferenceBook = units.reduce<
      Record<M.UnitReferenceBook['uuid'], M.UnitReferenceBook>
    >(
      (acc, x) => ({
        ...acc,
        [x.uuid]: x,
      }),
      {},
    );

    const data = quantities.reduce(
      (acc, x): Record<M.QuantityReferenceBook['uuid'], TS.UnitReference> => {
        const [metric, imperial] = x.units;

        return {
          ...acc,
          [x.uuid]: {
            referenceBook:
              unitIDToUnitReferenceBook[
                user.units === 'imperial' ? imperial : metric
              ],
            name: x.val,
            uuid: x.uuid,
          },
        };
      },
      {},
    );

    return {
      kind: 'successful',
      data,
    };
  },
);

let wasCalled = false;

function useUnitRefsCallState() {
  const unitRefsCallState = unitRefsCallStateUnit.useState();
  const quantityRefsCallState = quantityRefsCallStateUnit.useState();

  const callUnitRef = services.refLoad.useCall(
    unitRefsCallStateUnit as PrimaryStateUnit<CallState<M.ReferenceBook[]>>,
  );
  const callQuantityRef = services.refLoad.useCall(
    quantityRefsCallStateUnit as PrimaryStateUnit<CallState<M.ReferenceBook[]>>,
  );

  useEffect(() => {
    if (!wasCalled) {
      wasCalled = true;

      callUnitRef({ ref: 'unit' });
      callQuantityRef({ ref: 'quantity' });
    }
  }, [
    quantityRefsCallState.kind,
    unitRefsCallState.kind,
    callQuantityRef,
    callUnitRef,
  ]);
}

export function useCallState() {
  const callState = unitsCallStateUnit.useState();

  useUnitRefsCallState();

  return callState;
}
