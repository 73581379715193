import React from 'react';

import { I18n } from 'services';
import { ReactComponent as AngleArrowDownIcon } from 'shared/images/angleArrowDown.svg';
import { block } from 'utils/classname';

import i18nData from '../i18n.json';
import './style.scss';

const b = block('table-header-sort-toggle');

type View = 'ASC' | 'DESC' | 'initial';

export type Props = {
  useView(): View;
  useTitles(): { ascPropertyText: string; descPropertyText: string };
  onASCButtonClick(): void;
  onDESCButtonClick(): void;
};

function HeaderSortToggle({
  useView,
  useTitles,
  onASCButtonClick,
  onDESCButtonClick,
}: Props) {
  const text = I18n.useText(i18nData).headerSortToggle;

  const view = useView();

  const titles = useTitles();

  return (
    <div className={b()}>
      {(view === 'ASC' || view === 'initial') && (
        <button
          className={b('asc-button')}
          type="button"
          title={text.ascButton.title.replace(
            '#{property}',
            titles.ascPropertyText,
          )}
          onClick={onASCButtonClick}
        >
          <AngleArrowDownIcon />
        </button>
      )}
      {(view === 'DESC' || view === 'initial') && (
        <button
          className={b('desc-button')}
          type="button"
          title={text.descButton.title.replace(
            '#{property}',
            titles.descPropertyText,
          )}
          onClick={onDESCButtonClick}
        >
          <AngleArrowDownIcon />
        </button>
      )}
    </div>
  );
}

export const Component = React.memo(HeaderSortToggle);
