import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React, { useRef, useCallback } from 'react';

import { IconContainer } from 'components';
import { ReactComponent as TrashIcon } from 'shared/images/trash.svg';

import { ImageInput } from '../../../../../../../../subfeatures';
import { ImageElementState } from '../../../../types';
import { b } from '../b';
import { imageData } from '../constants';

export type Props = {
  element: ImageElementState;
  onDelete(element: ImageElementState): void;
};

function DraggableListItem({ element, onDelete }: Props) {
  const inputHandleRef = useRef<ImageInput.InputHandle>(null);

  const {
    attributes,
    transform,
    transition,
    isDragging,
    setNodeRef,
    listeners,
  } = useSortable({ id: element.uuid });

  const handleItemDelete: Exclude<IconContainer.Props['onClick'], undefined> =
    useCallback(() => {
      inputHandleRef.current?.delete();

      onDelete(element);
    }, [element, onDelete]);

  return (
    <li
      className={b('list-item', {
        kind: 'draggable',
        'is-dragging': isDragging,
      })}
      ref={setNodeRef}
      style={{ transform: CSS.Transform.toString(transform), transition }}
      {...attributes}
      {...listeners}
    >
      <ImageInput.Component
        formElementState={element.image}
        imageData={imageData}
        inputHandleRef={inputHandleRef}
        variant="card"
      />
      <IconContainer.Component
        className={b('list-item-delete-button')}
        tabIndex={0}
        size="s"
        backgroundDarknessLevel={2}
        onClick={handleItemDelete}
      >
        <TrashIcon />
      </IconContainer.Component>
    </li>
  );
}

export const Component = React.memo(DraggableListItem);
