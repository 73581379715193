import { CallState } from './types';

export function sumCallStates(
  a: CallState<any>,
  b: CallState<any>,
): CallState<any> {
  if (a.kind === 'initial' && b.kind === 'initial') {
    return { kind: 'initial' };
  }

  if (a.kind === 'pending' || b.kind === 'pending') {
    return { kind: 'pending' };
  }

  if (a.kind === 'error' || b.kind === 'error') {
    return { kind: 'error', message: '' };
  }

  if (a.kind === 'successful' && b.kind === 'successful') {
    return { kind: 'successful', data: null };
  }

  if (a.kind === 'successful' && b.kind === 'initial') {
    return { kind: 'pending' };
  }

  if (a.kind === 'initial' && b.kind === 'successful') {
    return { kind: 'pending' };
  }

  console.warn('unexpected call state combination', a, b);
  return { kind: 'error', message: '' };
}
