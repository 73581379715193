import React from 'react';

import { Page } from 'services';

import { routeTree } from '../../../routeTree';
import * as features from './features';

type Props = features.Projects.Props;

function Projects(props: Props) {
  return <features.Projects.Component {...props} />;
}

export const path = routeTree.LANG.course.list.COURSE.projects.getPath();

export const Component = Page.makePage({
  path,
  routeProps: { exact: true },
  scrollTo: 'none',
  Component: React.memo(Projects) as typeof Projects,
  features: Object.values(features).map(x => x.feature),
}) as typeof Projects;
