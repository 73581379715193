import { searchParamsProperty } from './constants';

export function parseSearchParams<T>(search: string): T | null {
  const params = new URLSearchParams(search);
  try {
    const jsonParams = params.get(searchParamsProperty);
    return jsonParams && JSON.parse(jsonParams);
  } catch {
    return null;
  }
}
