import { SetRequired } from 'type-fest';

import * as M from 'types/serverModels';

import { makeService } from '../../utils';

type PathInputData = {};

type QueryInputData = {};

type InputData = PathInputData & QueryInputData;

type APIOutputData = {
  project_book_templates?: SetRequired<
    M.ShortProjectBook,
    'uuid' | 'lang' | 'cdate' | 'supervisors'
  >[];
};

type OutputData = {
  projectBookTemplates: SetRequired<
    M.ShortProjectBook,
    'uuid' | 'lang' | 'cdate' | 'supervisors'
  >[];
};

function extractResponse(data: APIOutputData): OutputData {
  return {
    projectBookTemplates: data.project_book_templates || [],
  };
}

export const get = makeService<InputData, APIOutputData, OutputData>(
  ({ ticket }, convertToSearchParams) =>
    `project_book/templates/?${convertToSearchParams({ ticket })}`,
  'api-v2-get',
  false,
  extractResponse,
);
