import React, { useCallback } from 'react';

import { Dropdown } from 'components';
import { I18n } from 'services';
import { block } from 'utils/classname';

import i18nData from '../../i18n.json';
import { ProvidedProps } from '../../types';
import './style.scss';

const b = block('outlined-range-date-picker');

type Props = ProvidedProps;

const useDefaultLabel = () => {
  return I18n.useText(i18nData).variant.outlined.label;
};

function OutlinedVariant({
  formElementState,
  kind,
  title,
  useLabel = useDefaultLabel,
}: Props) {
  const useIsSelected = useCallback(() => {
    const { from, to } = formElementState.units.value.useState();

    return Boolean(from || to);
  }, [formElementState.units.value]);

  return (
    <div className={b()} data-toggle>
      <Dropdown.Component useLabel={useLabel} useIsSelected={useIsSelected} />
    </div>
  );
}

export const Component = React.memo(OutlinedVariant) as typeof OutlinedVariant;
