import { makeSharedEntryReference } from 'services/I18n/makeEntryReference';

export const videoNotSupportedReference = makeSharedEntryReference(
  data => data.videoNotSupported,
);

export const downloadVideoReference = makeSharedEntryReference(
  data => data.downloadVideo,
);

export const someErrorReference = makeSharedEntryReference(
  data => data.someError,
);
