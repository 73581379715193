import { MeschClass, UUID } from 'types/serverModels';

import { makeService } from '../../../utils';

type InputData = {
  schoolID: UUID;
};

type APIOutputData = {
  class: MeschClass[];
};

export const get = makeService<InputData, APIOutputData>(
  ({ ticket, schoolID }, convertToSearchParams) =>
    `mesch/school/${schoolID}/class/?${convertToSearchParams({ ticket })}`,
  'api-v2-get',
);
