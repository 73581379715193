import React from 'react';

import { PendingActionSubList } from 'features/project/Constructor/subfeatures';

import * as Controls from './Controls';

type Props = PendingActionSubList.ProvidedEntryProps;

function Entry({ ...pendingSubListEntryProps }: Props) {
  return (
    <PendingActionSubList.Entry.Component
      Controls={Controls.Component}
      {...pendingSubListEntryProps}
    />
  );
}

export const Component = React.memo(Entry) as typeof Entry;
