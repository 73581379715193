import React from 'react';

import { Page } from 'services';

import { routeTree } from '../../../routeTree';
import * as features from './features';

type Props = features.Answers.Props;

function Answers(props: Props) {
  return <features.Answers.Component {...props} />;
}

export const path =
  routeTree.LANG[
    'project-assignment'
  ].list.PROJECT_ASSIGNMENT.answers.getPath();

export const Component = Page.makePage({
  path,
  scrollTo: 'none',
  Component: React.memo(Answers),
  features: Object.values(features).map(x => x.feature),
});
