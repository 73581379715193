import * as M from 'types/serverModels';

import { makeService } from '../../utils';

type InputData = {
  uuid: M.UUID;
  status: Exclude<M.ProjectAssignmentResultStatus, 'waiting' | 'deleted'>;
};

type OutputData = {
  project_assignment_result: M.ProjectAssignmentResult;
};

export const status = makeService<InputData, OutputData, OutputData>(
  ({ uuid }) => `project_assignment_result/${uuid}/status`,
  'api-v2-put',
  false,
  output => output,
  { contentType: 'application/x-www-form-urlencoded' },
);
