import { useEffect } from 'react';

import * as M from 'types/serverModels';
import { makeDerivedUnit, PrimaryStateUnit } from 'utils/State';

import * as services from '../services';
import { CallState } from '../types';

type ProjectBookFieldReferenceBookCode =
  M.ProjectBookFieldReferenceBook['code'];

const projectBookFieldRefsCallStateUnit =
  services.refLoad.makeCallStateUnit() as PrimaryStateUnit<
    CallState<M.ProjectBookFieldReferenceBook[]>
  >;

const projectBookFieldsCallStateUnit = makeDerivedUnit(
  projectBookFieldRefsCallStateUnit,
).getUnit<
  CallState<{
    [key in ProjectBookFieldReferenceBookCode]: M.ProjectBookFieldReferenceBook;
  }>
>(projectBookFieldRefsCallState => {
  if (projectBookFieldRefsCallState.kind === 'successful') {
    const data = projectBookFieldRefsCallState.data.reduce<{
      [key in ProjectBookFieldReferenceBookCode]: M.ProjectBookFieldReferenceBook;
    }>((acc, x) => {
      return {
        ...acc,
        [x.code]: x,
      };
    }, {});

    return {
      kind: 'successful',
      data,
    };
  }

  return projectBookFieldRefsCallState;
});

let wasCalled = false;

function useProjectBookFieldRefsCallState() {
  const callState = projectBookFieldRefsCallStateUnit.useState();

  const call = services.refLoad.useCall(
    projectBookFieldRefsCallStateUnit as PrimaryStateUnit<
      CallState<M.ReferenceBook[]>
    >,
  );

  useEffect(() => {
    if (!wasCalled) {
      wasCalled = true;

      call({ ref: 'pb_field' });
    }
  }, [callState.kind, call]);
}

export function useCallState() {
  const callState = projectBookFieldsCallStateUnit.useState();

  useProjectBookFieldRefsCallState();

  return callState;
}
