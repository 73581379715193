import * as R from 'ramda';

import {
  projectLanguageUnit,
  projectNameFormElementState,
  serverProjectDataUnit,
} from 'features/project/Constructor/units';
import { I18n } from 'services';
import * as M from 'types/serverModels';
import { ProjectWrite } from 'utils/business';

import { materialsInNonProjectLanguage } from '../units';
import { getMaterialInstances } from './getMaterialInstances';

export function makeProjectSharedParts(): Partial<M.ProjectWriteData> {
  const lang = projectLanguageUnit.getState();

  const serverProjectData = serverProjectDataUnit.getState();
  const multilangState = I18n.makeMultilangFormState(
    projectNameFormElementState,
    serverProjectData?.title,
  );

  const unitsIDsFromInstances = getMaterialInstances()
    .map(instance => {
      const serverID = instance.serverIDUnit.getState();

      if (serverID === null) {
        console.warn('unexpected null server id on project saving', instance);
      }

      return serverID;
    })
    .filter((x): x is string => x !== null);

  return ProjectWrite.convertToProjectWriteData({
    ...serverProjectDataUnit.getState(),
    title: multilangState.getMergedMultilingString(lang),
    langs: serverProjectData ? serverProjectData.langs : [lang],
    units: R.uniq([
      ...unitsIDsFromInstances,
      ...materialsInNonProjectLanguage.getState(),
    ]),
  });
}
