import debounce from 'debounce';
import * as R from 'ramda';
import React, { useEffect, useMemo } from 'react';

import { ConstructorConfigContext } from 'features/project/Constructor/config/configContext';
import {
  Filter,
  TimelineWidgetDatafulView,
} from 'features/project/Constructor/subfeatures';
import { API } from 'services';
import * as M from 'types/serverModels';
import { makeMappingUnit } from 'utils/State';
import { Questionnaire } from 'utils/business';
import { useRequiredContext } from 'utils/react/RequiredContext';

import * as modals from '../../../modals';
import { ViewProps } from '../../../types';
import * as DatalessWidgetLayout from '../../shared/DatalessWidgetLayout';
import { makeEmulateParams } from '../../shared/makeEmulateParams';
import { TimeLineInstance } from '../types';

const hasAnswersForWidget = (x: M.QuestionnaireData, questionID: string) =>
  Questionnaire.answer.hasText(x.answers?.[questionID]);

const dataIsEmpty = (data: M.QuestionnaireData[], questionID: string) =>
  data.filter(x => hasAnswersForWidget(x, questionID))?.length === 0;

function View({
  instance,
  shouldEmulateDataUnit,
  useEmulationSeed,
}: ViewProps<TimeLineInstance>) {
  const callStateUnit = API.services.data.list.useCallStateUnit();
  const callState = callStateUnit.useState();
  const call = API.services.data.list.useCall(callStateUnit);

  const startDateQuestionID =
    instance.startDateQuestionID.units.value.useState();
  const endDateQuestionID = instance.endDateQuestionID.units.value.useState();
  const headlineQuestionID = instance.headlineQuestionID.units.value.useState();
  const textQuestionID = instance.textQuestionID.units.value.useState();
  const mediaQuestionID = instance.mediaQuestionID.units.value.useState();

  const emulationSeed = useEmulationSeed();

  const filterStateForRequestUnit = useMemo(
    () => Filter.makeFilterStateUnitForRequest(instance.filter),
    [instance.filter],
  );

  const settingsUnit = useMemo(() => {
    return makeMappingUnit({
      filter: filterStateForRequestUnit,
      shouldEmulate: shouldEmulateDataUnit,
    });
  }, [filterStateForRequestUnit, shouldEmulateDataUnit]);

  const { onQuestionnaireDelete, getProjectUUID } = useRequiredContext(
    ConstructorConfigContext,
  );

  useEffect(() => {
    const requestData = (
      settings: ReturnType<(typeof settingsUnit)['getState']>,
      prevSettings?: ReturnType<(typeof settingsUnit)['getState']>,
    ) => {
      const projectUUID = getProjectUUID();

      if (
        projectUUID &&
        !R.equals(settings, prevSettings) &&
        instance.filter.validate()
      ) {
        call({
          resolveUser: 1,
          filter: {
            project: projectUUID,
            ...Filter.makeServerFilter(settings.filter),
          },
          sort: { cdate: -1 },
          emulate: makeEmulateParams(settings.shouldEmulate, emulationSeed),
        });
      }
    };

    requestData(settingsUnit.getState());

    return settingsUnit.subscribe({
      name: 'data-requester',
      callback: debounce(requestData, 500),
    });
  }, [call, emulationSeed, getProjectUUID, instance.filter, settingsUnit]);

  useEffect(() => {
    return callStateUnit.subscribe({
      name: 'error-modal-opener',
      callback: callState => {
        switch (callState.kind) {
          case 'successful': {
            const startDateQuestionID =
              instance.startDateQuestionID.units.value.getState();
            const headlineQuestionID =
              instance.headlineQuestionID.units.value.getState();
            const isError =
              shouldEmulateDataUnit.getState() &&
              startDateQuestionID !== null &&
              headlineQuestionID !== null &&
              dataIsEmpty(callState.data.data, startDateQuestionID) &&
              dataIsEmpty(callState.data.data, headlineQuestionID) &&
              Object.values(filterStateForRequestUnit.getState()).every(
                x => x === null,
              );
            if (isError) {
              modals.QuestionsNotSavedOrOtherError.open();
            }
            break;
          }
        }
      },
    });
  }, [
    callStateUnit,
    filterStateForRequestUnit,
    instance.headlineQuestionID.units.value,
    instance.startDateQuestionID.units.value,
    shouldEmulateDataUnit,
  ]);

  const DatalessView = DatalessWidgetLayout.useViewWithLayout(
    'timeline',
    shouldEmulateDataUnit,
  );

  return API.renderCallState(callState, {
    successful: ({ data: { data, project } }) => {
      if (!startDateQuestionID || !headlineQuestionID || !project) {
        return <DatalessView />;
      }

      const widgetData = data.filter(
        x =>
          (startDateQuestionID === 'Meta.cdate' ||
            typeof x.answers?.[startDateQuestionID] === 'string') &&
          typeof x.answers?.[headlineQuestionID] === 'string',
      );
      if (widgetData.length === 0) {
        return <DatalessView />;
      }

      return (
        <TimelineWidgetDatafulView.Component
          startDateQuestionID={startDateQuestionID}
          endDateQuestionID={endDateQuestionID}
          headlineQuestionID={headlineQuestionID}
          textQuestionID={textQuestionID}
          mediaQuestionID={mediaQuestionID}
          project={project}
          data={widgetData}
          onQuestionnaireDelete={onQuestionnaireDelete}
        />
      );
    },
    error: () => <DatalessView />,
    initial: () => <DatalessView />,
  });
}

export const Component = React.memo(View);
