import React from 'react';

import { routeTree } from 'pages/routeTree';
import { Page } from 'services';

import * as features from './features';

type Props = features.Conclusions.Props;

function Conclusions(props: Props) {
  return <features.Conclusions.Component {...props} />;
}

export const path = routeTree.LANG.course.list.COURSE.conclusions.getPath();

export const Component = Page.makePage({
  path,
  scrollTo: 'none',
  Component: React.memo(Conclusions) as typeof Conclusions,
  features: Object.values(features).map(x => x.feature),
}) as typeof Conclusions;
