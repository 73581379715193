import * as DataList from './DataList';
import * as FilesList from './FilesList';
import * as Gallery from './Gallery';
import * as Map from './Map';
import * as TextsCloud from './TextsCloud';
import * as TextsList from './TextsList';
import * as Timeline from './Timeline';
import * as Venn from './Venn';
import * as chartWidgets from './chartWidgets';

export { DataList, Gallery, Map, TextsCloud, TextsList, Venn, chartWidgets };

export const constructorChartWidgets = [
  chartWidgets.Line,
  chartWidgets.Column,
  chartWidgets.ColumnStack,
  chartWidgets.Pie,
  chartWidgets.Scatter,
  chartWidgets.Area,
] as const;

export const constructorWidgets = [
  ...constructorChartWidgets,
  Gallery.constructorWidgetConfig,
  TextsList.constructorWidgetConfig,
  FilesList.constructorWidgetConfig,
  TextsCloud.constructorWidgetConfig,
  DataList.constructorWidgetConfig,
  Map.constructorWidgetConfig,
  Venn.constructorWidgetConfig,
  Timeline.constructorWidgetConfig,
] as const;

export const chartWidgetKeys = constructorChartWidgets.map(x => x.key);
export const widgetKeys = constructorWidgets.map(x => x.key);

type ConstructorWidgets = {
  [Widget in (typeof constructorWidgets)[number] as Widget['key']]: Widget;
};

export const widgetKeyToFindingsConstructorWidget =
  constructorWidgets.reduce<ConstructorWidgets>(
    (acc, x) => ({ ...acc, [x.key]: x }),
    {} as ConstructorWidgets,
  );

export type FindingsWidgetInstance =
  | chartWidgets.Instance
  | Gallery.GalleryInstance
  | TextsList.TextsListInstance
  | TextsCloud.TextsCloudInstance
  | DataList.DataListInstance
  | Map.MapInstance
  | Venn.VennInstance
  | FilesList.FilesListInstance
  | Timeline.TimeLineInstance;
