import React from 'react';

import { Page } from 'services';
import { block } from 'utils/classname';

import { routeTree } from '../routeTree';
import * as features from './features';
import './style.scss';

const b = block('password-reset-page');

function PasswordReset() {
  return (
    <div className={b()}>
      <features.PasswordResetFormFeature.Component />
    </div>
  );
}

export const Component = Page.makePage({
  path: routeTree.LANG['password-reset'].getPath(),
  scrollTo: 'top-on-mount',
  Component: React.memo(PasswordReset),
  features: Object.values(features).map(x => x.feature),
});
