import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';

import { Preloader } from 'components';
import { Page } from 'services';
import { useID } from 'services/IDProvider';
import { ReactComponent as NavigationIcon } from 'shared/images/back.svg';
import { ReactComponent as CloseIcon } from 'shared/images/cross.svg';
import * as M from 'types/serverModels';
import { block } from 'utils/classname';
import { CircularArray } from 'utils/collection';

import * as Area from './Area';
import './style.scss';
import { CarouselImage, Mode } from './types';

export type { CarouselImage } from './types';

const b = block('carousel-image');

export type Props<T> = {
  images: CarouselImage<T>[];
  initialImage: CarouselImage<T>;
  ImageInfo?: React.VFC<{ data?: T }>;
  onExit(): void;
};

type CarouselImageConstructorArguments<T> = {
  image: M.Image;
  data?: T;
};

export function makeImage<T>(
  args: CarouselImageConstructorArguments<T>,
): CarouselImage<T> {
  const imageElement = new Image();
  imageElement.className = b('image');
  imageElement.draggable = false;

  return {
    image: args.image,
    imageElement,
    data: args.data,
    loadIfNot: () => {
      imageElement.src = args.image.large;
    },
  };
}

const initialSlidesOffset = 'calc(-100% / 3)';

const getContainedSize = (img: HTMLImageElement) => {
  const ratio = img.naturalWidth / img.naturalHeight;
  const width = img.height * ratio;
  const height = img.height;
  if (width > img.width) {
    return {
      width: img.width,
      height: img.width / ratio,
    };
  }

  return { width, height };
};

function Carousel<T>({ images, initialImage, ImageInfo, onExit }: Props<T>) {
  const [mode, setMode] = useState<Mode>({ kind: 'normal' });
  const [isInitialRun, setIsInitialRun] = useState(true);

  const [offset, setOffset] = useState<number>(0);
  const [slidesOffset, setSlidesOffset] = useState<string | number>(
    initialSlidesOffset,
  );

  Page.useSetScroll(true);

  const [attachedAreasStyle, setAttachedAreasStyle] = useState<
    React.CSSProperties | undefined
  >();

  const initialImageIndex = useMemo(() => {
    const index = images.findIndex(x => x === initialImage);
    if (index === -1) {
      console.error(
        'initial image',
        initialImage,
        'was not found in images',
        images,
      );
      return 0;
    }

    return index;
  }, [images, initialImage]);

  const [currentImageIndex, setCurrentImageIndex] = useState(initialImageIndex);
  const [isLoading, setIsLoading] = useState(false);

  const { image, data, imageElement } = images[currentImageIndex];

  const transform = `translateX(${
    typeof slidesOffset === 'number' ? `${slidesOffset}px` : slidesOffset
  })`;

  const areasID = useID('areas');

  useLayoutEffect(() => {
    const carouselImage = images[currentImageIndex];

    const activeCarouselImages =
      images.length <= 1
        ? images
        : CircularArray.getSubarray(images, currentImageIndex, 1);

    if (isInitialRun) {
      carouselImage.loadIfNot();
      carouselImage.imageElement.classList.add('carousel-image__image_initial');
      carouselImage.imageElement.onanimationend = () => {
        carouselImage.imageElement.classList.remove(
          'carousel-image__image_initial',
        );
      };
      setIsInitialRun(false);
    } else {
      activeCarouselImages.forEach(x => x.loadIfNot());
    }

    const slidesNode = slidesRef.current;

    if (slidesNode) {
      while (slidesNode.firstChild) {
        slidesNode.firstChild.remove();
      }

      const { imageElement } = images[currentImageIndex];

      activeCarouselImages.forEach(x => {
        const imageContainerNode = document.createElement('div');
        imageContainerNode.className = 'carousel-image__image-container';
        imageContainerNode.append(x.imageElement);
        return slidesNode.append(imageContainerNode);
      });

      if (!imageElement.complete) {
        imageElement.classList.add('carousel-image_image_loading');
        setIsLoading(true);

        imageElement.onload = () => {
          imageElement.classList.remove('carousel-image_image_loading');
          setIsLoading(false);

          if (isInitialRun) {
            activeCarouselImages[0].loadIfNot();
            activeCarouselImages[activeCarouselImages.length - 1].loadIfNot();
          }
        };
      }
    }
  }, [currentImageIndex, images, isInitialRun]);

  const slidesRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const slidesNode = slidesRef.current;

    if (!slidesNode) return;

    let hammer: HammerManager | null = null;

    import('hammerjs').then(() => {
      hammer = new Hammer(slidesNode);

      hammer.get('swipe').set({ threshold: 100 });
      hammer.on('swipe', e => {
        if ((Hammer.DIRECTION_HORIZONTAL & e.direction) > 0) {
          setHandleMouseDown(undefined);
          setHandleMouseUp(undefined);
          setHandleMouseMove(undefined);

          switch (e.direction) {
            case Hammer.DIRECTION_RIGHT: {
              setMode({ kind: 'transition', nextImageIndexDiff: -1 });
              break;
            }
            case Hammer.DIRECTION_LEFT: {
              setMode({ kind: 'transition', nextImageIndexDiff: 1 });
            }
          }
        }
      });
    });

    return () => {
      hammer?.destroy();
    };
  }, []);

  const [handleMouseMove, setHandleMouseMove] = useState<
    React.MouseEventHandler<HTMLDivElement> | undefined
  >(undefined);

  const [handleMouseUp, setHandleMouseUp] = useState<
    React.MouseEventHandler<HTMLDivElement> | undefined
  >(undefined);

  const initialHandleMouseDown = useCallback(
    (downEvent: React.MouseEvent<HTMLDivElement>) => {
      const slidesNode = slidesRef.current;
      if (slidesNode === null || images.length <= 1) {
        return;
      }
      const slideWidth = slidesNode.getBoundingClientRect().width;

      setHandleMouseMove(
        () => (moveEvent: React.MouseEvent<HTMLDivElement>) => {
          const offset = moveEvent.clientX - downEvent.clientX;
          setOffset(offset);
          setSlidesOffset(offset - slideWidth / 3);
        },
      );

      setHandleMouseUp(() => (upEvent: React.MouseEvent<HTMLDivElement>) => {
        setHandleMouseDown(undefined);

        if (slidesRef.current) {
          const rect = slidesRef.current.getBoundingClientRect();
          const slideWidth = rect.width / 3;

          const diff = downEvent.clientX - upEvent.clientX;

          if (diff > slideWidth / 2) {
            setMode({ kind: 'transition', nextImageIndexDiff: 1 });
          } else if (-diff > slideWidth / 2) {
            setMode({ kind: 'transition', nextImageIndexDiff: -1 });
          } else {
            setMode({ kind: 'transition', nextImageIndexDiff: 0 });
          }
        }

        setOffset(0);
        setHandleMouseMove(undefined);
        setHandleMouseUp(undefined);
      });
    },
    [images.length],
  );

  const [handleMouseDown, setHandleMouseDown] = useState<
    React.MouseEventHandler<HTMLDivElement> | undefined
  >(() => initialHandleMouseDown);

  const handleTransitionEnd = useCallback(() => {
    if (mode.kind === 'transition') {
      setHandleMouseDown(() => initialHandleMouseDown);

      if (slidesRef.current) {
        slidesRef.current.style.transition = '';
        setMode({ kind: 'normal' });
        setOffset(0);
        setSlidesOffset(initialSlidesOffset);
      } else {
        console.warn('no slides ref');
      }
      setCurrentImageIndex(prev => {
        switch (mode.nextImageIndexDiff) {
          case -1:
            return CircularArray.getPrevIndex(images, prev);
          case 1:
            return CircularArray.getNextIndex(images, prev);
          case 0:
            return prev;
        }
      });
    } else {
      console.warn('unexpected mode on transition end');
    }
  }, [mode, initialHandleMouseDown, images]);

  const handlePrevIconClick = useCallback(() => {
    setMode({ kind: 'transition', nextImageIndexDiff: -1 });
  }, []);

  useLayoutEffect(() => {
    if (mode.kind === 'transition' && slidesRef.current) {
      slidesRef.current.style.transition = 'transform 200ms';
      const offset = (() => {
        switch (mode.nextImageIndexDiff) {
          case 1:
            return 'calc((-200%) / 3)';
          case -1:
            return 0;
          case 0:
            return initialSlidesOffset;
        }
      })();

      setSlidesOffset(offset);
    }
  }, [mode]);

  const handleNextIconClick = useCallback(() => {
    setMode({ kind: 'transition', nextImageIndexDiff: 1 });
  }, []);

  useLayoutEffect(() => {
    const handleResize = () => {
      setAttachedAreasStyle(getContainedSize(imageElement));
    };

    const resizeObserver = new globalThis.ResizeObserver(handleResize);

    resizeObserver.observe(imageElement);

    setTimeout(() => {
      setAttachedAreasStyle(getContainedSize(imageElement));
    }, 50);

    return () => {
      resizeObserver.disconnect();
    };
  }, [imageElement]);

  return (
    <>
      {createPortal(
        <div
          className={b({
            draggable: images.length > 1,
            dragging: handleMouseMove !== undefined,
          })}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
          onTransitionEnd={handleTransitionEnd}
        >
          <div className={b('slides')} style={{ transform }} ref={slidesRef} />
          <div id={areasID} className={b('areas')} style={attachedAreasStyle}>
            {image.areas?.map(x => (
              <Area.Component
                key={x.uuid}
                mode={mode}
                containerID={areasID}
                area={x}
                offset={offset}
              />
            ))}
          </div>
          {images.length > 1 && (
            <>
              <NavigationIcon
                className={b('prev-icon')}
                onClick={handlePrevIconClick}
              />
              <NavigationIcon
                className={b('next-icon')}
                onClick={handleNextIconClick}
              />
            </>
          )}
          <CloseIcon className={b('close-icon')} onClick={onExit} />
          {isLoading && <Preloader.Component />}
          {ImageInfo && (
            <div className={b('image-info')}>
              <ImageInfo data={data} />
            </div>
          )}
        </div>,
        document.body,
      )}
    </>
  );
}

export const Component = React.memo(Carousel) as typeof Carousel;
