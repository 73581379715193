import React from 'react';

import { Page } from 'services';

import { routeTree } from '../../../routeTree';
import * as features from './features';

type Props = features.Stages.Props;

function Stages(props: Props) {
  return <features.Stages.Component {...props} />;
}

export const path = [
  routeTree.LANG['project-book'].list.PROJECT_BOOK.stages.getPath(),
  routeTree.LANG['project-book'].list.PROJECT_BOOK.users.USER.stages.getPath(),
];

export const Component = Page.makePage({
  path,
  routeProps: { exact: false },
  scrollTo: 'none',
  Component: React.memo(Stages) as typeof Stages,
  features: Object.values(features).map(x => x.feature),
}) as typeof Stages;
