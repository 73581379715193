import { I18n } from 'services';
import * as M from 'types/serverModels';
import { makeUUID } from 'utils/UUID';

export function makePrintUnit<
  T extends Omit<M.PrintUnit, 'uuid' | 'lang' | 'type'>,
>(data: T) {
  return {
    uuid: makeUUID(),
    lang: I18n.activeLangStateUnit.getState(),
    type: 'print',
    ...data,
  } as const;
}
