import * as M from 'types/serverModels';

import { matchField } from './matchField';

export function findField(
  data: M.ProjectBookChange['change'] | M.ProjectBookRecycleBinObjectItem,
  projectBook: M.ProjectBook,
): M.ProjectBookField | undefined {
  const fieldUUID = matchField(data);

  return fieldUUID
    ? projectBook.fields?.find(x => x.uuid === fieldUUID)
    : undefined;
}
