import React from 'react';

import { SoundQuestion } from 'features/project/Constructor/subfeatures';
import { useFormElementState } from 'utils/FormState';

import { PreviewModeFormProps } from '../../../types';
import { StateInstance } from '../types';

function PreviewModeForm({ instance }: PreviewModeFormProps<StateInstance>) {
  const formElementState = useFormElementState<string | null>(null);

  return (
    <SoundQuestion.Component
      formElementState={formElementState}
      questionUUID={instance.id}
    />
  );
}

export const Component = React.memo(PreviewModeForm);
