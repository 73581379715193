import React, { useMemo } from 'react';

import { Page } from 'services';
import * as M from 'types/serverModels';

import { PersonalAccountMyProjectsRouteTree, routeTree } from '../../routeTree';
import * as features from './features';

type Props = {
  user: M.User;
};

function MyProjects({ user }: Props) {
  const personalAccountMyProjectsRouteTree: PersonalAccountMyProjectsRouteTree =
    useMemo(() => {
      return {
        kind: 'personal-account-my-projects',
        tree: routeTree.LANG['personal-account']['my-projects'],
      };
    }, []);

  return (
    <features.MyProjects.Component
      personalAccountMyProjectsRouteTree={personalAccountMyProjectsRouteTree}
      user={user}
    />
  );
}

export const Component = Page.makePage({
  path: routeTree.LANG['personal-account']['my-projects'].getPath(),
  scrollTo: 'top-on-mount',
  Component: React.memo(MyProjects),
  features: Object.values(features).map(x => x.feature),
});
