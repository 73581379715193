import { default as fileSrc, ReactComponent as FileInline } from './file.svg';
import { default as linkSrc, ReactComponent as LinkInline } from './link.svg';
import { default as testSrc, ReactComponent as TestInline } from './test.svg';
import { default as textSrc, ReactComponent as TextInline } from './text.svg';
import {
  default as videoSrc,
  ReactComponent as VideoInline,
} from './video.svg';

type MaterialIcon = {
  src: string;
  InlineComponent: typeof TextInline;
};

export const text: MaterialIcon = {
  src: textSrc,
  InlineComponent: TextInline,
};

export const video: MaterialIcon = {
  src: videoSrc,
  InlineComponent: VideoInline,
};

export const file: MaterialIcon = {
  src: fileSrc,
  InlineComponent: FileInline,
};

export const link: MaterialIcon = {
  src: linkSrc,
  InlineComponent: LinkInline,
};

export const test: MaterialIcon = {
  src: testSrc,
  InlineComponent: TestInline,
};
