import { makeFeature } from 'services/Feature';

import type { Component as ComponentType } from './Component';
import * as subfeatures from './subfeatures';

export const feature = makeFeature({
  name: 'Autocomplete',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "autocomplete" */
      './Component'
    ),
  i18nData: null,
  i18nSharedReferences: null,
  subfeatures: Object.values(subfeatures).map(x => x.feature),
});

export * as ValueItem from './ValueItem';
export * as SortedItem from './SortedItem';
export * from './makeHeaderWithLabel';

export const Component = feature.Component as typeof ComponentType;
