import React from 'react';

import { Page } from 'services';

import { routeTree } from '../../routeTree';
import * as features from './features';

type Props = features.MySensors.Props;

function MySensors({}: Props) {
  return (
    <>
      <features.MySensors.Component />
    </>
  );
}

export const Component = Page.makePage({
  path: routeTree.LANG['personal-account']['my-sensors'].getPath(),
  routeProps: { exact: true },
  scrollTo: 'top-on-mount',
  Component: React.memo(MySensors),
  features: Object.values(features).map(x => x.feature),
});
