import { USER_ROLE } from 'shared/constants';
import * as M from 'types/serverModels';

import { defaults } from './constants';

type Options<T> = {
  user: M.User;
  currentUser: M.User | null;
  statusesToDisplay?: Set<M.Project['status']>;
  convertItem?(project: M.ProjectIdxItem): T;
};

export function getAuthorProjects<T = M.ProjectIdxItem>({
  user,
  currentUser,
  statusesToDisplay = defaults.statusesToDisplay,
  convertItem = project => project as unknown as T,
}: Options<T>) {
  return (
    user.author_project_stack?.reduce<T[]>((acc, uuid) => {
      const project = user.projectIdx[uuid];

      if (project === undefined) return acc;

      const isShowPrivateProject =
        currentUser &&
        (currentUser?.role === USER_ROLE.moderator ||
          currentUser?.login === user.login)
          ? true
          : !project.private;
      const isProjectToDisplay =
        statusesToDisplay.has(project.status) && isShowPrivateProject;

      if (!isProjectToDisplay) {
        return acc;
      }

      return [...acc, convertItem(project)];
    }, []) || []
  );
}
