import React from 'react';

import { routeTree } from 'pages/routeTree';
import { Page } from 'services';

import * as features from './features';

type Props = Omit<features.Quiz.Props, 'objectUUID'>;

function Quiz(props: Props) {
  const params =
    routeTree.LANG.project.list.PROJECT.quiz.form.QUIZ_UUID.useRouteParams();

  if (!params) {
    return null;
  }
  return <features.Quiz.Component objectUUID={params.quiz_uuid} {...props} />;
}

export const path =
  routeTree.LANG.project.list.PROJECT.quiz.form.QUIZ_UUID.getPath();

export const Component = Page.makePage({
  path,
  scrollTo: 'top-on-mount',
  Component: React.memo(Quiz),
  features: Object.values(features).map(x => x.feature),
});
