import {
  LayerWithoutSettings,
  QuestionForLayerWithoutSettings,
} from '../types';

export function withoutSettings(
  question: QuestionForLayerWithoutSettings,
  id: string,
): LayerWithoutSettings {
  return {
    kind: 'without-settings',
    id,
    question,
  };
}
