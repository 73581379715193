import { Location } from 'history';

export type Subscriber = (newLocation: Location) => void;

export let subscribers: Subscriber[] = [];

export function subscribeLocationChange(subscriber: Subscriber) {
  subscribers.push(subscriber);
  return () => {
    subscribers = subscribers.filter(x => x !== subscriber);
  };
}
