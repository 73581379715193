import { makeSharedEntryReference } from 'services/I18n/makeEntryReference';

export const unitTypeReferences = {
  presentation: makeSharedEntryReference(
    data => data.unit.type.default.presentation,
  ),
  web: makeSharedEntryReference(data => data.unit.type.default.web),
  test: makeSharedEntryReference(data => data.unit.type.default.test),
  video: makeSharedEntryReference(data => data.unit.type.default.video),
  interactive: makeSharedEntryReference(
    data => data.unit.type.default.interactive,
  ),
  print: makeSharedEntryReference(data => data.unit.type.default.print),
  project: makeSharedEntryReference(data => data.unit.type.default.project),
  mesch_eom: makeSharedEntryReference(data => data.unit.type.default.mesch_eom),
};
