import debounce from 'debounce';
import * as R from 'ramda';
import React, { useEffect, useMemo } from 'react';

import { Chart } from 'components';
import { ConstructorConfigContext } from 'features/project/Constructor/config/configContext';
import { Filter } from 'features/project/Constructor/subfeatures';
import { API } from 'services';
import * as TS from 'types';
import {
  makeDerivedUnit,
  makeMappingUnit,
  makeMappingUnitFromUnit,
} from 'utils/State';
import { VennChart } from 'utils/business';
import { useRequiredContext } from 'utils/react/RequiredContext';

import { ViewProps } from '../../../types';
import * as DatalessWidgetLayout from '../../shared/DatalessWidgetLayout';
import { makeEmulateParams } from '../../shared/makeEmulateParams';
import { VennInstance } from '../types';

function View({
  instance,
  shouldEmulateDataUnit,
  useEmulationSeed,
}: ViewProps<VennInstance>) {
  const callStateUnit = API.services.data.venn.useCallStateUnit();
  const callState = callStateUnit.useState();

  const emulationSeed = useEmulationSeed();

  const call = API.services.data.venn.useCall(callStateUnit);

  const chartSetsDataUnit = useMemo(() => {
    return makeDerivedUnit(
      makeMappingUnitFromUnit(instance.sets, { deep: true }),
    ).getUnit(data =>
      data.reduce<TS.VennChartSetsData>((acc, x) => {
        const setData = VennChart.makeSetData(x);

        if (setData !== null) {
          return {
            ...acc,
            [x.name]: setData,
          };
        }
        return acc;
      }, {}),
    );
  }, [instance.sets]);

  const filterStateForRequestUnit = useMemo(
    () => Filter.makeFilterStateUnitForRequest(instance.filter),
    [instance.filter],
  );

  const settingsUnit = useMemo(() => {
    return makeMappingUnit(
      {
        sets: makeDerivedUnit(instance.sets).getUnit(sets =>
          sets.map(VennChart.makeSetForRequest),
        ),
        filter: filterStateForRequestUnit,
        shouldEmulate: shouldEmulateDataUnit,
      },
      { deep: true },
    );
  }, [filterStateForRequestUnit, instance.sets, shouldEmulateDataUnit]);

  const { getProjectUUID } = useRequiredContext(ConstructorConfigContext);

  useEffect(() => {
    const requestData = (
      settings: ReturnType<(typeof settingsUnit)['getState']>,
      prevSettings?: ReturnType<(typeof settingsUnit)['getState']>,
    ) => {
      const projectUUID = getProjectUUID();

      if (
        !projectUUID ||
        settings.sets.every(x => x.kind === 'without-selected-question')
      ) {
        return;
      }

      if (!R.equals(settings, prevSettings) && instance.filter.validate()) {
        call({
          project: projectUUID,
          filter: Filter.makeServerFilter(settings.filter),
          params: VennChart.makeServerDescriptorSets(settings.sets),
          emulate: makeEmulateParams(settings.shouldEmulate, emulationSeed),
        });
      }
    };

    requestData(settingsUnit.getState());

    return settingsUnit.subscribe({
      name: 'data-requester',
      callback: debounce(requestData, 500),
    });
  }, [call, emulationSeed, getProjectUUID, instance.filter, settingsUnit]);

  useEffect(() => {
    let prevQuestionIDs: string[] = [];

    return instance.sets.subscribe({
      name: 'emulation-mode-canceller',
      callback: state => {
        if (shouldEmulateDataUnit.getState()) {
          const setsQuestions = state
            .filter(VennChart.isSetWithQuestion)
            .map(x => x.question.id);
          if (
            !setsQuestions.every((x, index) => x === prevQuestionIDs[index])
          ) {
            prevQuestionIDs = setsQuestions;
            shouldEmulateDataUnit.setState(false);
          }
        }
      },
    });
  }, [instance.sets, shouldEmulateDataUnit]);

  const DatalessView = DatalessWidgetLayout.useViewWithLayout(
    'chart',
    shouldEmulateDataUnit,
  );

  return API.renderCallState(callState, {
    successful: ({ data }) => {
      if (data.every(x => x.value === '-1' || x.value.length === 0)) {
        return <DatalessView />;
      }

      const chartData: TS.VennChartData = {
        type: 'venn',
        setsData: chartSetsDataUnit.getState(),
        items: data,
      };

      return <Chart.Component data={chartData} />;
    },
    error: () => <DatalessView />,
    initial: () => <DatalessView />,
  });
}

export const Component = React.memo(View);
