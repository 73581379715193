import { PrimaryStateUnit } from 'utils/State';
import { makeUUID } from 'utils/UUID';

import { Notification } from '../types';

type Options = {
  notification: Omit<Notification, 'key'>;
  notificationsUnit: PrimaryStateUnit<Notification[]>;
};

export function addNotification({ notification, notificationsUnit }: Options) {
  notificationsUnit.setState(prev => [
    ...prev,
    { ...notification, key: makeUUID() },
  ]);
}
