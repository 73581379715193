import React, { useCallback, useEffect } from 'react';

import { EditableTextView } from 'features/abstract';
import { ProjectWriteContext } from 'features/project/Constructor/FormWithSteps/ProjectWriteContext';
import { I18n } from 'services';
import { FormElementState } from 'utils/FormState';
import { block } from 'utils/classname';
import { useRequiredContext } from 'utils/react/RequiredContext';

import i18nData from '../../../../../../i18n.json';
import { WidgetDeleteControl } from '../../../../../../subfeatures';
import './style.scss';

const b = block('project-constructor-product');

type Props = {
  id: string;
  formElementState: FormElementState<string>;
  onDelete?(id: string): void;
};

function Product({ id, formElementState, onDelete }: Props) {
  const text = I18n.useText(i18nData).steps.advancedSettings.section.products;

  const { saveProject } = useRequiredContext(ProjectWriteContext);

  const sharedStateUnits = EditableTextView.useSharedStateUnits();
  const mode = sharedStateUnits.modeUnit.useState();

  useEffect(() => {
    return sharedStateUnits.modeUnit.subscribe({
      name: 'mode-changed',
      callback: mode => {
        if (mode === 'view') {
          saveProject();
        }
      },
    });
  }, [saveProject, sharedStateUnits.modeUnit]);

  const handleDelete = useCallback(() => {
    onDelete?.(id);
  }, [id, onDelete]);

  return (
    <div className={b({ mode })}>
      <EditableTextView.Component
        kind="regular"
        formElementState={formElementState}
        sharedStateUnits={sharedStateUnits}
        errorRows={0}
        nameFontSize="header-6"
        nowrap
      />
      <WidgetDeleteControl.Component
        size={mode === 'edit' ? 'm' : 's'}
        confirmMessage={text.confirmDeleteMessage}
        onDelete={handleDelete}
      />
    </div>
  );
}

export const Component = React.memo(Product);
