import * as M from 'types/serverModels';

import { makeService } from '../utils';

type InputData = M.EmulateParamsHolder & {
  question: string[];
  filter: M.Filter | null;
};

type APIOutputData = {
  data: {
    question_uuid: M.UUID;
    data: M.ServerMapData[];
  }[];
};

type OutputData = {
  question_uuid: M.UUID;
  data: M.ServerMapData[];
};

export const map = makeService<InputData, APIOutputData, OutputData[]>(
  (data, convertToSearchParams) =>
    `data/map/?${convertToSearchParams({
      ...data,
      filter: JSON.stringify(data.filter),
    })}`,
  'api-v2-get',
  false,
  ({ data }) => data,
);
