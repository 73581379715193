import * as TS from 'types';
import * as M from 'types/serverModels';

import { makeService } from './utils';

/**
 * -1 - по убыванию
 * 1 - по возрастанию
 */
type SortMode = -1 | 1;

type InputData = {
  data: {
    filter?: {
      lang?: TS.Language;
      uuid?: M.Webinar['uuid'][];
      datetime?: [from: M.Webinar['datetime'], to: M.Webinar['datetime']];
      audience?: M.Webinar['audience'];
    };
    sort?: {
      [key in keyof M.Webinar]?: SortMode;
    };
  };
};

export const webinarList = makeService<InputData, M.Webinar[]>(
  'webinar_list',
  'post-json',
);
