import React from 'react';

import { I18n } from 'services';
import { useRequiredContext } from 'utils/react/RequiredContext';

import { ConstructorConfigContext } from '../../config/configContext';
import i18nData from '../../i18n.json';
import { ConstructorStepsContext } from '../../steps/stepsContext';
import { ConstructorStepsPanel } from '../../subfeatures';
import { StepKey } from '../types';
import { openedStepPreviewUnit } from '../units';

type Props = {
  onStepSelect(stepKey: StepKey): void;
};

function useTitle(stepKey: StepKey) {
  return I18n.useText(i18nData).steps[stepKey].title;
}

function StepsPanel({ onStepSelect }: Props) {
  const { mode } = useRequiredContext(ConstructorConfigContext);
  const { stepsSections, stepKeyToStep, activeStepUnit } = useRequiredContext(
    ConstructorStepsContext,
  );

  return (
    <ConstructorStepsPanel.Component<StepKey>
      stepsSections={stepsSections}
      stepKeyToStep={stepKeyToStep}
      openedStepPreviewUnit={openedStepPreviewUnit}
      activeStepUnit={activeStepUnit}
      mode={mode}
      useTitle={useTitle}
      onStepSelect={onStepSelect}
    />
  );
}

export const Component = React.memo(StepsPanel) as typeof StepsPanel;
