import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  uuid: M.UUID;
};

export const blogMessageLike = makeService<InputData, number>(
  'blog_message_like',
  'get',
);
