import React, { useCallback, useMemo } from 'react';

import { PrimaryStateUnit } from 'utils/State';
import { block, classnames } from 'utils/classname';

import { ExpandTriggerAreaProps, HeaderProps } from '../types';
import './style.scss';

export * as SimpleLabeledHeader from '../SimpleLabeledHeader';

const b = block('expansion-panel-without-animation');

type Props = {
  Header: React.FC<HeaderProps>;
  Content: React.FC;
  visibleUnit: PrimaryStateUnit<boolean>;
  className?: string;
};

function ExpansionPanel({ Content, Header, visibleUnit, className }: Props) {
  const handleExpandTriggerAreaClick = useCallback(() => {
    visibleUnit.setState(prev => !prev);
  }, [visibleUnit]);

  const visible = visibleUnit.useState();

  const ExpandTriggerArea = useMemo(() => {
    return ({
      children,
      fullWidth,
    }: React.PropsWithChildren<ExpandTriggerAreaProps>) => {
      return (
        <div
          className={b('expand-trigger-area', { 'full-width': fullWidth })}
          onClick={handleExpandTriggerAreaClick}
        >
          {children}
        </div>
      );
    };
  }, [handleExpandTriggerAreaClick]);

  return (
    <div className={classnames(b(), className)}>
      <Header
        panelExpandedUnit={visibleUnit}
        ExpandTriggerArea={ExpandTriggerArea}
      />
      {visible && <Content />}
    </div>
  );
}

export const Component = React.memo(ExpansionPanel) as typeof ExpansionPanel;
