import { makeUUID } from 'utils/UUID';

import { Question } from '../../shared';
import { config } from '../config';

const c = (questionInstance: Question.QuestionInstance) =>
  Question.makeCachedInstanceFromQuestionInstance(
    questionInstance,
    'edit',
    config.questionKeyToConstructorWidget,
  );

const q = Question;

const makeConstructor = (title: string, uuid: string = makeUUID()) =>
  Question.makeSharedInstanceStateConstructor({
    title,
    id: uuid,
  });

export const makeInstancesMocks = () => [
  c(
    q.VariantSelection.constructorWidget.makeInstance(
      makeConstructor('variants with multiple choice'),
      {
        hasMultipleChoice: true,
        displayKind: 'radio',
        variants: Question.VariantSelection.makeVariantsMock(),
      },
    ),
  ),
  c(
    q.VariantSelection.constructorWidget.makeInstance(
      makeConstructor('variants with single choice'),
      {
        hasMultipleChoice: false,
        displayKind: 'radio',
        variants: Question.VariantSelection.makeVariantsMock(),
      },
    ),
  ),
  c(q.Image.constructorWidget.makeInstance(makeConstructor('image'))),
  c(
    q.Text.constructorWidget.makeInstance(
      makeConstructor('single-line-text'),
      false,
    ),
  ),
  c(
    q.Text.constructorWidget.makeInstance(
      makeConstructor('multiline text'),
      true,
    ),
  ),
  c(q.Map.constructorWidget.makeInstance(makeConstructor('map'))),
];
