import * as M from 'types/serverModels';

export function makeIcon(srcURL: string, uuid: string = ''): M.ImageInfo {
  // TODO fix it
  return {
    original: srcURL,
    thumb: [{ code: 'icon', url: srcURL }],
    uuid,
  };
}
