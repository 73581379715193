import React from 'react';

import { Checkbox } from 'components';
import { block } from 'utils/classname';

import { GroupState } from '../../../types';
import './style.scss';

const b = block('group');

type Props = {
  groupState: GroupState;
};

function Group({ groupState }: Props) {
  const Label = Checkbox.makeLabel({
    size: 'text-16',
    useLabel: () => groupState.group.name,
  });
  return (
    <Checkbox.Component
      className={b()}
      formElementState={groupState.checkboxState}
      Label={Label}
    />
  );
}

export const Component = React.memo(Group);
