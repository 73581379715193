import * as M from 'types/serverModels';

import { makeService } from '../utils';

type PathInputData = { uuid: Exclude<M.APIToken['uuid'], 'undefined'> };

type BodyInputData = {
  api_token: Pick<M.APIToken, 'name'>;
};

type InputData = PathInputData & BodyInputData;

type APIOutputData = {
  api_token?: Omit<M.APIToken, 'token' | 'valid'> & { valid?: true };
};

type OutputData = {
  apiToken: Omit<M.APIToken, 'token' | 'valid'> & { valid?: true };
};

function extractResponse(data: APIOutputData): OutputData {
  return {
    apiToken: data.api_token || {},
  };
}

export const put = makeService<InputData, APIOutputData, OutputData>(
  ({ ticket, uuid }, convertToSearchParams) =>
    `api_token/${uuid}/?${convertToSearchParams({ ticket })}`,
  'api-v2-put',
  false,
  extractResponse,
  {
    extractBodyInput: input => {
      const { ticket, uuid, ...bodyInput } = input;

      return bodyInput;
    },
  },
);
