import React, { useCallback } from 'react';
import { SetOptional } from 'type-fest';

import { Action } from 'components';
import {
  ConclusionsRouteTree,
  getConclusionsRootParams,
} from 'pages/routeTree';
import { I18n, Routing } from 'services';
import * as M from 'types/serverModels';
import { block } from 'utils/classname';

import type * as content from './content';

const b = block('quiz-modal-action');

export type Props = SetOptional<
  Action.Props<content.ProvidedProps>,
  'onClick'
> & {
  conclusionsRouteTree: ConclusionsRouteTree;
  quizUUID: M.Quiz['uuid'];
};

function QuizAction({
  conclusionsRouteTree,
  quizUUID,
  onClick,
  ...actionProps
}: Props) {
  const lang = I18n.activeLangStateUnit.useState();

  const handleClick = useCallback(() => {
    const history = Routing.getHistory();

    history?.push(
      conclusionsRouteTree.tree.view.QUIZ_UUID.getPath({
        routeParams: {
          ...getConclusionsRootParams(conclusionsRouteTree),
          LANG: lang,
          QUIZ_UUID: quizUUID,
        },
      }),
    );
    onClick?.();
  }, [conclusionsRouteTree, lang, onClick, quizUUID]);

  return (
    <Action.Component
      className={b({}, [actionProps.className])}
      onClick={handleClick}
      {...actionProps}
    />
  );
}

export * as content from './content';

export const Component = React.memo(QuizAction) as typeof QuizAction;
