import { useCallback } from 'react';

import * as TS from 'types';

import { activeLangStateUnit } from './activeLangStateUnit';
import { EntryReference } from './types';

export function useReference(reference: EntryReference): string {
  return getReferenceText(activeLangStateUnit.useState(), reference);
}

export function useOptionalReference(
  reference: EntryReference | null,
): string | null {
  const lang = activeLangStateUnit.useState();

  if (reference) {
    return getReferenceText(lang, reference);
  }

  return null;
}

export function getReferenceText(
  lang: TS.Language,
  reference: EntryReference,
): string {
  const { data, path } = reference;
  const node = [lang, ...path].reduce<string | null | TS.I18nData>(
    (acc, key) => (acc as any)[key],
    data,
  ) as string | null;

  return node === null
    ? `no entry for ${reference.path.join('.')} reference`
    : node;
}

export function useGetReferenceText() {
  const lang = activeLangStateUnit.useState();

  return useCallback(
    (reference: EntryReference) => getReferenceText(lang, reference),
    [lang],
  );
}

export function getReferenceTextForCurrentLang(
  reference: EntryReference,
): string {
  const lang = activeLangStateUnit.getState();
  return getReferenceText(lang, reference);
}
