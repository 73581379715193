import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  stream: M.Stream['uuid'];
};

type UserCoursesCounter = number;

type OutputData = UserCoursesCounter;

export const userCourseJoin = makeService<InputData, OutputData>(
  'user_course_join',
  'get',
);
