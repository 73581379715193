import React from 'react';

import { I18n } from 'services';
import * as M from 'types/serverModels';
import { block } from 'utils/classname';

import i18nData from '../../i18n.json';
import './style.scss';

const b = block('stream-select-indicators-option-extension');

type Props = {
  open: M.StreamOpen;
  active: M.StreamActive;
  className?: string;
};

function Indicators({ open, active, className }: Props) {
  const text = I18n.useText(i18nData).optionExtension.indicators;

  return (
    <div className={b({}, [className])}>
      <span className={b('indicator', { active: !!open })}>
        {text.open.label}
      </span>
      <span className={b('indicator', { active: !!active })}>
        {text.active.label}
      </span>
    </div>
  );
}

export const Component = React.memo(Indicators);
