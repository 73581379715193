import { makeEntryReference } from 'services/I18n';

import i18nData from './i18n.json';

export const i18nFieldsSharedReferences = {
  kindName: makeEntryReference(i18nData, data => data.field.kind.name),
  levelName: makeEntryReference(i18nData, data => data.field.level.name),
  descriptionName: makeEntryReference(
    i18nData,
    data => data.field.description.name,
  ),
};
