import * as M from 'types/serverModels';

import { makeService } from '../utils';

type InputData = {
  comment: {
    parent?: M.UUID;
    object: M.UUID;
    collection: M.Collection;
    body: string;
    for_tutor?: boolean;
  };
};

type APIOutputData = {
  comment: Omit<M.CommentData, 'author'> & { author: string };
  author_profile: Pick<M.User, 'login' | 'name' | 'avatar' | 'role'>;
};

export const post = makeService<InputData, APIOutputData, M.CommentData>(
  () => 'comment/',
  'api-v2-post',
  false,
  ({ comment, author_profile }) => ({ ...comment, author: author_profile }),
);
