type Props = {
  method: string;
  url: string;
  data: any;
  target?: string;
};

export function redirect({ method, url, data, target }: Props) {
  const form = document.createElement('form');
  form.action = url;
  form.method = method;
  form.target = target || '_self';
  if (data) {
    for (var key in data) {
      var input = document.createElement('textarea');
      input.name = key;
      input.value =
        typeof data[key] === 'object' ? JSON.stringify(data[key]) : data[key];
      form.appendChild(input);
    }
  }
  form.style.display = 'none';
  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
}
