import * as FormWithSteps from '../FormWithSteps';
import {
  conclusionsCallStateUnitUnit,
  saveMaterialsCallStateUnitUnit,
} from '../FormWithSteps/shared';
import {
  materialsInNonProjectLanguage,
  allMaterialsCallState,
} from '../FormWithSteps/units';
import { fullConstructorStepSections } from '../steps/makeSteps';
import { serverProjectDataUnit, projectReadCallStateUnit } from '../units';

export function resetState() {
  setTimeout(() => {
    FormWithSteps.resetState(fullConstructorStepSections['moderator'].flat());
    serverProjectDataUnit.resetState();
    materialsInNonProjectLanguage.resetState();
    allMaterialsCallState.resetState();
    projectReadCallStateUnit.resetState();
    saveMaterialsCallStateUnitUnit.resetState();
    conclusionsCallStateUnitUnit.resetState();
  }, 250);
}
