import * as M from 'types/serverModels';

import { makeService } from '../../utils';

type InputData = {
  file: File;
};

type OutputData = {
  experiment: Pick<M.Experiment, 'experiment_id' | 'sensors'>;
};

export const post = makeService<InputData, OutputData, OutputData>(
  () => {
    return `logger/experiment/`;
  },
  'api-v2-post',
  false,
  data => data,
  {
    contentType: 'multipart/form-data',
  },
);
