import React, { useMemo } from 'react';

import { TextInput } from 'components';
import { I18n } from 'services';
import { FormElementState, useFormElementState } from 'utils/FormState';
import { emailSyntax, nonEmptyString } from 'utils/validators';

import { emailInexistenceValidator } from './emailInexistenceValidator';
import i18nData from './i18n.json';
import {
  requiredFieldReference,
  invalidEmailValidationErrorReference,
} from './i18nSharedReferences';

export const useFieldState = () => {
  return useFormElementState<string>('', [
    nonEmptyString(requiredFieldReference),
    emailSyntax(invalidEmailValidationErrorReference),
    emailInexistenceValidator(),
  ]);
};

type Props = {
  formElementState: FormElementState<string>;
};

const Header = TextInput.makeHeaderWithLabel({
  useLabel: () => I18n.useText(i18nData).label,
  useTooltip: () => I18n.useText(i18nData).tooltip,
  color: 'black',
});

function EmailInput({ formElementState }: Props) {
  const text = I18n.useText(i18nData);

  const asyncValidationData: TextInput.AsyncValidationData = useMemo(() => {
    return {
      pendingMessage: I18n.makeEntryReference(
        i18nData,
        data => data.pendingMessage,
      ),
      successMessage: I18n.makeEntryReference(
        i18nData,
        data => data.successMessage,
      ),
    };
  }, []);

  return (
    <TextInput.Component
      formElementState={formElementState}
      Header={Header}
      placeholder={text.placeholder}
      type="email"
      asyncValidationData={asyncValidationData}
    />
  );
}

export const Component = React.memo(EmailInput);
