import { Language } from 'types';
import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  lang: Language;
  separator?: string;
  project: M.UUID;
};

export const dataExport = makeService<InputData, File>(
  'data_export',
  'get-file',
);
