import React, { useCallback } from 'react';

import { Button } from 'components';
import { ConstructorConfigContext } from 'features/project/Constructor/config/configContext';
import { List } from 'features/project/Constructor/subfeatures';
import { useRequiredContext } from 'utils/react/RequiredContext';

import { ConfigContext } from '../../../ConfigContext';
import * as modals from '../../../modals';

type Props = {};

function RightPart({}: Props) {
  const { config } = useRequiredContext(ConfigContext);
  const { mode, getProjectUUID } = useRequiredContext(ConstructorConfigContext);

  const handleClick = useCallback(() => {
    const projectUUID = getProjectUUID();

    const openModal =
      projectUUID === null
        ? modals.CantInvite.open
        : () => config.usersSelectionModalIsOpenUnit.setState(true);

    openModal();
  }, [config.usersSelectionModalIsOpenUnit, getProjectUUID]);

  switch (mode) {
    case 'compact':
      return null;
    case 'full':
      return (
        <List.TwoColumnHeader.ControlsRightColumn.Component>
          <List.TwoColumnHeader.ControlsRightColumn.Button.Component
            Icon={Button.PlusIcon.Component}
            onClick={handleClick}
          >
            {config.inviteButtonTitle}
          </List.TwoColumnHeader.ControlsRightColumn.Button.Component>
        </List.TwoColumnHeader.ControlsRightColumn.Component>
      );
  }
}

export const Component = React.memo(RightPart);
