import i18nData from 'features/project/Constructor/i18n.json';
import { I18n } from 'services';
import * as TS from 'types';
import {
  AbstractStateUnit,
  makeDerivedUnit,
  makeMappingUnitFromUnit,
} from 'utils/State';
import { makeUUID } from 'utils/UUID';

import { Question } from '../../shared';
import { config } from '../config';
import { EnumeratedQuestionInstance } from '../types';
import { makeInstancesMocks } from './instancesMocks';

const makeInitialMapQuestion = () => {
  const uuid = makeUUID();

  const makeSharedInstanceState = Question.makeSharedInstanceStateConstructor({
    title: () =>
      I18n.getText(i18nData).steps.shared.questions.list.map
        .valueForDefaultQuestion,
    id: uuid,
  });

  return Question.makeCachedInstanceFromQuestionInstance(
    Question.Map.constructorWidget.makeInstance(makeSharedInstanceState),
    'edit',
    config.questionKeyToConstructorWidget,
  );
};

export const makeInitialQuestionInstances = () => [
  makeInitialMapQuestion(),
  ...(process.env.RAZZLE_MOCK_CONSTRUCTOR_DATA === 'yes'
    ? makeInstancesMocks()
    : []),
];

export const {
  cachedStoreInstancesUnit,
  flatCachedInstancesUnit,
  questionInstancesUnit,
  flatQuestionInstancesUnit,
} = Question.makeInstanceUnits(makeInitialQuestionInstances());

export const questionIDToQuestionKindUnit = makeMappingUnitFromUnit(
  makeDerivedUnit(flatQuestionInstancesUnit).getUnit(questionInstances =>
    questionInstances.reduce<
      Record<string, TS.QuestionKind | AbstractStateUnit<TS.QuestionKind>>
    >(
      (acc, x) => ({
        ...acc,
        [x.id]: Question.getQuestionKindFromQuestionInstance(x),
      }),
      {},
    ),
  ),
);

export const questionIDToQuestionInstanceUnit = makeDerivedUnit(
  flatQuestionInstancesUnit,
).getUnit(questions =>
  questions.reduce<Record<string, Question.QuestionInstance>>(
    (acc, x) => ({
      ...acc,
      [x.id]: x,
    }),
    {},
  ),
);

export const questionIDToQuestionTextUnitMappingUnit = makeDerivedUnit(
  flatQuestionInstancesUnit,
).getUnit(questions =>
  questions.reduce<Record<string, AbstractStateUnit<string>>>((acc, x) => {
    return {
      [x.id]: x.questionText.formElementState.units.value,
      ...acc,
    };
  }, {}),
);

export const questionIDToQuestionTextUnit = makeMappingUnitFromUnit(
  questionIDToQuestionTextUnitMappingUnit,
);

export const enumeratedQuestionInstances = makeDerivedUnit(
  flatQuestionInstancesUnit,
).getUnit(instances =>
  instances.map<EnumeratedQuestionInstance>((x, index) => ({
    instance: x,
    number: index + 1,
  })),
);

export const questionsUnit = makeMappingUnitFromUnit(
  makeDerivedUnit(enumeratedQuestionInstances).getUnit(instances => {
    return instances.map(x => ({
      id: x.instance.id,
      questionKind: Question.getQuestionKindFromQuestionInstance(x.instance),
      text: x.instance.questionText.formElementState.units.value,
    }));
  }),
);

export const questionIDToIndexedQuestionTextUnit = makeMappingUnitFromUnit(
  makeDerivedUnit(enumeratedQuestionInstances).getUnit(questions =>
    questions.reduce<Record<string, AbstractStateUnit<string>>>((acc, x) => {
      return {
        [x.instance.id]: x.instance.questionText.formElementState.units.value,
        ...acc,
      };
    }, {}),
  ),
);
