import * as TS from 'types';
import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  data: {
    project?: string;
    author?: string;
    uuid?: string;
    parent?: string;
    sort?: {
      cdate?: -1 | 1;
      author?: -1 | 1;
    };
    limit?: number;
    offset?: number;
    lang: TS.Language;
  };
};

export const blogMessageList = makeService<InputData, M.BlogMessage[]>(
  'blog_message_list',
  'post-json',
);
