import React from 'react';

import { Page } from 'services';

import { routeTree } from '../routeTree';
import * as features from './features';

function UiKit() {
  return (
    <div>
      <features.UIKit.Component />
    </div>
  );
}

export const Component = Page.makePage({
  path: routeTree.LANG['ui-kit'].getPath(),
  routeProps: { exact: true },
  scrollTo: 'top-on-mount',
  Component: React.memo(UiKit),
  features: Object.values(features).map(x => x.feature),
});
