import { makeSharedEntryReference } from 'services/I18n/makeEntryReference';

export const joinButtonLabelReference = makeSharedEntryReference(
  data => data.joinButtonLabel,
);

export const acceptButtonLabelReference = makeSharedEntryReference(
  data => data.acceptButtonLabel,
);

export const rejectButtonLabelReference = makeSharedEntryReference(
  data => data.rejectButtonLabel,
);
