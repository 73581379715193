import * as R from 'ramda';

import { makePrimaryUnit } from 'utils/State';

import { QuestionInstance } from '../questions';
import { makeSharedInstanceStateConstructor } from '../questions/makeSharedInstanceState';
import {
  CachedQuestionInstance,
  InstancesCache,
  QuestionConstructor,
  QuestionMode,
} from '../types';

export function makeCachedInstanceFromQuestionInstance(
  questionInstance: QuestionInstance,
  mode: QuestionMode,
  questionKeyToConstructor: Record<string, QuestionConstructor<any, any, any>>,
): CachedQuestionInstance {
  const makeSharedInstanceState = makeSharedInstanceStateConstructor({
    id: questionInstance.id,
    title: questionInstance.questionText,
  });

  const instancesCache = Object.entries(
    R.omit([questionInstance.kind], questionKeyToConstructor),
  ).reduce(
    (acc, [key, constructor]) => ({
      ...acc,
      [key]: constructor.makeInstance(makeSharedInstanceState),
    }),
    { [questionInstance.kind]: questionInstance } as InstancesCache,
  );

  const activeQuestionKeyUnit = makePrimaryUnit(questionInstance.kind);

  return {
    kind: 'question-instance',
    id: questionInstance.id,
    activeQuestionKeyUnit,
    instancesCache,
    mode: makePrimaryUnit<QuestionMode>(mode),
    useStateInstance: () => instancesCache[activeQuestionKeyUnit.useState()],
    getStateInstance: () => instancesCache[activeQuestionKeyUnit.getState()],
  };
}
