import { Device } from './types';
import { unit } from './unit';

export function useMatch(matcher: Device) {
  const device = unit.useState();

  switch (matcher) {
    case 'desktop-l': {
      return (
        device === 'desktop-l' ||
        device === 'desktop-m' ||
        device === 'desktop-s' ||
        device === 'mobile'
      );
    }
    case 'desktop-m': {
      return (
        device === 'desktop-m' || device === 'desktop-s' || device === 'mobile'
      );
    }
    case 'desktop-s': {
      return device === 'desktop-s' || device === 'mobile';
    }
    case 'mobile': {
      return device === 'mobile';
    }
  }
}
