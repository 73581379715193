import { makePrimaryUnit } from 'utils/State';
import { makeUUID } from 'utils/UUID';

import { makeSharedInstanceStateConstructor } from '../questions/makeSharedInstanceState';
import {
  CachedQuestionInstance,
  InstancesCache,
  QuestionConstructor,
  QuestionKey,
  QuestionMode,
} from '../types';

export function makeNewCachedInstance(
  key: QuestionKey,
  id: string = makeUUID(),
  questionKeyToQuestionConstructorWidget: Record<
    QuestionKey,
    QuestionConstructor<any, any, any>
  >,
): CachedQuestionInstance {
  const makeSharedInstanceState = makeSharedInstanceStateConstructor({ id });

  const instancesCache = Object.entries(
    questionKeyToQuestionConstructorWidget,
  ).reduce<InstancesCache>(
    (acc, [key, widget]) => ({
      ...acc,
      [key]: widget.makeInstance(makeSharedInstanceState),
    }),
    {} as InstancesCache,
  );

  const activeQuestionKeyUnit = makePrimaryUnit(key);
  return {
    kind: 'question-instance',
    id,
    activeQuestionKeyUnit,
    instancesCache,
    mode: makePrimaryUnit<QuestionMode>('edit'),
    useStateInstance: () => instancesCache[activeQuestionKeyUnit.useState()],
    getStateInstance: () => instancesCache[activeQuestionKeyUnit.getState()],
  };
}
