import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = { course: M.Course };

type APIOutputData = {
  clean: M.Course;
  response: M.CourseReadCourse;
};

type OutputData = {
  course: M.Course;
  courseReadCourse: M.CourseReadCourse;
};

function extractResponse(data: APIOutputData): OutputData {
  return {
    course: data.clean,
    courseReadCourse: data.response,
  };
}

export const courseUpdate = makeService<InputData, APIOutputData, OutputData>(
  'course_update',
  'post-json',
  false,
  extractResponse,
  {
    extractBodyInput: ({ ticket, course }) => {
      return { ticket, course: JSON.stringify(course) };
    },
  },
);
