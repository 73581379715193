import React from 'react';

import { Page } from 'services';
import * as M from 'types/serverModels';

import { routeTree } from '../../routeTree';
import * as features from './features';

type Props = {
  group: M.ServerGroup;
};

function Discussion({ group }: Props) {
  return <features.Discussion.Component group={group} />;
}

export const path = routeTree.LANG.group.GROUP.discussion.getPath();

export const Component = Page.makePage({
  path: routeTree.LANG.group.GROUP.discussion.getPath(),
  routeProps: { exact: true },
  scrollTo: 'top-on-mount',
  Component: React.memo(Discussion) as typeof Discussion,
  features: Object.values(features).map(x => x.feature),
});
