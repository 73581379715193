import * as R from 'ramda';
import React, { useCallback, useMemo } from 'react';

import { ItemSelection, WidgetNumberMark } from 'components';
import i18nData from 'features/project/Constructor/i18n.json';
import { I18n } from 'services';
import * as questionIcons from 'shared/images/questionIcons';
import { PrimaryStateUnit } from 'utils/State';
import { block } from 'utils/classname';
import { useRequiredContext } from 'utils/react/RequiredContext';

import { QuestionsConfigContext } from '../../QuestionsConfigContext';
import { makeNewCachedInstance } from '../../cachedInstanceConstructors';
import {
  QuestionKey,
  WidgetPath,
  UngroupedStoreCachedInstances,
  GroupedStoreCachedInstances,
  StoreCachedInstances,
  ConstructorWidgetCachedInstance,
} from '../../types';
import { useHasAccessToTheFile } from '../../useHasAccessToTheFile';
import * as ControlsBar from '../ControlsBar';
import './style.scss';

const b = block('constructor-widget-for-question-selection');

type Props = {
  path: WidgetPath;
  widgetNumber: number;
  cachedStoreInstancesUnit: PrimaryStateUnit<StoreCachedInstances>;
};

const Header = ItemSelection.Header.makeComponent({
  useTitle: () => I18n.useText(i18nData).steps.shared.questions.header.title,
  useSubtitle: () =>
    I18n.useText(i18nData).steps.shared.questions.header.subtitle,
});

function ConstructorWidgetForQuestionSelection({
  path,
  widgetNumber,
  cachedStoreInstancesUnit,
}: Props) {
  const text = I18n.useText(i18nData).steps.shared.questions;

  const { widgets, questionKeyToConstructorWidget } = useRequiredContext(
    QuestionsConfigContext,
  );

  const hasAccessToTheFile = useHasAccessToTheFile();

  const handleQuestionSelect = useCallback(
    (questionKey: QuestionKey) => {
      cachedStoreInstancesUnit.setState(prev => {
        const lens =
          path.kind === 'ungrouped'
            ? R.lensPath<UngroupedStoreCachedInstances, 'instances', number>([
                'instances',
                path.index,
              ])
            : R.lensPath<
                GroupedStoreCachedInstances,
                'groups',
                number,
                'instances',
                number
              >(['groups', path.groupIndex, 'instances', path.widgetIndex]);

        return R.over(
          lens as R.Lens<StoreCachedInstances, ConstructorWidgetCachedInstance>,
          instance => {
            return makeNewCachedInstance(
              questionKey,
              instance.id,
              questionKeyToConstructorWidget,
            );
          },
          prev,
        );
      });
    },
    [cachedStoreInstancesUnit, path, questionKeyToConstructorWidget],
  );

  const Title = useMemo(
    () =>
      WidgetNumberMark.makeComponent({
        value: widgetNumber,
      }),
    [widgetNumber],
  );

  const Content = ItemSelection.Content.makeComponent({
    data: widgets.filter(hasAccessToTheFile).map(x => {
      const questionKey = x.key as QuestionKey;

      return {
        title: text.list[questionKey].label,
        itemKey: questionKey,
        tooltip: text.list[questionKey].tooltip,
        iconSrc: questionIcons[x.icon].src,
      };
    }),
    onSelect: handleQuestionSelect,
  });

  return (
    <div className={b()}>
      <ItemSelection.Component
        Header={Header}
        Content={Content}
        Title={Title}
        ControlsBar={ControlsBar.Component}
      />
    </div>
  );
}

export const Component = React.memo(ConstructorWidgetForQuestionSelection);
