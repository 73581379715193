import * as TS from 'types';
import {
  AbstractStateUnit,
  makeDerivedUnit,
  makeMappingUnitFromUnit,
} from 'utils/State';

import * as Question from '../Questions/Question';

export const {
  cachedStoreInstancesUnit,
  flatCachedInstancesUnit,
  questionInstancesUnit,
  flatQuestionInstancesUnit,
} = Question.makeInstanceUnits([]);

export const questionIDToQuestionKindUnit = makeMappingUnitFromUnit(
  makeDerivedUnit(flatQuestionInstancesUnit).getUnit(questionInstances =>
    questionInstances.reduce<
      Record<
        string,
        TS.QuizQuestionKind | AbstractStateUnit<TS.QuizQuestionKind>
      >
    >(
      (acc, x) => ({
        ...acc,
        [x.id]: Question.getQuestionKindFromQuestionInstance(x),
      }),
      {},
    ),
  ),
);
