import React from 'react';

import { Page } from 'services';

import { routeTree } from '../../../routeTree';
import * as features from './features';

type Props = features.Members.Props;

function Members(props: Props) {
  return <features.Members.Component {...props} />;
}

export const path = [
  routeTree.LANG['project-book'].list.PROJECT_BOOK.members.getPath(),
  routeTree.LANG['project-book'].list.PROJECT_BOOK.users.USER.members.getPath(),
];

export const Component = Page.makePage({
  path,
  routeProps: { exact: true },
  scrollTo: 'none',
  Component: React.memo(Members) as typeof Members,
  features: Object.values(features).map(x => x.feature),
}) as typeof Members;
