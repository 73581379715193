import React from 'react';

import { ConstructorStepSection } from 'features/project/Constructor/subfeatures';

type Props = {
  heading: string;
  tooltip: string;
  Content: React.FC;
};

function LabeledSection({ Content, heading, tooltip }: Props) {
  return (
    <ConstructorStepSection.Component title={heading} tooltip={tooltip}>
      <Content />
    </ConstructorStepSection.Component>
  );
}

export const Component = React.memo(LabeledSection);
