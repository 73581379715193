import React from 'react';

import { Page } from 'services';
import { block } from 'utils/classname';

import { routeTree } from '../routeTree';
import * as features from './features';
import './style.scss';

const b = block('confirm-email-page');

function ConfirmEmail() {
  return (
    <div className={b()}>
      <features.ConfirmEmailFormFeature.Component />
    </div>
  );
}

export const Component = Page.makePage({
  path: routeTree.LANG['confirm-email'].UUID.getPath(),
  routeProps: { exact: true },
  scrollTo: 'top-on-mount',
  Component: React.memo(ConfirmEmail),
  features: Object.values(features).map(x => x.feature),
});
