import { I18n } from 'services';
import * as M from 'types/serverModels';

export type QuizTrackObject = Pick<
  M.CourseStreamServiceQuizTrackObjectOutput,
  'type' | 'object' | 'abstract'
>;

export function getQuizTrackObjectAbstractMultilingStrings(
  trackObject: QuizTrackObject,
  t: ReturnType<typeof I18n.useGetMultilingTranslation>,
) {
  return [trackObject.abstract, trackObject.object.description].filter(
    (x): x is M.MultilingString => !!(x && t(x)),
  );
}

export function formatQuizTrackObjectAbstract(
  trackObject: QuizTrackObject,
  t: ReturnType<typeof I18n.useGetMultilingTranslation>,
) {
  return getQuizTrackObjectAbstractMultilingStrings(trackObject, t).reduce(
    (acc, x) => (acc ? acc : t(x)),
    '',
  );
}
