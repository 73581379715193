import { vennChartSetNames } from 'shared/constants';
import { Language } from 'types';
import * as M from 'types/serverModels';
import { AbstractStateUnit, PrimaryStateUnit } from 'utils/State';

import { AnswerVariant } from '../AnswerVariant';
import { makeQuestionFromServerQuestion } from './makeQuestionFromServerQuestion';
import * as setConstructors from './setConstructors';
import { Set } from './types';

type Args = {
  widget: M.VennChartWidget;
  questionIDToQuestion: Record<string, M.Question>;
  langUnit: AbstractStateUnit<Language>;
  variantSelectionQuestionIDToAnswerVariants?: Record<
    string,
    PrimaryStateUnit<AnswerVariant[]>
  >;
};

export function makeSetsFromServerData({
  widget,
  questionIDToQuestion,
  variantSelectionQuestionIDToAnswerVariants,
  langUnit,
}: Args): Set[] {
  const { set1, set2, set3, set4 } = widget.descriptor;

  return [set1, set2, set3, set4].map((set, index): Set => {
    const name = vennChartSetNames[index];
    if (typeof set === 'string') {
      return {
        kind: 'without-selected-question',
        name,
      };
    }

    const question = questionIDToQuestion[set.qId];

    // if question was deleted somehow
    if (question === undefined) {
      return {
        kind: 'without-selected-question',
        name,
      };
    }

    const questionForVenn = makeQuestionFromServerQuestion({
      question,
      variantSelectionQuestionIDToAnswerVariants,
      langUnit,
    });

    switch (questionForVenn.kind) {
      case 'with-single-variant':
        return setConstructors.forSingleVariant(
          questionForVenn,
          name,
          (set.variants as string[])[0],
        );
      case 'with-multiple-variants':
        return setConstructors.forMultipleVariants(
          questionForVenn,
          name,
          set.variants as string[],
        );

      case 'with-number': {
        const rangeVariant = set.variants[0] as M.RangeVariant;
        return setConstructors.forNumberQuestion(questionForVenn, name, {
          from: rangeVariant.vStart ?? null,
          to: rangeVariant.vEnd ?? null,
        });
      }
      default:
        return { kind: 'without-selected-question', name };
    }
  });
}
