import React from 'react';

import { block } from 'utils/classname';

import './style.scss';

const b = block('fullscreen-widget-container-default-content');

type Props = React.PropsWithChildren<{}>;

function DefaultContent({ children }: Props) {
  return <div className={b()}>{children}</div>;
}

export const Component = React.memo(DefaultContent) as typeof DefaultContent;
