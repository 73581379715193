import * as TS from 'types';
import {
  AbstractStateUnit,
  makeDerivedUnit,
  makeMappingUnitFromUnit,
} from 'utils/State';

import { Question } from '../../shared';
import { makeInstancesMocks } from './instancesMocks';

export const makeInitialQuestionInstances = () =>
  process.env.RAZZLE_MOCK_CONSTRUCTOR_DATA === 'yes'
    ? makeInstancesMocks()
    : [];

export const {
  cachedStoreInstancesUnit,
  flatCachedInstancesUnit,
  questionInstancesUnit,
  flatQuestionInstancesUnit,
} = Question.makeInstanceUnits(makeInitialQuestionInstances());

export const questionIDToQuestionKindUnit = makeMappingUnitFromUnit(
  makeDerivedUnit(flatQuestionInstancesUnit).getUnit(questionInstances =>
    questionInstances.reduce<
      Record<string, TS.QuestionKind | AbstractStateUnit<TS.QuestionKind>>
    >(
      (acc, x) => ({
        ...acc,
        [x.id]: Question.getQuestionKindFromQuestionInstance(x),
      }),
      {},
    ),
  ),
);
