import { createRequiredContext } from 'utils/react/RequiredContext';

import { MaterialStateInstance } from '../materials';
import { MaterialConstructorWidget } from '../types';

export type WidgetContextData = {
  onDelete(): void;
  onMoveNext(): void;
  onMovePrev(): void;
  stateInstance: MaterialStateInstance;
  constructorWidget: MaterialConstructorWidget<any, any, any>;
};

export const WidgetContext = createRequiredContext<WidgetContextData>();
