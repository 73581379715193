import { I18n } from 'services';

import {
  ProjectTrackObject,
  formatProjectTrackObjectAbstract,
} from './formatProjectTrackObjectAbstract';
import {
  QuizTrackObject,
  formatQuizTrackObjectAbstract,
} from './formatQuizTrackObjectAbstract';
import {
  SectionTrackObject,
  formatSectionTrackObjectAbstract,
} from './formatSectionTrackObjectAbstract';
import {
  UnitTrackObject,
  formatUnitTrackObjectAbstract,
} from './formatUnitTrackObjectAbstract';

export function formatTrackObjectAbstract(
  trackObject:
    | SectionTrackObject
    | ProjectTrackObject
    | UnitTrackObject
    | QuizTrackObject,
  t: ReturnType<typeof I18n.useGetMultilingTranslation>,
) {
  switch (trackObject.type) {
    case 'section':
    case 'subsection': {
      return formatSectionTrackObjectAbstract(trackObject, t);
    }
    case 'project': {
      return formatProjectTrackObjectAbstract(trackObject, t);
    }
    case 'unit': {
      return formatUnitTrackObjectAbstract(trackObject, t);
    }
    case 'quiz': {
      return formatQuizTrackObjectAbstract(trackObject, t);
    }
  }
}
