import * as TS from 'types';

import { makeService } from './utils';

type InputData = {
  login: string;
  password: string;
  email: string;
  role: 1 | 2;
  referral?: string;
  notification: 'yes' | 'no';
  validate_email?: boolean;
  activate?: boolean;
  language: TS.Language;
};

export const userRegister = makeService<InputData, string>(
  'user_register',
  'post',
);
