import React, { useEffect } from 'react';

import i18nData from 'features/project/Constructor/i18n.json';
import { ErrorModal } from 'features/project/Constructor/subfeatures';
import { I18n } from 'services';
import * as TS from 'types';
import { makePrimaryUnit } from 'utils/State';

type Props = {};

export const errorCodeUnit = makePrimaryUnit<
  | keyof (typeof i18nData)[TS.Language]['modals']['editError']['errorCodes']
  | null
>(null);

function EditError({}: Props) {
  const isOpenUnit = ErrorModal.useIsOpenUnit();
  const errorCode = errorCodeUnit.useState();

  const text = I18n.useText(i18nData).modals.editError.errorCodes;

  useEffect(() => {
    isOpenUnit.setState(!!errorCode);
  }, [errorCode, isOpenUnit]);

  useEffect(() => {
    return () => {
      errorCodeUnit.resetState();
    };
  }, []);

  useEffect(() => {
    return isOpenUnit.subscribe({
      name: 'closing-the-modal',
      callback: newState => {
        if (!newState) {
          errorCodeUnit.resetState();
        }
      },
    });
  }, [isOpenUnit]);

  return (
    <ErrorModal.Component isOpenUnit={isOpenUnit}>
      {errorCode && text[errorCode]}
    </ErrorModal.Component>
  );
}

export const Component = React.memo(EditError);
