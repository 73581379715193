import React, { useMemo } from 'react';

import { makeDerivedUnit, makeMappingUnitFromUnit } from 'utils/State';
import { block } from 'utils/classname';
import { useRequiredContext } from 'utils/react/RequiredContext';

import { Step } from '../FormWithSteps';
import { ConstructorConfigContext } from '../config/configContext';
import { ConstructorStepsContext } from '../steps/stepsContext';
import { projectNameFormElementState } from '../units';
import './style.scss';

const b = block('progress-bar');

type Props = {};

const makeProgressInPercentsUnit = (steps: Step[]) => {
  const projectNameProgress = makeDerivedUnit(
    projectNameFormElementState.units.value,
  ).getUnit(x => x !== '');

  const stepProgressUnits = makeMappingUnitFromUnit(
    makeDerivedUnit(...steps.map(x => x.progressInvariantUnitsUnit)).getUnit(
      (...xs) => {
        return [...xs.flat(), projectNameProgress];
      },
    ),
  );

  const progressUnit = makeDerivedUnit(stepProgressUnits).getUnit(
    progresses => progresses.filter(x => x).length / progresses.length,
  );

  return makeDerivedUnit(progressUnit).getUnit(
    progress => `${progress * 100}%`,
  );
};

function ProgressBar({}: Props) {
  const { mode } = useRequiredContext(ConstructorConfigContext);
  const { steps } = useRequiredContext(ConstructorStepsContext);

  const progressInPercentsUnit = useMemo(
    () => makeProgressInPercentsUnit(steps),
    [steps],
  );

  const progress = progressInPercentsUnit.useState();

  return (
    <div
      style={{ width: progress }}
      className={b({ 'constructor-mode': mode })}
    />
  );
}

export const Component = React.memo(ProgressBar);
