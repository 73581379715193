import * as M from 'types/serverModels';

export function matchMaterial(
  data: M.ProjectBookChange['change'],
): M.ProjectBookUnit['uuid'] | null {
  const [, materialUUID] =
    data?.path?.match(/materials\[uuid=([a-z0-9-]+?)]/) || [];

  return materialUUID || null;
}
