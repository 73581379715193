import { makeSharedEntryReference } from 'services/I18n/makeEntryReference';

export const cancelButtonLabelReference = makeSharedEntryReference(
  data => data.cancelButtonLabel,
);

export const requiredFieldValidationErrorReference = makeSharedEntryReference(
  data => data.validationErrors.requiredField,
);

export const invalidEmailValidationErrorReference = makeSharedEntryReference(
  data => data.validationErrors.invalidEmail,
);
