import React, { useCallback } from 'react';

import { emulateClick } from 'utils/DOM';
import { block } from 'utils/classname';

import './style.scss';

const b = block('action');

type BeforeClickEvent = { event: React.MouseEvent; callback: () => void };

export type Props<T extends { [key: string]: unknown } = {}> = T & {
  Content: React.VFC<T>;
  className?: string;
  onClick(): void;
  beforeClick?(event: BeforeClickEvent): void;
};

function Action<T extends { [key: string]: unknown } = {}>({
  Content,
  className,
  onClick,
  beforeClick,
  ...contentRest
}: Props<T>) {
  const handleKeyDown: React.KeyboardEventHandler = useCallback(event => {
    emulateClick(event);
  }, []);

  const handleClick: React.MouseEventHandler = useCallback(
    event => {
      const callback = () => {
        onClick();
      };

      if (beforeClick !== undefined) {
        beforeClick({ event, callback });

        return;
      }

      callback();
    },
    [onClick, beforeClick],
  );

  return (
    <div
      className={b({}, [className])}
      tabIndex={0}
      onKeyDown={handleKeyDown}
      onClick={handleClick}
    >
      <Content {...(contentRest as unknown as T)} />
    </div>
  );
}

export const Component = React.memo(Action) as typeof Action;
