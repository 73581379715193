import {
  MaterialConstructorWidget,
  SharedInstanceStateConstructor,
} from '../../types';
import { StateInstance } from './types';

export type { StateInstance };

export const constructorWidget: MaterialConstructorWidget<
  'notSelected',
  StateInstance,
  typeof makeInstance
> = {
  key: 'notSelected',
  makeInstance,
};

function makeInstance(
  makeSharedInstanceState: SharedInstanceStateConstructor,
): StateInstance {
  return {
    kind: 'notSelected',
    makeServerUnit: () => {
      throw Error('execution has no sense');
    },
    ...makeSharedInstanceState(),
  };
}
