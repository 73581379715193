import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  uuid: M.Project['uuid'];
  user: M.User['login'];
};

export const projectExpelOwner = makeService<InputData, M.Project>(
  'project_expel_owner',
  'post',
);
