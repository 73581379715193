import { makeFeature } from 'services/Feature';

import i18nData from './i18n.json';
import * as subfeatures from './subfeatures';

export const feature = makeFeature({
  name: 'Footer',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "footer" */
      './Component'
    ),
  i18nData,
  i18nSharedReferences: null,
  subfeatures: Object.values(subfeatures).map(x => x.feature),
});

export * as LogoLink from './LogoLink';
export * as Signature from './Signature';

export { footerContentUnit } from './units';

export const Component = feature.Component;
