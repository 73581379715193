import { useMemo } from 'react';

import { I18n } from 'services';
import * as M from 'types/serverModels';

type References = Record<M.MaterialUnitType, I18n.EntryReference>;

export function useMaterialUnitTypeText(references: References) {
  const linkText = I18n.useReference(references.link);
  const textText = I18n.useReference(references.text);
  const fileText = I18n.useReference(references.file);
  const videoText = I18n.useReference(references.video);

  const text = useMemo(() => {
    return {
      link: linkText,
      text: textText,
      file: fileText,
      video: videoText,
    };
  }, [linkText, textText, fileText, videoText]);

  return text;
}
