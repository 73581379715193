import * as M from 'types/serverModels';

import { makeService } from '../utils';

type InputData = {
  project: M.ProjectWriteData | { copyof: M.UUID };
};

type APIOutputData = {
  project: M.Project;
};

export const post = makeService<InputData, APIOutputData, M.Project>(
  () => 'project/',
  'api-v2-post',
  false,
  ({ project }) => project,
);
