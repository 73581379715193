import React, { useCallback, useMemo } from 'react';

import { ConstructorConfigContext } from 'features/project/Constructor/config/configContext';
import { PendingActionSubList } from 'features/project/Constructor/subfeatures';
import { useRequiredContext } from 'utils/react/RequiredContext';

import { ConfigContext } from '../../ConfigContext';
import * as Entry from './Entry';

type Props = {};

function PendingSublist({}: Props) {
  const { config } = useRequiredContext(ConfigContext);

  const { getProjectUUID } = useRequiredContext(ConstructorConfigContext);

  const filter = useMemo(() => {
    return {
      type: config.pendingActionType,
      project: getProjectUUID(),
    };
  }, [config.pendingActionType, getProjectUUID]);

  const useTitle = useCallback(
    () => config.pendingUsersSublistTitle,
    [config.pendingUsersSublistTitle],
  );

  return (
    <PendingActionSubList.Component
      Entry={Entry.Component}
      filter={filter}
      pendingActionsUnit={config.invitedUsersUnit}
      useTitle={useTitle}
    />
  );
}

export const Component = React.memo(PendingSublist);
