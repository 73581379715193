import React, { useMemo } from 'react';

import { Modal } from 'components';
import i18nData from 'features/project/Constructor/i18n.json';
import { API, I18n } from 'services';
import { block } from 'utils/classname';

import { invitedSupervisorsUnit, supervisorsUnit } from '../../../units';
import { LeadershipList } from '../shared';
import './style.scss';

type Props = {};

const b = block('supervisors-list');

function AuthorsList({}: Props) {
  const text =
    I18n.useText(i18nData).steps.access.sections.leadership.subsections
      .supervisors;

  const usersSelectionModalIsOpenUnit = Modal.useIsOpenUnit();
  const pendingActionListCallStateUnit =
    API.services.pendingActionList.useCallStateUnit();

  const config = useMemo((): LeadershipList.SupervisorsConfig => {
    return {
      kind: 'supervisors',
      pendingActionType: 'project_supervisor_invitation',
      listTitle: text.title,
      userSelectionModalTitle: text.inviteSupervisorsModalTitle,
      pendingUsersSublistTitle: text.pendingSupervisorsSublistTitle,
      inviteButtonTitle: text.inviteSupervisorsButtonText,
      usersUnit: supervisorsUnit,
      invitedUsersUnit: invitedSupervisorsUnit,
      usersSelectionModalIsOpenUnit,
      pendingActionListCallStateUnit,
    };
  }, [usersSelectionModalIsOpenUnit, pendingActionListCallStateUnit, text]);

  return <LeadershipList.Component config={config} className={b()} />;
}

export const Component = React.memo(AuthorsList);
