import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  key: string;
};

export const userActivate = makeService<InputData, M.UserActivate>(
  'user_activate',
  'post',
);
