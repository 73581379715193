import { quizLanguageUnit } from 'features/quiz/Constructor/units';
import * as M from 'types/serverModels';

import { ServerQuestion, StateInstance } from './types';

type ServerQuestionConstructorArgs = Pick<
  StateInstance,
  'leftColumn' | 'rightColumn' | 'matchingsUnit' | 'score'
>;

export function makeServerQuestion({
  leftColumn,
  rightColumn,
  matchingsUnit,
  score,
}: ServerQuestionConstructorArgs): Pick<
  ServerQuestion,
  'type' | 'columns' | 'matchings' | 'score'
> {
  return {
    type: 'match',
    columns: {
      left: leftColumn.isImage.getValue()
        ? {
            mode: 'image',
            elements: leftColumn.imageElementsUnit
              .getState()
              .reduce<M.MatchQuizQuestionImageElement[]>((acc, x) => {
                const image = x.image.getValue();

                if (!image) {
                  return acc;
                }

                return [
                  ...acc,
                  {
                    ...x,
                    image: image.original,
                  },
                ];
              }, []),
          }
        : {
            mode: 'text',
            elements: leftColumn.textElementsUnit.getState().map(x => ({
              ...x,
              text: x.text.getMergedMultilingString(
                quizLanguageUnit.getState(),
              ),
            })),
          },
      right: {
        elements: rightColumn.elementsUnit.getState().map(x => ({
          ...x,
          text: x.text.getMergedMultilingString(quizLanguageUnit.getState()),
        })),
      },
    },
    matchings: (leftColumn.isImage.getValue()
      ? leftColumn.imageElementsUnit.getState()
      : leftColumn.textElementsUnit.getState()
    ).reduce<M.MatchQuizQuestion['matchings']>(
      (acc, left) => ({
        ...acc,
        [left.uuid]: Object.values(
          matchingsUnit.getState()[left.uuid].stateUnit.getState(),
        ).reduce<
          M.MatchQuizQuestion['columns']['right']['elements'][number]['uuid'][]
        >((acc, y) => {
          if (!y.value.getValue()) {
            return acc;
          }

          return [...acc, y.item.uuid];
        }, []),
      }),
      {},
    ),
    score: Number(score.getValue()),
  };
}
