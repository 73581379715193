import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  ref: M.RefType;
};

export const refLoad = makeService<InputData, M.ReferenceBook[]>(
  'ref_load',
  'get',
);
