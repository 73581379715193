export function getSubarray<T>(
  array: T[],
  pointIndex: number,
  radius: number,
): T[] {
  const exceedsLeftBoundary = pointIndex - radius < 0;
  const exceedsRightBoundary = pointIndex + radius >= array.length;

  const leftPart = exceedsLeftBoundary
    ? [
        ...array.slice(array.length + pointIndex - radius, array.length),
        ...array.slice(0, pointIndex),
      ]
    : array.slice(pointIndex - radius, pointIndex);

  // right part + point
  const rightPart = exceedsRightBoundary
    ? [
        ...array.slice(pointIndex, array.length),
        ...array.slice(0, array.length - pointIndex + radius - 1),
      ]
    : array.slice(pointIndex, pointIndex + radius + 1);

  return [...leftPart, ...rightPart];
}

export function getNextIndex<T>(array: T[], index: number): number {
  if (index === array.length - 1) {
    return 0;
  }

  if (index >= 0) {
    return index + 1;
  }

  return -1;
}

export function getPrevIndex<T>(array: T[], index: number): number {
  if (index === 0) {
    return array.length - 1;
  }

  if (index < array.length) {
    return index - 1;
  }

  return -1;
}
