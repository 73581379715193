import * as M from 'types/serverModels';

import { makeService } from '../../utils';

type PathInputData = {};

type BodyInputData = Pick<M.ProjectBookTask, 'uuid' | 'order'>[];

type InputData = PathInputData & BodyInputData;

type APIOutputData = void;

type OutputData = void;

function extractResponse(data: APIOutputData): OutputData {
  return data;
}

export const put = makeService<InputData, APIOutputData, OutputData>(
  ({ ticket }, convertToSearchParams) =>
    `project_book_task/order/?${convertToSearchParams({
      ticket,
    })}`,
  'api-v2-put',
  false,
  extractResponse,
  {
    extractBodyInput: input => {
      const { ticket, ...bodyInput } = input;

      return bodyInput;
    },
  },
);
