import { makeSharedEntryReference } from 'services/I18n/makeEntryReference';

export const userRoleReference = {
  0: makeSharedEntryReference(data => data.user.roleText[0]),
  1: makeSharedEntryReference(data => data.user.roleText[1]),
  2: makeSharedEntryReference(data => data.user.roleText[2]),
  4: makeSharedEntryReference(data => data.user.roleText[4]),
  8: makeSharedEntryReference(data => data.user.roleText[8]),
};

export const userStatusReference = {
  '-1': makeSharedEntryReference(data => data.user.statusText[-1]),
  0: makeSharedEntryReference(data => data.user.statusText[0]),
  1: makeSharedEntryReference(data => data.user.statusText[1]),
  '-4': makeSharedEntryReference(data => data.user.statusText[-4]),
};
