import { makeFeature } from 'services/Feature';

export const feature = makeFeature({
  name: 'LandingPageHighlights1Elements',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "landing-page-highlights-1-elements" */
      './Component'
    ),
  i18nData: null,
  i18nSharedReferences: null,
  subfeatures: [],
});

export const Component = feature.Component;
