import * as M from 'types/serverModels';

import { makeService } from './utils';

type Action = 'accept' | 'reject' | 'cancel';

type InputData = {
  uuid: M.Notification['uuid'];
  action: Action;
};

type OutputData = boolean | undefined;

export const pendingActionChange = makeService<InputData, OutputData>(
  'pending_action_change',
  'get',
);
