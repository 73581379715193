import React, { useEffect } from 'react';

import { ConstructorConfigContext } from 'features/project/Constructor/config/configContext';
import i18nData from 'features/project/Constructor/i18n.json';
import { ConstructorStepsContext } from 'features/project/Constructor/steps/stepsContext';
import { OkModal } from 'features/project/Constructor/subfeatures';
import { serverProjectDataUnit } from 'features/project/Constructor/units';
import { I18n } from 'services';
import { useRequiredContext } from 'utils/react/RequiredContext';

import { ProjectWriteContext } from '../../ProjectWriteContext';

type Props = {};

const Header = OkModal.Header.makeFromTitle(() => {
  const serverProject = serverProjectDataUnit.useState();
  const text = I18n.useText(i18nData).modals.successfulPublish;

  if (serverProject?.private) {
    return text.forPrivate.title;
  }
  switch (serverProject?.status) {
    case 2:
      return text.forPublic.withModeration.title;
    case 4:
      return text.forPublic.withoutModeration.title;
    default:
      return null;
  }
});

const useLabel = () => {
  const serverProject = serverProjectDataUnit.useState();
  const text = I18n.useText(i18nData).modals.successfulPublish;

  if (serverProject?.private) {
    return text.forPrivate.text;
  }
  switch (serverProject?.status) {
    case 2:
      return text.forPublic.withModeration.text;
    case 4:
      return text.forPublic.withoutModeration.text;
    default:
      return null;
  }
};

function PublishSuccessful({}: Props) {
  const { status } = useRequiredContext(ProjectWriteContext);

  const { mode } = useRequiredContext(ConstructorConfigContext);
  const { steps } = useRequiredContext(ConstructorStepsContext);

  const isOpenUnit = OkModal.useIsOpenUnit();

  useEffect(() => {
    return status.callStateUnit.subscribe({
      name: 'successful-publish-modal-handler',
      callback: callState => {
        switch (callState.kind) {
          case 'successful': {
            serverProjectDataUnit.setState(callState.data);
            steps.forEach(x => {
              return x.fillFromExistingProject?.({
                project: callState.data,
                language: callState.data.langs![0],
                mode,
              });
            });
          }
        }
      },
    });
  }, [isOpenUnit, mode, status.callStateUnit, steps]);

  return (
    <OkModal.Component isOpenUnit={isOpenUnit} Header={Header}>
      {useLabel()}
    </OkModal.Component>
  );
}

export const Component = React.memo(PublishSuccessful);
