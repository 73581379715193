import { makeSharedEntryReference } from 'services/I18n';

export const i18nStageStatusSharedReferences = {
  assigned: makeSharedEntryReference(
    data => data.projectBook.stage.status.assigned,
  ),
  onprogress: makeSharedEntryReference(
    data => data.projectBook.stage.status.onprogress,
  ),
  assessment: makeSharedEntryReference(
    data => data.projectBook.stage.status.assessment,
  ),
  finished: makeSharedEntryReference(
    data => data.projectBook.stage.status.finished,
  ),
  null: makeSharedEntryReference(data => data.projectBook.stage.status.null),
};
