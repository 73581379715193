import React from 'react';

import { routeTree } from 'pages/routeTree';
import { Page } from 'services';

import * as features from './features';

function QuizConstructor() {
  return <features.QuizConstructor.Component kind="page" />;
}

export const Component = Page.makePage({
  path: routeTree.LANG.quiz.constructor.getPath(),
  scrollTo: 'top-on-mount',
  Component: React.memo(QuizConstructor),
  features: Object.values(features).map(x => x.feature),
});
