import { Location } from 'history';

let previousLocation: Location | null = null;

export function getPreviousLocation(): Location | null {
  return previousLocation;
}

export function setPreviousLocation(value: Location) {
  previousLocation = value;
}
