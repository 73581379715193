import { constructorModeUnit } from 'features/project/Constructor/units';
import { makeDerivedUnit } from 'utils/State';

import {
  annotationState,
  projectDescriptionState,
  projectImageState,
  ageRangesChecksUnit,
  subjectsChecksUnit,
  keywordsState,
} from './inputState';

const annotationInvariantUnit = makeDerivedUnit(
  annotationState.units.value,
).getUnit(x => x !== '');
const projectDescriptionInvariantUnit = makeDerivedUnit(
  projectDescriptionState.units.value,
).getUnit(x => x !== '');
const projectImageInvariantUnit = makeDerivedUnit(
  projectImageState.units.value,
).getUnit(x => x !== null);
const keywordsInvariantUnit = makeDerivedUnit(
  keywordsState.units.value,
).getUnit(x => x !== null);
const ageRangesInvariantUnit = makeDerivedUnit(ageRangesChecksUnit).getUnit(
  xs => xs.some(x => x === true),
);
const subjectsInvariantUnit = makeDerivedUnit(subjectsChecksUnit).getUnit(xs =>
  xs.some(x => x === true),
);

export const progressInvariantUnitsUnit = makeDerivedUnit(
  constructorModeUnit,
).getUnit(mode => {
  switch (mode) {
    case 'full':
      return [
        annotationInvariantUnit,
        projectDescriptionInvariantUnit,
        projectImageInvariantUnit,
        keywordsInvariantUnit,
        ageRangesInvariantUnit,
        subjectsInvariantUnit,
      ];
    case 'compact':
      return [
        annotationInvariantUnit,
        projectDescriptionInvariantUnit,
        projectImageInvariantUnit,
        keywordsInvariantUnit,
      ];
  }
});
