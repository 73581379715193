import { SetOptional } from 'type-fest';

import { State } from '../types';
import { makeCheckCallStateUnit } from './makeCheckCallStateUnit';

export function makeCheck<T extends SetOptional<State, 'callStateUnit'>>(
  state: T,
): T & {
  callStateUnit: ReturnType<typeof makeCheckCallStateUnit>;
} {
  const callStateUnit = makeCheckCallStateUnit();

  return { ...state, callStateUnit: state.callStateUnit || callStateUnit };
}
