import { makeFeature } from 'services/Feature';

import i18nData from './i18n.json';
import * as i18nSharedReferences from './i18nSharedReferences';
import * as subfeatures from './subfeatures';

export const feature = makeFeature({
  name: 'ProjectBookPagePassport',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "project-book-page-passport" */
      './Component'
    ),
  i18nSharedReferences: Object.values(i18nSharedReferences),
  i18nData,
  subfeatures: Object.values(subfeatures).map(x => x.feature),
});

export type { Props } from './Component';

export type { Field } from './types';

export { i18nFieldsSharedReferences as i18nPassportFieldsSharedReferences } from './i18nFieldsSharedReferences';

export const Component = feature.Component;
