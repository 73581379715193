import * as M from 'types/serverModels';
import { MappedState } from 'utils/State';

import { SetForRequest } from './types';

export function makeServerSet(
  set: MappedState<SetForRequest>,
): M.VennChartWidgetDescriptorSet | '-1' {
  switch (set.kind) {
    case 'without-selected-question':
      return '-1';

    case 'for-multiple-variants':
      return {
        qId: set.questionID,
        variants: set.variantIDs,
      };

    case 'for-single-variant':
      return {
        qId: set.questionID,
        variants: [set.selectedVariantID!],
      };
    case 'for-number-question':
      return {
        qId: set.questionID,
        variants: [
          {
            vStart: set.range.from!,
            vEnd: set.range.to!,
          },
        ],
      };
  }
}
