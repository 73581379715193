import React from 'react';

import * as TS from 'types';
import * as M from 'types/serverModels';
import { makeFormElementState } from 'utils/FormState';
import { nonEmptyArray } from 'utils/validators';

import { isRequired } from '../i18nSharedReferences';

export const makeState = (
  question: M.MatchQuizQuestion,
  initialValue: M.MatchQuizQuestion['matchings'] = {},
) => {
  const formElementState = makeFormElementState(
    question.columns.left.elements.reduce<TS.MatchStateItem>((acc, x) => {
      const formElementState = makeFormElementState<string[]>(
        [],
        [nonEmptyArray(isRequired)],
      );
      formElementState.units.value.setState(initialValue[x.uuid] || []);

      return {
        ...acc,
        [x.uuid]: formElementState,
      };
    }, {}),
  );

  return formElementState;
};

export const useState = (
  question: M.MatchQuizQuestion,
  initialValue?: M.MatchQuizQuestion['matchings'],
) => {
  const [state] = React.useState(() => makeState(question, initialValue));
  return state;
};
