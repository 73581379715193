type Args = {
  updatePosition: () => void;
};

export function stickFixedPositionElement({ updatePosition }: Args) {
  let requestID: number;

  function callback() {
    updatePosition();

    loop();
  }

  function loop() {
    requestID = requestAnimationFrame(callback);
  }

  requestID = requestAnimationFrame(loop);

  return () => {
    cancelAnimationFrame(requestID);
  };
}
