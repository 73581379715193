import * as M from 'types/serverModels';

import { makeService } from '../../utils';

type InputData = {
  embed_code: string;
};

type OutputData = {
  video: M.VideoFromHosting;
};

export const post = makeService<InputData, OutputData, OutputData>(
  () => 'data/video/',
  'api-v2-post',
  false,
  output => output,
  {
    contentType: 'application/x-www-form-urlencoded',
  },
);
