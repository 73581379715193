import { Preloader } from 'components';
import { makeFeature } from 'services/Feature';

import i18nData from './i18n.json';
import * as i18nSharedReferences from './i18nSharedReferences';

export const feature = makeFeature({
  name: 'LocationObserverModal',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "location-observer-modal" */
      './Component'
    ),
  Preloader: Preloader.variants.Empty.Component,
  i18nSharedReferences: Object.values(i18nSharedReferences),
  i18nData,
  subfeatures: [],
});

export { hashes } from './constants';
export {
  getCurrentLocationOpenHref,
  useCurrentLocationOpenHref,
  setSubjectURI,
} from './api';

export * as Auth from './variants/Auth';

export const Component = feature.Component;
