import React, { useMemo, useCallback } from 'react';
import { SetOptional } from 'type-fest';

import { Action } from 'components';
import {
  ProjectPageRouteTree,
  getProjectPageRootParams,
} from 'pages/routeTree';
import { I18n, Routing } from 'services';
import * as M from 'types/serverModels';
import { block } from 'utils/classname';

import type * as content from './content';

const b = block('project-view-modal-action');

export type Props = SetOptional<
  Action.Props<content.ProvidedProps>,
  'onClick'
> & {
  projectPageRouteTree: ProjectPageRouteTree;
  projectCode: M.Project['code'] | undefined;
  projectUUID: M.Project['uuid'];
};

function ProjectAction({
  projectPageRouteTree,
  projectCode,
  projectUUID,
  onClick,
  ...actionProps
}: Props) {
  const lang = I18n.activeLangStateUnit.useState();

  const projectOpenRoute = useMemo(() => {
    return projectPageRouteTree.tree.PROJECT.general.getPath({
      routeParams: {
        ...getProjectPageRootParams(projectPageRouteTree),
        LANG: lang,
        PROJECT: projectCode || projectUUID,
      },
    });
  }, [projectPageRouteTree, projectCode, projectUUID, lang]);

  const handleClick = useCallback(() => {
    const history = Routing.getHistory();

    history?.push(projectOpenRoute);

    onClick?.();
  }, [projectOpenRoute, onClick]);

  return (
    <Action.Component
      className={b({}, [actionProps.className])}
      onClick={handleClick}
      {...actionProps}
    />
  );
}

export * as content from './content';

export const Component = React.memo(ProjectAction) as typeof ProjectAction;
