import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  data: {
    to: string;
    body: string;
  };
};

type APIOutputData = {
  uuid: M.UUID;
  cdate: string;
  from: string;
  to: string;
  body: string;
  read: boolean;
  notified?: boolean;
};

function convertPrivateMessage(data: APIOutputData): M.LoadedMessage {
  return { ...data, cdate: new Date(data.cdate), kind: 'loaded' };
}

export const privateMessageWrite = makeService<
  InputData,
  APIOutputData,
  M.LoadedMessage
>('private_message_write', 'post-json', true, convertPrivateMessage);
