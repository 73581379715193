import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  uuid: M.Course['uuid'];
};

type OutputData = number;

export const courseLike = makeService<InputData, OutputData>(
  'course_like',
  'post',
);
