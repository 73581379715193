import { MeschSchool } from 'types/serverModels';

import { makeService } from '../../utils';

type InputData = {};

type APIOutputData = {
  school: MeschSchool[];
};

export const get = makeService<InputData, APIOutputData>(
  ({ ticket }, convertToSearchParams) =>
    `mesch/school/?${convertToSearchParams({ ticket })}`,
  'api-v2-get',
);
