import React from 'react';

import { block } from 'utils/classname';

import './style.scss';

const b = block('rounded-list-item-mark');

export type Variant = 'contained' | 'outlined';

type Props = {
  variant: Variant;
  color?: 'accent-2' | 'error';
  className?: string;
};

function RoundedListItemMark({
  variant,
  color = 'accent-2',
  className,
  children,
}: React.PropsWithChildren<Props>) {
  return (
    <div className={b({ variant, color }, [className])}>
      <div className={b('content')}>{children}</div>
    </div>
  );
}

export const Component = React.memo(
  RoundedListItemMark,
) as typeof RoundedListItemMark;
