import { makeFeature } from 'services/Feature';
import { makeFormElementState } from 'utils/FormState';

import type { ComponentType } from './Component';
import i18nData from './i18n.json';
import { OptionData } from './type';

export const feature = makeFeature({
  name: 'StreamSelect',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "stream-select" */
      './Component'
    ),
  i18nSharedReferences: null,
  i18nData,
  subfeatures: [],
});

export type { Props } from './Component';

export * as OptionExtension from './OptionExtension';

export function makeFieldState<T extends OptionData>(
  initialState: T | null = null,
) {
  return makeFormElementState<T | null>(initialState);
}

export const Component = feature.Component as ComponentType;
