import React from 'react';

import { Checkbox } from 'components';
import { I18n } from 'services';
import { block } from 'utils/classname';

import i18nData from '../../../../../i18n.json';
import { HeaderExtensionProps } from '../../../types';
import { StateInstance } from '../types';
import './style.scss';

const b = block('quiz-text-question-header-extension');

const Label = Checkbox.makeLabel({
  size: 'text-14',
  useLabel: () =>
    I18n.useText(i18nData).questions.list.text.multilineCheckboxLabel,
});

function HeaderExtension({ instance }: HeaderExtensionProps<StateInstance>) {
  return (
    <div className={b()}>
      <Checkbox.Component
        Label={Label}
        formElementState={instance.isMultiline}
      />
    </div>
  );
}

export const Component = React.memo(HeaderExtension) as typeof HeaderExtension;
