import { makeFeature } from 'services/Feature';

import i18nData from './i18n.json';
import * as subfeatures from './subfeatures';

export const feature = makeFeature({
  name: 'ProjectBookPageNavigation',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "project-book-page-navigation" */
      './Component'
    ),
  i18nSharedReferences: null,
  i18nData,
  subfeatures: Object.values(subfeatures).map(x => x.feature),
});

export type { Props } from './Component';

export * from './api';

export type { Tab, TabState } from './types';

export { i18nLinkSharedReferences as i18nNavigationLinkSharedReferences } from './i18nLinkSharedReferences';

export const Component = feature.Component;
