import React from 'react';

import { routeTree } from 'pages/routeTree';
import { Page } from 'services';
import { block } from 'utils/classname';

import * as features from './features';
import './style.scss';

const b = block('quiz-view-page');

function Quiz() {
  const params = routeTree.LANG.quiz.view.QUIZ_UUID.useRouteParams();

  if (!params) {
    return null;
  }

  return (
    <div className={b()}>
      <features.Quiz.Component objectUUID={params.quiz_uuid} kind="quiz" />
    </div>
  );
}

export const Component = Page.makePage({
  path: routeTree.LANG.quiz.view.QUIZ_UUID.getPath(),
  scrollTo: 'top-on-mount',
  Component: React.memo(Quiz),
  features: Object.values(features).map(x => x.feature),
});
