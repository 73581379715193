import * as M from 'types/serverModels';

import { makeService } from '../utils';

type PathInputData = {
  code: M.LandingPage['code'];
};

type QueryInputData = {};

type InputData = PathInputData & QueryInputData;

type APIOutputData = {
  landing_page?: M.LandingPage;
  users?: M.UserXShort[];
};

type OutputData = {
  landingPage?: M.LandingPage;
  users: M.UserXShortRecord;
};

function extractResponse(data: APIOutputData): OutputData {
  return {
    landingPage: data.landing_page,
    users:
      data?.users?.reduce<M.UserXShortRecord>(
        (acc, x) =>
          x.login !== undefined ? { ...acc, [x.login]: x } : { ...acc },
        {},
      ) || {},
  };
}

export const get = makeService<InputData, APIOutputData, OutputData>(
  ({ ticket, code }, convertToSearchParams) =>
    `landing_page/${code}/?${convertToSearchParams({ ticket })}`,
  'api-v2-get',
  false,
  extractResponse,
);
