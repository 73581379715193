import { Location } from 'history';
import { match } from 'path-to-regexp';
import { useEffect, useState } from 'react';

import {
  getCurrentLocation,
  getHistory,
  subscribeLocationChange,
} from 'services/Routing';
import { searchParamsProperty } from 'services/Routing/constants';
import { parseSearchParams } from 'services/Routing/parseSearchParams';
import {
  getNewState,
  PrimaryStateUnit,
  SetStateValue,
  StateSubscriber,
  StateSubscriberCallback,
} from 'utils/State';

export function makeSearchParamsUnit<T>(
  pathPattern: string,
): PrimaryStateUnit<T | null> {
  const routeMatches = (location: Location) => {
    const matcher = match(pathPattern);

    return matcher(location.pathname) !== false;
  };

  const initialState = null;

  let subscribers: Array<StateSubscriber<T | null>> = [];

  const subscribe = (
    callback: StateSubscriberCallback<T | null>,
    name: string = 'not-specified',
  ) => {
    const subscriber: StateSubscriber<T | null> = { callback, name };

    subscribers.push(subscriber);

    return () => {
      subscribers = subscribers.filter(x => x !== subscriber);
    };
  };

  let prevSearch: string = '';
  let params: T | null = null;

  subscribeLocationChange(location => {
    if (!routeMatches(location) && params !== initialState) {
      params = initialState;
      subscribers.forEach(subscriber =>
        subscriber.callback(initialState, Symbol('not-implemented') as any),
      );

      prevSearch = '';

      return;
    }

    if (routeMatches(location) && location.search !== prevSearch) {
      params = parseSearchParams<T>(location.search);

      subscribers.forEach(subscriber =>
        subscriber.callback(params, Symbol('not-implemented') as any),
      );

      prevSearch = location.search;
    }
  });

  const getState = () => {
    const location = getCurrentLocation();

    if (location === null) return null;

    params = parseSearchParams<T>(location.search);

    return routeMatches(location) ? params : null;
  };

  return {
    subscribe: ({ callback, name }) => {
      return subscribe(callback, name);
    },
    getState,
    initialState,
    isStateUnit: true,
    kind: 'primary',
    setState: (value: SetStateValue<T | null>) => {
      const currentLocation = getCurrentLocation();
      const history = getHistory();

      if (currentLocation === null) {
        console.warn(
          'requested search params state set access before location initialiation',
        );
        return null;
      }

      if (!routeMatches(currentLocation)) {
        console.warn(`currentLocation isn't matched with ${pathPattern}`);

        return null;
      }

      if (history === null) {
        console.warn(
          'requested search params state set access before history initialiation',
        );
        return null;
      }

      const newState = (() => {
        const newState = getNewState(value, params);

        if (newState === null || typeof newState !== 'object') {
          return null;
        }

        if (Object.keys(newState).length === 0) {
          return null;
        }

        return newState;
      })();
      params = newState;

      if (newState) {
        history.replace(
          `${currentLocation.pathname}?${searchParamsProperty}=${JSON.stringify(
            newState,
          )}${currentLocation.hash}`,
        );
      } else {
        history.replace(`${currentLocation.pathname}${currentLocation.hash}`);
      }
    },
    useState: () => {
      const [searchParams, setSearchParams] = useState(getState);

      useEffect(() => subscribe(setSearchParams), []);

      return searchParams;
    },
    getBridgeUnit: () => {
      throw Error('not-implemented');
    },
    useBridgeUnitMemo: () => {
      throw Error('not-implemented');
    },
    subscribeStart: () => {
      throw new Error('no implemented');
    },
    resetState: () => {
      throw new Error('no implemented');
    },
  };
}
