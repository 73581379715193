import { FilterQuery } from 'mongodb';

import * as M from 'types/serverModels';

import { makeService } from '../../utils';

type PathInputData = {
  uuid: Exclude<M.ProjectBook['uuid'], undefined>;
  set: M.ProjectBookChangeNotificationStatus;
};

type QueryInputData = {
  user?: M.UserXShort['login'];
  filter?: FilterQuery<M.ProjectBookChange>;
  sort?: {
    property: keyof M.ProjectBookChange;
    direction: 'ASC' | 'DESC';
  };
  start?: number;
  limit?: number;
};

type InputData = PathInputData & QueryInputData;

type APIOutputData = {
  project_book_changes?: M.ProjectBookChange[];
  count?: number;
};

type OutputData = {
  projectBookChanges: M.ProjectBookChange[];
  count: number;
};

function extractResponse(data: APIOutputData): OutputData {
  return {
    projectBookChanges: data.project_book_changes || [],
    count: data.count || 0,
  };
}

export const list = makeService<InputData, APIOutputData, OutputData>(
  ({ uuid, set, filter, sort, ...rest }, convertToSearchParams) => {
    return `project_book/${uuid}/notifications/${set}/?${convertToSearchParams({
      ...rest,
      filter: JSON.stringify(filter),
      sort: JSON.stringify(sort),
    })}`;
  },
  'api-v2-get',
  false,
  extractResponse,
);
