import * as M from 'types/serverModels';
import { FormElementState, makeFormElementState } from 'utils/FormState';

import {
  QuestionConstructorWidget,
  SharedInstanceStateConstructor,
} from '../../types';
import * as FormExtension from './FormExtension';
import * as PreviewModeForm from './PreviewModeForm';
import { StateInstance } from './types';

export type { StateInstance };

export const constructorWidget: QuestionConstructorWidget<
  'number',
  StateInstance,
  typeof makeInstance
> = {
  key: 'number',
  icon: 'number',
  makeInstance,
  FormExtension: FormExtension.Component,
  PreviewModeForm: PreviewModeForm.Component,
};

export function makeServerQuestion(
  measurementTypeState: FormElementState<M.UUID | null>,
): Partial<M.NumberQuestion> {
  const measurementType = measurementTypeState.units.value.getState();
  if (measurementType) {
    return {
      type: 'number',
      quantity: measurementType,
    };
  }
  return {
    type: 'number',
  };
}

export function makeInstance(
  makeSharedInstanceState: SharedInstanceStateConstructor,
  measurementType: M.UUID | null = null,
): StateInstance {
  const measurementTypeState = makeFormElementState<M.UUID | null>(
    measurementType,
  );
  return {
    kind: 'number',
    measurementType: measurementTypeState,
    makeServerQuestion: () => makeServerQuestion(measurementTypeState),
    ...makeSharedInstanceState(),
  };
}
