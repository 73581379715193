import React, { useMemo, useCallback } from 'react';

import { Switch } from 'components';
import { I18n } from 'services';

import i18nData from '../../../../../../i18n.json';
import {
  ImageElementState,
  TextElementState,
  StateInstance,
} from '../../types';
import { makeMatchingLeftColumnElement } from '../../utils';
import { Section } from '../components';
import * as elements from '../elements';

type Props = {
  instance: StateInstance;
};

const useTitle = () =>
  I18n.useText(i18nData).questions.list.match.leftColumn.title;

const IsImageSwitchLabel = Switch.makeLabel({
  size: 'text-16',
  color: 'fg-1',
  useLabel: () =>
    I18n.useText(i18nData).questions.list.match.leftColumn.isImageSwitchLabel,
});

function LeftColumnList({ instance }: Props) {
  const HeaderExtension = useMemo(
    () =>
      React.memo(() => (
        <Switch.Component
          Label={IsImageSwitchLabel}
          formElementState={instance.leftColumn.isImage}
        />
      )),
    [instance],
  );

  const Content = useMemo(
    () =>
      React.memo(() => {
        const isImage = instance.leftColumn.isImage.units.value.useState();

        const handleElementAdd = useCallback(
          (element: TextElementState | ImageElementState) => {
            instance.matchingsUnit.setState(prev => {
              return {
                ...prev,
                [element.uuid]: makeMatchingLeftColumnElement({
                  rightColumn: instance.rightColumn,
                  initState: () => false,
                }),
              };
            });
          },
          [],
        );

        const handleElementRemove = useCallback(
          (element: TextElementState | ImageElementState) => {
            instance.matchingsUnit.setState(prev => {
              const { [element.uuid]: deleted, ...rest } = prev;

              return rest;
            });
          },
          [],
        );

        return (
          <>
            {isImage ? (
              <elements.Image.Component
                elementsUnit={instance.leftColumn.imageElementsUnit}
                sectionStateUnit={instance.leftColumnSectionState}
                onAdd={handleElementAdd}
                onRemove={handleElementRemove}
              />
            ) : (
              <elements.Text.Component
                elementsUnit={instance.leftColumn.textElementsUnit}
                sectionStateUnit={instance.leftColumnSectionState}
                onAdd={handleElementAdd}
                onRemove={handleElementRemove}
              />
            )}
          </>
        );
      }),
    [instance],
  );

  return (
    <Section.Component
      Content={Content}
      HeaderExtension={HeaderExtension}
      useTitle={useTitle}
    />
  );
}

export const Component = React.memo(LeftColumnList);
