import React from 'react';

import { VideoQuestion } from 'features/project/Constructor/subfeatures';
import { VideoFromHosting } from 'types/serverModels';
import { useFormElementState } from 'utils/FormState';

import { PreviewModeFormProps } from '../../../types';
import { StateInstance } from '../types';

function PreviewModeForm({}: PreviewModeFormProps<StateInstance>) {
  return (
    <VideoQuestion.Component
      formElementState={useFormElementState<VideoFromHosting | null>(null)}
    />
  );
}

export const Component = React.memo(PreviewModeForm) as typeof PreviewModeForm;
