import { useMemo } from 'react';

import { I18n } from 'services';
import * as M from 'types/serverModels';

type References = Record<
  M.ProjectBookStageStatus | 'null',
  I18n.EntryReference
>;

export function useStatusText(references: References) {
  const assignedText = I18n.useReference(references.assigned);
  const onprogressText = I18n.useReference(references.onprogress);
  const assessmentText = I18n.useReference(references.assessment);
  const finishedText = I18n.useReference(references.finished);
  const nullText = I18n.useReference(references.null);

  const text = useMemo(() => {
    return {
      assigned: assignedText,
      onprogress: onprogressText,
      assessment: assessmentText,
      finished: finishedText,
      null: nullText,
    };
  }, [assignedText, onprogressText, assessmentText, finishedText, nullText]);

  return text;
}
