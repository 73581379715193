import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  data: (Pick<M.User, 'name' | 'login' | 'email' | 'role'> & {
    uuid: M.UUID;
    password: string;
    group: M.Group['uuid'];
  })[];
};

type APIOutputData = (Pick<M.User, 'name' | 'login' | 'email' | 'role'> & {
  uuid: M.UUID;
  password: string;
} & (
    | { status: 1 }
    | {
        status: 0;
        error: Partial<
          Record<
            'login',
            ('incorrectLogin' | 'occupiedLogin' | 'notUniqueLogin')[]
          > &
            Record<
              'email',
              ('occupiedEmail' | 'notUniqueEmail' | 'badEmailFormat')[]
            > &
            Record<'password', 'badPassword'[]>
        >;
      }
  ))[];

type OutputData = Record<APIOutputData[number]['uuid'], APIOutputData[number]>;

function extractResponse(data: APIOutputData): OutputData {
  return data.reduce<OutputData>((acc, x) => ({ ...acc, [x.uuid]: x }), {});
}

export const userRegisterBatch = makeService<
  InputData,
  APIOutputData,
  OutputData
>('user_register_batch', 'post-json', true, extractResponse);
