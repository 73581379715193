import { I18n } from 'services';
import * as M from 'types/serverModels';
import { makeFormElementState } from 'utils/FormState';

type Options = {
  group?: M.Group;
  t?: ReturnType<typeof I18n.useGetMultilingTranslation>;
};

export function makeFieldsetState({ group, t }: Options) {
  if (!group) {
    const locationFieldsetDefaultElementState = makeFormElementState([
      0, 0,
    ] as M.Location);
    locationFieldsetDefaultElementState.units.disabled.setState(true);

    return {
      name: makeFormElementState(''),
      isPublic: makeFormElementState(false),
      description: makeFormElementState(''),
      motto: makeFormElementState(''),
      location: locationFieldsetDefaultElementState,
      avatar: makeFormElementState<M.ImageInfo>({
        uuid: '',
        original: '',
        thumb: [{ code: 'thumb', url: '' }],
      }),
    };
  }

  const { name, is_private, description, motto, location, avatar } = group;

  const locationFieldsetElementState = makeFormElementState([
    location?.longitude || 0,
    location?.latitude || 0,
  ] as M.Location);
  locationFieldsetElementState.units.disabled.setState(true);

  return {
    name: makeFormElementState(name || ''),
    isPublic: makeFormElementState(!is_private || false),
    description: makeFormElementState(description || ''),
    motto: makeFormElementState(motto || ''),
    location: locationFieldsetElementState,
    avatar: makeFormElementState<M.ImageInfo>({
      uuid: '',
      original: avatar || '',
      thumb: [{ code: 'thumb', url: avatar || '' }],
    }),
  };
}
