import { SetRequired } from 'type-fest';

import * as M from 'types/serverModels';

import * as object from './object';

export function convertToStreamTrack(
  data: SetRequired<M.CourseStreamServiceTrack, 'lang'>,
): M.StreamTrack {
  return {
    uuid: data.uuid,
    ref_uuid: data.ref_uuid,
    lang: data.lang,
    objects: data.objects.map(x => object.convertToStreamTrackObject(x)),
  };
}
