import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from 'react';

import { PrimaryStateUnit } from 'utils/State';
import { block, classnames } from 'utils/classname';

import { ExpandTriggerAreaProps, HeaderProps } from '../types';
import './style.scss';

export * as SimpleLabeledHeader from '../SimpleLabeledHeader';

const b = block('expansion-panel-with-animation');

type Props = {
  Header: React.FC<HeaderProps>;
  Content: React.FC;
  visibleUnit: PrimaryStateUnit<boolean>;
  className?: string;
};

function ExpansionPanel({ Content, Header, visibleUnit, className }: Props) {
  const contentRef = useRef<HTMLDivElement>(null);
  const contentContainerRef = useRef<HTMLDivElement>(null);

  const handleContentTransitionEnd = useCallback(
    (event: React.TransitionEvent<HTMLDivElement>) => {
      if (event.propertyName === 'height') {
        const contentContainerNode = contentContainerRef.current;
        if (contentContainerNode) {
          if (contentContainerNode.getBoundingClientRect().height !== 0) {
            contentContainerNode.style.height = '';
          }
        }
      }
    },
    [],
  );

  useLayoutEffect(() => {
    const visible = visibleUnit.getState();
    if (!visible) {
      const contentContainerNode = contentContainerRef.current;

      if (contentContainerNode) {
        contentContainerNode.style.height = '0px';
      }
    }
  }, [visibleUnit]);

  useEffect(() => {
    return visibleUnit.subscribe({
      name: 'visible-to-content-height',
      callback: visible => {
        const contentNode = contentRef.current;
        const contentContainerNode = contentContainerRef.current;
        if (contentNode && contentContainerNode) {
          const contentHeight = contentNode.getBoundingClientRect().height;
          if (visible) {
            contentContainerNode.style.opacity = '1';
            contentContainerNode.style.height = `${contentHeight}px`;
          } else {
            contentContainerNode.style.opacity = '0';
            contentContainerNode.style.height = `${contentHeight}px`;
            setTimeout(() => (contentContainerNode.style.height = '0px'), 0);
          }
        }
      },
    });
  }, [visibleUnit]);

  const handleExpandTriggerAreaClick = useCallback(() => {
    visibleUnit.setState(prev => !prev);
  }, [visibleUnit]);

  const ExpandTriggerArea = useMemo(() => {
    return ({
      children,
      fullWidth,
    }: React.PropsWithChildren<ExpandTriggerAreaProps>) => {
      return (
        <div
          className={b('expand-trigger-area', { 'full-width': fullWidth })}
          onClick={handleExpandTriggerAreaClick}
        >
          {children}
        </div>
      );
    };
  }, [handleExpandTriggerAreaClick]);

  return (
    <div className={classnames(b(), className)}>
      <Header
        panelExpandedUnit={visibleUnit}
        ExpandTriggerArea={ExpandTriggerArea}
      />
      <div
        className={b('content-container')}
        ref={contentContainerRef}
        onTransitionEnd={handleContentTransitionEnd}
      >
        <div className={b('content')} ref={contentRef}>
          <Content />
        </div>
      </div>
    </div>
  );
}

export const Component = React.memo(ExpansionPanel) as typeof ExpansionPanel;
