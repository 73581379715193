import { Language } from 'types';

const dict: Record<
  Extract<Language, 'ru'>,
  Record<string, Record<string, string>>
> = {
  ru: {
    участник: {
      nom_sing: 'участник',
      nom_plur: 'участники',
      gen_sing: 'участника',
      gen_plur: 'участников',
      acc_sing: 'участника',
      acc_plur: 'участников',
      dat_sing: 'участнику',
      dat_plur: 'участникам',
      ins_sing: 'участником',
      ins_plur: 'участниками',
      abl_sing: 'участнике',
      abl_plur: 'участниках',
    },
    человек: {
      nom_sing: 'человек',
      nom_plur: 'человеки',
      gen_sing: 'человека',
      gen_plur: 'человек',
      acc_sing: 'человека',
      acc_plur: 'человек',
      dat_sing: 'человеку',
      dat_plur: 'человекам',
      ins_sing: 'человеком',
      ins_plur: 'человеками',
      abl_sing: 'человеке',
      abl_plur: 'человеках',
    },
    год: {
      nom_sing: 'год',
      gen_sing: 'года',
      gen_plur: 'лет',
    },
    месяц: {
      nom_sing: 'месяц',
      nom_plur: 'месяцы',
      gen_sing: 'месяца',
      gen_plur: 'месяцев',
      dat_sing: 'месяцу',
      dat_plur: 'месяцам',
      acc_sing: 'месяц',
      acc_plur: 'месяцы',
      ins_sing: 'месяцем',
      ins_plur: 'месяцами',
    },
    комментарий: {
      nom_sing: 'комментарий',
      gen_sing: 'комментария',
      gen_plur: 'комментариев',
    },
    проект: {
      nom_sing: 'проект',
      nom_plur: 'проекты',
      gen_sing: 'проекта',
      gen_plur: 'проектов',
      acc_sing: 'проект',
      acc_plur: 'проектов',
      dat_sing: 'проекту',
      dat_plur: 'проектам',
      ins_sing: 'проектом',
      ins_plur: 'проектами',
      abl_sing: 'проекте',
      abl_plur: 'проектах',
    },
    'проектное задание': {
      nom_sing: 'проектное задание',
      nom_plur: 'проектные задания',
      gen_sing: 'проектного задания',
      gen_plur: 'проектных заданий',
      acc_sing: 'проектное задание',
      acc_plur: 'проектные задания',
      dat_sing: 'проектному заданию',
      dat_plur: 'проектным заданиям',
      ins_sing: 'проектным заданием',
      ins_plur: 'проектными заданиями',
      abl_sing: 'проектном задании',
      abl_plur: 'проектных заданиях',
    },
    группа: {
      nom_sing: 'группа',
      nom_plur: 'группы',
      gen_sing: 'группы',
      gen_plur: 'групп',
      acc_sing: 'проект',
      acc_plur: 'группу',
      dat_sing: 'группы',
      dat_plur: 'группам',
      ins_sing: 'группой',
      ins_plur: 'группами',
      abl_sing: 'группе',
      abl_plur: 'группах',
    },
    курс: {
      nom_sing: 'курс',
      gen_sing: 'курса',
      gen_plur: 'курсов',
      acc_sing: 'курс',
      abl_sing: 'курсе',
      abl_plur: 'курсах',
    },
    материал: {
      nom_sing: 'материал',
      nom_plur: 'материалы',
      gen_sing: 'материала',
      gen_plur: 'материалов',
      acc_sing: 'материал',
      acc_plur: 'материалы',
      dat_sing: 'материалу',
      dat_plur: 'материалам',
      ins_sing: 'материалом',
      ins_plur: 'материалами',
      abl_sing: 'материале',
      abl_plur: 'материалах',
    },
    вывод: {
      nom_sing: 'вывод',
      gen_sing: 'вывода',
      gen_plur: 'выводов',
      acc_sing: 'вывод',
      abl_sing: 'выводе',
      abl_plur: 'выводах',
    },
    идея: {
      nom_sing: 'идея',
      gen_sing: 'идеи',
      gen_plur: 'идей',
      abl_sing: 'идее',
      abl_plur: 'идеях',
    },
    шаг: {
      nom_sing: 'шаг',
      gen_sing: 'шага',
      gen_plur: 'шагов',
    },
    оставлен: {
      sing_m: 'оставлен',
      plur: 'оставлено',
    },
    анкета: {
      nom_sing: 'анкета',
      acc_sing: 'анкету',
      gen_sing: 'анкеты',
      gen_plur: 'анкет',
    },
    картинка: {
      nom_sing: 'картинка',
      gen_sing: 'картинки',
      gen_plur: 'картинок',
    },
    день: {
      nom_sing: 'день',
      gen_sing: 'дня',
      gen_plur: 'дней',
    },
    рубль: {
      nom_sing: 'рубль',
      gen_sing: 'рубля',
      gen_plur: 'рублей',
    },
    доллар: {
      nom_sing: 'доллар',
      gen_sing: 'доллара',
      gen_plur: 'долларов',
    },
    награда: {
      ins_sing: 'наградой',
      ins_plur: 'наградами',
      dat_sing: 'награде',
      dat_plur: 'наградам',
      nom_sing: 'награда',
      nom_plur: 'награды',
      gen_sing: 'награды',
      gen_plur: 'наград',
      acc_sing: 'награду',
    },
    результат: {
      ins_sing: 'результатом',
      ins_plur: 'результатами',
      dat_sing: 'результату',
      dat_plur: 'результатам',
      nom_sing: 'результат',
      nom_plur: 'результаты',
      gen_sing: 'результата',
      gen_plur: 'результатов',
    },
    очко: {
      nom_sing: 'очко',
      nom_plur: 'очки',
      gen_sing: 'очка',
      gen_plur: 'очков',
      dat_sing: 'очку',
      dat_plur: 'очкам',
      acc_sing: 'очко',
      acc_plur: 'очки',
      ins_sing: 'очком',
      ins_plur: 'очками',
      abl_sing: 'очке',
      abl_plur: 'очках',
    },
    чат: {
      dat_sing: 'чату',
    },
    уведомление: {
      nom_sing: 'уведомление',
      nom_plur: 'уведомления',
      gen_sing: 'уведомления',
      gen_plur: 'уведомлений',
      dat_sing: 'уведомлению',
      dat_plur: 'уведомлениям',
      acc_sing: 'уведомление',
      acc_plur: 'уведомления',
      ins_sing: 'уведомлением',
      ins_plur: 'уведомлениями',
      abl_sing: 'уведомлении',
      abl_plur: 'уведомлениях',
    },
    сообщение: {
      nom_sing: 'сообщение',
      nom_plur: 'сообщения',
      gen_sing: 'сообщения',
      gen_plur: 'сообщений',
      dat_sing: 'сообщению',
      dat_plur: 'сообщениям',
      acc_sing: 'сообщение',
      acc_plur: 'сообщения',
      ins_sing: 'сообщением',
      ins_plur: 'сообщениями',
      abl_sing: 'сообщении',
      abl_plur: 'сообщениях',
    },
    новое: {
      nom_sing: 'новое',
      nom_plur: 'новые',
      gen_sing: 'нового',
      gen_plur: 'новых',
      dat_sing: 'новому',
      dat_plur: 'новым',
      acc_sing: 'новое',
      acc_plur: 'новых',
      ins_sing: 'новым',
      ins_plur: 'новыми',
      abl_sing: 'новом',
      abl_plur: 'новых',
    },
  },
};

const grammar = {
  ru: {
    _incl: function (word: string, arg: number, incl: string): string {
      if (!dict.ru[word]) return word;
      return arg % 100 !== 11 && arg % 10 === 1
        ? dict.ru[word][incl + '_sing']
        : dict.ru[word][incl + '_plur'];
    },
    with: function (word: string, arg: number): string {
      return arg === 0
        ? dict.ru[word]['gen_plur']
        : this._incl(word, arg, 'ins');
    },
    in: function (word: string, arg: number): string {
      return arg === 0
        ? dict.ru[word]['gen_plur']
        : this._incl(word, arg, 'abl');
    },
    of: function (word: string, arg: number): string {
      return arg === 0
        ? dict.ru[word]['gen_plur']
        : this._incl(word, arg, 'gen');
    },
    likes: function (word: string, arg: number): string {
      return arg === 0
        ? dict.ru[word]['gen_plur']
        : this._incl(word, arg, 'dat');
    },
    number: function (word: string, arg: number): string {
      if (!dict.ru[word]) return word;
      const cases = [
        'gen_plur',
        'nom_sing',
        'gen_sing',
        'gen_sing',
        'gen_sing',
        'gen_plur',
      ];
      return dict.ru[word][
        arg % 100 > 4 && arg % 100 < 20
          ? 'gen_plur'
          : cases[arg % 10 < 5 ? arg % 10 : 5]
      ];
    },
    obj: function (word: string, arg: number): string {
      if (!dict.ru[word]) return word;
      const cases = [
        'acc_plur',
        'acc_sing',
        'acc_plur',
        'acc_plur',
        'acc_plur',
        'acc_plur',
      ];
      return dict.ru[word][
        arg % 100 > 4 && arg % 100 < 20
          ? 'gen_plur'
          : cases[arg % 10 < 5 ? arg % 10 : 5]
      ];
    },
  },
  en: {
    number: function (word: string, arg: number): string {
      return arg === 1 ? word : word + 's';
    },
    verb: function (word: string, arg: number): string {
      return arg === 1 ? word : word + 's';
    },
  },
  es: {
    number: function (word: string, arg: number): string {
      return arg === 1 ? word : word + 's';
    },
    verb: function (word: string, arg: number): string {
      return arg === 1 ? word : word + 's';
    },
  },
};

export function makePhrase(
  phrase: string,
  lang: Language,
  args: { [i: string]: any } = {},
): string {
  let match: RegExpExecArray | null;

  while ((match = /\{\$([a-z_-]+)\}/.exec(phrase))) {
    phrase = phrase.replace(match[0], args[match[1]]);
  }

  while ((match = /\{([^$|]+)\|([^(]+)\(\$([a-z_-]+)\)\}/.exec(phrase))) {
    const key = match[2] as keyof (typeof grammar)[typeof lang];
    if (grammar[lang][key] && typeof args[match[3]] != 'undefined') {
      phrase = phrase.replace(
        match[0],
        grammar[lang][key](match[1], args[match[3]]),
      );
    } else {
      phrase = phrase.replace(match[0], match[1]);
    }
  }

  return phrase;
}
