import * as M from 'types/serverModels';

import { ProjectBookUser, ShadowUserRole } from '../types';

type Options = {
  user: M.User;
  supervisors: M.ProjectBook['supervisors'];
  isIndividual: M.ProjectBook['is_individual'];
  projectBookUser: ProjectBookUser;
  projectBookShadowUserRole: ShadowUserRole;
  users: M.UserXShortRecord;
  userToCheck: NonNullable<M.UserXShort['login']>;
};

export function isUserToDisplay({
  user,
  supervisors,
  isIndividual,
  projectBookUser,
  projectBookShadowUserRole,
  users,
  userToCheck,
}: Options) {
  if (!users[userToCheck]) {
    return false;
  }

  if (projectBookShadowUserRole === 'supervisor') {
    return true;
  }

  if (supervisors?.includes(userToCheck)) {
    return true;
  }

  if (isIndividual) {
    return userToCheck === user.login || userToCheck === projectBookUser;
  }

  return true;
}
