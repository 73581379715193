import { serverProjectDataUnit } from 'features/project/Constructor/units';
import { PROJECT_STATUS } from 'shared/constants';
import { useHasUserExclusiveRight } from 'utils/business';

type HasAccess = {
  isEditAccess: true;
  errorCode: null;
};

type HasNoAccess = {
  isEditAccess: false;
  errorCode: 'published' | 'moderation';
};

export function useIsEditAccess(): HasAccess | HasNoAccess {
  const serverProject = serverProjectDataUnit.useState();

  const hasUserExclusiveRight = useHasUserExclusiveRight();

  if (serverProject === null || hasUserExclusiveRight) {
    return { isEditAccess: true, errorCode: null };
  }

  switch (serverProject.status) {
    case PROJECT_STATUS.published:
      return { isEditAccess: false, errorCode: 'published' };
    case PROJECT_STATUS.reviewing:
      return { isEditAccess: false, errorCode: 'moderation' };
    default:
      return { isEditAccess: true, errorCode: null };
  }
}
