import { camelCase } from 'change-case';
import React from 'react';
import { CamelCase } from 'type-fest';

import { Select } from 'components';
import { I18n } from 'services';
import * as widgetIcons from 'shared/images/widgetIcons';
import * as TS from 'types';
import { FormElementState } from 'utils/FormState';
import { block } from 'utils/classname';

import i18nData from '../../i18n.json';
import './style.scss';

const b = block('widget-settings-container-data-settings-chart-type-select');

type ChartType = TS.ChartType | 'stacked-area';

type Props<T extends ChartType> = {
  chartTypeFormElementState: FormElementState<T>;
  options: T[];
};

function Option<T extends ChartType>({ option }: Select.Option.Props<T>) {
  const widgetKey = camelCase(option!) as CamelCase<
    ChartType,
    { preserveConsecutiveUppercase: false }
  >;
  const text =
    I18n.useText(i18nData).dataSettingsLayout.chartTypeSelect.options[
      widgetKey
    ];
  const iconSrc = widgetIcons[widgetKey].smaller.src;

  return (
    <Select.Option.Container.DefaultComponent>
      <img
        src={iconSrc}
        alt="chart-icon"
        className={b('option-icon', { kind: option! })}
      />{' '}
      {text}
    </Select.Option.Container.DefaultComponent>
  );
}

function ActiveOption<T extends ChartType>({
  option,
}: Select.ActiveOption.Props<T>) {
  const widgetKey = camelCase(option!) as CamelCase<
    ChartType,
    { preserveConsecutiveUppercase: false }
  >;
  const text =
    I18n.useText(i18nData).dataSettingsLayout.chartTypeSelect.options[
      widgetKey
    ];
  const Icon = widgetIcons[widgetKey].smaller.InlineComponent;

  return (
    <Select.ActiveOption.Container.DefaultComponent>
      <div className={b('active-option-content')}>
        <Icon className={b('active-option-icon', { kind: option! })} /> {text}
      </div>
    </Select.ActiveOption.Container.DefaultComponent>
  );
}

function ChartTypeSelect<T extends ChartType>({
  chartTypeFormElementState,
  options,
}: Props<T>) {
  return (
    <Select.Component
      ActiveOption={ActiveOption}
      Option={Option}
      activeOptionState={chartTypeFormElementState}
      options={options}
    />
  );
}

export const Component = React.memo(ChartTypeSelect) as typeof ChartTypeSelect;
