type Options = {
  codes?: Set<'Enter' | 'Space'>;
};

export function emulateClick(
  event: Pick<KeyboardEvent, 'target' | 'repeat' | 'code' | 'preventDefault'>,
  { codes = new Set(['Enter'] as const) }: Options = {},
): void {
  const element = event.target;

  const shouldClick =
    !event.repeat && Array.from<string>(codes).includes(event.code);

  if (element instanceof HTMLElement && shouldClick) {
    event.preventDefault();
    element.click();
  }
}
