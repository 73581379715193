import { useEffect } from 'react';

import * as M from 'types/serverModels';
import { makeDerivedUnit, PrimaryStateUnit } from 'utils/State';

import * as services from '../services';
import { CallState } from '../types';

export const themeRefsCallStateUnit =
  services.refLoad.makeCallStateUnit() as PrimaryStateUnit<
    CallState<M.ThemeReferenceBook[]>
  >;

const themesCallStateUnit = makeDerivedUnit(themeRefsCallStateUnit).getUnit<
  CallState<Record<M.ThemeReferenceBook['uuid'], M.ThemeReferenceBook>>
>(themeRefsCallState => {
  if (themeRefsCallState.kind === 'successful') {
    const data = themeRefsCallState.data.reduce<
      Record<M.ThemeReferenceBook['uuid'], M.ThemeReferenceBook>
    >((acc, x) => {
      return {
        ...acc,
        [x.uuid]: x,
      };
    }, {});

    return {
      kind: 'successful',
      data,
    };
  }

  return themeRefsCallState;
});

const call = services.refLoad.makeCall(
  themeRefsCallStateUnit as PrimaryStateUnit<CallState<M.ReferenceBook[]>>,
);

let wasCalled = false;

export const callRefService = () => {
  if (!wasCalled) {
    wasCalled = true;

    call({ ref: 'theme' });
  }
};

export function useThemeRefsCallEffect() {
  const callState = themeRefsCallStateUnit.useState();

  useEffect(() => {
    callRefService();
  }, [callState.kind]);
}

export function useCallState() {
  const callState = themesCallStateUnit.useState();

  useThemeRefsCallEffect();

  return callState;
}

export function readSubjectsCallState(
  themesCallState: CallState<Record<string, M.ThemeReferenceBook>>,
  subjects: M.UUID[],
): CallState<M.ThemeReferenceBook[]> {
  if (themesCallState.kind !== 'successful') {
    return { ...themesCallState };
  }

  return {
    kind: 'successful',
    data: subjects.reduce<M.ThemeReferenceBook[]>((acc, uuid) => {
      const theme = themesCallState.data[uuid];

      if (theme === undefined) {
        return acc;
      }

      return [...acc, theme];
    }, []),
  };
}
