import type { Location } from 'history';
import { match } from 'path-to-regexp';
import { useState, useEffect } from 'react';

import {
  getHistory,
  getCurrentLocation,
  subscribeLocationChange,
} from 'services/Routing';
import {
  PrimaryStateUnit,
  SetStateValue,
  StateSubscriber,
  StateSubscriberCallback,
  getNewState,
} from 'utils/State';

export function makeHashParamUnit<T extends Location['hash']>(
  pathPattern: string,
): PrimaryStateUnit<T | ''> {
  const routeMatches = (location: Location) =>
    match(pathPattern)(location.pathname) !== false;

  const initialState = '';

  const getState = (): T | '' => {
    const location = getCurrentLocation();

    if (location === null) return '';

    return routeMatches(location) ? (location.hash as T) : '';
  };

  const setState = (value: SetStateValue<T | ''>) => {
    const currentLocation = getCurrentLocation();
    const history = getHistory();

    if (currentLocation === null) {
      console.warn(
        'requested hash param state set access before location initialiation',
      );

      return;
    }

    if (!routeMatches(currentLocation)) {
      console.warn(`currentLocation isn't matched with ${pathPattern}`);

      return null;
    }

    if (history === null) {
      console.warn(
        'requested hash param state set access before history initialiation',
      );

      return;
    }

    const newState = getNewState(value, hashParam);
    hashParam = newState;

    if (newState) {
      history.replace(
        `${currentLocation.pathname}${currentLocation.search}${newState}`,
      );
    } else {
      history.replace(`${currentLocation.pathname}${currentLocation.search}`);
    }

    apiSubscribers.forEach(subscriber =>
      subscriber.callback(newState as T, Symbol('not-implemented') as any),
    );
  };

  let apiSubscribers: Array<StateSubscriber<T | ''>> = [];

  const subscribeAPI = (subscriber: StateSubscriber<T | ''>) => {
    apiSubscribers.push(subscriber);

    return () => {
      apiSubscribers = apiSubscribers.filter(x => x !== subscriber);
    };
  };

  let subscribers: Array<StateSubscriber<T | ''>> = [];

  const subscribe = (
    callback: StateSubscriberCallback<T | ''>,
    name: string = 'not-specified',
  ) => {
    const subscriber: StateSubscriber<T | ''> = { callback, name };

    subscribers.push(subscriber);

    return () => {
      subscribers = subscribers.filter(x => x !== subscriber);
    };
  };

  let prevHashParam: string = '';
  let hashParam: T | '' = '';

  subscribeLocationChange(location => {
    if (!routeMatches(location) && hashParam !== initialState) {
      hashParam = initialState;
      subscribers.forEach(subscriber =>
        subscriber.callback(initialState, Symbol('not-implemented') as any),
      );

      prevHashParam = initialState;

      return;
    }

    if (routeMatches(location) && location.hash !== prevHashParam) {
      hashParam = location.hash as T;
      subscribers.forEach(subscriber =>
        subscriber.callback(
          location.hash as T,
          Symbol('not-implemented') as any,
        ),
      );

      prevHashParam = location.hash;
    }
  });

  return {
    kind: 'primary',
    isStateUnit: true,
    initialState,
    useState: () => {
      const [hashParam, setHashParam] = useState(getState);

      useEffect(() => subscribe(setHashParam), []);

      return hashParam;
    },
    setState,
    getState,
    getBridgeUnit: () => {
      throw Error('not-implemented');
    },
    useBridgeUnitMemo: () => {
      throw Error('not-implemented');
    },
    resetState: () => setState(initialState),
    subscribe: subscribeAPI,
    subscribeStart: () => {
      throw Error('not-implemented');
    },
  };
}
