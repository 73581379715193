import React from 'react';

import { Link } from 'components';
import { I18n } from 'services';
import { block } from 'utils/classname';

import i18nData from '../../i18n.json';
import { isDownloadable } from '../../isDownloadable';
import { ProvidedProps } from '../types';

const b = block('unit-action-link-content');

type Props = ProvidedProps;

function LinkContent({ unit }: Props) {
  const text = I18n.useText(i18nData);

  return (
    <Link.Component className={b()} color="accent-2" size="m">
      {isDownloadable(unit) ? text.downloadLabel : text.openLabel}
    </Link.Component>
  );
}

export const Component = React.memo(LinkContent) as typeof LinkContent;
