import React from 'react';

import i18nData from 'features/project/Constructor/i18n.json';
import { ConstructorStepSection } from 'features/project/Constructor/subfeatures';
import { serverProjectDataUnit } from 'features/project/Constructor/units';
import { I18n } from 'services';

import * as AuthorsList from './AuthorsList';
import * as SupervisorsList from './SupervisorsList';
import * as modals from './shared/LeadershipList/modals';

type Props = {};

function Leadership({}: Props) {
  const text = I18n.useText(i18nData).steps.access.sections.leadership;

  const serverProject = serverProjectDataUnit.useState();

  return (
    <ConstructorStepSection.Component title={text.title}>
      <AuthorsList.Component />
      {serverProject?.status === 4 && <SupervisorsList.Component />}
      <modals.CantInvite.Component />
    </ConstructorStepSection.Component>
  );
}

export const Component = React.memo(Leadership);
