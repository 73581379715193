import { makeFeature } from 'services/Feature';

import i18nData from './i18n.json';
import * as i18nSharedReferences from './i18nSharedReferences';
import * as subfeatures from './subfeatures';

export const feature = makeFeature({
  name: 'UnitMediaLibrary',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "unit-media-library" */
      './Component'
    ),
  i18nSharedReferences: Object.values(
    (() => {
      const { unitTypeReferences } = i18nSharedReferences;

      return [...Object.values(unitTypeReferences)];
    })(),
  ),
  i18nData,
  subfeatures: Object.values(subfeatures).map(x => x.feature),
});

export const Component = feature.Component;

export type { Props } from './Component';
