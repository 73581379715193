import { API } from 'services';
import { Project } from 'types/serverModels';

import { fillConclusions } from './fillConclusions';

export async function loadAndFillConclusions(project: Project) {
  if (project.conclusions) {
    const quiz = await API.services.quiz.get.callPromised({
      uuid: project.conclusions,
    });
    fillConclusions(quiz);
  }
}
