import React from 'react';

import { Preloader, Select } from 'components';
import i18nData from 'features/project/Constructor/i18n.json';
import { API, I18n } from 'services';
import * as M from 'types/serverModels';
import { block } from 'utils/classname';

import { FormExtensionProps } from '../../../types';
import { StateInstance } from '../types';
import './style.scss';

const b = block('sensor-data-question-form-extension');

function OptionContent({ probetypeID }: { probetypeID: M.UUID | null }) {
  const t = I18n.useGetMultilingProjectTranslation();

  const probetypesCallState = API.references.probetypes.useCallState();

  if (probetypeID === null) {
    return null;
  }

  switch (probetypesCallState.kind) {
    case 'pending':
      return <Preloader.Component size="xs" />;
    case 'successful':
      return <>{t(probetypesCallState.data[probetypeID].val)}</>;
    default:
      return null;
  }
}

function Option(props: Select.Option.Props<M.UUID | null>) {
  return (
    <Select.Option.Container.DefaultComponent>
      <OptionContent probetypeID={props.option} />
    </Select.Option.Container.DefaultComponent>
  );
}

function ActiveOption({ option }: Select.Option.Props<M.UUID | null>) {
  const label =
    I18n.useText(i18nData).steps.shared.questions.list.sensorData
      .probetypeSelectPlaceholder;

  return (
    <Select.ActiveOption.Container.DefaultComponent
      className={b('probetype-select-active-option', {
        placeholder: option === null,
      })}
    >
      {option === null ? label : <OptionContent probetypeID={option} />}
    </Select.ActiveOption.Container.DefaultComponent>
  );
}

function FormExtension({
  instance,
  className,
}: FormExtensionProps<StateInstance>) {
  const probetypesCallState = API.references.probetypes.useCallState();

  return (
    <div className={b({}, [className])}>
      {API.renderCallState(probetypesCallState, {
        successful: ({ data }) => (
          <Select.Component<M.UUID | null>
            className={b('probetype-select')}
            ActiveOption={ActiveOption}
            Option={Option}
            options={Object.keys(data)}
            activeOptionState={instance.probetypeID}
            errorRows={1}
          />
        ),
        pending: () => <Preloader.Component size="xs" />,
      })}
    </div>
  );
}

export const Component = React.memo(FormExtension);
