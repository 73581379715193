import React from 'react';

import { block } from 'utils/classname';

import './style.scss';

const b = block('fullscreen-widget-container-secondary-title');

type Props = {
  useText(): React.ReactNode;
};

function SecondaryTitle({ useText }: Props) {
  return <span className={b()}>{useText()}</span>;
}

export const Component = React.memo(SecondaryTitle) as typeof SecondaryTitle;
