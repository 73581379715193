export const chartPalettes = [
  [
    '#2f7ed8',
    '#0d233a',
    '#8bbc21',
    '#910000',
    '#1aadce',
    '#492970',
    '#f28f43',
    '#77a1e5',
    '#c42525',
    '#a6c96a',
  ],
  [
    '#4572A7',
    '#AA4643',
    '#89A54E',
    '#80699B',
    '#3D96AE',
    '#DB843D',
    '#92A8CD',
    '#A47D7C',
    '#B5CA92',
  ],
  [
    '#B58900',
    '#CB4B16',
    '#DC322F',
    '#D33682',
    '#6C71C4',
    '#268BD2',
    '#2AA198',
    '#859900',
  ],
  [
    '#010101',
    '#ED2D2E',
    '#008C47',
    '#1859A9',
    '#F37D22',
    '#662C91',
    '#A11D20',
    '#B33893',
  ],
  [
    '#F6F4BB',
    '#FFE38C',
    '#F89727',
    '#F27120',
    '#E64C24',
    '#FEC146',
    '#A02C20',
    '#F7F8E4',
  ],
  [
    '#a50026',
    '#d73027',
    '#f46d43',
    '#fdae61',
    '#fee08b',
    '#ffffbf',
    '#d9ef8b',
    '#a6d96a',
    '#66bd63',
    '#1a9850',
    '#006837',
  ],
  ['#101b15', '#3a392b', '#7a7d58', '#fff7b6', '#dd9954', '#94583c', '#601600'],
  ['#880000', '#c14700', '#dcad2c', '#7cb09b', '#f1f7d3', '#76897b'],
  [
    '#1a1334',
    '#26294a',
    '#01545a',
    '#017351',
    '#03c383',
    '#aad962',
    '#fbbf45',
    '#ef6a32',
    '#ed0345',
  ],
  [
    '#f4e1f0',
    '#f98a6b',
    '#d31b36',
    '#123e49',
    '#71999b',
    '#f9f7fc',
    '#fed760',
    '#fc648d',
    '#621a32',
    '#778cdf',
    '#a12a5e',
    '#710162',
    '#110141',
  ],
  [
    '#af8700',
    '#d75f00',
    '#d70000',
    '#af005f',
    '#5f5faf',
    '#0087ff',
    '#00afaf',
    '#5f8700',
  ],
  ['#2CB048', '#DC221A', '#2C45B0', '#D0D122', '#808080'],
];
