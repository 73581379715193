import React from 'react';

import * as M from 'types/serverModels';
import { makeFormElementState } from 'utils/FormState';
import { nonEmptyString } from 'utils/validators';

import { questionIsRequired } from '../i18nSharedReferences';

export const makeState = (
  question: M.NumberQuestion,
  initialValue: string = '',
) => {
  const formElementState = makeFormElementState(
    '',
    !question.optional ? [nonEmptyString(questionIsRequired)] : [],
  );
  formElementState.units.value.setState(String(initialValue));

  return formElementState;
};

export const useState = (question: M.NumberQuestion, initialValue?: string) => {
  const [state] = React.useState(() => makeState(question, initialValue));
  return state;
};
