import * as R from 'ramda';

import * as M from 'types/serverModels';
import { makePrimaryUnit } from 'utils/State';

import {
  CachedInstance,
  InstancesCache,
  MaterialConstructorWidget,
  MaterialKey,
  MaterialLanguage,
  MaterialMode,
} from '../types';
import { MaterialStateInstance } from './MaterialStateInstance';
import { makeSharedInstanceStateConstructor } from './makeSharedInstanceState';
import { materialKeyToMaterialConstructorWidget } from './materialKeyToMaterialConstructorWidget';

export function makeInstanceFromServerUnit(
  unit: M.Unit,
): CachedInstance | null {
  const serverIDUnit = makePrimaryUnit<string | null>(unit.uuid);

  const language = unit.lang as MaterialLanguage;

  const materialModeUnit = makePrimaryUnit<MaterialMode>('edit');

  const makeSharedInstanceState = makeSharedInstanceStateConstructor({
    serverIDUnit,
    modeUnit: materialModeUnit,
    title: unit.name,
    explanation: unit.description,
    isForTeacher: unit.tutor || false,
    language,
  });

  const makeInstancesCache = (
    materialKey: MaterialKey,
    stateInstance: MaterialStateInstance,
  ): InstancesCache =>
    Object.entries<MaterialConstructorWidget<any, any, any>>(
      R.omit([materialKey], materialKeyToMaterialConstructorWidget),
    ).reduce<InstancesCache>(
      (acc, [key, constructorWidget]) => ({
        ...acc,
        [key]: constructorWidget.makeInstance(makeSharedInstanceState),
      }),
      { [materialKey]: stateInstance } as InstancesCache,
    );

  const makeInstance = (
    materialKey: MaterialKey,
    activeInstance: MaterialStateInstance,
  ): CachedInstance => {
    const instancesCache = makeInstancesCache(materialKey, activeInstance);
    const activeMaterialKeyUnit = makePrimaryUnit(materialKey);

    if (activeInstance.isValid()) {
      materialModeUnit.setState('preview');
    }

    return {
      activeMaterialKeyUnit,
      mode: materialModeUnit,
      serverIDUnit,
      instancesCache,
      language,
      getStateInstance: () => instancesCache[activeMaterialKeyUnit.getState()],
    };
  };

  const widgets = materialKeyToMaterialConstructorWidget;

  switch (unit.type) {
    case 'print':
      return makeInstance(
        'file',
        widgets.file.makeInstance(makeSharedInstanceState, unit.file?.url),
      );

    case 'web':
      if (unit.url)
        return makeInstance(
          'link',
          widgets.link.makeInstance(makeSharedInstanceState, unit.url),
        );

      return makeInstance(
        'text',
        widgets.text.makeInstance(makeSharedInstanceState, unit.text),
      );

    case 'video':
      return makeInstance(
        'video',
        widgets.video.makeInstance(makeSharedInstanceState, unit.video?.mp4),
      );

    case 'test': {
      return makeInstance(
        'test',
        widgets.test.makeInstance(makeSharedInstanceState, unit.quiz_uuid),
      );
    }

    default:
      return null;
  }
}
