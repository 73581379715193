import * as M from 'types/serverModels';

export function getPersonalDataRows(user: M.User) {
  const personalData = [
    user.badge,
    user.native_langs_stack,
    user.langs_stack,
    user.subjects_stack,
    user.interests,
    user.education,
    user.about,
  ];

  return personalData.reduce((acc, data) => {
    const hasItems = Array.isArray(data) && data.length > 0;
    const hasContent = typeof data === 'string' && data !== '';

    if (hasItems || hasContent) {
      return acc + 1;
    }

    return acc;
  }, 0);
}
