import { makeSharedEntryReference } from 'services/I18n/makeEntryReference';

export const courseEntityLabelReference = makeSharedEntryReference(
  data => data.entity.course.label,
);

export const projectEntityLabelReference = makeSharedEntryReference(
  data => data.entity.project.label,
);

export const webinarEntityLabelReference = makeSharedEntryReference(
  data => data.entity.webinar.label,
);
