import React, { useContext, useEffect } from 'react';

import { Switch } from 'components';
import i18nData from 'features/project/Constructor/i18n.json';
import { I18n } from 'services';
import { block } from 'utils/classname';

import { HeaderExtensionProps } from '../../../types';
import { QuestionTypeChangePermissionContext } from '../../QuestionTypeChangePermissionContext';
import { StateInstance } from '../types';
import './style.scss';

const b = block('image-question-header-extension');

const Label = Switch.makeLabel({
  useLabel: () =>
    I18n.useText(i18nData).steps.shared.questions.list.variantSelection
      .multipleChoiceSwitchLabel,
});

function HeaderExtension({ instance }: HeaderExtensionProps<StateInstance>) {
  const { getQuestionTypeChangeIsPermitted } = useContext(
    QuestionTypeChangePermissionContext,
  );

  useEffect(() => {
    return instance.hasMultipleChoice.units.value.subscribe({
      name: 'permission-validator',
      callback: value => {
        if (
          !getQuestionTypeChangeIsPermitted(
            instance.id,
            value ? 'multiple-variants-selection' : 'single-variant-selection',
          )
        ) {
          setTimeout(() => {
            instance.hasMultipleChoice.units.value.setState(!value, [
              'permission-validator',
            ]);
          }, 0);
        }
      },
    });
  }, [getQuestionTypeChangeIsPermitted, instance]);

  return (
    <div className={b()}>
      <Switch.Component
        Label={Label}
        formElementState={instance.hasMultipleChoice}
      />
    </div>
  );
}

export const Component = React.memo(HeaderExtension) as typeof HeaderExtension;
