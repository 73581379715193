import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  cdate: M.Datetime;
  pa_state?: M.PendingAction['state'] | M.PendingAction['state'][] | '*';
};

export const notificationList = makeService<InputData, M.Notification[]>(
  'notification_list',
  'get',
);
