import { makeService } from './utils';

type InputData = {
  email: string;
};

export const userPasswordReset = makeService<InputData, unknown>(
  'user_password_reset',
  'post',
);
