import { makeDerivedUnit } from 'utils/State';

import { flatCachedInstancesUnit } from './instances';
import { modesUnit } from './modes';

export const allQuestionAreSavedUnit = makeDerivedUnit(
  flatCachedInstancesUnit,
  modesUnit,
).getUnit(
  (instances, modes) =>
    instances.every(x => x.kind !== 'select-instance-kind') &&
    modes.every(x => x === 'preview'),
);

export const oneQuestionInvariantUnit = makeDerivedUnit(
  flatCachedInstancesUnit,
).getUnit(
  instances => instances.filter(x => x.kind === 'question-instance').length > 0,
);
