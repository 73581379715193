import { useMemo } from 'react';

import { makePrimaryUnit } from 'utils/State';

import { Tab, TabState } from '../types';

export function useTabsState(): Record<Tab, TabState> {
  return useMemo(() => {
    return {
      passport: {
        hasUnViewedUpdatesUnit: makePrimaryUnit(false),
      },
      members: {
        hasUnViewedUpdatesUnit: makePrimaryUnit(false),
      },
      stages: {
        hasUnViewedUpdatesUnit: makePrimaryUnit(false),
      },
      results: {
        hasUnViewedUpdatesUnit: makePrimaryUnit(false),
      },
      evaluation: {
        hasUnViewedUpdatesUnit: makePrimaryUnit(false),
      },
      reflexion: {
        hasUnViewedUpdatesUnit: makePrimaryUnit(false),
      },
      history: {
        hasUnViewedUpdatesUnit: makePrimaryUnit(false),
      },
      notifications: {
        hasUnViewedUpdatesUnit: makePrimaryUnit(false),
      },
      recycleBin: {
        hasUnViewedUpdatesUnit: makePrimaryUnit(false),
      },
    };
  }, []);
}
