import React, { useMemo } from 'react';

import { List } from 'features/project/Constructor/subfeatures';
import * as M from 'types/serverModels';
import { PrimaryStateUnit } from 'utils/State';

import * as Header from './Header';
import * as PendingSublist from './PendingSublist';
import { makeColumns } from './columns';
import './style.scss';

type Props = {
  usersUnit: PrimaryStateUnit<M.UserXShort[]>;
};

function UsersList({ usersUnit }: Props) {
  const users = usersUnit.useState();

  const columns = useMemo(() => makeColumns(usersUnit), [usersUnit]);

  return (
    <List.Component
      columns={columns}
      entries={users}
      SubHeader={PendingSublist.Component}
      Header={Header.Component}
    />
  );
}

export const Component = React.memo(UsersList);

export { Header, PendingSublist };
