import { makeSharedEntryReference } from 'services/I18n';

export const editButtonLabelReference = makeSharedEntryReference(
  data => data.editButtonLabel,
);

export const deleteButtonLabelReference = makeSharedEntryReference(
  data => data.deleteButtonLabel,
);

export const requiredFieldReference = makeSharedEntryReference(
  data => data.validationErrors.requiredField,
);

export const saveButtonLabelReference = makeSharedEntryReference(
  data => data.saveButtonLabel,
);

export const closeButtonLabelReference = makeSharedEntryReference(
  data => data.closeButtonLabel,
);
