import { Device } from './types';

export const breakpoints: Record<Device, number> = {
  mobile: 770,
  'desktop-s': 990,
  'desktop-m': 1250,
  'desktop-l': Infinity,
};

export const media: Record<Device, string> = {
  mobile: `(max-width: ${breakpoints.mobile}px)`,
  'desktop-s': `(max-width: ${breakpoints['desktop-s']}px)`,
  'desktop-m': `(max-width: ${breakpoints['desktop-m']}px)`,
  'desktop-l': `(min-width: 1251px)`,
};
