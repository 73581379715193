import React from 'react';

import { I18n } from 'services';
import { block } from 'utils/classname';
import { parseHTML } from 'utils/react';

import i18nData from '../i18n.json';
import './style.scss';

const b = block('footer-signature');

type Props = {
  size: 'm' | 's';
  className?: string;
};

const currentYear = new Date().getFullYear();

function Signature({ size, className }: Props) {
  const text = I18n.useText(i18nData);
  const getTemplate = I18n.useGetTemplate();

  return (
    <div className={b({ size }, [className])}>
      {getTemplate(text.signature, { y: currentYear })}
      <span className={b('designer')}>
        {parseHTML(text.designerLabel, {
          linkConfig: { color: 'primary', weight: 'bold', size: 'm' },
        })}
      </span>
    </div>
  );
}

export const Component = React.memo(Signature) as typeof Signature;
