import React from 'react';

import { block } from 'utils/classname';

import './style.scss';

const b = block('main-menu-underlined-label');

type Props = {
  underlined: boolean;
};

function UnderlinedLabel({
  underlined,
  children,
}: React.PropsWithChildren<Props>) {
  return (
    <div className={b()}>
      <div className={b('label')}>{children}</div>
      <div className={b('line', { visible: underlined })} />
    </div>
  );
}

export const Component = React.memo(UnderlinedLabel) as typeof UnderlinedLabel;
