import React, { useCallback } from 'react';

import { block } from 'utils/classname';
import { parseHTML } from 'utils/react';

import { UserProfile } from '../../subfeatures';
import { Item } from '../../types';
import { ProvidedProps } from '../types';

const b = block('autocomplete-user-profile-sorted-item');

type Props<T extends Item> = ProvidedProps<T> &
  Omit<UserProfile.Props, 'userNameFormatter' | 'loginFormatter'>;

function UserProfileSortedItem<T extends Item>({
  item,
  regExp,
  ...userProfileProps
}: Props<T>) {
  const userNameFormatter: Exclude<
    UserProfile.Props['userNameFormatter'],
    undefined
  > = useCallback(
    userName =>
      parseHTML(
        userName.replaceAll(
          regExp,
          substring => substring && `<mark>${substring}</mark>`,
        ),
      ),
    [regExp],
  );

  const loginFormatter: Exclude<
    UserProfile.Props['loginFormatter'],
    undefined
  > = useCallback(
    login =>
      parseHTML(
        login.replaceAll(
          regExp,
          substring => substring && `<mark>${substring}</mark>`,
        ),
      ),
    [regExp],
  );

  return (
    <UserProfile.Component
      className={b()}
      userNameFormatter={userNameFormatter}
      loginFormatter={loginFormatter}
      {...userProfileProps}
    />
  );
}

export const Component = React.memo(UserProfileSortedItem);

export const makeComponent =
  (userProfileProps: UserProfile.Props) =>
  <T extends Item>(props: ProvidedProps<T>) => (
    <Component {...props} {...userProfileProps} />
  );
