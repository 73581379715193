import React from 'react';

import { I18n } from 'services';
import { EnabledTestFileTypeIcon } from 'shared/images/fileTypes';
import { block } from 'utils/classname';

import i18nData from '../../../i18n.json';
import { ProvidedProps } from '../types';

const b = block('quiz-action-enabled-file-type-icon-content');

type Props = ProvidedProps;

function EnabledFileTypeIcon({}: Props) {
  const text = I18n.useText(i18nData).content.enabledFileTypeIcon;

  return <EnabledTestFileTypeIcon className={b()} title={text.title} />;
}

export const Component = React.memo(
  EnabledFileTypeIcon,
) as typeof EnabledFileTypeIcon;
