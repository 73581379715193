import * as M from 'types/serverModels';

import { makeService } from '../utils';

type BodyInputData = {
  api_token: Pick<M.APIToken, 'name'>;
};

type InputData = BodyInputData;

type APIOutputData = {
  api_token?: Omit<M.APIToken, 'valid'> & { valid?: true };
};

type OutputData = {
  apiToken: Omit<M.APIToken, 'valid'> & { valid?: true };
};

function extractResponse(data: APIOutputData): OutputData {
  return {
    apiToken: data.api_token || {},
  };
}

export const post = makeService<InputData, APIOutputData, OutputData>(
  ({ ticket }, convertToSearchParams) =>
    `api_token/?${convertToSearchParams({ ticket })}`,
  'api-v2-post',
  false,
  extractResponse,
  {
    extractBodyInput: input => {
      const { ticket, ...bodyInput } = input;

      return bodyInput;
    },
  },
);
