import React from 'react';

import { capitalize } from 'utils/string';

import { DeviceShort, Device } from './types';
import { shortUnit, unit } from './unit';

type DeviceComponents<T> = {
  Mobile: React.FC<T>;
  Desktop: React.FC<T>;
};
type DetailedDeviceComponents<T> = {
  Mobile: React.FC<T>;
  'Desktop-s': React.FC<T>;
  'Desktop-m': React.FC<T>;
  'Desktop-l': React.FC<T>;
};

export function makeComponent<T extends Record<string, unknown>>(
  deviceComponents: DeviceComponents<T>,
) {
  return (props: T) => {
    const device = shortUnit.useState();

    const Component =
      deviceComponents[capitalize(device) as Capitalize<DeviceShort>];

    return <Component {...props} />;
  };
}

export function makeDetailedComponent<T extends Record<string, unknown>>(
  deviceComponents: DetailedDeviceComponents<T>,
) {
  return (props: T) => {
    const device = unit.useState();

    const Component =
      deviceComponents[capitalize(device) as Capitalize<Device>];

    return <Component {...props} />;
  };
}
