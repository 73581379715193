import { makeSharedEntryReference } from 'services/I18n/makeEntryReference';

export const courseTabReferences = {
  cover: makeSharedEntryReference(data => data.course.tabs.cover),
  track: makeSharedEntryReference(data => data.course.tabs.track),
  progress: makeSharedEntryReference(data => data.course.tabs.progress),
  projects: makeSharedEntryReference(data => data.course.tabs.projects),
  results: makeSharedEntryReference(data => data.course.tabs.results),
  conclusions: makeSharedEntryReference(data => data.course.tabs.conclusions),
  blog: makeSharedEntryReference(data => data.course.tabs.blog),
  members: makeSharedEntryReference(data => data.course.tabs.members),
};
