import { Classifications } from 'features/project/Constructor/subfeatures';
import { projectFeatures } from 'shared/constants';
import * as M from 'types/serverModels';
import {
  FormElementState,
  makeFormElementState,
  makeFormSectionState,
} from 'utils/FormState';
import {
  makeDerivedUnit,
  makeMappingUnitFromUnit,
  makePrimaryUnit,
} from 'utils/State';
import { Project } from 'utils/models';

import { projectFeatureReferences } from '../../../../i18nSharedReferences';

export const weightState = makeFormElementState<number | null>(null);

export const perkState = makeFormElementState<M.ProjectPerk | null>(null);

export const classificationsStateUnit = Classifications.makeStateUnit();

export const classificationsUnit = makePrimaryUnit<M.UUID[]>([]);

export const featuresUnit = makePrimaryUnit(
  projectFeatures.map(x => ({
    id: x,
    value: makeFormElementState(false),
    useLabel: () =>
      `${x} — ${Project.useFeatureText(projectFeatureReferences)[x]}`,
  })),
);

export const featuresChecksUnit = makeMappingUnitFromUnit(
  makeDerivedUnit(featuresUnit).getUnit(xs => xs.map(x => x.value.units.value)),
);

export const selectedFeaturesUnit = makeDerivedUnit(
  makeMappingUnitFromUnit(featuresUnit),
).getUnit(feature => {
  return feature
    .map(x => ({ selected: x.value.units.value, id: x.id }))
    .filter(x => x.selected)
    .map(x => x.id);
});

export const featuresFormSectionState =
  makeFormSectionState(featuresChecksUnit);

featuresChecksUnit.subscribe({
  name: 'visited-updater',
  callback: state => {
    if (state.some(x => x === true)) {
      const visitedUnit = featuresFormSectionState.units.visited;

      if (!visitedUnit.getState()) {
        visitedUnit.setState(true);
      }
    }
  },
});

export const featureCodeState = makeFormElementState('');

export const isPaywallState = makeFormElementState(false);

export const paywallWarningState = makeFormElementState('');

type ProductItem = {
  id: string;
  formElementState: FormElementState<string>;
};

export const productsUnit = makePrimaryUnit<ProductItem[]>([]);
