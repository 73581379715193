export type Logger = {
  log(...data: any[]): void;
  warn(...data: any[]): void;
};

export function makeLogger(prefix: string, debugMode: boolean): Logger {
  return debugMode
    ? {
        log(...data: any[]) {
          console.log(`[${prefix}] `, ...data);
        },
        warn(...data: any[]) {
          console.warn(`[${prefix}] `, ...data);
        },
      }
    : {
        log() {},
        warn() {},
      };
}
