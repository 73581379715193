import { filesize } from 'filesize';
import { useCallback } from 'react';

import { activeLangStateUnit } from './activeLangStateUnit';
import { memorySizes } from './constants';

type SizeInBytes = number;

export function useGetFormattedFileSize() {
  const activeLang = activeLangStateUnit.useState();

  return useCallback(
    (size: SizeInBytes) => {
      return filesize(size, {
        base: 2,
        standard: 'jedec',
        locale: activeLang,
        symbols: memorySizes[activeLang],
      }) as string;
    },
    [activeLang],
  );
}
