import { Preloader } from 'components';
import { makeFeature } from 'services/Feature';
import { usePrimaryUnit } from 'utils/State';

import * as subfeatures from './subfeatures';

export const feature = makeFeature({
  name: 'FullscreenWidgetContainer',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "fullscreen-widget-container" */
      './Component'
    ),
  Preloader: () => <Preloader.Component size="xs" />,
  i18nSharedReferences: null,
  i18nData: null,
  subfeatures: Object.values(subfeatures).map(x => x.feature),
});

export {
  getHeaderHeightStyleVariable,
  getHeaderHeight,
} from './styleVariables';

export * as Content from './content';
export * as Title from './title';
export * as SettingsContainer from './SettingsContainer';
export * as SettingsControl from './SettingsControl';

export type { Props } from './Component';

export function useFullScreenUnit() {
  return usePrimaryUnit(false);
}

export { Component } from './Component';
