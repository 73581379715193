import {
  DndContextProps,
  DndContext,
  PointerSensor as LibPointerSensor,
  KeyboardSensor as LibKeyboardSensor,
  DragOverlay,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  rectSortingStrategy,
  arrayMove,
} from '@dnd-kit/sortable';
import React, { useCallback, useMemo } from 'react';

import * as M from 'types/serverModels';
import {
  FormSectionState,
  makeFormElementState,
  useFormElementState,
} from 'utils/FormState';
import { PrimaryStateUnit, usePrimaryUnit } from 'utils/State';
import { makeUUID } from 'utils/UUID';

import { ImageInput } from '../../../../../../../subfeatures';
import { ImageElementState } from '../../../types';
import { SectionError } from '../../components';
import * as DraggableListItem from './DraggableListItem';
import { b } from './b';
import { imageData } from './constants';
import './style.scss';

type Props = {
  elementsUnit: PrimaryStateUnit<ImageElementState[]>;
  sectionStateUnit: FormSectionState<any>;
  onAdd(element: ImageElementState): void;
  onRemove(element: ImageElementState): void;
};

class PointerSensor extends LibPointerSensor {
  static activators: (typeof LibPointerSensor)['activators'] = [
    {
      eventName: 'onPointerDown',
      handler: (...args) => {
        return (
          shouldHandleSensorEvent(args[0]?.target || null) &&
          !!LibPointerSensor.activators
            .find(x => x.eventName === 'onPointerDown')
            ?.handler(...args)
        );
      },
    },
    ...LibPointerSensor.activators.filter(x => x.eventName !== 'onPointerDown'),
  ];
}

class KeyboardSensor extends LibKeyboardSensor {
  static activators: (typeof LibKeyboardSensor)['activators'] = [
    {
      eventName: 'onKeyDown',
      handler: (...args) => {
        return (
          shouldHandleSensorEvent(args[0]?.target || null) &&
          LibKeyboardSensor.activators
            .find(x => x.eventName === 'onKeyDown')
            ?.handler(...args)
        );
      },
    },
    ...LibKeyboardSensor.activators.filter(x => x.eventName !== 'onKeyDown'),
  ];
}

function shouldHandleSensorEvent(element: EventTarget | null) {
  if (element !== null && !(element instanceof Element)) {
    return true;
  }

  let cur = element;

  while (cur) {
    if (cur.classList.contains(b('list-item-delete-button'))) {
      return false;
    }

    cur = cur.parentElement;
  }

  return true;
}

const ElementsList = React.memo(
  ({
    elementsUnit,
    onAdd,
    onRemove,
  }: Pick<Props, 'elementsUnit' | 'onAdd' | 'onRemove'>) => {
    const sensors = useSensors(
      useSensor(PointerSensor),
      useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
      }),
    );

    const elements = elementsUnit.useState();
    const elementsIDs = useMemo(() => elements.map(x => x.uuid), [elements]);

    const draggedElementUnit = usePrimaryUnit<ImageElementState['uuid'] | null>(
      null,
    );

    const draggedElementID = draggedElementUnit.useState();
    const draggedElement = useMemo(
      () =>
        draggedElementID
          ? elements.find(x => x.uuid === draggedElementID)
          : null,
      [elements, draggedElementID],
    );

    const addItemButtonFormElementState =
      useFormElementState<M.ImageInfo | null>(null);

    const handleDragStart: Exclude<DndContextProps['onDragStart'], undefined> =
      useCallback(
        event => {
          draggedElementUnit.setState(`${event.active.id}`);
        },
        [draggedElementUnit],
      );

    const handleDragEnd: Exclude<DndContextProps['onDragEnd'], undefined> =
      useCallback(
        event => {
          draggedElementUnit.setState(null);

          const { active, over } = event;

          if (over && active.id !== over.id) {
            elementsUnit.setState(elements => {
              const oldIndex = elements.findIndex(x => x.uuid === active.id);
              const newIndex = elements.findIndex(x => x.uuid === over.id);

              return arrayMove(elements, oldIndex, newIndex);
            });
          }
        },
        [elementsUnit, draggedElementUnit],
      );

    const handleDraggableListItemDelete: DraggableListItem.Props['onDelete'] =
      useCallback(
        element => {
          elementsUnit.setState(prev =>
            prev.filter(x => x.uuid !== element.uuid),
          );

          onRemove(element);
        },
        [elementsUnit, onRemove],
      );

    const handleAddItemButtonUpload: Exclude<
      ImageInput.Props['onUpload'],
      undefined
    > = useCallback(
      data => {
        const newElement = {
          uuid: makeUUID(),
          image: makeFormElementState<M.ImageInfo | null>(data),
        };

        elementsUnit.setState(prev => [...prev, newElement]);

        addItemButtonFormElementState.formNode.reset();

        onAdd(newElement);
      },
      [elementsUnit, addItemButtonFormElementState, onAdd],
    );

    return (
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <ul className={b('list')}>
          <SortableContext items={elementsIDs} strategy={rectSortingStrategy}>
            {elements.map(x => (
              <DraggableListItem.Component
                key={x.uuid}
                element={x}
                onDelete={handleDraggableListItemDelete}
              />
            ))}
            {draggedElement ? (
              <DragOverlay>
                <li className={b('list-item', { kind: 'overlay' })}>
                  <ImageInput.Component
                    formElementState={draggedElement.image}
                    imageData={imageData}
                    variant="card"
                  />
                </li>
              </DragOverlay>
            ) : null}
          </SortableContext>
          <li className={b('list-item', { kind: 'add-item-button' })}>
            <ImageInput.Component
              formElementState={addItemButtonFormElementState}
              imageData={imageData}
              variant="card"
              onUpload={handleAddItemButtonUpload}
            />
          </li>
        </ul>
      </DndContext>
    );
  },
);

function ImageElements({
  elementsUnit,
  sectionStateUnit,
  onAdd,
  onRemove,
}: Props) {
  return (
    <div className={b()}>
      <ElementsList
        elementsUnit={elementsUnit}
        onAdd={onAdd}
        onRemove={onRemove}
      />
      <SectionError.Component sectionStateUnit={sectionStateUnit} />
    </div>
  );
}

export const Component = React.memo(ImageElements);
