import { createContext } from 'react';

import { QuestionKind } from 'types';

type DependenciesData = {
  getQuestionTypeChangeIsPermitted(
    instanceID: string,
    questionKind: QuestionKind,
  ): boolean;
};

export const QuestionTypeChangePermissionContext =
  createContext<DependenciesData>({
    getQuestionTypeChangeIsPermitted: () => true,
  });
