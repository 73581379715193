import React from 'react';
import { Route } from 'react-router';

import { Page } from 'services';

import { routeTree } from '../../routeTree';
import * as History from './History';
import * as Schedular from './Schedular';
import * as features from './features';

type Props = {};

const tabs = { schedular: true, history: true };

const Navigation: features.Layout.Props['Navigation'] = React.memo(
  ({ className }) => (
    <features.Navigation.Component className={className} tabs={tabs} />
  ),
);

const Content: features.Layout.Props['Content'] = React.memo(
  ({ className }) => (
    <>
      <Route path={Schedular.path}>
        <Schedular.Component
          className={className}
          listStretchSizeOnMobile={20}
        />
      </Route>
      <Route path={History.path}>
        <History.Component className={className} listStretchSizeOnMobile={20} />
      </Route>
    </>
  ),
);

function WebinarList({}: Props) {
  return (
    <features.Layout.Component Navigation={Navigation} Content={Content} />
  );
}

export const Component = Page.makePage({
  path: routeTree.LANG.webinar.list.getPath(),
  routeProps: { exact: false },
  scrollTo: 'top-on-mount',
  Component: React.memo(WebinarList) as typeof WebinarList,
  features: Object.values(features).map(x => x.feature),
});
