import React from 'react';

import { block } from 'utils/classname';

import './style.scss';

const b = block('text-tab-content');

type Props = {
  useLabel(): string;
};

function TextTabContent({ useLabel }: Props) {
  return <div className={b()}>{useLabel()}</div>;
}

export const Component = React.memo(TextTabContent) as typeof TextTabContent;

export const makeComponent = (props: Props) => () => <Component {...props} />;
