import * as M from 'types/serverModels';

export function getText(data: M.Unit) {
  switch (data.type) {
    case 'web': {
      return data.text || '';
    }
    default: {
      return null;
    }
  }
}
