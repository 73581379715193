import React, { useCallback, useEffect } from 'react';

import { API } from 'services';
import { PrimaryStateUnit, usePrimaryUnit } from 'utils/State';

import { UserActionErrorModal } from '../../../subfeatures';
import { PendingAction } from '../../../types';
import * as Default from '../Default';

type ErrorEvent = {
  kind: 'already-accepted' | 'already-rejected' | 'already-canceled';
  action: PendingAction;
};

export type Props = {
  pendingAction: PendingAction;
  pendingActionsUnit: PrimaryStateUnit<PendingAction[]>;
  useLabel(): string;
  useErrorModalCaption(): {
    alreadyAccepted: string;
    alreadyRejected: string;
    alreadyCanceled: string;
  };
  onSuccess(): void;
  onError(event: ErrorEvent): void;
};

function AcceptAction({
  pendingAction,
  pendingActionsUnit,
  useLabel,
  useErrorModalCaption,
  onSuccess,
  onError,
}: Props) {
  const errorModalCaption = useErrorModalCaption();
  const errorUnit = usePrimaryUnit<ErrorEvent | null>(null);
  const errorModalCaptionUnit = UserActionErrorModal.useCaptionUnit();
  const isErrorModalOpenUnit = UserActionErrorModal.useIsOpenUnit();

  const callStateUnit = API.services.pendingActionChange.useCallStateUnit();

  const call = API.services.pendingActionChange.useCall(callStateUnit);

  const handleClick = useCallback(() => {
    call({
      uuid: pendingAction.actionUUID,
      action: 'accept',
    });
  }, [pendingAction.actionUUID, call]);

  const handleErrorModalClose = useCallback(() => {
    pendingActionsUnit.setState(prev =>
      prev.filter(x => x.actionUUID !== pendingAction.actionUUID),
    );

    onError(errorUnit.getState() as ErrorEvent);

    errorUnit.resetState();
  }, [pendingActionsUnit, pendingAction, errorUnit, onError]);

  useEffect(() => {
    return callStateUnit.subscribe({
      name: 'pending-users-state-updater',
      callback: state => {
        switch (state.kind) {
          case 'successful': {
            pendingActionsUnit.setState(prev =>
              prev.filter(x => x.actionUUID !== pendingAction.actionUUID),
            );

            onSuccess();

            break;
          }
          case 'error': {
            switch (state.code) {
              case 1016: {
                errorModalCaptionUnit.setState(
                  errorModalCaption.alreadyAccepted,
                );

                errorUnit.setState({
                  kind: 'already-accepted',
                  action: pendingAction,
                });

                break;
              }
              case 1017: {
                errorModalCaptionUnit.setState(
                  errorModalCaption.alreadyRejected,
                );

                errorUnit.setState({
                  kind: 'already-rejected',
                  action: pendingAction,
                });

                break;
              }
              case 1018: {
                errorModalCaptionUnit.setState(
                  errorModalCaption.alreadyCanceled,
                );

                errorUnit.setState({
                  kind: 'already-canceled',
                  action: pendingAction,
                });

                break;
              }
            }

            isErrorModalOpenUnit.setState(true);

            break;
          }
        }
      },
    });
  }, [
    pendingActionsUnit,
    pendingAction,
    errorModalCaption.alreadyAccepted,
    errorModalCaption.alreadyRejected,
    errorModalCaption.alreadyCanceled,
    errorUnit,
    errorModalCaptionUnit,
    isErrorModalOpenUnit,
    callStateUnit,
    onError,
    onSuccess,
  ]);

  return (
    <>
      <Default.Component callStateUnit={callStateUnit} onClick={handleClick}>
        {useLabel()}
      </Default.Component>
      <UserActionErrorModal.Component
        isOpenUnit={isErrorModalOpenUnit}
        captionUnit={errorModalCaptionUnit}
        userData={pendingAction.userData}
        onOkButtonClick={handleErrorModalClose}
        onClose={handleErrorModalClose}
      />
    </>
  );
}

export const Component = React.memo(AcceptAction) as typeof AcceptAction;
