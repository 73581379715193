import * as R from 'ramda';

export const removeNilProperties = <T extends Record<string, unknown>>(
  value: T,
): T => {
  return Object.entries(value).reduce((acc, [key, x]) => {
    if (R.isNil(x)) return acc;

    if (R.type(x) === 'Object') {
      return {
        ...acc,
        [key]: removeNilProperties(x as Record<string, unknown>),
      };
    }

    return { ...acc, [key]: x };
  }, {} as T);
};
