import { createRequiredContext } from 'utils/react/RequiredContext';

import { ConstructorWidgetInstance } from '../types';

export type ConstructorWidgetContextData = {
  stateInstance: ConstructorWidgetInstance;
  onDelete(): void;
  onMoveNext(): void;
  onMovePrev(): void;
};

export const ConstructorWidgetContext =
  createRequiredContext<ConstructorWidgetContextData>();
