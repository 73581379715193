import { Validator } from 'types';
import { makeFormElementState } from 'utils/FormState';
import { makeDerivedUnit } from 'utils/State';
import { VennChart } from 'utils/business';

import { VariantOfSingleVariant } from '../types';

export function forSingleVariant(
  question: VennChart.QuestionWithSingleVariant,
  name: string,
  answerUUID: string | null = null,
  validators: Validator[] = [],
): VennChart.SetForSingleVariantQuestion {
  return {
    kind: 'for-single-variant',
    question,
    name,
    selectedVariantID: makeFormElementState<string | null>(
      answerUUID,
      validators,
    ),
    variants: makeDerivedUnit(question.variants).getUnit(variants =>
      variants.map(
        (x): VariantOfSingleVariant => ({
          id: x.id,
          name: x.text.formElementState.units.value,
        }),
      ),
    ),
  };
}
