import React, { useEffect, useMemo } from 'react';

import { Select } from 'components';
import i18nData from 'features/project/Constructor/i18n.json';
import { I18n } from 'services';
import { vennChartSetNames } from 'shared/constants';
import { useFormElementState } from 'utils/FormState';
import { makeMappingUnit } from 'utils/State';
import { makeUUID } from 'utils/UUID';
import { Layer, VennChart } from 'utils/business';
import { block } from 'utils/classname';

import { EnumeratedQuestionInstance } from '../../../InputDataForm/types';
import { questionIDToQuestionKindUnit } from '../../../InputDataForm/units/instances';
import { Question } from '../../../shared';
import { WidgetKey } from '../../types';
import { instancesUnit, questionIDToWidgetsNumberUnit } from '../../units';
import { widgetIDToScrollToUnit } from '../../widgetIDToScrollToUnit';
import { widgetKeyToFindingsConstructorWidget } from '../../widgets';
import { questionIDToQuestionForLayerUnit } from '../../widgets/Map/questionsForLayersUnit';
import { questionIDToQuestionForVennUnit } from '../../widgets/Venn/questionsForVennUnit';
import { getWidgetKeys } from './getWidgetKeys';
import './style.scss';

const b = block('widget-select');

type Props = {
  questionInstance: EnumeratedQuestionInstance;
};

function Option({ option }: Select.Option.Props<WidgetKey>) {
  const text = I18n.useText(i18nData);
  return (
    <Select.Option.Container.DefaultComponent>
      {text.steps.findingsSettings.widgets[option!].name}
    </Select.Option.Container.DefaultComponent>
  );
}

function WidgetSelect({ questionInstance }: Props) {
  const questionKindUnit = useMemo(() => {
    const questionKind = Question.getQuestionKindFromQuestionInstance(
      questionInstance.instance,
    );
    return makeMappingUnit(questionKind);
  }, [questionInstance.instance]);

  const questionKind = questionKindUnit.useState();

  const options = useMemo((): WidgetKey[] => {
    return getWidgetKeys(questionKind);
  }, [questionKind]);

  const activeOptionState = useFormElementState<WidgetKey | null>(null);

  const ActiveOption = useMemo(() => {
    return function ActiveOption({}: Select.ActiveOption.Props<WidgetKey>) {
      const text = I18n.useText(i18nData);

      const questionIDToWidgetsNumber =
        questionIDToWidgetsNumberUnit.useState();

      const widgetsNumber =
        questionIDToWidgetsNumber[questionInstance.instance.id];

      return (
        <Select.ActiveOption.Container.DefaultComponent>
          {widgetsNumber
            ? `${text.steps.findingsSettings.addWidgetSelect.placeholderTextForNonZeroWidgets} ${widgetsNumber}`
            : text.steps.findingsSettings.addWidgetSelect
                .placeholderTextForZeroWidgets}
        </Select.ActiveOption.Container.DefaultComponent>
      );
    };
  }, [questionInstance.instance.id]);

  useEffect(() => {
    return activeOptionState.units.value.subscribe({
      name: 'widget-adder',
      callback: key => {
        const newInstance = (() => {
          const id = makeUUID();
          const questionID = questionInstance.instance.id;

          switch (key) {
            case 'area':
            case 'column':
            case 'columnStack':
            case 'line':
            case 'pie':
            case 'scatter':
              return widgetKeyToFindingsConstructorWidget[key].makeInstance({
                id,
                initialXAxisData: { kind: 'question', questionID },
                initialYAxisData: { kind: 'quantity' },
              });
            case 'dataList':
              return widgetKeyToFindingsConstructorWidget.dataList.makeInstance(
                { id },
              );
            case 'map': {
              const questionID = questionInstance.instance.id;

              const questionIDToQuestionKind =
                questionIDToQuestionKindUnit.getState();

              const layerID = makeUUID();

              const layer = ((): Layer.Layer => {
                const questionIDToQuestionForLayer =
                  questionIDToQuestionForLayerUnit.getState();

                const question = questionIDToQuestionForLayer[questionID];
                const layerVariant = Layer.makeVariantFromQuestionKind(
                  questionIDToQuestionKind[questionID],
                );
                switch (layerVariant) {
                  case 'colored-markers/icons':
                    return Layer.layerConstructors.withColoredMarkersOrIcons(
                      question as Layer.QuestionForLayerWithVariants,
                      layerID,
                    );
                  case 'scale':
                    return Layer.layerConstructors.withScale(
                      question as Layer.QuestionForLayerWithScale,
                      layerID,
                    );
                  case 'without-settings':
                    return Layer.layerConstructors.withoutSettings(
                      question as Layer.QuestionForLayerWithoutSettings,
                      layerID,
                    );
                  default:
                    throw Error(`unexpected layer variant, ${layerVariant}`);
                }
              })();

              return widgetKeyToFindingsConstructorWidget.map.makeInstance({
                id,
                initialLayers: [layer],
              });
            }

            case 'gallery':
            case 'filesList':
            case 'textsList':
            case 'textsCloud':
              return widgetKeyToFindingsConstructorWidget[key].makeInstance({
                id,
                initialQuestionID: questionID,
              });
            case 'timeline':
              switch (questionInstance.instance.kind) {
                case 'text': {
                  if (questionInstance.instance.isMultiline.getValue()) {
                    return widgetKeyToFindingsConstructorWidget.timeline.makeInstance(
                      {
                        id,
                        initialTextQuestionID: questionID,
                      },
                    );
                  }
                  return widgetKeyToFindingsConstructorWidget.timeline.makeInstance(
                    {
                      id,
                      initialHeadlineQuestionID: questionID,
                    },
                  );
                }
                case 'image':
                case 'video': {
                  return widgetKeyToFindingsConstructorWidget.timeline.makeInstance(
                    {
                      id,
                      initialMediaQuestionID: questionID,
                    },
                  );
                }
                default:
                  return widgetKeyToFindingsConstructorWidget.timeline.makeInstance(
                    {
                      id,
                      initialStartDateQuestionID: questionID,
                    },
                  );
              }

            case 'venn': {
              const questionIDToQuestionForVenn =
                questionIDToQuestionForVennUnit.getState();
              const question = questionIDToQuestionForVenn[questionID];

              const [firstName, ...restNames] = vennChartSetNames;

              return widgetKeyToFindingsConstructorWidget.venn.makeInstance({
                id,
                initialSets: [
                  VennChart.makeNewSet(
                    question,
                    firstName,
                    VennChart.makeConstructorValidators(),
                  ),
                  ...restNames.map(VennChart.setConstructors.withoutQuestion),
                ],
              });
            }
            case null:
              throw new Error('unexpected value');
          }
        })();
        instancesUnit.setState(prev => [...prev, newInstance]);
        widgetIDToScrollToUnit.setState(newInstance.id);
      },
    });
  }, [activeOptionState.units.value, questionInstance.instance]);

  if (options.length === 0) {
    return null;
  }

  return (
    <Select.Component<WidgetKey | null>
      activeOptionState={activeOptionState}
      className={b()}
      options={options}
      ActiveOption={ActiveOption}
      Option={Option}
      kind="unframed"
    />
  );
}

export const Component = React.memo(WidgetSelect);
