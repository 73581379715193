import * as R from 'ramda';

import {
  GroupedStoreCachedInstances,
  GroupedWidgetPath,
  ConstructorWidgetCachedInstance,
} from '../../types';

export function moveGroupedNext(
  widgetInstance: ConstructorWidgetCachedInstance,
  path: GroupedWidgetPath,
  storeInstances: GroupedStoreCachedInstances,
): GroupedStoreCachedInstances {
  const group = storeInstances.groups[path.groupIndex];

  if (
    path.groupIndex === storeInstances.groups.length - 1 &&
    path.widgetIndex === group.instances.length - 1
  ) {
    return storeInstances;
  }

  const makeWidgetInstancesLens = (groupIndex: number) =>
    R.lensPath<GroupedStoreCachedInstances, 'groups', number, 'instances'>([
      'groups',
      groupIndex,
      'instances',
    ]);

  if (path.widgetIndex === group.instances.length - 1) {
    const removeFromOldGroup = R.over(
      makeWidgetInstancesLens(path.groupIndex),
      instances => R.without([widgetInstance], instances),
    );

    const addToNextGroup = R.over(
      makeWidgetInstancesLens(path.groupIndex + 1),
      instances => [widgetInstance, ...instances],
    );

    return [removeFromOldGroup, addToNextGroup].reduce(
      (acc, f) => f(acc),
      storeInstances,
    );
  }

  return R.over(
    makeWidgetInstancesLens(path.groupIndex),
    instances => R.move(path.widgetIndex, path.widgetIndex + 1, instances),
    storeInstances,
  );
}
