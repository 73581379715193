import * as R from 'ramda';
import { useCallback } from 'react';

import { useReference } from './reference';
import { EntryReference } from './types';

function getFormattedCoordinateValue(
  coordinate: number,
  decimals: number | -1,
) {
  if (decimals === -1) {
    return Math.abs(coordinate);
  }

  return Math.abs(coordinate).toFixed(decimals);
}

function getCoordinateUnit(
  value: number,
  positiveValueUnit: string,
  negativeValueUnit: string,
) {
  if (value > 0) {
    return positiveValueUnit;
  }

  if (value < 0) {
    return negativeValueUnit;
  }

  return '';
}

type Options = {
  lat: { value: number; decimals?: number };
  lng: { value: number; decimals?: number };
  delimiter?: string;
};

const defaultOptions = {
  lat: { decimals: -1 },
  lng: { decimals: -1 },
  delimiter: ' ',
};

export function useGetFormattedCoordinates(
  latitudePositiveValueUnitReference: EntryReference,
  latitudeNegativeValueUnitReference: EntryReference,
  longitudePositiveValueUnitReference: EntryReference,
  longitudeNegativeValueUnitReference: EntryReference,
) {
  const latitudePositiveValueUnit = useReference(
    latitudePositiveValueUnitReference,
  );
  const latitudeNegativeValueUnit = useReference(
    latitudeNegativeValueUnitReference,
  );
  const longitudePositiveValueUnit = useReference(
    longitudePositiveValueUnitReference,
  );
  const longitudeNegativeValueUnit = useReference(
    longitudeNegativeValueUnitReference,
  );

  return useCallback(
    (options: Options) => {
      const fullOptions = R.mergeDeepLeft(options, defaultOptions);

      const formattedLatValue = getFormattedCoordinateValue(
        fullOptions.lat.value,
        fullOptions.lat.decimals,
      );
      const formattedLngValue = getFormattedCoordinateValue(
        fullOptions.lng.value,
        fullOptions.lng.decimals,
      );

      const latUnit = getCoordinateUnit(
        fullOptions.lat.value,
        latitudePositiveValueUnit,
        latitudeNegativeValueUnit,
      );
      const lngUnit = getCoordinateUnit(
        fullOptions.lng.value,
        longitudePositiveValueUnit,
        longitudeNegativeValueUnit,
      );

      return `${formattedLatValue} ${latUnit}${fullOptions.delimiter}${formattedLngValue} ${lngUnit}`;
    },
    [
      latitudePositiveValueUnit,
      latitudeNegativeValueUnit,
      longitudePositiveValueUnit,
      longitudeNegativeValueUnit,
    ],
  );
}
