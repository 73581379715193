import { useMemo } from 'react';

import { I18n } from 'services';

type References = {
  0: I18n.EntryReference;
  1: I18n.EntryReference;
  4: I18n.EntryReference;
};

export function useStatusText(references: References) {
  const text0 = I18n.useReference(references[0]);
  const text1 = I18n.useReference(references[1]);
  const text4 = I18n.useReference(references[4]);

  const courseStatusText = useMemo(() => {
    return {
      0: text0,
      1: text1,
      4: text4,
    };
  }, [text0, text1, text4]);

  return courseStatusText;
}
