import { I18n } from 'services';
import * as TS from 'types';
import { makeLogger } from 'utils/Logger';
import {
  UnitDebugData,
  makePrimaryUnit,
  makeDerivedUnit,
  makeMappingUnit,
  AbstractStateUnit,
} from 'utils/State';

import { FormNode, FormSectionState, FormSectionStateUnits } from './types';

export function makeFormSectionState<T extends AbstractStateUnit<any>>(
  valueUnit: T,
  validators?: TS.Validator[],
  debuggers?: {
    value?: UnitDebugData;
    error?: UnitDebugData;
    validator?: UnitDebugData;
  },
): FormSectionState<T> {
  const visitedUnit = makePrimaryUnit<boolean>(false);
  const disabledUnit = makePrimaryUnit<boolean>(false);

  const validationLogger = makeLogger(
    debuggers?.validator?.name || '',
    debuggers?.validator?.name !== undefined,
  );

  let stateValidators: TS.Validator[] = validators ?? [];

  const errorUnit = makePrimaryUnit<I18n.EntryReference | null>(null);
  const isValidUnit = makePrimaryUnit(true);
  const validationIsPendingUnit = makePrimaryUnit(false);

  const units: FormSectionStateUnits<T> = {
    error: errorUnit,
    isValid: isValidUnit,
    value: valueUnit,
    visited: visitedUnit,
    disabled: disabledUnit,
    validationIsPending: validationIsPendingUnit,
  };

  let validatorsAreInitialized = false;

  let resetValidators: (() => void) | null = null;

  const initValidators = (validatorsToInit: TS.Validator[]) => {
    if (validatorsAreInitialized) {
      console.warn('bad validators initailization');
      return;
    }

    validatorsAreInitialized = true;

    const errorUnitsForValidators = validatorsToInit.map(() =>
      makePrimaryUnit<I18n.EntryReference | null>(null),
    );

    resetValidators = () => {
      errorUnitsForValidators.forEach(unit => {
        unit.resetState();
      });

      validatorsToInit.forEach(x => {
        x.reset();
      });
    };

    units.error = makeDerivedUnit(
      makeMappingUnit(errorUnitsForValidators),
    ).getUnit(errors => errors.find(x => x !== null) || null, debuggers?.error);

    units.isValid = makeDerivedUnit(units.error).getUnit(err => err === null);

    validatorsToInit.forEach((validator, index) => {
      const handleInvalidResult = () => {
        validationLogger.log('handle invalid result');

        validatorsToInit
          .slice(index + 1)
          .forEach(x => x.validationIsAllowedUnit.setState(false));
      };

      const handleSwitchFromInvalidToValid = () => {
        validationLogger.log('handle switch from valid to valid');

        for (let i = index + 1; i < validatorsToInit.length; ++i) {
          validatorsToInit[i].validationIsAllowedUnit.setState(true);
          const valid = validatorsToInit[i].validate();

          if (!valid) {
            break;
          }
        }
      };

      validator.initDependencies(
        units,
        errorUnitsForValidators[index],
        handleInvalidResult,
        handleSwitchFromInvalidToValid,
      );
    });
  };

  if (stateValidators.length > 0) {
    initValidators(stateValidators);
  }

  const formNode: FormNode = {
    validate: () =>
      stateValidators ? stateValidators.every(x => x.validate()) : true,
    isValid: () =>
      stateValidators ? stateValidators.every(x => x.isValid()) : true,
    setDisabled: disabledUnit.setState,
    getValue: valueUnit.getState,
    reset: () => {
      visitedUnit.resetState();
      disabledUnit.resetState();
      errorUnit.resetState();
      isValidUnit.resetState();
      resetValidators?.();
    },
  };

  return {
    kind: 'form-section-state',
    units,
    formNode,
    setValidators: (...validators) => {
      stateValidators = validators;
      initValidators(validators);
    },
  };
}
