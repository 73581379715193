type Options = { path: string; mode?: 'link' };

export function downloadFile({ path, mode = 'link' }: Options) {
  switch (mode) {
    case 'link': {
      downloadByLink(path);

      break;
    }
  }
}

function downloadByLink(path: string) {
  const anchor = document.createElement('a');

  anchor.href = path;

  const isPathExternal = !path.startsWith('/');
  anchor.target = isPathExternal ? '_blank' : '';
  anchor.rel = isPathExternal ? 'noopener noreferrer' : '';

  anchor.download = path.slice(path.lastIndexOf('/') + 1);

  document.body.appendChild(anchor);

  anchor.click();

  document.body.removeChild(anchor);
}
