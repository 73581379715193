import React from 'react';

import { ConstructorFormSection } from 'features/project/Constructor/subfeatures';
import { makePrimaryUnit } from 'utils/State';
import { block } from 'utils/classname';

import { Step } from '../../types';
import { makeStepErrorsUnit } from '../../utils';
import * as sections from './sections';
import { visitedUnit } from './units';

const b = block('recommendations-section');

type Props = {};

function Recommendations({}: Props) {
  return (
    <ConstructorFormSection.Component className={b()}>
      <sections.Comments.Component />
    </ConstructorFormSection.Component>
  );
}

const Component = React.memo(Recommendations);

export const step: Step = {
  key: 'recommendations',
  Form: Component,
  errorsUnit: makeStepErrorsUnit(),
  visitedUnit,
  progressInvariantUnitsUnit: makePrimaryUnit([]),
  hasSomeInputUnit: makePrimaryUnit(false),
  resetState: () => {},
};
