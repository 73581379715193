import * as M from 'types/serverModels';

import { findStage } from './findStage';
import { matchMaterial } from './matchMaterial';

export function findMaterial(
  data: M.ProjectBookChange['change'],
  projectBook: M.ProjectBook,
) {
  const materialUUID = matchMaterial(data);

  const stage = findStage(data, projectBook);

  return materialUUID
    ? stage?.materials?.find(x => x.uuid === materialUUID)
    : undefined;
}
