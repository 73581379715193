import { useMemo } from 'react';

import type { Props } from './Component';
import { b } from './b';

type Options = {
  color?: 'fg-3';
};

export const makeUserNameFormatter: (
  options: Options,
) => Props['userNameFormatter'] =
  ({ color }) =>
  userName => {
    return (
      <span className={b('formatted-username', { color })}>{userName}</span>
    );
  };

export const useUserNameFormatter: (
  options: Options,
) => Props['userNameFormatter'] = options =>
  useMemo(() => makeUserNameFormatter(options), [options]);
