import { makeDerivedUnit, makeMappingUnit } from 'utils/State';

import { makeStepVisitedUnit } from '../../../utils';
import {
  annotationState,
  projectDescriptionState,
  projectImageState,
  keywordsState,
  subjectsFormSectionState,
  ageRangesFormSectionState,
} from './inputState';

export const someFieldIsVisitedUnit = makeDerivedUnit(
  makeMappingUnit([
    annotationState.units.visited,
    projectDescriptionState.units.visited,
    projectImageState.units.visited,
  ]),
).getUnit(statuses => statuses.some(x => x));

export const visitedUnit = makeStepVisitedUnit();

const errorUnitsNotifierName = 'error-units-notifier';

visitedUnit.subscribe({
  name: errorUnitsNotifierName,
  callback: state => {
    if (state) {
      [
        annotationState,
        projectDescriptionState,
        projectImageState,
        keywordsState,
        subjectsFormSectionState,
        ageRangesFormSectionState,
      ].forEach(formEntity => {
        formEntity.units.visited.setState(true);
      });
    }
  },
});
