import React from 'react';

import * as M from 'types/serverModels';
import { block } from 'utils/classname';

import './style.scss';

const b = block('flag');

type Props = {
  text: string;
  color?: `status-${M.Project['status'] | M.Course['status']}`;
  className?: string;
  theme?: 'dark' | 'light';
  cardColor?: React.CSSProperties['backgroundColor'];
};

function Flag({ theme, text, color, className, cardColor }: Props) {
  if (theme && cardColor) {
    return (
      <span className={b({ theme, color }, [className])} title={text}>
        <span className={b('text')} style={{ backgroundColor: cardColor }}>
          {text}
        </span>
      </span>
    );
  } else {
    return (
      <span className={b('default', { color }, [className])} title={text}>
        {text}
      </span>
    );
  }
}

export const Component = React.memo(Flag) as typeof Flag;
