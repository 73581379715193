import * as M from 'types/serverModels';

import { makeService } from '../utils';

type InputData = {
  uuid: M.UUID;
  resolveUser?: boolean;
  resolveGroup?: boolean;
};

type OutputData = {
  project: M.Project;
  data: M.QuestionnaireData;
};

export const get = makeService<InputData, OutputData>(
  ({ uuid, resolveUser, resolveGroup }, convertToSearchParams) =>
    `data/${uuid}/?${convertToSearchParams({ resolveUser, resolveGroup })}`,
  'api-v2-get',
  false,
);
