import { Modal, Preloader } from 'components';
import { makeFeature } from 'services/Feature';

import i18nData from './i18n.json';
import * as subfeatures from './subfeatures';

export const feature = makeFeature({
  name: 'CompactConstructorModal',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "compact-constructor-modal" */
      './Component'
    ),
  Preloader: Preloader.variants.Empty.Component,
  i18nData,
  i18nSharedReferences: null,
  subfeatures: Object.values(subfeatures).map(x => x.feature),
});

export const makeIsOpenUnit = Modal.makeIsOpenUnit;
export const useIsOpenUnit = Modal.useIsOpenUnit;

export const Component = feature.Component;
