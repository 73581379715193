import React from 'react';

import { ScrollToTopOnMount } from 'utils/DOM';

import { PageConstructorArgs } from './types';

type Props = Pick<PageConstructorArgs<any, any>, 'scrollTo'>;

function ScrollTo({ scrollTo }: Props) {
  switch (scrollTo) {
    case 'none': {
      return null;
    }
    case 'top-on-mount': {
      return <ScrollToTopOnMount.Component />;
    }
  }
}

export const Component = React.memo(ScrollTo);
