import { makeService } from './utils';

type InputData =
  | {
      login: string;
    }
  | { messages: string[] };

export const privateMessageCheckRead = makeService<InputData, unknown>(
  'private_message_check_read',
  'post',
);
