import * as units from '../units';

export function resetState() {
  Object.values(units).forEach(x => {
    // TIP: for resetting tinymce state, it has buggy event dispatching which leads to value state change notification after component unmounting
    setTimeout(() => {
      switch (x.kind) {
        case 'form-element-state': {
          x.formNode.reset();
          break;
        }
        case 'primary': {
          x.resetState();
          break;
        }
      }
    }, 0);
  });
}
