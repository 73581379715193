import { makeFeature } from 'services/Feature';

import i18nData from './i18n.json';

export const feature = makeFeature({
  name: 'ProjectViewAssignmentModal',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "project-view-assignment-modal" */
      './Component'
    ),
  i18nData,
  i18nSharedReferences: null,
  subfeatures: [],
});

export const Component = feature.Component;

export { isOpenUnit } from './units';
