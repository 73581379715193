import { FindingsSettings } from '../../steps';
import {
  AssociatedWidgetErrorInfo,
  EntityManipulationPermission,
} from '../../types';
import { widgetHasAssociatedQuestion } from './widgetHasAssociatedQuestion';

export function getQuestionInstanceDeletePermission(
  questionID: string,
): EntityManipulationPermission {
  const widgetsAssociatedWithQuestion = FindingsSettings.selectedWidgetInstances
    .getState()
    .filter(widget => widgetHasAssociatedQuestion(widget, questionID));

  if (widgetsAssociatedWithQuestion.length === 0) {
    return { kind: 'granted' };
  }

  return {
    kind: 'denied',
    associatedWidgets: widgetsAssociatedWithQuestion.map(
      (x): AssociatedWidgetErrorInfo => ({
        name: x.title.formElementState.units.value.getState(),
        widgetKey: x.kind,
      }),
    ),
  };
}
