import { QuizQuestionKind } from 'types';

import { QuestionKey } from './types';

export const questionKindToQuestionKeyMapping: Record<
  QuizQuestionKind,
  QuestionKey | null
> = {
  'multiline-string': 'text',
  'single-line-string': 'text',
  'multiple-variants-selection': 'variantSelection',
  'single-variant-selection': 'variantSelection',
  image: 'image',
  map: 'map',
  'text-to-text-match': 'match',
  'image-to-text-match': 'match',
  number: 'number',
};
