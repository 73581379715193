import * as M from 'types/serverModels';
import { FormElementState, makeFormElementState } from 'utils/FormState';

import {
  QuestionConstructor,
  SharedInstanceStateConstructor,
} from '../../types';
import * as FormExtension from './FormExtension';
import * as PreviewModeForm from './PreviewModeForm';
import { StateInstance } from './types';

export type { StateInstance };

export const constructor: QuestionConstructor<
  'number',
  StateInstance,
  typeof makeInstance
> = {
  key: 'number',
  icon: 'number',
  makeInstance,
  FormExtension: FormExtension.Component,
  PreviewModeForm: PreviewModeForm.Component,
};

export function makeServerQuestion(
  matchingState: FormElementState<string>,
  scoreState: FormElementState<number | null>,
): Partial<M.NumberQuizQuestion> {
  return {
    type: 'number',
    matchings: [matchingState.getValue()],
    score: Number(scoreState.getValue()),
  };
}

type InitialState = {
  matchings: string[];
  score: number | null;
};

export function makeInstance(
  makeSharedInstanceState: SharedInstanceStateConstructor,
  initialState?: InitialState,
): StateInstance {
  const matchingState = makeFormElementState(
    initialState?.matchings?.[0] || '',
  );
  const scoreState = makeFormElementState(initialState?.score ?? null);

  return {
    kind: 'number',
    matching: matchingState,
    score: scoreState,
    makeServerQuestion: () => makeServerQuestion(matchingState, scoreState),
    ...makeSharedInstanceState(),
  };
}
