import { makeFeature } from 'services/Feature';

import * as subfeatures from './subfeatures';

export const feature = makeFeature({
  name: 'ProjectPage',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "project-page" */
      './Component'
    ),
  i18nData: null,
  i18nSharedReferences: null,
  prefetched: true,
  subfeatures: Object.values(subfeatures).map(x => x.feature),
});

export type { Props } from './Component';

export type { ContentProvidedData } from './types';

export { DependenciesContext } from './dependenciesContext';

export const Component = feature.Component;
