import * as M from 'types/serverModels';
import { UUID } from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  data: {
    basket: UUID;
    lang: string;
    currency: M.Currency;
    operator: string;
  };
};

type Output = any;

export const storePayUrl = makeService<InputData, Output>(
  'store_pay_url',
  'post-json',
);
