import React from 'react';

import { Checkbox } from 'components';
import { I18n } from 'services';
import { FormElementState, useFormElementState } from 'utils/FormState';
import { block } from 'utils/classname';
import { parseHTML } from 'utils/react';
import { nonFalse } from 'utils/validators';

import i18nData from './i18n.json';
import './style.scss';

const b = block('sign-up-form-terms-service-field');

type Props = {
  formElementState: FormElementState<boolean>;
};

export const useFieldState = () => {
  return useFormElementState<boolean>(false, [
    nonFalse(I18n.makeEntryReference(i18nData, data => data.notAcceptedError)),
  ]);
};

const Label = Checkbox.makeLabel({
  size: 'text-14',
  useLabel: () =>
    parseHTML(I18n.useText(i18nData).label, {
      linkConfig: { size: 'm', weight: 'bold' },
    }),
});

function TermsServiceCheckbox({ formElementState }: Props) {
  return (
    <Checkbox.Component
      className={b()}
      formElementState={formElementState}
      Label={Label}
      errorRows={1}
      boxToTop
    />
  );
}

export const Component = React.memo(TermsServiceCheckbox);
