import { Dropdown } from 'components';
import { I18n } from 'services';
import { makeFormSectionState } from 'utils/FormState';
import {
  PrimaryStateUnit,
  MappedState,
  makePrimaryUnit,
  makeDerivedUnit,
  makeMappingUnitFromUnit,
} from 'utils/State';
import { makeSingleUnitValidator } from 'utils/validators';

import i18nData from '../../../../../i18n.json';
import { MatchingsState, ServerQuestion, StateInstance } from '../types';
import { makeMatchingRightColumnElement } from './makeMatchingRightColumnElement';

type Options = {
  rightColumn: StateInstance['rightColumn'];
  initState(
    rightElement: MappedState<
      StateInstance['rightColumn']['elementsUnit']
    >[number],
  ): boolean;
};

const atLeastOneMatchingRequiredMessage = I18n.makeEntryReference(
  i18nData,
  data =>
    data.questions.list.match.matchings.rightColumn.errors
      .atLeastOneMatchingRequired,
);

export function makeMatchingLeftColumnElement({
  rightColumn,
  initState,
}: Options) {
  const atLeastOneMatchingRequiredValidator = makeSingleUnitValidator<
    MappedState<MatchingsState[string]['formSectionState']['units']['value']>
  >(x => {
    return !Object.values(x).some(y => !!y)
      ? {
          kind: 'invalid',
          messageReference: atLeastOneMatchingRequiredMessage,
        }
      : { kind: 'valid' };
  });

  const stateUnit = makePrimaryUnit(
    rightColumn.elementsUnit.getState().reduce<
      Dropdown.Children.CheckBoxList.IsCheckedState<
        Omit<ServerQuestion['columns']['right']['elements'][number], 'text'> & {
          text: I18n.MultilangFormState;
        }
      >
    >(
      (acc, x) => ({
        ...acc,
        [x.uuid]: makeMatchingRightColumnElement({ item: x, initState }),
      }),
      {},
    ),
  );

  return {
    stateUnit,
    formSectionState: makeFormSectionState(
      makeMappingUnitFromUnit(
        makeDerivedUnit(stateUnit).getUnit(x =>
          Object.values(x).reduce<
            Record<
              MappedState<
                StateInstance['rightColumn']['elementsUnit']
              >[number]['uuid'],
              PrimaryStateUnit<boolean>
            >
          >((acc, y) => {
            return { ...acc, [y.item.uuid]: y.value.units.value };
          }, {}),
        ),
      ),
      [atLeastOneMatchingRequiredValidator],
    ),
  };
}
