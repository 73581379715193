import * as M from 'types/serverModels';

import { makeService } from '../utils';

type InputData = {
  project_assignment: M.ProjectAssignment;
};

type APIOutputData = {
  project_assignment: M.ProjectAssignment;
  users: M.ProjectAssignmentUser[];
};

export const put = makeService<InputData, APIOutputData>(
  ({ project_assignment }) => `project_assignment/${project_assignment.uuid}`,
  'api-v2-put',
  false,
);
