import { useEffect } from 'react';
import React from 'react';

import { API } from 'services';

type Props = {
  terms: string[];
};

export const callStateUnit =
  API.services.classificationPaths.makeCallStateUnit();

function SelectedFetcher({ terms }: Props) {
  const call = API.services.classificationPaths.useCall(callStateUnit);
  const callState = callStateUnit.useState();

  useEffect(() => {
    if (callState.kind === 'initial') {
      if (terms.length > 0) {
        call({ term: terms });
      } else {
        callStateUnit.setState({ kind: 'successful', data: [] });
      }
    }
  }, [call, callState.kind, terms]);

  useEffect(
    () => () => {
      callStateUnit.resetState();
    },
    [terms],
  );

  return null;
}

export const Component = React.memo(SelectedFetcher);
