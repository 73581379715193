import React, { useCallback } from 'react';

import { Button } from 'components';
import { ConstructorConfigContext } from 'features/project/Constructor/config/configContext';
import i18nData from 'features/project/Constructor/i18n.json';
import { DatalessView } from 'features/project/Constructor/subfeatures';
import { I18n } from 'services';
import { PrimaryStateUnit } from 'utils/State';
import { block } from 'utils/classname';
import { useRequiredContext } from 'utils/react/RequiredContext';

import { FindingsConstructorWidgetContext } from '../../../ConstructorWidget/FindingsConstructorWidgetContext';
import * as modals from '../../../modals';
import './style.scss';

const b = block('dataless-widget-layout');

type Props = {
  shouldEmulateUnit: PrimaryStateUnit<boolean>;
};

function DatalessWidgetLayout({
  children,
  shouldEmulateUnit,
}: React.PropsWithChildren<Props>) {
  const { stateInstance } = useRequiredContext(
    FindingsConstructorWidgetContext,
  );

  const { getProjectUUID } = useRequiredContext(ConstructorConfigContext);

  const handleShowEmulatedDataButtonClick = useCallback(() => {
    const projectUUID = getProjectUUID();
    if (projectUUID === null) {
      modals.QuestionsNotSavedOrOtherError.open('project not saved');
    } else {
      if (stateInstance.validateForEmulation()) {
        shouldEmulateUnit.setState(true);
      } else {
        modals.IncompleteSettings.open();
      }
    }
  }, [getProjectUUID, shouldEmulateUnit, stateInstance]);

  const text = I18n.useText(i18nData);

  return (
    <div className={b()}>
      {children}
      <Button.Component
        className={b('show-emulated-data-button')}
        type="button"
        color="secondary"
        variant="outlined"
        onClick={handleShowEmulatedDataButtonClick}
      >
        {text.showEmulatedDataButtonText}
      </Button.Component>
    </div>
  );
}

export const Component = React.memo(DatalessWidgetLayout);

export function useViewWithLayout(
  kind: DatalessView.Props['kind'],
  shouldEmulateUnit: PrimaryStateUnit<boolean>,
) {
  return () => (
    <Component shouldEmulateUnit={shouldEmulateUnit}>
      <DatalessView.Component kind={kind} />
    </Component>
  );
}
