import React from 'react';

import i18nData from 'features/project/Constructor/i18n.json';
import {
  ConstructorStepSection,
  ConstructorFormSection,
} from 'features/project/Constructor/subfeatures';
import { getProjectLanguage } from 'features/project/Constructor/utils';
import { I18n } from 'services';
import * as M from 'types/serverModels';
import { makeFormElementState } from 'utils/FormState';
import { makePrimaryUnit } from 'utils/State';
import { makeUUID } from 'utils/UUID';
import { block } from 'utils/classname';
import { makeMapping } from 'utils/collection';

import { Step } from '../../types';
import { makeStepErrorsUnit, makeStepVisitedUnit } from '../../utils';
import * as Section from './Section';
import * as section from './sections';
import './style.scss';
import {
  featuresUnit,
  classificationsUnit,
  weightState,
  perkState,
  selectedFeaturesUnit,
  featureCodeState,
  productsUnit,
  paywallWarningState,
  isPaywallState,
  classificationsStateUnit,
} from './units/inputState';

const b = block('project-constructor-advanced-settings');

type Props = {};

function AdvancedSettings({}: Props) {
  const text = I18n.useText(i18nData).steps.advancedSettings;

  return (
    <div className={b()}>
      <ConstructorFormSection.Component>
        <Section.Component
          Content={section.Classifications.Component}
          heading={text.section.classifications.header}
          tooltip={text.section.classifications.tooltip}
        />
        <Section.Component
          Content={section.Weight.Component}
          heading={text.section.weight.header}
          tooltip={text.section.weight.tooltip}
        />
        <Section.Component
          Content={section.Perk.Component}
          heading={text.section.perk.header}
          tooltip={text.section.perk.tooltip}
        />
        <Section.Component
          Content={section.Features.Component}
          heading={text.section.features.header}
          tooltip={text.section.features.tooltip}
        />
      </ConstructorFormSection.Component>
      <ConstructorFormSection.Component>
        <ConstructorStepSection.Component
          title={text.section.paywallSwitch.header}
          HeaderRightPart={section.PaywallSwitch.Component}
        />
        <Section.Component
          Content={section.FeatureCode.Component}
          heading={text.section.featureCode.header}
          tooltip={text.section.featureCode.tooltip}
        />
        <Section.Component
          Content={section.Products.Component}
          heading={text.section.products.header}
          tooltip={text.section.products.tooltip}
        />
        <Section.Component
          Content={section.PaywallWarning.Component}
          heading={text.section.paywallWarning.header}
          tooltip={text.section.paywallWarning.tooltip}
        />
      </ConstructorFormSection.Component>
    </div>
  );
}

const Component = React.memo(AdvancedSettings);

export const step: Step = {
  key: 'advancedSettings',
  Form: Component,
  errorsUnit: makeStepErrorsUnit(),
  visitedUnit: makeStepVisitedUnit(),
  progressInvariantUnitsUnit: makePrimaryUnit([]),
  hasSomeInputUnit: makePrimaryUnit(false),
  getProjectData: ({ serverProject }) => {
    const lang = getProjectLanguage();
    const weight = weightState.getValue() || 0;
    const perk = perkState.getValue();
    const isPaywall = isPaywallState.getValue();

    const charge = {
      ...serverProject?.charge_draft,
      ...serverProject?.charge,
      participation: featureCodeState.getValue(),
      products: productsUnit
        .getState()
        .map(({ formElementState }) => formElementState.getValue()),
      paywall_text_signedin: I18n.makeMultilangFormState(
        paywallWarningState,
        serverProject?.charge?.paywall_text_signedin,
      ).getMergedMultilingString(lang),
    };

    return {
      classifications: classificationsUnit.getState(),
      weight,
      perks: perk ? [perk] : [],
      features: selectedFeaturesUnit.getState(),
      charge: isPaywall ? charge : null,
      charge_draft: !isPaywall ? charge : null,
    };
  },
  fillFromExistingProject: ({ project, language }) => {
    const t = (str?: M.MultilingString) =>
      I18n.getOptionalMultilingTranslation(language, str);

    if (project.features?.length) {
      const features = makeMapping(
        featuresUnit.getState(),
        ({ id }) => id,
        ({ value }) => value,
      );
      project.features.forEach(feature => {
        features[feature]?.units.value.setState(true);
      });
    }

    if (Array.isArray(project.classifications)) {
      classificationsUnit.setState(project.classifications);
    }

    if (typeof project.weight === 'number') {
      weightState.units.value.setState(project.weight);
    }

    if (typeof project.perks?.[0]?.type === 'number') {
      perkState.units.value.setState(project.perks[0]);
    }

    const charge = project.charge || project.charge_draft;

    isPaywallState.units.value.setState(!!project.charge);
    featureCodeState.units.value.setState(charge?.participation || '');
    productsUnit.setState(
      charge?.products.map(x => ({
        id: makeUUID(),
        formElementState: makeFormElementState(x),
      })) || [],
    );
    paywallWarningState.units.value.setState(t(charge?.paywall_text_signedin));
  },
  resetState: () => {
    classificationsUnit.resetState();
    weightState.formNode.reset();
    perkState.formNode.reset();
    featuresUnit.getState().forEach(x => x.value.formNode.reset());
    isPaywallState.formNode.reset();
    featureCodeState.formNode.reset();
    productsUnit.resetState();
    paywallWarningState.formNode.reset();

    Object.values(classificationsStateUnit.getState()).forEach(x => {
      x.extended.resetState();
      x.value.formNode.reset();
    });
  },
  resetStateAfterDelay: () => {
    paywallWarningState.formNode.reset();
  },
};
