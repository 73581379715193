import React from 'react';

import { I18n } from 'services';
import { ReactComponent as AngleArrowDownIcon } from 'shared/images/angleArrowDown.svg';
import { block } from 'utils/classname';

import i18nData from '../../i18n.json';
import { ProvidedProps } from '../../types';
import './style.scss';

const b = block('inline-range-date-picker');

type OwnProps = {
  size?: 'text-18' | 'text-16';
  fontWeight?: 'bold' | 'normal';
};

type Props = ProvidedProps & OwnProps;

function InlineVariant({
  formElementState,
  kind,
  size = 'text-18',
  fontWeight = 'bold',
  title,
  useLabel,
}: Props) {
  const text = I18n.useText(i18nData);
  const getFormattedRangeDate = I18n.useGetFormattedRangeDate();

  const { from, to } = formElementState.units.value.useState();

  const formattedRangeDate = getFormattedRangeDate({ from, to });

  return (
    <div
      className={b({ kind, size, 'font-weight': fontWeight })}
      title={title ?? (kind === 'form' ? text.title : undefined)}
      data-toggle
    >
      <time>{useLabel?.() ?? formattedRangeDate}</time>
      {kind === 'form' && <AngleArrowDownIcon className={b('icon')} />}
    </div>
  );
}

export const makeComponent =
  (ownProps: OwnProps) => (providedProps: ProvidedProps) => (
    <Component {...ownProps} {...providedProps} />
  );

export const Component = React.memo(InlineVariant) as typeof InlineVariant;
