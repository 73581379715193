import * as R from 'ramda';
import React, { useEffect } from 'react';

import { ProjectWriteContext } from 'features/project/Constructor/FormWithSteps/ProjectWriteContext';
import { ConstructorImageInput } from 'features/project/Constructor/subfeatures';
import * as M from 'types/serverModels';
import { useRequiredContext } from 'utils/react/RequiredContext';

import { projectImageState } from '../../units/inputState';

type Props = {};

const imageData: M.ImageData = {
  container: 'projects',
  thumb: [{ code: 'large', mode: 2, size: 690 }],
};

function ProjectPageImage({}: Props) {
  const { saveProject } = useRequiredContext(ProjectWriteContext);

  useEffect(() => {
    return projectImageState.units.value.subscribe({
      name: 'project-image-changed',
      callback: (newState, preState) => {
        if (!R.equals(newState, preState)) {
          saveProject();
        }
      },
    });
  }, [saveProject]);

  return (
    <ConstructorImageInput.Component
      formElementState={projectImageState}
      imageData={imageData}
      errorRows={1}
    />
  );
}

export const Component = React.memo(ProjectPageImage);
