import { makeMapping } from 'utils/collection';
import { createRequiredContext } from 'utils/react/RequiredContext';

import { QuestionConstructorWidget, QuestionKey } from './types';

type Config = {
  widgets: QuestionConstructorWidget<any, any, any>[];
  questionKeyToConstructorWidget: Record<
    QuestionKey,
    QuestionConstructorWidget<QuestionKey, any, any>
  >;
};

export const QuestionsConfigContext = createRequiredContext<Config>();

export function makeConfig(
  widgets: QuestionConstructorWidget<any, any, any>[],
): Config {
  return {
    widgets,
    questionKeyToConstructorWidget: makeMapping(
      widgets,
      x => x.key,
      x => x,
    ),
  };
}
