import React, { useCallback } from 'react';

import { ReactComponent as CrossIcon } from 'shared/images/cross.svg';
import { block } from 'utils/classname';

import { Props as TextInputProps } from '../../Component';
import './style.scss';

const b = block('text-input-clear-button');

type Props = Pick<TextInputProps, 'formElementState'> & {
  useIsVisible?(): boolean;
  onClick?(): void;
};

function ClearButton({ formElementState, useIsVisible, onClick }: Props) {
  const value = formElementState.units.value.useState();

  const visible = useIsVisible?.() ?? value !== '';

  const handleClick = useCallback(() => {
    formElementState.units.value.setState('');

    onClick?.();
  }, [formElementState, onClick]);

  return (
    <div className={b({ visible })}>
      <button className={b('button')} type="button" onClick={handleClick}>
        <CrossIcon className={b('icon')} />
      </button>
    </div>
  );
}

export const Component = React.memo(ClearButton);

export const makeComponent = (props: Props) => () => <Component {...props} />;
