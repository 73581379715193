import { FilterQuery } from 'mongodb';

import * as M from 'types/serverModels';

import { makeService } from '../../utils';

type PathInputData = {
  uuid: Exclude<M.ProjectBook['uuid'], undefined>;
};

type QueryInputData = {
  user?: M.UserXShort['login'];
  filter?: FilterQuery<M.ProjectBookChange>;
  sort?: { property: keyof M.ProjectBookChange; direction: 'ASC' | 'DESC' };
  start?: number;
  limit?: number;
};

type InputData = PathInputData & QueryInputData;

type APIOutputData = {
  project_book_changes?: M.ProjectBookChange[];
  count?: number;
};

type OutputData = {
  projectBookChanges: M.ProjectBookChange[];
  count: number;
};

function extractResponse(data: APIOutputData): OutputData {
  return {
    projectBookChanges: data?.project_book_changes || [],
    count: (data?.count ?? data?.project_book_changes?.length) || 0,
  };
}

export const get = makeService<InputData, APIOutputData, OutputData>(
  ({ uuid, ...params }, convertToSearchParams) => {
    const { filter, ...rest } = params;

    return `project_book/${uuid}/changes/?${convertToSearchParams({
      ...rest,
      filter: JSON.stringify(filter),
    })}`;
  },
  'api-v2-get',
  false,
  extractResponse,
);
