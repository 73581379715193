import React from 'react';

import { block } from 'utils/classname';

import './style.scss';

const b = block('list-string-title');

type Props = {
  useTitle(): string;
};

function StringTitle({ useTitle }: Props) {
  return <h3 className={b()}>{useTitle()}</h3>;
}

export const Component = React.memo(StringTitle) as typeof StringTitle;

export const makeComponent = (props: Props) => () => <Component {...props} />;
