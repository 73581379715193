import * as M from 'types/serverModels';

import { makeService } from '../utils';

type InputData = {
  uuid: M.UUID;
  project: Partial<M.ProjectWriteData>;
};

type APIOutputData = {
  project: M.Project;
};

export const patch = makeService<InputData, APIOutputData, M.Project>(
  ({ uuid }) => `project/${uuid}`,
  'api-v2-patch',
  false,
  ({ project }) => project,
  { extractBodyInput: ({ project }) => ({ project }) },
);
