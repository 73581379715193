import { makeFeature } from 'services/Feature';

import * as i18nSharedReferences from './i18nSharedReferences';

export const feature = makeFeature({
  name: 'ListLayout',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "list-layout" */
      './Component'
    ),
  i18nData: null,
  i18nSharedReferences: Object.values(i18nSharedReferences),
  subfeatures: [],
  prefetched: true,
});

export type { Props } from './Component';

export const Component = feature.Component;
