import React from 'react';

import { emulateClick } from 'utils/DOM';
import { block } from 'utils/classname';

import './style.scss';

const b = block('info-popup-anchor');

export type Props = {
  Icon?: React.VFC;
  forwardedRef: React.RefObject<HTMLDivElement>;
  icon: '?' | 'i';
  iconColor?: 'fg-3' | 'inherit';
  className?: string;
} & Omit<React.HTMLAttributes<HTMLDivElement>, 'ref'>;

function Anchor({
  Icon,
  forwardedRef,
  icon = '?',
  iconColor = 'fg-3',
  className,
  ...attributes
}: Props) {
  const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = event => {
    attributes.onKeyDown?.(event);

    emulateClick(event);
  };

  return (
    <div
      tabIndex={0}
      {...attributes}
      className={b({}, [className])}
      ref={forwardedRef}
      onKeyDown={handleKeyDown}
    >
      {Icon ? (
        <Icon />
      ) : (
        <div className={b('icon', { color: iconColor })}>{icon}</div>
      )}
    </div>
  );
}

export const Component = React.memo(Anchor);
