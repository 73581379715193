import { makeSharedEntryReference } from 'services/I18n/makeEntryReference';

export const requiredFieldValidationErrorReference = makeSharedEntryReference(
  data => data.validationErrors.requiredField,
);

export const equalityOfFieldsValidationErrorReference =
  makeSharedEntryReference(data => data.validationErrors.equalityOfFields);

export const confirmButtonLabelReference = makeSharedEntryReference(
  data => data.confirmButtonLabel,
);

export const cancelButtonLabelReference = makeSharedEntryReference(
  data => data.cancelButtonLabel,
);
