import React from 'react';

import { Page } from 'services';

import { routeTree } from '../../routeTree';
import * as features from './features';
import './style.scss';

type Props = {};

function ProjectList({}: Props) {
  return <features.ProjectList.Component />;
}

export const Component = Page.makePage({
  path: routeTree.LANG.project.list.getPath(),
  routeProps: { exact: true },
  scrollTo: 'top-on-mount',
  Component: React.memo(ProjectList) as typeof ProjectList,
  features: Object.values(features).map(x => x.feature),
});
