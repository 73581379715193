import React from 'react';

import i18nData from 'features/project/Constructor/i18n.json';
import { List } from 'features/project/Constructor/subfeatures';
import { I18n } from 'services';
import * as questionIcons from 'shared/images/questionIcons';
import { block } from 'utils/classname';
import { useRequiredContext } from 'utils/react/RequiredContext';

import { Question } from '../../shared';
import { EnumeratedQuestionInstance } from '../types';
import { enumeratedQuestionInstances } from '../units/instances';
import './style.scss';

const b = block('input-data-form-preview');

type Props = {};

const columns: Array<List.Column<EnumeratedQuestionInstance>> = [
  {
    cell: {
      Component: ({
        entry: {
          instance: { questionText, questionExplanation },
          number,
        },
      }) => {
        const text = questionText.formElementState.units.value.useState();
        const explanation =
          questionExplanation.formElementState.units.value.useState();

        return (
          <div className={b('question-text-and-explanation-cell-content')}>
            <div className={b('question-text')}>
              {number}. {text}
            </div>
            <div className={b('question-explanation')}>{explanation}</div>
          </div>
        );
      },
      align: 'left',
    },
  },

  {
    cell: {
      Component: ({
        entry: {
          instance: { kind },
        },
      }) => {
        const { questionKeyToConstructorWidget } = useRequiredContext(
          Question.QuestionsConfigContext,
        );
        const { key, icon } = questionKeyToConstructorWidget[kind];
        const questionLabel =
          I18n.useText(i18nData).steps.shared.questions.list[key].label;

        return (
          <div className={b('question-type-cell-content')}>
            <div className={b('question-type-icon-container')}>
              <img
                className={b('question-type-icon')}
                src={questionIcons[icon].src}
                alt="question-icon"
              />
            </div>
            <div className={b('question-label')}>{questionLabel}</div>
          </div>
        );
      },
      align: 'center',
    },
  },
];

function Preview({}: Props) {
  const instances = enumeratedQuestionInstances.useState();

  return (
    <List.Component className={b()} columns={columns} entries={instances} />
  );
}

export const Component = React.memo(Preview) as typeof Preview;
